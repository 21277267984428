import React from 'react';
import ProductionBucket from './production-bucket';
import { useGenerationBuckets } from './use-generation-buckets';
import Button from '@mui/material/Button';
import { L } from 'harmony-language';
import { ModalDialog } from '../../shared/containers/modal-dialog';
import { useBool } from '../../shared/hooks/use-bool';
import ProductionPreview from '../preview/production-preview';
import { AgisticsDateRange } from '../../shared/inputs/agistics-date-range';
import { DndContext, DragOverlay, DragStartEvent } from '@dnd-kit/core';
import { ProductionCardsDroppable } from './production-cards-droppable';
import { ProductionCard, ProductionCardType } from './production-card';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

interface ProductionGenerationProps {
    cargoTypeId: number;
}

const ProductionGeneration: React.FC<ProductionGenerationProps> = (props) => {
    const { cargoTypeId } = props;
    const {
        isLoading,
        dayBucket,
        generationBucket,
        clear,
        dateRange,
        setDateRange,
        handleOnDragOver,
        findContainer,
    } = useGenerationBuckets(cargoTypeId);
    const [isOpen, { off: close, on: open }] = useBool(false);
    const [activeDraggingCard, setActiveDraggingCard] = React.useState<ProductionCardType | null>(null);

    const handleOnDragStart = (event: DragStartEvent) => {
        const foundCard = dayBucket.find(card => card.id === event.active.id) || generationBucket.find(card => card.id === event.active.id);
        if (foundCard) {
            setActiveDraggingCard(foundCard);
        }
    }

    const handleOnDragEnd = () => {
        setActiveDraggingCard(null);
    }

    return (
        <div>
            <ModalDialog title={L.previewLoads()} onClose={close}
                open={isOpen && generationBucket.length !== 0}>
                <ProductionPreview cargoTypeId={cargoTypeId} productionCardIds={generationBucket.map(b => b.id)} onGenerateSuccess={() => {
                    close();
                }} />
            </ModalDialog>
            <div style={{ padding: '1rem 0 0 0', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{ maxWidth: '20rem' }}>
                    <AgisticsDateRange
                        startDate={dateRange.from}
                        endDate={dateRange.to}
                        setStartDate={(date) => setDateRange({ ...dateRange, from: date })}
                        setEndDate={(date) => setDateRange({ ...dateRange, to: date })}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <DndContext
                    onDragStart={handleOnDragStart}
                    onDragEnd={handleOnDragEnd}
                    onDragOver={handleOnDragOver}
                >
                    {/* VERY IMPORTANT TO HAVE DROPPABLE WRAPPED AROUND THE SORTABLE FOR WHEN THE LIST MIGHT HAVE 0 ITEMS IN IT!!!!! */}
                    <ProductionCardsDroppable id='day-bucket'>
                        <SortableContext
                            items={dayBucket.map(x => x.id)}
                            strategy={verticalListSortingStrategy}
                        >
                            <ProductionBucket
                                id={'day-bucket'}
                                findContainer={findContainer}
                                cards={dayBucket}
                                isLoading={isLoading}
                            />
                        </SortableContext>
                    </ProductionCardsDroppable>
                    <ProductionCardsDroppable id='generation-bucket'>
                        <SortableContext
                            items={generationBucket.map(x => x.id)}
                            strategy={verticalListSortingStrategy}
                        >
                            <ProductionBucket
                                id={'generation-bucket'}
                                findContainer={findContainer}
                                cards={generationBucket}
                                disableSearch
                            >
                                <div style={{ padding: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button type='button'
                                        title={L.clear()}
                                        variant='contained'
                                        color='secondary'
                                        sx={{ margin: '0 0.5rem' }}
                                        onClick={clear}
                                    >{L.clear()}</Button>
                                    <Button type='button'
                                        disabled={isLoading || generationBucket.length === 0}
                                        title={L.preview()}
                                        variant='contained'
                                        color='primary'
                                        sx={{ margin: '0 0.5rem' }}
                                        onClick={open}
                                    >{L.preview()}</Button>
                                </div>
                            </ProductionBucket>
                        </SortableContext>
                    </ProductionCardsDroppable>
                    <DragOverlay>
                        {activeDraggingCard ? <ProductionCard card={activeDraggingCard} /> : null}
                    </DragOverlay>
                </DndContext>
            </div>
        </div>
    );
};

export default ProductionGeneration;
