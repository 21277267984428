import React from 'react';
import Grid from '@mui/material/Grid';
import { L } from 'harmony-language';
import { DashboardCard } from './dashboard-card';
import { DashboardSummary } from './dashboard-summary';
import { getWorkingWeek } from '../../utils/date-time-utils';
import { useAtLeastOneReport, useCarrier, useFeatures, usePermissions, useTier } from '../user/selectors/use-permissions';
import { ContentHeader } from '../shared/containers/content-header';
import { Content } from '../shared/containers/content';
import Typography from '@mui/material/Typography';
import { CustomerTier, Features, PermissionType } from 'harmony-constants';
import { DashboardReports } from './dashboard-reports';
import { DashboardResourceReports } from './dashboard-resource-reports';
import { DashboardResourceLog } from './dashboard-resource-log';
import { useUser } from '../../api/queries/use-user';

export const Dashboard: React.FC = () => {
    const { user } = useUser()
    const isFreemium = useTier(CustomerTier.Freemium);
    const canViewLiveMapFeature = useFeatures(Features.DeliveryTracking);
    const canViewChecklists = useFeatures(Features.Checklists);
    const canViewProductionPlanning = useFeatures(Features.ProductionPlanning);
    const canViewSiteIssues = useFeatures(Features.SiteIssues);
    const canAccessAdministration = usePermissions(PermissionType.AccessAdministration);
    const canViewOrders = usePermissions(PermissionType.ViewOwningOrders);
    const canViewLiveMap = usePermissions(PermissionType.ViewLiveMap);
    const canViewDrafts = useFeatures(Features.Drafts) && usePermissions(PermissionType.EditOrder)
    const canViewMaintenance = usePermissions(PermissionType.ViewChecklist);
    const canViewSiteIssuesPage = usePermissions(PermissionType.ViewSiteIssues);
    const canViewProductionPlanningPage = usePermissions(PermissionType.ViewProductionCards);
    const workingWeek = React.useMemo(() => getWorkingWeek(0), []);
    const isCarrierOrg = useCarrier();
    const canViewReports = useFeatures(Features.Reports)
    const atLeastOneReport = useAtLeastOneReport();

    return (
        <>
            <ContentHeader>
                <Typography variant='h5'>{`${L.welcome()} ${user.name}`}</Typography>
            </ContentHeader>
            <Content>
                <Grid container>
                    {canViewProductionPlanning && canViewProductionPlanningPage && !isCarrierOrg && <DashboardCard isDisabled={isFreemium} linkTo={'/production-planning'} icon={'list_alt'} text={L.productionPlanning()} />}
                    {canViewDrafts && !isCarrierOrg && <DashboardCard isDisabled={isFreemium} linkTo={'/drafts'} icon={'create'} text={L.viewDraftLoads()} />}
                    {canViewOrders && <DashboardCard isDisabled={isFreemium} linkTo={'/loads'} icon={'event'} text={L.viewScheduledLoads()} />}
                    {canViewReports && atLeastOneReport && <DashboardCard linkTo={'/reports'} icon={'history'} text={L.viewReports()} />}
                    {canViewLiveMap && canViewLiveMapFeature && <DashboardCard linkTo={'/live-map'} icon={'map'} text={L.viewLiveMap()} />}
                    {canViewMaintenance && canViewChecklists && !isCarrierOrg && <DashboardCard isDisabled={isFreemium} linkTo={'/maintenance'} icon={'build'} text={L.maintenance()} />}
                    {canViewSiteIssues && canViewSiteIssuesPage && !isCarrierOrg && <DashboardCard isDisabled={isFreemium} linkTo={'/site-issues'} icon={'warning'} text={L.siteIssues()} />}
                    {canAccessAdministration &&
                        <DashboardCard linkTo={'/manage-company'} icon={'people'} text={L.administration()} />}
                </Grid>
                {canViewOrders && !isFreemium &&
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                            <DashboardSummary workingWeek={workingWeek} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <DashboardReports />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <DashboardResourceReports />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <DashboardResourceLog />
                        </Grid>
                    </Grid>
                }
            </Content>
        </>
    );
};
