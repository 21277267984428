import React, { useEffect, useState } from 'react';
import { ValidatedTextField, Validation } from './validated-text-field';
import Autocomplete, { AutocompleteChangeReason } from "@mui/material/Autocomplete";
import { L } from 'harmony-language';
import { Popper } from '@mui/material';

export type ItemId = number | null;

export type AutoItem = {
    id: ItemId;
    label: string;
    //category?: string;
    cargoTypeIds?: number[];
    //isCategory?: boolean;
}

type ResourceAutoCompleteProps = {
    initialSelectedOptionId?: AutoItem['id'];
    includeNone?: boolean,
    isNoneValid?: boolean,
    // resourceType: ResourceType,
    // stop: Stop,
    options: AutoItem[];
    onChange: (id: AutoItem['id'] | null) => void;
    label?: string,
    isLoading?: boolean;
    fontSize?: boolean;
    disabledOptionIds?: Array<AutoItem['id']>;
    validations?: Validation[];
    disabled?: boolean;
}

const CustomPopper = (props: any) => {

    return <Popper {...props} style={{ width: 'fit-content'}} />;
};

export const ResourceAutoComplete: React.FC<ResourceAutoCompleteProps> = (props) => {
    const {
        initialSelectedOptionId = null,
        options,
        onChange,
        // stop,
        label,
        // resourceType,
        isLoading,
        fontSize,
        validations,
        disabled,
    } = props;

    const list = options.sortBy((l: AutoItem) => `${l.label}`);
    const noneOption: AutoItem = { id: -1, label: L.none() };
    if (!list.some(l => l.id === -1)) {
        list.unshift(noneOption);
    }


    const [value, setValue] = useState<AutoItem | null | undefined>(list.find(o => o.id === initialSelectedOptionId));
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        setValue(list.find(o => o.id === initialSelectedOptionId));
    }, [initialSelectedOptionId])

    const setNewValue = (newValue?: AutoItem, reason?: AutocompleteChangeReason) => {
        if (reason === 'selectOption') {
            onChange(newValue?.id || null)
        }
        setValue(newValue);
    }

    return (
        <>
            {value && list.some(o => o.id === value.id) ?
                <Autocomplete
                    sx={fontSize ? { input: { fontSize: '.875rem' }} : undefined}
                    disableClearable
                    disabled={disabled}
                    loading={isLoading}
                    loadingText={L.loading()}
                    options={list}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    value={value || undefined}
                    onChange={(event, newValue, reason) => setNewValue(newValue, reason)}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                    PopperComponent={CustomPopper}
                    // renderOption={(option) => {
                    //     return (
                    //         <MenuItem key={option.id} value={option.label}>
                    //             {resourceType ?
                    //                 <ResourceConflictContainer
                    //                     resource={{
                    //                         id: option.id,
                    //                         type: resourceType,
                    //                         cargoTypeIds: option.cargoTypeIds,
                    //                         stop,
                    //                     }}>
                    //                     {option.label}
                    //                 </ResourceConflictContainer> : option.label}
                    //         </MenuItem>
                    //     );
                    // }}
                    renderInput={(params) => {
                        return (
                            <ValidatedTextField
                                {...params}
                                label={label}
                                required
                                variant='standard'
                                fullWidth
                                validations={validations}
                            />
                        );
                    }}
                /> : null}
        </>
    );
}
