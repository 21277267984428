import React, { CSSProperties, forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Close from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Paper, { PaperProps } from '@mui/material/Paper';
import { TransitionProps } from '@mui/material/transitions';
import { DndContext, DragEndEvent, useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';

const DRAGGABLE_ID = 'modal-dialog-draggale';

const StyledAppBar = styled(AppBar)({
    position: 'sticky',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
    width: 'auto',
});

const HeaderBar = styled(AppBar)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    boxShadow: 'none',
    padding: '0.5rem 0 0.25rem 1rem',
    width: 'inherit',
    flexGrow: 1,
});

const ModalContent = styled('div')({
    padding: '0.5rem 1rem',
    height: '100%',
    overflow: 'auto',
});

interface Coordinate {
    x?: number;
    y?: number;
}

type AgisticsModalTransitionProps = TransitionProps & {
    setCoordinates: React.Dispatch<React.SetStateAction<Coordinate>>;
    children: React.ReactElement<any, any>;
}

const AgisticsModalTransition = forwardRef((props: AgisticsModalTransitionProps, ref: React.Ref<unknown>) => {
    const { setCoordinates, onExited, ...rest } = props;

    return (
        <Slide
            {...rest}
            ref={ref}
            direction='up'
            onExited={(a) => {
                setCoordinates(defaultCoordinates);
                onExited && onExited(a);
            }}
        />
    );
})

type DraggableComponentProps = PaperProps & {
    top: number;
    left: number;
};

const defaultCoordinates = {
    x: undefined,
    y: undefined,
};

const DraggableComponent: React.FC<DraggableComponentProps> = (props) => {
    const { top, left, ...rest } = props;
    const { setNodeRef, transform} = useDraggable({
        id: DRAGGABLE_ID,
    });

    const style: CSSProperties = {
        transform: CSS.Translate.toString(transform),
        position: 'absolute',
        top: top,
        left: left,
    };

    return (
        <Paper
            ref={setNodeRef}
            style={style}
            {...rest}
        />
    );
}

interface ModalDialogProps {
    className?: string;
    onClose: () => void;
    open: boolean;
    title: string;
    fullWidth?: boolean;
    children: React.ReactNode;
}

export const ModalDialog: React.FC<ModalDialogProps> = (props) => {
    const {
        open,
        onClose,
        title,
        className,
        children,
        fullWidth,
    } = props;
    const [{x, y}, setCoordinates] = React.useState<Coordinate>(defaultCoordinates);

    const handleClose = (_: object, reason: 'escapeKeyDown' | 'backdropClick') => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    const handleDragEnd = (event: DragEndEvent) => {
        const { delta, activatorEvent } = event;

        setCoordinates(({x, y}) => {
            return {
                // @ts-ignore - there has to be a better way to determine where the top/left is at startup, but I can't figure it out (32 magic number, I have no idea)
                x: x ? x + delta.x : activatorEvent.pageX - activatorEvent.offsetX - 32,
                // @ts-ignore
                y: y ? y + delta.y : activatorEvent.pageY - activatorEvent.offsetY - 32,
            };
        });
    }

    return (
        <DndContext
            onDragEnd={handleDragEnd}
            modifiers={[restrictToWindowEdges]}
        >
            <Dialog
                disablePortal={false}
                className={className}
                open={open}
                onClose={handleClose}
                // @ts-ignore
                TransitionComponent={AgisticsModalTransition}
                TransitionProps={{
                    // @ts-ignore
                    setCoordinates: setCoordinates,

                }}
                maxWidth={false}
                fullWidth={fullWidth}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                // @ts-ignore
                PaperComponent={DraggableComponent}
                PaperProps={{
                    top: y,
                    left: x,
                }}
            >
                <InternalModalHeaderAndContent
                    title={title}
                    onClose={onClose}
                >
                    {children}
                </InternalModalHeaderAndContent>
            </Dialog>
        </DndContext>
    );
};

interface InternalModalHeaderAndContentProps {
    title: string;
    onClose: () => void;
    children: React.ReactNode;
}

const InternalModalHeaderAndContent: React.FC<InternalModalHeaderAndContentProps> = (props) => {
    const { title, onClose, children } = props;
    const { attributes, listeners } = useDraggable({
        id: DRAGGABLE_ID,
    });

    return (
        <>
            <StyledAppBar>
                <HeaderBar
                    {...attributes}
                    {...listeners}
                >
                    <Typography
                        variant='h6'
                        style={{ maxWidth: 'fit-content' }}
                    >
                        {title}
                    </Typography>
                </HeaderBar>
                <Button
                    sx={{ color: 'white' }}
                    onClick={onClose}
                >
                    <Close />
                </Button>
            </StyledAppBar>
            <ModalContent
                data-testid={'modal-dialog-content'}
            >
                {children}
            </ModalContent>
        </>
    );
}
