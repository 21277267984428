import { L } from 'harmony-language';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../api/config';
import { useTractorTrailer } from '../../api/queries/use-tractor-trailer';
import { useLocationTypes } from '../../api/queries/use-location-types';
import {
    LoadCargo,
    LoadDate,
    LoadDestination,
    LoadDriver,
    LoadFirstStop,
    LoadLastCompleted,
    LoadLastStop,
    LoadOrigin,
    LoadStatus,
    LoadTractor,
    LoadTrailer
} from '../shared/load-table/load-table-cells';
import { LoadTableColumn } from '../shared/load-table/load-table';
import {
    StopActualQuantity,
    StopActualWeight,
    StopCargoType,
    StopCompletedTime,
    StopLocation,
    StopLotId,
    StopNotes,
    StopOrderNumber,
    StopProductionPlan,
    StopQuantity,
    StopReadyTime,
    StopTime,
    StopType,
    StopWeight
} from '../shared/load-table/stops-table-cells';
import {
    sortByDate,
    sortByDriver,
    sortByStatus,
    sortByStop,
    sortByStopCompletedTime,
    sortByStopTime,
    sortByTractor,
    sortByTrailer
} from '../shared/table/utils/sorting-functions';
import React from 'react';
import { loadToFirstStop, loadToLastStop, orderToLastCompleted } from '../../utils/data-mapping-utils';
import { Driver, OrganizationLocation } from '../../types';

export const useLoadsColumns = (): { columns: LoadTableColumn[], stopColumns: LoadTableColumn[], isLoading: boolean } => {
    const { isLoadingTractors, isLoadingTrailers, tractors, trailers } = useTractorTrailer();
    const { isLoading: isLoadingLoadingTeams } = useOrganizationQuery(OrgQueryKeys.loadingTeams);
    const { isLoading: isLoadingLocations, data: organizationLocations } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { isLoading: isLoadingDrivers, data: drivers } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers);
    const { isLoading: isLoadingLocationTypes } = useLocationTypes();
    const isLoading = isLoadingTractors || isLoadingTrailers || isLoadingLoadingTeams || isLoadingLocations || isLoadingDrivers || isLoadingLocationTypes;

    const columns: LoadTableColumn[] = React.useMemo(() => ([
        { header: L.date(), render: LoadDate, sort: sortByDate() },
        { header: L.firstStop(), render: LoadFirstStop, sort: sortByStopTime(loadToFirstStop), defaultSort: true, defaultDirection: 'desc' },
        { header: L.origin(), render: LoadOrigin, sort: sortByStop(organizationLocations, 'Origin') },
        { header: L.lastStop(), render: LoadLastStop, sort: sortByStopTime(loadToLastStop) },
        { header: L.completed(), render: LoadLastCompleted, sort: sortByStopCompletedTime(orderToLastCompleted), defaultDirection: 'desc' },
        { header: L.destination(), render: LoadDestination, sort: sortByStop(organizationLocations, 'Destination') },
        { header: L.cargo(), render: LoadCargo },
        { header: L.driver(), render: LoadDriver, sort: sortByDriver(drivers) },
        { header: L.tractor(), render: LoadTractor, sort: sortByTractor(tractors) },
        { header: L.trailer(), render: LoadTrailer, sort: sortByTrailer(trailers) },
        { header: L.status(), render: LoadStatus, sort: sortByStatus() },
    ]), [organizationLocations, drivers, tractors, trailers]);

    const stopColumns: LoadTableColumn[] = React.useMemo(() => ([
        { header: L.stopType(), render: StopType },
        { header: L.location(), render: StopLocation },
        { header: L.readyTime(), render: StopReadyTime },
        { header: L.scheduled(), render: StopTime },
        { header: L.completed(), render: StopCompletedTime },
        { header: L.planned(), render: StopQuantity },
        { header: L.actual(), render: StopActualQuantity },
        { header: L.planned(), render: StopWeight },
        { header: L.actual(), render: StopActualWeight },
        { header: L.cargoType(), render: StopCargoType },
        { header: L.orderNumber(), render: StopOrderNumber },
        { header: L.productionPlan(), render: StopProductionPlan },
        { header: `${L.pickUp()} #`, render: StopLotId },
        { header: L.notes(), render: StopNotes }
    ]), []);

    return { columns, stopColumns, isLoading };
};
