import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { DynamicQueryKeys, mutationOptions } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { Permission } from '../../../types';

type AddRolePermssion = {
    roleId: number;
    permissionId: number;
}

const addRolePermission = async (rolePermission: AddRolePermssion) => {
    return (await axios.post(`${API_BASE}/api/role-permissions`, rolePermission)).data;
}

export const useAddRolePermissionMutation = (roleId: number) => {
    const snackbar = useSnackbar();
    const key = DynamicQueryKeys.rolePermissions(roleId);
    const queryClient = useQueryClient();

    return useMutation(addRolePermission, {
        ...mutationOptions([key], queryClient),
        onSuccess: (data: Permission) => {
            const rolePermissions = queryClient.getQueryData<Permission[]>([key]);
            if (rolePermissions) {
                const newRolePermissions = [...rolePermissions, data];
                queryClient.setQueryData([key], newRolePermissions);
                snackbar(L.addedRolePermission());
            }
        },
        onSettled: () => {},
    });
};
