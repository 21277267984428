import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { L } from 'harmony-language';
import { ResourceEditCreateContainer } from '../../../shared/containers/resource-edit-create-container';

export const EditCreateCustomer = (props) => {
    const { existingCustomer, onSubmit } = props;
    const [customer, setCustomer] = React.useState({});

    React.useEffect(() => {
        if (existingCustomer) {
            setCustomer(existingCustomer);
        }
    }, []);

    const submitForm = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        onSubmit(customer);
    };

    const renderTextField = (label, keyValue, required = false) => {
        return (
            <TextField
                variant='standard'
                label={label}
                required={required}
                value={customer[keyValue] || ''}
                onChange={(e) => {
                    setCustomer({ ...customer, [keyValue]: e.target.value });
                }}
                style={{
                    marginBottom: '1rem',
                    width: '100%'
                }} />
        );
    };

    return (
        <ResourceEditCreateContainer width='20vw'>
            <form id='edit-create-customer-form' name='edit-create-customer-form' onSubmit={submitForm}>
                {renderTextField(L.name(), 'customerName', true)}
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <Button type='submit'
                        title='Save'
                        variant='contained'
                        color='primary'>{existingCustomer ? L.updateCustomer() : L.createCustomer()}</Button>
                </div>
            </form>
        </ResourceEditCreateContainer>
    );
};

EditCreateCustomer.propTypes = {
    existingCustomer: PropTypes.object,
    onSubmit: PropTypes.func
};
