import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { Load, LoadCache } from '../../../types';
import { useLoadsKey } from '../../config-hooks';

async function addLoad(options: { load: Load }) {
    const load = {
        ...options.load,
        date: options.load?.stops?.[0].arrivalTime
    };

    return (await axios.post(`${API_BASE}/api/orders`, load)).data;
}

export const useAddLoadMutation = () => {
    const snackbar = useSnackbar();
    const loadsKey = useLoadsKey();
    const queryClient = useQueryClient();

    return useMutation(addLoad, {
        ...mutationOptions(loadsKey, queryClient),
        onSuccess: (data) => {
            //map organizationId to owningOrganizationId as web project only knows organizationId
            data.organizationId = data.owningOrganizationId;
            const previousCache = queryClient.getQueryData<LoadCache>(loadsKey);

            if (previousCache) {
                queryClient.setQueryData(loadsKey, {
                    ...previousCache,
                    data: previousCache.data.concat(data).sortBy(u => u.id)
                });
                snackbar(L.loadAddedSuccessfully());
            }
        },
        onSettled: () => {}
    });
};
