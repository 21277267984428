import React from 'react';
import { styled } from '@mui/material/styles';
import { useBoolean } from '../../../shared/hooks/use-boolean';
import { ImageOutlined } from '@mui/icons-material';
import { ModalDialog } from '../../../shared/containers/modal-dialog';
import { L } from 'harmony-language';
import Dropzone from 'react-dropzone';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { ImageThumbnailExpand } from '../../../shared/image-thumbnail-expand';
import { capitalizeFirst } from '../../../../utils/string-utils';
import { Image } from '../../../../types';

const dimension = {
    width: '200px',
    height: '200px'
};

const ImageGridContainer = styled(Grid)({
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center'
});

const FormFileUpload = styled('section')({
    'max-width': '100%',
    'text-align': 'center',
    position: 'relative',
    margin: '1rem',
    ...dimension
});

const LabelFileUpload = styled('div')({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: '2px',
    borderRadius: '1rem',
    borderStyle: 'dashed',
    borderColor: '#cbd5e1',
    backgroundColor: '#f8fafc'
});

const MAX_IMAGES = 5;

interface EditLocationImageModalProps {
    onDroppedFiles: (droppedFiles: File[]) => Promise<void>;
    images: Image[];
    onDelete?: (image: Image) => void;
}

const EditLocationImageModal: React.FC<EditLocationImageModalProps> = ({
    images,
    onDroppedFiles,
    onDelete
}) => {
    const [isOpen, open, close] = useBoolean(false);

    const currentImageCount = images?.length;
    const allowedImages = MAX_IMAGES - currentImageCount;

    return (
        <>
            <Button color='secondary' onClick={open} style={{ width: '100%' }}>
                <ImageOutlined sx={{ padding: '0 5px' }} />
                {L.siteImages()}
            </Button>
            <ModalDialog
                title={`${L.siteImages()}`}
                open={isOpen}
                onClose={() => close()}
            >
                <ImageGridContainer
                    container
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    spacing={1}
                >
                    {allowedImages > 0 ? (
                        <Dropzone
                            onDrop={onDroppedFiles}
                            accept={{
                                'image/jpeg': ['.jpg', '.jpeg']
                            }}
                            maxFiles={allowedImages}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <FormFileUpload>
                                    <LabelFileUpload
                                        {...getRootProps()}
                                    >
                                        <input
                                            {...getInputProps()}
                                            style={{ display: 'none' }}
                                        />
                                        <p>
                                            {`${L.imageDragAndDropDescription()}`}
                                            <br/><br/>
                                            {`${capitalizeFirst(L.limit())}: ${MAX_IMAGES} jpeg/jpg ${L.images()}`}
                                        </p>
                                    </LabelFileUpload>
                                </FormFileUpload>
                            )}
                        </Dropzone>
                    ) : null}
                    {images?.length
                        ? images.map((image, i) => (
                              <Grid item key={i}>
                                  <ImageThumbnailExpand image={image} onDelete={onDelete} label={L.siteImage()} key={i} />
                              </Grid>
                          ))
                        : null}
                </ImageGridContainer>
            </ModalDialog>
        </>
    );
};

export default EditLocationImageModal;
