import React from 'react';
import PropTypes from 'prop-types';
import EtaThresholdEntry from './eta-threshold-entry';
import ThroughputEntry from './throughput-entry';
import Grid from '@mui/material/Grid';
import { STOP_TYPES } from '../../../constants/constants';
import { L } from 'harmony-language';
import { MenuItem, Select } from '@mui/material';
import { getTransCargoTypeLabel } from '../../../constants/constants';

export const EditEntries = props => {
    const {
        isLoading,
        throughputs = [],
        cargoTypes = [],
        onChange,
        onEtaThresholdChange,
        etaThresholdMinutes,
        onEtaDelayThresholdChange,
        etaDelayThresholdMinutes,
        etaThresholdMinutesError,
        etaDelayThresholdMinutesError,
    } = props;
    const [throughputIndex, setThroughputIndex] = React.useState(0);

    const defaultThroughputs = React.useMemo(() => {
        const getThroughput = (throughputs, stopType, cargoTypeId) => {
            return throughputs.find(x => x.loadingEntityType === 'Organization'
                                    && x.stopType === stopType
                                    && x.cargoTypeId === cargoTypeId)
                              || { domKey: Math.random(), stopType, cargoTypeId, loadingEntityType: 'Organization' };
        }

        const c = [
            {id: null, label: L.default()},
            ...cargoTypes.sort((a,b) => getTransCargoTypeLabel(a.id).localeCompare(getTransCargoTypeLabel(b.id)))
                         .map(x => ({id: x.id, label: getTransCargoTypeLabel(x.id)}))
        ];
        return c.map(x => {
            const t = [
                getThroughput(throughputs, STOP_TYPES().Origin.key, x.id),
                getThroughput(throughputs, STOP_TYPES().Destination.key, x.id)
            ]
            //only have the weigh stop duration on the default cargo type
            if (x.id === null) {
                return [...t, getThroughput(throughputs, STOP_TYPES().Weigh.key, x.id)]
            } else {
                return t;
            }
        }).flat();
    }, [throughputs, cargoTypes]);

    const isVoidOrWhitespace = (s) => {
        if (s === undefined || s === null || String(s).trim() === '') {
            return true;
        }
        return false;
    }

    const handleChangeFunc = (index) => (key, value) => {
        const newThroughputs = defaultThroughputs.map((t, i) => i === index ? { ...t, [key]: value } : t)
        const filtered = newThroughputs.filter(x => x.loadingEntityType === 'Organization' &&
                                              (!isVoidOrWhitespace(x.cargoUnitsPerMinute) || 
                                               !isVoidOrWhitespace(x.baselineLoadingMinutes) ||
                                               !isVoidOrWhitespace(x.weightPerMinute)));
        onChange(filtered);
    };

    return (
        <Grid container spacing={2} style={{ padding: '0.75rem 0 1.25rem 0' }}>
            <EtaThresholdEntry etaThresholdMinutes={etaThresholdMinutes} defaultValue={30} translationPrefix={'ETA'} isLoading={isLoading} onChange={onEtaThresholdChange} error={etaThresholdMinutesError}/>
            <EtaThresholdEntry etaThresholdMinutes={etaDelayThresholdMinutes} defaultValue={60} translationPrefix={'ETADelay'} isLoading={isLoading} onChange={onEtaDelayThresholdChange} error={etaDelayThresholdMinutesError}/>
            <Grid item xs={12}>
                <Select
                    variant='standard'
                    id="demo-simple-select"
                    value={throughputIndex}
                    onChange={(e) => setThroughputIndex(e.target.value)}>
                    <MenuItem key={0} value={0}>{L.default()}</MenuItem>
                    {cargoTypes.map((ct,i) => <MenuItem key={ct.id} value={i+1}>{getTransCargoTypeLabel(ct.id)}</MenuItem>)}
                </Select>
            </Grid>
            {defaultThroughputs.map((t, i) => {
                let show = false;
                if (throughputIndex === 0) {
                    show = i < 3;
                } else {
                    const first = (throughputIndex - 1) * 2 + 3;
                    show = i >= first && i <= first + 1  
                }
                return show ? <ThroughputEntry key={t.id || t.domKey} throughput={t} isLoading={isLoading} onChange={handleChangeFunc(i)} /> : <></>
            })}
        </Grid>
    );
};

EditEntries.propTypes = {
    isLoading: PropTypes.bool,
    throughputs: PropTypes.array,
    cargoTypes: PropTypes.array,
    onChange: PropTypes.func,
    onEtaThresholdChange: PropTypes.func,
    etaThresholdMinutes: PropTypes.number,
    onEtaDelayThresholdChange: PropTypes.func,
    etaDelayThresholdMinutes: PropTypes.number,
    etaThresholdMinutesError: PropTypes.bool,
    etaDelayThresholdMinutesError: PropTypes.bool,
};
