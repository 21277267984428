import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { L } from 'harmony-language';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { isIntegerAndMoreThanZeroAndLessThan600 } from '../../../utils/validation-utils';
import { Organization } from '../../../types';

interface UnscheduledStopEntriesProps {
    company: Organization;
    isLoading?: boolean;
    handleSettingsChange: (key: string, returnsEvent?: boolean) => (value: number) => void
}

const DEFAULT_INSIDE_SITE_INACTIVE_MINS = 30;
const DEFAULT_OUTSIDE_SITE_INACTIVE_MINS = 10;

export const UnscheduledStopEntries: React.FC<UnscheduledStopEntriesProps> = (props) => {
    const { company, isLoading, handleSettingsChange } = props;

    const [insideSiteDisplayValue, setInsideSiteDisplayValue] = React.useState<number | string>('')
    const [outsideSiteDisplayValue, setOutsideSiteDisplayValue] = React.useState<number | string>('')

    const insideSiteInactiveMinutesError = React.useMemo(() => !isIntegerAndMoreThanZeroAndLessThan600(company?.settings?.insideSiteInactiveMinutes),
        [company?.settings?.insideSiteInactiveMinutes]);
    const outsideSiteInactiveMinutesError = React.useMemo(() => !isIntegerAndMoreThanZeroAndLessThan600(company?.settings?.outsideSiteInactiveMinutes),
        [company?.settings?.outsideSiteInactiveMinutes]);

    React.useEffect(() => {
        if (company?.settings?.insideSiteInactiveMinutes === undefined) {
            setInsideSiteDisplayValue(DEFAULT_INSIDE_SITE_INACTIVE_MINS);
        } else {
            setInsideSiteDisplayValue(company?.settings?.insideSiteInactiveMinutes);
        }
        if (company?.settings?.outsideSiteInactiveMinutes === undefined) {
            setOutsideSiteDisplayValue(DEFAULT_OUTSIDE_SITE_INACTIVE_MINS);
        } else {
            setOutsideSiteDisplayValue(company?.settings?.outsideSiteInactiveMinutes);
        }
    }, []);

    return (
        <Grid container spacing={2} style={{ padding: '0.75rem 0 1.25rem 0' }}>
            <Grid item xs={12} sm={6}>
                <Typography>{L.unscheduledStopInsideSite()}</Typography>
                <TextField
                    variant='standard'
                    fullWidth
                    disabled={isLoading}
                    id={`unscheduled-stop-inside`}
                    label={L.unscheduledStopInsideSiteLabel()}
                    type='number'
                    value={insideSiteDisplayValue}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        style: {
                            marginTop: '1.7em'
                        },
                        inputProps: { min: 0, max: 600 },
                        endAdornment: <InputAdornment position='end'>{L.mins()}</InputAdornment>
                    }}
                    onChange={(e) => {
                        setInsideSiteDisplayValue(Number(e.target.value) || '')
                        handleSettingsChange('insideSiteInactiveMinutes')(Number(e.target.value));
                    }}
                    error={insideSiteInactiveMinutesError}
                    helperText={insideSiteInactiveMinutesError && L.invalidThresholdNumber()} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography>{L.unscheduledStopOutsideSite()}</Typography>
                <TextField
                    variant='standard'
                    fullWidth
                    disabled={isLoading}
                    id={`unscheduled-stop-outside`}
                    label={L.unscheduledStopOutsideSiteLabel()}
                    type='number'
                    value={outsideSiteDisplayValue}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        style: {
                            marginTop: '1.7em'
                        },
                        inputProps: { min: 0, max: 600 },
                        endAdornment: <InputAdornment position='end'>{L.mins()}</InputAdornment>
                    }}
                    onChange={(e) => {
                        setOutsideSiteDisplayValue(Number(e.target.value) || '')
                        handleSettingsChange('outsideSiteInactiveMinutes')(Number(e.target.value));
                    }}
                    error={outsideSiteInactiveMinutesError}
                    helperText={outsideSiteInactiveMinutesError && L.invalidThresholdNumber()} />
            </Grid>
        </Grid>
    );
};
