import React from 'react';
import { L, TIMEZONES } from 'harmony-language';
import PropTypes from 'prop-types';
import { Select } from './select';

export const SelectTimezone = props => {
    const { timezone, onChange } = props;

    const menuItems = TIMEZONES.map(tz => ({ id: tz, value: tz, label: tz.replace('_', ' ') }));
    const item = menuItems.find(x => x.value === timezone);

    return (
        <Select
            {...props}
            label={L.selectTimezone()}
            list={menuItems}
            item={item}
            onChange={(v) => onChange(v.value)}/>
    );
};

SelectTimezone.propTypes = {
    timezone: PropTypes.string,
    onChange: PropTypes.func,
};
