import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { L } from 'harmony-language';

export const NicknameInput = ({ organizationLocation, setOrganizationLocation }) => {
    const [chageName, setChangeName] = React.useState(0);

    return (
        <TextField
            variant='standard'
            required
            label={L.name()}
            id='nickname'
            value={organizationLocation.name || ''}
            style={{ width: '100%' }}
            onChange={(e) => {
                setOrganizationLocation({ ...organizationLocation, name: e.target.value });
            }}
            onClick={() => setChangeName(chageName + 1)}
            inputProps={{ maxLength: 200 }}
            disabled={chageName >= 7 ? false : organizationLocation.id ? true : false} />
    );
};

NicknameInput.propTypes = {
    organizationLocation: PropTypes.object,
    setOrganizationLocation: PropTypes.func
};
