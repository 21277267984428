import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { Collapse } from '@mui/material';
import { L } from 'harmony-language';
import Skeleton from "@mui/material/Skeleton";

export const AvoidanceZoneList = (props) => {
    const { isLoading, avoidanceZones, companyId, canEdit, canModifyGlobalAvoidanceZones, selectedAvoidanceZone, onEdit } = props;
    const [sections, setSections] = React.useState({
        'company-zone-key': true,
        'global-zone-key': true,
        'company-zone-deactivated-key': true,
        'global-zone-deactivated-key': true,
    });

    const renderAvoidanceZone = (avoidanceZone, index) => (
        <ListItem key={index} button={true} onClick={() => props.onSelect(avoidanceZone)}
            selected={selectedAvoidanceZone === avoidanceZone}>
            <ListItemText>{avoidanceZone.name}</ListItemText>
            {canEdit && (avoidanceZone.organizationId || canModifyGlobalAvoidanceZones) && (
                <ListItemSecondaryAction>
                    <IconButton data-testid={`edit-avoidance-zone-${avoidanceZone.id}`} edge='end' onClick={() => onEdit(avoidanceZone)} size='large'>
                        <EditIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );

    const renderSection = (title, items, keyClass) => {
        if (items.length === 0) {
            return null;
        }

        return (
            <div data-testid={keyClass}>
                <ListSubheader disableSticky className='avoidance-zone-list-subheader'
                    onClick={() => setSections({ ...sections, [keyClass]: !sections[keyClass] })}>
                    <span>{title}</span>
                    <div className={keyClass}/>
                    {sections[keyClass] ? <ExpandLess/> : <ExpandMore/>}
                </ListSubheader>
                <Collapse in={sections[keyClass]} timeout='auto' unmountOnExit={true}>
                    {items}
                </Collapse>
            </div>
        );
    };

    const companyZones = avoidanceZones.filter(zone => zone.organizationId && zone.organizationId === companyId);
    const globalZones = avoidanceZones.filter(zone => !zone.organizationId);

    const companyActiveZones = companyZones.filter(zone => zone.deactivatedAt === null).map(renderAvoidanceZone);
    const globalActiveZones = globalZones.filter(zone => zone.deactivatedAt === null).map(renderAvoidanceZone);
    const companyDeactivatedZones = companyZones.filter(zone => zone.deactivatedAt !== null).map(renderAvoidanceZone);
    const globalDeactivatedZones = globalZones.filter(zone => zone.deactivatedAt !== null).map(renderAvoidanceZone);

    const toSkeletonListItem = (x) => <Skeleton key={x} width='90%' height={32} />;

    return (
        <List className='avoidance-zone-list' dense={true}>
            {isLoading && Array.from(Array(5), (x, i) => i).map(toSkeletonListItem)}
            {!isLoading && renderSection(L.zones(), companyActiveZones, 'company-zone-key')}
            {!isLoading && renderSection(L.global(), globalActiveZones, 'global-zone-key')}
            <br /><br /><br />
            {!isLoading && renderSection(L.deactivated(), companyDeactivatedZones, 'company-zone-deactivated-key')}
            {!isLoading && renderSection(L.deactivatedGlobal(), globalDeactivatedZones, 'global-zone-deactivated-key')}
        </List>
    );
};

AvoidanceZoneList.propTypes = {
    isLoading: PropTypes.bool,
    avoidanceZones: PropTypes.array,
    companyId: PropTypes.number,
    canEdit: PropTypes.bool,
    canModifyGlobalAvoidanceZones: PropTypes.bool,
    onEdit: PropTypes.func,
    onSelect: PropTypes.func,
    selectedAvoidanceZone: PropTypes.object,
};
