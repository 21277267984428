import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import { Button, IconButton } from "@mui/material";
import { L } from 'harmony-language';
import { useAddDraftMutation } from "../../../api/mutations/add/use-add-draft-mutation"
import { useOrganizationQuery } from "../../../api/queries/use-organization-query"
import { getTransCargoTypeLabel, ORDER_STATUSES, STOP_TYPES } from "../../../constants/constants"
import { Load, OrganizationLocation, Stop } from "../../../types"
import { OrderGroupDetailsResult, useOrderGroupDetails } from "../../production-planning/order-groups/use-order-group-detail"
import { useLoadOrderGroup } from "./utils/use-load-order-group"
import { useBoolean } from '../hooks/use-boolean';
import { OrgQueryKeys, QueryKeys } from '../../../api/config';
import Skeleton from "@mui/material/Skeleton";
import { AddCircle } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';

const AllocationTable = styled('div')({
    display: 'grid',
    fontSize:'.875rem',
    margin: '0px 50px',
    gridTemplateRows: '30px',
    gridTemplateColumns: '1.5fr repeat(2, 2fr) repeat(5, 1fr) .25fr',
    [`& > div:nth-child(18n+1),
     & > div:nth-child(18n+2),
     & > div:nth-child(18n+3),
     & > div:nth-child(18n+4),
     & > div:nth-child(18n+5),
     & > div:nth-child(18n+6),
     & > div:nth-child(18n+7),
     & > div:nth-child(18n+8)`]: {
        backgroundColor: '#f5f5f5'
    },
    '& > div': {
        display:'flex',
        alignItems:'center',
        height: '30px'
    }
});

interface OrderGroupSummaryProps {
    loads: Load[],
    selectedLoads: Load[],
    tableId: 'drafts' | 'loads',
    isLoading: boolean
}
interface MismatchedLoadDetails {
    origin?: LoadStatusCounts,
    destination?: LoadStatusCounts
}
interface ProductionCardStopSummary {
    key: string,
    cargoTypeId: number,
    locationId?: number,
    orderNumber?: string | null,
    productionPlan?: string | null,
    quantity: number,
    stopType: "Origin" | "Destination"
}
interface OrderGroupDetailsBreakdown {
    productionCardDetails: ProductionCardStopSummary[],
    loadDetails: LoadDetails,
    mismatchedLoadDetails: MismatchedLoadDetails
}

const generateProductionCardKey = (stop: Partial<Stop>) => {
    return `${stop.organizationLocationId}🔑${stop.cargoTypeId}🔑${stop.productionPlan}🔑${stop.orderNumber}`
}
const stopQuantityReducerByKey = (acc: {[key: string]: number}, cur: Stop) => {
    const key = generateProductionCardKey(cur);
    if (acc[key]) {
        acc[key] += cur.quantity;
    } else {
        acc[key] = cur.quantity;
    }
    return acc;
}
const incrementAccumulatorWithOrderSummary =
    (
        summary: { [key: string]: number },
        incrementKey: 'planned' | 'scheduled' | 'complete',
        type: 'origin' | 'destination',
        accumulator: {[key: string]: {
            planned: number,
            scheduled: number,
            complete: number,
            type: 'origin' | 'destination'
        }}
    ) => {
    for (const [key, value] of Object.entries(summary)) {
        if (accumulator[key]) {
            if (accumulator[key] && accumulator[key][incrementKey]) {
                accumulator[key][incrementKey] += value;
            } else {
                accumulator[key] = { ...accumulator[key], [incrementKey]: value }
            }
        } else {
            accumulator[key] = { planned: 0, scheduled: 0, complete: 0, type }
            accumulator[key][incrementKey] += value
        }
    }
    return accumulator;
}

const orderReducer = (incrementKey: 'planned' | 'scheduled' | 'complete') => (acc: LoadDetails, cur: Load) => {
    const originSummary = cur.stops.filter(x => x.type === STOP_TYPES().Origin.key).reduce(stopQuantityReducerByKey, {});
    const destinationSummary = cur.stops.filter(x => x.type === STOP_TYPES().Destination.key).reduce(stopQuantityReducerByKey, {});

    incrementAccumulatorWithOrderSummary(originSummary, incrementKey, 'origin', acc);
    incrementAccumulatorWithOrderSummary(destinationSummary, incrementKey, 'destination', acc);

    return acc;
}

const orderGroupDetailBreakdown = (response: OrderGroupDetailsResult | null, locations: OrganizationLocation[]) => {
    if (!response) {
        return;
    }
    let loadDetails: LoadDetails = {}

    //drafts
    loadDetails = response.orders.filter(x => x.isDraft)
                                 .reduce(orderReducer('planned'), loadDetails);
    //scheduled
    loadDetails = response.orders.filter(x => !x.isDraft && x.status !== ORDER_STATUSES().Delivered.key)
                                 .reduce(orderReducer('scheduled'), loadDetails);
    //completed
    loadDetails = response.orders.filter(x => !x.isDraft && x.status === ORDER_STATUSES().Delivered.key)
                                 .reduce(orderReducer('complete'), loadDetails);

    const productionCardDetails = response.productionCards.reduce((acc, x) => {
        const originLocation = x.stops.filter(y => y.type === STOP_TYPES().Origin.key)[0];
        const destinationLocation = x.stops.filter(y => y.type === STOP_TYPES().Destination.key)[0];
        const originKey = generateProductionCardKey({...x, organizationLocationId: originLocation.organizationLocationId});
        const destinationKey = generateProductionCardKey({...x, organizationLocationId: destinationLocation.organizationLocationId});
        [originKey, destinationKey].map((key, i) => {
            if (acc[key]) {
                acc[key].quantity += x.quantity
            } else {
                acc[key] = {
                    key,
                    locationId: (i === 0) ? originLocation.organizationLocationId : destinationLocation.organizationLocationId,
                    stopType: (i === 0 ? 'Origin' : 'Destination'),
                    quantity: x.quantity,
                    cargoTypeId: x.cargoTypeId,
                    productionPlan: x.productionPlan,
                    orderNumber: x.orderNumber
                }  
            }
        });
        return acc
    }, {} as {[key:string]: ProductionCardStopSummary});

    const mismatchedLoadDetails = Object.entries(loadDetails).reduce((acc, cur) => {
        const [key, value] = cur;
        if (!productionCardDetails[key]) {
            acc[value.type].planned += (value?.planned || 0);
            acc[value.type].scheduled += (value?.scheduled || 0);
            acc[value.type].complete += (value?.complete || 0);
        }
        return acc;
    }, {
        origin: {
            planned: 0, scheduled: 0, complete: 0
        },
        destination: {
            planned: 0, scheduled: 0, complete: 0
        }
    });

    //sort the productionCardDetails
    const sorted = Object.values(productionCardDetails).sort((a,b) => {
        if (a.stopType === b.stopType) {
            const locA = locations.find(l => l.id === a.locationId);
            const locB = locations.find(l => l.id === b.locationId);
            if (locA && locB) {
                return locA.name.localeCompare(locB.name);
            }
            return -1;
        } else if (a.stopType === STOP_TYPES().Origin.key) {
            return -1;
        } else {
            return 1;
        }
    })
    return { productionCardDetails: sorted.map(x => productionCardDetails[x.key]), loadDetails, mismatchedLoadDetails }
}

interface LoadStatusCounts {
    planned: number,
    scheduled: number,
    complete: number 
}
interface LoadDetails {
    [key: string]: LoadStatusCounts & {
        type: 'origin' | 'destination'
    }
}

export const OrderGroupSummary: React.FC<OrderGroupSummaryProps> = (props) => {
    const {loads, selectedLoads, tableId, isLoading: isTableLoading} = props;
    const { mutate: addDraft, isLoading: isAddingDraft } = useAddDraftMutation();
    const orderGroup = useLoadOrderGroup();
    const { data: locations = [] as OrganizationLocation[] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const {isLoading: isOrderGroupLoading, fetch} = useOrderGroupDetails();
    const [details, setDetails] =  React.useState<OrderGroupDetailsBreakdown>();
    const [orderGroupId, setOrderGroupId] = React.useState<number | null>(null);
    const [detailShown, , hideDetail, toggleDetail] = useBoolean(false);
    const [orderGroupGuid, setOrderGroupGuid] = React.useState<string | null>(null);
    const [showAllocation, setShowAllocation] = React.useState<boolean>(false);

    const queryClient = useQueryClient()

    React.useEffect(() => {
        const selectedLoadGroupGuid = selectedLoads?.[0]?.orderGroup?.guid;
        const showAllocationButton = Boolean(selectedLoads.length)
                                        && selectedLoads?.every(x => x.orderGroup?.guid === selectedLoadGroupGuid
                                        && x.orderGroup?.entryType === 'ProductionCards');
        const newShowAllocation = Boolean(orderGroup.guid) || showAllocationButton
        setShowAllocation(newShowAllocation);
        const newOrderGroupId = (newShowAllocation) ? (orderGroup.guid || selectedLoadGroupGuid || null) : null
        setOrderGroupGuid(newOrderGroupId)
        if (!newOrderGroupId) {
            hideDetail();
        }
    }, [loads, selectedLoads, orderGroup.guid])

    React.useEffect(() => {
        !isOrderGroupLoading &&
        !isAddingDraft &&
        !isTableLoading && (async () => {
            if (orderGroupGuid) {
                const response = await fetch(orderGroupGuid);
                setOrderGroupId(response?.id || null);

                const details = orderGroupDetailBreakdown(response, locations);
                setDetails(details);

                if (!response?.id) {
                    queryClient.invalidateQueries([QueryKeys.productionCardGroups]);
                }
            }
        })();
    }, [orderGroupGuid, loads, isTableLoading]);

    const createDraft = async (productionCardDetails: ProductionCardStopSummary[], index: number, balance: number) => {
        const prodCard = productionCardDetails[index];
        const origKey = STOP_TYPES().Origin.key;
        const destKey = STOP_TYPES().Destination.key;
        const pairedStop = productionCardDetails.find(x => {
            return x.stopType === (prodCard.stopType === origKey ? destKey : origKey) &&
                   x.cargoTypeId === prodCard.cargoTypeId &&
                   x.orderNumber === prodCard.orderNumber &&
                   x.productionPlan === prodCard.productionPlan &&
                   x.quantity === prodCard.quantity
        })
        const cardStops = [prodCard, pairedStop].filter(Boolean).sort((a,_) => a?.stopType === origKey ? -1 : 1)
        const stops = cardStops.map((x, i) => {
            return {
                "organizationLocationId": x?.locationId,
                "status": "Not Started",
                "sequence": i+1,
                "cargoTypeId": x?.cargoTypeId,
                "quantity": Math.abs(balance),
                "type": (i === 0 ? origKey : destKey),
                "productionPlan": x?.productionPlan,
                "orderNumber": x?.orderNumber,
            }
        })
        
        await addDraft({
            load: {
                "date": new Date(),
                "status": "Open",
                "orderGroupId": orderGroupId,
                stops,
            }
        })
    }

    return (
        <>
        {showAllocation && orderGroupId ? <div><Button onClick={toggleDetail}>{(detailShown ? L.hideAllocation() : L.showAllocation())}</Button></div> : <div />}
        {detailShown && <Table size={'small'} component={'div'}>
            {isOrderGroupLoading && <Skeleton />}
            {!isOrderGroupLoading &&
            <AllocationTable>
                <div>{L.locationType()}</div>
                <div>{L.location()}</div>
                <div>{L.cargoType()}</div>
                <div>{L.total()}</div>
                <div>{tableId !== 'drafts' ? <a href={`/drafts?orderGroupGuid=${orderGroupGuid}`}>{L.planned()}</a> : L.planned()}</div>
                <div>{tableId !== 'loads' ? <a href={`/loads?orderGroupGuid=${orderGroupGuid}`}>{L.scheduled()}</a> : L.scheduled()}</div>
                <div>{L.complete()}</div>
                <div>{L.balance()}</div>
                <div/>
                {details?.productionCardDetails.map((x, i) => {
                    const location = locations.find(l => l.id === x.locationId)
                    const loadDetails = details.loadDetails[x.key];
                    const cargoTypeName = getTransCargoTypeLabel(x.cargoTypeId);
                    const totals = {
                        planned: loadDetails?.planned || 0,
                        scheduled: loadDetails?.scheduled || 0,
                        complete: loadDetails?.complete || 0
                    };
                    const balance = (totals.planned + totals.scheduled + totals.complete) - x.quantity;
                    
                    return <React.Fragment key={i}>
                    <div>{Object.values(STOP_TYPES()).find(t => t.key === x.stopType)?.label}</div>
                    <div>{location?.name}</div>
                    <div>{cargoTypeName}</div>
                    <div>{x.quantity}</div>
                    <div>{totals.planned}</div>
                    <div>{totals.scheduled}</div>
                    <div>{totals.complete}</div>
                    <div>{balance}</div>
                    <div>
                       {(balance < 0 && tableId === 'drafts') && <IconButton onClick={async () => await createDraft(details.productionCardDetails, i, balance)} aria-label='Create Draft' title='Create Draft' size='small'>
                            <AddCircle/>
                        </IconButton>}
                    </div>
                    </React.Fragment>
                })}
                {['origin' as keyof MismatchedLoadDetails, 'destination' as keyof MismatchedLoadDetails].map(x => {
                    return (Boolean(details?.mismatchedLoadDetails[x]?.planned) ||
                    Boolean(details?.mismatchedLoadDetails[x]?.scheduled) ||
                    Boolean(details?.mismatchedLoadDetails[x]?.complete)) &&
                    <>
                        <div>{x === 'origin' ? L.mismatchedOrigins() : L.mismatchedDestinations()}</div><div /><div /><div />
                        <div>{details?.mismatchedLoadDetails[x]?.planned}</div>
                        <div>{details?.mismatchedLoadDetails[x]?.scheduled}</div>
                        <div>{details?.mismatchedLoadDetails[x]?.complete}</div><div /><div />
                    </>
                })}
            </AllocationTable>}
            </Table>}
        </>
    )
}