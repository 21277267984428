import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions, QueryKeys } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { AvoidanceZone } from '../../../types';

async function addAvoidanceZone(options: { avoidanceZone: AvoidanceZone }) {
    const avoidanceZone = options.avoidanceZone;

    return (await axios.post(`${API_BASE}/api/avoidance-zones`, avoidanceZone)).data;
}

export const useAddAvoidanceZoneMutation = () => {
    const snackbar = useSnackbar();
    const key = QueryKeys.avoidanceZones;
    const queryClient = useQueryClient();

    return useMutation(addAvoidanceZone, {
        ...mutationOptions([key], queryClient),
        onSuccess: (data) => {
            const avoidanceZones = queryClient.getQueryData<AvoidanceZone[]>([key]);
            if (avoidanceZones) {
                const newAvoidanceZones = [...avoidanceZones, data].sortBy(u => u.id);
                queryClient.setQueryData([key], newAvoidanceZones);
                snackbar(L.avoidanceCreated());
            }
        }
    });
};
