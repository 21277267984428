import Typography from '@mui/material/Typography';
import { L } from 'harmony-language';
import { localDateTimeDisplay } from '../../../../utils/date-time-utils';
import PropTypes from 'prop-types';
import React from 'react';

export const BookingConflictTooltip = (props) => {
    const { conflict } = props;

    return (
        <div>
            <Typography>{L.conflictResource()}</Typography>
            <div>{`${L.load()} #${conflict.orderId}`}</div>
            {conflict.orderNumber && <div>{`${L.orderNumber()}: ${conflict.orderNumber}`}</div>}
            {conflict.lotId && <div>{`${L.pickUp()} #: ${conflict.lotId}`}</div>}
            {conflict.stopSequence && <div>{`${L.stop()}: ${conflict.stopSequence}`}</div>}
            <div>{`${localDateTimeDisplay(conflict.begin)} \u00a0- \u00a0${localDateTimeDisplay(conflict.end)}`}</div>
        </div>
    );
};

BookingConflictTooltip.propTypes = {
    conflict: PropTypes.object
};
