import React from 'react';
import { L } from 'harmony-language';

type MapPlaceholderProps = {
    isResourceLog?: boolean;
};

export const MapPlaceholder: React.FC<MapPlaceholderProps> = (props) => {
    const { isResourceLog } = props;
    const text =  isResourceLog ? L.mapPlaceHolderResourceLog() : L.mapPlaceHolder();

    return (
        <div style={{
            display: 'flex',
            padding: '1rem',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 10000,
            backgroundColor: '#f5f5f5',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <p>{text}</p>
        </div>
    );
};
