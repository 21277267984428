import axios from 'axios';
import { errorOccurred } from '../components/errors/error-occurred';
import { userSignOut } from '../components/user/actions/user-sign-out';
import { QueryClient } from '@tanstack/react-query';
import { NavigateFunction } from 'react-router-dom';

export const setUpAxiosResponseHandler = (queryClient: QueryClient, navigate: NavigateFunction) => {
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                errorOccurred({
                    type: 'API Error Occurred',
                    data: error.response.data,
                    message: error.response.data.message
                });

                if (error.response.status === 401) {
                    userSignOut(queryClient, navigate);
                }
            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                errorOccurred({
                    type: 'API Error Occurred',
                    data: error.request,
                    message: error.message
                });
            } else {
                // Something happened in setting up the request and triggered an Error
                errorOccurred({
                    type: 'API Error Occurred',
                    data: error
                });
            }

            throw error;
        }
    );
};
