import React from 'react';
import PropTypes from 'prop-types';
import { ModalDialog } from '../shared/containers/modal-dialog';
import { BulkOrder } from './bulk-order/bulk-order';
import { BulkConfirmation } from './bulk-order/bulk-confirmation';
// import { BulkOrderTabs } from './bulk-order/bulk-order-tabs';
import { useBoolean } from '../shared/hooks/use-boolean';
import { getWorkingWeekFromDate } from '../../utils/date-time-utils';
import { useUpdateUserPreferences } from '../shared/hooks/use-update-user-preferences';
import { AgisticsContext } from '../../app/agistics-context';
import { QueryKeys } from '../../api/config';
import { L } from 'harmony-language';

export const BulkDraftModal = props => {
    const { isOpen, onClose } = props;
    const [bulkTabIndex, setBulkTabIndex] = React.useState(0);
    const [isBulkCompletionOpen, openBulkCompletion, closeBulkCompletion] = useBoolean(false);
    const { preferences, updatePreferences } = useUpdateUserPreferences();
    const { updateDateFilters } = React.useContext(AgisticsContext);

    const onCloseBulkConfiguration = ({ completed, week }) => {
        setBulkTabIndex(0);
        if (week) {
            const { weekNumber, sundayIso, saturdayIso } = getWorkingWeekFromDate(week);
            const newFilter = {
                preset: weekNumber,
                from: sundayIso,
                to: saturdayIso,
            }

            updateDateFilters(QueryKeys.drafts, newFilter);
        }

        onClose();
        if (completed) {
            if (!preferences?.doNotShowBulkOrderCompletionModal) {
                openBulkCompletion();
            }
        }
    };

    const onCloseBulkCompletion = (doNotShowAgain) => {
        if (doNotShowAgain) {
            updatePreferences({ doNotShowBulkOrderCompletionModal: true });
        }
        closeBulkCompletion();
    };

    // const bulkTabs = (<BulkOrderTabs bulkTabIndex={bulkTabIndex} setBulkTabIndex={setBulkTabIndex}
    //     closeBulkCreation={onCloseBulkConfiguration}/>);

    return (
        <>
            <ModalDialog
                title={L.createDrafts()}
                open={isOpen}
                onClose={() => onCloseBulkConfiguration({ completed: false })}
                // titleComponent={bulkTabs}
            >
                <BulkOrder bulkTabIndex={bulkTabIndex} setBulkTabIndex={setBulkTabIndex}
                    closeModal={onCloseBulkConfiguration}/>
            </ModalDialog>
            <ModalDialog open={isBulkCompletionOpen} onClose={onCloseBulkCompletion}
                titleComponent={<></>}>
                <BulkConfirmation onCloseBulkCompletion={onCloseBulkCompletion}/>
            </ModalDialog>
        </>
    );
};

BulkDraftModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
};
