import React from 'react';
import { HerePoint } from '../../../types';
import { Stack } from '@mui/material';

interface DraggableRouteListProps {
    draggableRoute: HerePoint[];
}

export const DraggableRouteList: React.FC<DraggableRouteListProps> = (props) => {
    const { draggableRoute } = props;

    return (
        <Stack>
            {draggableRoute.map((x, i) => {
                return (
                    <span key={i}>{`${x.lat.toFixed(8)}  ${x.lng.toFixed(8)}`}</span>
                )
            })}
            <span>{draggableRoute.length}</span>
        </Stack>
    );
};
