/* eslint-disable */
import { renderToStaticMarkup } from 'react-dom/server';
import { getCurrentUnitSystem, getHereMapLanguage } from 'harmony-language';

let platform;

export function getPlatform() {
    if (!platform) {
        platform = new H.service.Platform({
            apiKey: 'wYZaauBZeYtKjuNeUdX558EELoYeJZ0nQFJQzI73-rw'
        });
    }

    return platform;
}

export async function createHereMapInstance(elementId, zoomLevel = 12, centerPosition) {
    const defaultLayers = getPlatform().createDefaultLayers({
        lg: getHereMapLanguage().mapCode
    });

    // New instance of Raster Tile Service
    const satelliteTiles = platform.getRasterTileService({
        resource: 'base',
        queryParams: {
            style: 'explore.satellite.day',
        },
    });

    // Create Provider
    const satelliteTileProvider = new H.service.rasterTile.Provider(satelliteTiles);

    // Create layer and override layer in the defaultLayers stack
    const satelliteTilesLayer = new H.map.layer.TileLayer(satelliteTileProvider);
    defaultLayers.raster.satellite.map = satelliteTilesLayer;
    defaultLayers.vector.normal.map.setMax(18);

    const map = new H.Map(
        document.getElementById(elementId),
        defaultLayers.raster.satellite.map,
        {
            zoom: zoomLevel,
            center: centerPosition,
            imprint: null,
            pixelRatio: window.devicePixelRatio || 1
        });

    window.addEventListener('resize', () => {
        map.getViewPort().resize();
    });

    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
    const ui = H.ui.UI.createDefault(map, defaultLayers, getHereMapLanguage().uiCode);

    ui.setUnitSystem(getCurrentUnitSystem().toLowerCase());

    return {
        map,
        ui,
        behavior
    };
}

export function createMarker(latitude, longitude, icon, visibility = true, type) {
    if (icon) {
        const domMarkerOptions = {
            icon: createMapIcon(icon),
            visibility: visibility,
            data: {
                isMobileHeadingMarker: Boolean(type === 'mobile'),
                isTrailerHeadingMarker: Boolean(type === 'trailer'),
            }
        };

        return new H.map.DomMarker({ lat: latitude, lng: longitude }, domMarkerOptions);
    }

    return new H.map.DomMarker({ lat: latitude, lng: longitude });
}

export function isMarker(shape) {
    return shape instanceof H.map.DomMarker;
}

export function createMapIcon(icon) {
    return new H.map.DomIcon(icon);
}

export function createInfoBubble(latitude, longitude, tooltip) {
    const content = renderToStaticMarkup(tooltip);
    const infoBubble = new H.ui.InfoBubble({ lat: latitude, lng: longitude }, {
        content
    });

    return infoBubble;
}

export function isInfoBubbleOpen(infoBubble) {
    return infoBubble.getState() === H.ui.InfoBubble.State.OPEN;
}

export function createPolygon(lineString) {
    return new H.geo.Polygon(lineString);
}

export function createLineString(waypoints) {
    return new H.geo.LineString(waypoints);
}

export function createMultiLineString(lineStrings) {
    return new H.geo.MultiLineString(lineStrings);
}

export function createPolyline(lineString, options) {
    return new H.map.Polyline(lineString, options);
}

export function isPolyline(shape) {
    return shape instanceof H.map.Polyline;
}

export function createGroup(objects) {
    return new H.map.Group({
        volatility: true,
        objects
    });
}

export function isGroup(shape) {
    return shape instanceof H.map.Group;
}

export function createGeoRectangleFromPoints(topLeft, bottomRight) {
    return H.geo.Rect.fromPoints(topLeft, bottomRight);
}

export function createGeoRectangle(top, left, bottom, right) {
    return new H.geo.Rect(top, left, bottom, right);
}

export function createRectangle(pointA, pointB, strokeColor, fillColor, type) {
    return new H.map.Rect(
        new H.geo.Rect(pointA.lat, pointA.lng, pointB.lat, pointB.lng),
        {
            style: { strokeColor: strokeColor, fillColor: fillColor, lineWidth: 4 },
            data: { rectangleType: type },
        }
    );
}

export function isRectangle(shape) {
    return shape instanceof H.map.Rect;
}

export function createCircle(lat, lng, radius, color) {
    return new H.map.Circle(
        { lat, lng },
        radius,
        {
            style: { strokeColor: color, fillColor: 'rgba(0, 0, 0, 0)', lineWidth: 2 },
            // force circle to be 'above' rect, for pointerenter and  pointerleave events
            // zIndex does not accomplish this correctly
            extrusion: 1,
        }
    );
}

export function isCircle(shape) {
    return shape instanceof H.map.Circle;
}

export function createViaArgs(viaArgs) {
    return new H.service.Url.MultiValueQueryParameter(viaArgs);
}

export function lineStringFromEncodedPolyline(encodedPolyline) {
    return H.geo.LineString.fromFlexiblePolyline(encodedPolyline);
}
