import React from 'react';
import { AgisticsContext } from '../app/agistics-context';
import { QueryKeys } from './config';

export const useDraftsKey = () => {
    const { dateFilters } = React.useContext(AgisticsContext);
    const draftFilters = dateFilters[QueryKeys.drafts];
    const draftsKey = [QueryKeys.drafts, draftFilters.preset, draftFilters.from, draftFilters.to, draftFilters.includedDates];

    return draftsKey;
}

export const useLoadsKey = () => {
    const { dateFilters } = React.useContext(AgisticsContext);
    const loadFilters = dateFilters[QueryKeys.loads];
    const loadsKey = [QueryKeys.loads, loadFilters.preset, loadFilters.from, loadFilters.to, loadFilters.includedDates];

    return loadsKey;
}

export const useProductionCardGroupsKey = () => {
    const { dateFilters } = React.useContext(AgisticsContext);
    const productionCardsFilters = dateFilters[QueryKeys.productionCardGroups];
    const productionCardsKey = [QueryKeys.productionCardGroups, productionCardsFilters.preset, productionCardsFilters.from, productionCardsFilters.to];

    return productionCardsKey;
}
