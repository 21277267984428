import { L } from 'harmony-language';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../api/config';
import { useTractorTrailer } from '../../api/queries/use-tractor-trailer';
import { useLocationTypes } from '../../api/queries/use-location-types';
import {
    LoadCargo,
    LoadDate,
    LoadDestination,
    LoadDriver,
    LoadFirstStop,
    LoadLastStop,
    LoadOrigin,
    LoadTractor,
    LoadTrailer
} from '../shared/load-table/load-table-cells';
import { LoadTableColumn } from '../shared/load-table/load-table';
import {
    EditableStopLotId,
    EditableStopOrderNumber,
    StopCargoType,
    StopLocationAuto,
    StopNotes,
    StopProductionPlan,
    StopQuantity,
    StopTime,
    StopType,
    StopSplitIcon,
    StopReadyTime,
    StopWeight,
    StopAutoCalc,
    StopSubLocations,
    StopLotId
} from '../shared/load-table/stops-table-cells';
import {
    sortByDate,
    sortByDriver,
    sortByStop,
    sortByStopTime,
    sortByTractor,
    sortByTrailer
} from '../shared/table/utils/sorting-functions';
import React from 'react';
import { loadToFirstStop, loadToLastStop } from '../../utils/data-mapping-utils';
import { Driver, OrganizationLocation } from '../../types';
import { ResourceSchedulingButton } from './scheduling/resource-scheduling-button';

export const useDraftsColumns = (): { columns: LoadTableColumn[], stopColumns: LoadTableColumn[], isLoading: boolean } => {
    const { isLoadingTractors, isLoadingTrailers, tractors, trailers } = useTractorTrailer();
    const { isLoading: isLoadingLoadingTeams } = useOrganizationQuery(OrgQueryKeys.loadingTeams);
    const { isLoading: isLoadingLocations, data: organizationLocations } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { isLoading: isLoadingDrivers, data: drivers } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers);
    const { isLoading: isLoadingLocationTypes } = useLocationTypes();
    const isLoading = isLoadingTractors || isLoadingTrailers || isLoadingLoadingTeams || isLoadingLocations || isLoadingDrivers || isLoadingLocationTypes;

    const columns: LoadTableColumn[] = React.useMemo(() => ([
        { header: L.date(), render: LoadDate, sort: sortByDate(), defaultSort: true },
        { header: L.firstStop(), render: LoadFirstStop, sort: sortByStopTime(loadToFirstStop) },
        { header: L.origin(), render: LoadOrigin, sort: sortByStop(organizationLocations, 'Origin') },
        { header: L.lastStop(), render: LoadLastStop, sort: sortByStopTime(loadToLastStop) },
        { header: L.destination(), render: LoadDestination, sort: sortByStop(organizationLocations, 'Destination') },
        { header: L.cargo(), render: LoadCargo },
        { header: '', render: ResourceSchedulingButton },
        { header: L.driver(), render: LoadDriver, sort: sortByDriver(drivers) },
        { header: L.tractor(), render: LoadTractor, sort: sortByTractor(tractors) },
        { header: L.trailer(), render: LoadTrailer, sort: sortByTrailer(trailers) },
    ]), [organizationLocations, drivers, tractors, trailers]);

    const stopColumns: LoadTableColumn[] = React.useMemo(() => ([
        { header: L.stopType(), render: StopType },
        { header: L.location(), render: StopLocationAuto, required: true },
        { header: L.subLocations(), render: StopSubLocations, required: false },
        { header: L.readyTime(), render: StopReadyTime },
        { header: L.time(), render: StopTime },
        { header: '', render: StopAutoCalc },
        { header: L.quantity(), render: StopQuantity },
        { header: L.weight(), render: StopWeight },
        { header: L.cargoType(), render: StopCargoType },
        { header: L.split(), render: StopSplitIcon },
        { header: L.orderNumber(), render: EditableStopOrderNumber },
        { header: L.productionPlan(), render: StopProductionPlan },
        { header: `${L.pickUp()} #`, render: StopLotId },
        { header: '', render: StopNotes }
    ]), []);

    return { columns, stopColumns, isLoading };
};
