import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { L } from 'harmony-language';
import Skeleton from "@mui/material/Skeleton";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useCargoTypes } from '../../../../api/queries/use-cargo-types';
import { SelectCargoType } from '../../../shared/inputs/select-cargo-type';

const renderTrailerCargoTypeRow = (trailerCargoType, filterSelector, index, handleChange, handleDelete) => {
    const key = trailerCargoType.domKey || trailerCargoType.id;
    const currentCargoTypeId = trailerCargoType.cargoTypeId;

    return (
        <React.Fragment key={key}>
            <ListItem dense style={{ alignItems: 'flex-end' }} data-testid={`trailer-cargo-type-${key}`}>
                <SelectCargoType cargoType={{ id: currentCargoTypeId }}
                    onChange={(newCargoType) => {
                        handleChange({
                            ...trailerCargoType,
                            cargoTypeId: newCargoType.id
                        }, index);
                    }}
                    filterSelector={filterSelector(currentCargoTypeId)}
                    InputProps={{
                        disableUnderline: true,
                    }}
                    inputProps={{
                        'aria-label': L.cargoType()
                    }}
                    fullWidth
                    label={L.cargoType()}
                />
                <TextField
                    variant='standard'
                    type='number'
                    size='small'
                    id={`compartment-count-${key}`}
                    label={L[`cargoCompartmentLabel${currentCargoTypeId}`]()}
                    value={trailerCargoType.compartmentCount}
                    onChange={(e) => {
                        handleChange({
                            ...trailerCargoType,
                            compartmentCount: parseInt(e.target.value)
                        }, index);
                    }}
                    InputProps={{ disableUnderline: true, inputProps: { min: 1 } }} />
                <Tooltip title={L.delete()}>
                    <IconButton onClick={() => handleDelete(index)} size='small'>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </ListItem>
            <Divider/>
        </React.Fragment>
    );
};

const renderAddTrailerCargoType = (handleAdd, disabled) => {
    if (disabled) {
        return null;
    }

    return (
        <ListItem dense key={999}>
            <ListItemText primary={L.trailerCargoTypesAddPrompt()}/>
            <Tooltip title={L.add()}>
                <IconButton onClick={handleAdd} size='small'>
                    <AddIcon/>
                </IconButton>
            </Tooltip>
        </ListItem>
    );
};

export const EditTrailerCargoType = props => {
    const { trailerCargoTypes = [], onChange } = props;
    const { data: cargoTypes, isLoading } = useCargoTypes();

    const cargoFilterSelector = React.useCallback((cargoTypeId = null) => (cargoType) => {
        return !trailerCargoTypes?.some(trailerCargoType => trailerCargoType.cargoTypeId === cargoType.id) || cargoType.id === cargoTypeId;
    }, [trailerCargoTypes]);

    const filteredCargoTypes = cargoTypes?.filter(cargoFilterSelector());

    const handleChange = (value, index) => {
        const changed = [...trailerCargoTypes];
        changed[index] = value;
        onChange(changed);
    };

    const handleDelete = (index) => {
        onChange(trailerCargoTypes.filter((trailerCargoType, i) => i !== index));
    };

    const handleAdd = () => {
        onChange(trailerCargoTypes.concat({ domKey: Math.random(), cargoTypeId: filteredCargoTypes[0].id, compartmentCount: 1 }));
    };

    const toSkeletonItem = (x) => {
        return (
            <ListItem dense key={x} data-testid={`loading-cargo-row-${x}`}>
                <Skeleton width={'100%'}/>
            </ListItem>);
    };
    const toRowItem = (trailerCargoType, i) => {
        return renderTrailerCargoTypeRow(trailerCargoType, cargoFilterSelector, i, handleChange, handleDelete);
    };

    return (
        <>
            <Typography variant='h6'>{L.cargo()}</Typography>
            <List>
                {isLoading ? Array.from(Array(3), (x, i) => i).map(toSkeletonItem) : trailerCargoTypes.map(toRowItem)}
                {!isLoading && renderAddTrailerCargoType(handleAdd, Boolean((trailerCargoTypes.length >= cargoTypes.length)))}
            </List>
        </>
    );
};

EditTrailerCargoType.propTypes = {
    trailerCargoTypes: PropTypes.array,
    onChange: PropTypes.func
};

