import React from 'react';
import ReportProblemOutlined from '@mui/icons-material/ReportProblemOutlined';
import { Tooltip } from '@mui/material';

interface AgisticsWarningIconProps {
    message: string;
}

export const AgisticsWarningIcon: React.FC<AgisticsWarningIconProps> = (props) => {
    const { message } = props;

    return (
        <Tooltip title={message}>
            <ReportProblemOutlined
                sx={{ marginRight: '.5em', fontSize: '1em', color: '#d32f2f' }}
            />
        </Tooltip>
    );
};
