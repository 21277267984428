import React from 'react';
import PropTypes from 'prop-types';

export const Error = ({ error, errorInfo }) => {
    return (
        <div style={{ margin: '0 2rem 0 2rem' }}>
            <h3>An unexpected error has occurred.</h3>
            <p>Please contact development at helpdesk@dptechlink.com for assistance.</p>
            <details style={{ whiteSpace: 'pre-wrap' }}>
                {error?.toString()}
                <br/>
                {errorInfo?.componentStack}
            </details>
        </div>
    );
};

Error.propTypes = {
    error: PropTypes.object,
    errorInfo: PropTypes.object
};
