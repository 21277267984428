import React from 'react';
import { styled } from '@mui/material/styles';
import ProductionPreviewTable from './production-preview-table';
import { L } from 'harmony-language';
import LoadingButton from '../../shared/loading-button';
import Save from '@mui/icons-material/Save';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { DistributionMode, useProductionPreview } from './use-production-preview';
import { useConvertedUnitWeight } from '../../user/selectors/use-converted-unit';
import { AgisticsDatePicker } from '../../shared/inputs/agistics-date-picker';
import { localNow } from '../../../utils/date-time-utils';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '40vh',
    width: '85vw',
    justifyContent: 'space-between'
});

const TitleBar = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem 0'
});

const Footer = styled('div')({
    padding: '1rem',
    display: 'flex',
    justifyContent: 'flex-end'
});

interface ProductionPreviewProps {
    productionCardIds: number[],
    onGenerateSuccess: () => void;
    cargoTypeId: number;
}

const ProductionPreview: React.FC<ProductionPreviewProps> = (props) => {
    const { productionCardIds, onGenerateSuccess } = props;
    const [mode, setMode] = React.useState<DistributionMode>('greedy');
    const { isLoading, drafts, setDrafts, generate, trailerProfile } = useProductionPreview(mode, productionCardIds);
    const { result: maxCargoWeight, unitTranslation } = useConvertedUnitWeight(trailerProfile?.maxCargoWeight);
    const [dateOverride, setDateOverride] = React.useState<string>(localNow().toISOString());

    function changeLoadDates(newDate: string) {
        const loadsWithNewDate = drafts.map(draft => {
            return {...draft, date: newDate}
        })
        setDrafts(loadsWithNewDate);
        setDateOverride(newDate);
    }

    return (
        <Container>
            <div>
                <TitleBar>
                    <div style={{ flex: 4 }}>
                        <Typography
                            variant={'h5'}>{L.productionPlanningPreviewTitle(`${drafts.length}`)}</Typography>
                        <Typography variant={'h6'}>{L.productionPlanningPreviewMessage()}</Typography>
                    </div>
                    <div style={{ flex: 3 }}>
                        <Typography variant={'h6'}>{L.trailerProfile()}</Typography>
                        <div>{`${L.maxCargoWeight()}: ${Math.round(maxCargoWeight)} ${unitTranslation}`}</div>
                        <div>{`${L.compartments()}: ${trailerProfile?.compartmentCount || 0}`}</div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <FormControlLabel
                            control={<Switch checked={mode === 'greedy'}
                                             onChange={({ target: { checked } }) => {
                                                 setMode(checked ? 'greedy' : 'equitable');
                                             }}
                                             name={L.productionDistributionMode()}/>}
                            label={mode === 'greedy' ? L.productionDistributionGreedy() : L.productionDistributionEquitable()}
                        />
                    </div>
                    <AgisticsDatePicker
                        value={dateOverride}
                        onChange={changeLoadDates}
                        label={L.changeDateValue()}
                    />
                </TitleBar>
                <ProductionPreviewTable drafts={drafts} isLoading={isLoading}/>
            </div>
            <Footer>
                <LoadingButton isLoading={isLoading} icon={<Save/>} color={'primary'}
                               onClick={async () => {
                                   await generate(drafts);
                                   onGenerateSuccess();
                               }}
                               variant='contained'>{L.generate()}</LoadingButton>
            </Footer>
        </Container>
    );
};

export default ProductionPreview;
