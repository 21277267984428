import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { L } from 'harmony-language';
import { getTransLocationType } from '../../../../constants/constants';

const getTotalLoads = (loads) => {
    return loads?.sum(site => site.sum(day => parseInt(day) || 0));
};

const getSitesWithLoads = (loads) => {
    return loads?.sum(site => {
        const siteSum = site.sum(day => parseInt(day) || 0);

        return siteSum > 0 ? 1 : 0;
    });
};

export const BulkEntryTitleBar = (props) => {
    const { siteType, loads, previewLoads } = props;

    return (
        <div className='bulk-title-bar justify-between'>
            {getTotalLoads(loads) > 0 &&
            <h4>{L.bulkEntryPreview(getTotalLoads(loads), getSitesWithLoads(loads), getTransLocationType(siteType).label)}</h4>}
            {getTotalLoads(loads) === 0 && <div/>}
            <div className='bulk-title-bar-action'>
                <Button className='action-button' variant='contained' color='primary' onClick={previewLoads}
                    disabled={getTotalLoads(loads) <= 0}>{L.previewLoads()}</Button>
            </div>
        </div>
    );
};

BulkEntryTitleBar.propTypes = {
    siteType: PropTypes.object,
    loads: PropTypes.array,
    previewLoads: PropTypes.func
};
