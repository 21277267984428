import React from 'react';
import { useStaticQuery } from '../../../api/queries/use-static-query';
import { QueryKeys } from '../../../api/config';
import { useAddCompanyMutation } from '../../../api/mutations/add/use-add-company-mutation';
import { useEditCompanyMutation } from '../../../api/mutations/edit/use-edit-company-mutation';
import { EditCreateCompany } from './edit-create-company/edit-create-company';
import { useCompanyColumns } from './use-company-columns';
import { L } from 'harmony-language';
import { AdminBreadCrumbHeader } from '../admin-bread-crumb-header';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import { ModalDialog } from '../../shared/containers/modal-dialog';
import { Content } from '../../shared/containers/content';
import { FeatureManagement } from './features/feature-management';
import { styled } from '@mui/material/styles';
import { CargoTypeConfigManagement } from './cargo-type-configs/cargo-type-management';
import { ResourceTable } from '../../shared/resource-table/resource-table';
import { CarrierManagement } from './carrier-configs/carrier-management';

const SettingsContainer = styled('div')({
    maxWidth: '50vw',
    minWidth: '20rem',
    width: '100%'
});

export const CompanyManagement = () => {
    const { data: companies, isLoading } = useStaticQuery(QueryKeys.companies);
    const { mutate: addCompany, isLoading: isCreating } = useAddCompanyMutation();
    const { mutate: editCompany, isLoading: isUpdating } = useEditCompanyMutation();

    const [modalState, setModalState] = React.useState(null);

    const closeModal = React.useCallback(() => {
        setModalState(null);
    }, [setModalState]);

    const [company, setCompany] = React.useState(null);

    const actions = React.useMemo(() => [
        {
            icon: 'edit',
            tooltip: L.edit(),
            action: (row) => {
                setCompany(row);
                setModalState('edit-create');
            }
        },
        {
            icon: 'lock_open',
            tooltip: L.features(),
            action: (row) => {
                setCompany(row);
                setModalState('features');
            }
        },
        {
            icon: 'local_shipping',
            tooltip: L.cargo(),
            action: (row) => {
                setCompany(row);
                setModalState('cargo');
            }
        },
        {
            id: 'carrier',
            icon: 'assignment_returned',
            tooltip: L.carrierSetup(),
            action: (row) => {
                setCompany(row);
                setModalState('carrier');
            }
        },
    ], []);


    const columns = useCompanyColumns(actions);

    return (
        <>
            <AdminBreadCrumbHeader hideResourceDropDown hideCompanyDropDown>
                <Button variant='contained' color='primary' onClick={() => {
                    setCompany(null);
                    setModalState('edit-create');
                }}><Add/> {L.createCompany()}</Button>
            </AdminBreadCrumbHeader>
            <ModalDialog open={modalState === 'edit-create'} onClose={closeModal} title={L.manageCompany()}>
                <SettingsContainer>
                    <EditCreateCompany
                        existingCompany={company}
                        onSubmit={async (newData) => {
                            if (newData.id) {
                                editCompany({ company: newData });
                            } else {
                                addCompany({ company: newData });
                            }
                            closeModal();
                        }}/>
                </SettingsContainer>
            </ModalDialog>
            <ModalDialog open={modalState === 'features'} onClose={closeModal} title={`${L.features()} - ${company?.name}`}>
                {Boolean(company?.id) && <FeatureManagement companyId={company.id}/>}
            </ModalDialog>
            <ModalDialog open={modalState === 'cargo'} onClose={closeModal} title={`${L.cargo()} - ${company?.name}`}>
                {Boolean(company) && <CargoTypeConfigManagement companies={companies} company={company} onSubmit={closeModal} />}
            </ModalDialog>
            <ModalDialog open={modalState === 'carrier'} onClose={closeModal} title={`${L.carrier()} - ${company?.name}`}>
                {Boolean(company) && <CarrierManagement organizations={companies} organization={company} onSubmit={closeModal} />}
            </ModalDialog>
            <Content>
                <ResourceTable
                    id='company-managemnt'
                    title={L.companies()}
                    isLoading={isLoading || isUpdating || isCreating}
                    columns={columns}
                    data={companies || []}
                    options={React.useMemo(() => ({
                        pageSize: 10,
                    }), [])}
                />
            </Content>
        </>
    );
};
