import React from 'react';
import { L } from 'harmony-language';
import { Select } from './select';
import { UnitsWeight } from 'harmony-constants';

interface SelectUnitsWeightProps {
    unitsWeight: UnitsWeight | null;
    onChange: (s: UnitsWeight) => void;
    disabled?: boolean;
    className?: string;
}

export interface SelectUnitsWeightMenuItem {
    id: UnitsWeight;
    value: UnitsWeight;
    label: string;
}

export const SelectUnitsWeight: React.FC<SelectUnitsWeightProps> = (props) => {
    const { unitsWeight, onChange, disabled, className } = props;

    const menuItems: SelectUnitsWeightMenuItem[] = [
        { id: UnitsWeight.USTon, value: UnitsWeight.USTon, label: L.unitsWeightUsTon() },
        { id: UnitsWeight.Pound, value: UnitsWeight.Pound, label: L.unitsWeightPound() },
        { id: UnitsWeight.MetricTon, value: UnitsWeight.MetricTon, label: L.unitsWeightMetricTon() },
        { id: UnitsWeight.Kilogram, value: UnitsWeight.Kilogram, label: L.unitsWeightKilogram() },
    ]

    const item = menuItems.find(x => x.value === unitsWeight);

    return (
        <Select
            className={className}
            disabled={disabled}
            label={L.selectUnits()}
            list={menuItems}
            item={item}
            onChange={(v: SelectUnitsWeightMenuItem) => onChange(v.value)} />
    );
};
