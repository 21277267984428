import io from 'socket.io-client';

let socket = null;

export const getSocket = () => {
    if (!socket) {
        socket = io.connect(API_BASE, { transports: ['websocket'] });
    }
    return socket;
};
