import axios from 'axios';
import { useSnackbar } from '../../components/notifications/use-snackbar';
import { L } from 'harmony-language';
import { useQueryClient } from '@tanstack/react-query';
import { OrgQueryKeys } from '../config';

export type NotificationPreference = {
    notificationEventTypeId: number,
    notificationType: 'Text' | 'Email' | 'Push'
}

export const useEventNotificationPreferences = (companyId: number, userId: number) => {
    const snackbar = useSnackbar();
    const queryClient = useQueryClient();

    return {
        get: async (): Promise<NotificationPreference[]> => {
            return (await axios.get(`${API_BASE}/api/organizations/${companyId}/contacts/${userId}/notification-preferences`)).data
        },
        update: async (notificationConfigs: NotificationPreference[]) => {
            const result = (await axios.post(`${API_BASE}/api/organizations/${companyId}/contacts/${userId}/notification-preferences`, notificationConfigs)).data
            await queryClient.invalidateQueries([`/organizations/${companyId}${OrgQueryKeys.contacts}`]);
            snackbar(L.eventNotificationPreferenceSuccess());

            return result;
        }
    }
}
