import React from 'react';
import { styled } from '@mui/material/styles';
import { L } from 'harmony-language';
import { useBoolean } from '../shared/hooks/use-boolean';
import { ModalDialog } from '../shared/containers/modal-dialog';
import { NotificationPreference, useEventNotificationPreferences } from '../../api/queries/use-event-notification-preferences';
import { Button, Checkbox } from '@mui/material';
import { NotificationEventTypes } from 'harmony-constants';
import { notificationEventTypeTranslationHelper } from '../company-management/notification-configs/name-helpers';

const HeaderLabels = styled('div')({
    "& > div:nth-child(n+2):nth-child(-n+7)": {
        textAlign: 'center'
    }
});

const SkipFirstColumn = styled('div')({
    gridColumnStart: 2
});

interface UserNotificationPreferencesProps {
    contactId: number,
    organizationId: number,
    closePreferences: () => void;
}

export const UserNotificationPreferences: React.FC<UserNotificationPreferencesProps> = props => {
    const { contactId, organizationId, closePreferences } = props;
    const [isLoading, loading, done] = useBoolean(false);
    const [userPrefs, setUserPrefs] = React.useState<NotificationPreference[]>([]);
    const [systemPrefs, setSystemPrefs] = React.useState<NotificationPreference[]>([]);
    const {get: getNotificationPrefs, update: updateNotificationPrefs} = useEventNotificationPreferences(organizationId, contactId);

    const userNotificationEventTypes = Object.values(NotificationEventTypes)
                                             .filter(x => !x.system && !x.nyi) //filter out system event types

    React.useEffect(() => {
        (async () => {
            loading();
            const response = await getNotificationPrefs();
            setUserPrefs(response.filter(x => !Object.values(NotificationEventTypes).find(y => y.id === x.notificationEventTypeId)?.system))
            setSystemPrefs(response.filter(x => Object.values(NotificationEventTypes).find(y => y.id === x.notificationEventTypeId)?.system)) 
            done();
        })();
    }, []);

    const notificationPrefsChanged = async () => {
        loading();
        await updateNotificationPrefs([...systemPrefs, ...userPrefs])
        closePreferences()
        done();
    }

    type NotificationType = 'Text' | 'Email' | 'Push'

    const toggleColumn = (notificationType: NotificationType) => {
        if (userPrefs.filter(x => x.notificationType === notificationType).length === userNotificationEventTypes.length) {
            //already all on, toggle all off
            setUserPrefs([...userPrefs.filter(x => x.notificationType !== notificationType)])
        } else {
            //toggle all on
            const newItems = userNotificationEventTypes.map(x => ({notificationEventTypeId: x.id, notificationType}));
            setUserPrefs([...userPrefs.filter(x => x.notificationType !== notificationType), ...newItems])
        }
    }
    
    const prefExists = (notificationTypeId: number, notificationType: NotificationType) => {
        return Boolean(userPrefs.find(x => x.notificationEventTypeId === notificationTypeId &&
                                       x.notificationType === notificationType ));
    };

    const toggleCheckbox = (notificationTypeId: number, notificationType: NotificationType) => {
        const index = userPrefs.findIndex(x => x.notificationEventTypeId === notificationTypeId &&
                                           x.notificationType === notificationType );
        if (index === -1) {
            setUserPrefs([...userPrefs, {notificationEventTypeId: notificationTypeId, notificationType}]);
        } else {
            setUserPrefs([...userPrefs.slice(0, index), ...userPrefs.slice(index + 1)]);
        }
    }

    return (
        !isLoading ? <ModalDialog title={L.notifications()} open={true} onClose={closePreferences}>
            <form id='edit-notification-prefs-form' name='edit-notification-prefs-form'>
                <HeaderLabels style={{ display: 'grid', gridTemplateColumns: '10fr 3fr 3fr 3fr', width: '700px', alignItems: 'center' }}>
                    <div>{L.event()}</div>
                    <div>{L.text()}</div>
                    <div>{L.email()}</div>
                    <div>{L.inApp()}</div>
                    <SkipFirstColumn><Button variant='outlined' onClick={() => toggleColumn('Text')} size="small">{L.toggleAll()}</Button></SkipFirstColumn>
                    <div><Button variant='outlined' onClick={() => toggleColumn('Email')} size="small">{L.toggleAll()}</Button></div>
                    <div><Button variant='outlined' onClick={() => toggleColumn('Push')} size="small">{L.toggleAll()}</Button></div>
                    {userNotificationEventTypes.map(x => {
                        return <React.Fragment key={x.id}>
                            <div>{notificationEventTypeTranslationHelper(x.id)}</div>
                            <Checkbox id={`notification-text-${x.id}`} onClick={() => toggleCheckbox(x.id, 'Text')} checked={prefExists(x.id, 'Text')} />
                            <Checkbox id={`notification-email-${x.id}`} onClick={() => toggleCheckbox(x.id, 'Email')} checked={prefExists(x.id, 'Email')} />
                            <Checkbox id={`notification-push-${x.id}`} onClick={() => toggleCheckbox(x.id, 'Push')} checked={prefExists(x.id, 'Push')} />
                        </React.Fragment>
                    })}
                </HeaderLabels>
                <Button variant='contained' color='primary' onClick={notificationPrefsChanged}>{L.save()}</Button>
            </form>
        </ModalDialog> : <></>
    );
};
