import React from 'react';
import { STOP_TYPES } from '../../constants/constants';
import { L } from 'harmony-language';
import { toStopDisplayName } from '../../utils/data-mapping-utils';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../api/config';
import { Stop } from '../../types';

export const LiveMapStopStatus: React.FC<{stop?: Stop }> = ({ stop }) => {
    const { data: organizationLocations } = useOrganizationQuery(OrgQueryKeys.locations);

    if (!stop) {
        return null;
    }
    const label = STOP_TYPES()[stop.type].label;
    const message = L[`stopStatusMsg${stop.status}` as keyof typeof L](label)

    return (
        <>
            <span>{message}</span>
            <span>{toStopDisplayName(organizationLocations)(stop)}</span>
        </>
    );
};
