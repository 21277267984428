import { checkIfDateRangesOverlap, isBefore, local, utc } from '../../../utils/date-time-utils';
import { DAYSOFWEEK_BITMASK } from '../../../constants/constants';
import moment from 'moment-timezone';

function* generateRecurringDowntimes(downtime: any, searchDate: any) {
    // Bump out the search a week in both directions to catch wraparounds
    const searchDateBegin = moment(searchDate.begin).add(-1, 'weeks');
    const searchDateEnd = moment(searchDate.end).add(1, 'weeks');
    const daysOfWeek = parseInt(downtime.recurrenceDaysOfWeek, 2);

    while (searchDateBegin < searchDateEnd) {
        //find out if the iterated day's day of week matches a day in the recurring schedule
        const dayBitValue = 2 ** (6 - (searchDateBegin.day() % 7));

        if ((daysOfWeek & dayBitValue) === dayBitValue) {
            const start = moment.tz(`${searchDateBegin.format("YYYY-MM-DD")} ${downtime.startTime}`, downtime.timezone);
            console.log("Generating", `${searchDateBegin.format("YYYY-MM-DD")} ${downtime.startTime}`)
            yield {
                ...downtime,
                begin: start.utc().toISOString(),
                end: start.clone().add(downtime.durationMinutes, 'minutes').utc().toISOString(),
            };
        }
        searchDateBegin.add(1, 'days');
    }
}

export const downtimeToStartEnd = (downtime) => {
    if (downtime.begin && downtime.end) {
        //begin and end have already been computed or overridden
        return downtime;
    }
    console.log("DTSE downtime", downtime);
    const startInstant = moment.tz(`${downtime.startDate} ${downtime.startTime}`, downtime.timezone);
    const endInstant = startInstant.clone().add(downtime.durationMinutes, 'minutes');
    //console.log("S", startInstant, "E", endInstant)
    return {
        ...downtime,
        begin: startInstant.utc().toISOString(),
        end: endInstant.utc().toISOString(),
    }
}

export const filterForConflicts = (downtimes, dateRange) => {
    if (!dateRange) {
        return []; // return empty array
    }
    return downtimes.reduce((acc, downtime) => {
        const normalizedDowntime = downtimeToStartEnd(downtime);
        if (!parseInt(downtime.recurrenceDaysOfWeek, 2)) {
            // filter for one-times
            console.log("one-time downtime", normalizedDowntime, "dateRange", dateRange)
            
            if (checkIfDateRangesOverlap(dateRange, normalizedDowntime)) {
                return [...acc, normalizedDowntime]
            }
        } else {
            // filter for recurring
            // If downtime begin has not started yet then this downtime does not apply
            if (isBefore(dateRange.end, normalizedDowntime.begin)) {
                return acc;
            }
            
            for (const recurringDowntime of generateRecurringDowntimes(downtime, dateRange)) {
                console.log("checking for overlap:", dateRange, recurringDowntime)
                if (checkIfDateRangesOverlap(dateRange, recurringDowntime)) {
                    //return a hit on the downtime, but adjust the dates to match the recurrence that hit
                    return [...acc, {...recurringDowntime, begin: recurringDowntime.begin, end: recurringDowntime.end}]
                }
            }
        }
        return acc;
    }, []);
};

export const mapDaysOfWeekToArray = (daysOfWeek) => {
    let mask = daysOfWeek;

    if (typeof daysOfWeek === 'string') {
        mask = parseInt(daysOfWeek, 2);
    }

    //TODO: No translations have been provided for these weekday names & abbreviations
    const weekDays = moment.weekdays();
    const weekDaysMin = moment.weekdaysMin();

    return Object.values(DAYSOFWEEK_BITMASK).map((value, i)=>{
        return {
            name: weekDays[i],
            abbr: weekDaysMin[i],
            value: value,
            selected: (mask & value) !== 0
        };
    });
};