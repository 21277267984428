import React, { Dispatch, SetStateAction } from 'react';
import { ProductionCardType } from './production-card';
import { useProductionCards } from '../use-production-cards';
import { getWorkingWeek } from '../../../utils/date-time-utils';
import { DragOverEvent, UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

export type BucketId = 'day-bucket' | 'generation-bucket';

export type BucketState = {
    isLoading: boolean,
    dateRange: { from: string, to: string },
    setDateRange: Dispatch<SetStateAction<{ from: string, to: string }>>,
    clear: () => void;
    dayBucket: ProductionCardType[];
    generationBucket: ProductionCardType[];
    handleOnDragOver: (event: DragOverEvent) => void;
    findContainer: (id: UniqueIdentifier) => BucketId | null;
}

type InternalState = Record<string, Record<BucketId, ProductionCardType[]>>

export const useGenerationBuckets = (cargoTypeId: number, options: { initialGenerationData?: ProductionCardType[] } = {}): BucketState => {
    const [dateRange, setDateRange] = React.useState(() => {
        const week0 = getWorkingWeek(0);
        return {
            from: week0.sundayIso,
            to: week0.saturdayIso,
        }
    });
    const [state, setState] = React.useState<InternalState>({});

    const { data: cards, isLoading } = useProductionCards(cargoTypeId, dateRange);
    const key = cargoTypeId;

    React.useEffect(() => {
        if (cards) {
            setState((s) => {
                if (s[key]) {
                    const existingCards = [...s[key]['day-bucket'], ...s[key]['generation-bucket']]
                    const newCards = cards.filter(card => {
                        return !existingCards.find(x => x.id === card.id);
                    });

                    return {
                        ...s,
                        [key]: {
                            'day-bucket': [...s[key]['day-bucket'].filter(card => cards.find(x => x.id === card.id)), ...newCards],
                            'generation-bucket': [...s[key]['generation-bucket'].filter(card => cards.find(x => x.id === card.id))]
                        }
                    };
                } else {
                    return {
                        ...s,
                        [key]: {
                            'day-bucket': [...cards],
                            'generation-bucket': options.initialGenerationData ?? [] // for testing mostly..
                        }
                    };
                }
            });
        }
    }, [cards]);

    const findContainer = (id: UniqueIdentifier): BucketId | null => {
        if (typeof id === 'number') {
            const dayFound = state[key]['day-bucket'].find(x => x.id === id);

            if (dayFound) {
                return 'day-bucket';
            }

            const generationFound = state[key]['generation-bucket'].find(x => x.id === id);
            if (generationFound) {
                return 'generation-bucket';
            }

            return null;
        }

        return id === 'day-bucket' ? 'day-bucket' : id === 'generation-bucket' ? 'generation-bucket' : null;
    }

    const handleOnDragOver = (event: DragOverEvent) => {
        const { active, over } = event;
        const overId = over?.id;

        if (overId === undefined) {
            return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
            return;
        }

        if (activeContainer !== overContainer) {
            const activeItems = state[key][activeContainer];
            const activeIndex = activeItems.findIndex(x => x.id === active.id);

            const overItems = state[key][overContainer];
            const overIndex = overItems.findIndex(x => x.id === overId);

            const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top >
                    over.rect.top + over.rect.height;

            const modifier = isBelowOverItem ? 1 : 0;

            const newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length;

            const card = activeItems.find(x => x.id === active.id);

            if (card) {
                const newActiveItems = [...activeItems];
                newActiveItems.splice(activeIndex, 1);
                const newOverItems = [...overItems];
                newOverItems.splice(newIndex, 0, card);
                setState({
                    ...state,
                    [key]: {
                        ...state[key],
                        [activeContainer]: newActiveItems,
                        [overContainer]: newOverItems,
                    }
                });
            }
        } else if (activeContainer === overContainer) {
            const activeItems = state[key][activeContainer];
            const activeIndex = activeItems.findIndex(x => x.id === active.id);
            const overIndex = activeItems.findIndex(x => x.id === overId);

            const newActiveItems = arrayMove(activeItems, activeIndex, overIndex);

            setState({
                ...state,
                [key]: {
                    ...state[key],
                    [activeContainer]: newActiveItems,
                }
            });
        }
    }

    const clear = () => {
        setState({
            ...state,
            [key]: {
                'day-bucket': [...state[key]['day-bucket'], ...state[key]['generation-bucket']],
                'generation-bucket': []
            }
        });
    };

    return {
        dateRange,
        isLoading,
        setDateRange,
        clear,
        dayBucket: state[key]?.['day-bucket'] ? state[key]['day-bucket'] : [],
        generationBucket: state[key]?.['generation-bucket'] ? state[key]['generation-bucket'] : [],
        handleOnDragOver,
        findContainer,
    };
};
