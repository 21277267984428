import React from 'react';
import PropTypes from 'prop-types';
import { parsePhoneNumber } from 'libphonenumber-js';
import { getCountryCodeFromLanguage, getCurrentLanguage } from 'harmony-language';


export const PhoneNumber = (props) => {
    const { className, phone } = props;
    const countryCode = getCountryCodeFromLanguage(getCurrentLanguage());
    const phoneNumber = phone ? parsePhoneNumber(phone, countryCode) : null;

    return <span className={className}>{phoneNumber?.formatInternational?.()}</span>;
};

PhoneNumber.propTypes = {
    className: PropTypes.string,
    phone: PropTypes.string
};
