import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { mutationOptions, QueryKeys } from '../../config';

const editPreferences = (userId: number) => {
    return async (preferences: any) => {
        return (await axios.put(`${API_BASE}/api/users/${userId}/preferences`, { preferences: preferences })).data;
    }
};

export const useEditUserPreferences = (userId: number) => {
    const key = QueryKeys.self;
    const queryClient = useQueryClient();

    return useMutation(editPreferences(userId), {
        ...mutationOptions([key], queryClient),
    });
};
