import Tooltip from '@mui/material/Tooltip';
import { TravelTimeTooltip } from './travel-time-tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import { getDuration } from '../../utils/date-time-utils';
import React from 'react';
import { orderToLoadingDurationMinutes } from '../../utils/data-mapping-utils';
import { Load } from '../../types';
import { useTravelTimesLoad } from './use-travel-times';
import { UNITS, getCurrentUnitSystem } from 'harmony-language';
import { metersToKm, metersToMiles } from '../../utils/unit-conversion';

export const useTravelTimesTooltip = (load: Load): React.ReactNode => {
    const loadDurationMinutes = orderToLoadingDurationMinutes(load);
    const { travelTime, travelDistance, isLoading } = useTravelTimesLoad(load);
    const minuteMs = 1000 * 60;
    const roundedTotalTripMs = Math.ceil((travelTime + loadDurationMinutes) / 5) * 5 * minuteMs; // move to next 5 minute interval
    const isMetric = getCurrentUnitSystem() === UNITS.Metric;
    const convertedDistance = travelDistance ? isMetric ? metersToKm(travelDistance) : metersToMiles(travelDistance) : 0;

    return (
        <Tooltip
            title={
                <TravelTimeTooltip loadDurationMinutes={loadDurationMinutes}
                    convertedDistance={convertedDistance}
                    isMetric={isMetric}
                    travelTimeMinutes={travelTime} />
            }
            arrow
            placement='right'>
            {isLoading ?
                <CircularProgress data-testid={`travel-time-loading-${load.id}`} size='1em' /> :
                <div data-testid={`travel-time-value-${load.id}`}>
                    {(roundedTotalTripMs > 0) && getDuration(roundedTotalTripMs)}
                </div>
            }
        </Tooltip>
    )
}
