import React from 'react';
import { OrgQueryKeys } from '../../../../api/config';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { useTractorTrailer } from '../../../../api/queries/use-tractor-trailer';


const overridesDefault = {};

export const useLoadsTableOptions = (key, overrides = overridesDefault) => {
    const { tractors, trailers } = useTractorTrailer();
    const { data: drivers } = useOrganizationQuery(OrgQueryKeys.drivers);
    const { data: organizationLocations } = useOrganizationQuery(OrgQueryKeys.locations);

    return React.useMemo(() => ({
        tableKey: key,
        resources: {
            drivers, tractors, trailers, organizationLocations
        },
        sortBy: [{ id: 'load' }],
        ...overrides
    }), [key, drivers, tractors, trailers, organizationLocations, overrides]);
};
