import React from 'react';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import MuiTableFooter from '@mui/material/TableFooter';
import { L } from 'harmony-language';
import { TablePaginationActions } from './table-pagination-actions';
import { capitalizeFirst } from '../../../../utils/string-utils';
import { useUpdateUserPreferences } from '../../hooks/use-update-user-preferences';
import { Table } from '@tanstack/react-table';

const rowsPerPageOptions = [
    5,
    10,
    25,
];

interface AgisticsTableFooterProps {
    id: string;
    table: Table<any>;
}

export const AgisticsTableFooter: React.FC<AgisticsTableFooterProps> = (props) => {
    const { id, table } = props;
    const { preferences, updatePreferences } = useUpdateUserPreferences();

    const handlePageChange = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        table.setPageIndex(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const ps = Number(event.target.value);
        const newPrefs = {};

        // @ts-ignore
        newPrefs.tables = preferences?.tables ? { ...preferences.tables } : {};
        // @ts-ignore
        newPrefs.tables[id] = { pageSize: ps };
        updatePreferences(newPrefs);
        table.setPageSize(ps);
    };

    return (
        <MuiTableFooter>
            <TableRow>
                <TablePagination
                    labelRowsPerPage={capitalizeFirst(L.rows())}
                    rowsPerPageOptions={rowsPerPageOptions}
                    count={table.getRowCount()}
                    rowsPerPage={table.getState().pagination.pageSize}
                    page={table.getState().pagination.pageIndex}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </TableRow>
        </MuiTableFooter>
    );
};
