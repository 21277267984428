import axios from 'axios';
import { setTimezone } from '../../../utils/date-time-utils';
import { fetchLanguageDictionary } from './fetch-language-dictionary';
import { StateUser } from '../../../types';
import { setCurrentUnitSystem } from 'harmony-language';

axios.defaults.withCredentials = true;

interface SessionRes {
    cookies: any;
    user: StateUser;
    userId: number;
}

export const validateSession = async (updateCompanyId: (n: number) => void) => {
    try {
        const sessionResponse = await axios.get<SessionRes>(`${API_BASE}/api/session`);

        if (sessionResponse.data.user) {
            const user = sessionResponse.data.user;
            updateCompanyId(user.organizationId);

            setTimezone(user.contact.timezone || user.organization.timezone);
            await fetchLanguageDictionary(user.contact.language || user.organization.language);
            setCurrentUnitSystem(user.contact.units || user.organization.units);
            return true;
        } else {
            return false;
        }
    } catch (err) {
        return false;
    }
}
