import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import PropTypes from 'prop-types';
import React from 'react';

export const ActionIcon = (props) => {
    const { action, icon, isDisabled, tooltip, testId, IconProps, ...rest } = props;
    const tooltipLabel = (typeof (tooltip) === 'string') ? tooltip : icon;

    return (
        <Tooltip
            title={tooltip}
            placement='bottom'
            slotProps={{ tooltip: { sx: { fontSize: '0.6875rem' }}}}
        >
            <div style={{ textAlign: 'center' }}>
                <IconButton data-testid={`action-${tooltipLabel}-${testId}`}
                    sx={{ padding: '3px' }}
                    size='small'
                    disabled={isDisabled}
                    color='primary'
                    onClick={action}
                    {...rest}
                >
                    <Icon {...IconProps}>{icon}</Icon>
                </IconButton>
            </div>
        </Tooltip>
    );
};

ActionIcon.propTypes = {
    icon: PropTypes.string,
    IconProps: PropTypes.object,
    isDisabled: PropTypes.bool,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    action: PropTypes.func,
    testId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: PropTypes.object
};
