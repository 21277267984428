import React from 'react';
import { useLoadContext } from '../load-context';
import {
    filterStopByDestination,
    filterStopByOrigin,
    loadToFirstStop,
    loadToLastStop,
    orderToCargoQuantities,
    orderToLastCompleted,
    orderToLotIds,
    orderToOrderNumbers,
    toDriverDisplayName,
    toIdLabelTuple,
    toTractorDisplayName,
    toTrailerDisplayName
} from '../../../utils/data-mapping-utils';
import { Driver, Load, OrganizationCarrier, Stop, WashOption } from '../../../types';
import { ORDER_STATUSES, WASH_OPTIONS } from '../../../constants/constants';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { useTractorTrailer } from '../../../api/queries/use-tractor-trailer';
import { getDuration, localDateTimeDisplay } from '../../../utils/date-time-utils';
import { ResourceConflictContainer } from '../resource-conflicts/resource-conflict-container';
import { useTravelTimesTooltip } from '../../travel-times/use-travel-times-tooltip';
import { SalmonellaMeter } from '../salmonella-meter';
import { Item, ResourceSelect } from '../inputs/resource-select';
import { MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import { useLocationInfo } from '../hooks/use-location-info'
import { L } from 'harmony-language';
import { carrierResourceSettings } from './utils/load-utils';
import { useCarrier, useFeatures } from '../../user/selectors/use-permissions';
import { Features, OrderStatus } from 'harmony-constants';
import { useWeights } from '../hooks/use-weights';
import { useTravelTimesLoad } from '../../travel-times/use-travel-times';
import { UnmergeButton } from '../../drafts/unmerge/unmerge-button';
import { getConvertedDistance } from '../../../utils/distance-utils';

interface LoadDateProps {
    hideTravelTimes?: boolean
}
export const LoadDate: React.FC<LoadDateProps> = (props = {hideTravelTimes: false}) => {
    const { load } = useLoadContext();
    const travelTimesTooltip = useTravelTimesTooltip(load);
    const orderNumbers = [...new Set(load.stops.map(s => s.orderNumber).filter(Boolean))] as string[]
    const hasUnmergeFeature = useFeatures(Features.UnmergeDrafts);
    const canUnmerge = hasUnmergeFeature && Boolean(load.mergedFromOrderIds) && load.isDraft && load.id;

    return (
        <div style={{ minHeight: (props.hideTravelTimes ? '33px' : '48px'), justifyContent: 'center', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            {
                orderNumbers.length
                ? orderNumbers.map(orderNumber => <div title={load?.id ? load.id.toString() : orderNumber} style={{textOverflow: 'ellipsis', overflow: 'hidden'}} key={orderNumber}>{orderNumber}</div>)
                : load.id && <div>#{load.id}</div>
            }
            <div style={{ whiteSpace: 'nowrap' }}>{localDateTimeDisplay(load.date)}</div>
            {!props.hideTravelTimes && travelTimesTooltip}
            {canUnmerge && <UnmergeButton load={load} />}
        </div>
    );
};

export const LoadDateNoTooltip: React.FC = () => {
    return <LoadDate hideTravelTimes={true} />
}

export const EstimatedDuration: React.FC = () => {
    const { load } = useLoadContext();
    const travelTimesTooltip = useTravelTimesTooltip(load);
    
    return (
        <div>
            {travelTimesTooltip}
        </div>
    );
};

export const EstimatedDistance: React.FC = () => {
    const { load } = useLoadContext();
    const { travelDistance } = useTravelTimesLoad(load);
    const { convertedDistance, abbreviation } = getConvertedDistance(travelDistance);

    return (
        <div>{convertedDistance && `${convertedDistance} ${abbreviation}`}</div>
    );
};

export const ActualDuration: React.FC = () => {
    const { load } = useLoadContext();

    return (
        load.timeSeconds ? <div>{getDuration(load.timeSeconds * 1000)}</div> : <div />
    )
}

export const ActualDistance: React.FC = () => {
    const { load } = useLoadContext();
    const { convertedDistance, abbreviation } = getConvertedDistance(load.distanceMeters);

    if (convertedDistance) {
        return <div>{convertedDistance} {abbreviation}</div>
    } else {
        return <div />
    }
}

const LoadStopTime: React.FC<{ time: Stop }> = (props) => {
    const { time } = props;
    if (!time) {
        return null;
    }
    return <div style={{ whiteSpace: 'nowrap' }}>{localDateTimeDisplay(time)}</div>;
};

export const LoadFirstStop: React.FC = () => {
    const { load } = useLoadContext();
    const firstStop = loadToFirstStop(load);
    return <LoadStopTime time={firstStop?.arrivalTime} />;
};

export const LoadLastStop: React.FC = () => {
    const { load } = useLoadContext();
    const lastStop = loadToLastStop(load);
    return <LoadStopTime time={lastStop?.arrivalTime} />;
};

export const LoadLastCompleted: React.FC = () => {
    const { load } = useLoadContext();
    if (load.status !== OrderStatus.Delivered) {
        return null;
    }

    const lastCompletedStop = orderToLastCompleted(load);
    return <LoadStopTime time={lastCompletedStop?.completedAt} />;
};

const LoadStopLocation: React.FC<{ stop: Stop, displaySubSite?: boolean, stops: Stop[] }> = (props) => {
    const { stop, displaySubSite, stops } = props;
    const locId = stop.organizationLocationId as number;
    const { toSiteDisplayName, toSublocationDisplay, toAddressDisplay, cargoTypeIds } = useLocationInfo(locId, stop.type);

    const stopsWithSublocations = stops.filter(stop => stop.organizationSubLocationIds && stop.organizationSubLocationIds.length);
    const sublocationDisplay = toSublocationDisplay(stopsWithSublocations);
    const addressDisplay = toAddressDisplay();
    const tooltipDisplay = <div>
                            <Typography>{L.address()}</Typography>
                            {addressDisplay}
                            {sublocationDisplay ? <><hr/><Typography>{L.subLocations()}</Typography><Typography style={{fontSize:'smaller'}}>{sublocationDisplay}</Typography></> : <></>}
                            </div>

    return (
        <ResourceConflictContainer resource={{
            id: locId,
            type: 'location',
            cargoTypeIds,
            stop
        }}>
            <Tooltip
                title={<span style={{ fontSize: '.875rem' }}> {tooltipDisplay} </span>}
                arrow
                placement='right'>
                <div>{toSiteDisplayName(displaySubSite ? stop.organizationSubLocationIds : null)}</div>
            </Tooltip>
        </ResourceConflictContainer>
    );
};

const LoadLocation: React.FC<{ stops: Stop[], displaySubSite?: boolean, groupSublocations: boolean }> = (props) => {
    const { stops, displaySubSite, groupSublocations } = props;

    const distinctionFunc = groupSublocations ? (stop: Stop) => stop.organizationLocationId?.toString()
                                           : (stop: Stop) => stop.organizationLocationId + '|' + stop.organizationSubLocationIds;
    const uniqueStops = React.useMemo(() => {
        return stops.filter(stop => Boolean(stop.organizationLocationId)).distinctBy(distinctionFunc);
    }, [stops]);
    return (
        <div style={{ width: '100%' }}>{
            uniqueStops.map((stop: Stop) => <LoadStopLocation key={stop.organizationLocationId} stop={stop} displaySubSite={displaySubSite} stops={stops} />)
        }</div>
    );
};

export const LoadOrigin: React.FC<{ displaySubSite?: boolean, groupSublocations?: boolean }> = (props) => {
    const { displaySubSite, groupSublocations = true } = props;
    const { load } = useLoadContext();

    const origins = load.stops.filter(filterStopByOrigin);
    return <LoadLocation stops={origins} displaySubSite={displaySubSite} groupSublocations={groupSublocations} />;
};

export const LoadDestination: React.FC<{ displaySubSite?: boolean, groupSublocations?: boolean }> = (props) => {
    const { displaySubSite, groupSublocations = true } = props;
    const { load } = useLoadContext();

    const destinations = load.stops.filter(filterStopByDestination);
    return <LoadLocation stops={destinations} displaySubSite={displaySubSite} groupSublocations={groupSublocations} />;
};

export const LoadCargo: React.FC = () => {
    const { load } = useLoadContext();
    const { convertFromGramsDisplay } = useWeights();
    const cargoQuantities = orderToCargoQuantities(load, convertFromGramsDisplay);

    return (
        <LoadCargoInternal
            cargoQuantities={cargoQuantities}
            load={load} />
    );
};

// children of HereMapMarker (LiveMapRouteTooltip) which calls renderToStaticMarkup on the children components
// needs access to useSelector in useWeights() to get the users weight preference.  calling renderToStaticMarkup
// on redux things causes a crash
export const LoadCargo2: React.FC<{load: Load, convertFromGramsDisplay: (g: number) => string}> = (props) => {
    const { load, convertFromGramsDisplay } = props;
    const cargoQuantities = orderToCargoQuantities(load, convertFromGramsDisplay);

    return (
        <LoadCargoInternal
            cargoQuantities={cargoQuantities}
            load={load} />
    );
};

const LoadCargoInternal: React.FC<{cargoQuantities: any, load: Load}> = (props) => {
    const { cargoQuantities, load } = props;

    return (
        <div>
            {Object.entries(cargoQuantities).map(([cargo, quantity]) => {
                return (
                    <div key={cargo}>
                        {quantity ? `${quantity} - ${cargo}` : `${cargo}`}
                    </div>
                );
            })}
            <SalmonellaMeter severity={load.metadata?.cargo?.salmonellaResult} />
        </div>
    );
};

export const LoadLotIds: React.FC = () => {
    const { load } = useLoadContext();
    const lotIds = orderToLotIds(load);

    return (
        <div>
            {lotIds.map((lotId, i) => {
                return (
                    <div key={i}>
                        {lotId}
                    </div>
                );
            })}
        </div>
    );
};

export const LoadOrderNumbers: React.FC = () => {
    const { load } = useLoadContext();
    const orderNumbers = orderToOrderNumbers(load);

    return (
        <div>
            {orderNumbers.map((orderNumber, i) => {
                return (
                    <div key={i}>
                        {orderNumber}
                    </div>
                );
            })}
        </div>
    );
}

const inputProps = { style: { fontSize: '.875rem' } };

export const LoadDriver: React.FC = () => {
    const { load, editFunctions } = useLoadContext();
    //pull orgId from here as load does not always have an id (merge drafts)
    const { data: drivers = [], organizationId } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers);
    const { data: carriers = [] } = useOrganizationQuery<OrganizationCarrier[]>(OrgQueryKeys.carriers);
    const currentCarrier = carriers.find(x => x.carrierOrganizationId === load.transportingOrganizationId && x.organizationId === load.organizationId);
    const currentUserIsCarrier = useCarrier();

    const { driverList, isDisabled }: { driverList: Item[], isDisabled: boolean } = React.useMemo(() => {
        // If it's a drop down, only include enabled or assigned drivers
        const applicableDrivers = editFunctions?.onChange ? drivers.filter(driver => driver.enabled || driver.id === load.transportedByUserId) : drivers;
        
        const { resources, isDisabled } = currentCarrier
            ? carrierResourceSettings(applicableDrivers, currentUserIsCarrier, organizationId, currentCarrier, 'loadDriver')
            : { resources: applicableDrivers.filter(x => x.organizationId === organizationId), isDisabled: false };

        return {
            driverList: resources?.map(d => ({
                id: d.id,
                label: d.name,
            })).sortBy((x: Item) => x.label),
            isDisabled: isDisabled,
        };
    }, [drivers]);

    if (editFunctions?.onChange) {
        return (
            <ResourceSelect isDisabled={editFunctions?.isSaving || isDisabled} inputProps={inputProps}
                itemId={load.transportedByUserId} itemList={driverList} resourceType={'user'}
                onChange={(id) => {
                    const update: Partial<Load> = { transportedByUserId: id };
                    
                    const driver = drivers.find(x => x.id === id);
                    update.tractorId = driver?.driverInfo?.defaultTractorId;
                    update.trailerId = driver?.driverInfo?.defaultTrailerId;

                    if (id === null) {
                        load.status = ORDER_STATUSES().Open.key;
                    }
                    editFunctions.onChange(update);
                }} />
        );
    } else {
        return (
            <ResourceConflictContainer resource={{ id: load.transportedByUserId, type: 'user' }}>
                <span>{toDriverDisplayName(drivers)(load)}</span>
            </ResourceConflictContainer>
        );
    }
};

export const LoadTractor: React.FC = () => {
    const { load, editFunctions } = useLoadContext();
    const { tractors = [], organizationId } = useTractorTrailer();
    const { data: carriers = [] } = useOrganizationQuery<OrganizationCarrier[]>(OrgQueryKeys.carriers);
    const currentCarrier = carriers.find(x => x.carrierOrganizationId === load.transportingOrganizationId && x.organizationId === load.organizationId);
    const currentUserIsCarrier = useCarrier();

    const { tractorList, isDisabled }: { tractorList: Item[], isDisabled: boolean } = React.useMemo(() => {
        // If it's a drop down, only include enabled or assigned tractors
        const applicableTractors = editFunctions?.onChange ? tractors.filter(tractor => tractor.enabled || tractor.id === load.tractorId) : tractors;

        const { resources, isDisabled } = currentCarrier
            ? carrierResourceSettings(applicableTractors, currentUserIsCarrier, organizationId, currentCarrier, 'loadTractor')
            : { resources: applicableTractors.filter(x => x.organizationId === organizationId), isDisabled: false };

        return {
            tractorList: resources?.map(toIdLabelTuple('userDisplayName')) || [],
            isDisabled: isDisabled,
        };
    }, [tractors]);

    if (editFunctions?.onChange) {
        return (
            <ResourceSelect isDisabled={editFunctions?.isSaving || isDisabled} inputProps={inputProps} itemId={load.tractorId}
                itemList={tractorList} resourceType={'tractor'}
                onChange={(id) => editFunctions.onChange({ tractorId: id })} />
        );
    } else {
        return (
            <ResourceConflictContainer resource={{ id: load.tractorId, type: 'tractor' }}>
                <span>{toTractorDisplayName(tractors)(load)}</span>
            </ResourceConflictContainer>
        );
    }
};

export const LoadTrailer: React.FC = () => {
    const { load, editFunctions } = useLoadContext();
    const { trailers = [], organizationId } = useTractorTrailer();
    const { data: carriers = [] } = useOrganizationQuery<OrganizationCarrier[]>(OrgQueryKeys.carriers);
    const currentCarrier = carriers.find(x => x.carrierOrganizationId === load.transportingOrganizationId && x.organizationId === load.organizationId);
    const currentUserIsCarrier = useCarrier();

    const { trailerList, isDisabled }: { trailerList: Item[], isDisabled: boolean } = React.useMemo(() => {
        // If it's a drop down, only include enabled or assigned trailers
        const applicableTrailers = editFunctions?.onChange ? trailers.filter(trailer => trailer.enabled || trailer.id === load.trailerId) : trailers;

        const { resources, isDisabled } = currentCarrier
            ? carrierResourceSettings(applicableTrailers, currentUserIsCarrier, organizationId, currentCarrier, 'loadTrailer')
            : { resources: applicableTrailers.filter(x => x.organizationId === organizationId), isDisabled: false };

        return {
            trailerList: resources?.map(toIdLabelTuple('userDisplayName')) || [],
            isDisabled: isDisabled,
        };
    }, [trailers]);

    if (editFunctions?.onChange) {
        return (
            <ResourceSelect isDisabled={editFunctions?.isSaving || isDisabled} inputProps={inputProps} itemId={load.trailerId}
                itemList={trailerList} resourceType={'trailer'}
                onChange={(id) => editFunctions.onChange({ trailerId: id })} />
        );
    } else {
        return (
            <ResourceConflictContainer resource={{ id: load.trailerId, type: 'trailer' }}>
                <span>{toTrailerDisplayName(trailers)(load)}</span>
            </ResourceConflictContainer>
        );
    }
};

//TODO: Delete when washes are converted to actual stops
export const LoadWashOption: React.FC = () => {
    const { load, editFunctions } = useLoadContext();
    const isCarrierLoad = load.organizationId !== load.transportingOrganizationId;

    const washOptions = React.useMemo(() => {
        return Object.values(WASH_OPTIONS()).map(option => {
            return { id: option.key || 'None', label: option.label };
        });
    }, []);

    if (editFunctions?.onChange) {
        return (
            <TextField
                variant='standard'
                value={load.washOption || 'None'}
                select
                fullWidth
                disabled={editFunctions?.isSaving || isCarrierLoad}
                InputProps={inputProps}
                onChange={(e) => {
                    const washOption = e.target.value as WashOption;
                    editFunctions.onChange({ washOption });
                }}>
                {washOptions.map((option, i) => {
                    return <MenuItem key={i} value={option.id}>
                        {option.label}
                    </MenuItem>;
                })}
            </TextField>
        );
    } else {
        return <span>{washOptions.find(x => x.id === load.washOption)?.label}</span>;
    }
};

export const LoadStatus: React.FC = () => {
    const { load } = useLoadContext();
    const isCarrier = load.organizationId !== load.transportingOrganizationId;
    const { data: carriers = [] } = useOrganizationQuery<OrganizationCarrier[]>(OrgQueryKeys.carriers);

    if (isCarrier) {
        const carrierName = carriers.find(x => x.carrierOrganizationId === load.transportingOrganizationId)?.carrier?.name;
        return <div title={carrierName} style={{ color: '#29A84A', fontWeight: 'bold' }}>{ORDER_STATUSES()[load.status].label}</div>
    } else {
        return <div>{ORDER_STATUSES()[load.status].label}</div>
    }
}
