import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { L } from 'harmony-language';
import { styled } from '@mui/material/styles';

const BulkPreviewHeaderCell = styled(TableCell)({
    color: '#D32F2F',
    textTransform: 'uppercase',
    fontSize: '11px',
    paddingTop: '0.2em',
    paddingBottom: '0.2em',
    border: 'none',
});

export const BulkPreviewTableHeaders = (props) => {
    const { isOriginBased } = props;

    return (
        <TableHead>
            <TableRow>
                <BulkPreviewHeaderCell>{L.date()}</BulkPreviewHeaderCell>
                <BulkPreviewHeaderCell>{isOriginBased ? L.origin() : L.destination()}</BulkPreviewHeaderCell>
                <BulkPreviewHeaderCell>{L.cargoType()}</BulkPreviewHeaderCell>
                <BulkPreviewHeaderCell>{L.loadSize()}</BulkPreviewHeaderCell>
                <BulkPreviewHeaderCell>{L.loadWeight()}</BulkPreviewHeaderCell>
            </TableRow>
        </TableHead>
    );
};

BulkPreviewTableHeaders.propTypes = {
    isOriginBased: PropTypes.bool,
};
