export const createStop = (sequence, type) => ({
    domKey: Math.random(),
    type,
    arrivalTime: null,
    cargoTypeId: null,
    sequence,
    orgLocation: {
        location: {
            address: {},
            geoLocation: {}
        }
    },
    splitId: null,
    customerOrderNumber: null,
    requiredBegin: null,
    requiredEnd: null,
    readyTime: null,
});
