import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { DynamicQueryKeys } from '../../../api/config';
import { getSocket } from '../../../services/socket-factory';
import { DeviceLocation } from '../../../types';

function filterDeviceLocations(deviceLocations: DeviceLocation[]): DeviceLocation[] {
    return filterDriverLoggedOut(deviceLocations.filter(dl => dl.deviceId && dl.latitude && dl.longitude
    && (dl.userId || dl.orderId || dl.trailerId || dl.tractorId)));
}

function filterDriverLoggedOut(deviceLocations: DeviceLocation[]): DeviceLocation[] {
    return deviceLocations.filter(dl => dl.userId);
}

export function useConnectedDeviceLocations(): { latestDeviceLocations: DeviceLocation[], isFetched: boolean } {
    const { data = [], isFetched } = useQuery<DeviceLocation[]>([DynamicQueryKeys.latestDeviceLocations(1440)], {
        staleTime: 10 * 60 * 1000, // 10 mins
        cacheTime: 0,
    });
    const [latestDeviceLocations, setLatestDeviceLocations] = React.useState(data);

    React.useEffect(() => {
        if (isFetched) {
            setLatestDeviceLocations(filterDeviceLocations(data));
        }
    }, [data, isFetched]);

    React.useEffect(() => {
        const socket = getSocket();
        const socketHandler = (updatedLocation: DeviceLocation) => {
            setLatestDeviceLocations((previous: DeviceLocation[]) => {
                return filterDeviceLocations(previous.map(x => x.deviceId === updatedLocation.deviceId ? { ...updatedLocation } : x));
            });
        }
        socket.on('device:locations:latest:update', socketHandler);

        return () => socket.off('device:locations:latest:update', socketHandler);
    }, []);

    return { latestDeviceLocations, isFetched };
}