import React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';
import { LoadTableColumn } from './load-table';
import { StopsTable } from './stops-table';
import { Checkbox } from '@mui/material';
import { useLoadContext } from '../load-context';
import Save from '@mui/icons-material/Save';
import { L } from 'harmony-language';
import LoadingButton from '../loading-button';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import { useLoadConflict } from '../resource-conflicts/use-load-conflict';
import { classNames } from '../../../utils/string-utils';
import { ResourceConflictTooltip } from '../containers/resource-conflict-tooltip';

const StopsTableContainer = styled('div')({
    gridColumn: '1 / span 13',
    border: 'unset',
});

const StyledExpandInfo = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
});

interface LoadTableRowProps {
    columns: LoadTableColumn[],
    stopColumns: LoadTableColumn[],
    toggle: () => void,
    isSelected: boolean,
    showSelection: boolean,
}

export const LoadTableRow: React.FC<LoadTableRowProps> = (props) => {
    const { columns, toggle, isSelected, stopColumns, showSelection } = props;

    const {editFunctions, isDirty} = useLoadContext();
    const [expanded, setExpanded] = React.useState(false);
    const isDraft = Boolean(editFunctions);

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        editFunctions?.save();
    };

    return (
        <form role='row' onSubmit={handleSubmit}
              className={classNames('grid', {
                  'isSaving': editFunctions?.isSaving,
                  'isSuccess': editFunctions?.isSuccess,
                  'isError': editFunctions?.isError
              })}>
            {showSelection &&
                <div style={{gridColumn: '1'}}>
                    <Checkbox checked={isSelected} onChange={toggle}/>
                </div>
            }
            {
                columns.map((c, i) => {
                    return (
                        <div key={i} style={{ overflow: 'hidden' }}>
                            <c.render required={c.required} validations={c.validations}/>
                        </div>
                    );
                })
            }
            {editFunctions?.save && <div>
                <LoadingButton isLoading={editFunctions?.isSaving} disabled={!isDirty} icon={<Save/>} type='submit'
                               size='small'
                               variant='contained'>{L.save()}</LoadingButton>
            </div>}
            <ExpandInfo isOpen={expanded} toggle={() => setExpanded(!expanded)} isDraft={isDraft} />
            <StopsTableContainer>
                <Collapse
                    in={expanded}
                    // timeout='auto'
                    // unmountOnExit
                    mountOnEnter
                    style={{ flex: 1 }}
                >
                    <StopsTable columns={stopColumns}/>
                </Collapse>
            </StopsTableContainer>
        </form>
    );
};

interface ExpandInfoProps {
    isOpen: boolean;
    isDraft: boolean;
    toggle: () => void;
}

const ExpandInfo: React.FC<ExpandInfoProps> = (props) => {
    const { isOpen, toggle, isDraft } = props;

    const conflict = useLoadConflict(isDraft);

    return (
        <StyledExpandInfo>
            {conflict &&
                <ResourceConflictTooltip tooltipComponent={conflict}>
                    <WarningIcon
                        fontSize={'inherit'}
                        sx={{
                            fontSize: '1rem',
                            color: (theme) => theme.palette.error.main,
                            cursor: 'default',
                        }} />
                </ResourceConflictTooltip>
            }
            <IconButton aria-label="expand row" size="small" onClick={toggle}>
                {isOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            </IconButton>
        </StyledExpandInfo>
    );
};
