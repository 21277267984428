import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mutationOptions, OrgQueryKeys } from '../../config';
import axios from 'axios';
import { useState } from 'react';
import { useCompanyId } from '../../../app/agistics-context';
import { Throughput } from '../../../types';

export const useReplaceOrganizationLocationThroughputs = () => {
    const [mutateKey, setMutateKey] = useState<string | undefined>();
    const companyId = useCompanyId();
    const queryClient = useQueryClient();

    return useMutation(async ({ throughputs, locationId }: { throughputs: Throughput[], locationId: number }) => {
        const key = OrgQueryKeys.locationThroughput(companyId, locationId);
        setMutateKey(key);
        return (await axios.put<Throughput[]>(`${API_BASE}/api${key}`, throughputs))?.data
    }, {
        ...mutationOptions([mutateKey], queryClient),
    })
}
