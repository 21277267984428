import React from 'react';
import PropTypes from 'prop-types';
import * as HereMapService from '../../services/here-maps/here-map-service';
import * as HereMapWrapper from '../../services/here-maps/here-map-wrapper';
import { HereMapContext } from './here-map-context';

export const HereMapMarker = (props) => {
    const [marker, setMarker] = React.useState(null);
    const [bubble, setBubble] = React.useState(null);
    const context = React.useContext(HereMapContext);

    // set up info marker and bubble
    React.useEffect(() => {
        if (!context.map) {
            return;
        }

        const newMarker = HereMapWrapper.createMarker(props.latitude, props.longitude, props.icon, props.visibility, props.type);
        context.map.addObject(newMarker);
        setMarker(newMarker);


        let infoBubble;
        if (props.children) {
            infoBubble = HereMapWrapper.createInfoBubble(props.latitude, props.longitude, props.children);
            setBubble(infoBubble);
            context.ui.addBubble(infoBubble);
        }

        return () => {
            context.map.removeObject(newMarker);
            if (infoBubble) {
                context.ui.removeBubble(infoBubble);
            }
        };
    }, [context.map]);


    React.useEffect(() => {
        if (marker) {
            const clickHandler = () => props.onClick?.();
            marker.addEventListener('tap', clickHandler);
            return () => {
                marker.removeEventListener('tap', clickHandler);
            }
        }
    }, [marker, props.onClick]);

    React.useEffect(() => {
        if (bubble && marker) {
            const unsubscribe = HereMapService.setTooltipBehavior2(bubble, props.tooltipSticky, marker);
            return unsubscribe;
        }
    }, [marker, bubble, props.tooltipSticky]);


    // update marker
    React.useEffect(() => {
        if (marker) {
            const position = { lat: props.latitude, lng: props.longitude };

            marker.setGeometry(position);
            if (bubble) {
                bubble.setPosition(position);
            }
        }
    }, [marker, bubble, props.latitude, props.longitude]);


    // update info bubble
    React.useEffect(() => {
        if (bubble) {
            HereMapService.setInfoBubbleContent(bubble, props.children);
        }
    }, [marker, bubble, props.children, props.tooltipSticky]);


    React.useEffect(() => {
        if (marker && props.icon) {
            HereMapService.setMarkerIcon(marker, props.icon);
        }
    }, [marker, props.icon]);


    return null;
};

HereMapMarker.propTypes = {
    children: PropTypes.node,
    latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    icon: PropTypes.string,
    onClick: PropTypes.func,
    tooltipSticky: PropTypes.bool,
    visibility: PropTypes.bool,
    type: PropTypes.string,
};
