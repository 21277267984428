import Grid from '@mui/material/Grid';
import { CustomerTier, Features, PermissionType } from 'harmony-constants';
import { L } from 'harmony-language';
import React from 'react';
import { EDIT_COMPANY, VIEW_ALL_COMPANIES, VIEW_NOTIFCATION_CONFIGS_PERMISSION, VIEW_SERVICE_USER_PERMISSION } from '../../permissions/permissions';
import { Content } from '../shared/containers/content';
import { useCarrier, useEquipmentEditable, useFeatures, usePermissions, useTier } from '../user/selectors/use-permissions';
import { AdminBreadCrumbHeader } from './admin-bread-crumb-header';
import { AdministrationCard } from './administration-card';

export const Administration = () => {
    const equipmentEditable = useEquipmentEditable();
    const canViewCompanies = usePermissions(VIEW_ALL_COMPANIES);
    const canEditCompanies = usePermissions(EDIT_COMPANY);
    const viewServiceUserPermission = usePermissions(VIEW_SERVICE_USER_PERMISSION);
    const canAccessServerAPI = useFeatures(Features.ServerAPI);
    const hasChecklists = useFeatures(Features.Checklists);
    const hasStandardRoutes = useFeatures(Features.StandardRoutes);
    const canViewChecklistTemplates = usePermissions(PermissionType.ViewChecklistTemplates)
    const viewNotificationConfigsPermission = usePermissions(VIEW_NOTIFCATION_CONFIGS_PERMISSION);
    const isFreemium = useTier(CustomerTier.Freemium);
    const isCarrierOrg = useCarrier();

    const systemLinks = [];

    canAccessServerAPI && viewServiceUserPermission && !isCarrierOrg && systemLinks.push({
        title: L.manageServiceAccounts(),
        description: L.manageServiceAccountsDescription(),
        to: '/manage-company/service-accounts'
    });
    canEditCompanies && viewNotificationConfigsPermission && !isCarrierOrg && systemLinks.push({
        title: L.manageNotifications(),
        description: L.manageNotificationsDescription(),
        to: '/manage-company/notification-configs'
    });
    canEditCompanies && systemLinks.push({
        title: L.organizationSettings(),
        description: L.organizationSettingsDescription(),
        to: '/manage-company/settings'
    });

    const equipmentLinks = [
        {
            title: L.manageTractors(),
            description: L.manageTractorsDescription(),
            to: '/manage-company/tractors',
            isDisabled: !equipmentEditable.canEditTractors,
        },
        {
            title: L.manageTrailers(),
            description: L.manageTrailersDescription(),
            to: '/manage-company/trailers',
            isDisabled: !equipmentEditable.canEditTrailers,
        },
    ];
    hasChecklists && equipmentLinks.push(
        {
            title: L.manageTractorChecklistTemplates(),
            description: L.manageTractorChecklistTemplatesDescription(),
            to: '/manage-company/tractor-checklist-templates',
            isDisabled: !canViewChecklistTemplates
        },
        {
            title: L.manageTrailerChecklistTemplates(),
            description: L.manageTrailerChecklistTemplatesDescription(),
            to: '/manage-company/trailer-checklist-templates',
            isDisabled: !canViewChecklistTemplates
        },
    );

    const siteLinks = [
        {
            title: L.manageLocations(),
            description: L.manageLocationsDescription(),
            to: '/manage-company/locations',
            isDisabled: isFreemium || isCarrierOrg
        },
        {
            title: L.manageCustomers(),
            description: L.manageCustomersDescription(),
            to: '/manage-company/customers',
            isDisabled: isFreemium || isCarrierOrg
        },
        {
            title: L.manageAvoidanceZones(),
            description: L.manageAvoidanceZonesDescription(),
            to: '/manage-company/avoidance-zones',
            isDisabled: isFreemium || isCarrierOrg
        },
    ];

    hasStandardRoutes && siteLinks.push({
        title: L.manageStandardRoutes(),
        description: L.manageStandardRoutesDescription(),
        to: '/manage-company/standard-routes',
        isDisabled: isFreemium || isCarrierOrg
    });

    return (
        <>
            <AdminBreadCrumbHeader hideResourceDropDown/>
            <Content>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <AdministrationCard title={L.equipment()} icon={'local_shipping'} links={equipmentLinks}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <AdministrationCard title={L.people()} icon={'people'} links={[
                            {
                                title: L.manageUsers(),
                                description: L.manageUsersDescription(),
                                to: '/manage-company/users'
                            },
                            {
                                title: L.manageContacts(),
                                description: L.manageContactsDescription(),
                                to: '/manage-company/contacts',
                                isDisabled: isCarrierOrg,
                            },
                            // hidden for now
                            // {
                            //     title: L.manageLoadingTeams(),
                            //     description: L.manageLoadingTeamsDescription(),
                            //     to: '/manage-company/loading-teams',
                            //     isDisabled: isFreemium || isCarrierOrg
                            // },
                        ]}/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <AdministrationCard title={L.sites()} icon={'room'} links={siteLinks} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <AdministrationCard title={L.system()} icon={'settings'} links={systemLinks}/>
                    </Grid>
                    {canViewCompanies && <Grid item xs={12} sm={6} md={4}>
                        <AdministrationCard title={L.companies()} icon={'business'} links={[
                            {
                                title: L.manageCompanies(),
                                description: L.manageCompaniesDescription(),
                                to: '/manage-company/companies'
                            },
                            {
                                title: L.viewPermissions(),
                                description: L.viewPermissionsDescription(),
                                to: '/manage-company/permissions'
                            },
                            {
                                title: 'Map Helpers',
                                description: 'Agistics map helpers.  Currently only copy paste from db.',
                                to: '/map-helpers',
                            },
                        ]}/>
                    </Grid>}
                </Grid>
            </Content>
        </>
    );
};
