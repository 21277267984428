import React from 'react';
import { L, UNITS } from 'harmony-language';
import PropTypes from 'prop-types';
import { Select } from './select';

export const SelectUnits = props => {
    const { units, onChange } = props;
    const menuItems = [
        { id: UNITS.Imperial, value: UNITS.Imperial, label: L.unitsImperial() },
        { id: UNITS.Metric, value: UNITS.Metric, label: L.unitsMetric() }
    ];
    const item = menuItems.find(x => x.value === (units ? units : UNITS.Imperial));

    return (
        <Select
            {...props}
            label={L.selectUnits()}
            list={menuItems}
            item={item}
            onChange={(v) => onChange(v.value)}/>
    );
};

SelectUnits.propTypes = {
    units: PropTypes.string,
    onChange: PropTypes.func,
};
