import React from 'react';
import { Permission } from '../../../types';
import { Checkbox, Icon, ListItem, ListItemText, Tooltip } from '@mui/material';
import { useAddRolePermissionMutation } from '../../../api/mutations/add/use-add-role-permission-mutation';
import { useDeleteRolePermissionMutation } from '../../../api/mutations/delete/use-delete-role-permission-mutation';
import { InfoOutlined } from '@mui/icons-material';

interface PermissionsManagementItemProps {
    currentPermissions: Permission[];
    permission: Permission;
    roleId: number;
}

const excludedPermissions = ['viewAllCompanies', 'createCompany'];

export const PermissionsManagementItem: React.FC<PermissionsManagementItemProps> = (props) => {
    const { currentPermissions, permission, roleId } = props;
    const [hasPermission, setHasPermission] = React.useState(currentPermissions.filter(x => x.id === permission.id).length > 0);
    const { mutate: addRolePermission } = useAddRolePermissionMutation(roleId);
    const { mutate: deleteRolePermission } = useDeleteRolePermissionMutation(roleId, permission.id);
    const isDisabled = excludedPermissions.includes(permission.name) ? true : false;

    const handleChange = async () => {
        if (hasPermission) {
            deleteRolePermission({ roleId: roleId, permissionId: permission.id })
        } else {
            addRolePermission({ roleId: roleId, permissionId: permission.id });
        }
        setHasPermission(!hasPermission);
    }

    return (
        <ListItem dense>
            {isDisabled ? 
                <Tooltip
                    placement={'right'}
                    title={'Only admin@dptl.com can have this permission.'}>
                        <Icon><InfoOutlined></InfoOutlined></Icon>
                </Tooltip> :
                null
            }
            <ListItemText
                style={{ color: hasPermission ? 'green' : 'black' }}
                primary={permission.name}
                secondary={permission.description} />
            <Checkbox
                checked={hasPermission}
                disabled={isDisabled}
                onChange={handleChange} />
        </ListItem>
    );
};
