
interface OrgLocationColor {
    [key: PropertyKey]: {
        fill: string,
        stroke: string,
    };
}
const ORG_LOCATION_COLOR = {
    1: { // Sow Unit - Orange
        fill: 'rgba(213, 94, 0, 0.4)',
        stroke: 'rgba(230, 159, 0, 1)',
    },
    5: { // Hog Packing Plant - Pink
        fill: 'rgba(204, 121, 167, 0.4)',
        stroke: 'rgba(204, 121, 167, 1)',
    },
    7: { // Chicken Hatchery - Yellow
        fill: 'rgba(240, 228, 66, 0.4)',
        stroke: 'rgba(240, 228, 66, 1)',
    },
    11: { // Feed Mill - Green
        fill: 'rgba(0, 158, 115, 0.4)',
        stroke: 'rgba(0, 158, 115, 1)',
    },
    22: { // Turkey Incubator - Purple
        fill: 'rgba(75, 0, 146, 0.4)',
        stroke: 'rgba(75, 0, 146, 1)',
    },
}

export function useOrgLocationColorProxy() {
    const fillColorHandler = {
        get(colorDictionary: OrgLocationColor, locationTypeId: PropertyKey) {
            if (locationTypeId in colorDictionary) {
                return colorDictionary[locationTypeId];
            } else {
                return { // Default - Blue
                    fill: 'rgba(63, 104, 191, 0.4)',
                    stroke: 'rgba(63, 104, 191, 1)',
                }
            }
        }
    }

    return new Proxy(ORG_LOCATION_COLOR, fillColorHandler)
}