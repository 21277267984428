import React from 'react';
import { IconButton } from '@mui/material';
import { Stop } from '../../../types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface VisibilityIconButtonProps {
    secondRowVis: boolean;
    stop: Stop;
    onClick: (b: boolean) => void;
}

export const VisibilityIconButton: React.FC<VisibilityIconButtonProps> = (props) => {
    const { secondRowVis, stop, onClick } = props;

    return (
        <IconButton
            title={stop?.id?.toString()}
            edge='start'
            size='small'
            color='primary'
            onClick={() => onClick(!secondRowVis)}
        >
            {secondRowVis ?
                <VisibilityOffIcon /> :
                <VisibilityIcon />
            }
        </IconButton>
    );
};
