import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { L } from 'harmony-language';
import { getDuration } from '../../utils/date-time-utils';

const Unavailable = styled('span')(({ theme }) => ({
    color: theme.palette.error.main,
}));

export const TravelTimeTooltip = props => {
    const { loadDurationMinutes, travelTimeMinutes, convertedDistance, isMetric } = props;
    const minuteMs = 1000 * 60;
    const loadingDurationMs = loadDurationMinutes * minuteMs;

    return (
        <div>
            <Typography sx={{ fontWeight: 'bold' }}>{L.estimatedTotalTripTime()}</Typography>
            <Typography>{L.loadUnloadDuration()}: {getDuration(loadingDurationMs)}</Typography>
            <Typography>{L.travelTime()}: {(travelTimeMinutes || travelTimeMinutes === 0) ?
                getDuration(travelTimeMinutes * minuteMs) :
                <Unavailable>{L.travelTimeUnavailable()}</Unavailable>}
            </Typography>
            <Typography>{L.distance()}: {convertedDistance.toFixed(1)} {isMetric ? L.unitsMetricDistanceAbbr() : L.unitsImperialDistanceAbbr()}</Typography>
        </div>
    );
};

TravelTimeTooltip.propTypes = {
    loadDurationMinutes: PropTypes.number,
    travelTimeMinutes: PropTypes.number,
    convertedDistance: PropTypes.number,
    isMetric: PropTypes.bool,
};
