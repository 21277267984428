import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { mutationOptions, QueryKeys } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { L } from 'harmony-language';
import { OrderGroupCache } from '../../../components/production-planning/use-production-card-groups';
import { OrderGroup } from '../../../components/production-planning/order-groups/use-order-group-table';

async function unlinkOrderGroup(options: OrderGroup) {
    const guid = options.guid;

    await axios.delete(`${API_BASE}/api/production-cards/groups/${guid}`);
}

export const useUnlinkOrderGroupMutation = () => {
    const snackbar = useSnackbar();
    const key = QueryKeys.productionCardGroups;
    const queryClient = useQueryClient();

    return useMutation(unlinkOrderGroup, {
        ...mutationOptions([key], queryClient),
        onMutate: (options) => {
            const guid = options.guid;

            queryClient.cancelQueries([key]);

            const previousCache = queryClient.getQueryData<OrderGroupCache>([key]);
            if (previousCache) {
                const filteredGroups = previousCache.data.filter(x => x.guid !== guid);

                queryClient.setQueryData([key], {
                    ...previousCache,
                    data: filteredGroups
                });
            }

            return () => queryClient.setQueryData([key], previousCache);
        },
        onSuccess: (_, options) => {
            snackbar(L.bulkDeleteSnackbarSuccess(options.numberOfDraftLoads.toString()));
            queryClient.invalidateQueries([QueryKeys.productionCards]);
        },
    });
};
