import { QueryKeys } from '../config';
import { useLoadsKey } from '../config-hooks';
import { useLoadsWithDateFilter } from './use-loads-with-date-filter';

export const useLoads = () => {
    const loadsKey = useLoadsKey();
    const query = useLoadsWithDateFilter(QueryKeys.loads, loadsKey);

    return { loads: query?.data?.data, ...query };
};
