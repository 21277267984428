import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export const AlertDialog = (props) => {
    const { open, title, confirmText, handleClose, ...rest } = props;

    return (
        <Dialog
            {...rest}
            data-testid='alert-dialog'
            open={open}
            onClose={handleClose}
        >
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='primary'>
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AlertDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    children: PropTypes.node,
    title: PropTypes.string,
    confirmText: PropTypes.string,
    handleClose: PropTypes.func
};
