import React from 'react';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { Driver } from '../../../types';
import { Stack } from '@mui/material';
import { L } from 'harmony-language';
import { AgisticsMultiSelectWithPrefs } from '../../shared/multi-select/agistics-multi-select-with-prefs';

interface DriverEfficiencyFiltersProps {
    isAdmin: boolean;
    driverIds: number[];
    setDriverIds: React.Dispatch<React.SetStateAction<number[]>>;
}

export const DriverEfficiencyFilters: React.FC<DriverEfficiencyFiltersProps> = (props) => {
    const { isAdmin, driverIds, setDriverIds } = props;
    const { data: drivers = [] } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers, { enabled: !isAdmin });
    const driverMultiItems = React.useMemo(() => drivers.map(driver => ({ id: driver.id, value: driver.name })), [drivers]);

    return (
        <Stack sx={{ width: '20rem' }}>
            {!isAdmin &&
                <AgisticsMultiSelectWithPrefs
                    selectedIds={driverIds}
                    setSelectedIds={setDriverIds}
                    items={driverMultiItems}
                    label={L.drivers()}
                    prefKey='removedDrivers'
                    removeMode={true}
                />
            }
        </Stack>
    );
};
