import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { mutationOptions, OrgQueryKeys } from '../../config';
import { Feature } from '../../../types';

async function deleteFeature(companyId: number, options: { featureId: number }) {
    const featureId = options.featureId;

    return (await axios.delete(`${API_BASE}/api/organizations/${companyId}/features/${featureId}`)).data;
}

export const useDeleteFeatureMutation = (companyId: number) => {
    const key = OrgQueryKeys.resolve(companyId, OrgQueryKeys.features);
    const queryClient = useQueryClient();

    return useMutation((...args) => deleteFeature(companyId, ...args), {
        ...mutationOptions([key], queryClient),
        onMutate: (options) => {
            const featureId = options.featureId;

            queryClient.cancelQueries([key]);

            const previousFeatures = queryClient.getQueryData<Feature[]>([key]);
            if (previousFeatures) {
                const newFeatures = previousFeatures.filter(f => f.id !== featureId);

                // Optimistically update to the new value
                queryClient.setQueryData([key], newFeatures);
            }

            // Return a rollback function
            return () => queryClient.setQueryData([key], previousFeatures);
        }
    });
};
