import React from 'react';
import { L } from 'harmony-language';
import { createColumnHelper } from '@tanstack/react-table';
import { Tractor } from '../../../types';

export const useTractorColumns = () => {
    const columnHelper = createColumnHelper<Tractor>();

    const columns = React.useMemo(() => {
        const c = [
            columnHelper.accessor('userDisplayName', {
                header: L.name(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false
            }),
            columnHelper.accessor('licensePlate', {
                header: L.licensePlate(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false
            }),
            columnHelper.accessor('make', {
                header: L.make(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false
            }),
            columnHelper.accessor('model', {
                header: L.model(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false
            }),
            columnHelper.accessor('contract', {
                header: L.contract(),
                cell: (info) => info.getValue() ? L.yes() : L.no(),
                enableColumnFilter: false,
                size: 50,
            }),
        ];

        return c;
    }, []);

    return columns;
};
