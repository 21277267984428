import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { L, UNITS, getCurrentUnitSystem } from 'harmony-language';
import { useWeights } from '../../shared/hooks/use-weights';
import { SplitStopReportColumns } from '../report-types';
import { localDateTimeDisplay } from '../../../utils/date-time-utils';

export const useSplitStopColumns = (isAdmin: boolean) => {
    const columnHelper = createColumnHelper<SplitStopReportColumns>();
    const isMetric = getCurrentUnitSystem() === UNITS.Metric;
    const { weightAbbreviation } = useWeights();

    const columns = React.useMemo(() => {

        const adminSettings = () => {
            return [
                columnHelper.accessor('organization_id', {
                    header: () => L.organizationId(),
                    cell: info => info.getValue(),
                }),
            ]
        }

        const adminColumns = isAdmin ? adminSettings() : [];
        const restColumns = [
            columnHelper.accessor('order_id', {
                header: () => L.orderId(),
                size: 75,
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('trailer_name', {
                header: () => L.trailer(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('original_order_number', {
                header: () => L.originalOrderNumber(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('destination_order_number', {
                header: () => L.destinationOrderNumber(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('original_site', {
                header: () => L.originalSite(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('destination_site', {
                header: () => L.destination(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('destination_quantity', {
                header: () => L.quantity(),
                size: 75,
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('destination_weight', {
                header: () => `${L.weight()} (${weightAbbreviation})`,
                size: 75,
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('original_notes', {
                header: () => L.originalNotes(),
                cell: info => info.getValue(),
                enableSorting: false,
            }),
            columnHelper.accessor('destination_notes', {
                header: () => L.destinationNotes(),
                cell: info => info.getValue(),
                enableSorting: false,
            }),
            columnHelper.accessor('original_comments', {
                header: () => L.originalComments(),
                cell: info => info.getValue(),
                enableSorting: false,
            }),
            columnHelper.accessor('destination_comments', {
                header: () => L.destinationComments(),
                cell: info => info.getValue(),
                enableSorting: false,
            }),
            columnHelper.accessor('order_date', {
                header: () => L.date(),
                cell: info => localDateTimeDisplay(info.getValue()),
            }),
        ];

        return [...adminColumns, ...restColumns];
    }, [weightAbbreviation, isMetric]);

    return columns;
}
