import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

export const Select = props => {
    const { item, list, onChange, disabled, inputProps, required = false, label, style, onBlur, className, ...rest } = props;

    return (
        <TextField
            variant='standard'
            {...rest}
            className={className}
            style={{ width: '100%', ...style }}
            disabled={disabled}
            required={required}
            select
            label={label}
            value={item?.id || ''}
            onBlur={(e) => {
                onBlur?.(list.find(l => l.id === e.target.value));
            }}
            InputProps={{
                ...inputProps,
                'aria-label': label,
            }}
            onChange={(e) => {
                onChange?.(list.find(l => l.id === e.target.value));
            }}>
            {list.map((i) => {
                return (
                    <MenuItem key={i.id} value={i.id}>
                        {i.label}
                    </MenuItem>
                );
            })}
        </TextField>
    );
};

Select.propTypes = {
    list: PropTypes.array.isRequired,
    item: PropTypes.object,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    inputProps: PropTypes.object,
    label: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    required: PropTypes.bool
};

