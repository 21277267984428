import * as HereMapWrapper from './here-map-wrapper';
import * as ResizableCircleEvents from './resizable-circle-events';
import * as ResizableRectangleEvents from './resizable-rectangle-events';

export function makeShapeDynamic(map, mapBehavior, shape, onChanging, onChanged) {
    if (HereMapWrapper.isRectangle(shape)) {
        return makeRectangleDynamic(map, mapBehavior, shape, onChanging, onChanged);
    }
    if (HereMapWrapper.isCircle(shape)) {
        return makeCircleResizable(map, mapBehavior, shape, onChanging);
    }
}

function makeCircleResizable(map, circle, onResizing) {
    const circleOutline = HereMapWrapper.createPolyline(circle.getGeometry().getExterior(), {
        style: { strokeColor: 'rgb(10, 10, 10)', lineWidth: 8 }
    });
    const circleGroup = HereMapWrapper.createGroup([circle, circleOutline]);
    let timeout;

    circle.draggable = true;
    circleOutline.draggable = true;

    circleOutline.getGeometry().pushPoint(circleOutline.getGeometry().extractPoint(0));

    circleGroup.addEventListener('pointermove', (event) => ResizableCircleEvents.onPointerMove(event), true);
    circleGroup.addEventListener('pointerenter', () => timeout = ResizableCircleEvents.onPointerEnter(circleOutline, timeout), true);
    circleGroup.addEventListener('pointerleave', (event) => timeout = ResizableCircleEvents.onPointerLeave(event, circleOutline, timeout), true);
    circleGroup.addEventListener('drag', (event) => {
        ResizableCircleEvents.onDrag(event, map, circle, circleOutline);
        onResizing({
            radius: circle.getRadius(),
            latitude: circle.getCenter().lat,
            longitude: circle.getCenter().lng
        });
    }, true);

    return circleGroup;
}

// handles both resizing and moving
function makeRectangleDynamic(map, mapBehavior, rectangle, onChanging, onChanged) {
    const rectangleOutline = HereMapWrapper.createPolyline(rectangle.getGeometry().getExterior(), {
        style: { strokeColor: 'rgb(10, 10, 10)', lineWidth: 4, lineCap: 'square' }
    });
    const rectangleGroup = HereMapWrapper.createGroup([rectangle, rectangleOutline]);

    let timeout;

    rectangle.draggable = true;
    rectangleOutline.draggable = true;

    rectangleOutline.getGeometry().pushPoint(rectangleOutline.getGeometry().extractPoint(0));

    rectangleGroup.addEventListener('pointermove', (event) => ResizableRectangleEvents.onPointerMove(event, map, rectangleOutline, rectangleGroup), true);
    rectangleGroup.addEventListener('pointerenter', () => timeout = ResizableRectangleEvents.onPointerEnter(rectangleOutline, timeout), true);
    rectangleGroup.addEventListener('pointerleave', (event) => timeout = ResizableRectangleEvents.onPointerLeave(event, rectangleOutline, timeout), true);

    rectangleGroup.addEventListener('drag', (event) => {
        ResizableRectangleEvents.onDrag(event, map, rectangleGroup, rectangle, rectangleOutline);
        if (onChanging) {
            const positionInfo = getRectangleGeo(rectangle);

            onChanging(positionInfo);
        }
    }, true);

    rectangleGroup.addEventListener('dragstart', (event) => {
        if (event.target === rectangleOutline) {
            mapBehavior.disable();
        }
        event.target.setData({
            startCoord: map.screenToGeo(event.currentPointer.viewportX, event.currentPointer.viewportY)
        });
        event.stopPropagation();
    }, true);

    rectangleGroup.addEventListener('dragend', () => {
        mapBehavior.enable();
        if (onChanged) {
            const positionInfo = getRectangleGeo(rectangle);

            onChanged(positionInfo);
        }
    }, true);


    return rectangleGroup;
}


function getRectangleGeo(rectangle) {
    const bbox = rectangle.getBoundingBox();
    const topLeft = bbox.getTopLeft();
    const bottomRight = bbox.getBottomRight();

    return {
        latitude: (topLeft.lat + bottomRight.lat) / 2,
        longitude: (topLeft.lng + bottomRight.lng) / 2,
        bbox: {
            topLeft: {
                latitude: topLeft.lat,
                longitude: topLeft.lng,
            },
            bottomRight: {
                latitude: bottomRight.lat,
                longitude: bottomRight.lng,
            }
        }
    };
}
