import { L, UNITS, getCurrentUnitSystem } from 'harmony-language';
import { metersToKm, metersToMiles } from './unit-conversion';

export const getConvertedDistance = (distance: number | null) => {
    const isMetric = getCurrentUnitSystem() === UNITS.Metric;
    const convertedDistance = distance ? isMetric ? metersToKm(distance) : metersToMiles(distance) : 0;

    return {
        convertedDistance: convertedDistance ? convertedDistance.toFixed(1) : null,
        abbreviation: isMetric ? L.unitsMetricDistanceAbbr() : L.unitsImperialDistanceAbbr(),
    }
}
