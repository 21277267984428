import React from 'react';
import PropTypes from 'prop-types';
import { HereMapContext } from './here-map-context';
import * as HereMapRouteService from '../../services/here-maps/here-map-route-service';
import * as HereMapWrapper from '../../services/here-maps/here-map-wrapper';

export const HereMapRoute = (props) => {
    const [polyline, setPolyline] = React.useState(null);
    const context = React.useContext(HereMapContext);
    const map = React.useRef();
    const polylineId = React.useRef();
    const previousWaypoints = React.useRef('');

    React.useEffect(() => {
        return () => {
            const existingRoute = map.current?.getObjects()
                .find(obj => obj.getId() === polylineId.current);

            if (existingRoute) {
                map.current.removeObject(existingRoute);
            }
        };
    }, []);

    React.useEffect(() => {
        if (!context.map) {
            setPolyline(null);
            return;
        }
        map.current = context.map;

        if (props.calculateRouteFromWaypoints && JSON.stringify(props.waypoints) !== previousWaypoints.current) {
            previousWaypoints.current = JSON.stringify(props.waypoints);

            HereMapRouteService.calculateRoute(props.waypoints, props.avoidanceZones, (result) => {
                const sections = result.routes[0].sections;
                const lineStrings = [];
                sections.forEach((section) => {
                    lineStrings.push(HereMapWrapper.lineStringFromEncodedPolyline(section.polyline));
                });

                //convert to MultiLineString as it's a format compatable with H.map.Polyline
                const multiLineString = HereMapWrapper.createMultiLineString(lineStrings);
                setRoute(multiLineString, 'rgba(0, 128, 255, 0.7)');
            });
        } else if (!props.calculateRouteFromWaypoints && JSON.stringify(props.waypoints) !== previousWaypoints.current) {
            previousWaypoints.current = JSON.stringify(props.waypoints);

            const positions = HereMapWrapper.createLineString(props.waypoints.flat());
            setRoute(positions, props.green ? 'rgba(0, 255, 0, 1)' : 'rgba(255, 0, 0, 1)', true);
        }
    }, [props.waypoints, context]);

    const setRoute = (multiLineString, color, actualRoute = false) => {
        const newPolyline = HereMapWrapper.createPolyline(multiLineString, {
            style: {
                lineWidth: 4,
                strokeColor: color,
            },
            zIndex: actualRoute ? 10 : 0,
            data: {
                isMobileLocations: actualRoute && !props.green ? true : false,
                isTrailerLocations: actualRoute && props.green ? true : false,
            }
        });

        if (!polyline) {
            setPolyline(newPolyline);
            polylineId.current = newPolyline.getId();

            // Add the polyline to the map
            context.map.addObject(newPolyline);
        } else {
            polyline.setGeometry(multiLineString);
        }

        props.onRouteDrawn && props.onRouteDrawn();
    };

    return null;
};

HereMapRoute.propTypes = {
    calculateRouteFromWaypoints: PropTypes.bool,
    onRouteDrawn: PropTypes.func,
    waypoints: PropTypes.array,
    avoidanceZones: PropTypes.array,
    green: PropTypes.bool,
};
