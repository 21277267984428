import { orderToFilteredDateTimeRange, stopToDateTimeRange } from '../../../utils/data-mapping-utils';
import { filterForBookingConflicts } from '../../bookings/utils/booking-utils';
import { BookingConflictTooltip } from './tooltips/booking-conflict-tooltip';
import React from 'react';
import { Resource, Load, Booking } from '../../../types';
import { isCargoStop } from '../../../constants/constants';
import { Conflict } from './resource-conflict-utils';

export const useGetBookingConflict = (load: Load, bookings: Booking | undefined, resource: Resource): Conflict => {
    // Used for bookings, only uses valid stop arrival times
    
    let range;
    if (resource.stop) {
        if (!isCargoStop(resource.stop)) {
            return { conflict: null }
        }
        range = stopToDateTimeRange(resource.stop)
    } else {
        range = orderToFilteredDateTimeRange(load);
    }

    const conflict = filterForBookingConflicts(bookings, resource.type, resource.id, load.id, range, resource.stop?.organizationSubLocationIds);
    const conflictTooltip = conflict ? <BookingConflictTooltip conflict={conflict} /> : null;

    return { conflict: conflictTooltip };
};
