import { ImageOutlined } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { L } from 'harmony-language';
import React from 'react';
import { Image } from '../../types';
import { ModalDialog } from './containers/modal-dialog';
import { useBoolean } from './hooks/use-boolean';
import { ImageThumbnailExpand } from './image-thumbnail-expand';

const IconDiv = styled('div')({
    display: 'inline-block',
    'vertical-align': 'middle',
    margin: 0,
    padding: 0,
});

interface StyledGridProps {
    isLoading: boolean;
}

const StyledGrid = styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'isLoading',
})<StyledGridProps>(({ isLoading }) => ({
    flexGrow: 1,
    ...(isLoading && {
        width: '200px',
        height: '200px',
    })
}));

interface StopImagesModalProps {
    imageLabel: string;
    modalLabel: string;
    queryKey: string;
}

export const ImagesModal: React.FC<StopImagesModalProps> = (props) => {
    const { imageLabel, modalLabel, queryKey } = props;
    const [isOpen, open, close] = useBoolean(false);
    const [images, setImages] = React.useState<Image[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const onClick = async () => {
        setIsLoading(true);
        open();
        const images = (await axios.get<Image[]>(`${API_BASE}/api${queryKey}`)).data;
        setImages(images);
        setIsLoading(false);
    }

    return (
        <IconDiv>
            <IconButton sx={{ padding: 0 }} onClick={onClick} size='small' color='primary'>
                <ImageOutlined />
            </IconButton>
            <ModalDialog title={modalLabel} open={isOpen} onClose={() => close()}>
                <StyledGrid container direction='row' justifyContent='center' alignItems='center' spacing={1} isLoading={isLoading}>
                    {isLoading ? (
                        <CircularProgress size={'4em'} />
                    ):(
                        images.map(image => (
                            <Grid item key={image?.id}>
                                <ImageThumbnailExpand key={image?.id} image={image}
                                    label={imageLabel} />
                            </Grid>
                        ))
                    )}
                </StyledGrid>
            </ModalDialog>
        </IconDiv>
    );
}
