import React from 'react';
import { L, getCurrentLanguage } from 'harmony-language';
import { OrderGroup } from './use-order-group-table';
import { localDateShort } from '../../../utils/date-time-utils';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { OrganizationLocation } from '../../../types';
import { getTransCargoTypeLabel } from '../../../constants/constants';
import { OrderGroupUnlinkAction } from './order-group-unlink-action';
import { Link } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { toSiteDisplayName } from '../../../utils/data-mapping';
import { useWeights } from '../../shared/hooks/use-weights';
import { usePermissions } from '../../user/selectors/use-permissions';
import { PermissionType } from 'harmony-constants';
import { useDraftsKey, useLoadsKey } from '../../../api/config-hooks';
import { createColumnHelper } from '@tanstack/react-table';

const ArrayCell: React.FC<{ value?: string[] }> = (props) => {
    return <div>{props.value?.map(x => <div key={x}>{x}</div>)}</div>;
};

interface OrderGroupLinkProps {
    value: number;
    isClickable: boolean;
    queryKey: (string | number | number[])[];
    route: string;
}

const OrderGroupLink: React.FC<OrderGroupLinkProps> = (props) => {
    const {value, isClickable, route, queryKey} = props
    const queryClient = useQueryClient();

    return value && isClickable ?
        <Link onClick={async () => {
            await queryClient.invalidateQueries(queryKey);
        }} to={route}>{value}</Link>
        :
        <span>{value}</span>
}

export const useOrderGroupColumns = () => {
    const { data: locations }: { data?: OrganizationLocation[] } = useOrganizationQuery(OrgQueryKeys.locations);
    const languageCode = getCurrentLanguage()
    const { weightSystem, convertFromGramsDisplay } = useWeights();
    const canViewDrafts = usePermissions(PermissionType.EditOrder);
    const viewOwningOrders = usePermissions(PermissionType.ViewOwningOrders);
    const draftsKey = useDraftsKey();
    const loadsKey = useLoadsKey();
    const columnHelper = createColumnHelper<OrderGroup>();

    const columns = React.useMemo(() => {
        const c = [
            columnHelper.accessor((orderGroup) => {
                return [...new Set(orderGroup.dateRange.map(localDateShort))].join(' - ');
            }, {
                id: 'dateRange',
                header: L.dateRange(),
                cell: info => info.getValue(),
                enableColumnFilter: false,
            }),
            columnHelper.accessor((orderGroup) => {
                const locs = orderGroup.pickups.map(id => {
                    const location = locations?.find(l => l.id === id);
                    return location ? toSiteDisplayName(location.name, location.description) : '';
                });
                return locs;
            }, {
                id: 'pickups',
                header: L.origin(),
                cell: (info) => <ArrayCell value={info.getValue()} />,
                enableColumnFilter: false,
                size: 200,
            }),
            columnHelper.accessor((orderGroup) => {
                const locs = orderGroup.dropoffs.map(id => {
                    const location = locations?.find(l => l.id === id);
                    return location ? toSiteDisplayName(location.name, location.description) : '';
                });
                return locs;
            }, {
                id: 'dropoffs',
                header: L.destination(),
                cell: (info) => <ArrayCell value={info.getValue()} />,
                enableColumnFilter: false,
                size: 200,
            }),
            columnHelper.accessor((orderGroup) => {
                const cargos = orderGroup.cargo.map(c => {
                    const qtyOrWeight = c.quantity ? new Intl.NumberFormat(languageCode).format(c.quantity) : convertFromGramsDisplay(c.weight || 0);
                    return `${qtyOrWeight} - ${c.productionPlan ? c.productionPlan : getTransCargoTypeLabel(c.cargoTypeId)}`;
                });
                return cargos;
            }, {
                id: 'cargo',
                header: L.cargo(),
                cell: (info) => <ArrayCell value={info.getValue()} />,
                enableColumnFilter: false,
                enableSorting: false,
                size: 200,
            }),
            columnHelper.accessor('orderNumbers', {
                header: L.orderNumber(),
                cell: (info) => <ArrayCell value={info.getValue()} />,
                enableColumnFilter: false,
            }),
            columnHelper.accessor('lotIds', {
                header: `${L.pickUp()} #`,
                cell: (info) => <ArrayCell value={info.getValue()} />,
                enableColumnFilter: false,
            }),
            columnHelper.accessor('numberOfDraftLoads', {
                header: L.drafts(),
                cell: (info) => {
                    return (
                        <OrderGroupLink
                        value={info.getValue()}
                        isClickable={canViewDrafts}
                        queryKey={draftsKey}
                        route={`/drafts?orderGroupGuid=${info.row.original.guid}`} />
                    )
                },
                enableColumnFilter: false,
                size: 100,
            }),
            columnHelper.accessor('numberOfScheduledLoads', {
                header: L.scheduled(),
                cell: (info) => {
                    return (
                        <OrderGroupLink
                        value={info.getValue()}
                        isClickable={viewOwningOrders}
                        queryKey={loadsKey}
                        route={`/loads?orderGroupGuid=${info.row.original.guid}`} />
                    )
                },
                enableColumnFilter: false,
                size: 100,
            }),
            columnHelper.accessor('numberOfHistoricLoads', {
                header: L.delivered(),
                cell: (info) => {
                    <OrderGroupLink
                    value={info.getValue()}
                    isClickable={viewOwningOrders}
                    queryKey={loadsKey}
                    route={`/loads?orderGroupGuid=${info.row.original.guid}`} />
                },
                enableColumnFilter: false,
                size: 100,
            }),
            // @ts-ignore
            columnHelper.accessor('actions', {
                header: L.actions(),
                cell: (info) => <OrderGroupUnlinkAction orderGroup={info.row.original} />,
                enableColumnFilter: false,
                enableSorting: false,
            })
        ];
        return c;
    }, [locations, weightSystem]);

    return columns;
}
