import { TractorTrailerResource } from "harmony-constants/lib/src/db/constants";
import { L } from "harmony-language";
import React from "react";
import { DynamicQueryKeys } from "../../../api/config";
import { useAddOrganizationResourceMutation } from "../../../api/mutations/add/use-add-organization-resource-mutation";
import { useEditOrganizationResourceMutation } from "../../../api/mutations/edit/use-edit-organization-resource-mutation";
import { useOrganizationQuery } from "../../../api/queries/use-organization-query";
import { type ChecklistTemplate, type EditCreateComponentProps, type EquipmentResourcePath } from "../../../types";
import { ResourcePage } from "../resource-page";
import { EditCreateTractorChecklistTemplate } from "./edit-create-tractor-checklist-template";
import { EditCreateTrailerChecklistTemplate } from "./edit-create-trailer-checklist-template";
import { useChecklistTemplateColumns } from "./use-checklist-template-columns";

interface ChecklistTemplateManagementProps {
    equipmentType: EquipmentResourcePath<TractorTrailerResource>
}

export const ChecklistTemplateManagement = ({equipmentType}: ChecklistTemplateManagementProps) => {
    const {data: checklistTemplates, isLoading} = useOrganizationQuery<ChecklistTemplate[]>(DynamicQueryKeys.resourceTypeChecklistTemplates(equipmentType));
    const { mutate: addChecklistTemplate, isLoading: isCreating } = useAddOrganizationResourceMutation(DynamicQueryKeys.resourceTypeChecklistTemplates(equipmentType), L.checklistTemplate());
    const { mutate: editChecklistTemplate, isLoading: isUpdating } = useEditOrganizationResourceMutation(DynamicQueryKeys.resourceTypeChecklistTemplates(equipmentType), L.checklistTemplate());

    const columns = useChecklistTemplateColumns();

    async function submitChecklist(submittedChecklistTemplate: ChecklistTemplate, closeModal: () => void) {
        if (submittedChecklistTemplate.id) {
            editChecklistTemplate(submittedChecklistTemplate);
        } else {
            addChecklistTemplate(submittedChecklistTemplate);
        }
        closeModal();
    }

    return (
        <ResourcePage
            resourceType="checklistTemplate"
            isLoading={isLoading || isCreating || isUpdating}
            titleFunc={checklistTemplate => checklistTemplate.name}
            data={checklistTemplates}
            columns={columns}
            EditCreateComponent={(editCreateProps: EditCreateComponentProps<ChecklistTemplate>) =>
                equipmentType === 'tractor' ? (
                    <EditCreateTractorChecklistTemplate
                        existingChecklistTemplate={editCreateProps.resource}
                        onSubmit={submittedChecklist => submitChecklist(submittedChecklist, editCreateProps.closeModal)}
                    />
                ) : (
                    <EditCreateTrailerChecklistTemplate
                        existingChecklistTemplate={editCreateProps.resource}
                        onSubmit={submittedChecklist => submitChecklist(submittedChecklist, editCreateProps.closeModal)}
                    />
                )
            }
        />
    )
}