import React, { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import * as HereMapService from '../../services/here-maps/here-map-service';
import * as HereMapWrapper from '../../services/here-maps/here-map-wrapper';
import { HereMapContext, HereMapContextValue } from './here-map-context';
import '../../scss/here-map.scss';
import { Button } from '@mui/material';
import { L } from 'harmony-language';
import { cancellablePromise } from '../../utils/cancellable-promise';
import { HereMapControl } from './here-map-control';

export interface HereMapRefObject {
    fitMapToAllObjects: (forceReset: boolean, zoomLevel: number | undefined, avoidanceInBounds: boolean) => void;
    centerMap: (latitude: number, longitude: number, zoomLevel: number) => void;
}

export interface HereMapControlOptions {
    showMobileLocations?: boolean;
    showTrailerLocations?: boolean;
    showMobileHeading?: boolean;
    showTrailerHeading?: boolean;
    showMobileEvents?: boolean;
    showTrailerEvents?: boolean;
    showAugerOn?: boolean;
}

interface HereMapProps {
    id?: string;
    defaultZoom?: number;
    centerPosition?: {
        lat: number;
        lng: number;
    };
    onClick?: (coordinates: { lat: number, lng: number }) => void;
    className?: string;
    children: React.ReactNode;
    controlOptions?: HereMapControlOptions;
}

export const HereMap = forwardRef((props: HereMapProps, ref: Ref<HereMapRefObject>) => {
    const { id, defaultZoom, centerPosition, onClick, className, children, controlOptions } = props;

    // using state object like this because having them in seperate useState() variables causes re-render on each set
    // which will cause other components (HereMapRectangle) to incorrectly re-render when 'ui' is not avaliable yet.
    const [mapState, setMapState] = useState({} as HereMapContextValue);
    const [error, setError] = useState();
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (!isLoaded) {
            const creationPromise = cancellablePromise(HereMapWrapper.createHereMapInstance(id || 'here-map', defaultZoom, centerPosition));

            creationPromise.promise.then(({ map, ui, behavior }) => {
                setMapState({
                    map,
                    ui,
                    behavior
                });
                setIsLoaded(true);
            }).catch(({ isCancelled, ...e }) => {
                if (isCancelled) {
                    return;
                }
                /* eslint-disable-next-line */
                console.error(e); // Keeping this here in order to see what's wrong with here maps
                setError(e);
            });

            // cleanup componentWillUnmount()
            return () => {
                creationPromise.cancel();
            };
        }
    }, []);

    // as a react FC, the inputed onClick prop does not update the attached addEventListener when onClick is changed in the parent
    // tried to move to react onClick behavior, but there is a difference between onClick and 'tap' in functionality here
    useEffect(() => {
        if (onClick && mapState.map) {
            const handler = (event: any) => {
                //@ts-ignore
                const coordinates = mapState.map.screenToGeo(event.currentPointer.viewportX, event.currentPointer.viewportY);
                onClick(coordinates);
            }
            //@ts-ignore
            mapState.map.addEventListener('tap', handler);
            //@ts-ignore
            return () => mapState.map.removeEventListener('tap', handler);
        }
    }, [onClick, mapState.map]);

    const fitMapToAllObjects = (forceReset: boolean, zoomLevel: number | undefined, avoidanceInBounds: boolean) => {
        HereMapService.fitMapToAllObjects(mapState.map, centerPosition, forceReset, zoomLevel, avoidanceInBounds);
    }

    // https://stackoverflow.com/questions/37949981/call-child-method-from-parent
    // https://react.dev/reference/react/useImperativeHandle
    // also notice this whole thing is wrapped in forwardRef() at definition
    // if having unexpected problems with this locally, I think hot reload messes with ref sometimes
    // a fresh reload should fix, this needs to likely be reworked
    useImperativeHandle(ref, () => {
        return {
            fitMapToAllObjects(forceReset, zoomLevel, avoidanceInBounds) {
                fitMapToAllObjects(forceReset, zoomLevel, avoidanceInBounds);
            },
            centerMap(latitude, longitude, zoomLevel) {
                HereMapService.centerMap(mapState.map, latitude, longitude, zoomLevel);
            },
        }
    });

    if (error) {
        return (
            <div className={className || 'here-map'} id={id || 'here-map'}>
                <div className='error'>
                    <p>{L.mapCurrentlyUnavailable()}</p>
                </div>
            </div>
        );
    } else {
        return (
            <HereMapContext.Provider value={{ map: mapState.map, ui: mapState.ui, behavior: mapState.behavior }}>
                <div className={className || 'here-map'} id={id || 'here-map'}>
                    {children}
                    <Button
                        variant='contained'
                        color='inherit'
                        sx={{ position: 'absolute', top: '1em', right: '1em', zIndex: 500 }}
                        onClick={() => fitMapToAllObjects(true, defaultZoom, false)}>{L.recenterMap()}</Button>
                    {controlOptions && Object.values(controlOptions).some(x => x) &&
                        <HereMapControl
                            map={mapState.map} 
                            controlOptions={controlOptions} />
                    }
                </div>
            </HereMapContext.Provider>
        );
    }
});
