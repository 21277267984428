import React from 'react';
import { useReplaceOrganizationLocationThroughputs } from '../mutations/replace/use-replace-organization-location-throughputs';
import axios from 'axios';
import { OrganizationLocation, Throughput } from '../../types';

export const useOrgLocationThroughputs = (organizationLocation: OrganizationLocation | null) => {
    const [throughputs, setThroughputs] = React.useState<Throughput[]>([]);
    const [isLoadingThroughputs, setIsLoadingThroughputs]= React.useState<boolean>(true);

    React.useEffect(() => {
        const fetchData = async () => {
            if (organizationLocation) {
                const throughputs = (await axios.get(`${API_BASE}/api/organizations/${organizationLocation?.organizationId}/locations/${organizationLocation?.id}/throughput`)).data
                setThroughputs(throughputs);
                setIsLoadingThroughputs(false);
            }
        }

        fetchData()
    }, [organizationLocation])

    const { mutateAsync: replaceLocationThroughputs } = useReplaceOrganizationLocationThroughputs();

    const onThroughputSubmit = async (organizationLocationId: number) => {
        const filteredThroughputs = throughputs.filter(x => {
            return !isNaN(parseInt(x.baselineLoadingMinutes, 10)) || !isNaN(parseInt(x.cargoUnitsPerMinute, 10)) || !isNaN(parseInt(x.weightPerMinute, 10));
        })
        await replaceLocationThroughputs({throughputs: filteredThroughputs, locationId: organizationLocationId})
    };

    return { throughputs, isLoadingThroughputs, setThroughputs, onThroughputSubmit };
};
