import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { Load, LoadCache } from '../../../types';
import { useDraftsKey } from '../../config-hooks';

async function addDraft(options: { load: Load }) {
    const orderDate = options.load?.stops?.map(x => x.arrivalTime).sort()[0] || options.load?.date || new Date();
    const load = {
        ...options.load,
        isDraft: true,
        date: orderDate
    };

    return (await axios.post(`${API_BASE}/api/orders`, load)).data;
}

export const useAddDraftMutation = () => {
    const snackbar = useSnackbar();
    const draftsKey = useDraftsKey();
    const queryClient = useQueryClient();

    return useMutation(addDraft, {
        ...mutationOptions(draftsKey, queryClient),
        onSuccess: (data) => {
            //map organizationId to owningOrganizationId as web project only knows organizationId
            data.organizationId = data.owningOrganizationId;
            const previousCache = queryClient.getQueryData<LoadCache>(draftsKey);

            if (previousCache) {
                queryClient.setQueryData(draftsKey, {
                    ...previousCache,
                    data: previousCache.data.concat(data).sortBy(u => u.id)
                });
                snackbar(L.loadAddedSuccessfully());
            }
        },
        onSettled: () => {}
    });
};
