import React from 'react';
import { Organization } from '../../../../types';
import { useEditOrganizationCarrier } from '../../../../api/mutations/edit/use-edit-organization-carrier' ;
import { Box, Button, Checkbox, Grid, List, ListItem, Typography } from '@mui/material';
import { L } from 'harmony-language';
import { CarrierEditLoadOption } from 'harmony-constants';
import { CarrierSettingSelect } from './carrier-setting-select';
import { AgisticsWarningIcon } from '../../../shared/agistics-warning-icon';

type CarrierManagmentProps = {
    organization: Organization;
    organizations: Organization[];
    onSubmit: () => void;
};

export const CarrierManagement: React.FC<CarrierManagmentProps> = (props) => {
    const selectedOrg = props.organization;
    const organizations = props.organizations;
    const organizationCarriers = selectedOrg.carriers ? selectedOrg.carriers.map(x => x.organizationCarrier) : [];
    const [selectedOrganizationCarriers, setSelectedOrganizationCarriers] = React.useState(organizationCarriers);
    const { mutate: editCarriers, isLoading: isUpdating } = useEditOrganizationCarrier(selectedOrg.id);
    const avaliableCarrierOrgs = organizations.filter(org => org.isCarrier);

    const resourceEditList = React.useMemo(() =>{
        const options = Object.keys(CarrierEditLoadOption);
        return options.map(x => ({ id: x, label: x }));
    }, []);

    return (
        <Box sx={{width: '40vw'}}>
            <Grid container justifyContent='space-between' alignItems='center' direction='row'>
                <Grid item xs={12} md={4}>
                    <Typography>{L.carrierName()}</Typography>
                </Grid>
                <Grid item xs={12} md={1}>
                    <Typography>{L.enabled()}</Typography>
                </Grid>
                <Grid item xs={12} md={1}>
                    <Typography>{L.active()}</Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography>{L.driver()}</Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography>{L.tractor()}</Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography>{L.trailer()}</Typography>
                </Grid>
            </Grid>

            <List dense disablePadding>
                {avaliableCarrierOrgs.map(avaliableCarrier => {
                    const isChecked = selectedOrganizationCarriers.some(oc => oc.carrierOrganizationId === avaliableCarrier.id);
                    const isDisabled = selectedOrganizationCarriers.some(oc => oc.carrierOrganizationId === avaliableCarrier.id);
                    const isActive = selectedOrganizationCarriers.find(oc => oc.carrierOrganizationId === avaliableCarrier.id)?.isActive || false;

                    const driverSetting = selectedOrganizationCarriers.find(oc => oc.carrierOrganizationId === avaliableCarrier.id)?.loadDriver;
                    const tractorSetting = selectedOrganizationCarriers.find(oc => oc.carrierOrganizationId === avaliableCarrier.id)?.loadTractor;
                    const trailerSetting = selectedOrganizationCarriers.find(oc => oc.carrierOrganizationId === avaliableCarrier.id)?.loadTrailer;

                    const selectdOrdCargoTypeIds = selectedOrg.cargoTypes.map(x => x.id);
                    const avaliableCarrierCargoTypeIds = avaliableCarrier.cargoTypes.map(x => x.id);
                    const carrierHasAllCargoTypes = selectdOrdCargoTypeIds.every(r => avaliableCarrierCargoTypeIds.includes(r));

                    return (
                        <ListItem key={avaliableCarrier.id} dense disableGutters>
                            <Grid container justifyContent='space-between' alignItems='center' direction='row'>
                                <Grid item xs={12} md={4}>
                                    <span>
                                        {!carrierHasAllCargoTypes && <AgisticsWarningIcon message={L.carrierCargoTypeWarning()} /> }
                                        <label>{avaliableCarrier.name}</label>
                                    </span>
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <Checkbox
                                        checked={isChecked}
                                        // this is a little different then the isDisabled above, as once one of these relationships is created
                                        // it cannot be removed.  Just deactivated.
                                        disabled={organizationCarriers.some(oc => oc.carrierOrganizationId === avaliableCarrier.id)}
                                        id={`${avaliableCarrier.name}-${avaliableCarrier.id}`}
                                        onChange={() => {
                                            if (isChecked) {
                                                setSelectedOrganizationCarriers(selectedOrganizationCarriers.filter(oc => oc.carrierOrganizationId !== avaliableCarrier.id));
                                            } else {
                                                setSelectedOrganizationCarriers(selectedOrganizationCarriers.concat({
                                                    id: null,
                                                    organizationId: selectedOrg.id,
                                                    carrierOrganizationId: avaliableCarrier.id,
                                                    isActive: false,
                                                    loadDriver: CarrierEditLoadOption.Carrier,
                                                    loadTractor: CarrierEditLoadOption.Carrier,
                                                    loadTrailer: CarrierEditLoadOption.Carrier,
                                                }));
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <Checkbox
                                        checked={isActive}
                                        disabled={!isChecked}
                                        onChange={() => {
                                            if (isActive) {
                                                //remove active from carrier
                                                const carrierIsNoLongerActive = selectedOrganizationCarriers.find(x => x.carrierOrganizationId === avaliableCarrier.id);
                                                if (carrierIsNoLongerActive) {
                                                    const updatedSelectedOrganizationCarriers = selectedOrganizationCarriers.map(x => {
                                                        if (x.carrierOrganizationId === carrierIsNoLongerActive.carrierOrganizationId) {
                                                            return { ...x, isActive: false }
                                                        }
                                                        return x;
                                                    })
                                                    setSelectedOrganizationCarriers(updatedSelectedOrganizationCarriers);
                                                }
                                            } else {
                                                //set carrier as active
                                                const carrierIsActiveAgain = selectedOrganizationCarriers.find(x => x.carrierOrganizationId === avaliableCarrier.id);
                                                if (carrierIsActiveAgain) {
                                                    const updatedSelectedOrganizationCarriers = selectedOrganizationCarriers.map(x => {
                                                        if (x.carrierOrganizationId === carrierIsActiveAgain.carrierOrganizationId) {
                                                            return { ...x, isActive: true }
                                                        }
                                                        return x;
                                                    })
                                                    setSelectedOrganizationCarriers(updatedSelectedOrganizationCarriers);
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} style={{ paddingRight: '1em' }}>
                                    {driverSetting ? <CarrierSettingSelect
                                        resourceEditList={resourceEditList}
                                        setting={driverSetting}
                                        isDisabled={isDisabled}
                                        isActive={isActive}
                                        selectedOrganizationCarriers={selectedOrganizationCarriers}
                                        avaliableCarrierId={avaliableCarrier.id}
                                        onChange={setSelectedOrganizationCarriers}
                                        propertyName={'loadDriver'}
                                    /> : null}
                                </Grid>
                                <Grid item xs={12} md={2} style={{ paddingRight: '1em' }}>
                                    {tractorSetting ? <CarrierSettingSelect
                                        resourceEditList={resourceEditList}
                                        setting={tractorSetting}
                                        isDisabled={isDisabled}
                                        isActive={isActive}
                                        selectedOrganizationCarriers={selectedOrganizationCarriers}
                                        avaliableCarrierId={avaliableCarrier.id}
                                        onChange={setSelectedOrganizationCarriers}
                                        propertyName={'loadTractor'}
                                    /> : null}
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {trailerSetting ? <CarrierSettingSelect
                                        resourceEditList={resourceEditList}
                                        setting={trailerSetting}
                                        isDisabled={isDisabled}
                                        isActive={isActive}
                                        selectedOrganizationCarriers={selectedOrganizationCarriers}
                                        avaliableCarrierId={avaliableCarrier.id}
                                        onChange={setSelectedOrganizationCarriers}
                                        propertyName={'loadTrailer'}
                                    /> : null}
                                </Grid>
                            </Grid>
                        </ListItem>
                    );
                })}
            </List>
            <Button type='button'
                disabled={isUpdating}
                title='Save'
                variant='contained'
                color='primary'
                sx={{ float: 'right', margin: '0 0.75rem 0.75rem 0' }}
                onClick={() => {
                    editCarriers({ carriers: selectedOrganizationCarriers });
                    props.onSubmit();
                }}
            >{L.save()}</Button>
        </Box>
    );
};
