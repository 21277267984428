import React from 'react';
import { styled } from '@mui/material/styles';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { L } from 'harmony-language';
import Typography from '@mui/material/Typography';

const Container = styled('div')({
    width: '35rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
});

const CheckMark = styled(CheckCircle)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '8rem',
}));

const Controls = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '1rem',
});

const SuccessButton = styled(Button)({
    width: '15rem',
});

interface BulkConfirmationProps {
    onCloseBulkCompletion: (b: boolean) => void;
}

export const BulkConfirmation: React.FC<BulkConfirmationProps> = (props) => {
    const { onCloseBulkCompletion } = props;
    const [noShowAgain, setNoShowAgain] = React.useState(false);

    return (
        <Container data-testid='bulk-confirmation'>
            <CheckMark />
            <Typography variant='h5'>{L.bulkConfirmation()}</Typography>
            <Controls>
                <SuccessButton variant='contained' onClick={() => onCloseBulkCompletion(noShowAgain)}>{L.okayGotIt()}</SuccessButton>
                <FormControlLabel
                    value={noShowAgain}
                    control={<Checkbox onChange={() => setNoShowAgain(!noShowAgain)} color='primary'/>}
                    label={L.noShowAgain()}
                    labelPlacement='end'
                />
            </Controls>
        </Container>
    );
};
