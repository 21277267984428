import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { DynamicQueryKeys, mutationOptions } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { Permission } from '../../../types';

type DeleteRolePermssion = {
    roleId: number;
    permissionId: number;
}

const deleteRolePermission = async (rolePermission: DeleteRolePermssion) => {
    return (await axios.delete(`${API_BASE}/api/role-permissions`, { data: rolePermission })).data;
}

export const useDeleteRolePermissionMutation = (roleId: number, permissionId: number) => {
    const snackbar = useSnackbar();
    const key = DynamicQueryKeys.rolePermissions(roleId);
    const queryClient = useQueryClient();

    return useMutation(deleteRolePermission, {
        ...mutationOptions([key], queryClient),
        onSuccess: () => {
            const rolePermissions = queryClient.getQueryData<Permission[]>([key]);
            if (rolePermissions) {
                const newRolePermissions = rolePermissions?.filter(x => x.id !== permissionId);
                queryClient.setQueryData([key], newRolePermissions);
                snackbar(L.removedRolePermission());
            }
        },
        onSettled: () => {},
    });
};
