import React, { useEffect } from 'react';
import { HereMapContext } from './here-map-context';
import { HerePoint } from '../../types';
import { distributedCopy, indexOnLine, lineStringToHerePointArray, removeMapObjectById } from './standard-route-helpers';

const svgCircle = '<svg width="20" height="20" version="1.1" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="7" fill="transparent" stroke="red" stroke-width="4"/></svg>';

export const useDraggableStandardRoute = (
    flexiblePolyline: string,
    setDraggableRoute: (h: HerePoint[]) => void
) => {
    const context = React.useContext(HereMapContext);

    useEffect(() => {
        let mainGroupId = 0;
        if (context.map) {
            const lineString = H.geo.LineString.fromFlexiblePolyline(flexiblePolyline);

            const herePoints = lineStringToHerePointArray(lineString);
            const distributedPoints = distributedCopy(herePoints);
            setDraggableRoute(distributedPoints);

            const andBackToLineString = distributedPoints.flatMap(x => [x.lat, x.lng, 0]);
            const newLineString = new H.geo.LineString(andBackToLineString);

            const polyline = new H.map.Polyline(newLineString, {
                style: {
                    lineWidth: 4,
                    strokeColor: 'rgba(0, 55, 255, 1)',
                },
            });

            const verticeGroup = new H.map.Group({ visibility: false });
            const mainGroup = new H.map.Group({
                // @ts-ignore
                volatility: true, // mark the group as volatile for smooth dragging of all it's objects
                objects: [polyline, verticeGroup],
            });
            let polylineTimeout: NodeJS.Timeout | null;

            // ensure that the polyline can receive drag events
            // @ts-ignore
            polyline.draggable = true;

            // create markers for each polyline's vertice which will be used for dragging
            const addVerticesToVerticeGroup = () => {
                const lineString = polyline.getGeometry();
                if (lineString instanceof H.geo.LineString) {
                    lineString.eachLatLngAlt((lat, lng, _, index) => {
                        const vertice = new H.map.Marker({ lat, lng }, { icon: new H.map.Icon(svgCircle, { anchor: { x: 10, y: 10 }})});
                        vertice.draggable = (index === 0 || index + 1 === lineString.getPointCount()) ? false : true;
                        vertice.setData({ 'verticeIndex': index })
                        verticeGroup.addObject(vertice);
                    });
                }
            }

            addVerticesToVerticeGroup();

            mainGroupId = mainGroup.getId();
            // add group with polyline and it's vertices (markers) on the map
            context.map.addObject(mainGroup);

            // event listener for main group to show markers if moved in with mouse (or touched on touch devices)
            mainGroup.addEventListener('pointerenter', (_) => {
                if (polylineTimeout) {
                    clearTimeout(polylineTimeout);
                    polylineTimeout = null;
                }

                // show vertice markers
                verticeGroup.setVisibility(true);
            }, true);

            // event listener for main group to hide vertice markers if moved out with mouse (or released finger on touch devices)
            // the vertice markers are hidden on touch devices after specific timeout
            mainGroup.addEventListener('pointerleave', (evt) => {
                // @ts-ignore
                const timeout = (evt.currentPointer.type == 'touch') ? 1000 : 0;

                // hide vertice markers
                polylineTimeout = setTimeout(() => {
                    verticeGroup.setVisibility(false);
                }, timeout);
            }, true);

            mainGroup.addEventListener('tap', (evt) => {
                // @ts-ignore
                const pointer = evt.currentPointer;
                const geoLineString = polyline.getGeometry();
                const geoPoint = context.map.screenToGeo(pointer.viewportX, pointer.viewportY);
                if (geoLineString instanceof H.geo.LineString) {
                    const herePoints = lineStringToHerePointArray(geoLineString);
                    const afterIndex = indexOnLine(herePoints, geoPoint.lat, geoPoint.lng);
                    if (afterIndex !== null) {
                        geoLineString.insertPoint(afterIndex + 1, geoPoint);
                        polyline.setGeometry(geoLineString);

                        // fixes edge cases with 'drag' eventHandler capturing wrong evt.target
                        verticeGroup.removeAll();
                        addVerticesToVerticeGroup();

                        const newDraggableRoutePoints = lineStringToHerePointArray(geoLineString);
                        setDraggableRoute(newDraggableRoutePoints);
                    }
                }
            }, true);

            // event listener for vertice markers group to change the cursor to pointer if mouse position enters this group
            verticeGroup.addEventListener('pointerenter', (_) => {
                document.body.style.cursor = 'pointer';
            }, true);

            // event listener for vertice markers group to change the cursor to default if mouse leaves this group
            verticeGroup.addEventListener('pointerleave', (_) => {
                document.body.style.cursor = 'default';
            }, true);

            // event listener for vertice markers group to resize the geo polyline object if dragging over markers
            verticeGroup.addEventListener('drag', (evt) => {
                // @ts-ignore
                const pointer = evt.currentPointer;
                const geoLineString = polyline.getGeometry();
                const geoPoint = context.map.screenToGeo(pointer.viewportX, pointer.viewportY);

                // set new position for vertice marker
                // @ts-ignore
                evt.target.setGeometry(geoPoint);

                // set new position for polyline's vertice
                // @ts-ignore
                geoLineString.removePoint(evt.target.getData()['verticeIndex']);
                // @ts-ignore
                geoLineString.insertPoint(evt.target.getData()['verticeIndex'], geoPoint);
                polyline.setGeometry(geoLineString);

                // stop propagating the drag event, so the map doesn't move
                evt.stopPropagation();
            }, true);

            verticeGroup.addEventListener('dragend', (_) => {
                const geoLineString = polyline.getGeometry();
                if (geoLineString instanceof H.geo.LineString) {
                    const herePoints = lineStringToHerePointArray(geoLineString);
                    setDraggableRoute(herePoints);
                }
            }, true);
        }

        return () => {
            if (context.map) {
                removeMapObjectById(context, mainGroupId);
            }
        }
    }, [context.map]);
}
