import React from 'react';
import { L } from 'harmony-language';
import { CargoType, Contact, Customer, KeysWithValsOfType, NotificationConfig, OrganizationLocation } from '../../../types';
import { notificationEventTypeIdToName, siteIdsToNames, customerIdsToNames, cargoTypesToNames } from './name-helpers';
import { List, ListItem, ListItemText, Tooltip, Typography } from '@mui/material';
import { ActionIcon } from '../../shared/table/components/action-icon';
import { NotificationEventTypes, NotificationType } from 'harmony-constants';
import { createColumnHelper } from '@tanstack/react-table';

type NotificationConfigEventTypeProps = {
    notificationEventName: string;
};

const NotificationConfigEventType = (props: NotificationConfigEventTypeProps) => {
    const { notificationEventName } = props;

    return (
        <span>{notificationEventName}</span>
    );
};

type NotificationConfigRenderProps = {
    names: string[];
    isGreyedOut: boolean | undefined;
}

const NotificationConfigRender = (props: NotificationConfigRenderProps) => {
    const { names, isGreyedOut } = props;

    if (isGreyedOut) {
        return <div className='grey' style={{ height: '100%' }}></div>
    }

    return (
        <List dense disablePadding>
            {names.map((name, i) => {
                return (
                    <ListItem key={i} disableGutters>
                        <ListItemText primary={name} />
                    </ListItem>
                );
            })}
        </List>
    );
}

interface ContactsRenderProps {
    contactIds: number[];
    contacts: Contact[];
    notificationEventTypeId: number;
}

const RenderContacts = (props: ContactsRenderProps) => {
    const { contactIds, contacts, notificationEventTypeId } = props;

    return (
        <List dense disablePadding>
            {contactIds.map((contactId, i) => {
                const contact = contacts.find(contact => contact.id === contactId);
                const preferencesForEvent = contact?.contactPreferences?.filter(x => x.notificationEventTypeId === notificationEventTypeId);

                const prefText = preferencesForEvent?.find(x => x.notificationType === NotificationType.Text);
                const prefEmail = preferencesForEvent?.find(x => x.notificationType === NotificationType.Email);
                const prefPush = preferencesForEvent?.find(x => x.notificationType === NotificationType.Push);

                const tooltip = <div>
                    <Typography style={{ fontSize: 'larger' }}>{`${L.text()}: ${prefText ? L.yes() : L.no()}`}</Typography>
                    <Typography style={{ fontSize: 'larger' }}>{`${L.email()}: ${prefEmail ? L.yes() : L.no()}`}</Typography>
                    <Typography style={{ fontSize: 'larger' }}>{`${L.inApp()}: ${prefPush ? L.yes() : L.no()}`}</Typography>
                </div>;

                return (
                    <Tooltip
                        key={i}
                        title={tooltip}
                        arrow
                        placement='right'>
                        <ListItem disableGutters style={{ width: 'fit-content'}}>
                            <ListItemText style={{ color: preferencesForEvent && preferencesForEvent.length > 0 ? 'black' : 'red' }} primary={contact?.name} />
                        </ListItem>
                    </Tooltip>
                );
            })}
        </List>
    );
};

type CellIdType = KeysWithValsOfType<Omit<NotificationConfig, 'contactIds'>, number[] | null>;

const greyedOut = (cellId: CellIdType, row: NotificationConfig) => {
    const notificationEventType = Object.values(NotificationEventTypes).find(x => x.id === row.notificationEventTypeId);
    return notificationEventType?.removedOptions?.includes(cellId);
}

export const useNotificationConfigColumns = (contacts: Contact[], organizationLocations: OrganizationLocation[], customers: Customer[], cargoTypeList: CargoType[]) => {
    const [editConfig, setEditConfig] = React.useState<NotificationConfig>();
    const [deleteConfig, setDeleteConfig] = React.useState<NotificationConfig>();
    const columnHelper = createColumnHelper<NotificationConfig>();

    const actions = [
        {
            icon: 'edit',
            tooltip: L.edit(),
            action: (row: NotificationConfig) => setEditConfig(row),
        },
        {
            icon: 'delete',
            tooltip: L.delete(),
            action: (row: NotificationConfig) => setDeleteConfig(row),
        },
    ];

    const columns = React.useMemo(() => {
        const c = [
            columnHelper.accessor('notificationEventTypeId', {
                header: L.notificationEventType(),
                cell: (info) => {
                    const value = notificationEventTypeIdToName(info.row.original.notificationEventTypeId)
                    return <NotificationConfigEventType notificationEventName={value} />
                },
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor((notificationConfig) => {
                const contactNameForFilter = notificationConfig.contactIds.map(x => {
                    const contact = contacts.find(contact => contact.id === x);
                    return contact?.name;
                });
                return contactNameForFilter.join();
            }, {
                header: L.contacts(),
                cell: (info) => {
                    return (
                        <RenderContacts
                            contactIds={info.row.original.contactIds}
                            contacts={contacts}
                            notificationEventTypeId={info.row.original.notificationEventTypeId} />
                    );
                },
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor('originSiteIds', {
                header: L.originLocations(),
                cell: (info) => {
                    const value = siteIdsToNames(info.row.original.originSiteIds, organizationLocations);
                    const isGreyedOut = greyedOut('originSiteIds', info.row.original);

                    return (
                        <NotificationConfigRender names={value} isGreyedOut={isGreyedOut} />
                    );
                },
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor('destinationSiteIds', {
                header: L.destinationLocations(),
                cell: (info) => {
                    const value = siteIdsToNames(info.row.original.destinationSiteIds, organizationLocations);
                    const isGreyedOut = greyedOut('destinationSiteIds', info.row.original);

                    return (
                        <NotificationConfigRender names={value} isGreyedOut={isGreyedOut} />
                    );
                },
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor('customerIds', {
                header: L.customers(),
                cell: (info) => {
                    const value = customerIdsToNames(info.row.original.customerIds, customers);
                    const isGreyedOut = greyedOut('customerIds', info.row.original);

                    return (
                        <NotificationConfigRender names={value} isGreyedOut={isGreyedOut} />
                    );
                },
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor('stopCargoTypeIds', {
                header: L.stopCargoTypes(),
                cell: (info) => {
                    const value = cargoTypesToNames(info.row.original.stopCargoTypeIds, cargoTypeList);
                    const isGreyedOut = greyedOut('stopCargoTypeIds', info.row.original);

                    return (
                        <NotificationConfigRender names={value} isGreyedOut={isGreyedOut} />
                    );
                },
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor('trailerCargoTypeIds', {
                header: L.trailerCargoTypes(),
                cell: (info) => {
                    const value = cargoTypesToNames(info.row.original.trailerCargoTypeIds, cargoTypeList);
                    const isGreyedOut = greyedOut('trailerCargoTypeIds', info.row.original);

                    return (
                        <NotificationConfigRender names={value} isGreyedOut={isGreyedOut} />
                    );
                },
                enableColumnFilter: false,
                enableSorting: false,
            }),
            // @ts-ignore
            columnHelper.accessor('actions', {
                header: L.actions(),
                cell: (info) => {
                    const row = info.row.original;

                    return (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {actions.map(a => <ActionIcon key={a.icon} action={() => a.action(row)} icon={a.icon}
                                                           tooltip={a.tooltip} testId={row.id}/>)}
                        </div>
                    );
                },
                enableColumnFilter: false,
                enableSorting: false,
            }),
        ];

        return c;
    }, [contacts, organizationLocations, customers, cargoTypeList]);

    return { columns, editConfig, setEditConfig, deleteConfig, setDeleteConfig };
};
