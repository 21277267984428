import React from 'react';
import { HereMap, HereMapRefObject } from '../../../here-maps/here-map';
import { HereMapMarker } from '../../../here-maps/here-map-marker';
import { HereMapRectangle } from '../../../here-maps/here-map-rectangle';
import { Button } from '@mui/material';
import { SublocationCircle } from '../../../here-maps/sublocation-circle';
import { useOrgLocationColorProxy } from '../../../here-maps/use-org-location-color-proxy';
import { Bbox, Organization, Point } from '../../../../types';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { EitherOrganizationLocation } from './edit-create-location';
import { pointToBbox } from 'harmony-constants';

interface LocationMapProps {
    mapRef: React.RefObject<HereMapRefObject>;
    organizationLocation: EitherOrganizationLocation;
    setOrganizationLocation: (e: EitherOrganizationLocation) => void;
    helperMarker: Point | null;
}

export const LocationMap: React.FC<LocationMapProps> = (props) => {
    const { mapRef, organizationLocation, setOrganizationLocation, helperMarker } = props;

    const organizationQuery = useOrganizationQuery<Organization>('');
    const [sublocationIndex, setSublocationIndex] = React.useState<number | null>(null);

    const onClick = (coords: { lat: number, lng: number }) => {
        const point = {
            latitude: coords.lat,
            longitude: coords.lng,
        };

        if (sublocationIndex !== null) {
            const changed = [...organizationLocation.orgSubLocations];
            const oldSublocation = changed.at(sublocationIndex);
            const updatedSublocation = {
                ...oldSublocation,
                customGeofence: {
                    id: oldSublocation?.customGeofenceId,
                    ...point,
                }
            }
            // @ts-ignore
            changed[sublocationIndex] = updatedSublocation;
            setOrganizationLocation({
                ...organizationLocation,
                orgSubLocations: changed,
            });
            setSublocationIndex(null);
        } else {
            setOrganizationLocation({
                ...organizationLocation,
                customGeofence: {
                    ...organizationLocation.customGeofence,
                    ...point,
                    bbox: pointToBbox(point),
                }
            });
        }
    };

    const renderHelperMarker = () => {
        if (helperMarker) {
            return <HereMapMarker latitude={helperMarker.latitude} longitude={helperMarker.longitude} />;
        }
    };

    const onShapeChanged = (changedShape: { latitude: number, longitude: number, bbox: Bbox }) => {
        setOrganizationLocation({
            ...organizationLocation,
            customGeofence: {
                ...organizationLocation.customGeofence,
                ...changedShape,
            }
        });
    };

    const locationShape = () => {
        const { bbox } = organizationLocation.customGeofence;
        const topLeft = { lat: bbox.topLeft.latitude, lng: bbox.topLeft.longitude };
        const bottomRight = { lat: bbox.bottomRight.latitude, lng: bbox.bottomRight.longitude };

        return (
            <HereMapRectangle
                pointA={topLeft}
                pointB={bottomRight}
                strokeColor='none'
                fillColor={useOrgLocationColorProxy()[organizationLocation.organizationLocationTypeId].fill}
                type='site'
                changeable={true}
                onChanged={onShapeChanged}
            />
        );
    };

    const sublocationCircles = () => {
        const circles = organizationLocation?.orgSubLocations?.map((x, i) => {
            if (x?.customGeofence) {
                return (
                    <SublocationCircle
                        organizationSubLocation={x}
                        key={i}
                    />
                )
            }
            return null;
        });
        return circles;
    }

    const tagLocationButtons = () => {
        if (organizationLocation.orgSubLocations) {
            const buttons = organizationLocation.orgSubLocations.map((x, i) => {
                if (x.name !== '') {
                    return (
                        <Button
                            key={i}
                            sx={{ marginTop: '1em', left: '1em', zIndex: 10 }}
                            variant='contained'
                            color='inherit'
                            onClick={() => setSublocationIndex(i)}
                        >{x.name}</Button>
                    )
                }
            });
            return <div style={{ position: 'absolute', display: 'grid' }}>{buttons}</div>;
        }
    }

    if (!organizationQuery.isSuccess) {
        return null;
    }

    const mapStart = 'id' in organizationLocation ? {
        lat: organizationLocation.customGeofence.latitude,
        lng: organizationLocation.customGeofence.longitude,
    } : {
        lat: organizationQuery.data.latitude,
        lng: organizationQuery.data.longitude,
    }

    return (
        <HereMap
            ref={mapRef}
            className='location-map'
            id='location-map'
            defaultZoom={'id' in organizationLocation ? 16 : 14}
            onClick={onClick}
            centerPosition={mapStart}
        >
            {renderHelperMarker()}
            {locationShape()}
            {tagLocationButtons()}
            {sublocationCircles()}
        </HereMap>
    );
};
