import React from 'react';
import { L } from 'harmony-language';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { useAddOrganizationResourceMutation } from '../../../api/mutations/add/use-add-organization-resource-mutation';
import {
    useEditOrganizationResourceMutation
} from '../../../api/mutations/edit/use-edit-organization-resource-mutation';
import { ResourcePage } from '../resource-page';
import { useLoadingTeamColumns } from './use-loading-team-columns';
import { EditCreateLoadingTeam } from './edit-create-loading-team/edit-create-loading-team';

export const LoadingTeamManagement = () => {
    const { data: loadingTeams, isLoading } = useOrganizationQuery(OrgQueryKeys.loadingTeams);
    const { mutate: addLoadingTeam, isLoading: isCreating } = useAddOrganizationResourceMutation(OrgQueryKeys.loadingTeams, L.loadingTeam());
    const { mutate: editLoadingTeam, isLoading: isUpdating } = useEditOrganizationResourceMutation(OrgQueryKeys.loadingTeams, L.loadingTeam());

    const columns = useLoadingTeamColumns();

    return (
        <ResourcePage
            resourceType='loadingTeam'
            isLoading={isLoading || isCreating || isUpdating}
            titleFunc={loadingTeam => loadingTeam.name}
            data={loadingTeams}
            columns={columns}
            EditCreateComponent={(editCreateProps) => {
                return <EditCreateLoadingTeam
                    existingLoadingTeam={editCreateProps.resource}
                    onSubmit={async (newData) => {
                        if (newData.id) {
                            editLoadingTeam(newData);
                        } else {
                            addLoadingTeam(newData);
                        }
                        editCreateProps.closeModal();
                    }}/>;
            }}/>
    );

};

