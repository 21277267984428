import React from 'react';
import PropTypes from 'prop-types';
import { HereMapContext } from './here-map-context';
import { createCircle, createInfoBubble } from '../../services/here-maps/here-map-wrapper';
import { makeShapeDynamic } from '../../services/here-maps/here-map-resizable-shapes';

export const HereMapCircle = (props) => {
    const context = React.useContext(HereMapContext);
    const instance = React.useRef({});

    React.useEffect(() => {
        return () => {
            if (!props.radius) {
                return;
            }

            instance.current.map.removeObject(instance.current.circle);
        };
    }, []);

    React.useEffect(() => {
        instance.current = {
            ...instance.current,
            map: context.map,
            ui: context.ui,
            behavior: context.behavior
        };

        if (instance.current.map && props.radius) {
            if (instance.current.circle) {
                instance.current.map.removeObject(instance.current.circle);
            }

            let circle = createCircle(props.latitude, props.longitude, props.radius, props.color);

            if (props.resizable) {
                circle = makeShapeDynamic(instance.current.map, instance.current.behavior, circle, props.onChange);
            }

            instance.current.circle = circle;
            instance.current.map.addObject(circle);

            if (props.children) {
                const infoBubble = createInfoBubble(props.latitude, props.longitude, props.children);
                circle.addEventListener('pointerenter', () => {
                    instance.current.ui.addBubble(infoBubble);
                });

                circle.addEventListener('pointerleave', () => {
                    instance.current.ui.removeBubble(infoBubble);
                });
            }
        }
    }, [props.latitude, props.longitude, props.radius, context]);

    return null;
};

HereMapCircle.propTypes = {
    children: PropTypes.node,
    color: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    onChange: PropTypes.func,
    radius: PropTypes.number,
    resizable: PropTypes.bool,
};