import React from 'react';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import { Load } from '../../../types';
import { L } from 'harmony-language';
import TableContainer from '@mui/material/TableContainer';
import { Paper } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import { LoadProvider } from '../../shared/load-context';
import { LoadCargo, LoadDate, LoadDestination, LoadLotIds, LoadOrderNumbers, LoadOrigin } from '../../shared/load-table/load-table-cells';
import Skeleton from "@mui/material/Skeleton";

interface ProductionPreviewTableProps {
    drafts: Load[],
    isLoading: boolean,
}

const Header: React.FC = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>{L.date()}</TableCell>
                <TableCell>{L.origin()}</TableCell>
                <TableCell>{L.destination()}</TableCell>
                <TableCell>{L.cargo()}</TableCell>
                <TableCell width={'10%'}>{`${L.pickUp()} #`}</TableCell>
                <TableCell width={'10%'}>{L.orderNumber()}</TableCell>
            </TableRow>
        </TableHead>
    );
};

const Row: React.FC = () => {
    return (
        <TableRow>
            <TableCell><LoadDate hideTravelTimes={true} /></TableCell>
            <TableCell><LoadOrigin displaySubSite groupSublocations={false} /></TableCell>
            <TableCell><LoadDestination displaySubSite groupSublocations={false} /></TableCell>
            <TableCell><LoadCargo/></TableCell>
            <TableCell width={'10%'}><LoadLotIds/></TableCell>
            <TableCell width={'10%'}><LoadOrderNumbers/></TableCell>
        </TableRow>
    );
};


const ProductionPreviewTable: React.FC<ProductionPreviewTableProps> = (props) => {
    const { drafts, isLoading } = props;
    return (
        <Paper style={{ width: '100%' }}>
            <TableContainer>
                <Table style={{ tableLayout: 'fixed' }}>
                    <Header/>
                    <TableBody>
                        {isLoading ?
                            Array.from(Array(10), (x, i) => i).map((x) => {
                                return (
                                    <TableRow key={x}>
                                        <TableCell colSpan={5}>
                                            <Skeleton width={'100%'} height={'1rem'}/>
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : drafts.map((d, i) => {
                                return (
                                    <LoadProvider key={i} load={d}>
                                        <Row />
                                    </LoadProvider>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default ProductionPreviewTable;
