import { getSocket } from '../../services/socket-factory';

export const connectSocket = () => {
    const socket = getSocket();

    socket.on('connect', () => {
        /* eslint-disable-next-line */
        console.log('Socket connected!');
    });

    socket.on('disconnect', () => {
        /* eslint-disable-next-line */
        console.log('Socket disconnected!');
    });

    socket.on('orders:update', (updatedOrder) => {
        /* eslint-disable-next-line */
        console.log(`Socket orders:update: ${updatedOrder.id}`);
        // ordersUpdate(updatedOrder, dispatch, getState); TODO: Re-implement this with react-query
    });

    socket.on('orders:stops:update', (updatedStop) => {
        /* eslint-disable-next-line */
        console.log(`Socket orders:stops:update: ${updatedStop.id}`);
        // ordersStopsUpdate(updatedStop, dispatch, getState); TODO: Re-implement this with react-query
    });

    // socket.on('device:locations:latest:update', (deviceLocation) => {
    //     /* eslint-disable-next-line */
    //     console.log(`Socket device:locations:latest:update:`, deviceLocation);
    // });
};
