import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { L } from 'harmony-language';
import { SequencingReportColumns } from '../report-types';
import { localDateTimeDisplay } from '../../../utils/date-time-utils';

export const useSequencingColumns = (isAdmin: boolean) => {
    const columnHelper = createColumnHelper<SequencingReportColumns>();

    const columns = React.useMemo(() => {
        const adminSettings = () => {
            return [
                columnHelper.accessor('organization_id', {
                    header: () => L.organizationId(),
                    cell: info => info.getValue(),
                }),
            ]
        }

        const adminColumns = isAdmin ? adminSettings() : [];
        const restColumns = [
            columnHelper.accessor('order_number', {
                header: () => L.orderNumber(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('origin', {
                header: () => L.origin(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('trailer', {
                header: () => L.trailer(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('destination', {
                header: () => L.destination(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('split_site', {
                header: () => L.splitSite(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('flushed', {
                header: () => L.flushed(),
                size: 25,
                cell: info => info.getValue() ? <span style={{ fontWeight: 'bold' }}>&#10003;</span> : null,
            }),
            columnHelper.accessor('formula', {
                header: () => L.formula(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('compartment_1', {
                header: () => 1,
                size: 25,
                cell: info => info.getValue() ? <span style={{ fontWeight: 'bold' }}>&#10003;</span> : null,
            }),
            columnHelper.accessor('compartment_2', {
                header: () => 2,
                size: 25,
                cell: info => info.getValue() ? <span style={{ fontWeight: 'bold' }}>&#10003;</span> : null,
            }),
            columnHelper.accessor('compartment_3', {
                header: () => 3,
                size: 25,
                cell: info => info.getValue() ? <span style={{ fontWeight: 'bold' }}>&#10003;</span> : null,
            }),
            columnHelper.accessor('compartment_4', {
                header: () => 4,
                size: 25,
                cell: info => info.getValue() ? <span style={{ fontWeight: 'bold' }}>&#10003;</span> : null,
            }),
            columnHelper.accessor('compartment_5', {
                header: () => 5,
                size: 25,
                cell: info => info.getValue() ? <span style={{ fontWeight: 'bold' }}>&#10003;</span> : null,
            }),
            columnHelper.accessor('compartment_6', {
                header: () => 6,
                size: 25,
                cell: info => info.getValue() ? <span style={{ fontWeight: 'bold' }}>&#10003;</span> : null,
            }),
            columnHelper.accessor('compartment_7', {
                header: () => 7,
                size: 25,
                cell: info => info.getValue() ? <span style={{ fontWeight: 'bold' }}>&#10003;</span> : null,
            }),
            columnHelper.accessor('compartment_8', {
                header: () => 8,
                size: 25,
                cell: info => info.getValue() ? <span style={{ fontWeight: 'bold' }}>&#10003;</span> : null,
            }),
            columnHelper.accessor('compartment_9', {
                header: () => 9,
                size: 25,
                cell: info => info.getValue() ? <span style={{ fontWeight: 'bold' }}>&#10003;</span> : null,
            }),
            columnHelper.accessor('time_unloaded', {
                header: () => L.timeUnloaded(),
                cell: info => localDateTimeDisplay(info.getValue()),
            }),
        ];

        return [...adminColumns, ...restColumns];
    }, []);

    return columns;
}
