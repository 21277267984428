import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { CheckListType } from 'harmony-constants/lib/src/db/constants';
import { DictionaryKey, L } from 'harmony-language';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { type Checklist } from '../../../types';
import { mutationOptions, OrgQueryKeys } from '../../config';

export const useAddOrganizationChecklistMutation = (
    companyId: number,
    resourceType: 'tractor' | 'trailer',
    resourceId: number
) => {
    const snackbar = useSnackbar();
    const resolveChecklistsKey = OrgQueryKeys.resolveChecklist(
        companyId,
        resourceType,
        resourceId
    );
    const queryClient = useQueryClient();

    return useMutation(
        async ({ checklist }: { checklist: Checklist }) => {
            return (
                await axios.post(`${API_BASE}/api${resolveChecklistsKey}`, {
                    organizationId: companyId,
                    type: CheckListType.Maintenance,
                    checklist: {
                        ...checklist.checklist,
                        notes: null,
                        groups: [
                            {
                                ...checklist.checklist.groups[0],
                                items: checklist.checklist.groups[0].items.map(
                                    (item) => ({
                                        ...item,
                                        shownText:
                                            L[item.key as DictionaryKey]()
                                    })
                                )
                            }
                        ],
                        inspectionId: checklist.id,
                    },
                    checklistTemplateId: checklist.checklistTemplateId
                })
            ).data;
        },
        {
            ...mutationOptions([resolveChecklistsKey], queryClient),
            onSuccess: (data, { resourceName }: any) => {
                snackbar(
                    L.checklistResolvedSuccessMessage(
                        resourceName,
                        L[resourceType.toLowerCase() as DictionaryKey]()
                    )
                );
                queryClient.setQueryData([resolveChecklistsKey], data);
            },
            onSettled: async () => {
                const getAllLatestChecklistsKey = OrgQueryKeys.resolve(
                    companyId,
                    OrgQueryKeys.checklists
                );
                const getResourceLatestChecklistKey = OrgQueryKeys.getLatestChecklist(
                    companyId,
                    resourceType,
                    resourceId
                );
                const getResolveLatestCombinedChecklistsKey = OrgQueryKeys.resolve(
                    companyId,
                    OrgQueryKeys.getResolveCombinedChecklists(resourceType, resourceId)
                );
                await queryClient.invalidateQueries([getAllLatestChecklistsKey]);
                await queryClient.invalidateQueries([getResourceLatestChecklistKey]);
                await queryClient.invalidateQueries([getResolveLatestCombinedChecklistsKey]);
            },
        }
    );
};
