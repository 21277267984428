import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { AgisticsCard } from '../../shared/containers/agistics-card';
import { L } from 'harmony-language';
import { useMergeDrafts } from './use-merge-drafts';
import { LoadProvider, useLoadContext } from '../../shared/load-context';
import {
    LoadDate,
    LoadDriver,
    LoadTractor,
    LoadTrailer,
} from '../../shared/load-table/load-table-cells';
import {
    StopCargoType,
    StopLocationAuto,
    StopLotId,
    StopNotes,
    StopOrderNumber,
    StopProductionPlan,
    StopQuantity,
    StopTime,
    StopType,
    StopReadyTime,
    StopSplitIcon,
    StopWeight,
    StopAutoCalc,
    StopSubLocations
} from '../../shared/load-table/stops-table-cells';
import { StopsTable } from '../../shared/load-table/stops-table';
import LoadingButton from '../../shared/loading-button';
import Save from '@mui/icons-material/Save';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import { Load } from '../../../types';

const TopContainer = styled('div')({
    width: '90vw',
    fontSize: '0.875rem',
});

const StopsRow = styled('div')({
    gridColumn: '1 / -1'
});

const MasterGrid = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridColumnGap: '1rem'
});

const MergeDraftsButtonContainer = styled('div')({
    paddingTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end'
});

interface MergeDraftsProps {
    drafts: Load[];
    onSubmit: () => void;
}

export const MergeDrafts: React.FC<MergeDraftsProps> = (props) => {
    const { drafts, onSubmit } = props;
    const mergedDrafts = useMergeDrafts(drafts);

    return (
        <LoadProvider load={mergedDrafts} mutation={'MergeDrafts'}>
            <MergingDraftsForm previousDrafts={drafts} onSubmit={onSubmit} />
        </LoadProvider>
    );
};

interface MergingDraftsFormProps {
    previousDrafts: Load[];
    onSubmit: () => void;
}

const MergingDraftsForm: React.FC<MergingDraftsFormProps> = (props) => {
    const { previousDrafts, onSubmit } = props;

    const { editFunctions } = useLoadContext();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await editFunctions?.save({ previousDrafts: previousDrafts });
        onSubmit();
    };

    const mergeColumns = React.useMemo(() => ([
        { header: L.date(), render: LoadDate },
        { header: L.driver(), render: LoadDriver },
        { header: L.tractor(), render: LoadTractor },
        { header: L.trailer(), render: LoadTrailer },
    ]), []);

    const stopColumns = React.useMemo(() => ([
        { header: L.stopType(), render: StopType },
        { header: L.location(), render: StopLocationAuto },
        { header: L.subLocations(), render: StopSubLocations },
        { header: L.readyTime(), render: StopReadyTime },
        { header: L.time(), render: StopTime },
        { header: '', render: StopAutoCalc },
        { header: L.quantity(), render: StopQuantity },
        { header: L.weight(), render: StopWeight },
        { header: L.cargoType(), render: StopCargoType },
        { header: L.split(), render: StopSplitIcon },
        { header: L.orderNumber(), render: StopOrderNumber },
        { header: L.productionPlan(), render: StopProductionPlan },
        { header: `${L.pickUp()} #`, render: StopLotId },
        { header: '', render: StopNotes }
    ]), []);

    const handleClick = () => {
        editFunctions?.reverseDestinations();
    }

    return (
        <TopContainer data-testid='merge-drafts'>
            <form onSubmit={handleSubmit} id='merge-drafts-form' name='merge-drafts-form'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AgisticsCard title={L.mergedDraftPreview()} paddingSmall>
                            <MasterGrid>
                                {mergeColumns.map((c, i) => {
                                    return (
                                        <div key={i}>
                                            {c.header}
                                        </div>
                                    );
                                })}
                                {mergeColumns.map((c, i) => {
                                    return (
                                        <div key={i}>
                                            <c.render/>
                                        </div>
                                    );
                                })}
                                <StopsRow>
                                    <StopsTable columns={stopColumns}/>
                                </StopsRow>
                            </MasterGrid>
                        </AgisticsCard>
                    </Grid>
                </Grid>
                <MergeDraftsButtonContainer>
                    <LoadingButton isLoading={editFunctions?.isSaving} icon={<FlipCameraAndroidIcon/>} onClick={handleClick} color='primary'
                                   variant='contained' style={{ marginRight: '1rem' }}>{L.reverseDestinations()}</LoadingButton>
                    <LoadingButton isLoading={editFunctions?.isSaving} icon={<Save/>} type='submit' color={'primary'}
                                   variant='contained'>{L.mergeDrafts()}</LoadingButton>
                </MergeDraftsButtonContainer>
            </form>
        </TopContainer>
    );
};
