import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Select } from '../shared/inputs/select';
import { useCargoTypes } from '../../api/queries/use-cargo-types';
import { CargoType } from '../../types';
import { ContentHeader } from '../shared/containers/content-header';
import { Content } from '../shared/containers/content';
import ProductionGeneration from './generation/production-generation';
import { OrderGroupTable } from './order-groups/order-group-table';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import { useBool } from '../shared/hooks/use-bool';
import { usePermissions } from '../user/selectors/use-permissions';
import { ModalDialog } from '../shared/containers/modal-dialog';
import CreateProductionCard from './create-production-card';
import { getTransCargoTypeLabel } from '../../constants/constants';
import { L } from 'harmony-language';
import { PermissionType } from 'harmony-constants';

export const ProductionPlanning: React.FC = () => {
    const { cargoTypeList } = useCargoTypes(true);
    const canCreateProductionCard = usePermissions(PermissionType.CreateProductionCards);
    const [isCreateOpen, {on: open, off: close  }] = useBool(false);
    const [cargoType, setCargoType] = React.useState<CargoType | undefined>();
    useEffect(() => {
        if(!cargoType){
            setCargoType(cargoTypeList?.[0]);
        }
    }, [cargoTypeList]);

    const cardChangeBackgroundFunc = (cargoTypeId: number) => {
        const changedCargoType = cargoTypeList.find((x: CargoType) => x.id === cargoTypeId);
        setCargoType(changedCargoType);
    };

    return (
        <>
            <ContentHeader>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h5'>{L.productionPlanning()}</Typography>
                    <Typography variant='h5'>&#xa0;&#x2f;&#xa0;</Typography>
                    {cargoTypeList && <Select
                        item={cargoType}
                        list={cargoTypeList}
                        onChange={setCargoType}
                        inputProps={{
                            disableUnderline: true,
                            style: {
                                fontSize: '1.5rem',
                            }
                        }}
                        style={{
                            width: 'unset',
                        }}
                    />}
                </div>
                {canCreateProductionCard &&
                <Button variant='contained' color='primary' onClick={open}><Add /> {L.productionCardCreateProductionCard()}</Button>}
            </ContentHeader>
            {cargoType &&
            <Content style={{ paddingTop: 0 }}>
                <ModalDialog title={`${L.productionCardCreateProductionCard()} - ${getTransCargoTypeLabel(cargoType.id)}`} onClose={close} open={isCreateOpen}>
                    <CreateProductionCard
                        cargoTypeList={cargoTypeList}
                        cargoTypeId={cargoType.id}
                        onSubmit={close} 
                        cargoChangeFunc={cardChangeBackgroundFunc}
                    />
                </ModalDialog>
                <ProductionGeneration cargoTypeId={cargoType.id}/>
                <OrderGroupTable cargoTypeId={cargoType.id}/>
            </Content>
            }
        </>
    );
};
