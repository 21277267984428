import React from 'react';
import { styled } from '@mui/material/styles';
import { L } from 'harmony-language';
import { useBoolean } from '../hooks/use-boolean';
import { IconButton, TextField, Typography } from '@mui/material';
import { Stop } from '../../../types';
import { ModalDialog } from '../containers/modal-dialog';
import { NoteAddOutlined, NoteOutlined } from '@mui/icons-material';

const IconDiv = styled('div')({
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: 0,
    padding: 0,
});

const StyledTextField = styled(TextField)({
    width: '25vw',
    margin: '0.5rem 0.25rem',
});

interface StopNotesModalProps {
    stop: Stop;
    onChange?: ((stop: Partial<Stop>) => void) | null | undefined;
    stopEditDisabled: boolean;
}

export const StopNotesModal: React.FC<StopNotesModalProps> = (props) => {
    const { stop, onChange, stopEditDisabled } = props;
    const [isOpen, open, close] = useBoolean(false);


    let stopNotesIcon;
    if (stop.notes || stop.comments) {
        stopNotesIcon = <NoteOutlined/>;
    } else if (onChange && !stop.notes) {
        stopNotesIcon = <NoteAddOutlined/>
    }

    if (!stopNotesIcon) {
        return null;
    }

    return (
        <IconDiv>
            <IconButton
                sx={{ padding: 0 }}
                onClick={open}
                size='small'
                color='primary'
                disabled={stopEditDisabled}
            >
                {stopNotesIcon}
            </IconButton>
            <ModalDialog title={`${L.stop()} ${L.notes() + ' & ' + L.stopComments()}`}
                         open={isOpen}
                         onClose={() => close()}>
                <Typography>{L.notes()}</Typography>
                <StyledTextField
                           multiline
                           minRows={4}
                           variant='outlined'
                           value={stop.notes || ''}
                           disabled={!onChange}
                           onChange={(e) => onChange ? onChange({ notes: e.target.value }) : null}
                />
                <br />
                <Typography>{L.stopComments()}</Typography>
                <StyledTextField
                           multiline
                           minRows={4}
                           variant='outlined'
                           value={stop.comments || ''}
                           disabled={true}
                />
            </ModalDialog>
        </IconDiv>
    );
}
