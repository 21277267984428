import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions, QueryKeys } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { AvoidanceZone } from '../../../types';

async function editAvoidanceZone(options: { avoidanceZone: AvoidanceZone }) {
    const avoidanceZone = options.avoidanceZone;

    return (await axios.put(`${API_BASE}/api/avoidance-zones/${avoidanceZone.id}`, avoidanceZone)).data;
}

export const useEditAvoidanceZoneMutation = () => {
    const snackbar = useSnackbar();
    const key = QueryKeys.avoidanceZones;
    const queryClient = useQueryClient();

    return useMutation(editAvoidanceZone, {
        ...mutationOptions([key], queryClient),
        onSuccess: (data) => {
            const avoidanceZones = queryClient.getQueryData<AvoidanceZone[]>([key]);
            if (avoidanceZones) {
                const newAvoidanceZones = avoidanceZones.map((existingZone) => {
                    if (existingZone.id === data.id) {
                        return {
                            ...data,
                        };
                    }
                    return existingZone;
                });
                queryClient.setQueryData([key], newAvoidanceZones);
                snackbar(L.avoidanceModified());
            }
        },
    });
};
