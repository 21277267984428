import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import '../../scss/login.scss';
import { L } from 'harmony-language';
// @ts-ignore
import SamsImage from '../../resources/agistics-logo-name.png';
import { Link, useNavigate } from 'react-router-dom';
import { resetPassword } from './actions/reset-password';

export const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        resetPassword(email, navigate);
    };

    return (
        <div className='login-container'>
            <img className='agistics-logo' src={SamsImage} alt=''/>
            <Paper className='login-paper'>
                <form className='reset-password-form' onSubmit={onSubmit}>
                    <TextField
                        variant='standard'
                        id='email'
                        type='text'
                        required
                        label={L.email()}
                        InputLabelProps={{ shrink: true }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} />
                    <Button type='submit' color='primary' variant='contained'>{L.resetPass()}</Button>
                </form>
                <Link to='/login'>{L.login()}</Link>
            </Paper>
        </div>
    );
};
