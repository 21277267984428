import { fetchFunction, typedFetchFunction } from '../../../../api/config';
import { TripEvent } from '../../../../types';

const timestampSort = (data: TripEvent[]) => {
    return data.sortBy((data) => data.timestamp);
};

//thoughts: use this config for the 4 data queries on the delivery log (deviceLocations, stopTasks, washTasks, tripEvents)
//auto refresh these 4 queries after 5 mins.
//invalidate after when modal closed, which is the cacheTime: 0
export const deliveryLogQueryConfig = {
    queries: {
        //sorting is done in api
        queryFnNoSort: async (key: string) => {
            return fetchFunction(key).then(({ data }) => data);
        },
        queryFnSortTimestamp: async (key: string) => {
            return typedFetchFunction<TripEvent[]>(key).then(({ data }) => timestampSort(data));
        },
        staleTime: 5 * 60 * 1000, //5mins
        cacheTime: 0,
        refetchInterval: 5 * 60 * 1000, //5mins
    }
};
