import { LocationResource, Resource } from '../../../types';
import { isCargoStop } from '../../../constants/constants';
import { isValidCargoType } from '../../../utils/data-mapping-utils';
import { LocationCargoTypeConflictTooltip } from './tooltips/location-cargo-type-conflict-tooltip';
import React from 'react';
import { Conflict } from './resource-conflict-utils';

export const useGetCargoTypeConflict = (resource: Resource): Conflict => {
    let conflict = null;
    // Right now only supporting location
    if (resource.type === 'location' && isCargoStop(resource.stop) && !isValidCargoType(resource.cargoTypeIds, resource.stop.cargoTypeId)) {
        conflict = <LocationCargoTypeConflictTooltip resource={resource as LocationResource}/>
    }
    return { conflict };
}
