import { isValidPhoneNumber } from 'libphonenumber-js';

export const capitalizeFirst = (s) => {
    if (typeof s !== 'string') {
        return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export function padZero(num, size) {
    num = num.toString();
    while (num.length < size) {
        num = '0' + num;
    }
    return num;
}


export function classNames(...args) {
    const entries = args.reduce((acc, a) => {
        if (a) {
            if (typeof a === 'object') {
                if (Array.isArray(a)) {
                    return {
                        ...acc,
                        [classNames(...a)]: true
                    };
                } else {
                    return {
                        ...acc,
                        ...a
                    };
                }
            } else if (typeof a === 'string') {
                return {
                    ...acc,
                    [a]: true
                };
            }
        }

        return acc;
    }, {});

    return Object.entries(entries).filter(([, value]) => value).map((([key]) => key)).join(' ');
}

/*
Psuedo code: https://gist.github.com/tommy/14631
 */
export const levenshtein = (s, t) => {
    const source = (s || '').toLowerCase().replace(/\s+/g, '');
    const target = (t || '').toLowerCase().replace(/\s+/g, '');

    const table = Array.from(Array(source.length + 1), () => new Array(target.length + 1));

    for (let i = 0; i <= source.length; i++) {
        table[i][0] = i;
    }
    for (let j = 0; j <= target.length; j++) {
        table[0][j] = j;
    }

    for (let i = 0; i < source.length; i++) {
        for (let j = 0; j < target.length; j++) {
            const cost = source.charAt(i) === target.charAt(j) ? 0 : 1;

            const deletion = table[i][j + 1] + 1;
            const insertion = table[i + 1][j] + 1;
            const substitution = table[i][j] + cost;

            table[i + 1][j + 1] = Math.min(
                deletion,
                insertion,
                substitution,
            );
        }
    }
    return table[source.length][target.length];
};

export function validatePhone(phone) {
    return phone ? isValidPhoneNumber(phone) : true;
}

export function formatPhone(phone) {
    return phone ? `+${(phone.replace(/[^0-9]/g, ''))}` : null;
}

export const isNothing = (s) => {
    if (!s || typeof s !== 'string' || s.trim() === '') {
        return true;
    }
    return false;
}
