import React from 'react';
import { Icon } from '@mui/material';
import { L } from 'harmony-language';
import { useLoadContext } from '../load-context';
import { useCargoTypes } from '../../../api/queries/use-cargo-types';
import { orderCargoEligibleForCompartments } from '../../../utils/data-mapping-utils';

const CompartmentsHeader: React.FC = () => {
    const { load, editFunctions } = useLoadContext();
    const { data: cargoTypes, isLoading: isLoadingCargoTypes } = useCargoTypes();

    if (isLoadingCargoTypes) {
        return null;
    }

    const cargoEligibleForCompartments = orderCargoEligibleForCompartments(cargoTypes, load);
    return (
        <>
            <span>{L.compartments()}</span>
            {cargoEligibleForCompartments &&
                editFunctions?.onChange &&
                <span style={{ marginLeft: '0.3rem', color: '#555' }} onClick={() => {
                    editFunctions?.setCompartmentLinkingEnabled(!editFunctions?.compartmentLinkingEnabled);
                }}>
                    <Icon style={{ fontSize: '1rem', cursor: 'pointer' }} title={L.toggleCompartmentLinking()}>
                        {editFunctions?.compartmentLinkingEnabled ? 'link' : 'link_off'}
                    </Icon>
                </span>
            }
        </>
    );
};

export default CompartmentsHeader;