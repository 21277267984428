import { L } from 'harmony-language';
import React from 'react';
import { useUnlinkOrderGroupMutation } from '../../../api/mutations/delete/use-unlink-order-group-mutation';
import { ConfirmationDialog } from '../../shared/confirmation-dialog';
import { useBool } from '../../shared/hooks/use-bool';
import { ActionIcon } from '../../shared/table/components/action-icon';
import { OrderGroup } from './use-order-group-table';
import { usePermissions } from '../../user/selectors/use-permissions';
import { PermissionType } from 'harmony-constants';

export const OrderGroupUnlinkAction: React.FC<{ orderGroup: OrderGroup }> = (props) => {
    const { orderGroup } = props;
    const [isOpen, { on, off }] = useBool(false);
    const { mutate: unlinkCards } = useUnlinkOrderGroupMutation();
    const canDeleteOrder = usePermissions(PermissionType.DeleteOrder);

    return canDeleteOrder ? <>
        <ActionIcon action={() => on()}
            icon={'link_off'}
            tooltip={orderGroup.isUnlinkable ? L.productionPlanningUnlinkProductionCards() : L.productionPlanningUnlinkForbidden()} testId={orderGroup.guid}
            isDisabled={!orderGroup.isUnlinkable}
        />
        <ConfirmationDialog
            open={isOpen}
            resultCallback={(confirmed) => {
                off()
                if (confirmed) {
                    unlinkCards(orderGroup);
                }
            }}
            title={L.productionPlanningUnlinkTitle()}
            message={L.productionPlanningUnlinkMessage(`${orderGroup.numberOfDraftLoads + orderGroup.numberOfScheduledLoads}`)}
            cancelText={L.cancel()}
            confirmText={L.unlink()}
        />
    </> : null;
}
