export function deepEqual(x, y, fieldsToIgnore = []) {
    const ok = Object.keys;
    const tx = typeof x;
    const ty = typeof y;

    const check = x && y && tx === 'object' && tx === ty;

    if (check) {
        const xKeys = ok(x).filter(key => !fieldsToIgnore.includes(key));
        const yKeys = ok(y).filter(key => !fieldsToIgnore.includes(key));

        const xLen = xKeys.length;
        const yLen = yKeys.length;

        return x.constructor === y.constructor && xLen === yLen && xKeys.every(key => {
            const value = deepEqual(x[key], y[key], fieldsToIgnore);

            // Uncomment to see what property has changed
            // if(!value){
            //     console.log(key, [x[key], y[key]]);
            // }
            return value;
        });
    } else {
        return x === y;
    }
}


// Function to check if key value entry exists in other object and if it is deep equal
export function deepEqualEntry(xKey, xValue, y) {
    return deepEqual(xValue, y?.[xKey]);
}
