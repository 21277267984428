import React from 'react';
import { LoadDriver, LoadTractor, LoadTrailer } from '../../shared/load-table/load-table-cells';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { Driver, Trailer } from '../../../types';
import { DynamicQueryKeys, OrgQueryKeys } from '../../../api/config';
import { useCompanyId } from '../../../app/agistics-context';
import { useQuery } from '@tanstack/react-query';
import { localDateTimeDisplay } from '../../../utils/date-time-utils';
import { styled } from '@mui/material';
import { L } from 'harmony-language';
import { useLoadContext } from '../../shared/load-context';
import { useUser } from '../../../api/queries/use-user';

const ColHeader = styled('td')({
    fontWeight: 'bold',
});

interface SchedulingResourceInfo {
    driverTimestamps: {
        driverId: number;
        orderId: number;
        timestamp: string;
    }[];
    latestTrailerProductionPlan: {
        trailerId: number;
        orderId: number;
        productionPlans: string;
    }[];
}

// this is wrote to only be on the drafts page.  Ignores carriers
// if needed to add to other screens where carriers need to be handled, will need some modifications
export const ResourceScheduling: React.FC = () => {
    const { load } = useLoadContext();
    const { data: drivers = [] } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers);
    const { data: trailers = [] } = useOrganizationQuery<Trailer[]>(OrgQueryKeys.trailers);
    const companyId = useCompanyId();
    const { user } = useUser();

    const filteredDrivers = React.useMemo(() => {
        const userPrefRemovedDrivers = user.preferences?.removedDrivers || [];

        const filteredOrgAndDisabledDrivers = drivers.filter(x => x.enabled && x.organizationId === companyId);
        const filteredRemovedDrivers = filteredOrgAndDisabledDrivers.filter(driver => !userPrefRemovedDrivers.includes(driver.id));
        return filteredRemovedDrivers;
    }, []);

    const filteredTrailers = React.useMemo(() => {
        const userPrefRemovedTrailers = user.preferences?.removedTrailers || [];

        const filteredOrgAndDisabledTrailers = trailers.filter(x => x.enabled && x.organizationId === companyId);
        const cargoTypeIdsOfStops = load.stops.map(stop => stop.cargoTypeId);
        const filteredCargoTrailers = filteredOrgAndDisabledTrailers.filter(trailer => {
            const cargoIdsOfTrailer = trailer.trailerCargoTypes.map(x => x.cargoTypeId);
            const has = cargoIdsOfTrailer.some(c => cargoTypeIdsOfStops.includes(c));
            return has;
        });
        const filteredRemovedTrailers = filteredCargoTrailers.filter(trailer => !userPrefRemovedTrailers.includes(trailer.id))
        return filteredRemovedTrailers;
    }, []);

    const key = DynamicQueryKeys.schedulingResourceInfo(filteredDrivers.map(x => x.id), filteredTrailers.map(x => x.id));
    const { data: schedulingResourceInfo } = useQuery<SchedulingResourceInfo>({
        queryKey: [key],
        staleTime: Infinity,
        cacheTime: 0,
    });

    return (
        <>
            <div style={{ width: '40vw', display: 'grid', gridTemplateColumns: '2fr 2fr 2fr', columnGap: '10px' }}>
                <LoadDriver />
                <LoadTractor />
                <LoadTrailer />
            </div>
            <br />
            <table width='100%'>
                <tr style={{ display: 'flex', alignContent: 'flex-start', justifyContent: 'space-evenly', fontSize: '.875rem' }}>
                    <td>
                        <table style={{ border: '1px solid', padding: '5px' }}>
                            <tr><ColHeader>{L.driver()}</ColHeader><ColHeader>{L.time()}</ColHeader></tr>
                            {filteredDrivers.map((driver) => {
                                const driverTimestamp = schedulingResourceInfo?.driverTimestamps.find(x => x.driverId === driver.id);

                                return (
                                    <tr key={driver.id}>
                                        <td
                                            title={driverTimestamp?.orderId.toString()}
                                            style={{ paddingRight: '10px' }}
                                        >
                                            {driver.name}
                                        </td>
                                        <td>{driverTimestamp ? localDateTimeDisplay(driverTimestamp.timestamp) : ''}</td>
                                    </tr>
                                );
                            })}
                        </table>
                    </td>
                    <td>
                        <table style={{ border: '1px solid', padding: '5px' }}>
                            <tr><ColHeader>{L.trailer()}</ColHeader><ColHeader>{L.productionPlan()}</ColHeader></tr>
                            {filteredTrailers.map((trailer) => {
                                const latestPlan = schedulingResourceInfo?.latestTrailerProductionPlan.find(x => x.trailerId === trailer.id);

                                return (
                                    <tr key={trailer.id}>
                                        <td
                                            title={latestPlan?.orderId.toString()}
                                            style={{ paddingRight: '10px' }}
                                        >
                                            {trailer.userDisplayName}
                                        </td>
                                        <td>{latestPlan ? latestPlan.productionPlans : ''}</td>
                                    </tr>
                                );
                            })}
                        </table>
                    </td>
                </tr>
            </table>
        </>
    );
};
