import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions, QueryKeys } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { OrganizationCarrier } from '../../../types';

function editCompanyCarriers(orgId: number) {
    return async (options: { carriers: OrganizationCarrier[] }) => {
        const carriers = options.carriers;

        return (await axios.put(`${API_BASE}/api/organizations/${orgId}/carriers`, carriers)).data;
    };
}

export const useEditOrganizationCarrier = (orgId: number) => {
    const snackbar = useSnackbar();
    const key = QueryKeys.companies;
    const queryClient = useQueryClient();

    return useMutation(editCompanyCarriers(orgId), {
        ...mutationOptions([key], queryClient),
        onSuccess: (data) => {
            snackbar(L.updatedMessage(data.name));
        }
    });
};
