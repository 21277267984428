import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { L } from 'harmony-language';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { OrganizationCarrier } from '../../types';

type CarrierAssignmentCardProps = {
    assignCarrier: (car: OrganizationCarrier) => void;
    carrier: OrganizationCarrier;
}

const Actions = styled(CardActions)(({ theme }) => ({
    backgroundColor: theme.palette.grey['100'],
    justifyContent: 'flex-end'
}));

export const CarrierAssignmentCard = (props: CarrierAssignmentCardProps) => {
    const { assignCarrier, carrier } = props;
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const theme = useTheme();

    return (
        <Card style={{ width: '100%' }}>
            <CardContent>
                {showConfirmation ? (
                    <Typography variant='h6'>{L.assignSure(`${carrier.carrier?.name}`)}</Typography>
                ) : (
                        <>
                            <div style={{ fontSize: theme.typography.h6.fontSize }}>
                                <Typography variant='h6'>{`${carrier.carrier?.name}`}</Typography>
                            </div>
                        </>
                )}
            </CardContent>
            <Actions>
                {!showConfirmation &&
                    <Button variant='text' color='primary' onClick={() => setShowConfirmation(true)}>{L.assign()}</Button>}
                {showConfirmation && <Button variant='text' color='primary'
                    onClick={() => setShowConfirmation(false)}>{L.assignNo()}</Button>}
                {showConfirmation &&
                    <Button variant='text' color='primary' onClick={() => assignCarrier(carrier)}>{L.assignYes()}</Button>}
            </Actions>
        </Card>
    );
};
