import { DynamicQueryKeys } from '../../../../api/config';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { TripEvent } from '../../../../types';
import { deliveryLogQueryConfig } from './delivery-log-config'

export const useTripEvents = (type: string, loadId: number, startDate?: string, endDate?: string): UseQueryResult<TripEvent[], unknown> => {
    const key = DynamicQueryKeys.resourceTripEvents(type, loadId, startDate ? startDate : null, endDate ? endDate : null);

    return useQuery<TripEvent[]>([key], async (): Promise<TripEvent[]> => {
        return await deliveryLogQueryConfig.queries.queryFnSortTimestamp(key);
    },
    {
        staleTime: deliveryLogQueryConfig.queries.staleTime,
        cacheTime: deliveryLogQueryConfig.queries.cacheTime,
        refetchInterval: deliveryLogQueryConfig.queries.refetchInterval,
    });
};
