import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { mutationOptions, OrgQueryKeys } from '../../config';
import { Feature } from '../../../types';

async function addFeature(companyId: number, options: { featureId: number }) {
    const featureId = options.featureId;

    return (await axios.post(`${API_BASE}/api/organizations/${companyId}/features`, { featureId })).data;
}

export const useAddFeatureMutation = (companyId: number) => {
    const key = OrgQueryKeys.resolve(companyId, OrgQueryKeys.features);
    const queryClient = useQueryClient();

    return useMutation((...args) => addFeature(companyId, ...args), {
        ...mutationOptions([key], queryClient),
        onMutate: (options) => {
            const featureId = options.featureId;

            queryClient.cancelQueries([key]);

            const previousFeatures = queryClient.getQueryData<Feature[]>([key]);
            if (previousFeatures) {
                const newFeatures = [...previousFeatures, { id: featureId }];

                // Optimistically update to the new value
                queryClient.setQueryData([key], newFeatures);
            }
            // Return a rollback function
            return () => queryClient.setQueryData([key], previousFeatures);
        }
    });
};
