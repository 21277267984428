import { checkIfDateRangesOverlap } from '../../../utils/date-time-utils';
import { capitalizeFirst } from '../../../utils/string-utils';
import {
    orderToFilteredDateTimeRange,
    stopToDateTimeRange,
} from '../../../utils/data-mapping-utils';
import { isCargoStop } from '../../../constants/constants';

export const filterForBookingConflicts = (bookingsData, resourceType, resourceId, orderId, dateRange, sublocationIds) => {
    if (!dateRange?.begin || !dateRange?.end) {
        return null;
    }
    const resourceBookings = bookingsData?.[capitalizeFirst(resourceType)] || [];

    let filteredBookings;
    if (resourceType === 'location') {
        //if no sublocation on stop, just check resourceIds, ignore sublocations all together
        if (!sublocationIds || !sublocationIds.length) {
            filteredBookings = resourceBookings.filter(x => x.orderId !== orderId && x.resourceId === resourceId);
        } else {
            filteredBookings = resourceBookings.filter(x => {
                const boolOrderId = x.orderId !== orderId;
                const boolResourceId = x.resourceId === resourceId;
                const boolSublocations = x.organizationSubLocationIds?.some(x => sublocationIds.includes(x));
                return boolOrderId && boolResourceId && boolSublocations;
            });
        }
    } else {
        filteredBookings = resourceBookings.filter(x => x.orderId !== orderId && x.resourceId === resourceId);
    }

    return filteredBookings.find(booking => checkIfDateRangesOverlap(dateRange, booking)) || null;
};

const filterOutId = (array, load) => {
    return array?.filter(x => x.orderId !== load.id) || [];
};

export const createNewBookingsFromLoad = (bookingsData, load) => {
    // Note that this is a different method than orderToDateTimeRange. This is intentional as we don't want to put in
    // bookings unless there are stop arrivalTimes
    const orderDateRange = orderToFilteredDateTimeRange(load);

    // Remove all existing bookings for this order
    const drivers = filterOutId(bookingsData?.User, load);
    const tractors = filterOutId(bookingsData?.Tractor, load);
    const trailers = filterOutId(bookingsData?.Trailer, load);
    const locations = filterOutId(bookingsData?.Location, load);
    const loadingTeams = filterOutId(bookingsData?.LoadingTeam, load);

    // Recreate all of them
    if (orderDateRange) {
        load.transportedByUserId && drivers.push({
            resourceId: load.transportedByUserId,
            orderId: load.id, ...orderDateRange
        });
        load.tractorId && tractors.push({ resourceId: load.tractorId, orderId: load.id, ...orderDateRange });
        load.trailerId && trailers.push({ resourceId: load.trailerId, orderId: load.id, ...orderDateRange });

        load.stops?.filter(isCargoStop)?.forEach(stop => {
            const stopDateRange = stopToDateTimeRange(stop);

            if (stopDateRange) {
                stop.organizationLocationId && locations.push({
                    resourceId: stop.organizationLocationId,
                    orderId: load.id,
                    orderNumber: stop.orderNumber,
                    stopSequence: stop.sequence,
                    organizationSubLocationId: stop.organizationSubLocationId,
                    lotId: stop.lotId,
                    ...stopDateRange
                });
                stop.loadingTeamId && loadingTeams.push({
                    resourceId: stop.loadingTeamId,
                    orderId: load.id,
                    orderNumber: stop.orderNumber,
                    stopSequence: stop.sequence,
                    organizationSubLocationId: stop.organizationSubLocationId,
                    lotId: stop.lotId,
                    ...stopDateRange
                });
            }
        });
    }

    return {
        User: drivers,
        Tractor: tractors,
        Trailer: trailers,
        Location: locations,
        LoadingTeam: loadingTeams,
    };
};
