import React from 'react';
import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

const StyledPaper = styled(Paper)({
    padding: '16px',
    zIndex: 100,
    borderRadius: 0,
});

interface ContentHeaderProps {
    children: React.ReactNode;
}

export const ContentHeader: React.FC<ContentHeaderProps> = (props) => {
    const { children } = props;

    return (
        <StyledPaper data-testid='agistics-header' role='header'>
            <div style={{ height: '2.33rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between', boxSizing: 'border-box' }}>
                {children}
            </div>
        </StyledPaper>
    );
};
