import { STOP_STATUSES } from '../../../constants/constants';

export const getCurrentStop = (stops) => {
    if (!stops) {
        return null;
    }
    const orderedStops = stops.sortBy((stop) => stop.sequence).reverse();

    return orderedStops.find((stop) => stop.status !== STOP_STATUSES()['Not Started'].key);
};
