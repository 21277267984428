import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import { L } from 'harmony-language';

export const LoadingTeamDropdown = ({ organizationLocation, loadingTeams, setOrganizationLocation }) => {
    if (!loadingTeams) {
        return null;
    }

    const modifiedLoadingTeams = [{ id: -1, name: 'None' }, ...loadingTeams];

    return (
        <TextField
            variant='standard'
            className='loading-team-dropdown'
            InputLabelProps={{ shrink: true }}
            label={L.loadingTeamDefault()}
            id='default-loading-team'
            style={{ width: '100%' }}
            select={true}
            value={organizationLocation.loadingTeamId || -1}
            onChange={(e) => {
                let value = parseInt(e.target.value);

                if (value === -1) {
                    value = null;
                }
                setOrganizationLocation({ ...organizationLocation, loadingTeamId: value });
            }}>
            {modifiedLoadingTeams.map((loadingTeam) => (
                <MenuItem key={loadingTeam.id} value={loadingTeam.id}>
                    {loadingTeam.name}
                </MenuItem>
            ))}
        </TextField>
    );
};

LoadingTeamDropdown.propTypes = {
    loadingTeams: PropTypes.array,
    organizationLocation: PropTypes.object,
    setOrganizationLocation: PropTypes.func,
};

