import { resetPassword } from '../../user/actions/reset-password';
import { usePermissions } from '../../user/selectors/use-permissions';
import { PermissionType } from 'harmony-constants';

export const useResetPassword = () => {
    const canReset = usePermissions(PermissionType.EditUser);

    const resetFunc = async (email: string) => {
        resetPassword(email);
    };

    return canReset ? resetFunc : null;
};
