import { ConfirmationDialog } from '../shared/confirmation-dialog';
import { L } from 'harmony-language';
import React from 'react';
import PropTypes from 'prop-types';
import { useDeleteLoadsMutation } from '../../api/mutations/delete/use-delete-loads-mutation';

export const DeleteLoadsModal = (props) => {
    const { records, unselectFn, onResultCallback, queryKey } = props;
    const { mutate: deleteLoads } = useDeleteLoadsMutation(queryKey);

    return (
        <ConfirmationDialog
            open={records.length > 0}
            title={L.deleteConfirmationTitle()}
            message={L.deleteConfirmationMessage(`${records.length} ${L.loads().toLocaleLowerCase()}`)}
            cancelText={L.cancel()}
            confirmText={L.delete()}
            resultCallback={async (confirmed) => {
                onResultCallback();
                if (confirmed) {
                    unselectFn?.();
                    await deleteLoads({ ids: records.map(x => x.id) });
                }
            }}>
        </ConfirmationDialog>
    );
};

DeleteLoadsModal.propTypes = {
    records: PropTypes.array,
    unselectFn: PropTypes.func,
    onResultCallback: PropTypes.func,
    queryKey: PropTypes.array,
};
