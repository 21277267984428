import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
// @ts-ignore
import SamsImage from '../../resources/agistics-logo-name.png';
import '../../scss/login.scss';
import TextField from '@mui/material/TextField';
import { L } from 'harmony-language';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import { userSignIn } from './actions/user-sign-in';

export const LoginForm = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSigningIn, setIsSigningIn] = useState(false);

    const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
    const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        setIsSigningIn(true);
        e.preventDefault();
        const success = await userSignIn(email, password);
        if (success) {
            navigate('/');
        }
        setIsSigningIn(false);
    };

    return (
        <div className='login-container'>
            <img className='agistics-logo' src={SamsImage} alt='' />
            <Paper className='login-paper'>
                <form className='login-form' onSubmit={(e) => handleSubmit(e)}>
                    <TextField
                        variant='standard'
                        id='email'
                        type='text'
                        label={L.username()}
                        InputLabelProps={{ shrink: true }}
                        value={email}
                        onChange={handleEmailInput} />
                    <TextField
                        variant='standard'
                        id='password'
                        type='password'
                        label={L.password()}
                        InputLabelProps={{ shrink: true }}
                        value={password}
                        onChange={handlePasswordInput} />
                    <Button type='submit'
                        variant='contained'
                        color='primary'
                        disabled={isSigningIn}
                    >
                        {isSigningIn ? <CircularProgress size={24}/> : L.login()}
                    </Button>
                </form>
                <Link to='/forgot-password'>{L.forgotPassword()}</Link>
            </Paper>
        </div>
    );
};
