import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlineRounded';
import DragHandle from '@mui/icons-material/DragHandle';
import { IconButton, styled, Typography } from '@mui/material';
import { ResourceType } from 'harmony-constants';
import { L } from 'harmony-language';
import React from 'react';
import { Select } from '../../shared/inputs/select';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';

interface TemplateEditorProps {
    templateItemsArray: string[];
    resourceType: string;
    updateTemplate: (reorderedValues: string[]) => void;
}

const TemplateItemsContainer = styled('div')({
    marginBottom: '0.5rem',
    border: '1px solid #e0e0e0 ',
    fontSize: '.875rem',
    maxHeight: '45em',
    overflowY: 'scroll'
});

const Header = styled('div')({
    backgroundColor: '#eee',
    padding: '8px 8px',
    position: 'sticky',
    top: 0,
    zIndex: 1
})

const tractorItems = [
    "checklistItemTractorAdequateTreadDepthLeft",
    "checklistItemTractorAdequateTreadDepthRight",
    "checklistItemTractorAllDoorsOpenAndCloseFully",
    "checklistItemTractorAllFittedWorkingCleanAndNotDamaged",
    "checklistItemTractorAllLightsWorkCorrectlyIncludingHazardLights",
    "checklistItemTractorAllSwitchesAndWarningLightsOperateCorrectly",
    "checklistItemTractorAugerCradleIndicatorLightUnobstructedAndworking",
    "checklistItemTractorFireExtinguisherServicedAndReadyForUse",
    "checklistItemTractorGreaseBoomAugerBearings",
    "checklistItemTractorGreaseBoomCylinderPins",
    "checklistItemTractorGreaseBoomPivot",
    "checklistItemTractorGreaseLowerVerticalRotation",
    "checklistItemTractorGreaseTroughAugerBearings",
    "checklistItemTractorGreaseUpperVerticalBearingClamp",
    "checklistItemTractorGreaseVerticalAugerBearings",
    "checklistItemTractorGreaseVerticalRotationPilotRing",
    "checklistItemTractorHornWorkingCorrectly",
    "checklistItemTractorInspectAndGreaseFifthWheel",
    "checklistItemTractorLensesAreCleanAndNotCracked",
    "checklistItemTractorLicensePlateRegistration",
    "checklistItemTractorLubeChain",
    "checklistItemTractorNoApparentAirLeaksFromBreaks",
    "checklistItemTractorNoCutsOrBulgesInTireSideWallsLeft",
    "checklistItemTractorNoCutsOrBulgesInTireSideWallsRight",
    "checklistItemTractorNoFluidLeaksFromEngineFuelSystemOrWastewaterTankLeft",
    "checklistItemTractorNoFluidLeaksFromEngineFuelSystemOrWastewaterTankRight",
    "checklistItemTractorSeatbeltsWorkingCorrectly",
    "checklistItemTractorSideBodyPictureLeft",
    "checklistItemTractorSideBodyPictureRight",
    "checklistItemTractorSpeedometerWorkingCorrectly",
    "checklistItemTractorSteeringForExcessiveFreeMovement",
    "checklistItemTractorSufficientFuelToCompleteTrip",
    "checklistItemTractorTiresAreCorrectlyInflatedAndNotDamaged",
    "checklistItemTractorTiresAreCorrectlyInflatedAndNotDamagedLeft",
    "checklistItemTractorWasherFluidContainerFull",
    "checklistItemTractorWheelNutsAreTightLeft",
    "checklistItemTractorWheelNutsAreTightRight",
    "checklistItemTractorWheelsNotDamagedLeft",
    "checklistItemTractorWheelsNotDamagedRight",
    "checklistItemTractorWindshieldCleanAndNotCracked",
    "checklistItemTractorWindshieldWashersWorkCorrectly",
    "checklistItemTractorWiperBladesNotDamaged",
    "checklistItemTractorWipersWorkCorrectly",
]

const trailerItems = [
    "checklistItemTrailerAirConnection",
    "checklistItemTrailerAirLines",
    "checklistItemTrailerAllPaperwork",
    "checklistItemTrailerAuditoryAlarm",
    "checklistItemTrailerBackupLights",
    "checklistItemTrailerBearings",
    "checklistItemTrailerBoom",
    "checklistItemTrailerBoomLightsAndWiring",
    "checklistItemTrailerBoomSock",
    "checklistItemTrailerBrakes",
    "checklistItemTrailerCabMonitor",
    "checklistItemTrailerCheckHydraulicOil",
    "checklistItemTrailerCorneringLights",
    "checklistItemTrailerCracksOnUprightBoom",
    "checklistItemTrailerCracksSuspensionBogieTank",
    "checklistItemTrailerCrossOver",
    "checklistItemTrailerDoors",
    "checklistItemTrailerDOTTape",
    "checklistItemTrailerDrainAirReservoirs",
    "checklistItemTrailerDriverPPE",
    "checklistItemTrailerEngine1",
    "checklistItemTrailerEngine2",
    "checklistItemTrailerFloor",
    "checklistItemTrailerGauges",
    "checklistItemTrailerHeatingCooling",
    "checklistItemTrailerHeightTravelMode",
    "checklistItemTrailerHighPressureFilter",
    "checklistItemTrailerHoses",
    "checklistItemTrailerHydraulicReservoirs",
    "checklistItemTrailerInteriorCondition",
    "checklistItemTrailerInteriorExteriorLights",
    "checklistItemTrailerIsClean",
    "checklistItemTrailerLeaks",
    "checklistItemTrailerLicensePlateRegistration",
    "checklistItemTrailerLiftgate",
    "checklistItemTrailerLugNutsTorque",
    "checklistItemTrailerMarkerLights",
    "checklistItemTrailerMonitorNumber",
    "checklistItemTrailerOilLevel",
    "checklistItemTrailerPressureCheckWetKitsPumps",
    "checklistItemTrailerRoofLids",
    "checklistItemTrailerRotate",
    "checklistItemTrailerSeals",
    "checklistItemTrailerSplinesPulledOutAndGreased",
    "checklistItemTrailerSpringsTieDownsStTrucks",
    "checklistItemTrailerSprockets",
    "checklistItemTrailerStubShaft",
    "checklistItemTrailerTankFilters",
    "checklistItemTrailerTargetTemperature",
    "checklistItemTrailerTarps",
    "checklistItemTrailerTensioner",
    "checklistItemTrailerTiresCheckAirPressure",
    "checklistItemTrailerTiresWheels",
    "checklistItemTrailerUpright",
    "checklistItemTrailerV20HandlesAndValves",
    "checklistItemTrailerVentTemps",
    "checklistItemTrailerWorkLights",
]

export const ChecklistTemplateItemsEditor = (props: TemplateEditorProps) => {
    const { templateItemsArray, resourceType, updateTemplate } = props;

    const selectItems = resourceType === ResourceType.Tractor
        // @ts-ignore
        ? tractorItems.map(item => ({id: item, label: L[item]()}))
        // @ts-ignore
        : trailerItems.map(item => ({id: item, label: L[item]()}));

    const handleOnDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (!over || typeof active.id === 'string' || typeof over.id === 'string') return;

        if (active.id !== over.id) {
            const items = Array.from(templateItemsArray);
            const [reorderedItem] = items.splice(active.id - 1, 1);
            items.splice((over.id - 1), 0, reorderedItem);

            updateTemplate(items);
        }
    }

    return (
        <div>
            <Header>
                <Typography>{L.checklistItems()}</Typography>
            </Header>
            <TemplateItemsContainer>
                <DndContext
                    onDragEnd={handleOnDragEnd}
                    modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                >
                    <SortableContext
                        items={templateItemsArray.map((_, i) => i + 1)}
                        strategy={verticalListSortingStrategy}
                    >
                        {templateItemsArray.map((x, i) => (
                            <DraggableTemplateRow
                                key={i}
                                selectItems={selectItems}
                                x={x}
                                i={i}
                                templateItemsArray={templateItemsArray}
                                updateTemplate={updateTemplate}
                            />
                        ))}
                        <IconButton
                            title={L.add()}
                            size='small'
                            color='primary'
                            onClick={() => {
                                const items = Array.from(templateItemsArray);
                                items.push('');

                                updateTemplate(items);
                            }}
                        >
                            <AddBoxOutlinedIcon color='primary' />
                        </IconButton>
                    </SortableContext>
                </DndContext>
            </TemplateItemsContainer>
        </div>
    )
}

interface TemplateItemRowProps {
    selectItems: { id: string, label: any }[];
    x: string;
    i: number;
    templateItemsArray: string[];
    updateTemplate: (reorderedValues: string[]) => void;
}

const DraggableTemplateRow: React.FC<TemplateItemRowProps> = (props) => {
    const { selectItems, x, i, templateItemsArray, updateTemplate } = props;
    const { attributes, listeners, setNodeRef, transform } = useSortable({
        // adding 1, as when this is 0, the first item in the list is not draggable
        id: i + 1,
        transition: null,
    });
    const style = {
        transform: CSS.Translate.toString(transform),
        // transition: transition,
    };

    return (
        <div
            ref={setNodeRef}
            style={{
                ...style,
                display: 'flex',
                flexDirection: 'row',
                height: '3em',
                alignItems: 'center',
            }}
        >
            <DragHandle
                color='primary'
                sx={{ mx: 1 }}
                {...listeners}
                {...attributes}
            />
            <Select
                list={selectItems}
                required
                item={selectItems.find(selectItem => selectItem.id === x)}
                onChange={(newlySelected) => {
                    const items = Array.from(templateItemsArray);
                    items.splice(i, 1);
                    items.splice(i, 0, newlySelected.id);

                    updateTemplate(items);
                }}
            />
            <IconButton onClick={() => {
                const items = Array.from(templateItemsArray);
                items.splice(items.indexOf(x), 1);

                updateTemplate(items);
            }}>
                <DeleteOutlinedIcon color="primary"/>
            </IconButton>
        </div>
    );
}
