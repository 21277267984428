import axios from 'axios';

axios.defaults.withCredentials = true;

export const userSignIn = async (username: string, password: string) => {
    try {
        await axios.post(`${API_BASE}/api/session`, { username, password });
        return true;
    } catch (err) {
        return false
    }
};
