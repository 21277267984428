import React from 'react';
import { styled } from '@mui/material/styles';
import { L } from 'harmony-language';
import { isValidDate, localNow } from '../../utils/date-time-utils';
import { AgisticsCard } from '../shared/containers/agistics-card';
import Button from '@mui/material/Button';
import { DropDownValue, OmniResourceSelect } from '../shared/inputs/omni-resource-select';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import { downloadResourceReport } from '../../utils/download-utils';
import { AgisticsDateTimePicker } from '../shared/inputs/agistics-date-time-picker';
import { useWeights } from '../shared/hooks/use-weights';
import { useUser } from '../../api/queries/use-user';

const ReportContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
});

const ReportContainerDates = styled('div')({
    flexDirection: 'row',
    marginBottom: '.5rem',
});

interface DashboardResourceReportsProps {
    isDisabled?: boolean,
}

export const DashboardResourceReports: React.FC<DashboardResourceReportsProps> = ({ isDisabled }) => {
    const { user } = useUser();
    const [reportsResource, setReportsResource] = React.useState<DropDownValue | null>(null);
    const [selectedDateBegin, setSelectedDateBegin] = React.useState<string | null>(localNow().add(-1, 'days').startOf('day').toISOString());
    const [selectedDateEnd, setSelectedDateEnd] = React.useState<string | null>(localNow().add(1, 'hour').startOf('hour').toISOString());
    const { weightSystem } = useWeights();

    return (
        <AgisticsCard title={L.resourceReports()}
            left={
                <Tooltip
                    placement={'right-end'}
                    title={L.resourceReportsInfo()} >
                    <Icon fontSize='medium'>info_outlined</Icon>
                </Tooltip >
            }>
            <ReportContainer>
                <ReportContainerDates>
                    <div>
                        <AgisticsDateTimePicker
                            disabled={isDisabled}
                            label={L.dateBegin()}
                            value={selectedDateBegin}
                            onChange={(date) => setSelectedDateBegin(date)}
                        />
                    </div>
                    <div>
                        <AgisticsDateTimePicker
                            disabled={isDisabled}
                            label={L.dateEnd()}
                            value={selectedDateEnd}
                            onChange={(date) => setSelectedDateEnd(date)}
                        />
                    </div>
                </ReportContainerDates>
                <div>
                    <OmniResourceSelect
                        isDisabled={isDisabled}
                        resourceTypes={['driver', 'tractor', 'trailer']}
                        value={reportsResource}
                        onChange={(value: DropDownValue | null) => {
                            setReportsResource(value);
                        }} />
                    <Button
                        style={{ marginLeft: '1rem' }}
                        type='button'
                        title={L.resourceReportGenerate()}
                        variant='contained'
                        disabled={isDisabled || !isValidDate(selectedDateBegin)}
                        onClick={() => {
                            downloadResourceReport(user.organizationId, selectedDateBegin, selectedDateEnd, reportsResource?.type, reportsResource?.id, weightSystem);
                        }}
                        color='primary'>{L.generate()}</Button>
                </div>
            </ReportContainer>
        </AgisticsCard >
    );
};
