import React from 'react';
import { styled } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';

const Actions = styled('div')(({ theme }) => ({
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
}));

export const TablePaginationActions = props => {
    const { count, page, rowsPerPage, onPageChange } = props;
    const goToFirstPage = React.useCallback((event) => onPageChange(event, 0), [onPageChange]);
    const previousPage = React.useCallback((event) => onPageChange(event, page - 1), [onPageChange, page]);
    const nextPage = React.useCallback((event) => onPageChange(event, page + 1), [onPageChange, page]);
    const goToLastPage = React.useCallback((event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1)), [onPageChange, rowsPerPage, count]);

    return (
        <Actions>
            <IconButton
                onClick={goToFirstPage}
                disabled={page === 0}
                aria-label='first page'
                size="large">
                <FirstPageIcon/>
            </IconButton>
            <IconButton
                onClick={previousPage}
                disabled={page === 0}
                aria-label='previous page'
                size="large">
                <KeyboardArrowLeft/>
            </IconButton>
            <IconButton
                onClick={nextPage}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label='next page'
                size="large">
                <KeyboardArrowRight/>
            </IconButton>
            <IconButton
                onClick={goToLastPage}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label='last page'
                size="large">
                <LastPageIcon/>
            </IconButton>
        </Actions>
    );
};

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

