import React from 'react';
import TextField from '@mui/material/TextField';
import { L } from 'harmony-language';
import { NOTIFICATION_TYPES, NotificationType, Contact, User } from '../../../types'
import { PhoneInput } from '../../shared/inputs/phone-input';
import { Button } from '@mui/material';
import { CheckboxEnabled } from '../../shared/inputs/checkbox-enabled';
import { SelectTimezone } from '../../shared/inputs/select-timezone';
import { SelectLanguage } from '../../shared/inputs/select-language';
import { SelectUnits } from '../../shared/inputs/select-units';
import { SelectUnitsWeight } from '../../shared/inputs/select-units-weight';
import { UnitsWeight } from 'harmony-constants';
import { useUser } from '../../../api/queries/use-user';

interface ConditionalFormWrapperProps {
    condition: boolean;
    wrapper: (c: any) => React.ReactNode;
    children: any;
}

const ConditionalFormWrapper = (props: ConditionalFormWrapperProps) => {
    const { condition, wrapper, children } = props;

    return (condition ? wrapper(children) : children);
}

const createNewContact = (self: User) => {
    return {
        name: '',
        language: self?.organization?.language || 'en-US',
        timezone: self?.organization?.timezone || 'America/Chicago',
        units: self?.organization?.units || 'Imperial',
        unitsWeight: self?.organization?.unitsWeight || UnitsWeight.Pound,
        enabled: true,
        contactNotificationMethods: [],
    };
};

type EditCreateContactProps = {
    contact: Contact;
    onContactChanged?: (contact: Contact) => void;
    onSubmit?: (contact: Contact) => void;
};

export const EditCreateContact = (props: EditCreateContactProps) => {
    const { contact, onContactChanged, onSubmit } = props;
    const { user: self } = useUser();
    const [editContact, setEditContact] = React.useState<Contact>(contact || createNewContact(self));

    //current business assumption only 1 of each email / phone.  Though database model supports multiple if needed in future.
    const notificationTypesSplitter = (notificationType: NotificationType) => {
        const existing = editContact.contactNotificationMethods.find(x => x.notificationType === notificationType);
        const others = editContact.contactNotificationMethods.filter(x => x.notificationType !== notificationType);
        return { existing, others };
    };

    const update = (updatedContact: Contact) => {
        setEditContact(updatedContact);
        onContactChanged && onContactChanged(updatedContact);
    };

    const updateContactNotificationMethod = (notificationType: NotificationType, value: string) => {
        const { existing, others } = notificationTypesSplitter(notificationType);

        if (existing) {
            update({ ...editContact, contactNotificationMethods: [ ...others, { ...existing, destination: value}]});
        } else {
            update({ ...editContact, contactNotificationMethods: [ ...others, { 
                ...(contact?.id ? { contactId: contact.id } : {}),
                notificationType: notificationType,
                destination: value,
            }]});
        }
    };

    const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        onSubmit && onSubmit(editContact)
    };

    return (
        <ConditionalFormWrapper
            condition={onSubmit ? true : false}
            wrapper={(children: any) => <form id='edit-create-contact-form' name='edit-create-contact-form' onSubmit={submitForm}>{children}</form>}
        >
            <TextField
                variant='standard'
                label={L.name()}
                fullWidth
                required={true}
                value={editContact?.name}
                onChange={(e) => {
                    update({ ...editContact, name: e.target.value });
                }} />
            <TextField
                variant='standard'
                label={L.email()}
                fullWidth
                value={notificationTypesSplitter(NOTIFICATION_TYPES.Email).existing?.destination || ''}
                onChange={(e) => {
                    updateContactNotificationMethod(NOTIFICATION_TYPES.Email, e.target.value);
                }} />
            <PhoneInput
                phone={notificationTypesSplitter(NOTIFICATION_TYPES.Text).existing?.destination}
                onChange={(phone: string) => {
                    updateContactNotificationMethod(NOTIFICATION_TYPES.Text, phone);
                }}
            />
            <SelectLanguage
                language={editContact?.language}
                onChange={(e) => {
                    update({ ...editContact, language: e });
                }}
            />
            <SelectTimezone
                timezone={editContact?.timezone}
                onChange={(e) => {
                    update({ ...editContact, timezone: e });
                }}
            />
            <SelectUnits
                units={editContact?.units}
                onChange={(e) => {
                    update({ ...editContact, units: e });
                }}
            />
            <SelectUnitsWeight
                unitsWeight={editContact.unitsWeight}
                onChange={(e: UnitsWeight) => {
                    update({ ...editContact, unitsWeight: e });
                }}
            />
            {onSubmit ?
                <CheckboxEnabled
                    checked={editContact.enabled}
                    onChange={() => update({ ...editContact, enabled: !editContact.enabled })}
                /> : null
            }
            {onSubmit ?
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '1rem'
                }}>
                    <Button type='submit'
                        title={L.save()}
                        variant='contained'
                        color='primary'>{editContact.id ? L.updateContact() : L.createContact()}
                    </Button>
                </div>
            : null}
        </ConditionalFormWrapper>
    );
}
