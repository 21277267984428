import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { mutationOptions, QueryKeys } from '../../config';
import { Organization } from '../../../types';

async function addCompany(options: { company: Organization }) {
    const company = options.company;

    return (await axios.post(`${API_BASE}/api/organizations`, company)).data;
}

export const useAddCompanyMutation = () => {
    const snackbar = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation(addCompany, {
        ...mutationOptions([QueryKeys.companies], queryClient),
        onSuccess: (data) => {
            snackbar(L.createdMessage(data.name));
        }
    });
};
