import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { L } from 'harmony-language';
import MenuItem from '@mui/material/MenuItem';
import '../../../scss/simple-select-with-validation.scss';

// Supplies translatable error messages to the user when fields aren't populated.  Supports textFields and select textFields.
// Has to be reloaded after first render so elements can be found and updated in DOM
export const TextFieldWithValidation = props => {
    const { autoFocus, className, items, label, onChange, requiredMsg, type, value, required, validationFunc } = props;
    const [, forceRender] = React.useState();

    React.useEffect(() => forceRender({}), []);

    const message = requiredMsg || L.requiredField();
    const fullClassName = `validated-select-select ${className}`;
    const validationId = className + '-valid';

    return (
        <div className='bulk-config-field'>
            <TextField
                variant='standard'
                id={items ? className : validationId}
                autoFocus={autoFocus}
                className={fullClassName}
                label={label}
                onChange={onChange}
                inputProps={{
                    'aria-label': label
                }}
                required={required}
                select={Boolean(items)}
                type={type || 'string'}
                value={value}
                InputProps={{
                    ...props.InputProps,
                }}>
                {items && items.map(item => {
                    return (<MenuItem key={className + '-item-' + item.id} value={item.value}>{item.label}</MenuItem>);
                })}
            </TextField>
            {items && <input id={validationId} tabIndex={-1} autoComplete='off' className='validated-select-input' defaultValue={value} required/>}
            {document.getElementById(validationId) && !value && required && document.getElementById(validationId).setCustomValidity(message)}
            {document.getElementById(validationId) && value && document.getElementById(validationId).setCustomValidity('')}
            {document.getElementById(validationId) && validationFunc && !validationFunc() && document.getElementById(validationId).setCustomValidity(message)}
            {document.getElementById(validationId) && validationFunc && validationFunc() && document.getElementById(validationId).setCustomValidity('')}
        </div>
    );
};

TextFieldWithValidation.propTypes = {
    autoFocus: PropTypes.bool,
    className: PropTypes.string.isRequired,
    items: PropTypes.array,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    requiredMsg: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    InputProps: PropTypes.object,
    required: PropTypes.bool,
    validationFunc: PropTypes.func,
};
