import { Checkbox, InputAdornment, MenuItem, Tab, Tabs } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { L, Units, UNITS } from 'harmony-language';
import React, { FormEvent } from 'react';
import { OrgQueryKeys } from '../../../api/config';
import { useCargoTypes } from '../../../api/queries/use-cargo-types';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { AvoidanceZone, Bbox, EditCreateAvoidanceZoneType, OrganizationLocation, OrganizationLocationWithCustomGeofence, Point } from '../../../types';
import { utc } from '../../../utils/date-time-utils';
import { HereMap, HereMapRefObject } from '../../here-maps/here-map';
import { HereMapMarker } from '../../here-maps/here-map-marker';
import { HereMapRectangle } from '../../here-maps/here-map-rectangle';
import { SiteLocator } from '../../live-map/components/site-locator';
import { AgisticsTabPanel } from '../../shared/agistics-tab-panel';
import { useWeights } from '../../shared/hooks/use-weights';
import { AgisticsMultiSelect } from '../../shared/multi-select/agistics-multi-select';
import { AvoidanceZoneAddress } from './avoidance-zone-address';
import { avoidanceZoneColor, avoidanceZoneStrokeColor, globalAvoidanceZoneColor, globalAvoidanceZoneStrokeColor } from './avoidance-zone-management';
import { pointToBbox } from 'harmony-constants';
import { AvoidanceZonePoint } from './avoidance-zone-point';
import { adjust, calculateOuterBounds, getHaversineDistance } from './haversine';

// arbitrary number, just don't want values wrapping the earth
const maxAvoidanceZoneSize = 200;
const tabHeight = '24px';

interface EditCreateAvoidanceZoneProps {
    existingAvoidanceZone: AvoidanceZone | null;
    canModifyGlobalAvoidanceZones: boolean;
    centerPosition: { lat: number, lng: number },
    companyId: number;
    onSubmit: (a: EditCreateAvoidanceZoneType) => void;
    userUnits: Units;
}

export const EditCreateAvoidanceZone: React.FC<EditCreateAvoidanceZoneProps> = (props) => {
    const { existingAvoidanceZone, canModifyGlobalAvoidanceZones, centerPosition, companyId, onSubmit, userUnits } = props;
    const [avoidanceZone, setAvoidanceZone] = React.useState<EditCreateAvoidanceZoneType>(existingAvoidanceZone || {
        name: '',
        description: '',
        organizationId: companyId,
        deactivatedAt: null,
        geoLocation: {
            latitude: 0,
            longitude: 0,
            bbox: {
                topLeft: {
                    latitude: 0,
                    longitude: 0,
                },
                bottomRight: {
                    latitude: 0,
                    longitude: 0,
                },
            },
        },
        weightThreshold: null,
        cargoTypeIds: null
    });
    const mapRef = React.useRef<HereMapRefObject>(null);
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { convertFromGrams, convertToGrams, weightAbbreviation } = useWeights();
    const { cargoTypeList, isLoading: isCargoTypesLoading } = useCargoTypes();

    const [helperMarker, setHelperMarker] = React.useState<Point | null>(null);
    const [tabValue, setTabValue] = React.useState(0);
    const [restrictBy, setRestrictBy] = React.useState(avoidanceZone.weightThreshold ? 'weight' : avoidanceZone.cargoTypeIds ? 'cargoType' : 'none');
    const [weightThreshold, setWeightThreshold] = React.useState(avoidanceZone.weightThreshold ? convertFromGrams(avoidanceZone.weightThreshold) : null);

    const roundFunc = (number: number) => {
        return Math.round(number * 100) / 100;
    }

    const constructTopRight = (bbox: Bbox) => {
        return {
            latitude: bbox.topLeft.latitude,
            longitude: bbox.bottomRight.longitude,
        }
    }

    const setHeightWidth = (bbox: Bbox) => {
        const topRight = constructTopRight(bbox);
        const newWidth = getHaversineDistance(bbox.topLeft, topRight, userUnits);
        setWidth(roundFunc(newWidth));
        const newHeight = getHaversineDistance(bbox.bottomRight, topRight, userUnits);
        setHeight(roundFunc(newHeight));
    }
    const [width, setWidth] = React.useState<number | null>(existingAvoidanceZone
        ? roundFunc(getHaversineDistance(existingAvoidanceZone.geoLocation.bbox.topLeft, constructTopRight(existingAvoidanceZone.geoLocation.bbox), userUnits))
        : null);
    const [height, setHeight] = React.useState<number | null>(existingAvoidanceZone
        ? roundFunc(getHaversineDistance(existingAvoidanceZone.geoLocation.bbox.bottomRight, constructTopRight(existingAvoidanceZone.geoLocation.bbox), userUnits))
        : null);

    const onClick = (coords: { lat: number, lng: number }) => {
        if (existingAvoidanceZone) {
            return;
        }

        const bbox = pointToBbox({ latitude: coords.lat, longitude: coords.lng });

        setAvoidanceZone(prev => ({
            ...prev,
            geoLocation: {
                ...avoidanceZone.geoLocation,
                latitude: coords.lat,
                longitude: coords.lng,
                bbox: bbox,
            },
        }));

        setHeightWidth(bbox);
    };

    const onShapeChanged = (changedShape: { latitude: number, longitude: number, bbox: Bbox }) => {
        setAvoidanceZone(prev => ({
            ...prev,
            geoLocation: {
                ...avoidanceZone.geoLocation,
                ...changedShape,
            },
        }));

        setHeightWidth(changedShape.bbox);
    };

    const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        onSubmit(avoidanceZone);
    };

    const onStatusSwitch = () => {
        onSubmit({
            ...avoidanceZone,
            deactivatedAt: avoidanceZone.deactivatedAt ? null : utc(),
        });
    };

    const renderAvoidanceZone = () => {
        const latitude = avoidanceZone.geoLocation.latitude;
        const longitude = avoidanceZone.geoLocation.longitude;

        if (!latitude || !longitude) {
            return null;
        }

        return (
            <HereMapRectangle
                pointA={{
                    lat: avoidanceZone.geoLocation.bbox.topLeft.latitude,
                    lng: avoidanceZone.geoLocation.bbox.topLeft.longitude,
                }}
                pointB={{
                    lat: avoidanceZone.geoLocation.bbox.bottomRight.latitude,
                    lng: avoidanceZone.geoLocation.bbox.bottomRight.longitude,
                }}
                type='avoidance'
                strokeColor={!avoidanceZone.organizationId ? globalAvoidanceZoneStrokeColor : avoidanceZoneStrokeColor}
                fillColor={!avoidanceZone.organizationId ? globalAvoidanceZoneColor : avoidanceZoneColor}
                changeable={!existingAvoidanceZone}
                onChanged={onShapeChanged}
            />
        );
    };

    const renderHelperMarker = () => {
        if (helperMarker) {
            return <HereMapMarker latitude={helperMarker.latitude} longitude={helperMarker.longitude} />
        }
    }

    const renderGlobalCheckbox = () => {
        if (!canModifyGlobalAvoidanceZones || existingAvoidanceZone) {
            return null;
        }

        return (
            <div className='avoidance-zone-global-checkbox'>
                <Checkbox
                    checked={!avoidanceZone.organizationId}
                    onChange={() => {
                        setAvoidanceZone(prev => ({
                            ...prev,
                            organizationId: avoidanceZone.organizationId ? null : companyId,
                        }));
                    }}
                />
                <span>{L.globalZone()}</span>
            </div>
        );
    };

    const onWidthChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const changedWidth = Number(e.target.value) > maxAvoidanceZoneSize ? maxAvoidanceZoneSize : Number(e.target.value);
        const outerBounds = calculateOuterBounds(avoidanceZone.geoLocation.bbox.topLeft, changedWidth, props.userUnits);
        const bounds = {
            east: adjust(avoidanceZone.geoLocation.bbox.topLeft, [0, outerBounds.deltaLongitude]),
        }

        const newBottomRight = {
            latitude: avoidanceZone.geoLocation.bbox.bottomRight.latitude,
            longitude: bounds.east.longitude,
        }

        setAvoidanceZone(prev => ({
            ...prev,
            geoLocation: {
                ...avoidanceZone.geoLocation,
                bbox: {
                    ...avoidanceZone.geoLocation.bbox,
                    bottomRight: newBottomRight,
                }
            },
        }));

        setWidth(roundFunc(changedWidth));
    };

    const onHeightChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const changedHeight = Number(e.target.value) > maxAvoidanceZoneSize ? maxAvoidanceZoneSize : Number(e.target.value);
        const outerBounds = calculateOuterBounds(avoidanceZone.geoLocation.bbox.bottomRight, changedHeight, props.userUnits);
        const bounds = {
            north: adjust(avoidanceZone.geoLocation.bbox.bottomRight, [outerBounds.deltaLatitude, 0]),
        }

        const newTopLeft = {
            latitude: bounds.north.latitude,
            longitude: avoidanceZone.geoLocation.bbox.topLeft.longitude,
        }

        setAvoidanceZone(prev => ({
            ...prev,
            geoLocation: {
                ...avoidanceZone.geoLocation,
                bbox: {
                    ...avoidanceZone.geoLocation.bbox,
                    topLeft: newTopLeft,
                }
            },
        }));

        setHeight(roundFunc(changedHeight));
    };

    const onSiteLocatorChange = (orgLoc: OrganizationLocationWithCustomGeofence | null) => {
        if (!orgLoc) {
            return;
        }

        const customGeofence = structuredClone(orgLoc.customGeofence);

        setAvoidanceZone(prev => ({
            ...prev,
            name: orgLoc.name,
            geoLocation: {
                ...avoidanceZone.geoLocation,
                latitude: customGeofence.latitude,
                longitude: customGeofence.longitude,
                bbox: customGeofence.bbox,
            }
        }))

        setHeightWidth(customGeofence.bbox);

        // centering map on dropdown change fixes a bug with instance.current.map.removeObject that happens in here-map-rectangle
        // where the old avoidance zone is not removed from the screen (as in it's still visiable), but if you get the objects on the map, it's not there
        mapRef.current?.centerMap(customGeofence.latitude, customGeofence.longitude, 14);
    }

    const mapStart = existingAvoidanceZone ? {
        lat: avoidanceZone.geoLocation.latitude,
        lng: avoidanceZone.geoLocation.longitude,
    } : {
        lat: centerPosition.lat,
        lng: centerPosition.lng,
    }

    function handleRestrictByChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const value = e.target.value;
        if (value !== 'weight') {
            setAvoidanceZone(prev => ({...prev, weightThreshold: null}))
        }
        if (value !== 'cargoType') {
            setAvoidanceZone(prev => ({...prev, cargoTypeIds: null}))
        }
        setRestrictBy(value);
    }

    const cargoTypesMultiItems = React.useMemo(() => {
        return cargoTypeList.map((cargoType) => {
            return {
                id: cargoType.id,
                value: cargoType.label,
            }
        });
    }, [cargoTypeList]);

    return (
        <div className='avoidance-zone-creation-container'>
            <form onSubmit={onFormSubmit}>
                <div className='avoidance-zone-creation-form-fields'>
                    <TextField
                        variant='standard'
                        value={avoidanceZone.name}
                        label={L.name()}
                        onChange={(e) => setAvoidanceZone(prev => ({ ...prev, name: e.target.value }))}
                        inputProps={{
                            'aria-label': L.name()
                        }}
                        required />
                    <TextField
                        variant='standard'
                        value={avoidanceZone.description}
                        onChange={(e) => setAvoidanceZone(prev => ({ ...prev, description: e.target.value }))}
                        inputProps={{
                            'aria-label': L.description()
                        }}
                        label={L.description()} />
                    <TextField
                        variant='standard'
                        value={width || ''}
                        type='number'
                        disabled={Boolean(existingAvoidanceZone) || Boolean(!avoidanceZone.geoLocation.bbox.bottomRight.latitude)}
                        style={{ width: '6rem' }}
                        onChange={(e) => onWidthChange(e)}
                        inputProps={{ min: 0, step: 0.01 }}
                        InputProps={{
                            endAdornment: (props.userUnits === UNITS.Imperial ? L.unitsImperialDistanceAbbr() : L.unitsMetricDistanceAbbr()),
                        }}
                        label={L.width()} />
                    <TextField
                        variant='standard'
                        value={height || ''}
                        type='number'
                        disabled={Boolean(existingAvoidanceZone) || Boolean(!avoidanceZone.geoLocation.bbox.bottomRight.latitude)}
                        style={{ width: '6rem' }}
                        onChange={(e) => onHeightChange(e)}
                        inputProps={{ min: 0.0, step: 0.01 }}
                        InputProps={{
                            endAdornment: (props.userUnits === UNITS.Imperial ? L.unitsImperialDistanceAbbr() : L.unitsMetricDistanceAbbr()),
                        }}
                        label={L.height()} />
                    <TextField
                        variant='standard'
                        value={restrictBy}
                        select
                        style={{ width: '8rem' }}
                        onChange={handleRestrictByChange}
                        label="Restriction:"
                    >
                        <MenuItem value="none">{L.none()}</MenuItem>
                        <MenuItem value="weight">{L.weight()}</MenuItem>
                        <MenuItem value="cargoType">{L.cargoType()}</MenuItem>
                    </TextField>
                    {restrictBy === 'weight' &&
                        <TextField
                            variant='standard'
                            value={weightThreshold || ''}
                            type='number'
                            style={{ width: '7rem' }}
                            onChange={(e) => {
                                const newWeight = Number(e.target.value);
                                setWeightThreshold(newWeight);
                                setAvoidanceZone(prev => ({...prev, weightThreshold: convertToGrams(newWeight)}));
                            }}
                            inputProps={{ min: 0 }}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>{weightAbbreviation}</InputAdornment>
                            }}
                            label=' '
                        />
                    }
                    {restrictBy === 'cargoType' && !isCargoTypesLoading &&
                        <div style={{ width: '14rem' }}>
                        <AgisticsMultiSelect
                            label={L.select()}
                            selectedIds={avoidanceZone.cargoTypeIds || []}
                            setSelectedIds={(value) => setAvoidanceZone(prev => ({...prev, cargoTypeIds: value}))}
                            items={cargoTypesMultiItems}
                        />
                        </div>
                    }
                    {renderGlobalCheckbox()}
                </div>
                {!existingAvoidanceZone && <>
                    <Tabs
                        value={tabValue}
                        onChange={(_, value) => setTabValue(value)}
                        sx={{ minHeight: tabHeight, height: tabHeight, margin: '1em 0 .5em 0' }}
                    >
                        <Tab label={L.siteLocator()} sx={{ minHeight: tabHeight, height: tabHeight }} />
                        <Tab label={L.point()} sx={{ minHeight: tabHeight, height: tabHeight }} />
                        <Tab label={L.address()} sx={{ minHeight: tabHeight, height: tabHeight }} />
                    </Tabs>
                    <AgisticsTabPanel value={tabValue} index={0} p={0}>
                        <SiteLocator
                            sites={organizationLocations}
                            onChange={onSiteLocatorChange}
                        />
                    </AgisticsTabPanel>
                    <AgisticsTabPanel value={tabValue} index={1} p={0}>
                        <AvoidanceZonePoint centerMap={mapRef.current?.centerMap} setHelperMarker={setHelperMarker} />
                    </AgisticsTabPanel>
                    <AgisticsTabPanel value={tabValue} index={2} p={0}>
                        <AvoidanceZoneAddress centerMap={mapRef.current?.centerMap} setHelperMarker={setHelperMarker} />
                    </AgisticsTabPanel>
                </>}
                <HereMap id='avoidance-zone-creation-map' className='edit-order-map' onClick={onClick}
                    ref={mapRef}
                    centerPosition={mapStart}
                >
                    {renderAvoidanceZone()}
                    {renderHelperMarker()}
                </HereMap>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1em' }}>
                    {existingAvoidanceZone &&
                        <Button
                            variant='contained'
                            color='secondary'
                            type='button'
                            onClick={onStatusSwitch}
                            sx={{ marginRight: '1em' }}
                        >
                            {avoidanceZone.deactivatedAt ? L.activate() : L.deactivate()}
                        </Button>
                    }
                    <Button
                        disabled={!avoidanceZone.geoLocation.latitude}
                        variant='contained'
                        color='primary'
                        type='submit'
                    >
                        {existingAvoidanceZone ? L.saveChanges() : L.createZone()}
                    </Button>
                </div>
            </form>
        </div>
    );
};
