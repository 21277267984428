import axios from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

export const resetPassword = async (email: string, navigate?: NavigateFunction) => {
    await axios.post(`${API_BASE}/api/users/reset-password`, { email });

    if (navigate) {
        navigate('/login');
    }

    enqueueSnackbar('Password email sent.', { variant: 'success' });
};
