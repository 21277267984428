import React from 'react';
import { AdminBreadCrumbHeader } from '../admin-bread-crumb-header';
import { Content } from '../../shared/containers/content';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { EditCreateCompany } from '../companies/edit-create-company/edit-create-company';
import { useEditCompanyMutation } from '../../../api/mutations/edit/use-edit-company-mutation';
import { useCompanyId } from '../../../app/agistics-context';

export const SettingsManagement = () => {
    const companyId = useCompanyId();
    const { data: company, isLoading } = useOrganizationQuery('', { orgId: companyId });
    const { mutate: editCompany, isLoading: isUpdating } = useEditCompanyMutation(companyId);

    return (
        <>
            <AdminBreadCrumbHeader/>
            <Content>
                <div style={{
                    maxWidth: '40rem',
                    minWidth: '20rem',
                    width: '100%'
                }}>
                    <EditCreateCompany
                        isLoading={isLoading || isUpdating}
                        existingCompany={company} onSubmit={async (newData) => {
                            editCompany({ company: newData });
                        }}/>
                </div>
            </Content>
        </>
    );
};
