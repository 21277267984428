import React from 'react';
import Switch from '@mui/material/Switch';
import Skeleton from "@mui/material/Skeleton";
import { useStaticQuery } from '../../../../api/queries/use-static-query';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { OrgQueryKeys, QueryKeys } from '../../../../api/config';
import { useAddFeatureMutation } from '../../../../api/mutations/add/use-add-feature-mutation';
import { useDeleteFeatureMutation } from '../../../../api/mutations/delete/use-delete-feature-mutation';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import PropTypes from 'prop-types';
import { ResourceEditCreateContainer } from '../../../shared/containers/resource-edit-create-container';

export const FeatureManagement = (props) => {
    const { companyId } = props;

    const { data: features, isLoading: isLoadingFeatures } = useStaticQuery(QueryKeys.features);
    const { data: companyFeatures, isLoading: isLoadingCompanyFeatures } = useOrganizationQuery(OrgQueryKeys.features, { orgId: companyId });
    const { mutate: addFeature } = useAddFeatureMutation(companyId);
    const { mutate: deleteFeature } = useDeleteFeatureMutation(companyId);

    const featureList = React.useMemo(() => features?.map(feature => ({
        ...feature,
        enabled: companyFeatures?.some(f => f.id === feature.id) || false
    })), [features, companyFeatures]);

    const handleChange = (enabled, id) => {
        if (enabled) {
            deleteFeature({ featureId: id });
        } else {
            addFeature({ featureId: id });
        }
    };

    const toSkeletonListItem = (x) => <Skeleton key={x} width='100%' height={32}/>;

    const isLoading = isLoadingFeatures || isLoadingCompanyFeatures;

    return (
        <ResourceEditCreateContainer width='20vw' data-testid={'feature-management'}>
            <List>
                {isLoading ? Array.from(Array(5), (x, i) => i).map(toSkeletonListItem) : featureList?.map(feature => {
                    return (
                        <ListItem key={feature.id} style={{ padding: 0 }}>
                            <p style={{ margin: 0 }}>{feature.description}</p>
                            <Switch
                                color='primary'
                                checked={feature.enabled}
                                onChange={() => {
                                    handleChange(feature.enabled, feature.id);
                                }}
                                name={feature.name + ' switch'}
                                inputProps={{ 'aria-label': feature.name + ' switch' }}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </ResourceEditCreateContainer>
    );
};


FeatureManagement.propTypes = {
    companyId: PropTypes.number.isRequired
};
