import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { mutationOptions, OrgQueryKeys } from '../../config';
import { TravelTimesCache, TravelTimesResponse, travelTimesRecordBuilder } from '../../../components/travel-times/use-travel-times';
import { travelTimesCacheKey } from '../../../utils/data-mapping-utils';

const addTravelTimes = (orgId: number) => async (pairs: number[][]) => {
    return (await axios.post(`${API_BASE}/api/organizations/${orgId}/locations/travel-times`, pairs)).data;
}

export const useAddTravelTimes = (organizationId: number) => {
    const key = OrgQueryKeys.resolve(organizationId, OrgQueryKeys.locationTravelTimes);
    const queryClient = useQueryClient();

    return useMutation(addTravelTimes(organizationId), {
        ...mutationOptions([key], queryClient),
        mutationKey: [key],
        onSuccess: (data: TravelTimesResponse[]) => {
            const previousCache = queryClient.getQueryData<TravelTimesCache>([key]);
            if (previousCache) {
                const newGeneratedItems: TravelTimesCache = data.reduce((acc, cur) => {
                    const key = travelTimesCacheKey(cur);
                    const existingItem = previousCache[key];
                    if (existingItem) {
                        return acc;
                    } else {
                        return travelTimesRecordBuilder(acc, cur);
                    }
                }, {});

                const newCache = {...previousCache, ...newGeneratedItems};

                // Optimistically update to the new value
                queryClient.setQueryData([key], newCache);
            }
            // Return a rollback function
            return () => queryClient.setQueryData([key], previousCache);
        },
        onSettled: () => {},
    });
};
