import React from 'react';
import { L } from 'harmony-language';
import { OrgQueryKeys } from '../../../api/config';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { useResetPassword } from './use-reset-password';
import { useEditOrganizationResourceMutation } from '../../../api/mutations/edit/use-edit-organization-resource-mutation';
import { useAddOrganizationResourceMutation } from '../../../api/mutations/add/use-add-organization-resource-mutation';
import { EditCreateUser, userHasEmail } from './edit-create-user/edit-create-user';
import { useUserColumns } from './use-user-columns';
import { ResourcePage } from '../resource-page';
import { formatPhone } from '../../../utils/string-utils';
import { NOTIFICATION_TYPES } from '../../../types';

export const UserManagement = () => {
    const { data: users, isLoading } = useOrganizationQuery(OrgQueryKeys.users);
    const { mutate: addUser, isLoading: isCreating } = useAddOrganizationResourceMutation(OrgQueryKeys.users, L.user());
    const { mutate: editUser, isLoading: isUpdating } = useEditOrganizationResourceMutation(OrgQueryKeys.users, L.user());
    const resetPassword = useResetPassword();

    const actions = resetPassword && [
        {
            icon: 'lockOpen',
            tooltip: L.resetPass(),
            action: (row) => {
                resetPassword(row.email);
            },
            condition: (row) => {
                return userHasEmail(row.contact.contactNotificationMethods);
            },
        }
    ];
    const columns = useUserColumns();

    return (
        <ResourcePage
            resourceType='user'
            isLoading={isLoading || isCreating || isUpdating}
            titleFunc={user => `${user.firstName} ${user.lastName}`}
            additionalActions={actions}
            data={users}
            columns={columns}
            EditCreateComponent={(editCreateProps) => {
                return <EditCreateUser
                    existingUser={editCreateProps.resource}
                    onSubmit={async (newData) => {
                        const existingPhone = newData.contact.contactNotificationMethods.find(x => x.notificationType === NOTIFICATION_TYPES.Text);
                        if (existingPhone) {
                            const index = newData.contact.contactNotificationMethods.indexOf(existingPhone);
                            existingPhone.destination = formatPhone(existingPhone.destination) || '';
                            newData.contact.contactNotificationMethods[index] = existingPhone;
                        }

                        if (newData.id) {
                            editUser(newData);
                        } else {
                            addUser(newData);
                        }
                        editCreateProps.closeModal();
                    }} />;
            }} />
    );
};
