import { L } from 'harmony-language';
import React from 'react';
import { AgisticsDatePicker } from './agistics-date-picker';
import { OnTimeReportMode } from '../../reports/on-time/on-time-report';

interface AgisticsDateRangeProps {
    startDate: string;
    endDate: string;
    setStartDate: (d: string) => void;
    setEndDate: (d: string) => void;
    mode?: OnTimeReportMode;
}

export const AgisticsDateRange: React.FC<AgisticsDateRangeProps> = (props) => {
    const { startDate, endDate, setStartDate, setEndDate, mode } = props;

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <AgisticsDatePicker
                label={L.dateBegin()}
                value={startDate}
                transformTime='start'
                onChange={(date) => {
                    setStartDate(date);
                }}
                style={{ marginRight: '10px' }}
                enabledDatesConfig={mode ? { mode: mode, val: 'start' } : undefined}
            />
            <AgisticsDatePicker
                label={L.dateEnd()}
                value={endDate}
                transformTime='end'
                onChange={(date) => {
                    setEndDate(date);
                }}
                style={{ marginRight: '10px' }}
                enabledDatesConfig={mode ? { mode: mode, val: 'end' } : undefined}
            />
        </div>
    );
};
