import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { L } from 'harmony-language';
import Autocomplete, { AutocompleteChangeReason } from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import React from 'react';
import { filterByRole } from '../../user/utils/user-mappings';
import { SiteManager } from '../../../constants/constants';
import { Driver, Tractor, Trailer, LoadingTeam, OrganizationLocation, User } from '../../../types';
import { toSiteDisplayName } from '../../../utils/data-mapping';

export type ResourceSelectType = 'driver' | 'tractor' | 'trailer' | 'location' | 'loadingTeam' | 'siteManager';

export type DropDownValue = {
    category: string
    type: string
    label: string
    id: number
}
export interface OmniResourceSelectProps {
    isDisabled?: boolean,
    onChange?: (value: DropDownValue | null) => void,
    value: DropDownValue | null,
    resourceTypes: ResourceSelectType[]
}

// todo: make the types of resources loaded and shown configurable via prop parameter
export const OmniResourceSelect = (props: OmniResourceSelectProps) => {
    const { value, onChange, isDisabled } = props;

    const enabledResources: Record<ResourceSelectType, boolean> = {
        driver: props.resourceTypes?.includes('driver') ?? false,
        tractor: props.resourceTypes?.includes('tractor') ?? false,
        trailer: props.resourceTypes?.includes('trailer') ?? false,
        location: props.resourceTypes?.includes('location') ?? false,
        loadingTeam: props.resourceTypes?.includes('loadingTeam') ?? false,
        siteManager: props.resourceTypes?.includes('siteManager') ?? false,
    }

    function enabledOption(resourceType: ResourceSelectType) {
        return { enabled: !isDisabled && enabledResources[resourceType] }
    }

    const { data: drivers, isLoading: isLoadingDrivers } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers, enabledOption('driver'));
    const { data: tractors, isLoading: isLoadingTractors } = useOrganizationQuery<Tractor[]>(OrgQueryKeys.tractors, enabledOption('tractor'));
    const { data: trailers, isLoading: isLoadingTrailers } = useOrganizationQuery<Trailer[]>(OrgQueryKeys.trailers, enabledOption('trailer'));
    const { data: locations, isLoading: isLoadingLocations } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations, enabledOption('location'));
    const { data: loadingTeams, isLoading: isLoadingLoadingTeams } = useOrganizationQuery<LoadingTeam[]>(OrgQueryKeys.loadingTeams, enabledOption('loadingTeam'));
    const { data: users, isLoading: isLoadingUsers } = useOrganizationQuery<User[]>(OrgQueryKeys.users, enabledOption('siteManager')); //?

    const isLoading = isLoadingDrivers || isLoadingTractors || isLoadingTrailers || isLoadingLocations || isLoadingLoadingTeams || isLoadingUsers;

    const options = React.useMemo(() => {
        if (isLoading) {
            return [];
        } else {
            const driversList = (enabledResources.driver && drivers?.map((x) => ({
                category: L.drivers(),
                type: 'driver',
                label: `${x.lastName}, ${x.firstName}`,
                id: x.id
            }))) || [];
            const tractorsList = (enabledResources.tractor && tractors?.map((x) => ({
                category: L.tractors(),
                type: 'tractor',
                label: x.userDisplayName,
                id: x.id
            }))) || [];
            const trailersList = (enabledResources.trailer && trailers?.map((x) => ({
                category: L.trailers(),
                type: 'trailer',
                label: x.userDisplayName,
                id: x.id
            }))) || [];
            const locationsList = (enabledResources.location && locations?.map((x) => ({
                category: x.organizationLocationTypeId ? L[`locationTypeValue${x.organizationLocationTypeId}` as keyof typeof L]() : '',
                type: 'location',
                label: toSiteDisplayName(x.name, x.description),
                id: x.id
            }))) || [];
            const loadingTeamList = (enabledResources.loadingTeam && loadingTeams?.map((x) => ({
                category: L.loadingTeams(),
                type: 'loadingTeam',
                label: x.name,
                id: x.id
            }))) || [];
            const siteManagerList = (enabledResources.siteManager && filterByRole(users, SiteManager)?.map((x) => ({
                category: L.siteManagers(),
                type: 'siteManager',
                label: `${x.lastName}, ${x.firstName}`,
                id: x.id
            }))) || [];

            return [...driversList, ...tractorsList, ...trailersList, ...locationsList, ...loadingTeamList, ...siteManagerList];
        }

    }, [drivers, tractors, trailers, locations, loadingTeams, users, isLoading]);

    const onChangeInternal = (newValue: DropDownValue | null, reason?: AutocompleteChangeReason) => {
        if (reason === 'selectOption' && onChange) {
            onChange(newValue || null)
        }
    }

    return (
        <Autocomplete
            disabled={isDisabled}
            loading={isLoading}
            fullWidth
            id='omni-resource-select'
            value={value}
            isOptionEqualToValue={(a, b) => a.category === b.category && a.id === b.id}
            options={options.sort((a, b) => -b.category.localeCompare(a.category))}
            groupBy={(option) => option.category}
            getOptionLabel={(option) => option.label}
            loadingText={L.loading()}
            noOptionsText={L.noOptions()}
            clearText={L.clear()}
            closeText={L.close()}
            openText={L.open()}
            onChange={(event, newValue, reason) => onChangeInternal(newValue, reason)}
            renderInput={(params) => <TextField variant='standard' {...params} label={L.selectResource()} />}
        />
    );
};
