import React from 'react';
import { IconButton } from '@mui/material';
import { PersonSearch } from '@mui/icons-material';
import { ModalDialog } from '../../shared/containers/modal-dialog';
import { ResourceScheduling } from './resource-scheduling';
import { L } from 'harmony-language';

export const ResourceSchedulingButton: React.FC = () => {
    const [modalOpen, setModalOpen] = React.useState(false);

    return (
        <>
            <IconButton
                onClick={() => setModalOpen(true)}
                size='small'
            >
                <PersonSearch fontSize='small' />
            </IconButton>
            <ModalDialog
                title={L.resourceScheduling()}
                onClose={() => setModalOpen(false)}
                open={modalOpen}
            >
                <ResourceScheduling />
            </ModalDialog>
        </>
    );
};
