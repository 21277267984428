import { createColumnHelper } from '@tanstack/react-table';
import { L } from 'harmony-language';
import React from 'react';
import { OrganizationLocation } from '../../../types';

export const useLocationColumns = () => {
    const columnHelper = createColumnHelper<OrganizationLocation>();

    const columns = React.useMemo(() => {
        const c = [
            columnHelper.accessor('name', {
                header: L.name(),
                cell: (info) => info.getValue(),
                size: 150,
                enableColumnFilter: false
            }),
            // search does not work right when a null value is in this list
            // but it MIGHT actually be, if 1st value in the list is null is what is
            // stoping the searcher from working?!?
            columnHelper.accessor((info) => {
                return info.description ? info.description : '';
            }, {
                header: L.description(),
                cell: (info) => info.getValue(),
                size: 150,
                enableColumnFilter: false
            }),
            columnHelper.accessor((location) => {
                // @ts-ignore
                return L[`locationTypeValue${location.organizationLocationTypeId}`]();
            }, {
                header: L.locationType(),
                cell: (info) => {                    
                    // @ts-ignore
                    return L[`locationTypeValue${info.row.original.organizationLocationTypeId}`]();
                },
                size: 125,
                enableColumnFilter: true,
                filterFn: 'arrIncludesSome',
            }),
            columnHelper.display({
                header: L.address(),
                cell: (info) => {
                    const row = info.row.original;
                    const display = [row.address1, row.city, row.state].filter(Boolean).join(', ');
                    return `${display} ${row.postalCode}`;
                },
                size: 300,
                enableColumnFilter: false
            }),
            columnHelper.accessor('hasScale', {
                header: L.hasWeighScale(),
                cell: (info) => info.getValue() ? L.yes() : L.no(),
                size: 150,
                enableColumnFilter: false
            }),
        ];

        return c;
    }, []);

    return columns
};
