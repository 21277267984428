import axios from 'axios';
import { useBool } from '../../../shared/hooks/use-bool';

export const useLookupUsername = () => {
    const [isLoading, { off, on }] = useBool(false);

    const fetch = async (username: string): Promise<boolean> => {
        const params = {
            username: username,
        }
        on();
        const response = await axios.get(`${API_BASE}/api/users/username?`, {
            params: params,
        });
        off();
        return response.data;
    };

    return {
        isLoading,
        fetch,
    };
};
