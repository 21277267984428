import React from 'react';
import { L } from 'harmony-language';
import { createColumnHelper } from '@tanstack/react-table';
import { Customer } from '../../../types';

export const useCustomerColumns = () => {
    const columnHelper = createColumnHelper<Customer>();

    const columns = React.useMemo(() => {
        const c = [
            columnHelper.accessor('customerName', {
                header: L.name(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
            }),
        ];

        return c;
    }, []);

    return columns
};
