import React from 'react';
import ReactDOMServer from 'react-dom/server';

export const getStopIcon = (sequence) => {
    const svg = (
        <div>
            <svg className='live-map-marker stop-marker' viewBox='-20 -30 40 60' width='32' height='32'
                xmlns='http://www.w3.org/2000/svg'>
                <circle cx='0' cy='0' r='24' fill='#01b6b2' stroke='black'/>
                <text x='0' y='0' dy='8px' fontSize='24px' textAnchor='middle' fontFamily='Arial'
                    fill='white'>{sequence}</text>
            </svg>
        </div>
    );

    return ReactDOMServer.renderToString(svg);
};
