import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { L } from 'harmony-language';

const MeterContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover > .suspicious > div:nth-child(1), &:hover > .mildPositive > div:nth-child(-n+2), &:hover > .highPositive > div:nth-child(-n+3)': {
        backgroundColor: theme.palette.error.main,
    },
}));

const Meter = styled('div')(({ theme }) => ({
    marginLeft: '3px',
    padding: '2px',
    '& > div': {
        border: `1px solid ${theme.palette.grey[900]}`,
        borderRadius: '2px',
        height: '0.6rem',
        width: '0.6rem',
        float: 'left',
        marginRight: '2px'
    },
    '&.suspicious > div:nth-child(1), &.mildPositive > div:nth-child(-n+2), &.highPositive > div:nth-child(-n+3)': {
        backgroundColor: theme.palette.grey[500],
    },
}));

export const salmonellaSeverities = {
    negative: 'negative',
    suspicious: 'suspicious',
    mildpositive: 'mildPositive',
    highpositive: 'highPositive'
};

export const salmonellaRanks = ['negative', 'suspicious', 'mildPositive', 'highPositive'];

export const SalmonellaMeter = (props) => {
    const { severity, disableLabel, ...rest } = props;
    const severityValue = salmonellaSeverities[severity?.toLowerCase()];

    if (!severityValue) {
        return null;
    }

    return (
        <MeterContainer data-testid='salmonella-meter' { ...rest}>
            { !disableLabel && <span>{L.salmonella()}:</span>}
            <Tooltip title={`${L[severityValue]()}`} arrow placement='right'>
                <Meter className={`${severityValue}`}>
                    <div></div>
                    <div></div>
                    <div></div>
                </Meter>
            </Tooltip >
        </MeterContainer>
    );
};

SalmonellaMeter.propTypes = {
    severity: PropTypes.string,
    disableLabel: PropTypes.bool,
    style: PropTypes.object
};
