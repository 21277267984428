import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { getDaysOfWorkingWeek } from '../../../../utils/date-time-utils';
import { L } from 'harmony-language';

export const BulkEntryTableHeaders = (props) => {
    const { weekOf, onCopyLastWeeksProjections, siteProjections, isOriginBased } = props;

    const checkForLastWeekProjections = () => {
        return siteProjections.some(site => site.lastWeeksProjections?.sum());
    };

    return (
        <TableHead>
            <TableRow className='bulk-entry-top-header'>
                <TableCell className='no-right-padding'>
                    <IconButton
                        tabIndex={-1}
                        className='bulk-entry-copy-button'
                        aria-label={L.bulkEntryCopy()}
                        disabled={!checkForLastWeekProjections()}
                        onClick={() => onCopyLastWeeksProjections(null)}
                        size='large'>
                            <ContentCopy />
                        <span className='tooltip'>{L.bulkEntryCopy()}</span>
                    </IconButton>
                </TableCell>
                <TableCell className='no-left-padding'>{isOriginBased ? L.bulkEntryOrigins(siteProjections.length) : L.bulkEntryDestinations(siteProjections.length)}</TableCell>
                <TableCell sx={{ textAlign: 'center'}} className='bulk-entry-group-header' colSpan={7}>{isOriginBased ? L.bulkEntryLoadsPerDayOrigin() : L.bulkEntryLoadsPerDayDestination()}</TableCell>
                <TableCell sx={{ textAlign: 'center'}} className='bulk-entry-group-header' colSpan={2}>{L.totals()}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell className='bulk-entry-header-cell'/>
                <TableCell className='bulk-entry-header-cell'>{isOriginBased ? L.origin() : L.destination()}</TableCell>
                {getDaysOfWorkingWeek(weekOf).map(day => <TableCell className='bulk-entry-header-cell'
                    key={day.label}>{day.label}</TableCell>)}
                <TableCell className='bulk-entry-header-cell'>{L.current()}</TableCell>
                <TableCell className='bulk-entry-header-cell'>{L.running()}</TableCell>
            </TableRow>
        </TableHead>
    );
};

BulkEntryTableHeaders.propTypes = {
    weekOf: PropTypes.string,
    onCopyLastWeeksProjections: PropTypes.func,
    siteProjections: PropTypes.array,
    isOriginBased: PropTypes.bool,
};
