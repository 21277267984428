import React from 'react';
import { L } from 'harmony-language';
import { Button, TextField } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { hereMapGeocode } from '../../../api/here-utils';
import { Point } from '../../../types';

interface AvoidanceZoneAddressProps {
    centerMap?: (latitude: number, longitude: number, zoomLevel: number) => void;
    setHelperMarker: (p: Point) => void;
}

export const AvoidanceZoneAddress: React.FC<AvoidanceZoneAddressProps> = (props) => {
    const { centerMap, setHelperMarker } = props;
    const [address1, setAddress1] = React.useState<string | null>(null);
    const [city, setCity] = React.useState<string | null>(null);
    const [state, setState] = React.useState<string | null>(null);
    const [postalCode, setPostalCode] = React.useState<string | null>(null);

    const q = React.useMemo(() => {
        return [address1, city, state, postalCode].filter(Boolean).join(' ');
    }, [address1, city, state, postalCode]);

    return (
        <div className='avoidance-zone-creation-form-fields'>
            <AvoidanceZoneAddressItemInput
                label={L.addressLine1()}
                value={address1}
                setValue={setAddress1}
            />
            <AvoidanceZoneAddressItemInput
                label={L.city()}
                value={city}
                setValue={setCity}
            />
            <AvoidanceZoneAddressItemInput
                label={L.state()}
                value={state}
                setValue={setState}
            />
            <AvoidanceZoneAddressItemInput
                label={L.postalCode()}
                value={postalCode}
                setValue={setPostalCode}
            />
            <div>
                <Button
                    disabled={!address1 || !city || !state || !postalCode}
                    size='small'
                    onClick={async () => {
                        try {
                            const geocode = await hereMapGeocode({ q });
                            if (geocode?.position) {
                                centerMap && centerMap(geocode.position.lat, geocode.position.lng, 14);
                                setHelperMarker({ latitude: geocode.position.lat, longitude: geocode.position.lng });
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }}
                >
                    <MyLocationIcon />
                </Button>
            </div>
        </div>
    );
};

interface AvoidanceZoneAddressItemInputProps {
    label: string;
    value: string | null;
    setValue: (a: string | null) => void;
}

const AvoidanceZoneAddressItemInput: React.FC<AvoidanceZoneAddressItemInputProps> = (props) => {
    const { label, value, setValue } = props;

    return (
        <TextField
            variant='standard'
            label={label}
            id={label}
            value={value || ''}
            onChange={(e) => {
                setValue(e.target.value);
            }}
            inputProps={{ maxLength: 200 }}
        />
    );
};
