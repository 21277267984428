import { QueryKeys } from '../config';
import { useDraftsKey } from '../config-hooks';
import { useLoadsWithDateFilter } from './use-loads-with-date-filter';

export const useDrafts = () => {
    const draftsKey = useDraftsKey();
    const query = useLoadsWithDateFilter(QueryKeys.drafts, draftsKey);

    return { drafts: query?.data?.data, ...query };
};
