import React, { useMemo } from 'react';
import { CargoType, Contact, Customer, KeysWithValsOfType, NotificationConfig, OrganizationLocation } from '../../../../types';
import { NotificationEventTypes  } from 'harmony-constants';
import { NotificationMultiInput } from './notification-multi-input';
import { NotificationTypeDropdown } from './notification-type-dropdown';
import { L } from 'harmony-language';
import { Button } from '@mui/material';
import { toSiteDisplayName } from '../../../../utils/data-mapping';
import { SnackbarTypes, useSnackbar } from '../../../notifications/use-snackbar';

type AddEditNotificationProps = {
    existingNotificationConfig: NotificationConfig | null;
    organizationId: number;
    contacts: Contact[];
    organizationLocations: OrganizationLocation[];
    customers: Customer[];
    cargoTypes: CargoType[];
    onSubmit: (notificationConfig: NotificationConfig) => void;
}

export const AddEditNotification: React.FC<AddEditNotificationProps> = (props) => {
    const { existingNotificationConfig, organizationId, contacts, organizationLocations, customers, cargoTypes, onSubmit } = props;
    const [notificationConfig, setNotificationConfig] = React.useState<NotificationConfig>(existingNotificationConfig ? existingNotificationConfig : {
        organizationId: organizationId,
        notificationEventTypeId: 5,
        contactIds: [],
        stopCargoTypeIds: null,
        trailerCargoTypeIds: null,
        destinationSiteIds: null,
        originSiteIds: null,
        customerIds: null,
    });
    const snackbar = useSnackbar();

    const filteredContacts = React.useMemo(() => {
        // disabled contacts, unless they are on this notification, then they need to be able to remove them
        return contacts.filter(x => notificationConfig.contactIds.includes(x.id) || x.enabled);
    }, [contacts]);

    const isPropertyValidForNotificationEventType = (propertyName: KeysWithValsOfType<Omit<NotificationConfig, 'contactIds'>, number[] | null>) => {
        const notificationEventType = Object.values(NotificationEventTypes).find(x => x.id === notificationConfig.notificationEventTypeId);
        const includesProperty = notificationEventType?.removedOptions?.includes(propertyName);

        return !includesProperty;
    }

    const validProperties = useMemo(() => ({
        originSiteIds: isPropertyValidForNotificationEventType('originSiteIds'),
        destinationSiteIds: isPropertyValidForNotificationEventType('destinationSiteIds'),
        customerIds: isPropertyValidForNotificationEventType('customerIds'),
        stopCargoTypeIds: isPropertyValidForNotificationEventType('stopCargoTypeIds'),
        trailerCargoTypeIds: isPropertyValidForNotificationEventType('trailerCargoTypeIds'),
    }), [notificationConfig.notificationEventTypeId]);

    const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (!notificationConfig.contactIds.length) {
            snackbar(L.notificationConfigurationRequiredContacts(), SnackbarTypes.error);
        } else {
            onSubmit(notificationConfig);
        }
    };

    return (
        <form id='edit-create-notification-config-form' name='edit-create-notification-config-form' style={{ width: '30vw '}} onSubmit={submitForm}>
            <NotificationTypeDropdown
                notificationConfig={notificationConfig}
                eventList={Object.values(NotificationEventTypes).filter(x => !x.system && !x.nyi)}
                onNotificationChange={setNotificationConfig} />
            <NotificationMultiInput
                notificationConfig={notificationConfig}
                onNotificationChange={setNotificationConfig}
                items={filteredContacts.map(x => ({ id: x.id, value: x.name }))}
                identifierKey={'contactIds'}
                translation={`${L.contacts()} *`} />
            {validProperties.originSiteIds ? <NotificationMultiInput
                notificationConfig={notificationConfig}
                onNotificationChange={setNotificationConfig}
                items={organizationLocations.map(x => ({ id: x.id, value: toSiteDisplayName(x.name, x.description) }))}
                identifierKey={'originSiteIds'}
                translation={validProperties.originSiteIds && !validProperties.destinationSiteIds ? L.locations() : L.originLocations()} /> : null
            }
            {validProperties.destinationSiteIds ? <NotificationMultiInput
                notificationConfig={notificationConfig}
                onNotificationChange={setNotificationConfig}
                items={organizationLocations.map(x => ({ id: x.id, value: toSiteDisplayName(x.name, x.description) }))}
                identifierKey={'destinationSiteIds'}
                translation={L.destinationLocations()} /> : null
            }
            {validProperties.customerIds ? <NotificationMultiInput
                notificationConfig={notificationConfig}
                onNotificationChange={setNotificationConfig}
                items={customers.map(x => ({ id: x.id, value: x.customerName }))}
                identifierKey={'customerIds'}
                translation={L.customers()} /> : null
            }
            {validProperties.stopCargoTypeIds ? <NotificationMultiInput
                notificationConfig={notificationConfig}
                onNotificationChange={setNotificationConfig}
                items={cargoTypes.map(x => ({ id: x.id, value: x.label }))}
                identifierKey={'stopCargoTypeIds'}
                translation={L.stopCargoTypes()} /> : null
            }
            {validProperties.trailerCargoTypeIds ? <NotificationMultiInput
                notificationConfig={notificationConfig}
                onNotificationChange={setNotificationConfig}
                items={cargoTypes.map(x => ({ id: x.id, value: x.label }))}
                identifierKey={'trailerCargoTypeIds'}
                translation={L.trailerCargoTypes()} /> : null
            }
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '1rem'
            }}>
                <Button type='submit'
                    title={L.save()}
                    variant='contained'
                    color='primary'>{notificationConfig.id ? L.updateNotification() : L.createNotification()}
                </Button>
            </div>
        </form>
    );
}
