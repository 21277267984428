import { L } from 'harmony-language';
import React from 'react';
import { OrgQueryKeys } from '../../api/config';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { type Checklist } from '../../types';
import { Content } from '../shared/containers/content';
import { ModalDialog } from '../shared/containers/modal-dialog';
import { ResourceTable } from '../shared/resource-table/resource-table';
import { ChecklistEntry } from './checklist-entry';
import { useMaintenanceColumns } from './use-maintenance-columns';

export const Maintenance = () => {
    const { data: latestChecklists, isLoading: isLatestChecklistsLoading } =
        useOrganizationQuery<Checklist[]>(OrgQueryKeys.checklists);

    const {
        columns,
        isLoading: isLoadingColumns,
        resource,
        setResource
    } = useMaintenanceColumns();

    return (
        <>
            <ModalDialog
                open={Boolean(resource)}
                onClose={() => setResource(null)}
                title={`${L.equipmentChecklist()} - ${
                    resource?.userDisplayName
                }`}
            >
                {resource && (
                    <ChecklistEntry
                        resourceType={resource.type}
                        resource={resource}
                        onSubmit={() => setResource(null)}
                        isHistoricChecklist={false}
                    />
                )}
            </ModalDialog>
            <Content>
                <ResourceTable
                    id={'checklist'}
                    title={L.equipmentChecklists()}
                    isLoading={isLatestChecklistsLoading || isLoadingColumns}
                    columns={columns}
                    data={latestChecklists || []}
                    
                />
            </Content>
        </>
    );
};
