//COMPANY PERMISSIONS
import { capitalizeFirst } from '../utils/string-utils';

export const EDIT_COMPANY = 'editCompany';
export const VIEW_ALL_COMPANIES = 'viewAllCompanies';
export const VIEW_COMPANY = 'viewCompany';
export const CREATE_COMPANY = 'createCompany';
export const ACCESS_ADMINISTRATION = 'accessAdministration';

// TRACTOR PERMISSIONS
export const CREATE_TRACTORS_PERMISSION = 'createTractors';
export const EDIT_TRACTORS_PERMISSION = 'editTractors';
export const VIEW_TRACTORS_PERMISSION = 'viewTractors';

// TRAILER PERMISSIONS
export const CREATE_TRAILERS_PERMISSION = 'createTrailers';
export const EDIT_TRAILERS_PERMISSION = 'editTrailers';
export const VIEW_TRAILERS_PERMISSION = 'viewTrailers';

// LOADING TEAM PERMISSIONS
export const VIEW_LOADING_TEAMS = 'viewLoadingTeams';
export const EDIT_LOADING_TEAMS = 'editLoadingTeams';
export const CREATE_LOADING_TEAMS = 'createLoadingTeams';

//DRIVER PERMISSIONS
export const ASSIGN_DRIVERS = 'assignDrivers';
export const VIEW_DRIVERS = 'viewDrivers';

//LOCATION PERMISSIONS
export const VIEW_LOCATIONS = 'viewLocations';
export const EDIT_LOCATIONS = 'editLocations';
export const CREATE_LOCATIONS = 'createLocations';

//ORDER PERMISSIONS
export const CREATE_ORDER = 'createOrder';
export const EDIT_ORDER = 'editOrder';
export const COMPLETE_ORDER_PERMISSION = 'completeOrder';
export const VIEW_OWNING_ORDERS_PERMISSION = 'viewOwningOrders';
export const DELETE_ORDER = 'deleteOrder';

//USER PERMISSIONS
export const VIEW_USERS = 'viewUsers';
export const EDIT_USER = 'editUser';
export const CREATE_USER = 'createUser';

//LIVE MAP PERMISSIONS
export const VIEW_LIVE_MAP = 'viewLiveMap';

//AVOIDANCE ZONES
export const VIEW_AVOIDANCE_ZONES = 'viewAvoidanceZones';
export const EDIT_AVOIDANCE_ZONES = 'editAvoidanceZones';
export const CREATE_AVOIDANCE_ZONES = 'createAvoidanceZones';

// Server-to-Server API Access administration permissions
export const VIEW_SERVICE_USER_PERMISSION = 'viewServiceUser';
export const CREATE_SERVICE_USER_PERMISSION = 'createServiceUser';
export const EDIT_SERVICE_USER_PERMISSION = 'editServiceUser';
export const DELETE_SERVICE_USER_PERMISSION = 'deleteServiceUser';

//MAINTENANCE PERMISSIONS
export const VIEW_CHECKLIST_PERMISSION = 'viewChecklist';
export const RESOLVE_CHECKLIST_PERMISSION = 'resolveChecklistItem';

//SITE ISSUE PERMISSIONS
export const VIEW_SITE_ISSUE_PERMISSION = 'viewSiteIssues';
export const RESOLVE_SITE_ISSUE_PERMISSION = 'resolveSiteIssues';
export const CREATE_SITE_ISSUE_PERMISSION = 'createSiteIssues';

//PRODUCTION CARD PERMISSIONS
// export const VIEW_PRODUCTION_CARDS_PERMISSION = 'viewProductionCards';
export const CREATE_PRODUCTION_CARDS_PERMISSION = 'createProductionCards';
export const DELETE_PRODUCTION_CARDS_PERMISSION = 'deleteProductionCards';

//CUSTOMER PERMISSIONS
export const CREATE_CUSTOMERS_PERMISSION = 'createCustomers';
export const EDIT_CUSTOMERS_PERMISSION = 'editCustomers';
export const VIEW_CUSTOMERS_PERMISSION = 'viewCustomers';

//CONTACT PERMISSIONS
export const VIEW_CONTACTS = 'viewContacts';
export const CREATE_CONTACTS_PERMISSION = 'createContacts';
export const EDIT_CONTACTS_PERMISSION = 'editContacts';
export const DELETE_CONTACTS_PERMISSION = 'deleteContacts';

// NOTIFICATION CONFGS
export const VIEW_NOTIFCATION_CONFIGS_PERMISSION = 'viewNotificationConfigs';
export const CREATE_NOTIFCATION_CONFIGS_PERMISSION = 'createNotificationConfigs';
export const EDIT_NOTIFCATION_CONFIGS_PERMISSION = 'editNotificationConfigs';
export const DELETE_NOTIFCATION_CONFIGS_PERMISSION = 'deleteNotificationConfigs';

const mapToPermissionSet = (view, edit, create)=> {
    if(!edit && !create){
        edit = view;
        create = view;
    }
    return {
        view: `view${view}`,
        edit: `edit${edit}`,
        create: `create${create}`,
    };
};

const mapToServiceUser = (view) => {
    return {
        view: `view${view}`,
        edit: `edit${view}`,
        create: `create${view}`,
        'delete': `delete${view}`
    }
};

export const resourcePermission = (resourceType) => {
    switch(resourceType){
    case 'serviceAccount':
        return mapToServiceUser('ServiceUser');
    case 'user':
        return mapToPermissionSet('Users', 'User', 'User');
    case 'tractor':
    case 'trailer':
    case 'loadingTeam':
    case 'location':
    case 'customer':
    case 'contact':
    default:
        return mapToPermissionSet(`${capitalizeFirst(resourceType)}s`);
    }
};
