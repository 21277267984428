import React from 'react';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface CascadeOffsetProps {
    offset: number;
    changeOffset: (value: number) => void,
    className?: string;
}

export const CascadeOffset: React.FC<CascadeOffsetProps> = ({offset, changeOffset}) => {
    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <IconButton size={'small'} onClick={() => changeOffset(offset + 15)}>
                <AddIcon fontSize="small"/>
            </IconButton>
            <span>{offset}</span>
            <IconButton size={'small'} onClick={() => changeOffset(offset - 15)}>
                <RemoveIcon fontSize="small"/>
            </IconButton>
        </div>
    )
}
