import React from 'react';
import { styled } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
// @ts-ignore
import agisticsSymbol from '../../resources/agistics-logo-white.png';
import Map from '@mui/icons-material/Map';
import People from '@mui/icons-material/People';
import Tooltip from '@mui/material/Tooltip';
import { L } from 'harmony-language';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import Create from '@mui/icons-material/Create';
import Event from '@mui/icons-material/Event';
import ListAlt from '@mui/icons-material/ListAlt';
import Build from '@mui/icons-material/Build';
import Warning from '@mui/icons-material/Warning';
import { History } from '@mui/icons-material';
import { useAtLeastOneReport, useCarrier, useFeatures, usePermissions } from '../user/selectors/use-permissions';
import Box from '@mui/material/Box'
import { Features, PermissionType } from 'harmony-constants';
import { UserInformation } from '../user/user-information';

interface StyledLinkProps {
    currentPath: string;
    to: string;
}

const StyledLink = styled(Link, {
    shouldForwardProp: (prop) => prop !== 'currentPath',
})<StyledLinkProps>(({ currentPath, to }) => ({
    textDecoration: 'none',
    transition: '0.3s',
    color: currentPath.includes(to) ? '#FFFFFF' : '#676767',
    '&:hover': {
        color: '#FFFFFF',
    },
}));

export const Navigation = () => {
    const canAccessAdministration = usePermissions(PermissionType.AccessAdministration);
    const canViewLiveMap = useFeatures(Features.DeliveryTracking) && usePermissions(PermissionType.ViewLiveMap);
    const canViewDrafts = useFeatures(Features.Drafts) && usePermissions(PermissionType.EditOrder);
    const canViewOrders = usePermissions(PermissionType.ViewOwningOrders);
    const canViewReports = useFeatures(Features.Reports);
    const canViewMaintenance = useFeatures(Features.Checklists) && usePermissions(PermissionType.ViewChecklist);
    const canViewSiteIssues = useFeatures(Features.SiteIssues) && usePermissions(PermissionType.ViewSiteIssues);
    const canViewProductionPlanning = useFeatures(Features.ProductionPlanning) && usePermissions(PermissionType.ViewProductionCards);
    const location = useLocation();
    const currentPath = location.pathname;
    const isCarrier = useCarrier();
    const atLeastOneReport = useAtLeastOneReport();

    const renderLink = (url: string, label: string, icon: React.ReactNode, canRender = true) => {
        if (canRender) {
            return (
                <ListItem sx={{ display: 'block', textAlign: 'center', listStyle: 'none' }}>
                    <Tooltip key={url} title={label} aria-label={label} placement='right'>
                        <StyledLink currentPath={currentPath} to={url}>
                            {icon}
                        </StyledLink>
                    </Tooltip>
                </ListItem>
            );
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            background: (theme) => theme.palette.secondary.dark,
            textAlign: 'center',
            justifyContent: 'space-between',
            paddingBottom: '1rem',
        }}>
            <List disablePadding component='nav'>
                {renderLink('/', '', <img style={{ paddingTop: '0.5rem', height: '2rem', width: '2rem' }} src={agisticsSymbol} alt=''/>)}
                <hr style={{ borderColor: '#676767', width: '60%' }} />
                {renderLink('/production-planning', L.productionPlanning(), <ListAlt />, canViewProductionPlanning && !isCarrier)}
                {renderLink('/drafts', L.drafts(), <Create />, canViewDrafts && !isCarrier)}
                {renderLink('/loads', L.loads(), <Event />, canViewOrders)}
                {renderLink('/reports', L.viewReports(), <History />, canViewReports && atLeastOneReport)}
                {renderLink('/live-map', L.liveMap(), <Map />, canViewLiveMap)}
                {renderLink('/maintenance', L.maintenance(), <Build />, canViewMaintenance && !isCarrier)}
                {renderLink('/site-issues', L.siteIssues(), <Warning />, canViewSiteIssues && !isCarrier)}
                {renderLink('/manage-company', L.administration(), <People />, canAccessAdministration)}
            </List>
            <Grid container direction='column'>
                <UserInformation />
            </Grid>
        </Box>
    );
};
