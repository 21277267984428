import React from 'react';
import { ContentHeader } from '../shared/containers/content-header';
import { Tab, Tabs } from '@mui/material';
import { DriverEfficiencyReport } from './driver-efficiency/driver-efficiency-report';
import { DecisionReport } from './decision/decision-report';
import { L } from 'harmony-language';
import { PermissionType } from 'harmony-constants';
import { usePermissions } from '../user/selectors/use-permissions';
import { SequencingReport } from './sequencing/sequencing-report';
import { useCompanyId } from '../../app/agistics-context';
import { SplitStopReport } from './split-stop/split-stop-report';
import { AgisticsTabPanel } from '../shared/agistics-tab-panel';
import { OnTimeReport } from './on-time/on-time-report';

export const Reports: React.FC = () => {
    const isAdmin = usePermissions(PermissionType.ViewAllCompanies);
    const companyId = useCompanyId();
    const viewDriverEfficiency = usePermissions(PermissionType.ViewReportDriverEfficiency);
    const viewDecision = usePermissions(PermissionType.ViewReportDecision);
    const viewSequencing = usePermissions(PermissionType.ViewReportSequencing);
    const viewSplitStop = usePermissions(PermissionType.ViewReportSplitStop);
    const viewOnTime = usePermissions(PermissionType.ViewReportOnTime);

    const permissionIndex = React.useMemo(() => [viewDriverEfficiency, viewDecision, viewSequencing, viewSplitStop, viewOnTime].findIndex(x => x), []);
    const [tabValue, setTabValue] = React.useState(permissionIndex);

    const handleChange = (_: React.ChangeEvent<object>, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <>
            <ContentHeader>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                >
                    <Tab label={L.driverEfficiencyReport()} style={{ display: viewDriverEfficiency ? 'inherit' : 'none' }} />
                    <Tab label={L.decisionReport()} style={{ display: viewDecision ? 'inherit' : 'none' }} />
                    <Tab label={L.sequencingReport()} style={{ display: viewSequencing ? 'inherit' : 'none' }} />
                    <Tab label={L.splitStopReport()} style={{ display: viewSplitStop ? 'inherit' : 'none' }} />
                    <Tab label={L.onTimeReport()} style={{ display: viewOnTime ? 'inherit' : 'none' }} />
                </Tabs>
            </ContentHeader>
            <AgisticsTabPanel
                value={tabValue}
                index={0}
                permission={viewDriverEfficiency}
                p={3}
            >
                <DriverEfficiencyReport isAdmin={isAdmin} companyId={companyId} />
            </AgisticsTabPanel>
            <AgisticsTabPanel
                value={tabValue}
                index={1}
                permission={viewDecision}
                p={3}
            >
                <DecisionReport isAdmin={isAdmin} companyId={companyId} />
            </AgisticsTabPanel>
            <AgisticsTabPanel
                value={tabValue}
                index={2}
                permission={viewSequencing}
                p={3}
            >
                <SequencingReport isAdmin={isAdmin} companyId={companyId} />
            </AgisticsTabPanel>
            <AgisticsTabPanel
                value={tabValue}
                index={3}
                permission={viewSplitStop}
                p={3}
            >
                <SplitStopReport isAdmin={isAdmin} companyId={companyId} />
            </AgisticsTabPanel>
            <AgisticsTabPanel
                value={tabValue}
                index={4}
                permission={viewOnTime}
                p={3}
            >
                <OnTimeReport isAdmin={isAdmin} companyId={companyId} />
            </AgisticsTabPanel>
        </>
    );
};
