import React from 'react';
import { L } from 'harmony-language';
import { Grid } from '@mui/material';
import { AgisticsMultiSelect } from '../../shared/multi-select/agistics-multi-select';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrganizationLocation, Trailer } from '../../../types';
import { OrgQueryKeys } from '../../../api/config';
import { toSiteDisplayName } from '../../../utils/data-mapping';

interface SequencingFiltersProps {
    isAdmin: boolean;
    trailerIds: number[];
    setTrailerIds: React.Dispatch<React.SetStateAction<number[]>>;
    originSiteIds: number[];
    setOriginSiteIds: React.Dispatch<React.SetStateAction<number[]>>;
    destinationSiteIds: number[];
    setDestinationSiteIds: React.Dispatch<React.SetStateAction<number[]>>;
}

export const SequencingFilters: React.FC<SequencingFiltersProps> = (props) => {
    const { isAdmin, trailerIds, setTrailerIds, originSiteIds, setOriginSiteIds, destinationSiteIds, setDestinationSiteIds } = props;
    const { data: trailers = [] } = useOrganizationQuery<Trailer[]>(OrgQueryKeys.trailers, { enabled: !isAdmin });
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations, { enabled: !isAdmin });

    const trailersMultiItems = React.useMemo(() => {
        return trailers.map(x => {
            return {
                id: x.id,
                value: x.userDisplayName,
            }
        });
    }, [trailers]);

    const orgLocationsMultiItems = React.useMemo(() => {
        return organizationLocations.map(x => {
            return {
                id: x.id,
                value: toSiteDisplayName(x.name, x.description),
            }
        });
    }, [organizationLocations]);

    return (
        !isAdmin && <Grid container sx={{ width: '20rem' }}>
            <Grid item xs={12}>
                <AgisticsMultiSelect
                    selectedIds={trailerIds}
                    setSelectedIds={setTrailerIds}
                    items={trailersMultiItems}
                    label={L.trailers()}
                />
            </Grid>
            <Grid item xs={12}>
                <AgisticsMultiSelect
                    selectedIds={originSiteIds}
                    setSelectedIds={setOriginSiteIds}
                    items={orgLocationsMultiItems}
                    label={L.originLocations()}
                />
            </Grid>
            <Grid item xs={12}>
                <AgisticsMultiSelect
                    selectedIds={destinationSiteIds}
                    setSelectedIds={setDestinationSiteIds}
                    items={orgLocationsMultiItems}
                    label={L.destinationLocations()}
                />
            </Grid>
        </Grid>
    );
};
