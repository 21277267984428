import { useQuery } from '@tanstack/react-query';
import { User } from '../../types';
import { QueryKeys } from '../config';

export const useUser = (enabled: boolean = true) => {
    const userQuery = useQuery<User>({
        queryKey: [QueryKeys.self],
        staleTime: Infinity,
        cacheTime: Infinity,
        enabled: enabled,
    });

    // trying to recreate things how redux used them.
    return {
        user: userQuery.data as User,
        isLoading: userQuery.isLoading,
        organizationId: userQuery.data?.organizationId || 0,
    }
}
