import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { L } from 'harmony-language';

export const BulkPreviewTitleBar = (props) => {
    const { generateProjections } = props;

    return (
        <div className='bulk-title-bar justify-between'>
            <h4>{L.bulkPreviewInfo()}</h4>
            <div className='bulk-title-bar-action'>
                <Button variant='contained' color='primary' onClick={generateProjections}>{L.createLoads()}</Button>
            </div>
        </div>
    );
};

BulkPreviewTitleBar.propTypes = {
    bulkConfiguration: PropTypes.object,
    generateProjections: PropTypes.func,
    totalProjections: PropTypes.number
};
