import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { ResourceType } from "harmony-constants";
import { L } from "harmony-language";
import React, { FormEvent } from "react";
import { type ChecklistTemplate, type ChecklistTemplateReading } from "../../../types";
import { ChecklistTemplateItemsEditor } from "./checklist-template-items-editor";
import { AllowRequireOff, EditCreateChecklistTemplateProps, Footer, GridContainer, newChecklistTemplate, ReadingsKey, TextInput, TripletToggleGroup } from "./edit-create-checklist-template-shared";

export const EditCreateTractorChecklistTemplate = (props: EditCreateChecklistTemplateProps) => {
    const { existingChecklistTemplate, onSubmit } = props;
    const [checklistTemplate, setChecklistTemplate] = React.useState<ChecklistTemplate>(existingChecklistTemplate || newChecklistTemplate(ResourceType.Tractor));
    const [promptEngineHours, setPromptEngineHours] = React.useState<AllowRequireOff>(
        !checklistTemplate.template[0].readings?.length ? 'off'
        : checklistTemplate.template[0].readings[0].required ? 'require' : 'allow'
    );

    function handleReadingsChange(renderSafeEngineVal: AllowRequireOff) {
        const readingsValue: ChecklistTemplateReading[] = [];

        renderSafeEngineVal !== 'off' && readingsValue.push({
            key: ReadingsKey.TRUCK_ENGINE,
            type: 'number',
            required: renderSafeEngineVal === 'require'
        });

        setChecklistTemplate(prev => ({...prev, template: [{
            default: prev.template[0].default,
            ...(readingsValue.length ? {readings: readingsValue} : {})
        }]}));
    }

    async function submitForm(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        e.stopPropagation();
 
        onSubmit(checklistTemplate);
    }

    return (
        <form onSubmit={submitForm} style={{width: '60em'}}>
            <GridContainer>
                <div>
                    <TextInput
                        label={L.name()}
                        value={checklistTemplate.name}
                        onChange={(e) => setChecklistTemplate(prev => ({ ...prev, name: e.target.value }))}
                    />
                    <TextInput
                        label={L.timeToExpirationMinutesLabel()}
                        value={checklistTemplate.timeToExpirationMinutes}
                        onChange={(e) => setChecklistTemplate(prev => ({ ...prev, timeToExpirationMinutes: +e.target.value }))}
                    />
                    <TripletToggleGroup
                        label={L.promptForEngineHours()}
                        value={promptEngineHours}
                        onChange={(e, val) => {
                            setPromptEngineHours(val);
                            handleReadingsChange(val);
                        }}
                    />
                    <FormControlLabel
                        label={L.default()}
                        control={
                            <Checkbox
                                checked={checklistTemplate.default}
                                onChange={() => setChecklistTemplate(prev => ({ ...prev, default: !prev.default }))}
                            />
                        }
                    />
                    <FormControlLabel
                        label={L.requireOnUserSwitch()}
                        control={
                            <Checkbox
                                checked={checklistTemplate.requireOnUserSwitch}
                                onChange={() => setChecklistTemplate(prev => ({ ...prev, requireOnUserSwitch: !prev.requireOnUserSwitch }))}
                            />
                        }
                    />
                </div>
                <ChecklistTemplateItemsEditor
                    templateItemsArray={checklistTemplate.template[0].default}
                    resourceType={checklistTemplate.resourceType}
                    updateTemplate={newTemplateItems => setChecklistTemplate(prev => ({
                        ...prev,
                        template: [{
                            default: newTemplateItems,
                            ...(prev.template[0].readings?.length ? {readings: prev.template[0].readings} : {})
                        }]
                    }))}
                />
                <Footer>
                    <Button
                        title={L.save()}
                        type='submit'
                        disabled={false}
                        variant='contained'
                        color='primary'
                        sx={{width: '18em'}}
                    >
                        {existingChecklistTemplate ? L.updateChecklistTemplate() : L.createChecklistTemplate()}
                    </Button>
                </Footer>
            </GridContainer>
        </form>
    );
};