import { Load, LoadCache } from '../../types';
import { OrgQueryKeys } from '../../api/config';
import { travelTimesCacheKey } from '../../utils/data-mapping-utils';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { roundLegTimeSeconds } from '../../utils/rounding-utils';
import React, { useMemo } from 'react';
import { loadToOrganizationLocationIdPairs, loadsToOrganizationLocationIdPairs } from '../../utils/data-mapping';
import { useAddTravelTimes } from '../../api/mutations/add/use-add-travel-times'
import { useUser } from '../../api/queries/use-user';
import { useDraftsKey, useLoadsKey } from '../../api/config-hooks';

export type TravelTimesResponse = { timeSeconds: number, distanceMeters: number, originOrganizationLocationId: number, destinationOrganizationLocationId: number }
export type TravelTimesCache = Record<string, Pick<TravelTimesResponse, 'timeSeconds' | 'distanceMeters'>>

export const travelTimesRecordBuilder = (acc: TravelTimesCache, distance: TravelTimesResponse) => {
    const key = `${distance.originOrganizationLocationId},${distance.destinationOrganizationLocationId}`;

    acc[key] = {
        timeSeconds: roundLegTimeSeconds(distance.timeSeconds),
        distanceMeters: distance.distanceMeters
    };
    return acc;
}

export const findUnknownPairsInCache = (cache: TravelTimesCache, pairs: number[][]) => {
    const unknowPairs: number[][] = [];
    pairs.forEach(pair => {
        const key = travelTimesCacheKey(pair);
        if (!cache[key]) {
            unknowPairs.push(pair);
        }
    });
    return unknowPairs;
}

const fetchTravelTimes = async (pairs: number[][], key: string) => {
    const pairsToQuery = pairs.filter(x => x[0] !== x[1]);

    const pointlessPairs = pairs
        .filter(x => x[0] === x[1])
        .reduce((acc, pair) => {
            const key = travelTimesCacheKey(pair);
            acc[key] = {
                timeSeconds: 0,
                distanceMeters: 0
            };
            return acc;
        }, {} as TravelTimesCache);

    if (pairsToQuery.length < 1) {
        return pointlessPairs;
    }

    // Using axios.create() so that default interceptors are not used
    const { data } = await axios.create().post(`${API_BASE}/api${key}`, pairsToQuery);

    return (data as TravelTimesResponse[]).reduce((acc, distance) => {
        return travelTimesRecordBuilder(acc, distance);
    }, pointlessPairs);
};

// api never returns data for pairs that are the same ie [52,52], need to handle client side
// 2 cases
//      1. on location change dropdown
//      2. on page change (drafts -> loads)
// returns pairs that are not pointless and unknown
export const updatePointlessPairs = (unknowPairs: number[][], travelTimesCache: TravelTimesCache, queryClient: QueryClient, queryKey: string) => {
    const pointlessPairs = unknowPairs.filter(x => x[0] === x[1]);
    const filteredUnknowPairs = unknowPairs.filter(x => !pointlessPairs.includes(x));

    if (pointlessPairs.length) {
        const newGeneratedItems: TravelTimesCache = pointlessPairs.reduce((acc, cur) => {
            const key = travelTimesCacheKey(cur);
            const existingItem = travelTimesCache[key];
            if (existingItem) {
                return acc;
            } else {
                const item = {
                    ...acc,
                    [key]: {
                        timeSeconds: 0,
                        distanceMeters: 0,
                    }
                }
                return item;
            }
        }, {});

        const newCache = {...travelTimesCache, ...newGeneratedItems};
        queryClient.setQueryData([queryKey], newCache);
    }

    return filteredUnknowPairs;
}

export const useTravelTimesCache = () => {
    const { organizationId } = useUser();
    const queryClient = useQueryClient();

    return React.useMemo(() => {
        const queryKey = OrgQueryKeys.resolve(organizationId, OrgQueryKeys.locationTravelTimes)
        const caches = queryClient.getQueriesData<TravelTimesCache>([queryKey]);
        return caches.reduce<TravelTimesCache>((acc, curr) => {
            return {
                ...acc,
                ...curr[1]
            }
        }, {})
    }, []);
}

export const useTravelTimesQuery = (isDependentFetching: boolean) => {
    const { organizationId } = useUser();
    const queryKey = OrgQueryKeys.resolve(organizationId, OrgQueryKeys.locationTravelTimes);
    const queryClient = useQueryClient();
    const { mutate: addTravelTimes } = useAddTravelTimes(organizationId);
    const draftsKey = useDraftsKey();
    const loadsKey = useLoadsKey();

    const draftsCache = queryClient.getQueryData<LoadCache>(draftsKey)?.data || [];
    const loadsCache = queryClient.getQueryData<LoadCache>(loadsKey)?.data || [];
    const combinedCache = draftsCache.concat(loadsCache);

    const cachedLoadPairs = useMemo(() => {
        if (!isDependentFetching) {
            const p = loadsToOrganizationLocationIdPairs(combinedCache);
            return p;
        }
        return [];
    }, [isDependentFetching]);

    const { data, isInitialLoading } = useQuery([queryKey], () => {
        return fetchTravelTimes(cachedLoadPairs, queryKey);
    }, {
        enabled: !isDependentFetching,
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: 3,
        refetchOnWindowFocus: false,
        retryDelay: attempt => 2 ** attempt * 2000 // exponential backoff (2, 4, 8 secs)
    });

    React.useEffect(() => {
        if (!isInitialLoading && !isDependentFetching && data) {
            const unknowPairs = findUnknownPairsInCache(data, cachedLoadPairs);
            const filteredUnknowPairs = updatePointlessPairs(unknowPairs, data, queryClient, queryKey);

            if (filteredUnknowPairs.length) {
                addTravelTimes(unknowPairs);
            }
        }
    }, [cachedLoadPairs]);
}

export const useTravelTimesLoad = (load: Load) => {
    const [travelTime, setTravelTime] = React.useState(0);
    const [travelDistance, setTravelDistance] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(true);
    const { organizationId } = useUser();
    const queryKey = OrgQueryKeys.resolve(organizationId, OrgQueryKeys.locationTravelTimes);
    const queryClient = useQueryClient();
    const travelTimesCache = queryClient.getQueryData<TravelTimesCache>(([queryKey]));

    const pairs = useMemo(() => {
        const p = loadToOrganizationLocationIdPairs(load);
        return p;
    }, [load]);

    const calcAndSetTime = (keys: string[]) => {
        let time = 0;

        const calculateTime = () => {
            const time = keys.reduce((acc, key) => {
                const timeSeconds = travelTimesCache?.[key]?.timeSeconds;
                if (timeSeconds) {
                    return acc + timeSeconds / 60;
                }
                return acc;
            }, 0);
            return time;
        }

        // most of this is needed to keep isLoading state
        if (travelTimesCache) {
            const hasAllKeys = keys.every(key => Object.keys(travelTimesCache).includes(key));
            if (hasAllKeys) {
                time = calculateTime();
                setIsLoading(false);
            } else {
                // mutation of cache is happening somewhere
                time = 0;
                setIsLoading(true);
            }
        }

        setTravelTime(time);
    }

    const calcAndSetDistance = (keys: string[]) => {
        const distance = keys.reduce((acc, key) => {
            const distanceMeters = travelTimesCache?.[key]?.distanceMeters;
            if (distanceMeters) {
                return acc + distanceMeters;
            }
            return acc;
        }, 0);
        setTravelDistance(distance);
    }

    React.useEffect(() => {
        const keys = pairs.map(travelTimesCacheKey);
        calcAndSetTime(keys);
        calcAndSetDistance(keys);
    }, [travelTimesCache, pairs]);

    return {
        travelTimesCache,
        travelTime,
        travelDistance,
        isLoading,
    }
}
