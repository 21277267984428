import Tooltip from '@mui/material/Tooltip';
import React from 'react';

interface ResourceConflictTooltipProps {
    tooltipComponent: NonNullable<React.ReactNode>,
    children: React.ReactElement<any, any>;
}

export const ResourceConflictTooltip: React.FC<ResourceConflictTooltipProps> = (props) => {
    const { tooltipComponent, children } = props;

    return (
        <Tooltip
            title={
                tooltipComponent
            }
            arrow
            placement='right'
            componentsProps={{
                tooltip: {
                    sx: (theme) => ({
                        backgroundColor: theme.palette.error.dark,
                        color: theme.palette.error.contrastText,
                        padding: '0.4rem 0.5rem',
                        fontSize: '0.75rem',
                        maxWidth: '240px'
                    })
                },
                arrow: {
                    sx: (theme) => ({
                        color: theme.palette.error.dark,
                    })
                }
            }}
        >
            {children}
        </Tooltip>
    );
};
