import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { BulkPreviewTitleBar } from './bulk-preview-title-bar';
import { BulkPreviewTableHeaders } from './bulk-preview-table-headers';
import { BulkPreviewTableBody } from './bulk-preview-table-body';
import { useBoolean } from '../../../shared/hooks/use-boolean';
import { LoadableComponent } from '../../../shared/loadable-component';
import { L } from 'harmony-language';
import { useGenerateProjections } from '../../../../api/mutations/use-generate-projections';

const getHeaderText = (bulkConfiguration, totalProjections) => {
    return <h2>{L.bulkPreviewHeader(totalProjections, bulkConfiguration.week)}</h2>;
};

export const BulkPreview = (props) => {
    const { bulkConfiguration, projections, projectionConfigs, onSubmit } = props;

    const [loading, isLoading, doneLoading] = useBoolean(false);
    const { mutateAsync: generateProjections } = useGenerateProjections();

    const createProjections = async () => {
        isLoading();
        try {
            await generateProjections({ config: projectionConfigs });
        } finally {
            doneLoading();
            onSubmit({ completed: true, week: bulkConfiguration.week });
        }
    };

    return (
        <LoadableComponent isLoading={loading}>
            <div className='bulk-preview' data-testid='bulk-preview'>
                {getHeaderText(bulkConfiguration, projections.length)}
                <Paper>
                    <BulkPreviewTitleBar generateProjections={createProjections}/>
                    <div className='bulk-entry-table'>
                        <Table>
                            <BulkPreviewTableHeaders isOriginBased={bulkConfiguration.checked}/>
                            <BulkPreviewTableBody projections={projections}/>
                        </Table>
                    </div>
                </Paper>
            </div>
        </LoadableComponent>
    );
};

BulkPreview.propTypes = {
    bulkConfiguration: PropTypes.object,
    onSubmit: PropTypes.func,
    projections: PropTypes.array,
    projectionConfigs: PropTypes.array,
};
