import React, { KeyboardEventHandler } from 'react';
import { OmniComplete } from '../inputs/omni-complete';
import { Load } from '../../../types';
import { LoadFilter, LoadFiltering } from './utils/use-load-filtering';
import { LoadDistinctionOption, useLoadDistinction } from './utils/use-load-distinction';
import { useLoadOrderGroup } from './utils/use-load-order-group';
import { useQueryClient } from '@tanstack/react-query';
import { useUpdateUserPreferences } from '../hooks/use-update-user-preferences';
import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, TextField } from '@mui/material';
import { Check, Lens, Clear, LensOutlined } from '@mui/icons-material';
import { L } from 'harmony-language';

interface FilterBarProps {
    isLoading: boolean,
    data: Load[],
    filtering: LoadFiltering,
    tableKey: string
}

export const FilterBar: React.FC<FilterBarProps> = props => {
    const { data, filtering, tableKey, isLoading } = props;
    const orderGroup = useLoadOrderGroup();
    const { distinctOptions: options } = useLoadDistinction(data)
    const queryClient = useQueryClient()

    const value = options.filter(o => filtering.filters.some(f => f.value === o.id && f.field === o.field));

    const [omniCompleteValue, setOmniCompleteValue] = React.useState(value);
    let hasOrderGroupFilter;

    if (orderGroup.guid) {
        value.push({ id: orderGroup.guid, entity: 'order', field: 'orderGroupGuid', category: 'order group', label: orderGroup.guid });
        hasOrderGroupFilter = true;
    } else {
        hasOrderGroupFilter = false;
    }

    const onChange = async (e: any, val: LoadDistinctionOption[]) => {
        const orderGroupFilter = val.find(x => x.id === orderGroup.guid);
        if (orderGroup.guid && !orderGroupFilter) {
            orderGroup.remove();
            await queryClient.invalidateQueries([tableKey]);
        }
        filtering.set(val.map(v => {
            return {
                value: v.id,
                entity: v.entity,
                field: v.field,
                label: v.label,
                category: v.category,
                ...(v.children ? {children: v.children} : {}),
                ...(v.selectedChild ? {selectedChild: v.selectedChild} : {})
            }
        }))
    };

    const presetOnChange = async (loadDistinctionOptions: LoadDistinctionOption[]) => {
        setOmniCompleteValue(loadDistinctionOptions);
        await onChange(null, loadDistinctionOptions)
    }

    return (
        <div style={{ width: '100%', display: 'flex', columnGap: '15px' }}>
            <OmniComplete value={omniCompleteValue} onChange={onChange} options={options} isLoading={isLoading} />
            <FilterPresets isLoading={false} allFilters={options} selectedFilters={filtering.filters} onChange={presetOnChange} hasOrderGroupFilter={hasOrderGroupFilter}  />
        </div>
    );
};

interface FilterPresetsProps {
    isLoading: boolean,
    allFilters: LoadDistinctionOption[],
    selectedFilters: LoadFilter[],
    onChange: (loadDistinctionOptions: LoadDistinctionOption[]) => void,
    hasOrderGroupFilter: boolean
}

interface Preset {
    name: string,
    filters: LoadFilter[],
    default?: boolean,
}

export const FilterPresets: React.FC<FilterPresetsProps> = props => {
    const {allFilters, selectedFilters, onChange, hasOrderGroupFilter} = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { preferences, updatePreferences } = useUpdateUserPreferences();

    const handleMenuItemClick = async (e: React.MouseEvent<HTMLElement> | null, filters: LoadFilter[]) => {
        setAnchorEl(null);

        const distinctionOptions = filters.map(x => {
            //try to get the children from the LoadDistinctionOptions, if possible
            let children;
            if (x.selectedChild) {
                const filter = allFilters.find(y => y.field === x.field && y.id === x.value);
                if (filter) {
                    children = filter.children;
                }
            }
            return {
                entity: x.entity,
                field: x.field,
                id: x.value,
                label: x.label,
                category: x.category,
                ...(x.children ? {children: children || x.children} : {}),
                ...(x.selectedChild ? {selectedChild: x.selectedChild} : {})
            }
        });
        await onChange(distinctionOptions);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };

    const [activePreset, setActivePreset] = React.useState<Preset | undefined>(undefined);

    const presetsMatch = (preset1: Pick<Preset, 'filters'>, preset2: Pick<Preset, 'filters'>) => {
        return preset1.filters.length === preset2.filters.length && 
                preset1.filters.every(y => {
                    return preset2.filters.findIndex(z => {
                        return z.value === y.value && z.entity === y.entity && z.field === y.field &&
                        z.selectedChild?.id === y.selectedChild?.id && z.selectedChild?.field === y.selectedChild?.field
                    }) > -1
                });
    }

    React.useEffect(() => {
        const matchingPreset = (preferences.presets as Preset[])?.find(x => presetsMatch({filters: selectedFilters}, x));
        setActivePreset(matchingPreset);
    }, [selectedFilters, preferences])

    React.useEffect(() => {
        //first time through, apply the default filter, if present
        if (!hasOrderGroupFilter) {
            const defaultPreset = (preferences?.presets as Preset[])?.find(x => x.default);
            if (defaultPreset) {
                handleMenuItemClick(null, defaultPreset.filters)
            }
        }
    },[])

    const handleSave = () => {
        const newPresetName = (document?.getElementById('newPresetName') as HTMLInputElement)?.value;
        if (newPresetName.trim().length === 0)
            return;
        
        const newPreferences = {
            ...preferences,
            presets: [
                ...(preferences.presets ? preferences.presets : {}),
                {
                    name: newPresetName,
                    filters: selectedFilters
                }
            ]
        }
        updatePreferences(newPreferences);
    }

    const handleClear = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, index: number) => {
        e.stopPropagation();

        const newPreferences = {
            ...preferences,
            presets: [
                ...preferences.presets.slice(0, index),
                ...preferences.presets.slice(index + 1)
            ]
        };
        updatePreferences(newPreferences);

    }

    const handleDefaultToggle = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, index: number, makeDefault: boolean) => {
        e.stopPropagation();

        let newPreferences;
        if (makeDefault) {
            //need to remove default from all other presets
            newPreferences = {
                ...preferences,
                presets: [
                    ...(preferences.presets as Preset[]).slice(0, index).map(x => ({...x, default: false })),
                    { ...preferences.presets[index], default: true },
                    ...(preferences.presets as Preset[]).slice(index + 1).map(x => ({...x, default: false }))
                ]
            }
        } else {
            //just removing the existing default
            newPreferences = {
                ...preferences,
                presets: [
                    ...preferences.presets.slice(0, index),
                    { ...preferences.presets[index], default: false },
                    ...preferences.presets.slice(index + 1)
                ]
            };
        }
        updatePreferences(newPreferences);

    }

    const stopPropagation: KeyboardEventHandler = (e) => {
        switch (e.key) {
          case "ArrowDown":
          case "ArrowUp":
          case "Home":
          case "End":
            break;
          default:
            e.stopPropagation();
        }
      };

    const showSearch = !activePreset && !(selectedFilters.length === 0);
    const hasPresets = preferences?.presets?.length > 0;
    return (
        <>
            <Button variant='contained' color='inherit' onClick={(e)=>setAnchorEl(e.currentTarget)}>{L.presets()}</Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} PaperProps={{style:{width:500}}}>
                <MenuList dense style={{minWidth:300}}>
            {showSearch &&
                <MenuItem style={{display: 'flex', columnGap: '15px'}}>
                    <TextField id='newPresetName' onKeyDown={stopPropagation} placeholder={L.nameThisPreset()} style={{width:'100%'}} variant='outlined' size='small'></TextField>
                    <Button variant='contained' size='small' onClick={handleSave}>{L.save()}</Button>
                </MenuItem>
            }
            {showSearch && <Divider />}
            {hasPresets && 
                <MenuItem style={{display: 'inline-grid', gridTemplateColumns: '30px 1fr 100px 100px', width: '100%'}}>
                    <div/>
                    <ListItemText>{L.name()}</ListItemText>
                    <ListItemText style={{display: 'flex', justifyContent: 'center'}}>{L.default()}</ListItemText>
                    <ListItemText style={{display: 'flex', justifyContent: 'center'}}>{L.delete()}</ListItemText>
                </MenuItem>
            }
            {hasPresets && <Divider />}
            {hasPresets ?
             (preferences?.presets as Preset[])?.sort((a,b) => a.name.localeCompare(b.name)).map((x, i: number) => {
                const isCurrentPreset = activePreset && presetsMatch(activePreset, x);
                const itemText = isCurrentPreset
                                ? <ListItemText primaryTypographyProps={{style: {fontWeight: 'bold'}}}>{x.name}</ListItemText>
                                : <ListItemText>{x.name}</ListItemText>;
                return <MenuItem key={i} onClick={(e) => handleMenuItemClick(e, x.filters)} style={{display: 'inline-grid', gridTemplateColumns: '30px 1fr 100px 100px', width: '100%'}}>
                    {isCurrentPreset ? <Check /> : <div />}
                    {itemText}
                    <ListItemIcon style={{justifyContent: 'center'}}>
                        {x.default
                            ? <Lens onClick={(e) => handleDefaultToggle(e, i, false)} fontSize='small' />
                            : <LensOutlined onClick={(e) => handleDefaultToggle(e, i, true)} fontSize="small" />
                        }
                    </ListItemIcon>
                    <ListItemIcon style={{justifyContent: 'center'}}>
                        <Clear onClick={(e) => handleClear(e, i)} fontSize="small" />
                    </ListItemIcon>
                </MenuItem>
            })
            : <MenuItem><ListItemText style={{textAlign: 'center'}}>{L.noPresetsHaveBeenDefined() }</ListItemText></MenuItem>}
            </MenuList>
            </Menu>

        </>
    )
}
