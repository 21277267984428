import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { mutationOptions, OrgQueryKeys, QueryKeys } from '../../config';

const editPreferences = (organizationId: number, contactId: number) => {
    return async (newContact: any) => {
        return (await axios.put(`${API_BASE}/api/organizations/${organizationId}/contacts/${contactId}`, newContact)).data;
    }
};

export const useEditContactPreferences = (organizationId: number, contactId: number) => {
    const key = QueryKeys.self;
    const queryClient = useQueryClient();

    return useMutation(editPreferences(organizationId, contactId), {
        ...mutationOptions([key], queryClient),
        onSuccess: () => {
            // if on editUsers screen and change pref on UserInformation popup
            // need to get fresh copy of all users
            const usersQueryKey = `/organizations/${organizationId}${OrgQueryKeys.users}`;
            queryClient.invalidateQueries([usersQueryKey]);
        }
    });
};
