import React from 'react';
import { setUpAxiosRequestHandler } from '../utils/set-up-axios-request-handler';
import { setUpAxiosResponseHandler } from '../utils/set-up-axios-response-handler';
import { Outlet, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from '../components/errors/error-boundary';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../styles/agistics-theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { defaultQueryOptions } from '../api/config';
import { SnackbarProvider } from 'notistack';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import '../scss/app.scss';
import { AgisticsProvider } from './agistics-context';
import { fetchLanguageDictionary } from '../components/user/actions/fetch-language-dictionary';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const queryClient = new QueryClient({
    defaultOptions: defaultQueryOptions,
});

export const App: React.FC = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        setUpAxiosRequestHandler();
        setUpAxiosResponseHandler(queryClient, navigate);
        fetchLanguageDictionary();
    }, []);

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider
                        disableWindowBlurListener={true}
                        maxSnack={1}
                        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                    >
                        <QueryClientProvider client={queryClient}>
                            <ErrorBoundary>
                                <AgisticsProvider>
                                    <Outlet />
                                </AgisticsProvider>
                            </ErrorBoundary>
                            {DEV_MODE && <ReactQueryDevtools
                                initialIsOpen={false}
                                position='bottom-right'
                                closeButtonProps={{ style: { left: 'auto', right: '0px' }}}/>}
                        </QueryClientProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </LocalizationProvider>
        </>
    );
};
