import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import { ResourceConflictTooltip } from '../containers/resource-conflict-tooltip';
import { Resource, ResourcePageResourceType, Stop, OrganizationLocation } from '../../../types';
import { useLoadContext } from '../load-context';
import { useGetBookingConflict } from './use-booking-conflict';
import { useGetDowntimeConflict } from './use-downtime-conflict';
import { useGetLocationTypeConflict } from './use-location-type-conflict';
import { useGetCargoTypeConflict } from './use-cargo-type-conflict';
import { conflictMapper } from './resource-conflict-utils';
import { useResourceBookings } from '../../bookings/use-resource-bookings';
import { useResourceDowntimes } from '../../downtime/use-resource-downtimes';
import { useFeatures } from '../../user/selectors/use-permissions';
import { Features } from 'harmony-constants';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { useLocationTypes } from '../../../api/queries/use-location-types';

const ConflictContainer: React.FC<{ resource: Resource, children: string | React.ReactNode }> = (props) => {
    const { resource, children } = props;

    const { load, disableConflicts } = useLoadContext();

    if (disableConflicts) {
        return <>{children}</>
    }

    const hasDowntimeAccess = useFeatures(Features.Downtime);
    const { data: allBookings, isFetching: isBookingFetching } = useResourceBookings();
    const { data: allDowntimes = [], isInitialLoading: isDowntimeLoading } = useResourceDowntimes({enabled: hasDowntimeAccess && !disableConflicts});
    const { data: locations = [], isLoading: isLoadingLocations } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { data: locationTypes = [], isLoading: isLoadingLocationTypes } = useLocationTypes();

    const bookingConflict = React.useMemo(() => {
        return useGetBookingConflict(load, allBookings, resource);
    }, [load, allBookings]);
    const downtimeConflict = React.useMemo(() => {
        return useGetDowntimeConflict(load, allDowntimes, resource);
    }, [load, allDowntimes]);
    const locationTypeConflict = React.useMemo(() => {
        return useGetLocationTypeConflict(locations, locationTypes, resource);
    }, [load, locations, locationTypes]);
    const cargoTypeConflict = React.useMemo(() => {
        return useGetCargoTypeConflict(resource);
    }, [load]);

    const isLoading = isBookingFetching || isDowntimeLoading || isLoadingLocations || isLoadingLocationTypes;

    const conflicts = conflictMapper(bookingConflict, downtimeConflict, locationTypeConflict, cargoTypeConflict);
    const hasConflict = conflicts.some(x => Boolean(x));

    const renderedConflicts = conflicts.filter(Boolean).reduce((acc, current) => {
        return acc === null ? current : <React.Fragment>{acc}
            <hr style={{ border: 0, borderTop: '1px solid white'}} />
            {current}</React.Fragment>;
    }, null);

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            color: hasConflict ? '#d32f2f' : '',
        }}>
            <div style={{display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                {children}
            </div>
            {isLoading ? <CircularProgress size={'1em'} /> : hasConflict &&
                <ResourceConflictTooltip tooltipComponent={
                    <div>
                        {renderedConflicts}
                    </div>
                }>
                    <WarningIcon
                        sx={{ width: '2em' }}
                        data-testid={'conflicted-icon'}
                        style={{ fontSize: '1em' }} />
                </ResourceConflictTooltip>
            }
        </div>
    );
};

type R = {
    id?: number | string | null;
    type: ResourcePageResourceType,
    cargoTypeIds?: number[],
    stop?: Stop
}

export const ResourceConflictContainer: React.FC<{ resource: R, children: string | React.ReactNode }> = (props) => {
    const { resource } = props;

    if (!resource.id || !resource.type) {
        return <>{props.children}</>;
    }

    return <ConflictContainer {...props} resource={resource as Resource} />;
};
