import React from 'react';
import Button from '@mui/material/Button';
import Close from '@mui/icons-material/Close';
import { getCurrentStop } from './utils/get-current-stop';
import { LiveMapStopsChart, LiveMapStopsChartLegend } from './live-map-stops-chart';
import { getTimeToNextStop } from './utils/time-to-next-stop';
import { LiveMapStopStatus } from './live-map-stop-status';
import { useRouteData } from './utils/use-route-data';
import Skeleton from "@mui/material/Skeleton";
import { useLoadsInTransit } from '../../api/queries/use-loads-in-transit';
import { DeviceLocation } from '../../types';
import { CircularLoading } from '../shared/circular-loading';
import { L } from 'harmony-language';

export const LiveMapLoadDetails: React.FC<{ deviceLocation: DeviceLocation, onClose: () => void }> = (props) => {
    const { deviceLocation, onClose } = props;
    const { data: loadsInTransit, isLoading, refetch: refetchLoadsInTransit } = useLoadsInTransit();
    const load = loadsInTransit?.find(x => x.id === deviceLocation.orderId);

    React.useEffect(() => {
        refetchLoadsInTransit();
    }, [deviceLocation]);

    // useRouteData - was having tons of here map calls because of the calls happening inside an useEffect
    // was working, until I needed to make loadsInTransit refetch on deviceLocation change (#2861), that change made here map calls go up to 3 per change instead of just 1
    // trying to just change useEffect dependency array to only include deviceLocation, will revist if this breaks something else unintentionally
    const routeInfo = useRouteData(deviceLocation, load);

    if (isLoading) {
        return <CircularLoading />;
    }

    const currentStop = getCurrentStop(load?.stops);

    return (
        <div className='live-map-order-info' data-testid='live-map-info'>
            <Button className='live-map-close-sidebar-button' onClick={onClose}><Close /></Button>
            {load ? <>
                <LiveMapStopStatus stop={currentStop} />
                {routeInfo ? <span className='remaining-time'>{L.expectedToArrive(getTimeToNextStop(routeInfo) || '')}</span> : <Skeleton />}
                <LiveMapStopsChart stops={load?.stops} route={routeInfo} />
                <LiveMapStopsChartLegend />
            </>
                :
                <div>{L.noLoad()}</div>}
        </div>
    );
};
