import React from 'react';
import { L } from 'harmony-language';
import { OrgQueryKeys } from '../../../api/config';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import {
    useEditOrganizationResourceMutation
} from '../../../api/mutations/edit/use-edit-organization-resource-mutation';
import { useAddOrganizationResourceMutation } from '../../../api/mutations/add/use-add-organization-resource-mutation';
import { useServiceAccountsColumns } from './use-service-accounts-columns';
import { ResourcePage } from '../resource-page';
import { EditCreateServiceAccount } from './edit-create-service-account/edit-create-service-account';
import { ConfirmationDialog } from '../../shared/confirmation-dialog';
import {
    useDeleteOrganizationResourceMutation
} from '../../../api/mutations/delete/use-delete-organization-resource-mutation';
import { AlertDialog } from '../../shared/containers/alert-dialog';
import Typography from '@mui/material/Typography';
import { ActionIcon } from '../../shared/table/components/action-icon';
import { useSnackbar } from '../../notifications/use-snackbar';
import { copyNodeTextToClipboard } from '../../../utils/copy-to-clipboard';
import { usePermissions } from '../../user/selectors/use-permissions';
import { DELETE_SERVICE_USER_PERMISSION } from '../../../permissions/permissions';

export const ServiceAccountManagement = () => {
    const { data: serviceUsers, isLoading } = useOrganizationQuery(OrgQueryKeys.serviceUsers);
    const { mutateAsync: addServiceUser, isLoading: isCreating } = useAddOrganizationResourceMutation(OrgQueryKeys.serviceUsers, L.serviceAccount());
    const { mutate: editServiceUser, isLoading: isUpdating } = useEditOrganizationResourceMutation(OrgQueryKeys.serviceUsers, L.serviceAccount());
    const { mutate: deleteServiceUser } = useDeleteOrganizationResourceMutation(OrgQueryKeys.serviceUsers);
    const deleteServiceAccountPermission = usePermissions(DELETE_SERVICE_USER_PERMISSION);

    const snackbar = useSnackbar();
    const [secret, setSecret] = React.useState(null);
    const secretTextRef = React.useRef(null);

    const [toDelete, setToDelete] = React.useState(null);

    const actions = [];
    if (deleteServiceAccountPermission) {
        const deleteAtion = {
            icon: 'delete',
            tooltip: L.delete(),
            action: setToDelete
        };

        actions.push(deleteAtion);
    }

    const columns = useServiceAccountsColumns();

    const copyToClipboard = () => {
        if (copyNodeTextToClipboard(secretTextRef.current)) {
            snackbar(L.copiedToClipboard());
        }
    };

    const handleClose = (e, reason) => {
        if (reason !== 'backdropClick') {
            setSecret(null);
        }
    }

    return (
        <>
            <AlertDialog
                maxWidth={'xs'}
                open={Boolean(secret)}
                title={L.copy()}
                confirmText={L.ok()}
                handleClose={handleClose}
            >
                <Typography variant={'h6'}>{L.serviceAccountCreatedMessage()}</Typography>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingTop: '1rem'
                }}>
                    <div style={{ overflowWrap: 'anywhere' }}>
                        <Typography variant={'body1'} ref={secretTextRef}>{secret}</Typography>
                    </div>
                    <ActionIcon icon={'file_copy'} tooltip={L.copy()} action={copyToClipboard} testId={-1}/>
                </div>
            </AlertDialog>
            <ConfirmationDialog
                open={Boolean(toDelete)}
                title={L.deleteConfirmationTitle()}
                message={L.deleteConfirmationMessage(toDelete?.name)}
                cancelText={L.cancel()}
                confirmText={L.delete()}
                resultCallback={(confirmed) => {
                    if (confirmed) {
                        deleteServiceUser({ id: toDelete.id });
                    }
                    setToDelete(null);
                }}
            >
            </ConfirmationDialog>
            <ResourcePage
                resourceType='serviceAccount'
                additionalActions={actions}
                isLoading={isLoading || isCreating || isUpdating}
                data={serviceUsers}
                columns={columns}
                EditCreateComponent={(editCreateProps) => {
                    return <EditCreateServiceAccount
                        existingServiceAccount={editCreateProps.resource}
                        onSubmit={async (newData) => {
                            if (newData.id) {
                                editServiceUser(newData);
                            } else {
                                addServiceUser(newData).then((response) => {
                                    setSecret(response.secret);
                                });
                            }
                            editCreateProps.closeModal();
                        }}/>;
                }}/>
        </>
    );
};
