/* eslint-disable */
import { renderToStaticMarkup } from 'react-dom/server';
import * as HereMapWrapper from './here-map-wrapper';

export function useBoundPadding(rect) {
    if (!rect) {
        return {};
    }

    const xPad = (rect.getRight() - rect.getLeft()) * .2;
    const yPad = (rect.getTop() - rect.getBottom()) * .2;
    const paddedRect = HereMapWrapper.createGeoRectangle(rect.getTop() + yPad, rect.getLeft() - xPad, rect.getBottom() - yPad, rect.getRight() + xPad);

    // Use our zoom level if we have no padding
    const useZoom = !Boolean(xPad > 0 || yPad > 0);

    return { bounds: paddedRect, useZoom };
}

//on avoidance zone screen - defualt to org center, recenter button to org center
//on create location screen:
//  new: org center
//  existing: fit address and geoFence on screen.
//on edit load screen - all waypoints address and geoFence, route points, avoidance zones NOT in bounds
//on live map - all objects, but not avoidance zones?
export function fitMapToAllObjects(map, centerPosition, forceReset, zoomLevel = 12, avoidanceInBounds) {
    if (!map) {
        return;
    }

    const positions = map.getObjects()
        .filter(HereMapWrapper.isMarker)
        .map((marker) => [marker.getGeometry().lat, marker.getGeometry().lng, null]);

    //these are rectangles that ARE movable.
    let centerOfBbox;
    const customBbox = map.getObjects().filter(HereMapWrapper.isGroup);
    if (customBbox.length > 0) {
        const boundingBox = customBbox[0].getObjects()[0].getGeometry().getBoundingBox();
        centerOfBbox = [boundingBox.getCenter().lat, boundingBox.getCenter().lng, null];
        positions.push(centerOfBbox);

        //when center of bbox is very close to marker, we don't want to zoom in to much
        positions.push([boundingBox.getTopLeft().lat, boundingBox.getTopLeft().lng, null]);
        positions.push([boundingBox.getBottomRight().lat, boundingBox.getBottomRight().lng, null]);
    }

    //these are rectangles that ARE NOT movable.
    const rectangle = map.getObjects()
        .filter(HereMapWrapper.isRectangle)
        .filter(rec => avoidanceInBounds ? rec : rec.getData().rectangleType === 'site')
        .map((r) => [r.getBoundingBox().getCenter().lat, r.getBoundingBox().getCenter().lng, null]);

    if (rectangle.length > 0) {
        rectangle.forEach(rec => {
            positions.push(rec);
        });
    }

    //H.map.Polyline is both MultiLineString and LineString
    //MultiLineString = route calculated and returned from here map api
    //LineString = line of actual locations from db
    const lineObjects = map.getObjects().filter(HereMapWrapper.isPolyline);
    if (lineObjects.length > 0) {
        lineObjects.map(lineObject => {
            const box = lineObject.getBoundingBox();
            positions.push([box.getTopLeft().lat, box.getTopLeft().lng, null]);
            positions.push([box.getBottomRight().lat, box.getBottomRight().lng, null]);
        })
    }

    if (positions.length === 0) {
        recenterMap(map, centerPosition);
        return;
    }

    const allInCurrentBounds = positions.every(([lat, lng]) => map.getViewModel().getLookAtData().bounds.getBoundingBox().containsLatLng(lat, lng));

    if (!allInCurrentBounds || forceReset) {
        const polygon = HereMapWrapper.createPolygon(HereMapWrapper.createLineString(positions.flat()));
        const { bounds, useZoom } = useBoundPadding(polygon.getBoundingBox());

        map.getViewModel().setLookAtData({
            zoom: useZoom ? zoomLevel : undefined,
            bounds,
            position: customBbox.length > 0 ? {
                lat: centerOfBbox[0],
                lng: centerOfBbox[1]
            } : polygon.getBoundingBox().getCenter()
        }, false);
    }
}

export function centerMap(map, latitude, longitude, zoomLevel = 12) {
    map.getViewModel().setLookAtData({
        position: { lat: latitude, lng: longitude },
        zoom: zoomLevel
    }, false);
}

//only for centering when no other objects are on the map
export function recenterMap(map, centerPosition) {
    map.getViewModel().setLookAtData({
        position: { lat: centerPosition.lat, lng: centerPosition.lng }
    });
}

export function setMarkerIcon(marker, icon) {
    marker.setIcon(HereMapWrapper.createMapIcon(icon));
}

export function setInfoBubbleContent(infoBubble, content) {
    infoBubble.setContent(renderToStaticMarkup(content));
}

export function setTooltipBehavior(infoBubble, stayOpen, onClick, parent) {
    if (stayOpen) {
        parent.addEventListener('tap', () => {
            HereMapWrapper.isInfoBubbleOpen(infoBubble) ? infoBubble.close() : infoBubble.open();
            onClick && onClick();
        });
    } else {
        infoBubble.close();

        parent.addEventListener('pointerenter', () => {
            infoBubble.open();
        });

        parent.addEventListener('pointerleave', () => {
            infoBubble.close();
        });

        parent.addEventListener('tap', () => {
            onClick && onClick();
        });
    }
}


export function setTooltipBehavior2(infoBubble, stayOpen, parent) {
    const pointerEnter = () => {
        infoBubble.open();
    };
    const pointerLeave = () => {
        infoBubble.close();
    }
    if (stayOpen) {
        infoBubble.open();
    } else {
        infoBubble.close();
        parent.addEventListener('pointerenter', pointerEnter);
        parent.addEventListener('pointerleave', pointerLeave);
        return () => {
            parent.removeEventListener('pointerenter', pointerEnter);
            parent.removeEventListener('pointerleave', pointerLeave);
        }
    }
}

export const toggleMapObjects = (map, isVisible, dataKey) => {
    const objects = map.getObjects().filter(x => x.getData()?.[dataKey]);
    objects.forEach(x => {
        x.setVisibility(isVisible);
    })
}

const getClusterMarkers = (map) => {
    const mapLayers = map.getLayers().asArray();
    const providerLayers = mapLayers.map(x => x.getProvider());
    const clusterProvider = providerLayers.find(x => x instanceof H.clustering.Provider);
    const rect = new H.geo.Rect(90, -180, -90, 180);
    const markers = clusterProvider.requestMarkers(rect, undefined, false, true);
    return markers;
}

export const toggleEventsByType = (map, isVisible, type) => {
    const markers = getClusterMarkers(map);

    markers.map(marker => {
        const isCluster = marker.getData().isCluster();
        if (!isCluster) {
            const markerData = marker.getData().getData();
            if (markerData.device.type === type) {
                marker.setVisibility(isVisible);
            }
        }
    });
}

export const toggleAugerOnEvents = (map, bool) => {
    const markers = getClusterMarkers(map);

    markers.map(marker => {
        const isCluster = marker.getData().isCluster();
        if (!isCluster) {
            const markerData = marker.getData().getData();
            if (bool) {
                marker.setVisibility(true);
            } else {
                if (markerData.type === 'AugerOn') {
                    marker.setVisibility(true);
                } else {
                    marker.setVisibility(false);
                }
            }
        }
    });
}
