import React from 'react';
import { getTransCargoType } from '../../../constants/constants';
import { cargoTypesToCategoryGroups } from '../../../utils/data-mapping-utils';
import { MenuItem, TextField } from '@mui/material';
import { L } from 'harmony-language';
import PropTypes from 'prop-types';
import { useCargoTypes } from '../../../api/queries/use-cargo-types';

export const SelectCargoType = (props) => {
    const { cargoType, onChange, filterSelector, ...rest } = props;
    const { data } = useCargoTypes();
    const cargoTypes = React.useMemo(() => {
        if(filterSelector){
            return data?.filter(filterSelector);
        }
        return data;
    }, [data, filterSelector]);

    const cargoTypeGroups = React.useMemo(() => {
        const groupedLocationTypes = cargoTypesToCategoryGroups(cargoTypes);

        Object.keys(groupedLocationTypes).map(group => {
            groupedLocationTypes[group] = groupedLocationTypes[group].map(getTransCargoType).sortBy(c => c.label);
        });
        return groupedLocationTypes;
    }, [cargoTypes]);

    const cargoTypeList = Object.keys(cargoTypeGroups).sort().map(group => {
        return cargoTypeGroups[group].reduce((acc, cargoType) => {
            return acc.concat(cargoType);
        }, [{ id: group, label: group, isGroupHeader: true }]);
    }).flat();

    return (
        <TextField
            variant='standard'
            id='cargo-type'
            label={L.cargoType()}
            select
            value={cargoType?.id || ''}
            SelectProps={{
                MenuProps: {
                    MenuListProps: {
                        disablePadding: true
                    }
                }
            }}
            onChange={(e) => {
                const value = e.target.value;
                const cargoType = cargoTypes.find(l => l.id === value);

                onChange(cargoType);
            }}
            {...rest}>
            {
                cargoTypeList.map((c) => {
                    return (
                        <MenuItem key={c.id}
                            value={c.id}
                            disabled={c.isGroupHeader}
                            style={{
                                opacity: c.isGroupHeader ? 1 : undefined,
                                ...(c.isGroupHeader && {
                                    backgroundColor: '#424242',
                                    color: 'white',
                                    display: 'block',
                                    textAlign: 'center',
                                }),
                            }}
                        >
                            {c.label}
                        </MenuItem>
                    );
                })
            }
        </TextField>
    );
};

SelectCargoType.propTypes = {
    cargoType: PropTypes.object,
    onChange: PropTypes.func,
    filterSelector: PropTypes.func
};

