import React from 'react';
import { HereMapCircle } from './here-map-circle';
import { OrganizationSubLocation } from '../../types';

const SUBLOCATION_RADIUS = 6.096; // 20 feet in meters

interface SublocationCircleProps {
    organizationSubLocation: OrganizationSubLocation;
}

export const SublocationCircle: React.FC<SublocationCircleProps> = (props) => {
    const { organizationSubLocation } = props;

    return (
        <HereMapCircle
            color={'red'}
            latitude={organizationSubLocation?.customGeofence?.latitude}
            longitude={organizationSubLocation?.customGeofence?.longitude}
            radius={SUBLOCATION_RADIUS}
            resizable={false}>
                <div>{organizationSubLocation.name}</div>
        </HereMapCircle>
    );
};
