import { UseQueryOptions, useQuery } from '@tanstack/react-query';

// Static queries have an infinite stale and cache time meaning they won't go stale and wont be garbage collections
// This means this query will only be called once unless the page is refreshed
export const useStaticQuery = <T>(key: string, options: UseQueryOptions<T> = {}) => {
    return useQuery<T>({
        queryKey: [key],
        staleTime: Infinity,
        cacheTime: Infinity,
        ...options,
    });
};
