import PropTypes from 'prop-types';
import { downtimeDateTimeRangeDisplay, durationToHoursMinutes, localDateTimeDisplay, unlocalizedDateTimeDisplay } from '../../utils/date-time-utils';
import { DaysOfWeek } from './downtime-days-of-week';
import { IconButton, ListItem, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { L } from 'harmony-language';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import React, { useState } from 'react';

const DowntimeRow = styled(ListItem)({
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    gridTemplateColumns: '6fr 4fr 5fr 8fr 2fr',
    rowGap: '0px',
    columnGap: '0px',
    height: '100%'
})
const DowntimeDateRange = styled('span')({
    marginLeft: '1rem',
    marginRight: '1rem',
});
const DowntimeDetails = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
});

export const ResourceDowntimeRow = (props) => {
    const { downtime, onEdit, onDelete } = props;

    return (
        <DowntimeRow>
            <div style={{gridColumnStart: 'span 5'}}>{downtime.description}</div>
            <div style={{whiteSpace: 'nowrap'}}>{unlocalizedDateTimeDisplay(`${downtime.startDate} ${downtime.startTime}`)}</div>
            <div style={{whiteSpace: 'nowrap'}}>{durationToHoursMinutes(downtime.durationMinutes)}</div>
            <div>{downtime.timezone.replace('_', ' ')}</div>
            <DaysOfWeek
                daysOfWeekBitmask={downtime.recurrenceDaysOfWeek}/>
            <div style={{textAlign: 'center', whiteSpace: 'nowrap'}}>
            <Tooltip title={L.edit()}>
                <IconButton
                    size='small'
                    color='primary'
                    disabled={false}
                    onClick={onEdit}
                >
                    <EditOutlinedIcon/>
                </IconButton>
            </Tooltip>
                <Tooltip ml={6} title={L.delete()}>
                    <IconButton
                        size='small'
                        color='primary'
                        disabled={false}
                        onClick={onDelete}
                    >
                        <DeleteOutlinedIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        </DowntimeRow>
    );
};

ResourceDowntimeRow.propTypes = {
    downtime: PropTypes.object.isRequired,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
};
