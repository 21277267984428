
import React, { Dispatch, SetStateAction, useState } from 'react';
import * as HereMapService from '../../services/here-maps/here-map-service';
import { L } from 'harmony-language';
import { Button, FormControlLabel, Paper, Popper, Switch } from '@mui/material';
import { HereMapControlOptions } from './here-map';

interface HereMapControlProps {
    map: any;
    controlOptions: HereMapControlOptions;
}

export const HereMapControl: React.FC<HereMapControlProps> = (props) => {
    const { map, controlOptions } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileLocations, setMobileLocations] = useState(controlOptions.showMobileLocations || false);
    const [trailerLocations, setTrailerLocations] = useState(controlOptions.showTrailerLocations || false);
    const [mobileHeading, setMobileHeading] = useState(false);
    const [trailerHeading, setTrailerHeading] = useState(false);
    const [mobileEvents, setMobileEvents] = useState(controlOptions.showMobileEvents);
    const [trailerEvents, setTrailerEvents] = useState(controlOptions.showTrailerEvents);
    const [augerOnEvents, setAugerOnEvents] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleMarkerChange = (
        setting: boolean,
        setter: Dispatch<SetStateAction<boolean>>,
        identifier: 'isMobileLocations' | 'isTrailerLocations' | 'isMobileHeadingMarker' | 'isTrailerHeadingMarker',
    ) => {
        HereMapService.toggleMapObjects(map, setting, identifier);
        setter(setting);
    }

    const handleEventsChange = (type: 'Mobile' | 'Trailer') => {
        if (type === 'Mobile') {
            HereMapService.toggleEventsByType(map, !mobileEvents, type);
            setMobileEvents(!mobileEvents)
        } else {
            HereMapService.toggleEventsByType(map, !trailerEvents, type);
            setTrailerEvents(!trailerEvents)
        }
        setAugerOnEvents(false);
    }

    const handleAugerOnEventsChange = () => {
        if (augerOnEvents) {
            HereMapService.toggleAugerOnEvents(map, augerOnEvents);
            setAugerOnEvents(!augerOnEvents);
            setTrailerEvents(true);
            setMobileEvents(true);
        } else {
            HereMapService.toggleAugerOnEvents(map, augerOnEvents);
            setAugerOnEvents(!augerOnEvents);
            setTrailerEvents(false);
            setMobileEvents(false);
        }
    }

    return (
        <>
            <Button
                variant='contained'
                color='inherit'
                sx={{ position: 'absolute', bottom: '1em', left: '1em', zIndex: 500 }}
                onClick={handleClick}
            >
                {L.controls()}
            </Button>
            <Popper
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                style={{ zIndex: 1300 }}
                placement='top-start'
                disablePortal={true}
                modifiers={[
                    {
                        name: 'flip',
                        enabled: false
                    },
                ]}
            >
                <Paper variant='outlined' style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    {controlOptions.showMobileLocations && <><FormControlLabel
                        control={<Switch
                            checked={mobileLocations}
                            onChange={() => handleMarkerChange(!mobileLocations, setMobileLocations, 'isMobileLocations')}
                        />}
                        label={L.mobileLocations()}
                    /><br /></>}
                    {controlOptions.showTrailerLocations && <><FormControlLabel
                        control={<Switch
                            checked={trailerLocations}
                            onChange={() => handleMarkerChange(!trailerLocations, setTrailerLocations, 'isTrailerLocations')}
                        />}
                        label={L.trailerLocations()}
                    /><br /></>}
                    {controlOptions.showMobileHeading && <><FormControlLabel
                        control={<Switch
                            checked={mobileHeading}
                            onChange={() => handleMarkerChange(!mobileHeading, setMobileHeading, 'isMobileHeadingMarker')}
                        />}
                        label={L.mobileHeading()}
                    /><br /></>}
                    {controlOptions.showTrailerHeading && <><FormControlLabel
                        control={<Switch
                            checked={trailerHeading}
                            onChange={() => handleMarkerChange(!trailerHeading, setTrailerHeading, 'isTrailerHeadingMarker')}
                        />}
                        label={L.trailerHeading()}
                    /><br /></>}
                    {controlOptions.showMobileEvents && <><FormControlLabel
                        control={<Switch
                            checked={mobileEvents}
                            onChange={() => handleEventsChange('Mobile')}
                        />}
                        label={L.mobileEvents()}
                    /><br /></>}
                    {controlOptions.showTrailerEvents && <><FormControlLabel
                        control={<Switch
                            checked={trailerEvents}
                            onChange={() => handleEventsChange('Trailer')}
                        />}
                        label={L.trailerEvents()}
                    /><br /></>}
                    {controlOptions.showAugerOn && <><FormControlLabel
                        control={<Switch
                            checked={augerOnEvents}
                            onChange={() => handleAugerOnEventsChange()}
                        />}
                        label={L.augerOnEvents()}
                    /><br /></>}
                </Paper>
            </Popper>
        </>
    );
};
