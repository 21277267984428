import { useStaticQuery } from '../../../../api/queries/use-static-query';
import { QueryKeys } from '../../../../api/config';
import ListItem from '@mui/material/ListItem';
import Skeleton from "@mui/material/Skeleton";
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { getTransUserRole } from '../../../../constants/constants';
import List from '@mui/material/List';
import PropTypes from 'prop-types';
import React from 'react';
import { Driver, SiteManager, LoadingTeamMember } from '../../../../constants/constants'

export const RolesList = (props) => {
    const { roles, onChange } = props;
    const { data: allAvailableRoles, isLoading } = useStaticQuery(QueryKeys.roles);

    const toggleRole = (role) => {
        const newRoles = roles || [];
        const roleIndex = newRoles.findIndex(r => r.id === role.id);

        if (roleIndex === -1) {
            newRoles.push(role);
        } else {
            newRoles.splice(roleIndex, 1);
        }

        onChange(newRoles);
    };

    const toSkeletonItem = (x) => <ListItem dense={true} key={x}><Skeleton width='100%'/></ListItem>;
    const toListItem = (role, i) => {
        const isChecked = roles?.some(r => r.id === role.id);

        let disabled = false;
        if (role.id === Driver.id) {
            disabled = roles?.some(r => r.id === SiteManager.id || r.id === LoadingTeamMember.id);
        }
        if (role.id === SiteManager.id || role.id === LoadingTeamMember.id) {
            disabled = roles?.some(r => r.id === Driver.id);
        }

        return (
            <ListItem dense={true} key={i} onClick={() => !disabled ? toggleRole(role) : null} disabled={disabled}>
                <ListItemIcon>
                    <Checkbox
                        checked={isChecked || false}
                        edge='start'
                        disabled={disabled}
                    />
                </ListItemIcon>
                <ListItemText>{getTransUserRole(role).label}</ListItemText>
            </ListItem>
        );
    };

    return (
        <List>
            {isLoading ? Array.from(Array(7), (x, i) => i).map(toSkeletonItem) : allAvailableRoles.map(toListItem)}
        </List>
    );
};

RolesList.propTypes = {
    roles: PropTypes.array,
    onChange: PropTypes.func,
};
