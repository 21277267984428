import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { mutationOptions, OrgQueryKeys } from '../config';
import { useSnackbar } from '../../components/notifications/use-snackbar';
import { L } from 'harmony-language';
import { SiteIssue } from '../../types';
import { useCompanyId } from '../../app/agistics-context';

const resolveSiteIssue = (companyId: number) => async (siteIssue: SiteIssue) => {
    const { id, organizationLocationId } = siteIssue;

    return (await axios.put(`${API_BASE}/api/organizations/${companyId}/locations/${organizationLocationId}/site-issues/${id}/resolve`, siteIssue)).data;
}

export const useResolveSiteIssueMutation = () => {
    const snackbar = useSnackbar();
    const companyId = useCompanyId();
    const key = OrgQueryKeys.resolve(companyId, OrgQueryKeys.siteIssues);
    const queryClient = useQueryClient();

    return useMutation(resolveSiteIssue(companyId), {
        ...mutationOptions([key], queryClient),
        onSuccess: (data: SiteIssue) => {
            const siteIssues = queryClient.getQueryData<SiteIssue[]>([key]);
            const updatedIssues = siteIssues?.map((issue) => {
                if (issue.id === data.id) {
                    return {
                        ...data,
                    };
                }
                return issue;
            });

            queryClient.setQueryData([key], updatedIssues);
            snackbar(L.siteIssueUpdated());
        }
    });
};
