import {
    CHECKLIST_RESPONSE_BITMASK,
    getTransCargoTypeLabel,
    isCargoStop,
    SITE_CATEGORIES,
    STOP_TYPES
} from '../constants/constants';
import { add, isAfter, isSame, local, subtract, utc } from './date-time-utils';
import moment from 'moment';
import { L } from 'harmony-language';
import { salmonellaSeverities } from '../components/shared/salmonella-meter';
import { toSiteDisplayName } from './data-mapping';

// TODO remove this when wash becomes actual stop
const filterDummyWashStops = (s) => {
    return s.type !== 'Wash';
};

export const filterStopByDestination = (stop) => stop.type === STOP_TYPES().Destination.key;
export const filterStopByOrigin = (stop) => stop.type === STOP_TYPES().Origin.key;

export const loadToFirstStop = (load) => load.stops.sortBy((load) => load.sequence).first();
export const loadToLastStop = (load) => load.stops.sortBy((load) => load.sequence).last();

export const orderToFirstOrigin = (order) => order.stops.filter(filterStopByOrigin).sortBy((stop) => stop.sequence).first();

export const orderToLastDestination = (order) => order.stops.filter(filterStopByDestination).sortBy((stop) => stop.sequence).last();

export const orderToLastCompleted = (order) => {
    return order.stops.filter((stop) => stop.completedAt).sortBy((stop) => stop.completedAt).last();
};

// The result of this is an object with cargo types (or production plans) as keys and quantities as values
// if quantities is null or 0, return weight converted and string
export const orderToCargoQuantities = (order, convertFromGramsDisplay) => {
    let isQuantity = true;

    const map = order?.stops?.reduce((acc, stop) => {
        if (stop.type === STOP_TYPES().Origin.key || stop.type === STOP_TYPES().Destination.key) {
            const index = stop.type === STOP_TYPES().Origin.key ? 0 : 1;

            if (stop.productionPlan) {
                if (!acc[stop.productionPlan]) {
                    acc[stop.productionPlan] = [0, 0];
                }
                if (!stop.quantity) {
                    isQuantity = false;
                }

                acc[stop.productionPlan][index] += stop.quantity || stop.weight;
            } else if (stop.cargoTypeId) {
                const label = getTransCargoTypeLabel(stop.cargoTypeId);

                if (!acc[label]) {
                    acc[label] = [0, 0];
                }
                if (!stop.quantity) {
                    isQuantity = false;
                }

                acc[label][index] += stop.quantity || stop.weight;
            }
        }

        return acc;
    }, {}) || {};

    for (const mapKey in map) {
        map[mapKey] = isQuantity ? Math.max(...map[mapKey]) : convertFromGramsDisplay(Math.max(...map[mapKey]));
    }
    return map;
};

export const orderToLotIds = (order) => {
    const lotIds = order?.stops?.map(stop => stop.lotId).filter(Boolean);
    const unique = new Set(lotIds);
    return [...unique];
};

export const orderToOrderNumbers = (order) => {
    const orderNumbers = order?.stops?.map(stop => stop.orderNumber).filter(Boolean);
    const unique = new Set(orderNumbers);
    return [...unique];
};

export const orderToAverageWeight = (order) => {
    return order?.metadata?.cargo?.averageWeight;
};

export const orderToCargoPerCompartment = (order) => {
    const cargoPerCompartments = [...new Set(order?.stops?.map(s => s.cargoPerCompartment))].filter(Boolean);

    if (cargoPerCompartments.length === 1) {
        return cargoPerCompartments[0] || null;
    }

    return null;
};

export const orderToCargoTypeId = (order) => {
    //Temporary function to select the first stop that has a cargo type's cargo type id
    return order?.stops?.filter(s => Boolean(s.cargoTypeId))?.[0]?.cargoTypeId;
};

export const mapCargoTypeIdToStops = (cargoTypeId) => (stop) => {
    if (stop.type === STOP_TYPES().Origin.key || stop.type === STOP_TYPES().Destination.key) {
        return { ...stop, cargoTypeId };
    }
    return stop;
};


export const orderToDateTimeRange = (order) => {
    if (!order?.date) {
        return null;
    }

    const stops = order.stops?.filter(filterDummyWashStops);

    const firstStop = stops?.[0];
    let begin = firstStop?.arrivalTime;

    const date = local(order.date);

    begin = begin && firstStop?.sequence === 1 ? begin : utc(date.startOf('day'));

    const lastStop = stops?.[stops?.length - 1];
    let end = lastStop?.arrivalTime;

    end = end && lastStop?.sequence !== 1 ? end : utc(date.endOf('day'));

    return {
        begin,
        end
    };
};

// Similar to orderToDateTimeRange but takes only stops with arrival times into account and adds duration minutes
export const orderToFilteredDateTimeRange = (order) => {
    const filteredStops = order?.stops?.filter(filterDummyWashStops).filter(s => Boolean(s.arrivalTime));

    if (!filteredStops?.length) {
        return null;
    }

    const begin = moment(Math.min(...filteredStops.map(s => moment(s.arrivalTime))));
    const end = moment(Math.max(...filteredStops.map(s => add(s.durationMinutes, 'minutes', s.arrivalTime))));

    return { begin, end };
};

export const stopToDateTimeRange = (stop) => {
    if (!stop?.arrivalTime) {
        return null;
    }

    const begin = local(stop.arrivalTime);
    const end = add(stop.durationMinutes || 0, 'minutes', begin);

    return {
        begin,
        end
    };
};

export const ordersToDateTimeRange = (orders) => {
    if (!Array.isArray(orders)) {
        return null;
    }

    const dateRanges = orders.map(orderToDateTimeRange).filter(Boolean);
    const beginTimes = dateRanges.map(r => local(r.begin));
    const endTimes = dateRanges.map(r => local(r.end));

    // Handle case where we don't have enough info
    if (beginTimes.length === 0 || endTimes === 0) {
        return null;
    }

    const begin = Math.min.apply(Math, beginTimes);
    const end = Math.max.apply(Math, endTimes);

    return {
        begin: utc(begin),
        end: utc(end)
    };
};

export const orderToLoadingDurationMinutes = (order) => {
    return order.stops?.reduce((acc, stop) => acc + (stop.durationMinutes || 0), 0);
};

export const travelTimesCacheKey = (pair) => `${pair[0]},${pair[1]}`;

export const toStopDisplayName = (organizationLocations, excludeSubSite = false, includeDescription = false) => (stop) => {
    if (!stop) {
        return '';
    }

    const organizationLocation = organizationLocations?.find(x => x.id === stop?.organizationLocationId);
    const organizationSubLocation = excludeSubSite ? null : organizationLocation?.orgSubLocations?.find(x => x.id === stop.organizationSubLocationId);
    const organizationLocationDescription = includeDescription ? organizationLocation?.description : null;

    return [organizationLocation?.name, organizationLocationDescription, organizationSubLocation?.name].filter(Boolean).join(' - ');
};

export const toCustomerDisplayName = (orgLocation, customers) => {
    const customer = customers.find(x => x.id === orgLocation.customerId);
    return customer.customerName;
};

export const toStopLocationIdentifier = (stop) => {
    return [stop.organizationLocationId, stop.organizationSubLocationId].filter(Boolean).join(',');
};

export const toDriverDisplayName = (drivers) => (load) => {
    const driver = drivers?.find(driver => driver.id === load.transportedByUserId);
    return driver ? driver.name : '';
};

export const toTractorDisplayName = (tractors) => (load) => {
    const tractor = tractors?.find((t) => t.id === load.tractorId);

    return tractor?.userDisplayName;
};

export const toTrailerDisplayName = (trailers) => (load) => {
    const trailer = trailers?.find((t) => t.id === load.trailerId);

    return trailer?.userDisplayName;
};

export const toStopLoadingTeamName = (loadingTeams) => (stop) => {
    const loadingTeam = loadingTeams?.find(l => l.id === stop?.loadingTeamId);

    return loadingTeam?.name;
};

export const stopToLoadingTeamIdLabelTuple = (loadingTeams) => (stop) => {
    const loadingTeam = loadingTeams?.find(l => l.id === stop?.loadingTeamId);

    return {
        id: (loadingTeam?.id || 'no') + ' loading team',
        label: loadingTeam?.name,
        category: L.loadingTeam()
    };
};

export const stopToOrgLocationIdLabelTuple = (orgLocations) => (stop) => {
    const orgLocation = orgLocations?.find(l => l.id === stop?.organizationLocationId);
    const sublocationNames = orgLocation?.orgSubLocations?.filter(x => stop.organizationSubLocationIds?.includes(x.id)).map(x => x.name).join(', ');
    const name = [orgLocation?.name, sublocationNames].filter(Boolean).join(' - ');
    const id = [orgLocation?.id, sublocationNames].filter(Boolean).join(',');
    const siteLabel = toSiteDisplayName(orgLocation?.name, orgLocation?.description, sublocationNames);

    return {
        id: id,
        label: name,
        siteLabel: siteLabel,
        category: orgLocation ? L[`locationTypeValue${orgLocation.organizationLocationTypeId}`]() : ''
    };
};

export const orgLocationsToIdLabelTuple = (orgLocations) => {
    const stopLocationList = orgLocations?.reduce((list, orgLoc) => {
        return list.concat(orgLoc.orgSubLocations.reduce((tuples, subLoc) => {
            return tuples.concat({
                id: [orgLoc.id, subLoc.id].join(','),
                label: `${orgLoc.name} - ${subLoc.name}`
            });
        }, [{ id: `${orgLoc.id}`, label: orgLoc.name }]));
    }, []);

    return stopLocationList ? stopLocationList : [];
};

export const orgLocationsToItems = (orgLocations, locationTypes, stop, keepCurrentLoc = true) => {
    const sites = orgLocations?.filter(orgLocation => orgLocation.orgLocationType.category === SITE_CATEGORIES.SITE && orgLocation.enabled);
    const weighLocations = orgLocations?.filter(orgLocation => orgLocation.hasScale);

    let locations = orgLocations || [];
    let needsCargo = isCargoStop(stop);
    if (needsCargo) {
        locations = sites;
    } else if (stop.type === STOP_TYPES().Weigh.key) {
        locations = weighLocations;
    }

    const list = locations.reduce((prev, curr) => {
        const type = locationTypes?.find(type => {
            return type.id === curr.organizationLocationTypeId;
        });
        const cargoTypeIds = locationTypeToCargoTypeIdList(stop.type)(type);
        //const category = getTransLocationTypeLabel(orgLoc.organizationLocationTypeId);

        if (needsCargo && !isValidCargoType(cargoTypeIds, stop.cargoTypeId)) {
            return prev;
        }

        return prev.concat({
            id: curr.id,
            label: toSiteDisplayName(curr.name, curr.description),
            cargoTypeIds
        });
    }, []);

    // Add the current location back in if it was filtered out - happens when you change cargo type to a type the current location does not accept
    if (keepCurrentLoc) {
        const current = orgLocations.find(l => l.id === stop.organizationLocationId);
        if (current && !list.find(i => i.id === stop.organizationLocationId)) {
            list.unshift({
                id: current.id,
                label: toSiteDisplayName(current.name, current.description),
                cargoTypeIds: locationTypeToCargoTypeIdList(stop.type)(locationTypes?.find(type => {
                    return type.id === current.organizationLocationTypeId;
                })),
            });
        }
    }

    return list;
};

export const orgLocationsToTableSelectTuple = (orgLocations, locationTypes, stopType) => {
    const stopLocationList = orgLocations?.reduce((list, orgLoc) => {
        const type = locationTypes?.find(type => {
            return type.id === orgLoc.organizationLocationTypeId;
        });
        const cargoTypes = locationTypeToCargoTypeIdList(stopType)(type);

        return list.concat(orgLoc.orgSubLocations.reduce((tuples, subLoc) => {
            return tuples.concat({
                id: [orgLoc.id, subLoc.id].join(','),
                label: `${orgLoc.name} - ${subLoc.name}`,
                locationTypeId: orgLoc.organizationLocationTypeId,
                cargoTypes
            });
        }, [{
            id: `${orgLoc.id}`,
            label: orgLoc.name,
            cargoTypes,
            locationTypeId: orgLoc.organizationLocationTypeId
        }]));
    }, []);

    return stopLocationList ? stopLocationList : [];
};

export const toIdLabelTuple = (labelField = 'label', idField = 'id') => object => ({
    id: object[idField],
    label: object[labelField]
});

export const orderToProductionPlans = (order) => {
    return Array.from(new Set(order.stops?.filter(x => Boolean(x.productionPlan)).map(x => x.productionPlan)));
};
export const orderToSalmonellaResult = (order) => {
    return salmonellaSeverities[order.metadata?.cargo?.salmonellaResult?.toLowerCase()];
};

export const calculateStopArrivalTimes = (stops, fromStopSequence, travelTimes) => {
    // Get travel times for legs of load
    const sortedStops = stops.sortBy((stop) => stop.sequence);
    const index = sortedStops.findIndex(s => s.sequence === fromStopSequence);

    if (index === -1) {
        return [...sortedStops];
    }

    // Handle previous stops
    for (let i = index; i > 0; i--) {
        const currentStop = sortedStops[i];
        const previousStop = sortedStops[i - 1];

        const legTimeSeconds = travelTimes?.[travelTimesCacheKey([previousStop.organizationLocationId, currentStop.organizationLocationId])]?.timeSeconds || 0;
        const minutesToSubtract = (legTimeSeconds / 60) + (previousStop.durationMinutes || 0);

        previousStop.arrivalTime = utc(subtract(minutesToSubtract, 'minutes', currentStop.arrivalTime));
    }

    // Handle subsequent stops
    for (let i = index; i < sortedStops.length - 1; i++) {
        const currentStop = sortedStops[i];
        const nextStop = sortedStops[i + 1];

        const legTimeSeconds = travelTimes?.[travelTimesCacheKey([currentStop.organizationLocationId, nextStop.organizationLocationId])]?.timeSeconds || 0;
        const minutesToAdd = (legTimeSeconds / 60) + (currentStop.durationMinutes || 0);

        nextStop.arrivalTime = utc(add(minutesToAdd, 'minutes', currentStop.arrivalTime));
    }

    return [...sortedStops];
};


export const orderToNextStopSequence = (order) => {
    const sortedStops = order.stops.sortBy((stop) => stop.sequence);

    let sequence = 1;

    for (let i = 0; i < sortedStops.length; i++) {
        if (sortedStops[i].sequence !== sequence) {
            break;
        } else {
            sequence++;
        }
    }
    return sequence;
};

export const orderToMissingStopType = (order) => {
    const originKey = STOP_TYPES().Origin.key;
    const hasOrigin = order.stops.some(s => s.type === originKey);

    if (!hasOrigin) {
        return originKey;
    }

    return STOP_TYPES().Destination.key;
};

export const orderToDefaultCompartments = (order) => {
    //only try to be "smart" about the used compartments if there's a single origin or destination
    const cargoStops = order.stops.filter(isCargoStop);
    if (cargoStops.length === 1 && cargoStops[0].compartments) {
        return cargoStops[0].compartments;
    }
    return undefined;
}

export const orderToDefaultCargoType = (order) => {
    const distinctCargoTypeIds = order.stops.distinctBy(x => x.cargoTypeId).map(x => x.cargoTypeId).filter(Boolean);

    if (distinctCargoTypeIds.length === 1) {
        return distinctCargoTypeIds[0];
    }
    return null;
};

export const orderToMissingStopQuantity = (order) => {
    const originQuantities = order.stops.filter(filterStopByOrigin).sum(s => s.quantity);
    const destinationQuantities = order.stops.filter(filterStopByDestination).sum(s => s.quantity);

    return Math.abs(originQuantities - destinationQuantities) || null;
};

export const orderToMissingStopWeight = (order) => {
    const originWeights = order.stops.filter(filterStopByOrigin).sum(s => s.weight);
    const destinationWeights = order.stops.filter(filterStopByDestination).sum(s => s.weight);

    return Math.abs(originWeights - destinationWeights) || null;
}

const listToGroup = (list, keySelector) => {
    if (!Array.isArray(list)) {
        return {};
    }
    return list.reduce((acc, curr) => {
        const key = keySelector(curr);

        if (Object.prototype.hasOwnProperty.call(acc, key)) {
            acc[key].push(curr);
        } else {
            acc[key] = [curr];
        }
        return acc;
    }, {});
};

export const organizationLocationsToSiteTypeGroups = (organizationLocations) => {
    return listToGroup(organizationLocations, (organizationLocation) => L[`locationTypeValue${organizationLocation.organizationLocationTypeId}`]());
};

export const cargoTypesToCategoryGroups = (cargoTypes) => {
    return listToGroup(cargoTypes, (cargoType) => L[`cargoCategory${cargoType.category}`]());
};

export const locationTypesToCategoryGroups = (locationTypes) => {
    if (!Array.isArray(locationTypes)) {
        return {};
    }
    return locationTypes.reduce((acc, locationType) => {
        if (locationType.cargoTypeCategories?.length) {
            return locationType.cargoTypeCategories.reduce((acc2, cargoType) => {
                const key = L[`cargoCategory${cargoType}`]();

                if (acc2[key]) {
                    acc2[key].push(locationType);
                } else {
                    acc2[key] = [locationType];
                }

                return acc2;
            }, acc);
        } else {
            acc[L[`locationTypeValue${locationType.id}`]()] = [locationType];
            return acc;
        }
    }, {});
};

export const locationTypeToCargoTypeIdList = (stopType) => (locationType) => {
    if (locationType) {
        if (stopType === STOP_TYPES().Origin.key) {
            return locationType.shipsCargoTypeIds;
        } else if (stopType === STOP_TYPES().Destination.key) {
            return locationType.receivesCargoTypeIds;
        }
    }
    return [];
};


// given list of orders, find all common stop org location ids; returns array of organizationLocationIds that are common across all stops
export const ordersToCommonStopLocationIds = (rows) => {
    const stops = rows.map(x => x.stops.filter(s => [STOP_TYPES().Origin.key, STOP_TYPES().Destination.key].includes(s.type)).distinctBy(s => s.organizationLocationId)).flat();
    const counts = stops.reduce((acc, curr) => {
        acc[curr.organizationLocationId] = (acc[curr.organizationLocationId] + 1) || 1;
        return acc;
    }, {});

    return Object.entries(counts).filter(([, value]) => value === rows.length).map(([key]) => Number(key));
};

export const checklistToMaxSeverityLabel = (responsesBitmask) => {
    const maxSeverity = responsesBitmask & 8 || responsesBitmask & 4 || 2;

    return ({
        [CHECKLIST_RESPONSE_BITMASK.OK]: L.checklistResponseOK(),
        [CHECKLIST_RESPONSE_BITMASK.Minor]: L.checklistResponseMinor(),
        [CHECKLIST_RESPONSE_BITMASK.Major]: L.checklistResponseMajor()
    })[maxSeverity];
};

export const isValidCargoType = (cargoTypeIdList, cargoTypeId) => {
    return !cargoTypeId || !cargoTypeIdList || cargoTypeIdList.some(x => x === cargoTypeId);
};

export const orderCargoEligibleForCompartments = (cargoTypes, order) => {
    return order?.stops?.some(x => stopCargoEligibleForCompartments(cargoTypes, x)) || false;
}

export const stopCargoEligibleForCompartments = (cargoTypes, stop) => {
    if (!stop || !isCargoStop(stop) || !cargoTypes || cargoTypes.length <= 0) {
        return false;
    }

    const cargoCategory = cargoTypes?.find(x => x.id === stop?.cargoTypeId)?.category;
    return cargoCategory === 'Feed' || cargoCategory === 'Other';
};

// A "paired" stop is when one and only one other stop is found on the order with the same orderNumber 
// (or no orderNumber) and complimentary stop type. E.g. If source stop is Destination and other
// stop that has the same orderNumber is Origin.
export const getPairedStop = (load, stop) => {
    let pairedStop = null;
    const origins = load.stops.filter(x => filterStopByOrigin(x) && x.orderNumber === stop.orderNumber);
    const destinations = load.stops.filter(x => filterStopByDestination(x) && x.orderNumber === stop.orderNumber);

    if (origins.length === 1 && destinations.length === 1) {
        if (origins[0].sequence === stop.sequence) {
            pairedStop = destinations[0];
        } else if (destinations[0].sequence === stop.sequence) {
            pairedStop = origins[0];
        }
    }

    return pairedStop;
}

export const isValidStopArrivalTime = (stop, load) => {
    const i = load.stops.findIndex(s => s.sequence === stop.sequence);
    if (i === 0) return true;
    const currStop = load.stops[i];
    const prevStop = load.stops[i - 1];
    if (isAfter(currStop.arrivalTime, prevStop.arrivalTime)) return true;
    //Per Jeff, no longer consider sub-location when determining if the same location can have the same arrival time
    const isSameLocation = (currStop.organizationLocationId === prevStop.organizationLocationId);
    return isSame(currStop.arrivalTime, prevStop.arrivalTime) && isSameLocation;
}