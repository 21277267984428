import React from 'react';
import { getTransLocationType } from '../../../constants/constants';
import { locationTypesToCategoryGroups } from '../../../utils/data-mapping-utils';
import { MenuItem, TextField } from '@mui/material';
import { L } from 'harmony-language';
import PropTypes from 'prop-types';
import { useLocationTypes } from '../../../api/queries/use-location-types';
import ListSubheader from '@mui/material/ListSubheader';

export const SelectLocationType = (props) => {
    const { locationType, onChange, filters, ...rest } = props;
    const { data: locationTypes } = useLocationTypes(filters);

    const locationTypeGroups = React.useMemo(() => {
        const groupedLocationTypes = locationTypesToCategoryGroups(locationTypes);

        Object.keys(groupedLocationTypes).map(group => {
            groupedLocationTypes[group] = groupedLocationTypes[group].map(getTransLocationType).sortBy(c => c.label);
        });
        return groupedLocationTypes;
    }, [locationTypes]);

    const locationTypeList = Object.keys(locationTypeGroups).sort().map(group => {
        return locationTypeGroups[group].reduce((acc, locationType) => {
            return acc.concat(locationType);
        }, [{ id: group, label: group, isGroupHeader: true }]);
    }).flat();

    return (
        <TextField
            variant='standard'
            id='location-type'
            label={L.locationType()}
            select
            value={locationType?.id || ''}
            SelectProps={{
                MenuProps: {
                    MenuListProps: {
                        disablePadding: true
                    }
                }
            }}
            onChange={(e) => {
                const value = e.target.value;
                const locationType = locationTypes.find(l => l.id === value);

                onChange(locationType);
            }}
            {...rest}>
            {
                locationTypeList.map((l, i) => {

                    if(l.isGroupHeader){
                        return <ListSubheader style={{backgroundColor: 'white'}} key={i}>{l.label}</ListSubheader>
                    }

                    return (
                        <MenuItem key={`${l.id}-${i}`} value={l.id}>
                            {l.label}
                        </MenuItem>
                    );
                })
            }
        </TextField>
    );
};

SelectLocationType.propTypes = {
    locationType: PropTypes.object,
    onChange: PropTypes.func,
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    filters: PropTypes.shape({
        category: PropTypes.string,
        cargoTypeId: PropTypes.number,
        isBasedOnOrigin: PropTypes.bool,
    })
};
