import { IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Switch } from '@mui/material';
import React from 'react';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { OrganizationLocation, StandardRoute } from '../../../types';
import { toSiteDisplayName } from '../../../utils/data-mapping';
import EditIcon from '@mui/icons-material/Edit';


interface StandardRouteListProps {
    standardRoutes: StandardRoute[];
    shownRoutes: number[];
    setShownRoutes: (n: number[]) => void;
    handleEditOpen: (s: StandardRoute) => void;
}

export const StandardRouteList: React.FC<StandardRouteListProps> = (props) => {
    const { standardRoutes, shownRoutes, setShownRoutes, handleEditOpen } = props;
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);

    const handleShownRoutesChange = (standardRouteId: number) => {
        const currentIndex = shownRoutes.indexOf(standardRouteId);
        const newShownRoutes = [...shownRoutes];

        if (currentIndex === -1) {
            newShownRoutes.push(standardRouteId);
        } else {
            newShownRoutes.splice(currentIndex, 1);
        }

        setShownRoutes(newShownRoutes);
    }

    return (
        <List dense={true} sx={{ minWidth: '25vw' }}>
            {standardRoutes.map(standardRoute => {
                const origin = organizationLocations.find(x => x.id === standardRoute.originId);
                const dest = organizationLocations.find(x => x.id === standardRoute.destinationId);

                const originName = origin ? toSiteDisplayName(origin.name, origin.description) : '';
                const destName = dest ? toSiteDisplayName(dest.name, dest.description) : '';

                return (
                    <ListItem
                        key={standardRoute.id}
                        secondaryAction={
                            <IconButton
                                edge='end'
                                onClick={() => handleEditOpen(standardRoute)}
                            >
                                <EditIcon />
                            </IconButton>
                        }
                    >
                        <ListItemButton
                            onClick={() => handleShownRoutesChange(standardRoute.id)}
                        >
                            <ListItemIcon>
                                <Switch
                                    edge='start'
                                    checked={shownRoutes.includes(standardRoute.id)}
                                />
                            </ListItemIcon>
                            <ListItemText>{originName + ' -> ' + destName}</ListItemText>
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
};
