import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import { LoadSelection } from './utils/use-load-selection';
import { LoadTableColumn } from './load-table';
import { LoadSorting } from './utils/use-load-sorting';

interface LoadsTableHeaderProps {
    selection: LoadSelection;
    sorting: LoadSorting;
    columns: LoadTableColumn[];
    showSelection: boolean;
}

const LoadsTableHeader: React.FC<LoadsTableHeaderProps> = (props) => {
    const { selection, columns, sorting, showSelection } = props;
    return (
        <div className={'grid'}>
            {showSelection &&
                <div>
                    <Checkbox checked={selection.allSelected} indeterminate={selection.indeterminate}
                            onChange={selection.toggleAll}/>
                </div>
            }
            {columns.map((c, i) => {
                const isActive = sorting.activeColumnIndex === i;
                return (
                    <div key={i}
                         style={{ whiteSpace: 'nowrap', fontSize: '0.95rem', cursor: c.sort ? 'pointer' : 'auto' }}
                         onClick={() => c.sort && sorting.onSort(i)}>
                        {typeof c.header === 'string' ? c.header : <c.header/>}
                        {c.sort && <TableSortLabel
                            active={isActive}
                            direction={sorting.sortDirection}
                        />}
                    </div>
                );
            })}
            <div/>
        </div>
    );
};

export default LoadsTableHeader;
