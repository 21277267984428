import React from 'react';
import { useDroppable } from '@dnd-kit/core';

interface ProductionCardsDroppableProps {
    id: string;
    children: React.ReactNode;
}

export const ProductionCardsDroppable: React.FC<ProductionCardsDroppableProps> = (props) => {
    const { id, children } = props;
    const { setNodeRef } = useDroppable({
        id: id,
    })

    return (
        <div
            ref={setNodeRef}
            style={{ flex: 1 }}
        >
            {children}
        </div>
    );
};
