import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { SearchBar } from '../shared/search-bar';
import { DriverAssignmentCard } from './driver-assignment-card';
import { L } from 'harmony-language';
import Typography from '@mui/material/Typography';
import Skeleton from "@mui/material/Skeleton";
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { useAssignDriverMutation } from '../../api/mutations/use-assign-driver-mutation';
import { OrgQueryKeys } from '../../api/config';
import { useCarrier, useFeatures } from '../user/selectors/use-permissions';
import { Features } from 'harmony-constants';
import { useResourceDowntimes } from '../downtime/use-resource-downtimes';
import { useGetDowntimeConflict } from '../shared/resource-conflicts/use-downtime-conflict';
import { useGetBookingConflict } from '../shared/resource-conflicts/use-booking-conflict';
import { useResourceBookings } from '../bookings/use-resource-bookings';

export const DriverAssignment = (props) => {
    const { loads, onAssign } = props;
    const [searchText, setSearchText] = React.useState('');
    const { data, isLoading, organizationId } = useOrganizationQuery(OrgQueryKeys.drivers);
    const { mutate: assignDriver } = useAssignDriverMutation();
    const currentUserIsCarrier = useCarrier();
    const hasDowntimeAccess = useFeatures(Features.Downtime);
    const { data: allBookings } = useResourceBookings();
    const { data: allDowntimes = [] } = useResourceDowntimes({ enabled: hasDowntimeAccess });

    const drivers = data?.filter(x => x.organizationId === organizationId) || [];
    const filterDrivers = (driver) => {
        const driverName = driver.name.toLowerCase();

        return (!searchText || driverName.includes(searchText.toLowerCase()));
    };
    const filteredDrivers = drivers.filter(filterDrivers);

    const driverConflicts = React.useMemo(() => {
        return filteredDrivers.map(driver => {
            const driverDowntimeConflicts = [];
            const driverBookingConflicts = [];
            const userResource = {
                id: driver.id,
                type: 'user',
                stop: undefined,
            }

            loads.map(load => {
                const downtimeConflict = useGetDowntimeConflict(load, allDowntimes, userResource);
                const bookingConflict = useGetBookingConflict(load, allBookings, userResource);

                if (downtimeConflict.conflict) {
                    driverDowntimeConflicts.push(downtimeConflict.conflict);
                }
                if (bookingConflict.conflict) {
                    driverBookingConflicts.push(bookingConflict.conflict);
                }
            });

            return {
                driverId: driver.id,
                downtimeConflicts: driverDowntimeConflicts,
                bookingConflicts: driverBookingConflicts,
            }
        });
    }, [loads, filteredDrivers]);

    const renderDriver = (driver, i) => (
        <ListItem key={i}>
            <DriverAssignmentCard assignDriver={(driver) => {
                assignDriver({ loads, driver, currentUserIsCarrier });
                onAssign(driver);
            }} driver={driver} driverConflicts={driverConflicts} />
        </ListItem>
    );

    const renderSkeleton = (driver, i) => (
        <ListItem key={i}>
            <Skeleton variant='rectangular' width='100%' />
        </ListItem>
    );

    const getAvailableText = () => {
        return filteredDrivers.length === 1 ? L.driverAvailable() : L.driversAvailable(filteredDrivers.length);
    };

    return (
        <div data-testid='driver-assignment'>
            <div>
                <SearchBar text={searchText} setText={setSearchText} />
                <Typography variant='h6'>{getAvailableText()}</Typography>
            </div>
            <List>
                {isLoading ? [...Array(5).keys()].map(renderSkeleton) : filteredDrivers.map(renderDriver)}
            </List>
        </div>
    );
};

DriverAssignment.propTypes = {
    loads: PropTypes.array,
    onAssign: PropTypes.func
};
