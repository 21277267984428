import React from 'react';
import { Load } from '../../../../types';
import { LoadTableColumn } from '../load-table';

export type LoadSorting = {
    sortedLoads: Load[];
    activeColumnIndex: number;
    sortDirection: SortDirection;
    onSort: (columnIndex: number) => void;
}

export type SortDirection = 'asc' | 'desc';

export const useLoadSorting = (filteredData: Load[], columns: LoadTableColumn[]): LoadSorting => {
    const defaultIndex = columns.findIndex(x => Boolean(x.defaultSort));
    const defaultSortDirection = columns[defaultIndex].defaultDirection || 'asc';
    const [activeColumnIndex, setActiveColumnIndex] = React.useState<number>(defaultIndex);
    const [sortDirection, setSortDirection] = React.useState<SortDirection>(defaultSortDirection)

    const onSort = (columnIndex: number) => {
        if (activeColumnIndex === columnIndex) {
            if (sortDirection === 'desc') {
                setSortDirection('asc');
            } else {
                setSortDirection('desc');
            }
        } else {
            setActiveColumnIndex(columnIndex);
            setSortDirection('asc');
        }
    };

    const sortedLoads = React.useMemo(() => {
        return [...filteredData].sort((a, b) => {
            const sortF = columns[activeColumnIndex]?.sort;
            if (!sortF) {
                return 0;
            }
            return sortDirection === 'asc' ? sortF(a, b) : sortF(b, a);
        });
    }, [filteredData, activeColumnIndex, sortDirection])

    return { sortedLoads, activeColumnIndex, sortDirection, onSort };
};
