import React from 'react';
import { L } from 'harmony-language';
import { Button } from '@mui/material';

type DeliveryLogRefetchButtonProps = {
    refetchFunc: () => void;
}

export const DeliveryLogRefetchButton: React.FC<DeliveryLogRefetchButtonProps> = (props) => {
    const { refetchFunc } = props;

    return (
        <Button
            variant='contained'
            color='primary'
            onClick={() => refetchFunc()}>
                {L.refresh()}
        </Button>
    )
};
