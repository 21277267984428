import React from 'react';
import { L } from 'harmony-language';
import Send from '@mui/icons-material/Send';
import { ContentHeader } from '../shared/containers/content-header';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import { Content } from '../shared/containers/content';
import Delete from '@mui/icons-material/Delete';
import Merge from '@mui/icons-material/MergeType';
import ClearAll from '@mui/icons-material/ClearAll';
import { useDraftsColumns } from './use-drafts-columns';
import { usePermissions } from '../user/selectors/use-permissions';
import { isDraftValid } from './utils/validate-drafts';
import { BulkDraftModal } from './bulk-draft-modal';
import { useBoolean } from '../shared/hooks/use-boolean';
import { useDrafts } from '../../api/queries/use-drafts';
import { QueryKeys } from '../../api/config';
import { ModalDialog } from '../shared/containers/modal-dialog';
import { CASCADING_STOP_SCHEDULING, MERGE_DRAFTS } from '../../permissions/features';
import { CascadeStopTimes } from './cascade/cascade-stop-times';
import { ordersToCommonStopLocationIds } from '../../utils/data-mapping-utils';
import { PublishDraftsModal } from './publish-drafts-modal';
import { DeleteLoadsModal } from './delete-loads-modal';
import { MergeDrafts } from './merge/merge-drafts';
import { useLoadsTableOptions } from '../shared/load-table/utils/use-loads-table-options';
import { LoadTable, LoadTableAction } from '../shared/load-table/load-table';
import { Load } from '../../types';
import Typography from '@mui/material/Typography';
import { PermissionType } from 'harmony-constants';
import { useWeights } from '../shared/hooks/use-weights';
import { useTravelTimesQuery } from '../travel-times/use-travel-times';
import { useDraftsKey } from '../../api/config-hooks';
import AutoFixHigh from '@mui/icons-material/AutoFixHigh';
import { BulkModify } from './bulk-modify/bulk-modify';

export const Drafts = () => {
    const canCreateOrder = usePermissions(PermissionType.CreateOrder);
    const [isBulkCreationOpen, openBulkCreation, closeBulkCreation] = useBoolean(false);
    const [deleteRecords, setDeleteRecords] = React.useState<Load[]>([]);
    const [publishRecords, setPublishRecords] = React.useState<Load[]>([]);
    const [cascadeRecords, setCascadeRecords] = React.useState<Load[]>([]);
    const [mergeRecords, setMergeRecords] = React.useState<Load[]>([]);
    const [bulkModifyRecords, setBulkModifyRecords] = React.useState<Load[]>([]);
    const [unselect, setUnselectFn] = React.useState<(() => void) | null>(null);
    const { convertFromGrams } = useWeights();
    const draftTableOptions = useLoadsTableOptions(QueryKeys.drafts);
    const { columns, stopColumns, isLoading: isColumnsLoading } = useDraftsColumns();

    const actions: LoadTableAction[] = React.useMemo(
        () => [
            {
                id: 'bulkModify',
                label: () => L.bulkModify(),
                icon: <AutoFixHigh />,
                action: async (rows: Load[]) => {
                    setBulkModifyRecords(rows);
                },
            },
            {
                id: 'mergeDrafts',
                label: () => L.mergeDrafts(),
                icon: <Merge style={{ transform: 'rotate(90deg)' }} />,
                action: async (rows: Load[], unselectFn?: () => void) => {
                    setMergeRecords(rows);
                    setUnselectFn(() => unselectFn);
                },
                disabledTooltipText: L.mergeDraftsDisabledTooltipText(),
                enabledTooltipText: L.mergeDraftsEnabledTooltipText(),
                features: [MERGE_DRAFTS],
                condition: (rows: Load[]) => Boolean(rows.length >= 2)
            },
            {
                id: 'cascadeTimes',
                label: () => L.cascadeStopTimes(),
                icon: <ClearAll style={{ transform: 'scaleX(-1)' }} />,
                action: async (rows: Load[]) => {
                    setCascadeRecords(rows);
                },
                disabledTooltipText: L.cascadeTimesDisabledTooltipText(),
                enabledTooltipText: L.cascadeTimesEnabledTooltipText(),
                features: [CASCADING_STOP_SCHEDULING],
                condition: (rows: Load[]) => Boolean(rows.length > 1 && ordersToCommonStopLocationIds(rows).length)
            }, {
                id: 'publish',
                label: () => L.publishTo(),
                icon: <Send />,
                disabledTooltipText: L.publishDisabledTooltipText(),
                action: async (rows: Load[], unselectFn?: () => void) => {
                    setPublishRecords(rows);
                    setUnselectFn(() => unselectFn);
                },
                condition: (rows: Load[]) => rows.every(x => isDraftValid(x, convertFromGrams)),
            },
            {
                id: 'delete',
                label: () => L.delete(),
                icon: <Delete />,
                permissions: [PermissionType.DeleteOrder],
                action: async (rows: Load[], unselectFn?: () => void) => {
                    setDeleteRecords(rows);
                    setUnselectFn(() => unselectFn);
                }
            },
        ], []);

    const { drafts, isLoading, isFetching, refetch } = useDrafts();
    useTravelTimesQuery(isFetching);
    const draftsKey = useDraftsKey();

    return (
        <>
            <ContentHeader>
                <Typography variant='h5'>{L.drafts()}</Typography>
                {canCreateOrder &&
                    <Button variant='contained' color='primary' onClick={openBulkCreation}><Add /> {L.createDrafts()}
                    </Button>}
            </ContentHeader>
            <BulkDraftModal isOpen={isBulkCreationOpen} onClose={closeBulkCreation} />
            <DeleteLoadsModal records={deleteRecords} unselectFn={unselect} onResultCallback={() => setDeleteRecords([])} queryKey={draftsKey} />
            <PublishDraftsModal records={publishRecords} unselectFn={unselect} onResultCallback={() => setPublishRecords([])} />
            <ModalDialog title={L.mergeDrafts()} onClose={() => setMergeRecords([])} open={mergeRecords.length > 0}>
                <MergeDrafts drafts={mergeRecords} onSubmit={() => {
                    unselect?.();
                    setMergeRecords([]);
                }} />
            </ModalDialog>
            <ModalDialog title={L.cascadeStopTimes()} onClose={() => setCascadeRecords([])} open={cascadeRecords?.length > 0}>
                <CascadeStopTimes drafts={cascadeRecords} onSubmit={() => {
                    setCascadeRecords([]);
                }} />
            </ModalDialog>
            <ModalDialog
                title={L.bulkModify()}
                onClose={() => setBulkModifyRecords([])}
                open={bulkModifyRecords.length > 0}
            >
                <BulkModify
                    drafts={bulkModifyRecords}
                    onSubmit={() => {
                        setBulkModifyRecords([])
                    }}
                />
            </ModalDialog>
            <Content>
                <LoadTable
                    id='drafts'
                    isLoading={isLoading || isFetching || isColumnsLoading}
                    columns={columns}
                    stopColumns={stopColumns}
                    data={drafts || []}
                    actions={actions}
                    isEditable={true}
                    options={draftTableOptions}
                    refetch={refetch}
                />
            </Content>
        </>
    );
};
