import { createColumnHelper } from "@tanstack/react-table";
import { L } from "harmony-language";
import React from "react";
import { type ChecklistTemplate } from "../../../types";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

export function useChecklistTemplateColumns() {
    const columnHelper = createColumnHelper<ChecklistTemplate>();

    return React.useMemo(() => (
        [
            columnHelper.accessor('name', {
                header: L.name(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false
            }),
            columnHelper.accessor('default', {
                header: L.default(),
                cell: (info) => info.getValue() && <CheckBoxIcon color="primary"/>,
                enableColumnFilter: false
            }),
        ]
    ), []);
}