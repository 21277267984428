import React from 'react';
import { L } from 'harmony-language';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { createColumnHelper } from '@tanstack/react-table';
import { LoadingTeam, User } from '../../../types';

interface LoadingTeamUserListProps {
    users: User[];
}

const LoadingTeamUserList: React.FC<LoadingTeamUserListProps> = (props) => {
    const { users } = props;
    return (
        <List dense disablePadding>
            {users.map(user => {
                return (
                    <ListItem dense key={user.id} disableGutters>
                        <ListItemText primary={`${user.firstName} ${user.lastName}`} secondary={`${user.email}`}/>
                    </ListItem>
                );
            })}
        </List>
    );
};

export const useLoadingTeamColumns = () => {
    const columnHelper = createColumnHelper<LoadingTeam>();

    const columns = React.useMemo(() => {
        const c = [
            columnHelper.accessor('name', {
                header: L.name(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                size: 150,
            }),
            columnHelper.accessor('email', {
                header: L.email(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                size: 150,
            }),
            columnHelper.accessor('users', {
                header: L.loadingTeamMembers(),
                cell: (info) => <LoadingTeamUserList users={info.getValue()} />,
                enableColumnFilter: false,
                size: 300,
            }),
        ];

        return c;
    }, []);

    return columns;
};
