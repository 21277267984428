import React from 'react';
import { getCurrentUnitSystem, L, UNITS } from 'harmony-language';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { getTransCargoType } from '../../../constants/constants';
import { kgToPounds } from '../../../utils/unit-conversion';
import { useTier } from '../../user/selectors/use-permissions';
import { useTheme } from '@mui/material/styles';
import { CustomerTier } from 'harmony-constants';
import { createColumnHelper } from '@tanstack/react-table';
import { Trailer } from '../../../types';

interface TrailerCargoTypeListProps {
    row: any;
    trailerCargoTypes: any[];
}

const TrailerCargoTypeList: React.FC<TrailerCargoTypeListProps> = (props) => {
    const { row, trailerCargoTypes } = props;
    const rowEnabled = row.original.enabled;
    const theme = useTheme();

    return (
        <List dense disablePadding>
            {trailerCargoTypes?.map(trailerCargoType => {
                return (
                    <ListItem dense key={trailerCargoType.id} disableGutters>
                        <ListItemText
                            primary={`${L.cargoType()}: ${getTransCargoType({ id: trailerCargoType.cargoTypeId }).label}`}
                            // @ts-ignore
                            secondary={`${L[`cargoCompartmentLabel${trailerCargoType.cargoTypeId}`]()}: ${trailerCargoType.compartmentCount}`}
                            // @ts-ignore
                            secondaryTypographyProps={{ style: { color: rowEnabled ? null : theme.palette.grey[300] }}} />
                    </ListItem>
                );
            })}
        </List>
    );
};

interface TrailerCargoWeightProps {
    maxCargoWeight: number;
}

const TrailerCargoWeight: React.FC<TrailerCargoWeightProps> = (props) => {
    const { maxCargoWeight } = props;
    const unitsKey = getCurrentUnitSystem() === UNITS.Metric ? 'unitsMetricWeightAbbr' : 'unitsImperialWeightAbbr';
    const unitsLabel = L[unitsKey]();
    return (
        <span>
            {Math.round(getCurrentUnitSystem() === UNITS.Metric ? maxCargoWeight : kgToPounds(maxCargoWeight))} {unitsLabel}
        </span>
    );
};

export const useTrailerColumns = () => {
    const isStandard = useTier(CustomerTier.Standard);
    const columnHelper = createColumnHelper<Trailer>();

    const columns = React.useMemo(() => {
        const standardCols = () => {
            return [
                columnHelper.accessor('maxCargoWeight', {
                    header: () => L.maxCargoWeight(),
                    cell: (info) => <TrailerCargoWeight maxCargoWeight={info.getValue()} />,
                    enableColumnFilter: false,
                    enableSorting: false,
                }),
                columnHelper.accessor('trailerCargoTypes', {
                    header: () => L.cargo(),
                    cell: (info) => <TrailerCargoTypeList row={info.row} trailerCargoTypes={info.getValue()} />,
                    enableColumnFilter: false,
                    enableSorting: false,
                }),
                columnHelper.accessor('contract', {
                    header: () => L.contract(),
                    cell: (info) => info.getValue() ? L.yes() : L.no(),
                    enableColumnFilter: false,
                    enableSorting: false,
                }),
            ]
        }

        const standardColumns = isStandard ? standardCols() : [];

        const c = [
            columnHelper.accessor('userDisplayName', {
                header: L.name(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false
            }),
            columnHelper.accessor('licensePlate', {
                header: L.licensePlate(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false
            })
        ];

        return [...c, ...standardColumns];
    }, []);

    return columns;
}
