import { enqueueSnackbar } from 'notistack';

interface ApiError {
    type: string;
    data: any;
    message?: string;
}

export const errorOccurred = (error: ApiError) => {
    enqueueSnackbar(`${error.type}: ${error.message}`, { variant: 'error' });
};
