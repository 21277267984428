import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { L } from 'harmony-language';
import { Load } from '../../../types';
import { LoadTableToolbar } from './load-table-toolbar';
import { LoadProvider } from '../load-context';
import { LoadTableRow } from './load-table-row';
import { useLoadFiltering } from './utils/use-load-filtering';
import { useLoadSearching } from './utils/use-load-searching';
import { useLoadSelection } from './utils/use-load-selection';
import Skeleton from "@mui/material/Skeleton";
import TablePagination from '@mui/material/TablePagination';
import { useLoadSorting, SortDirection } from './utils/use-load-sorting';
import { useLoadPaging } from './utils/use-load-paging';
import { TablePaginationActions } from '../table/components/table-pagination-actions';
import { Validation } from '../inputs/validated-text-field';
import LoadsTableHeader from './loads-table-header';
import { OrderGroupSummary } from './order-group-summary';

const draftsGridTemplateColumns = '45px minmax(0, 7fr) 7fr 9fr 7fr 9fr 10fr 1.5fr repeat(3, 7fr) 4fr 55px';
const loadsGridTemplateColumns = '45px 7fr 7fr 10fr 7fr 7fr 10fr 10fr repeat(3, 7fr) 5fr 55px';

interface StyledTableProps {
    id: 'drafts' | 'loads';
}

const StyledTable = styled(Table)<StyledTableProps>(({ id, theme }) => ({
    '& .grid': {
        display: 'grid',
        fontSize: '.875rem',
        gridTemplateRows: '1fr',
        borderBottom: '1px solid #e0e0e0',
        gridTemplateColumns: id === 'drafts' ? draftsGridTemplateColumns : loadsGridTemplateColumns,
    },
    '& > div.skeleton': {
        paddingLeft: '1rem',
        height: '3rem',
        alignItems: 'center'
    },
    '& .grid:nth-child(2n+2)': {
        backgroundColor: theme.palette.grey['100']
    },
    '& .grid > div': {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: '2px 5px',
    },
    ...(id === 'drafts' && {
        '& .isSaving': {
            filter: 'blur(.9px)',
            opacity: '.7'
        },
        '& .isSuccess': {
            boxShadow: `inset 0 0 2px 5px ${theme.palette.success.main}`,
            animation: 'fadeOutSuccess 3s ease-in 1 forwards',
        },
        '& .isError': {
            boxShadow: `inset 0 0 2px 5px ${theme.palette.error.main}`,
            animation: 'fadeOutError 3s ease-in 1 forwards',
        }
    })
}));

export type LoadTableAction = {
    id: string;
    action: (loads: Load[], callback?: () => void) => Promise<void>;
    permissions?: string[];
    icon: React.ReactNode;
    features?: string[];
    condition?: (loads: Load[]) => boolean;
    enabledTooltipText?: string;
    disabledTooltipText?: string;
    label: (loads?: Load[]) => string;
    displayBool?: boolean;
}

export type LoadTableColumn = {
    header: React.ElementType | string;
    render: (args?: any) => JSX.Element | null;
    sort?: (a: Load, b: Load) => number;
    defaultSort?: boolean;
    required?: boolean;
    validations?: Validation[];
    defaultDirection?: SortDirection;
}

interface LoadTableProps {
    id: 'drafts' | 'loads';
    isLoading: boolean;
    data: Load[];
    options: {
        tableKey: string,
        onlyCustomDateFilter?: boolean
        disableConflicts?: boolean
    },
    columns: LoadTableColumn[],
    stopColumns: LoadTableColumn[],
    actions?: LoadTableAction[],
    isEditable?: boolean;
    clearSelection?: boolean;
    refetch: () => void;
}

export const LoadTable: React.FC<LoadTableProps> = (props) => {
    const { id, isLoading, isEditable, data, columns, actions = [], stopColumns, options, clearSelection, refetch } = props;
    const filtering = useLoadFiltering(data);
    const searching = useLoadSearching(filtering.filteredData);
    const selection = useLoadSelection(searching.filteredData);
    const sorting = useLoadSorting(searching.filteredData, columns);
    const paging = useLoadPaging(sorting.sortedLoads, id);

    if (clearSelection && selection.selectedLoads.length > 0) {
        selection.clearAll();
    }

    return (
        <Paper>
            <LoadTableToolbar
                actions={actions}
                filtering={filtering}
                searching={searching}
                selection={selection}
                isLoading={isLoading}
                tableKey={options.tableKey}
                onlyCustomDateFilter={options.onlyCustomDateFilter}
                data={data}
                refetch={refetch}
            />
            {id === 'drafts' && <OrderGroupSummary loads={data} selectedLoads={selection.selectedLoads} tableId={id} isLoading={isLoading}></OrderGroupSummary>}
            <StyledTable id={id} size={'small'} component='div'>
                <LoadsTableHeader columns={columns} sorting={sorting} selection={selection} showSelection={true} />
                {/* If we have an update function then we need to show save button */}
                {/* {updateData && <div />} */}
                {isLoading ?
                    Array.from(Array(paging.rowsPerPage), (x, i) => i).map((x) => {
                        return (
                            <div className={'grid skeleton'} key={x}>
                                <Skeleton style={{ marginBottom: '5px', gridColumn: '1 / -1' }} width={'100%'} height={'2rem'} />
                            </div>
                        );
                    }) :
                    paging.pagedLoads.length > 0 ?
                        paging.pagedLoads.map(load => {
                            return (
                                <LoadProvider key={load.id} load={load} mutation={isEditable ? (load.id ? 'EditDraft' : 'AddDraft') : undefined} disableConflicts={options.disableConflicts}>
                                    <LoadTableRow
                                        toggle={() => selection.toggle(load)}
                                        isSelected={selection.isSelected(load)} columns={columns}
                                        stopColumns={stopColumns}
                                        showSelection={true} />
                                </LoadProvider>
                            )
                        }) :
                        <div style={{ display: 'flex', height: '10rem', justifyContent: 'center', alignItems: 'center' }}>
                            <span>{L.noRecords()}</span>
                        </div>

                }
                <div className={'grid'}>
                    <TablePagination
                        component={'div'}
                        sx={{ gridColumn: '1 / -1', justifyContent: 'flex-end' }}
                        count={sorting.sortedLoads.length}
                        page={paging.pageNumber}
                        rowsPerPage={paging.rowsPerPage}
                        onPageChange={paging.onPageChange}
                        onRowsPerPageChange={paging.onRowsPerPageChange}
                        rowsPerPageOptions={paging.rowsPerPageOptions}
                        ActionsComponent={TablePaginationActions}
                    />
                </div>
            </StyledTable>
        </Paper>
    );
};
