import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { useTier } from '../../components/user/selectors/use-permissions';
import { CustomerTier } from 'harmony-constants';
import { useCompanyId } from '../../app/agistics-context';

interface QueryOptions<T> extends UseQueryOptions<T> {
    orgId?: number;
    disableOnFreemium?: boolean;
    alternateKey?: boolean;
}
// Organization queries have an infinite stale time meaning they won't go stale.
// This means this query will only be called once unless the page is refreshed OR it has been removed from the cache (~5 mins)
export const useOrganizationQuery = <T>(
    key: string,
    options: QueryOptions<T> = {}
): UseQueryResult<T> & { key: string; organizationId: number } => {
    const { orgId, ...rest } = options;
    const isFreemium = useTier(CustomerTier.Freemium);
    const companyId = useCompanyId();

    const id = orgId || companyId;

    const queryKey = `/organizations/${id}${key}`;

    const enabled = Boolean(id) && (options.disableOnFreemium ? !isFreemium : true);

    return {
        ...useQuery({
            queryKey: [queryKey],
            staleTime: Infinity,
            enabled: enabled,
            ...rest
        }),
        key: queryKey,
        organizationId: id
    };
};
