import { MenuProps } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import { L, type DictionaryKey } from 'harmony-language';
import React from 'react';
import { QueryKeys } from '../../../api/config';
import { useStaticQuery } from '../../../api/queries/use-static-query';
import { type SiteIssueType, type CargoType } from '../../../types';

interface SelectSiteIssueKeysProps {
    cargoTypes: CargoType[];
    siteIssueKeys: string[];
    onChange: (siteIssueKeys: string[]) => void;
}

const menuProps = {
    variant: "menu",
    getContentAnchorEl: null
} as Partial<MenuProps>;

export const SelectSiteIssueKeys = (props: SelectSiteIssueKeysProps) => {
    const { cargoTypes, siteIssueKeys, onChange } = props;
    const siteIssueTypes = useStaticQuery(QueryKeys.siteIssueTypes).data as SiteIssueType[] | undefined;
    const siteIssuesForOrg = siteIssueTypes?.filter(siteIssueType => cargoTypes.some(cargoType => siteIssueType.cargoTypeIds?.includes(cargoType.id)))
    
    return (
        <FormControl variant='standard'>
            <InputLabel id='site-issues-label'>{L.siteIssues()}</InputLabel>
            <Select
                variant='standard'
                multiple
                value={siteIssueKeys || []}
                onChange={(e: SelectChangeEvent<string[]>) => {
                    onChange(e.target.value as string[]);
                }}
                renderValue={(selected) => (
                    <div style={{ display: 'flex', flexWrap: 'wrap'}}>
                        {selected.map((x) => (
                            <Chip key={x} label={L[x as DictionaryKey]()} style={{ margin: 2 }} />
                        ))}
                    </div>
                )}
                input={<Input/>}
                MenuProps={menuProps}>
                {siteIssuesForOrg?.map((siteIssueType) => (
                    <MenuItem key={siteIssueType.key} value={siteIssueType.key}>
                        <Checkbox checked={siteIssueKeys?.indexOf(siteIssueType.key) > -1}/>
                        <ListItemText primary={L[siteIssueType.key as DictionaryKey]()}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
