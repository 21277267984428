import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { L } from 'harmony-language';
import { EditLoadingTeamUsers } from './edit-loading-team-users';
import { ResourceEditCreateContainer } from '../../../shared/containers/resource-edit-create-container';

export const EditCreateLoadingTeam = (props) => {
    const { existingLoadingTeam, onSubmit } = props;
    const [loadingTeam, setLoadingTeam] = React.useState({});

    React.useEffect(() => {
        if (existingLoadingTeam) {
            setLoadingTeam(existingLoadingTeam);
        }
    }, []);

    const submitForm = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        onSubmit(loadingTeam);
    };

    return (
        <ResourceEditCreateContainer width='25vw'>
            <form id='edit-create-loading-team-form' name='edit-create-loading-team-form' onSubmit={submitForm}>
                <TextField
                    variant='standard'
                    label={L.name()}
                    required={true}
                    value={loadingTeam.name || ''}
                    onChange={(e) => {
                        setLoadingTeam({ ...loadingTeam, name: e.target.value });
                    }}
                    style={{
                        paddingTop: '0.25rem',
                        paddingBottom: '0.25rem',
                        width: '100%'
                    }} />
                <TextField
                    variant='standard'
                    label={L.email()}
                    value={loadingTeam.email || ''}
                    onChange={(e) => {
                        setLoadingTeam({ ...loadingTeam, email: e.target.value });
                    }}
                    style={{
                        paddingTop: '0.25rem',
                        paddingBottom: '0.25rem',
                        width: '100%'
                    }} />
                <EditLoadingTeamUsers users={loadingTeam.users}
                    onChange={(newLoadingTeamUsers) => {
                        setLoadingTeam({ ...loadingTeam, users: newLoadingTeamUsers });
                    }}/>
                <div
                    style={{
                        paddingTop: '1rem',
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    <Button type='submit'
                        title='Save'
                        variant='contained'
                        color='primary'
                    >{existingLoadingTeam ? L.updateLoadingTeam() : L.createLoadingTeam()}</Button>
                </div>
            </form>
        </ResourceEditCreateContainer>
    );
};

EditCreateLoadingTeam.propTypes = {
    existingLoadingTeam: PropTypes.object,
    onSubmit: PropTypes.func
};
