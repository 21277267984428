import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import '../../../../scss/bulk-creation.scss';
import { BulkEntryTableBody } from './bulk-entry-table-body';
import { BulkEntryTableHeaders } from './bulk-entry-table-headers';
import { BulkEntryTitleBar } from './bulk-entry-title-bar';
import {
    createProjection,
    currentProjectionsBySite,
    initializeLoads,
    lastWeekProjectionsBySite
} from '../projections-utils';
import { L } from 'harmony-language';
import { add, localDateShort, subtract, utc } from '../../../../utils/date-time-utils';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { useQuery } from '@tanstack/react-query';
import { DynamicQueryKeys, OrgQueryKeys } from '../../../../api/config';
import { LoadableComponent } from '../../../shared/loadable-component';

const getQueryKey = (weekOf, weekOffset = 0) => {
    const sunday = utc(subtract(weekOffset, 'weeks', weekOf));
    const saturday = utc(add(6, 'days', sunday));

    return DynamicQueryKeys.projections(sunday, saturday);
};

export const BulkEntry = (props) => {
    const { bulkConfiguration, loads, setLoads, previewLoads } = props;

    const { data: locations } = useOrganizationQuery(OrgQueryKeys.locations);
    const siteList = React.useMemo(() => locations?.filter(companyLocation => bulkConfiguration.siteType.id === companyLocation.orgLocationType.id).sortBy(companyLocation => companyLocation.name) || [], [locations]);

    const {
        data: currentProjections,
        isLoading: loadingCurrentProjections
    } = useQuery([getQueryKey(bulkConfiguration.week)]);
    const {
        data: lastWeeksProjections,
        isLoading: loadingLastWeeksProjections
    } = useQuery([getQueryKey(bulkConfiguration.week, 1)]);

    const [siteProjections, setSiteProjections] = React.useState([]);

    React.useEffect(() => {
        setLoads((loads) => {
            return !loads?.length ? initializeLoads(siteList.length, '') : loads;
        });
    }, [setLoads, siteList]);

    React.useEffect(() => {
        const newSiteProjections = siteList.map(site => ({
            id: site.id,
            name: site.name,
            currentProjections: currentProjectionsBySite(currentProjections, site.id, bulkConfiguration.cargoType.id),
            lastWeeksProjections: lastWeekProjectionsBySite(lastWeeksProjections, site.id, bulkConfiguration.cargoType.id)
        }));

        setSiteProjections(newSiteProjections);
    }, [siteList, currentProjections, lastWeeksProjections, bulkConfiguration]);

    const loadQtyChange = (event, siteIndex, dayIndex) => {
        loads[siteIndex][dayIndex] = parseInt(event.target.value) || 0;
        setLoads([...loads]);
    };

    const onCopyLastWeeksProjections = (siteIndex) => {
        if (siteIndex !== null) {
            loads[siteIndex] = [...siteProjections[siteIndex].lastWeeksProjections];
            setLoads([...loads]);
        } else {
            setLoads(siteProjections.map(site => [...site.lastWeeksProjections]));
        }
    };

    const loading = loadingCurrentProjections || loadingLastWeeksProjections;

    return (
        <div className='bulk-entry' data-testid='bulk-entry'>
            <h2>{L.bulkEntryHeading1(localDateShort(bulkConfiguration.week))}
                <small>{L.bulkEntryHeading2(bulkConfiguration.loadSize)}</small>
            </h2>
            <Paper>
                <BulkEntryTitleBar siteType={bulkConfiguration.siteType} loads={loads}
                    previewLoads={() => previewLoads(createProjection(loads, siteList, bulkConfiguration))}/>
                <div className='bulk-entry-table'>
                    <LoadableComponent isLoading={loading}>
                        <Table>
                            <BulkEntryTableHeaders weekOf={bulkConfiguration.week}
                                onCopyLastWeeksProjections={onCopyLastWeeksProjections}
                                siteProjections={siteProjections}
                                isOriginBased={bulkConfiguration.checked}/>
                            <BulkEntryTableBody loadQtyChange={loadQtyChange}
                                weekOf={bulkConfiguration.week}
                                loads={loads}
                                onCopyLastWeeksProjections={onCopyLastWeeksProjections}
                                siteProjections={siteProjections}/>
                        </Table>
                    </LoadableComponent>
                </div>
            </Paper>
        </div>
    );
};

BulkEntry.propTypes = {
    bulkConfiguration: PropTypes.object,
    loads: PropTypes.array,
    setLoads: PropTypes.func,
    siteList: PropTypes.array,
    previewLoads: PropTypes.func
};
