import React, { CSSProperties } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { L } from 'harmony-language';
import moment from 'moment-timezone';
import { OnTimeReportMode } from '../../reports/on-time/on-time-report';

export interface EnableDatesConfig {
    mode: OnTimeReportMode;
    val: 'start' | 'end';
}

interface AgisticsDatePickerProps {
    value: string;
    onChange: (date: string) => void;
    label: string;
    transformTime?: 'start' | 'end',
    disabled?: boolean;
    style?: CSSProperties;
    enabledDatesConfig?: EnableDatesConfig;
}

export const AgisticsDatePicker: React.FC<AgisticsDatePickerProps> = (props) => {
    const { value, onChange, transformTime = 'start', label, disabled, style, enabledDatesConfig } = props

    return (
        <DatePicker
            label={label}
            value={moment(value)}
            onChange={(momentObj) => {
                if (momentObj) {
                    if (transformTime === 'start') {
                        onChange(momentObj.startOf('day').toISOString());
                    } else if (transformTime === 'end') {
                        onChange(momentObj.endOf('day').toISOString());
                    }
                }
            }}
            disabled={disabled}
            format={'YYYY-MM-DD'}
            slotProps={
                {
                    actionBar: {
                        actions: ['today', 'cancel', 'accept'],
                    },
                    openPickerIcon: { fontSize: 'normal' },
                }
            }
            sx={style}
            localeText={{
                okButtonLabel: L.ok(),
                cancelButtonLabel: L.cancel(),
                todayButtonLabel: L.today(),
            }}
            shouldDisableDate={enabledDatesConfig !== undefined ? (val) => {
                if (enabledDatesConfig.mode === OnTimeReportMode.day) {
                    return false;
                } else if (enabledDatesConfig.mode === OnTimeReportMode.week) {
                    const weekday = val.weekday();
                    if (enabledDatesConfig.val === 'start') {
                        return weekday !== 0;
                    } else {
                        return weekday !== 6;
                    }
                } else {
                    const day = val.date();
                    if (enabledDatesConfig.val === 'start') {
                        return day !== 1;
                    } else {
                        const daysInMonth = val.daysInMonth();
                        return day !== daysInMonth;
                    }
                }
            } : undefined}
        />
    );
};
