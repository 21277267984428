import React from 'react';
import Button from '@mui/material/Button';
import { PropTypes, Tooltip } from '@mui/material';

interface ButtonWithTooltipProps {
    tooltipText?: string;
    onClick?: () => void;
    disabled?: boolean;
    other?: any[];
    type?: 'button' | 'submit' | 'reset';
    color?: PropTypes.Color;
    className?: string;
    variant?: 'text' | 'outlined' | 'contained';
    startIcon?: React.ReactNode;
}

export const ButtonWithTooltip: React.FC<ButtonWithTooltipProps> = ({
    tooltipText,
    disabled,
    onClick,
    ...other
}) => {

    const adjustedButtonProps = {
        disabled: disabled,
        component: disabled ? 'div' : undefined,
        onClick: disabled ? undefined : onClick
    };

    return (
        <Tooltip title={tooltipText || ''}>
            <Button
                {...other}
                {...adjustedButtonProps}
            />
        </Tooltip>
    );
};
