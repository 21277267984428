import React from 'react';
import PropTypes from 'prop-types';
import { BulkConfiguration } from './bulk-configuration/bulk-configuration';
import { BulkEntry } from './bulk-entry/bulk-entry';
import { BulkPreview } from './bulk-preview/bulk-preview';

export const BulkOrder = (props) => {
    const { bulkTabIndex, closeModal, setBulkTabIndex } = props;

    const [bulkConfiguration, setBulkConfiguration] = React.useState({});
    const [loads, setLoads] = React.useState(null);
    const [projection, setProjection] = React.useState([]);

    const onBulkConfigurationChange = (bulkConfiguration) => {
        setLoads(null);
        setBulkConfiguration(bulkConfiguration);
        setBulkTabIndex(1);
    };

    return (
        <div style={{ height: '72vh' }} data-testid='bulk-order'>
            {bulkTabIndex === 0 &&
            <BulkConfiguration bulkConfiguration={bulkConfiguration} setBulkConfiguration={onBulkConfigurationChange}/>}
            {bulkTabIndex === 1 &&
            <BulkEntry bulkConfiguration={bulkConfiguration} loads={loads} setLoads={setLoads}
                previewLoads={(projection) => {
                    setProjection(projection);
                    setBulkTabIndex(2);
                }}/>}
            {bulkTabIndex === 2 &&
            <BulkPreview bulkConfiguration={bulkConfiguration} onSubmit={closeModal}
                projections={projection.projections} projectionConfigs={projection.projectionConfigs}/>}
        </div>
    );
};

BulkOrder.propTypes = {
    bulkTabIndex: PropTypes.number,
    closeModal: PropTypes.func,
    setBulkTabIndex: PropTypes.func,
};
