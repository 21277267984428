import React from 'react';
import { L } from 'harmony-language';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { validateTrailer } from './utils/validate-trailer';
import { SnackbarTypes, useSnackbar } from '../../notifications/use-snackbar';
import { useAddOrganizationResourceMutation } from '../../../api/mutations/add/use-add-organization-resource-mutation';
import {
    useEditOrganizationResourceMutation
} from '../../../api/mutations/edit/use-edit-organization-resource-mutation';
import { ResourcePage } from '../resource-page';
import { useTrailerColumns } from './use-trailer-columns';
import { EditCreateTrailer } from './edit-create-trailer/edit-create-trailer';

export const TrailerManagement = () => {
    const snackbar = useSnackbar();
    const { data: trailers, isLoading, organizationId } = useOrganizationQuery(OrgQueryKeys.trailers);
    const { mutate: addTrailer, isLoading: isCreating } = useAddOrganizationResourceMutation(OrgQueryKeys.trailers, L.trailer());
    const { mutate: editTrailer, isLoading: isUpdating } = useEditOrganizationResourceMutation(OrgQueryKeys.trailers, L.trailer());

    const handleSubmit = (fn) => (trailer, closeModal) => {
        try {
            trailer.trailerCargoTypes = trailer.trailerCargoTypes?.filter(trailerCargoType => Boolean(trailerCargoType.cargoTypeId));
            validateTrailer(trailer);
            fn(trailer);
            closeModal();
        } catch (e) {
            snackbar(e.message, SnackbarTypes.error);
        }
    };

    const columns = useTrailerColumns();

    return (
        <ResourcePage
            resourceType='trailer'
            isLoading={isLoading || isCreating || isUpdating}
            titleFunc={tractor => tractor.userDisplayName}
            data={trailers ? trailers.filter(x => x.organizationId === organizationId) : []}
            columns={columns}
            EditCreateComponent={(editCreateProps) => {
                return <EditCreateTrailer
                    existingTrailer={editCreateProps.resource}
                    organizationId={organizationId}
                    trailers={trailers}
                    onSubmit={(newData) => {
                        handleSubmit(newData.id ? editTrailer : addTrailer)(newData, editCreateProps.closeModal);
                    }} />;
            }} />
    );
};
