import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mutationOptions, OrgQueryKeys } from '../../config';
import axios from 'axios';
import { useCompanyId } from '../../../app/agistics-context';

export const useDeleteOrganizationLocationImages = () => {
    const [mutateKey, setMutateKey] = useState<string | undefined>();
    const companyId = useCompanyId();
    const queryClient = useQueryClient();

    return useMutation(async ({ imageId, locationId }: { imageId: number, locationId: number }) => {
        const key = OrgQueryKeys.locationImages(companyId, locationId);
        setMutateKey(key);
        return (await axios.delete<void>(`${API_BASE}/api${key}/${imageId}`))?.data
    }, {
        ...mutationOptions([mutateKey], queryClient),
    })
}
