import React from 'react';
import { IconButton } from '@mui/material';
import { L } from 'harmony-language';
import UndoIcon from '@mui/icons-material/Undo';
import { UnmergeDrafts } from '../../drafts/unmerge/unmerge-drafts';
import { Load } from '../../../types';
import { useBoolean } from '../../shared/hooks/use-boolean';
import { ModalDialog } from '../../shared/containers/modal-dialog';

interface UnmergeButtonProps {
    load: Load;
}

export const UnmergeButton: React.FC<UnmergeButtonProps> = (props) => {
    const { load } = props;
    const [unmergeDialogOpen, unmergeOpenDialog, unmergeCloseDialog] = useBoolean(false);

    return (
        <>
            <IconButton
                style={{ width: 'fit-content' }}
                edge='start'
                title={L.unmerge()}
                size='small'
                color='primary'
                onClick={() => unmergeOpenDialog()}
            >
                <UndoIcon color='primary' />
            </IconButton>
            <ModalDialog
                title={L.unmerge()}
                open={unmergeDialogOpen}
                onClose={() => {unmergeCloseDialog();}}>
                    <UnmergeDrafts
                        loadId={load.id}
                        mergedFromOrderIds={load.mergedFromOrderIds ? load.mergedFromOrderIds : []}
                        unmergeCloseDialog={unmergeCloseDialog} />
            </ModalDialog></>
    );
};
