import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useLoadOrderGroup = (): { guid: string | null, remove: () => void } => {
    const navigation = useNavigate()
    const location = useLocation();
    const urlQuery = React.useMemo(() => {
        return new URLSearchParams(location.search);
    }, [location.search])
    const guid = urlQuery.get('orderGroupGuid');

    const remove = () => {
        if (urlQuery.has('orderGroupGuid')) {
            urlQuery.delete('orderGroupGuid');
            navigation({ search: urlQuery.toString() }, { replace: true });
        }
    };

    return { guid, remove };
}
