import React from 'react';
import PropTypes from 'prop-types';


export const ResourceEditCreateContainer = (props) => {
    const { children, width, ...rest } = props;

    return (
        <div style={{ width }} {...rest} >
            {children}
        </div>
    );
};

ResourceEditCreateContainer.propTypes = {
    children: PropTypes.node,
    width: PropTypes.string
};

