import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions, QueryKeys } from '../config';
import { useSnackbar } from '../../components/notifications/use-snackbar';
import { ORDER_STATUSES } from '../../constants/constants';
import { LoadCache } from '../../types';
import { useLoadsKey } from '../config-hooks';
import { StopStatus } from 'harmony-constants';

interface RequestManuallyCompleteLoad {
    id: number;
    stops: {
        id: number;
        metadata: Record<string, any> | null;
        completedAt: string | null;
    }[];
}

const manuallyCompleteLoads = async (options: { loads: RequestManuallyCompleteLoad[] }) => {
    return (await axios.put(`${API_BASE}/api${QueryKeys.completeLoads}`, options.loads)).data;
}

export const useManuallyCompleteLoads = () => {
    const snackbar = useSnackbar();
    const loadsKey = useLoadsKey();
    const queryClient = useQueryClient();

    return useMutation(manuallyCompleteLoads, {
        ...mutationOptions(loadsKey, queryClient),
        onMutate: (options: { loads: RequestManuallyCompleteLoad[] }) => {
            const loads = options.loads;

            queryClient.cancelQueries(loadsKey);
            const previousCache = queryClient.getQueryData<LoadCache>(loadsKey);
            const updatedLoads = previousCache?.data?.map(load => {
                const match = loads.find(x => x.id === load.id);

                if (match) {
                    load.status = ORDER_STATUSES().Delivered.key;
                    const updateStops = load.stops.map(stop => {
                        const matchingStop = match.stops.find(x => x.id === stop.id);
                        if (matchingStop && stop.status !== StopStatus.Complete) {
                            stop.completedAt = matchingStop.completedAt;
                            stop.metadata = {
                                manuallyCompleted: true,
                            }
                        }
                        return stop;
                    });

                    load.stops = updateStops;
                }
                return load;
            });

            // Optimistically update to the new value
            queryClient.setQueryData(loadsKey, {
                ...previousCache,
                data: updatedLoads
            });

            return () => queryClient.setQueryData(loadsKey, previousCache);
        },
        onSuccess: (data) => {
            let message = '';

            if (data.length > 1) {
                message = L.manuallyCompleteSnackbarSuccess(`${data.length} ${L.loads().toLocaleLowerCase()}`);
            } else {
                message = L.manuallyCompleteSnackbarSuccess(L.load().toLocaleLowerCase());
            }
            snackbar(message);
        },
        onSettled: () => { }
    });
};
