import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { Load, LoadCache } from '../../../types';
import { useLoadsKey } from '../../config-hooks';

async function editLoad(options: { load: Load }) {
    const load = options.load;

    return (await axios.put(`${API_BASE}/api/orders/${load.id}`, load)).data;
}

export const useEditLoadMutation = () => {
    const snackbar = useSnackbar();
    const loadsKey = useLoadsKey();
    const queryClient = useQueryClient();

    return useMutation(editLoad, {
        ...mutationOptions(loadsKey, queryClient),
        onMutate: (options) => {
            const load = options.load;

            queryClient.cancelQueries(loadsKey);

            const previousCache = queryClient.getQueryData<LoadCache>(loadsKey);
            const newLoads = previousCache?.data.map((existingLoad) => {
                if (existingLoad.id === load.id) {
                    return {
                        ...load,
                    };
                }
                return existingLoad;
            });

            // Optimistically update to the new value
            queryClient.setQueryData(loadsKey, {
                ...previousCache,
                data: newLoads
            });

            // Return a rollback function
            return () => queryClient.setQueryData(loadsKey, previousCache);
        },
        onSuccess: () => {
            snackbar(L.loadEditedSuccessfully());
        }
    });
};
