import { ORDER_STATUSES } from '../../../../constants/constants';
import moment from 'moment-timezone';
import { Driver, Load, OrganizationLocation, Stop, StopType, Tractor, Trailer } from '../../../../types';
import {
    toDriverDisplayName,
    toStopDisplayName,
    toTractorDisplayName,
    toTrailerDisplayName
} from '../../../../utils/data-mapping-utils';
import { utc } from '../../../../utils/date-time-utils';

const compare = (a: string | null, b: string | null) => {
    return a ? a.localeCompare(b || '') : -1;
};

export const sortByDate = () => (a: Load, b: Load) => {
    return moment(a.date).diff(moment(b.date));
};

export const sortByStop = (organizationLocations: OrganizationLocation[] | undefined, type: StopType) => (a: Load, b: Load) => {
    const stopA = a.stops.filter((stop) => stop.type === type)[0];
    const stopB = b.stops.filter((stop) => stop.type === type)[0];

    const locationNameA = toStopDisplayName(organizationLocations, true)(stopA);
    const locationNameB = toStopDisplayName(organizationLocations, true)(stopB);

    return compare(locationNameA, locationNameB);
};

export const sortByStopTime = (selector: (load: Load) => Stop) => (a: Load, b: Load) => {
    const stopA = selector(a);
    const stopB = selector(b);
    const timeA = stopA?.arrivalTime ? utc(stopA.arrivalTime) : '';
    const timeB = stopB?.arrivalTime ? utc(stopB.arrivalTime) : '';
    return compare(timeA, timeB);
};

export const sortByStopCompletedTime = (selector: (load: Load) => Stop) => (a: Load, b: Load) => {
    const stopA = selector(a);
    const stopB = selector(b);
    const timeA = stopA?.completedAt ? utc(stopA.completedAt) : '';
    const timeB = stopB?.completedAt ? utc(stopB.completedAt) : '';
    return compare(timeA, timeB);
};

export const sortByDriver = (drivers: Driver[] | undefined) => (a: Load, b: Load) => {
    const driverA = toDriverDisplayName(drivers)(a);
    const driverB = toDriverDisplayName(drivers)(b);
    return compare(driverA, driverB);
};

export const sortByTractor = (tractors: Tractor[] | undefined) => (a: Load, b: Load) => {
    const tractorA = toTractorDisplayName(tractors)(a);
    const tractorB = toTractorDisplayName(tractors)(b);
    return compare(tractorA, tractorB);
};

export const sortByTrailer = (trailers: Trailer[] | undefined) => (a: Load, b: Load) => {
    const trailerA = toTrailerDisplayName(trailers)(a);
    const trailerB = toTrailerDisplayName(trailers)(b);
    return compare(trailerA, trailerB);
};

export const sortByStatus = () => (a: Load, b: Load) => {
    const statusA = ORDER_STATUSES()[a.status].label;
    const statusB = ORDER_STATUSES()[b.status].label;
    return compare(statusA, statusB);
};
