import { User, Role } from "../../../types";

const flattenArray = (arrayOfArrays: any[any]) => {
    return [].concat(...arrayOfArrays);
};

export const getFeatures = (user: User) => {
    return user?.organization?.features || [];
};

export const getOrganizationTier = (user: User) => {
    return user?.organization?.tier || 'Freemium';
};

export const getPermissions = (user: User) => {
    if (user?.roles) {
        return flattenArray(user.roles.map((r) => r.permissions));
    }

    return [];
};

export const getRoles = (user: User) => {
    return user.roles;
};

export const filterByRole = (users: User[] | undefined, role: Role) => {
    return users?.filter(user => user?.roles?.some(r => r.id === role.id));
};

export const getCarrier = (user: User) => {
    return user?.organization?.isCarrier || false;
}
