import { InputAdornment, TextField } from "@mui/material";
import { L } from "harmony-language";
import React from "react";
import { EditCreateTractorType, EditCreateTrailerType } from "../../../types";
import { useWeights } from "../hooks/use-weights";

type EditCreateEquipment = EditCreateTractorType | EditCreateTrailerType;

interface EquipmentWeightInputProps<T> {
    equipment: T;
    setEquipment: React.Dispatch<React.SetStateAction<T>>;
}

export const EquipmentWeightInput = <T extends EditCreateEquipment> (props: EquipmentWeightInputProps<T>) => {
    const { equipment, setEquipment } = props;

    const { convertFromGrams, convertToGrams, weightAbbreviation } = useWeights();
    const [weightDisplay, setWeightDisplay] = React.useState(convertFromGrams(equipment.weight || 0));

    return (
        <TextField
            type='number'
            variant='standard'
            label={L.weight()}
            value={weightDisplay || ''}
            onChange={(e) => {
                const newWeight = Number(e.target.value);
                setWeightDisplay(newWeight);
                setEquipment(prev => ({...prev, weight: convertToGrams(newWeight)}));
            }}
            sx={{width: '50%'}}
            inputProps={{ min: 0 }}
            InputProps={{
                endAdornment: <InputAdornment position='end'>{weightAbbreviation}</InputAdornment>
            }}
        />
    )
}