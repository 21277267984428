import { Row, createColumnHelper } from '@tanstack/react-table';
import { L } from 'harmony-language';
import React from 'react';
import { User } from '../../../types';
import { PhoneNumber } from '../../shared/phone-number';

export const useUserColumns = () => {
    const columnHelper = createColumnHelper<User>();

    const columns = React.useMemo(() => {
        const c = [
            columnHelper.accessor('username', {
                header: L.username(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                size: 300,
            }),
            columnHelper.accessor('name', {
                header: L.name(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                size: 150,
            }),
            columnHelper.accessor('email', {
                header: L.email(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                size: 300,
            }),
            columnHelper.accessor('phone', {
                header: L.phone(),
                cell: (info) => <PhoneNumber phone={info.getValue()} />,
                enableColumnFilter: false,
                size: 150,
            }),
            columnHelper.accessor((user) => {
                // @ts-ignore
                return user.roles?.map(assignedRole => L[`roleValue${assignedRole.id}`]()).join(', ');
            }, {
                header: L.roles(),
                cell: (info) => info.getValue(),
                enableColumnFilter: true,
                filterFn: (row: Row<User>, columnId: string, filterValue: string[] | undefined) => {                    
                    if (!filterValue || filterValue?.length === 0) {
                        return true;
                    }
                    // @ts-ignore
                    return filterValue.some(filterElementString => row.getValue(columnId).includes(filterElementString));
                },
                getUniqueValues: user => user.roles?.map(assignedRole => L[`roleValue${assignedRole.id}`]()) || [],
                size: 300,
                sortingFn: (rowA, rowB) => {
                    if (rowA.original.roles?.length === 0) return -1;
                    if (rowB.original.roles?.length === 0) return 1;
                    const rowASorted = rowA.original.roles?.sort((roleA, roleB) => roleA.role.localeCompare(roleB.role));
                    const rowBSorted = rowB.original.roles?.sort((roleA, roleB) => roleA.role.localeCompare(roleB.role));
                    // @ts-ignore
                    return rowASorted[0].role.localeCompare(rowBSorted[0].role);
                },
            }),
        ];

        return c;
    }, []);

    return columns;
};
