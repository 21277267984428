import React from 'react';
import { OrganizationLocation, SiteIssue, User } from '../../types';
import { Box, Button, Checkbox, Grid, TextField, Typography } from '@mui/material';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../api/config';
import { L } from 'harmony-language';
import { localDateTimeDisplay } from '../../utils/date-time-utils';
import { useResolveSiteIssueMutation } from '../../api/mutations/use-resolve-site-issue-mutation';
import { useUser } from '../../api/queries/use-user';

interface EditSiteIssueProps {
    siteIssue: SiteIssue;
    closeModal: () => void;
}

export const EditSiteIssue: React.FC<EditSiteIssueProps> = (props) => {
    const { siteIssue, closeModal } = props;
    const [editIssue, setEditIssue] = React.useState(siteIssue);
    const { data: users = [] } = useOrganizationQuery<User[]>(OrgQueryKeys.usersCarriers);
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { mutate: resolve } = useResolveSiteIssueMutation();
    const { user } = useUser();
    const isResolved = Boolean(editIssue.resolvedByUserId);

    const userIdToUserDisplay = (userId: number | null) => {
        const user = users.find(x => x.id === userId);
        return user ? user.name : '';
    }

    const siteIdToSiteDisplay = (siteId: number) => {
        const site = organizationLocations.find(x => x.id === siteId);
        return site ? site.name : '';
    }

    const onChange = (item: Partial<SiteIssue>) => {
        const newIssue = {
            ...editIssue,
            ...item,
        }
        setEditIssue(newIssue);
    }

    const onSave = () => {
        resolve(editIssue);
        closeModal();
    }

    return (
        <Box sx={{width: '25vw'}}>
            <Grid container>
                <Grid item md={3}><Typography>{L.site()}</Typography></Grid>
                <Grid item md={9}><Typography>{siteIdToSiteDisplay(siteIssue.organizationLocationId)}</Typography></Grid>
                <Grid item md={3}><Typography>{L.issue()}</Typography></Grid>
                <Grid item md={9}><Typography>{L[siteIssue.issueLanguageKey]()}</Typography></Grid>
                <Grid item md={3}><Typography>{L.notes()}</Typography></Grid>
                <Grid item md={9}><Typography>{siteIssue.notes}</Typography></Grid>
                <Grid item md={3}><Typography>{L.createdAt()}</Typography></Grid>
                <Grid item md={9}><Typography>{localDateTimeDisplay(siteIssue.createdAt)}</Typography></Grid>
                <Grid item md={3}><Typography>{L.createdBy()}</Typography></Grid>
                <Grid item md={9}><Typography>{userIdToUserDisplay(siteIssue.createdByUserId)}</Typography></Grid>
                <Grid item md={3}><Typography>{L.resolvedAt()}</Typography></Grid>
                <Grid item md={9}><Typography>{siteIssue.resolvedAt ? localDateTimeDisplay(siteIssue.resolvedAt) : ''}</Typography></Grid>
                <Grid item md={3}><Typography>{L.resolvedBy()}</Typography></Grid>
                <Grid item md={9}><Typography>{siteIssue.resolvedByUserId ? userIdToUserDisplay(siteIssue.resolvedByUserId) : ''}</Typography></Grid>
            </Grid>
            <Typography>{L.resolutionNotes()}</Typography>
            <TextField
                sx={{ width: '25vw', margin: '0.5rem 0rem' }}
                multiline
                minRows={4}
                variant='outlined'
                value={editIssue.resolutionNotes || ''}
                onChange={(e) => onChange({ resolutionNotes: e.target.value })} />
            <br />
            <Typography style={{ display: 'inline' }}>{L.resolve()}</Typography>
            <Checkbox
                checked={isResolved}
                disabled={siteIssue.resolvedByUserId ? true : false}
                onChange={() => onChange(isResolved ? { resolvedByUserId: null } : { resolvedByUserId: user.id })} />
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end'
            }}>
                <Button type='submit'
                    title={L.save()}
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        onSave();
                    }}
                >{L.save()}</Button>
            </div>
        </Box>
    );
};
