import { flexRender } from '@tanstack/react-table';
import React from 'react';
import './report.scss';
import { ReportPaging } from './report-paging';
import { CircularLoading } from '../shared/circular-loading';
import { ReactTableColumns, ReportRunOptions } from './report-types';
import { Table } from '@tanstack/react-table';

interface ReportBaseProps<T> {
    table: Table<T>;
    hasData: boolean;
    isLoading: boolean;
    runReport: (r: ReportRunOptions) => void;
    confined?: boolean;
    additionalCsvButton?: React.ReactNode
}

export const ReportBase = <T extends ReactTableColumns>(props: ReportBaseProps<T>) => {
    const { table, hasData, isLoading, runReport, confined, additionalCsvButton } = props;

    return (
        isLoading ? <CircularLoading /> :
        !hasData ? <></> :
        <div style={{ width: confined ? '60vw' : 'inherit' }}>
            <ReportPaging table={table} runReport={runReport} additionalCsvButton={additionalCsvButton} />
            <table className='report-base'>
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                return (
                                    <th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        onClick={() => {
                                            if (header.column.getCanSort()) {
                                                header.column.toggleSorting();
                                            }
                                        }}
                                        style={{
                                            position: 'relative',
                                            width: header.getSize(),
                                        }}
                                        className={header.column.getCanSort() ? 'cursor-pointer' : ''}
                                    >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                        {{
                                            asc: <span>&#x25B2;</span>,
                                            desc: <span>&#x25BC;</span>,
                                        }[header.column.getIsSorted() as string] ?? null}
                                    {header.column.getCanResize() && (
                                        <div
                                            onMouseDown={header.getResizeHandler()}
                                            onTouchStart={header.getResizeHandler()}
                                            className={`resizer ${
                                                header.column.getIsResizing() ? 'isResizing' : ''
                                            }`}
                                        ></div>
                                    )}
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                            <tr key={row.id} className='table-row'>
                                {row.getVisibleCells().map(cell => {
                                    // @ts-ignore
                                    const isNumber = !isNaN(cell.getValue());
                                    const isBool = typeof(cell.getValue()) === 'boolean';
                                    return (
                                        <td key={cell.id}
                                            className={`table-data ${isNumber && 'table-data-right'} ${isBool && 'table-data-center'}`}
                                            style={{ width: cell.column.getSize() }}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <ReportPaging table={table} runReport={runReport} additionalCsvButton={additionalCsvButton} />
        </div>
    );
};
