import React from 'react';
import { L } from 'harmony-language';
import { Button, TextField } from '@mui/material';
import { isValidLatitude, isValidLongitude } from '../../../utils/validation-utils';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { Point } from '../../../types';

interface AvoidanceZonePointProps {
    centerMap?: (latitude: number, longitude: number, zoomLevel: number) => void;
    setHelperMarker: (p: Point) => void;
}

export const AvoidanceZonePoint: React.FC<AvoidanceZonePointProps> = (props) => {
    const { centerMap, setHelperMarker } = props;
    const [latitude, setLatitude] = React.useState<number | string>('');
    const [longitude, setLongitude] = React.useState<number | string>('');
    const validLatitude = React.useMemo(() => {
        return typeof latitude === 'number' ? isValidLatitude(latitude) : false;
    }, [latitude]);
    const validLongitude = React.useMemo(() => {
        return typeof longitude === 'number' ? isValidLongitude(longitude) : false;
    }, [longitude]);

    return (
        <div className='avoidance-zone-creation-form-fields'>
            <AvoidanceZoneLatLng
                label={L.latitude()}
                value={latitude}
                setValue={setLatitude}
            />
            <AvoidanceZoneLatLng
                label={L.longitude()}
                value={longitude}
                setValue={setLongitude}
            />
            <div>
                <Button
                    disabled={!validLatitude || !validLongitude}
                    size='small'
                    onClick={() => {
                        if (latitude && longitude && typeof latitude === 'number' && typeof longitude === 'number') {
                            centerMap && centerMap(latitude, longitude, 14);
                            setHelperMarker({ latitude: latitude, longitude: longitude });
                        }
                    }}
                >
                    <MyLocationIcon />
                </Button>
            </div>
        </div>
    );
};

interface ValidatedLatitudeProps {
    label: string;
    value: number | string;
    setValue: (a: number | string) => void;
}

const AvoidanceZoneLatLng: React.FC<ValidatedLatitudeProps> = (props) => {
    const { label, value, setValue } = props;

    return (
        <TextField
            variant='standard'
            label={label}
            type='number'
            value={value}
            onChange={(e) => setValue(Number(e.target.value))}
        />
    );
};
