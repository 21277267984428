import React from 'react';
import { KeysWithValsOfType, NotificationConfig } from '../../../../types';
import { AgisticsMultiItem, AgisticsMultiSelect } from '../../../shared/multi-select/agistics-multi-select';

type NotificationMultiInputProps = {
    notificationConfig: NotificationConfig;
    onNotificationChange: (notificationConfig: NotificationConfig) => void;
    items: AgisticsMultiItem<number>[];
    identifierKey: KeysWithValsOfType<NotificationConfig, number[] | null>;
    translation: string;
}

export const NotificationMultiInput: React.FC<NotificationMultiInputProps> = (props) => {
    const { notificationConfig, onNotificationChange, items, identifierKey, translation } = props;

    const handleChange = (value: number[]) => {
        onNotificationChange({ ...notificationConfig, [identifierKey]: value });
    }

    return (
        <AgisticsMultiSelect
            selectedIds={notificationConfig[identifierKey] || []}
            setSelectedIds={handleChange}
            items={items}
            label={translation}
        />
    );
};
