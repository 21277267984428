import React, { FormEvent } from 'react';
import { ValidatedTextField } from '../shared/inputs/validated-text-field';
import { getCurrentUnitSystem, L, UNITS } from 'harmony-language';
import Grid from '@mui/material/Grid';
import { AutoItem, ItemId, ResourceAutoComplete } from '../shared/inputs/resource-autocomplete';
import { orgLocationsToItems } from '../../utils/data-mapping-utils';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../api/config';
import { useLocationTypes } from '../../api/queries/use-location-types';
import { STOP_TYPES } from '../../constants/constants';
import { localNow } from '../../utils/date-time-utils';
import { Moment } from 'moment-timezone';
import { CargoType, OrganizationLocation, StopType } from '../../types';
import LoadingButton from '../shared/loading-button';
import Save from '@mui/icons-material/Save';
import { useAddProductionCardMutation } from '../../api/mutations/add/use-add-production-card-mutation';
import InputAdornment from '@mui/material/InputAdornment';
import { Container } from '@mui/material';
import { isValidStopLocation } from '../../utils/validation-utils';
import { Select } from '../shared/inputs/select';
import { useWeights } from '../shared/hooks/use-weights';
import { CargoTypeMode, CargoTypeModes } from 'harmony-constants';
import { AgisticsDatePicker } from '../shared/inputs/agistics-date-picker';
import { orgSubLocationToItems } from '../../utils/data-mapping';
import { AgisticsMultiSelect } from '../shared/multi-select/agistics-multi-select';

type ProductionStopInfo = {
    organizationLocationId: number | null,
    organizationSubLocationIds: number[] | null
}

export type ProductionCardState = {
    targetDate: string,
    cargoTypeId: number
    quantity: number | null,
    weight: number | null,
    productionPlan: string | null,
    lotId: string | null,
    orderNumber: string | null,
    averageWeight: number | null,
    numberOfLoads: number | null,
    origin: ProductionStopInfo,
    destination: ProductionStopInfo,
}

interface CreateProductionCardProps {
    cargoTypeList: CargoType[];
    cargoTypeId: number;
    onSubmit: () => void;
    cargoChangeFunc: (cargoTypeId: number) => void;
}

const CreateProductionCard: React.FC<CreateProductionCardProps> = (props) => {
    const { cargoTypeList, cargoTypeId, onSubmit, cargoChangeFunc } = props;

    const [cargoType, setCargoType] = React.useState<CargoType | undefined>();
    const { weightAbbreviation, convertFromGrams, convertToGrams } = useWeights();
    const [mode, setMode] = React.useState(CargoTypeModes.find(x => x.id === cargoTypeId)?.mode || CargoTypeMode.Quantity);

    React.useEffect(() => {
        setCargoType(cargoTypeList.find((x: CargoType) => x.id === cargoTypeId));
        const mode = CargoTypeModes.find(x => x.id === cargoTypeId)?.mode || CargoTypeMode.Quantity;
        setMode(mode);
    }, [cargoTypeId]);

    const [card, setCard] = React.useState<ProductionCardState>({
        targetDate: localNow().startOf('day').toISOString(),
        cargoTypeId: cargoTypeId,
        quantity: null,
        weight: null,
        productionPlan: null,
        lotId: null,
        orderNumber: null,
        averageWeight: 0,
        origin: {
            organizationLocationId: null,
            organizationSubLocationIds: null
        },
        destination: {
            organizationLocationId: null,
            organizationSubLocationIds: null
        },
        numberOfLoads: null,
    });
    const [displayWeight, setDisplayWeight] = React.useState(convertFromGrams(card.weight || 0));

    const { mutate: create, isLoading } = useAddProductionCardMutation();
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await create(card);
        onSubmit();
    };

    const handleChange = (field: keyof ProductionCardState) => (value: Moment | string | number | boolean | null | ProductionStopInfo) => {
        const setFunc = (value: Moment | string | number | boolean | null | ProductionStopInfo) => {
            setCard({
                ...card,
                [field]: value
            });
        }

        if (field === 'weight') {
            const changedWeight = Number(value);
            const grams = convertToGrams(changedWeight);
            setDisplayWeight(changedWeight);
            setFunc(grams);
        } else {
            setFunc(value);
        }
    };

    //when using setCard (useState) it's not updated until next re-render so can't set multiple values in above handleChange in one render
    const handleCargoTypeChange = (cargoTypeId: number) => {
        setCard({
            ...card,
            cargoTypeId: cargoTypeId,
            origin: {
                organizationLocationId: null,
                organizationSubLocationIds: null,
            },
            destination: {
                organizationLocationId: null,
                organizationSubLocationIds: null,
            },
        });
    };

    return (
        <form id={'create-production-card'} onSubmit={handleSubmit} style={{ width: '40vw' }}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={5}>
                {cargoTypeList && <Select
                        item={cargoType}
                        list={cargoTypeList}
                        onChange={val => {
                            cargoChangeFunc(val.id);
                            setCargoType(val);
                            handleCargoTypeChange(val.id);
                        }}
                    />}
                </Grid>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={5}>
                    <AgisticsDatePicker
                        label={L.date()}
                        value={card.targetDate}
                        onChange={date => {
                            if (date) {
                                handleChange('targetDate')(date);
                            }
                            
                        }}
                        style={{ width: '100%' }}
                    />
                    {mode === CargoTypeMode.Quantity ?
                        <ValidatedTextField
                            label={L.quantity()}
                            type='number'
                            required
                            value={card.quantity || ''}
                            inputProps={{ min: 1 }}
                            fullWidth
                            onChange={(e) => handleChange('quantity')(Number(e.target.value))} /> :
                        <ValidatedTextField
                            label={L.weight()}
                            type='number'
                            required
                            value={displayWeight || ''}
                            inputProps={{ min: 1 }}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>{weightAbbreviation}</InputAdornment>
                            }}
                            fullWidth
                            onChange={(e) => handleChange('weight')(Number(e.target.value))} />
                    }
                    {mode === CargoTypeMode.Quantity ?
                        <ValidatedTextField
                            label={L.averageWeight()}
                            type='number'
                            required
                            value={card.averageWeight || ''}
                            inputProps={{ min: 1, step: '0.01' }}
                            InputProps={{
                                endAdornment: <InputAdornment
                                    position='end'>{getCurrentUnitSystem() === UNITS.Metric ? L.unitsMetricWeightAbbr() : L.unitsImperialWeightAbbr()}</InputAdornment>
                            }}
                            fullWidth
                            onChange={(e) => {
                                const number = parseFloat(e.target.value);
                                handleChange('averageWeight')(number)
                            }} /> :
                        <ValidatedTextField
                            label={L.numberOfLoads()}
                            type='number'
                            required
                            value={card.numberOfLoads || ''}
                            inputProps={{ min: 1, step: '1' }}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>{'#'}</InputAdornment>
                            }}
                            fullWidth
                            onChange={(e) => {
                                const number = parseInt(e.target.value);
                                handleChange('numberOfLoads')(number)
                            }} />
                    }
                    <ValidatedTextField
                        label={L.productionPlan()}
                        value={card.productionPlan || ''}
                        fullWidth
                        onChange={(e) => handleChange('productionPlan')(e.target.value)}/>
                    <ValidatedTextField
                        label={`${L.pickUp()} #`}
                        value={card.lotId || ''}
                        fullWidth
                        onChange={(e) => handleChange('lotId')(e.target.value)}/>
                    <ValidatedTextField
                        label={L.orderNumber()}
                        value={card.orderNumber || ''}
                        fullWidth
                        onChange={(e) => handleChange('orderNumber')(e.target.value)}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <StopLocationSelect stopInfo={{ ...card.origin, cargoTypeId, type: 'Origin' }}
                                        onChange={handleChange('origin')}/>
                    <StopLocationSelect stopInfo={{ ...card.destination, cargoTypeId, type: 'Destination' }}
                                        onChange={handleChange('destination')}/>
                </Grid>
                <Grid item xs={12} md={3}>
                    <ProductionCardSublocations
                        stopInfo={card.origin}
                        onChange={handleChange('origin')}
                    />
                    <ProductionCardSublocations
                        stopInfo={card.destination}
                        onChange={handleChange('destination')}
                    />
                </Grid>

            </Grid>
            <div style={{ padding: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton isLoading={isLoading} icon={<Save/>} type='submit' color={'primary'}
                               variant='contained'>{L.productionCardCreateCard()}</LoadingButton>
            </div>
        </form>
    );
};

const StopLocationSelect: React.FC<{
    stopInfo: ProductionStopInfo & { cargoTypeId: number, type: StopType }, onChange: (info: ProductionStopInfo) => void
}> = (props) => {
    const { stopInfo, onChange } = props;
    const { data: locations = [] as OrganizationLocation[] } = useOrganizationQuery(OrgQueryKeys.locations);
    const { data: locationTypes } = useLocationTypes();
    const locationList: AutoItem[] = React.useMemo(() => {
        return orgLocationsToItems(locations, locationTypes, stopInfo, false);
    }, [locations, locationTypes, stopInfo, stopInfo.cargoTypeId]);
    const isStopLocationValid = React.useCallback(() => isValidStopLocation(stopInfo.organizationLocationId),
        [stopInfo]);
    const [ selectedItem, setSelectedItem ] = React.useState(-1);

    React.useEffect(() => {
        setSelectedItem(-1);
    }, [stopInfo.cargoTypeId]);

    return (
        <ResourceAutoComplete
            initialSelectedOptionId={selectedItem}
            options={locationList}
            onChange={(optionId: ItemId) => {
                onChange({
                    organizationLocationId: optionId,
                    organizationSubLocationIds: null,
                });
                setSelectedItem(optionId ? optionId : -1);
            }}
            label={STOP_TYPES()[stopInfo.type].label}
            validations={[
                {
                    isValid: isStopLocationValid,
                    message: L.invalidLocation(),
                }
            ]}
        />
    );
};

interface ProductionCardSublocationsProps {
    stopInfo: ProductionStopInfo;
    onChange: (info: ProductionStopInfo) => void
}

const ProductionCardSublocations: React.FC<ProductionCardSublocationsProps> = (props) => {
    const { stopInfo, onChange } = props;

    const { data: locations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const subLocationList = React.useMemo(() => {
        return orgSubLocationToItems(locations, stopInfo.organizationLocationId)
    }, [locations, stopInfo]);

    return (
        <Container style={{ height: '48px' }} disableGutters>
            {subLocationList.length ?
                <AgisticsMultiSelect
                    selectedIds={stopInfo.organizationSubLocationIds || []}
                    setSelectedIds={(organizationSubLocationIds) => {
                        onChange({
                            organizationLocationId: stopInfo.organizationLocationId,
                            organizationSubLocationIds: organizationSubLocationIds,
                        })
                    }}
                    items={subLocationList}
                    label={L.subLocations()}
                />
            : null}
        </Container>
    )
}

export default CreateProductionCard;
