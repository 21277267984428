import React from 'react';
import PropTypes from 'prop-types';
import Close from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

const DrawerTitle = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: 0,
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
}));

const DrawerContent = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    width: '25vw',
}));

export const DrawerDialog = (props) => {
    const { title, anchor, open, onClose } = props;

    return (
        <Drawer
            anchor={anchor}
            open={open}
            onClose={onClose}
            aria-labelledby='driver-assignment-dialog'
        >
            <DrawerTitle>
                <Typography variant='h6'>{title}</Typography>
                <IconButton onClick={onClose} color={'inherit'} size="large">
                    <Close/>
                </IconButton>
            </DrawerTitle>
            <DrawerContent>
                {props.children}
            </DrawerContent>
        </Drawer>
    );
};

DrawerDialog.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    fullScreen: PropTypes.bool,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
    anchor: PropTypes.string
};
