import React, { ChangeEvent, MouseEvent } from 'react';
import { Load } from '../../../../types';
import { useUpdateUserPreferences } from '../../hooks/use-update-user-preferences';

export type LoadPaging = {
    pagedLoads: Load[];
    pageNumber: number;
    rowsPerPage: number;
    rowsPerPageOptions: number[];
    onPageChange: (e: MouseEvent<HTMLButtonElement> | null, pageNumber: number) => void;
    onRowsPerPageChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const rowsPerPageOptions = [10, 25, 50, 100];

export const useLoadPaging = (filteredData: Load[], tableId: string): LoadPaging => {
    const { preferences, updatePreferences } = useUpdateUserPreferences();
    const pageSize = Math.max(preferences?.tables?.[tableId]?.pageSize || 25, 10); //Upgrade old small page sizes

    const [pageNumber, setPageNumber] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(pageSize);

    React.useEffect(() => {
        setRowsPerPage(pageSize);
    }, [pageSize])

    // set page number back to first page if filter/searching/changing weeks results
    // in a different result set
    const filteredDataIds = filteredData.map(x => x.id).sort().join("|");
    React.useEffect(() => {
        setPageNumber(0);
    }, [filteredDataIds]);

    const onPageChange = (e: MouseEvent<HTMLButtonElement> | null, page: number | null) => {
        setPageNumber(Number(page));
    }

    const onRowsPerPageChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newPageSize = Number(e.target.value)
        setRowsPerPage(newPageSize);
        const newPreferences = {
            tables: {
                ...preferences?.tables,
                [tableId]: {
                    pageSize: newPageSize
                }
            }
        }
        updatePreferences(newPreferences);
        setPageNumber(0);
    }

    const pagedLoads = React.useMemo(() => {
        return filteredData.slice(Number(pageNumber) * rowsPerPage, (Number(pageNumber) + 1) * rowsPerPage)
    }, [filteredData, pageNumber, rowsPerPage]);

    return {pagedLoads, pageNumber, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange};
};
