import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { mutationOptions, OrgQueryKeys } from '../../config';
import { NotificationConfig } from '../../../types';
import { useCompanyId } from '../../../app/agistics-context';

interface ServiceUser {
    id: number;
    name: string;
}

export const useDeleteOrganizationResourceMutation = (queryKey: string) => {
    const companyId = useCompanyId();
    const key = OrgQueryKeys.resolve(companyId, queryKey);
    const queryClient = useQueryClient();

    return useMutation(async (options) => {
        await axios.delete(`${API_BASE}/api${key}/${options.id}`);
    }, {
        ...mutationOptions([key], queryClient),
        onMutate: (options) => {
            const id = options.id;

            queryClient.cancelQueries([key]);

            const previousList = queryClient.getQueryData<NotificationConfig[] | ServiceUser[]>([key]);
            if (previousList) {
                const newList = previousList.filter(x => x.id !== id);

                // Optimistically update to the new value
                queryClient.setQueryData([key], newList);
            }

            // Return a rollback function
            return () => queryClient.setQueryData([key], previousList);
        }
    });
};
