import React from 'react';

export const useDebounce = (value: string, delay = 500) => {
    const [debouncedValue, setDebouncedValue] = React.useState(value);
    const [isDebouncing, setIsDebouncing] = React.useState(false);

    React.useEffect(() => {
        setIsDebouncing(true);
        const handler = setTimeout(() => {
            setDebouncedValue(value);
            setIsDebouncing(false);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return { debouncedValue, isDebouncing };
}
