import React from 'react';
import PropTypes from 'prop-types';
import { STOP_STATUSES } from '../../constants/constants';
import CheckCircle from '@mui/icons-material/CheckCircle';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { getNextStop } from './utils/get-next-stop';
import { getTimeDifference, localTime } from '../../utils/date-time-utils';
import { L } from 'harmony-language';
import { getTimeToNextStop } from './utils/time-to-next-stop';
import { Tooltip } from '@mui/material';

export const LiveMapStopsChart = (props) => {
    const { stops, route } = props;

    if (!stops) {
        return null;
    }
    const nextStop = getNextStop(stops);

    const renderIcon = (stop) => {
        const stopStatus = stop.status;
        const stopStatusObj = STOP_STATUSES()[stopStatus];

        switch (stopStatus) {
            case STOP_STATUSES().Complete.key:
                return <CheckCircle data-testid='live-map-stop-complete-icon' style={{ color: stopStatusObj.color }} />;
            case STOP_STATUSES().Arrived.key:
                return <CheckCircle style={{ color: stopStatusObj.color }} />;
            case STOP_STATUSES().Enroute.key:
                return <RadioButtonUnchecked style={{ color: stopStatusObj.color }} />;
            default:
                return <RadioButtonUnchecked />;
        }
    };

    const renderConnector = (stop, index) => {
        if (index >= stops.length - 1) {
            return null;
        }

        let estimatedArrival;
        if (stop.status === STOP_STATUSES().Complete.key && stops[index + 1] === nextStop) {
            estimatedArrival = getTimeDifference(new Date(), route?.sections[0]?.arrival?.time);
        }

        const actualTime = stop.completedAt ? getTimeDifference(stop.completedAt, stops[index + 1]?.completedAt) : null;

        return (
            <React.Fragment>
                <span className='stop-duration'>{getTimeDifference(stop.arrivalTime, stops[index + 1].arrivalTime)}</span>
                {estimatedArrival ?
                    <span className='computed-stop-travel-time-remaining'>{estimatedArrival}</span> :
                    <span className='actual-time-between-stops'>{actualTime}</span>
                }
                <div className='stop-connector'/>
            </React.Fragment>
        );
    };

    const renderNextStopLabel = (stop) => {
        if (stop !== nextStop) {
            return null;
        }

        return <span className='computed-next-stop-arrival'>{getTimeToNextStop(route)}</span>
    };

    const renderStop = (stop, index) => (
        <div key={index} className='live-map-stop' data-testid='live-map-stop-info'>
            {renderNextStopLabel(stop)}
            <span className='stop-arrival-time'>{localTime(stop.arrivalTime)}</span>
            <span className='stop-complete-time'>{stop.completedAt ? localTime(stop.completedAt) : null}</span>
            <Tooltip
                title={<span style={{ fontSize: '.875rem' }}>{STOP_STATUSES()[stop.status].label}</span>}
                arrow
                placement='top'>
                    {renderIcon(stop)}
            </Tooltip>
            {renderConnector(stop, index)}
        </div>
    );

    return (
        <div className='live-map-stops-container' data-testid='live-map-stops-chart'>
            <div className='live-map-label-container'>
                <span className='live-map-label-left'>{L.scheduled()}</span>
                <span className='live-map-label-right'>{L.completed()}</span>
            </div>
            {stops.map(renderStop)}
        </div>
    );
};

LiveMapStopsChart.propTypes = {
    stops: PropTypes.array,
    route: PropTypes.object,
};

export const LiveMapStopsChartLegend = () => {
    return (
        <div className='live-map-stops-legend'>
            <div className='legend'>{L.legend()}</div>
            <div className='gray'>{L.scheduled()}</div>
            <div className='green'>{L.actual()}</div>
            <div className='orange'>{L.estimated()}</div>
        </div>
    );
};
