import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { mutationOptions } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { L } from 'harmony-language';
import { LoadCache } from '../../../types';

async function deleteLoads(options: { ids: number[] }) {
    const ids = options.ids;

    await axios.delete(`${API_BASE}/api/orders`, { data: ids });
}

export const useDeleteLoadsMutation = (queryKey: (string | number)[]) => {
    const snackbar = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation(deleteLoads, {
        ...mutationOptions(queryKey, queryClient),
        onMutate: () => {
            queryClient.cancelQueries(queryKey);
            const previousCache = queryClient.getQueryData(queryKey);

            return () => queryClient.setQueryData(queryKey, previousCache);
        },
        onSuccess: (_, options) => {
            const ids = options.ids;
            const previousCache = queryClient.getQueryData<LoadCache>(queryKey);
            const filteredLoads = previousCache?.data.filter(load => !ids.includes(load.id));

            queryClient.setQueryData(queryKey, {
                ...previousCache,
                data: filteredLoads
            });
            snackbar(L.bulkDeleteSnackbarSuccess(options.ids.length.toString()));
        },
        onSettled: () => {}
    });
};
