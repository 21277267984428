import Paper, { PaperProps } from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import React from 'react';

interface TitleContainerProps {
    paddingSmall?: boolean;
}

const TitleContainer = styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'paddingSmall',
})<TitleContainerProps>(({ paddingSmall, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(.5),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    ...(paddingSmall && {
        padding: theme.spacing(1),
    })
}));

const Content = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
}));

interface AgisticsCardProps extends PaperProps {
    title?: string,
    icon?: string,
    left?: React.ReactNode,
    paddingSmall?: boolean,
}

export const AgisticsCard: React.FC<AgisticsCardProps> = props => {
    const { icon, title, children, left, paddingSmall, ...rest } = props;

    return (
        <Paper data-testid='agistics-card' {...rest}>
            <TitleContainer paddingSmall={paddingSmall}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {icon ? <Icon fontSize='medium' sx={{ paddingRight: '1rem' }}>{icon}</Icon> : null}
                    <Typography variant={'h6'}>{title}</Typography>
                </div>
                {left}
            </TitleContainer>
            <Content>
                {children}
            </Content>
        </Paper>
    );
};
