import { STOP_TYPES } from '../../../../constants/constants';
import { L } from 'harmony-language';
import Typography from '@mui/material/Typography';
import React from 'react';
import { LocationResource } from '../../../../types';
import { useLocationInfo } from '../../hooks/use-location-info';

export const LocationTypeConflictTooltip: React.FC<{ resource: LocationResource }> = (props) => {
    const {resource} = props;
    const stop = resource.stop;
    const { toSiteDisplayName, locationTypeId } = useLocationInfo(Number(resource.id), resource.stop?.type);

    if (!locationTypeId) {
        return null;
    }

    return (
        <div>
            <Typography>{L.locationTypeInvalid()}</Typography>
            <div>
                {L.resourceLocationTypeMismatchWarning(toSiteDisplayName(stop.organizationSubLocationId), STOP_TYPES()[stop.type].label)}
            </div>
        </div>
    );
};
