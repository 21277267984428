import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions } from '../config';
import { useSnackbar } from '../../components/notifications/use-snackbar';
import { useDraftsKey } from '../config-hooks';

async function generateProjections(options: any) {
    const projectionConfigs = options.config;
    return (await axios.post(`${API_BASE}/api/orders/create-projections`, projectionConfigs)).data;
}

export const useGenerateProjections = () => {
    const snackbar = useSnackbar();
    const draftsKey = useDraftsKey();
    const queryClient = useQueryClient();

    return useMutation(generateProjections, {
        ...mutationOptions(draftsKey, queryClient),
        onSuccess: (data) => {
            snackbar(L.bulkSnackbarSuccess(data.length));
        }
    });
};
