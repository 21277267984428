import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { CarrierAssignmentCard } from './carrier-assignment-card';
import Skeleton from "@mui/material/Skeleton";
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { useAssignCarrierMutation } from '../../api/mutations/use-assign-carrier-mutation';
import { OrgQueryKeys } from '../../api/config';
import { Load, OrganizationCarrier } from '../../types';
import { L } from 'harmony-language';
import { Button } from '@mui/material';

type CarrierAssignmentProps = {
    loads: Load[];
    onAssign: () => void;
}

export const CarrierAssignment = (props: CarrierAssignmentProps) => {
    const { loads, onAssign } = props;
    const { data, isLoading } = useOrganizationQuery<OrganizationCarrier[]>(OrgQueryKeys.carriers);
    const { mutate: assignCarrier } = useAssignCarrierMutation();

    const carriers = data?.filter(x => x.isActive) || [];

    const renderCarrier = (carrier: OrganizationCarrier, i: number) => {
        return (
            <ListItem key={i}>
                <CarrierAssignmentCard assignCarrier={(carrier) => {
                    assignCarrier({ loads, carrier });
                    onAssign();
                }} carrier={carrier} />
            </ListItem>
        );
    };

    const renderSkeleton = (x: any, i: number) => (
        <ListItem key={i}>
            <Skeleton variant='rectangular' width='100%' />
        </ListItem>
    );

    const handleClick = () => {
        assignCarrier({ loads });
        onAssign();
    }

    return (
        <div data-testid='carrier-assignment'>
            <List>
                {isLoading ? [...Array(5).keys()].map(renderSkeleton) : carriers.length ? carriers.map(renderCarrier) : L.noActiveCarrier()}
            </List>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    onClick={handleClick}
                    variant='contained'>{L.unassign()}
                </Button>
            </div>
        </div>
    );
};
