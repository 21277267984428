import React from 'react';
import { L } from 'harmony-language';
import { TextFieldWithValidation } from '../../../shared/inputs/text-field-with-validation';
import { InputAdornment } from '@mui/material';
import { useWeights } from '../../../shared/hooks/use-weights';

interface LoadWeightProps {
    loadWeightGrams: number | null;
    setLoadWeightGrams: (n: number | null) => void;
    setLoadWeightDisplay: (s: string) => void;
    sizeWeightValidation: () => boolean;
}

export const LoadWeight: React.FC<LoadWeightProps> = (props) => {
    const { loadWeightGrams, setLoadWeightGrams, setLoadWeightDisplay, sizeWeightValidation } = props;
    const { convertFromGrams, convertToGrams, weightAbbreviation, convertFromGramsDisplay } = useWeights();
    const [internalDisplay, setInternalDisplay] = React.useState<number | null>(loadWeightGrams ? convertFromGrams(loadWeightGrams) : null);
    
    const loadSizeChange = (event: React.ChangeEvent<{ value: number }>) => {
        const val = event.target.value;
        const weightInGrams = convertToGrams(event.target.value);
        
        setLoadWeightGrams(weightInGrams);
        setLoadWeightDisplay(convertFromGramsDisplay(weightInGrams));
        setInternalDisplay(val);
    };

    return (
        <TextFieldWithValidation
            className='bulk-config-load-weight'
            label={L.bulkSelectWeight()}
            onChange={loadSizeChange}
            type='number'
            value={internalDisplay}
            required={false}
            validationFunc={sizeWeightValidation}
            requiredMsg={L.loadInvalidStopValue()}
            InputProps={{
                endAdornment: <InputAdornment position='end'>{weightAbbreviation}</InputAdornment>
            }}
        />
    );
};
