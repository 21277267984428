import React from 'react';
import { styled } from '@mui/material/styles';
import Skeleton from "@mui/material/Skeleton";
import { L } from 'harmony-language';
import { Table, flexRender } from '@tanstack/react-table';

const NoRecords = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '5rem',
});

const Cell = styled('td')(({ theme }) => ({
    padding: '0 1rem',
    height: '3rem',
    borderBottom: `1px solid ${theme.palette.grey[300]}`
}));

interface TableRowCellProps {
    isDisabled: boolean;
    width: number;
}

const TableRowCell = styled('td', {
    shouldForwardProp: (prop) => prop !== 'isDisabled' && prop !== 'width',
})<TableRowCellProps>(({ isDisabled, width, theme }) => ({
    lineHeight: 1.43,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    width: width,
    boxSizing: 'border-box',
    padding: '0.5rem 1.5rem',
    height: '1px', // needed so we can greyOut cell
    ...(isDisabled && {
        color: theme.palette.grey[300],
    }),
    '& > .grey': {
        backgroundColor: theme.palette.grey[500],
    }
}));

interface AgisticsTableBodyProps {
    isLoading: boolean;
    table: Table<any>;
}

export const AgisticsTableBody: React.FC<AgisticsTableBodyProps> = (props) => {
    const { isLoading, table } = props;

    return (
        <tbody>
            {isLoading ? (
                Array.from(Array(10), (x, i) => i).map((x) => {
                    return (
                        <tr key={x} data-testid={`loading-row-${x}`}>
                            <Cell colSpan={10}>
                                <Skeleton
                                    style={{ marginBottom: '5px' }}
                                    height={'2rem'}
                                />
                            </Cell>
                        </tr>
                    );
                })
            ) : table.getPageCount() > 0 ? (
                table.getRowModel().rows.map(row => {
                    return (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => {
                                let isDisabled = false;
                                // @ts-ignore
                                if (Object.hasOwn(cell.row.original, 'enabled')) {
                                    if (cell.row.original.enabled === false) {
                                        isDisabled = true;
                                    }
                                }

                                return (
                                    <TableRowCell
                                        key={cell.id}
                                        isDisabled={isDisabled}
                                        width={cell.column.getSize()}
                                    >
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )}
                                    </TableRowCell>
                                )
                            })}
                        </tr>
                    )
                })
            ) : (
                <tr>
                    <td colSpan={10}>
                        <NoRecords>
                            {L.noRecords()}
                        </NoRecords>
                    </td>
                </tr>
            )}
        </tbody>
    )
};
