import { L } from 'harmony-language';
import React from 'react';
import { OrgQueryKeys } from '../../../../api/config';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { useTractorTrailer } from '../../../../api/queries/use-tractor-trailer';
import { ORDER_STATUSES, getTransCargoTypeLabel, getTransLocationTypeLabel } from '../../../../constants/constants';
import { Customer, Driver, Load, LoadingTeam, OrganizationLocation, Stop } from '../../../../types';
import {
    filterStopByDestination,
    toCustomerDisplayName,
    toDriverDisplayName,
    toStopDisplayName,
    toStopLoadingTeamName,
    toTractorDisplayName,
    toTrailerDisplayName
} from '../../../../utils/data-mapping-utils';
import { useWeights } from '../../hooks/use-weights';

export type LoadDistinctionOption = {
    id: string | number;
    label: string;
    category: string;
    entity: 'order' | 'stop' | 'organizationLocation' | 'calculatedField';
    field: keyof Load | keyof Stop | keyof OrganizationLocation | 'operatorWeight';
    children?: {field: keyof Stop, id: number, label: string}[];
    selectedChild?: {field: keyof Stop, id: number, label: string};
}

export type LoadDistinction = {
    distinctOptions: LoadDistinctionOption[],
}

export const useLoadDistinction = (data: Load[]): LoadDistinction => {
    const {tractors, trailers, isLoadingTractors, isLoadingTrailers} = useTractorTrailer();
    const {data: loadingTeams, isLoading: isLoadingLoadingTeams} = useOrganizationQuery<LoadingTeam[]>(OrgQueryKeys.loadingTeams);
    const {data: organizationLocations, isLoading: isLoadingLocations} = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const {data: drivers, isLoading: isLoadingDrivers} = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers);
    const {data: customers, isLoading: isLoadingCustomers} = useOrganizationQuery<Customer[]>(OrgQueryKeys.customers);
    const isLoading = isLoadingTractors || isLoadingTrailers || isLoadingLoadingTeams || isLoadingLocations || isLoadingDrivers || isLoadingCustomers;
    const { convertFromGrams, weightAbbreviation } = useWeights();

    const distinctOptions = React.useMemo(() => {
        if (isLoading) {
            return []
        }

        const options = data.reduce<LoadDistinctionOption[]>((options, load) => {
            const newOptions: LoadDistinctionOption[] = [];

            // Order super category options
            if (load.transportedByUserId) {
                const driver = toDriverDisplayName(drivers)(load) as string;
                newOptions.push({
                    entity: 'order',
                    field: 'transportedByUserId',
                    id: load.transportedByUserId,
                    label: driver,
                    category: L.driver()
                })
            }
            if (load.tractorId) {
                const tractor = toTractorDisplayName(tractors)(load)
                newOptions.push({
                    entity: 'order',
                    field: 'tractorId',
                    id: load.tractorId,
                    label: tractor,
                    category: L.tractor()
                })
            }
            if (load.trailerId) {
                const trailer = toTrailerDisplayName(trailers)(load)
                newOptions.push({
                    entity: 'order',
                    field: 'trailerId',
                    id: load.trailerId,
                    label: trailer,
                    category: L.trailer()
                })
            }

            // Order super calculated field options
            const destinationsWithQuantity = load.stops.filter(stop => filterStopByDestination(stop) && stop.quantity !== null);
            if (destinationsWithQuantity.length > 0) {
                const totalQuantity = destinationsWithQuantity.map(stop => stop.quantity || 0).reduce((acc, quantity) => acc + quantity);
                newOptions.push({
                    entity: 'calculatedField',
                    field: 'quantity',
                    id: totalQuantity,
                    label: `${totalQuantity}`,
                    category: `${L.total()} ${L.dropOff()} ${L.quantity()}`
                })
            }
            const destinationsWithWeight = load.stops.filter(stop => filterStopByDestination(stop) && stop.weight !== null);
            if (destinationsWithWeight.length > 0) {
                const totalWeight = destinationsWithWeight.map(stop => stop.weight || 0).reduce((acc, weight) => acc + weight);
                const totalWeightForCompare = convertFromGrams(totalWeight);
                newOptions.push({
                    entity: 'calculatedField',
                    field: 'weight',
                    id: totalWeightForCompare,
                    label: `${totalWeightForCompare} ${weightAbbreviation}`,
                    category: `${L.total()} ${L.dropOff()} ${L.weight()}`
                })
            }

            const status = ORDER_STATUSES()[load.status].label;
            newOptions.push({
                entity: 'order',
                field: 'status',
                id: load.status,
                label: status,
                category: L.status()
            })

            // Stop super category options
            load.stops.forEach(stop => {
                if (stop.organizationLocationId) {
                    const orgLocation = organizationLocations?.find((l: { id: number }) => l.id === stop?.organizationLocationId);
                    const location = toStopDisplayName(organizationLocations, true, true)(stop);
                    newOptions.push({
                        entity: 'stop',
                        field: 'organizationLocationId',
                        id: stop.organizationLocationId,
                        label: location,
                        category: getTransLocationTypeLabel(orgLocation?.organizationLocationTypeId),
                        ...(orgLocation?.orgSubLocations?.length ? {
                            children: orgLocation.orgSubLocations.map(x => ({
                                field: 'organizationSubLocationId' as keyof Stop,
                                id: x.id,
                                label: x.name
                            })).sort((a,b) => a.label.localeCompare(b.label, undefined, {numeric: true}))} : {})
                    })
                    if (orgLocation?.customerId) {
                        const customer = toCustomerDisplayName(orgLocation, customers);
                        newOptions.push({
                            entity: 'organizationLocation',
                            field: 'customerId',
                            id: orgLocation.customerId,
                            label: customer,
                            category: L.customer(),
                        })
                    }
                }
                if (stop.loadingTeamId) {
                    const loadingTeam = toStopLoadingTeamName(loadingTeams)(stop);
                    newOptions.push({
                        entity: 'stop',
                        field: 'loadingTeamId',
                        id: stop.loadingTeamId,
                        label: loadingTeam,
                        category: L.loadingTeam()
                    })
                }
                if (stop.cargoTypeId) {
                    const cargoType = getTransCargoTypeLabel(stop.cargoTypeId);
                    newOptions.push({
                        entity: 'stop',
                        field: 'cargoTypeId',
                        id: stop.cargoTypeId,
                        label: cargoType,
                        category: L.cargoType()
                    })
                }
            })

            return options.concat(newOptions);
        }, []);

        return options
            .filter((option, i) => options.findIndex(x => x.id === option.id && x.field === option.field) === i)
            .sort((a, b) => -`${b.category}${b.label}`.localeCompare(`${a.category}${a.label}`, undefined, {numeric: true}))
    }, [data, isLoading]);

    return {
        distinctOptions
    }
}
