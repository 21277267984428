import React from 'react';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { useResourceTable } from './use-resource-table';
import { useUpdateUserPreferences } from '../hooks/use-update-user-preferences';
import { AgisticsTableFooter } from '../table/components/agistics-table-footer';
import { AgisticsTableBody } from '../table/components/agistics-table-body';
import { ResourceTableToolbar } from './resource-table-toolbar';
import { AgisticsTableHeader } from '../table/components/agistics-table-header';

const StyledTable = styled('table')({
    borderCollapse: 'collapse',
    borderSpacing: 0,
    width: '100%',
    minWidth: '900px',
    fontSize: '.875rem',
})

interface ResourceTableProps {
    id: string;
    isLoading: boolean;
    columns: any[];
    data: any[];
    title: string;
    options?: any;
    showDrawer?: boolean;
}

export const ResourceTable: React.FC<ResourceTableProps> = (props) => {
    const { id, isLoading, columns, data, title, options, showDrawer = false } = props;
    const { preferences } = useUpdateUserPreferences();
    const pageSizePreference = preferences?.tables?.[id]?.pageSize || options?.pageSize || 10;
    const table = useResourceTable(columns, data, { pageIndex: 0, pageSize: pageSizePreference });

    return (
        <TableContainer component={Paper} sx={{ marginBottom: '1rem' }}>
            <ResourceTableToolbar
                title={title}
                table={table}
                showDrawer={showDrawer}
            />
            <StyledTable>
                <AgisticsTableHeader table={table} />
                <AgisticsTableBody
                    isLoading={isLoading}
                    table={table}
                />
                <AgisticsTableFooter
                    id={id}
                    table={table}
                />
            </StyledTable>
        </TableContainer>
    );
};
