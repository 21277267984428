import React from 'react';
import { LocationResource, LocationType, OrganizationLocation, Resource } from '../../../types';
import { isCargoStop, SITE_CATEGORIES, STOP_TYPES } from '../../../constants/constants';
import { LocationTypeConflictTooltip } from './tooltips/location-type-conflict-tooltip';
import { Conflict } from './resource-conflict-utils';

//conflict1 - trying to weigh at a location without a scale
//conflict2 - trying to take cargo to a wash site
//these conflicts are almost impossiable to make happen in the UI, maybe different results when sending orders to the api?
//Stop type of Weigh for stop index: 2 does not have a scale at location (similar message for wash site)

//only way I can get conflict1 to happen in UI is create site with a scale and assign a stop to it, then take scale away from the site.
//this will show the conflict, but the load will become uneditable until the stop is changed as we block saving a weigh stop that does not have a scale in the api
export const useGetLocationTypeConflict = (locations: OrganizationLocation[], locationTypes: LocationType[], resource: Resource): Conflict => {
    let conflict = null;
    if (resource.type === 'location') {
        const orgLocation = (locations as OrganizationLocation[]).find(x => x.id === Number(resource.id));
        const locationType = (locationTypes as LocationType[]).find(x => x.id === orgLocation?.organizationLocationTypeId);
        if (resource.stop.type === STOP_TYPES().Weigh.key && orgLocation?.hasScale === false) {
            conflict =  <LocationTypeConflictTooltip resource={resource as LocationResource}/>
        } else if (isCargoStop(resource.stop) && locationType?.category !== SITE_CATEGORIES.SITE) {
            conflict =  <LocationTypeConflictTooltip resource={resource as LocationResource}/>
        }
    }
    return { conflict: conflict }
}
