import React from 'react';
import { ColumnDef, ColumnSort, PaginationState, SortingState, Updater, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useBoolean } from '../shared/hooks/use-boolean';
import { getUserSettings } from './report-utils';
import { ReportRunOptions, ReportRes } from './report-types';
import axios from 'axios';
import { useWeights } from '../shared/hooks/use-weights';

const defaultPagination: PaginationState = {
    pageIndex: 0,
    pageSize: 50,
}

export const useReports = <T>(
    defaultSort: ColumnSort,
    columns: ColumnDef<T, any>[], // can't get TypeScript to figure out the 6+ column types that will be retured from createColumnHelper in react-table
    reportUrl: URL,
    reportParams: URLSearchParams,
    enableSorting: boolean,
) => {
    const [data, setData] = React.useState<ReportRes | null>(null);
    const [pagination, setPagination] = React.useState<PaginationState>(defaultPagination);
    const [sorting, setSorting] = React.useState<SortingState>([defaultSort]);
    const [isLoading, startLoading, endLoading] = useBoolean(false);
    const { weightSystem } = useWeights();

    const runReport = async (runOptions: ReportRunOptions) => {
        !runOptions.csv && startLoading();
        const userSettings = getUserSettings();
        const pageIndex = runOptions.pageIndex === undefined ? pagination.pageIndex : runOptions.pageIndex;
        const pageSize = runOptions.pageSize || pagination.pageSize;

        const sortColumn = runOptions.sortColumn || sorting[0].id;
        const sortDirection = runOptions.sortDirection || (sorting[0].desc ? 'desc' : 'asc');

        reportParams.set('language', userSettings.language);
        reportParams.set('timezone', userSettings.timezone);
        reportParams.set('unitsDistance', userSettings.units);
        reportParams.set('unitsWeight', weightSystem);
        reportParams.set('pageNumber', String(pageIndex + 1));
        reportParams.set('pageSize', String(pageSize));
        reportParams.set('sortColumn', sortColumn);
        reportParams.set('sortDirection', sortDirection);

        if (runOptions.csv) {
            reportParams.set('csv', 'true');
            window.location.href = `${reportUrl.toString()}?${reportParams.toString()}`;
        } else {
            const url = `${reportUrl.toString()}?${reportParams.toString()}`
            const reportData = (await axios.get<ReportRes>(url)).data;
            setData(reportData);
        }
        !runOptions.csv && endLoading();
    }

    const onPaginationChange = (updater: Updater<PaginationState>) => {
        if (typeof updater === 'function') {
            const newPagination = updater(pagination);
            setPagination(newPagination);
            runReport({ pageIndex: newPagination.pageIndex, pageSize: newPagination.pageSize });
        }
    }

    const onSortingChange = (updater: Updater<SortingState>) => {
        if (typeof updater === 'function') {
            const newSort = updater(sorting);
            setSorting(newSort);
            runReport({ sortColumn: newSort[0].id, sortDirection: newSort[0].desc ? 'desc' : 'asc' });
        }
    }

    const table = useReactTable<T>({
        data: data ? data.data : [],
        columns,
        columnResizeMode: 'onChange',
        columnResizeDirection: 'ltr',
        getCoreRowModel: getCoreRowModel(),
        rowCount: data?.info.paging.totalRecords,
        pageCount: data?.info.paging.totalPages,
        state: {
            pagination,
            sorting,
        },
        onPaginationChange: onPaginationChange,
        manualPagination: true,
        enableSorting: enableSorting,
        enableSortingRemoval: false, // removes tri-state sort
        onSortingChange: onSortingChange,
        // defaultColumn: {
        //     minSize: 1,
        //     size: 50,
        // },
        // debugTable: true,
        // debugHeaders: true,
        // debugColumns: true,
    });

    return {
        data,
        runReport,
        table,
        isLoading,
    }
}
