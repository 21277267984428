import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { ProductionCardType, SortableProductionCard } from './production-card';
import { CircularLoading } from '../../shared/circular-loading';
import BucketSearch from './bucket-search';
import { useBucketFiltering } from './use-bucket-filtering';
import { UniqueIdentifier, useDndMonitor } from '@dnd-kit/core';
import { BucketId } from './use-generation-buckets';

const StyledPaper = styled(Paper)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    margin: '1rem',
    backgroundColor: theme.palette.grey['200'],
    boxShadow: theme.shadows[3],
    height: '45vh',
}));

interface CardsProps {
    isDraggingOver: boolean;
}

const Cards = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isDraggingOver',
})<CardsProps>(({ isDraggingOver, theme }) => ({
    flex: 1,
    transition: 'background-color 0.2s ease',
    overflowY: 'scroll',
    padding: '0.5rem 1rem',
    '&::-webkit-scrollbar': {
        width: '0.4rem',
        backgroundColor: theme.palette.grey['200']
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.grey['800']
    },
    ...(isDraggingOver && {
        backgroundColor: theme.palette.grey['500']
    })
}));

interface ProductionBucketProps {
    id: BucketId;
    findContainer: (u: UniqueIdentifier) => BucketId | null;
    isLoading?: boolean;
    cards: ProductionCardType[];
    children?: React.ReactNode;
    disableSearch?: boolean;
}

const ProductionBucket: React.FC<ProductionBucketProps> = (props) => {
    const {
        id,
        findContainer,
        isLoading = false,
        cards,
        children,
        disableSearch = false,
    } = props;

    const [search, setSearch] = React.useState('');
    const filtered = useBucketFiltering(cards, search);
    const [isDraggingOver, setIsDraggingOver] = React.useState(false);

    useDndMonitor({
        onDragOver(event) {
            const { over } = event;
            if (over) {
                const container = findContainer(over.id);
                if (container === id) {
                    setIsDraggingOver(true);
                } else {
                    setIsDraggingOver(false);
                }
            } else {
                setIsDraggingOver(false);
            }
        },
        onDragEnd() {
            setIsDraggingOver(false);
        }
    });

    return (
        <StyledPaper>
            {isLoading && <CircularLoading/>}
            {!disableSearch &&
            <BucketSearch searchText={search} setSearchText={setSearch} numOfRecords={cards.length}/>}
            <Cards
                isDraggingOver={isDraggingOver}
            >
                {filtered.map((card, i) => {
                    return (
                        <SortableProductionCard
                            key={card.id}
                            card={card}
                        />
                    )
                })}
            </Cards>
            {children}
        </StyledPaper>
    );
};

export default ProductionBucket;
