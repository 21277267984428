import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export const ConfirmationDialog = (props) => {
    const { open, title, message, cancelText, confirmText, resultCallback } = props;

    return (
        <Dialog
            data-testid='confirmation-dialog'
            open={open}
            onClose={() => resultCallback(false)}
        >
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => resultCallback(false)} color='primary' autoFocus>
                    {cancelText}
                </Button>
                <Button onClick={() => resultCallback(true)} color='primary' autoFocus>
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    resultCallback: PropTypes.func
};
