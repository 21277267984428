import { useStaticQuery } from '../../api/queries/use-static-query';
import { DynamicQueryKeys } from '../../api/config';
import { getWorkingWeek } from '../../utils/date-time-utils';
import { useUser } from '../../api/queries/use-user';

export const useResourceDowntimes = (options = {}) => {
    const now = getWorkingWeek(-1).today;
    const { organizationId } = useUser();

    return useStaticQuery(DynamicQueryKeys.resourceDowntime(organizationId, now), options);
};


export const invalidateDowntimeCache = (companyId, queryClient) => {
    const now = getWorkingWeek(-1).today;

    queryClient.invalidateQueries([DynamicQueryKeys.resourceDowntime(companyId, now)], {
        refetchType: 'inactive',
    });
};
