import React from 'react';
import { DeviceLocation } from '../../types';
import { HereMapMarker } from './here-map-marker';
import ReactDOMServer from 'react-dom/server';

//when here adds the marker to the map, it overwrites the CSS property 'transform' so can't rotate that way.
const getHeading = (heading: number) => {
    if (heading >= 292.5 && heading < 337.5) {
        //northwest
        return {
            cardinal: false,
            rotateString: 'rotate(0, 8, 8)'
        };
    }
    if (heading >= 337.5 || heading < 22.5) {
        //north
        return {
            cardinal: true,
            rotateString: 'rotate(270, 8, 8)',
        };
    }
    if (heading >= 22.5 && heading < 67.5) {
        //northeast
        return {
            cardinal: false,
            rotateString: 'rotate(90, 8, 8)'
        };
    }
    if (heading >= 67.5 && heading < 112.5) {
        //east
        return {
            cardinal: true,
            rotateString: 'rotate(0, 8, 8)',
        };
    }
    if (heading >= 112.5 && heading < 157.5) {
        //southeast
        return {
            cardinal: false,
            rotateString: 'rotate(180, 8, 8)'
        };
    }
    if (heading >= 157.5 && heading < 202.5) {
        //south
        return {
            cardinal: true,
            rotateString: 'rotate(90, 8, 8)',
        };
    }
    if (heading >= 202.5 && heading < 247.5) {
        //southwest
        return {
            cardinal: false,
            rotateString: 'rotate(270, 8, 8)'
        };
    }
    if (heading >= 247.5 && heading < 292.5) {
        //west
        return {
            cardinal: true,
            rotateString: 'rotate(180, 8, 8)',
        }
    }
    return {
        cardinal: true,
        rotateString: 'rotate(0, 8, 8)',
    }
};

//default is east
const getCardinalSvg = (rotateString: string) => {
    return (
        <div style={{ position: 'relative', top: '-8px', left: '-8px' }}>
            <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                <g transform={rotateString}>
                    <line strokeWidth="2" stroke="#ffff00" id="card_svg1" y2="8" x2="8" y1="0" x1="0" fill="none" />
                    <line strokeWidth="2" stroke="#ffff00" id="card_svg2" y2="8" x2="8" y1="16" x1="0" fill="none" />
                </g>
            </svg>
        </div>
    );
}

//default is northwest
const getOrdinalSvg = (rotateString: string) => {
    return (
        <div style={{ position: 'relative', top: '-8px', left: '-8px' }}>
            <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                <g transform={rotateString}>
                    <line strokeWidth="4" stroke="#ffff00" id="ord_svg1" y2="0" x2="11" y1="0" x1="0" fill="none" />
                    <line strokeWidth="4" stroke="#ffff00" id="ord_svg2" y2="11" x2="0" y1="0" x1="0" fill="none" />
                </g>
            </svg>
        </div>
    );
}

const getHeadingIcon = (heading: number) => {
    const headingOptions = getHeading(heading);
    let svg;
    if (headingOptions.cardinal) {
        svg = getCardinalSvg(headingOptions.rotateString);
    } else {
        svg = getOrdinalSvg(headingOptions.rotateString);
    }

    return ReactDOMServer.renderToString(svg);
}

type HereMapHeadingProps = {
    location: DeviceLocation;
    type: 'mobile' | 'trailer';
}

export const HereMapHeading: React.FC<HereMapHeadingProps> = (props) => {
    const { location, type } = props;

    if (location.heading) {
        return (
            <HereMapMarker
                icon={getHeadingIcon(location.heading)}
                latitude={location.latitude}
                longitude={location.longitude}
                visibility={false}
                type={type}
            />
        );
    } else {
        return null;
    }
}
