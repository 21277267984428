import React from 'react';
import PropTypes from 'prop-types';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

export const BulkPreviewTableBody = (props) => {
    const { projections } = props;

    return (
        <TableBody className='bulk-entry-table-body'>
            {projections.map((projection, i) => (
                <TableRow className='bulk-preview-table-row' key={i}>
                    <TableCell className='left-align'>{projection.date}</TableCell>
                    <TableCell className='left-align'>{projection.location}</TableCell>
                    <TableCell className='left-align'>{projection.cargoType}</TableCell>
                    <TableCell className='left-align'>{projection.loadSize}</TableCell>
                    <TableCell className='left-align'>{projection.loadWeight}</TableCell>
                </TableRow>
            ))}
        </TableBody>
    );
};

BulkPreviewTableBody.propTypes = {
    projections: PropTypes.array
};
