import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Edit from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { L } from 'harmony-language';
import { ModalDialog } from '../../../shared/containers/modal-dialog';
import { useBoolean } from '../../../shared/hooks/use-boolean';

export const SiteInstructionsInput = ({ organizationLocation, setOrganizationLocation }) => {
    const [siText, setSiText] = React.useState('');

    React.useEffect(() => {
        setSiText(organizationLocation.siteInstructions);
    }, [organizationLocation]);

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        close();
        setOrganizationLocation({ ...organizationLocation, siteInstructions: siText });
    };

    const [isOpen, open, close] = useBoolean(false);
    return (
        <>
            <Button color='secondary' onClick={open} style={{ width: '100%' }}>
                <Edit sx={{ padding: '0 5px' }} />{L.siteInstructions()}
            </Button>
            <ModalDialog title={L.siteInstructions()} open={isOpen} onClose={close}>
                <form data-testid='site-instructions-form' style={{ width: '25vw' }} onSubmit={handleSubmit}>
                    <TextField
                        variant='standard'
                        id='location-site-instructions-input'
                        label={L.siteInstructions()}
                        autoFocus={true}
                        multiline
                        maxRows='10'
                        minRows='4'
                        value={siText}
                        onChange={(e) => setSiText(e.target.value)} />
                    <div>
                        <Button
                            data-testid='site-instructions-save'
                            sx={{ marginTop: '1rem', float:'right' }}
                            type='submit'
                            variant='contained'
                            color='primary'>{L.save()}
                        </Button>
                    </div>
                </form>
            </ModalDialog>
        </>
    );
};

SiteInstructionsInput.propTypes = {
    organizationLocation: PropTypes.object,
    setOrganizationLocation: PropTypes.func,
};
