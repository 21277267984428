import React from 'react';
import { ResourcePageResourceType, Stop } from '../../../types';
import { ResourceConflictContainer } from '../resource-conflicts/resource-conflict-container';
import { L } from 'harmony-language';
import { InputProps, ListSubheader, MenuItem, TextField, TextFieldProps } from '@mui/material';

type ItemId = number | string;

export type Item = {
    id: ItemId,
    label: string,
    category?: string,
    cargoTypeIds?: number[],
    isCategory?: boolean,
}

type ResourceSelectProps<T extends ItemId> = Omit<TextFieldProps, 'onChange' | 'disabled'> & {
    itemId?: T | null,
    itemList: Item[],
    resourceType?: ResourcePageResourceType,
    onChange: (id: T | null) => void
    label?: React.ReactNode,
    isDisabled?: boolean;
    inputProps?: InputProps['inputProps'];
    stop?: Stop;
    hideNone?: boolean;
}

export const ResourceSelect = <T extends ItemId>(props: ResourceSelectProps<T>) => {
    const {
        itemId,
        itemList,
        resourceType,
        onChange,
        label,
        isDisabled,
        inputProps,
        stop,
        hideNone = false,
        ...rest
    } = props;

    const list = (itemList as any).sortBy((l: Item) => `${l.category}${l.label}`).reduce((acc: Item[], item: Item) => {
        if (item.category && !acc.some(x => x.category === item.category)) {
            acc.push({id: -1, isCategory: true, label: item.category})
        }
        acc.push(item);
        return acc;
    }, [])

    return (
        <TextField
            variant='standard'
            {...rest}
            fullWidth
            label={label}
            select
            disabled={isDisabled}
            inputProps={inputProps}
            InputProps={{
                ...inputProps,
                // @ts-ignore
                'data-testid': 'resource-select-item'
            }}
            value={itemId || (hideNone ? '' : -1)}
            onChange={(e) => {
                const id = e.target.value as T;
                if(id){
                    onChange(id === -1 ? null : id)
                }
            }}>
            {list.map((item: Item, i: number) => {
                if (item.isCategory) {
                    return <ListSubheader style={{backgroundColor: 'white'}} key={i}>{item.label}</ListSubheader>
                }

                return (
                    <MenuItem key={i} value={item.id}>
                        {resourceType ?
                            <ResourceConflictContainer
                                resource={{
                                    id: item.id,
                                    type: resourceType,
                                    cargoTypeIds: item.cargoTypeIds,
                                    stop: stop
                                }}>
                                {item.label}
                            </ResourceConflictContainer> :
                            item.label}
                    </MenuItem>
                )
            })}
            {!hideNone && <MenuItem value={-1}>
                {L.none()}
            </MenuItem>}
        </TextField>
    );
}
