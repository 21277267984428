/* eslint-disable no-use-before-define */

import React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import { L } from 'harmony-language';
import { Menu, MenuItem } from '@mui/material';
import './omni-complete.css';

const OptionTag = styled('div')({
    cursor: 'default',
});

const ChipSuperCategory = styled('div')(({ theme }) => ({
    fontSize: '.7rem',
    lineHeight: '.5rem',
    color: theme.palette.grey[900],
    paddingLeft: '.6rem',
    paddingTop: '.3rem',
}));

const ChipCategory = styled('span')(({ theme }) => ({
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.grey[900],
    display: 'inline-block',
    marginRight: '.2rem',
    paddingLeft: '0.5rem',
    borderRadius: '1rem 0 0 1rem',
    paddingRight: '0.3rem',
    height: '1.5rem',
}));

const icon = <CheckBoxOutlineBlankIcon fontSize='small'/>;
const checkedIcon = <CheckBoxIcon fontSize='small'/>;

export const OmniComplete = (props) => {
    const { value, onChange, options, isLoading } = props;

    const size = 'small';

    const [acData, setAcData] = React.useState(value);

    React.useEffect(() => {
        setAcData(value);
    }, [value])

    const wrappedOnChange = (e, arr) => {
        setAcData(arr);
        onChange(e, [...arr]);
    }

    return (
        <Autocomplete
            id='omni-complete'
            data-testid={'omni-complete'}
            multiple
            fullWidth
            classes={{ inputRoot: 'autoCompleteInput', option: 'option', listbox: 'listbox', groupLabel: 'groupLabel' }}
            loading={isLoading}
            loadingText={L.loading()}
            noOptionsText={L.noOptions()}
            clearText={L.clear()}
            closeText={L.close()}
            openText={L.open()}
            value={acData}
            onChange={wrappedOnChange}
            groupBy={(option) => option.category}
            isOptionEqualToValue={(a, b) => a.category === b.category && a.id === b.id}
            options={options}
            filterOptions={createFilterOptions({ stringify: (option) => `${option.category}\u0000${option.label}` })}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            size={size}
            renderOption={(props, option, { selected }) => {
                return <li {...props} key={option.label + option.id}>
                    <Checkbox
                        size={size}
                        icon={icon}
                        checkedIcon={checkedIcon}
                        sx={{ marginRight: 0 }}
                        checked={selected}
                    />
                    {option.label}
                </li>
            }}
            renderInput={(params) => (
                <TextField {...params} variant='outlined' label={L.filters()} placeholder={L.filters()}/>
            )}
            renderTags={(value, getTagProps) => (
                acData.map((option, i) => <OmniChip allValues={value} onChange={wrappedOnChange} getTagProps={getTagProps} index={i} key={i} option={option} />)
            )}
        />
    );
};

OmniComplete.propTypes = {
    isLoading: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.array,
    options: PropTypes.array
};

const OmniChip = (props) => {
    const {option, allValues, onChange, getTagProps, size='small', index: i} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [subSelection, setSubSelection] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const handleChange = (event) => {
        setSubSelection(event?.target?.value);
    };
    const handleClose = async (e, option) => {
        setOpen(false);
        setAnchorEl(null);
        if (e?.target?.value || e?.target?.value === 0) {
            handleChange(e);
            const foundIndex = allValues.findIndex(x => {
                return (option.entity === x.entity &&
                        option.field === x.field &&
                        option.id === x.id);
            });
            const child = e.target.value === 0 ? undefined : option.children.find(x => x.id === e.target.value);
            const modifiedValue = {
                ...allValues[foundIndex],
                selectedChild: {...child},
            }
            await onChange(e, [
                ...allValues.slice(0,foundIndex),
                modifiedValue,
                ...allValues.slice(foundIndex + 1)
            ])
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        handleOpen();
      };

    const renderChipLabel = (option) => {
        if (option.children) {
            const child = option.selectedChild ? option.children.find(x => x.id === option.selectedChild.id) : null
            const label = child ? `${option.label} - ${child.label} \u25BE` : `${option.label} \u25BE`
            return <>
                <span onClick={handleClick} style={{cursor: 'pointer'}}>{label}</span>
                <Menu
                value={subSelection}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onChange={handleChange}
                >
                    <MenuItem onClick={async (e) => await handleClose(e, option)} value={''}>{L.none()}</MenuItem>
                    {option.children.map(x => <MenuItem onClick={async (e) => await handleClose(e, option)} key={x.id} value={x.id}>{x.label}</MenuItem> )}
                </Menu>
            </>
        } else {
            return <span>{`${option.label}`}</span>
        }
    }

    return (
        <OptionTag key={i}>
            <ChipSuperCategory>{option.superCategory}</ChipSuperCategory>
            <Chip classes={{ root: 'outerChip' }}
                size={size}
                label={
                      <>
                          <ChipCategory>{option.category}</ChipCategory>
                          {renderChipLabel(option, i)}
                      </>
                }{...getTagProps({ index: i })}/>
        </OptionTag>
    );
}
OmniChip.propTypes = {
    option: PropTypes.object,
    allValues: PropTypes.array,
    onChange: PropTypes.func,
    getTagProps: PropTypes.func,
    index: PropTypes.number,
    size: PropTypes.string
}
