import React from 'react';
import { styled } from '@mui/material/styles';
import { QueryKeys, defaultQueryOptions } from '../../../api/config';
import { QueryFunctionContext, useQuery, useQueryClient } from '@tanstack/react-query';
import { OrderStatus } from 'harmony-constants';
import { Load } from '../../../types';
import { useLoadsColumns } from '../../loads/use-loads-columns';
import { LoadProvider } from '../../shared/load-context';
import { LoadTableRow } from '../../shared/load-table/load-table-row';
import { Button, Table } from '@mui/material';
import LoadsTableHeader from '../../shared/load-table/loads-table-header';
import { useLoadSorting } from '../../shared/load-table/utils/use-load-sorting';
import { useLoadSelection } from '../../shared/load-table/utils/use-load-selection';
import { L } from 'harmony-language';
import axios from 'axios';
import { CircularLoading } from '../../shared/circular-loading';
import { useDraftsKey } from '../../../api/config-hooks';

const UnmergeTable = styled(Table)(({ theme }) => ({
    '& .grid': {
        display: 'grid',
        fontSize: '.875rem',
        gridTemplateRows: '1fr',
        borderBottom: '1px solid #e0e0e0',
        gridTemplateColumns: '6fr 7fr 10fr 7fr 7fr 10fr 10fr repeat(3, 7fr) 5fr 55px',
    },
    '& .grid:nth-child(2n+2)': {
        backgroundColor: theme.palette.grey['100'],
    },
    '& .grid > div': {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: '2px 5px',
    },
}));

interface UnmergeDraftsProps {
    loadId: number;
    mergedFromOrderIds: number[];
    unmergeCloseDialog: () => React.Dispatch<React.SetStateAction<boolean>>;
}

export const UnmergeDrafts: React.FC<UnmergeDraftsProps> = (props) => {
    const { loadId, mergedFromOrderIds, unmergeCloseDialog } = props;
    const draftsKey = useDraftsKey();

    const { data = [] } = useQuery<Load[]>([QueryKeys.drafts, mergedFromOrderIds], {
        queryFn: (queryFunctionContext: QueryFunctionContext) => {
            return defaultQueryOptions.queries.queryFn(queryFunctionContext, {
                params: {
                    status: OrderStatus.Merged,
                    mergedFromOrderIds: mergedFromOrderIds,
                }
            })
        },
        staleTime: 5 * 60 * 1000, // 5 mins
        cacheTime: 0,
    });
    const { columns, stopColumns } = useLoadsColumns();
    const selection = useLoadSelection(data);
    const sorting = useLoadSorting(data, columns);
    const queryClient = useQueryClient();

    const unmerge = async () => {
        await axios.post(`${API_BASE}/api/orders/unmerge?orderIds=[${loadId}]`, {
            mergedFromOrderIds: mergedFromOrderIds,
        });
        queryClient.invalidateQueries(draftsKey);
        unmergeCloseDialog();
    };

    if (data.length) {
        return (
            <>
                {/* @ts-ignore for some reason styled returns different type here? */}
                <UnmergeTable component='div'>
                    <LoadsTableHeader columns={columns} sorting={sorting} selection={selection} showSelection={false} />
                    {sorting.sortedLoads.map(load => {
                        return (
                            <LoadProvider key={load.id} load={load} disableConflicts={true}>
                                <LoadTableRow
                                    toggle={() => selection.toggle(load)}
                                    isSelected={selection.isSelected(load)}
                                    columns={columns}
                                    stopColumns={stopColumns}
                                    showSelection={false} />
                            </LoadProvider>
                        )
                    })}
                </UnmergeTable>
                <div style={{ paddingTop: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant='contained' color='primary' onClick={unmerge}>{L.unmerge()}</Button>
                </div>
            </>
        );
    } else {
        return <div style={{ width: '40vw', height: '20vh' }}><CircularLoading /></div>;
    }
};
