import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions } from '../config';
import { useSnackbar } from '../../components/notifications/use-snackbar';
import { Load, LoadCache } from '../../types';
import { useDraftsKey } from '../config-hooks';

async function modifyDrafts(options: { drafts: Load[] }) {
    const drafts = options.drafts;

    return (await axios.put(`${API_BASE}/api/orders`, drafts)).data;
}

export const useDraftsMutation = () => {
    const snackbar = useSnackbar();
    const draftsKey = useDraftsKey();
    const queryClient = useQueryClient();

    return useMutation(modifyDrafts, {
        ...mutationOptions(draftsKey, queryClient),
        onMutate: ({ drafts }: { drafts: Load[] }) => {
            queryClient.cancelQueries(draftsKey);

            const draftIds = drafts.map(d => d.id);
            const previousCache = queryClient.getQueryData<LoadCache>(draftsKey);
            const newDrafts = previousCache?.data?.map((existingDraft) => {
                if (draftIds.includes(existingDraft.id)) {
                    return {
                        ...existingDraft,
                        ...drafts.find(d => d.id === existingDraft.id),
                    };
                }
                return existingDraft;
            });

            // Optimistically update to the new value
            queryClient.setQueryData(draftsKey, {
                ...previousCache,
                data: newDrafts
            });

            // Return a rollback function
            return () => queryClient.setQueryData(draftsKey, previousCache);
        },
        onSuccess: (data) => {
            snackbar(L.draftsUpdateSuccessful(data.length));
        },
        onSettled: () => {}
    });
};
