import { Stack } from '@mui/material';
import TableSortLabel from '@mui/material/TableSortLabel';
import { styled } from '@mui/material/styles';
import { Table, flexRender } from '@tanstack/react-table';
import React from 'react';
import { DistinctColumnFilter } from './distinct-column-filter';

interface AgisticsTableHeaderProps {
    table: Table<any>;
}

const TableRowHeaderCell = styled('td')(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

export const AgisticsTableHeader: React.FC<AgisticsTableHeaderProps> = (props) => {
    const { table } = props;

    return (
        <thead>
            {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => {
                        const isSorted = header.column.getIsSorted();
                        const canSort = header.column.getCanSort();
                        return (
                            <TableRowHeaderCell
                                key={header.id}
                                onClick={(e) => {
                                    // Disallows children of header cell to call toggle fn
                                    // @ts-ignore
                                    if (e.target.id === 'headerCell') {
                                        header.column.toggleSorting();
                                    }
                                }}
                                style={{
                                    width: header.getSize(),
                                    padding: '0.5rem 1rem',
                                }}
                                className={canSort ? 'cursor-pointer' : ''}
                            >
                                <Stack
                                    id='headerCell'
                                    direction='row'
                                    alignItems='center'
                                    justifyContent={header.id === 'actions' ? 'flex-end' : undefined}
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )
                                    }
                                    {canSort && (
                                        <TableSortLabel
                                            active={Boolean(isSorted)}
                                            direction={isSorted ? isSorted : undefined}
                                        />
                                    )}
                                    {header.column.getCanFilter() && (
                                        <DistinctColumnFilter column={header.column} />
                                    )}
                                </Stack>
                            </TableRowHeaderCell>
                        )
                    })}
                </tr>
            ))}
        </thead>
    );
}
