import React from 'react';
import PropTypes from 'prop-types';
import '../../scss/loading-spinner.scss';

export const LoadableComponent = ({ children, isLoading }) => {
    return (
        <React.Fragment>
            {isLoading && <div className='loading-spinner-background'/>}
            {isLoading && <div className='loading-spinner' data-testid='loading-spinner'/>}
            {children}
        </React.Fragment>
    );
};

LoadableComponent.propTypes = {
    children: PropTypes.element,
    isLoading: PropTypes.bool
};
