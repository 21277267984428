import React from 'react';
import TextField from '@mui/material/TextField';
import { EitherOrganizationLocation } from './edit-create-location';

interface AddressItemInputProps {
    addressItem: keyof Pick<EitherOrganizationLocation, 'address1' | 'address2' | 'city' | 'state' | 'postalCode' | 'country'>;
    organizationLocation: EitherOrganizationLocation;
    setOrganizationLocation: (o: EitherOrganizationLocation) => void;
    label: string;
    optional?: boolean;
}

export const AddressItemInput: React.FC<AddressItemInputProps> = (props) => {
    const { addressItem, organizationLocation, setOrganizationLocation, label, optional = false } = props;

    return (
        <TextField
            variant='standard'
            required={!optional}
            label={label}
            id={label}
            value={organizationLocation[addressItem] || ''}
            style={{ width: '100%' }}
            onChange={(e) => {
                setOrganizationLocation({
                    ...organizationLocation,
                    [addressItem]: e.target.value,
                });
            }}
            inputProps={{ maxLength: 200 }}
        />
    );
};
