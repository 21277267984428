import React from 'react';
import { Select } from '../../../shared/inputs/select';
import { CarrierEditLoadOption } from 'harmony-constants';
import { OrganizationCarrier } from '../../../../types';

interface CarrierSettingSelectProps {
    resourceEditList: { id: string, label: string }[];
    setting: CarrierEditLoadOption;
    isDisabled: boolean;
    isActive: boolean;
    selectedOrganizationCarriers: OrganizationCarrier[];
    avaliableCarrierId: number;
    onChange: (organizationCarriers: OrganizationCarrier[]) => void;
    propertyName: 'loadDriver' | 'loadTractor' | 'loadTrailer';
}

export const CarrierSettingSelect: React.FC<CarrierSettingSelectProps> = (props) => {
    const { resourceEditList, setting, isDisabled, isActive, selectedOrganizationCarriers, avaliableCarrierId, onChange, propertyName } = props;

    return (
        <Select
            item={resourceEditList.find(x => x.id === setting)}
            disabled={!isDisabled || !isActive}
            list={resourceEditList}
            onChange={(value) => {
                const newSetting: CarrierEditLoadOption = value.id;
                const currentCarrier = selectedOrganizationCarriers.find(x => x.carrierOrganizationId === avaliableCarrierId);
                if (currentCarrier) {
                    const updatedSelectedOrganizationCarriers = selectedOrganizationCarriers.map(x => {
                        if (x.carrierOrganizationId === currentCarrier.carrierOrganizationId) {
                            return { ...x, [propertyName]: newSetting }
                        }
                        return x;
                    });
                    onChange(updatedSelectedOrganizationCarriers);
                }
            }} />
    );
};
