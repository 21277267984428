/* eslint-disable */
import React from 'react';
import { DictionaryKey, L } from 'harmony-language';
import { localDateTimeDisplay } from '../../utils/date-time-utils';
import { checklistToMaxSeverityLabel } from '../../utils/data-mapping-utils';
import { ActionIcon } from '../shared/table/components/action-icon';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../api/config';
import { useTractorTrailer } from '../../api/queries/use-tractor-trailer';
import { ResourceType } from 'harmony-constants';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { Tractor, TractorTrailerResource, Trailer, User } from '../../types';

export type MaintenanceResourceType = {
    type: TractorTrailerResource;
    rowId: number;
};
export type TractorTrailerResourceWithType =
    | (Tractor & MaintenanceResourceType)
    | (Trailer & MaintenanceResourceType);

interface MaintenanceRow {
    id: number;
    resourceType: TractorTrailerResource;
    resourceId: number;
    completedByUserId: number;
    usedResponses: number;
    hasNotes: boolean;
    updatedAt: string;
    createdAt: string;
}

interface MaintenanceAction {
    icon: string;
    tooltip: string;
    action: (row: MaintenanceRow) => void;
}

type IsResource<R extends TractorTrailerResource> =
    R extends ResourceType.Tractor ? Tractor : Trailer;

export const useMaintenanceColumns = ({
    isHistory
}: {
    isHistory?: boolean;
} = {}) => {
    const navigate = useNavigate();
    const { data: users, isLoading: isLoadingUsers } = useOrganizationQuery<
        User[]
    >(OrgQueryKeys.usersCarriers);
    const { tractors, trailers, isLoadingTractors, isLoadingTrailers } =
        useTractorTrailer();
    const isLoading = isLoadingUsers || isLoadingTractors || isLoadingTrailers;
    const [resource, setResource] =
        React.useState<TractorTrailerResourceWithType | null>(null);
    const columnHelper = createColumnHelper<MaintenanceRow>();

    const actions = React.useMemo(() => {
        const actions: MaintenanceAction[] = [
            {
                icon: 'assignment_turned_in_outlined',
                tooltip: L.checklistView(),
                action: (row: MaintenanceRow) => {
                    const resources = { Tractor: tractors, Trailer: trailers };
                    const resource = (
                        resources[row.resourceType] as
                            | IsResource<typeof row.resourceType>[]
                            | undefined
                    )?.find((x) => x.id === row.resourceId);

                    setResource({
                        ...resource,
                        type: row.resourceType,
                        rowId: row.id
                    } as TractorTrailerResourceWithType);
                }
            }
        ];
        if (!isHistory) {
            actions.push({
                icon: 'history',
                tooltip: L.historic(),
                action: (row: MaintenanceRow) => {
                    navigate(`/maintenance-history/${row.resourceType}/${row.resourceId}`);
                }
            });
        }
        return actions;
    }, [tractors, trailers]);

    const columns = React.useMemo(() => {
        const resources = {
            [ResourceType.Tractor]: tractors,
            [ResourceType.Trailer]: trailers
        };
        const findResource = (row: MaintenanceRow) =>
            (
                resources[row.resourceType] as
                    | IsResource<typeof row.resourceType>[]
                    | undefined
            )?.find((x) => x.id === row.resourceId);
        const displayUser = (userId: number) => {
            const user = users?.find((x) => x.id === userId);
            return user?.name;
        };

        const c = [
            // @ts-ignore
            columnHelper.accessor('userDisplayName', {
                header: L.name(),
                cell: (info) => findResource(info.row.original)?.userDisplayName,
                enableColumnFilter: false,
            }),
            // @ts-ignore
            columnHelper.accessor('licensePlate', {
                header: L.licensePlate(),
                cell: (info) => findResource(info.row.original)?.licensePlate,
                enableColumnFilter: false,
            }),
            columnHelper.accessor('resourceType', {
                header: L.type(),
                cell: (info) => L[info.row.original.resourceType.toLowerCase() as DictionaryKey](),
                enableColumnFilter: false,
            }),
            columnHelper.accessor('usedResponses', {
                header: L.maxSeverity(),
                cell: (info) => checklistToMaxSeverityLabel(info.getValue()),
                enableColumnFilter: false,
            }),
            columnHelper.accessor('hasNotes', {
                header: L.hasNotes(),
                cell: (info) => info.getValue() ? L.yes() : L.no(),
                enableColumnFilter: false,
            }),
            columnHelper.accessor('completedByUserId', {
                header: L.completedBy(),
                cell: (info) => displayUser(info.getValue()),
                enableColumnFilter: false,
            }),
            columnHelper.accessor('updatedAt', {
                header: L.date(),
                cell: (info) => localDateTimeDisplay(info.getValue()),
                enableColumnFilter: false,
            }),
            // @ts-ignore
            columnHelper.accessor('actions', {
                header: () => L.actions(),
                cell: (info) => {
                    const row = info.row.original;

                    return (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {actions?.map((a) => (
                                <ActionIcon
                                    key={a.icon}
                                    action={() => a.action(row)}
                                    icon={a.icon}
                                    tooltip={a.tooltip}
                                    testId={row.id}
                                />
                            ))}
                        </div>
                    );
                },
                enableColumnFilter: false,
                enableSorting: false,
            }),

        ]

        return c;
    }, [users, tractors, trailers, actions]);
    return { columns, isLoading, resource, setResource };
};
