import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions, OrgQueryKeys } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { invalidateDowntimeCache } from '../../../components/downtime/use-resource-downtimes';

export const useAddOrganizationResourceDowntimeMutation = (companyId, queryKey, resourceName, resourceId) => {
    const snackbar = useSnackbar();
    const key = OrgQueryKeys.resolveDowntime(companyId, queryKey, resourceId);
    const queryClient = useQueryClient();

    return useMutation(async (resource) => {
        resource.organizationId = companyId;
        return (await axios.post(`${API_BASE}/api${key}`, resource)).data;
    }, {
        ...mutationOptions([key], queryClient),
        onSuccess: (data) => {
            const resources = queryClient.getQueryData([key]);
            const newResources = [...resources, data].sortBy(u => u.id);

            queryClient.setQueryData(key, newResources);

            invalidateDowntimeCache(companyId);
            snackbar(L.updatedMessage(resourceName));
        }
    });
};
