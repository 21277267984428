import React from 'react';
import { AdminBreadCrumbHeader } from '../admin-bread-crumb-header';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { L } from 'harmony-language';
import { ResourceTable } from '../../shared/resource-table/resource-table';
import { Content } from '../../shared/containers/content';
import { useNotificationConfigColumns } from './use-notification-config-columns';
import { ModalDialog } from '../../shared/containers/modal-dialog';
import { AddEditNotification } from './add-edit-notification/add-edit-notification';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { Contact, Customer, NotificationConfig, OrganizationLocation } from '../../../types';
import { OrgQueryKeys } from '../../../api/config';
import { useCargoTypes } from '../../../api/queries/use-cargo-types';
import { useAddOrganizationResourceMutation } from '../../../api/mutations/add/use-add-organization-resource-mutation';
import { useEditOrganizationResourceMutation } from '../../../api/mutations/edit/use-edit-organization-resource-mutation';
import { ConfirmationDialog } from '../../shared/confirmation-dialog';
import { useDeleteOrganizationResourceMutation } from '../../../api/mutations/delete/use-delete-organization-resource-mutation';
import { NotificationEventTypes } from 'harmony-constants';
import { notificationEventTypeTranslationHelper } from './name-helpers';

export const NotificationConfigManagement = () => {
    const { data: notificationConfigs = [], isLoading: isLoadingNotificationConfigs } = useOrganizationQuery<NotificationConfig[]>(OrgQueryKeys.notificationConfigs);
    const { data: contacts = [], isLoading: isLoadingContacts } = useOrganizationQuery<Contact[]>(OrgQueryKeys.contacts);
    const { data: organizationLocations = [], isLoading: isLoadingLocations, organizationId: organizationId } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { data: customers = [], isLoading: isLoadingCustomers } = useOrganizationQuery<Customer[]>(OrgQueryKeys.customers);
    const { cargoTypeList } = useCargoTypes();
    const { mutate: addNotificationConfig, isLoading: isCreating } = useAddOrganizationResourceMutation(OrgQueryKeys.notificationConfigs, L.notificationConfiguration());
    const { mutate: editNotificationConfig, isLoading: isUpdating } = useEditOrganizationResourceMutation(OrgQueryKeys.notificationConfigs, L.notificationConfiguration());
    const { mutate: deleteNotificationConfig } = useDeleteOrganizationResourceMutation(OrgQueryKeys.notificationConfigs);

    const isLoading = isLoadingNotificationConfigs || isLoadingContacts || isLoadingLocations || isLoadingCustomers || isCreating || isUpdating;

    const { columns, editConfig, setEditConfig, deleteConfig, setDeleteConfig } = useNotificationConfigColumns(contacts, organizationLocations, customers, cargoTypeList);
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const handleClose = () => {
        setEditConfig(undefined);
        setIsModalOpen(false);
    };

    return (
        <>
            <AdminBreadCrumbHeader>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        setIsModalOpen(true);
                    }}>
                        <Add /> {L.createNew()}
                </Button>
            </AdminBreadCrumbHeader>
            <ModalDialog open={isModalOpen || Boolean(editConfig)} onClose={() => handleClose()} title={L.notificationConfiguration()}>
                <AddEditNotification
                    existingNotificationConfig={editConfig ? editConfig : null}
                    organizationId={organizationId}
                    contacts={contacts}
                    organizationLocations={organizationLocations}
                    customers={customers}
                    cargoTypes={cargoTypeList}
                    onSubmit={async (newData) => {
                        if (newData.id) {
                            editNotificationConfig(newData);
                        } else {
                            addNotificationConfig(newData);
                        }
                        handleClose();
                    }} />
            </ModalDialog>
            <ConfirmationDialog
                open={Boolean(deleteConfig)}
                title={L.deleteConfirmationTitle()}
                message={L.deleteConfirmationMessage(notificationEventTypeTranslationHelper(Object.values(NotificationEventTypes).find(x => x.id === deleteConfig?.notificationEventTypeId)?.id))}
                cancelText={L.cancel()}
                confirmText={L.delete()}
                resultCallback={(confirmed) => {
                    if (confirmed) {
                        deleteNotificationConfig({ id: deleteConfig?.id });
                    }
                    setDeleteConfig(undefined);
                }} />
            <Content>
                <ResourceTable
                    id={'notification-config'}
                    title={L.notificationConfiguration()}
                    isLoading={isLoading}
                    columns={columns}
                    data={notificationConfigs}
                    showDrawer={true}
                />
            </Content>
        </>
    );
}
