import { STOP_STATUSES } from '../../../constants/constants';

export const getNextStop = (stops) => {
    if (!stops) {
        return null;
    }
    const orderedStops = stops.sortBy((stop) => stop.sequence);

    return orderedStops.find((stop) => stop.status === STOP_STATUSES().Enroute.key) ||
        orderedStops.find((stop) => stop.status === STOP_STATUSES()['Not Started'].key) ||
        orderedStops.last();
};
