import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { fiveMinQueryConfig, QueryKeys } from '../../api/config';
import React from 'react';
import { endOfDay, startOfDay, utc } from '../../utils/date-time-utils';
import { ProductionCardType } from './generation/production-card';

export const useProductionCards = (cargoTypeId: number, dateRange: { from: string, to: string }): UseQueryResult<ProductionCardType[], unknown> => {
    const utcFrom = React.useMemo(() => utc(startOfDay(dateRange.from)), [dateRange]);
    const utcTo = React.useMemo(() => utc(endOfDay(dateRange.to)), [dateRange]);

    const key = [QueryKeys.productionCards, utcFrom, utcTo, cargoTypeId];
    return useQuery<ProductionCardType[]>(key, async (): Promise<ProductionCardType[]> => {
        return await fiveMinQueryConfig.queries.queryFn(`${QueryKeys.productionCards}?startDate=${utcFrom}&endDate=${utcTo}&cargoTypeId=${cargoTypeId}`);
    }, {
        staleTime: fiveMinQueryConfig.queries.staleTime,
        cacheTime: fiveMinQueryConfig.queries.cacheTime,
    });
};
