import React from 'react';
import { StopProvider } from '../stop-context';
import { VisibilityIconButton } from './visibility-icon-button';
import { Stop } from '../../../types';
import { StopCompartments, StopCustomerOrderNumber, StopLoadingTeam, StopRequiredTimestamp } from './stops-table-cells';
import { LoadTableColumn } from './load-table';
import { StopsTableGridRow, StopsTableGridRowAnother, StopsTableRowPrefixUneditable } from './stops-table';

interface StopsTableRowProps {
    stop: Stop;
    i: number;
    gridRowStatic: React.CSSProperties;
    columns: LoadTableColumn[];
    handleVisChange: (i: number, rowVis: boolean) => void;
}

export const StopsTableRow: React.FC<StopsTableRowProps> = (props) => {
    const { stop, i, gridRowStatic, columns, handleVisChange } = props;
    const [secondRowVis, setSecondRowVis] = React.useState(false);

    React.useEffect(() => {
        handleVisChange(i, secondRowVis);
    }, [secondRowVis]);

    return (
        <StopProvider key={stop.sequence} stop={stop}>
            <StopsTableRowPrefixUneditable>
                <VisibilityIconButton
                    secondRowVis={secondRowVis}
                    stop={stop}
                    onClick={setSecondRowVis} />
                <StopsTableGridRow style={gridRowStatic}>
                    {columns.map((c, i) => {
                        return (
                            <div key={i}>
                                <c.render required={c.required} validations={c.validations} />
                            </div>
                        );
                    })}
                </StopsTableGridRow>
            </StopsTableRowPrefixUneditable>
            {secondRowVis ?
                <StopsTableRowPrefixUneditable>
                    <div />
                    <StopsTableGridRowAnother>
                        <div><StopCustomerOrderNumber /></div>
                        <div><StopLoadingTeam /></div>
                        <div><StopRequiredTimestamp colName='requiredBegin' /></div>
                        <div><StopRequiredTimestamp colName='requiredEnd' /></div>
                        <div><StopCompartments /></div>
                    </StopsTableGridRowAnother>
                </StopsTableRowPrefixUneditable>
            : null}
        </StopProvider>
    );
};
