import { DeviceLocation, DeviceLocationDupeFieldCheck, DeviceLocationIds, DeviceLocationParsed } from '../../../types';
import { L } from 'harmony-language';

// @ts-ignore
function findDuplicateResourceIdsForField<Type extends keyof DeviceLocationIds>(deviceLocations: DeviceLocation[] = [], field: Type) {
    return deviceLocations.filter(dl => dl[field]).map(dl => dl[field])
        .filter((property, index, array) => array.indexOf(property) !== index);
}

export function findDuplicateResourceIdsForFields(deviceLocations: DeviceLocation[], fields: Array<keyof DeviceLocationIds>): DeviceLocationDupeFieldCheck {
    return Object.fromEntries(fields.map(f => [ f.toString(), findDuplicateResourceIdsForField(deviceLocations, f)]));
}

function markDuplicateFieldsForLocation(deviceLocation: DeviceLocation, dupedFields: DeviceLocationDupeFieldCheck): DeviceLocationParsed {
    return Object.assign({
        isDuplicateDeviceId: dupedFields.deviceId?.includes(deviceLocation.deviceId),
        isDuplicateOrderId: dupedFields.orderId?.includes(deviceLocation.orderId),
        isDuplicateUserId: dupedFields.userId?.includes(deviceLocation.userId),
        isDuplicateTractorId: dupedFields.tractorId?.includes(deviceLocation.tractorId),
        isDuplicateTrailerId: dupedFields.trailerId?.includes(deviceLocation.trailerId)
    } , deviceLocation);
}

export function markDuplicateFieldsForLocations(deviceLocations: DeviceLocation[], dupedFields: DeviceLocationDupeFieldCheck): Array<DeviceLocationParsed> {
    return deviceLocations.map(dl => markDuplicateFieldsForLocation(dl, dupedFields));
}

export function findFieldsWithDuplicatesForLocation(deviceLocation: DeviceLocationParsed): Array<keyof DeviceLocationIds> {
    const duplicateIdFields: Array<keyof DeviceLocationIds> = [];
    if (deviceLocation.isDuplicateDeviceId) duplicateIdFields.push('deviceId');
    if (deviceLocation.isDuplicateUserId) duplicateIdFields.push('userId');
    if (deviceLocation.isDuplicateOrderId) duplicateIdFields.push('orderId');
    if (deviceLocation.isDuplicateTractorId) duplicateIdFields.push('tractorId');
    if (deviceLocation.isDuplicateTrailerId) duplicateIdFields.push('trailerId');
    return duplicateIdFields;
}

const DUPE_WARN_MESSAGE = {
    deviceId: L.device(),
    orderId: L.load(),
    userId: L.driver(),
    tractorId: L.tractor(),
    trailerId: L.trailer()
}

export function buildDupeResourceList(fields: Array<keyof DeviceLocationIds>): string {
    let result = '';
    for (let i = 0; i < fields.length; i++) {
        result += DUPE_WARN_MESSAGE[fields[i]];
        if (i < fields.length - 1) result += ', ';
    }
    return result;
}

