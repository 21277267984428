import React from 'react';
import { styled } from '@mui/material/styles';
import Skeleton from "@mui/material/Skeleton";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import PropTypes from 'prop-types';
import { ResourceEditCreateContainer } from '../../../shared/containers/resource-edit-create-container';
import { Checkbox, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { L } from 'harmony-language';
import { useEditOrganizationCargoConfig } from '../../../../api/mutations/edit/use-edit-organization-cargo-config';
import { cargoTypesToCategoryGroups } from '../../../../utils/data-mapping-utils';
import { useCargoTypes } from '../../../../api/queries/use-cargo-types';
import { AgisticsWarningIcon } from '../../../shared/agistics-warning-icon';

const CargoTypesContainer = styled(List)({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: '60vh'
});

const StyledListItem = styled(ListItem)({
    width: 'unset',
    '& > label': {
        cursor: 'pointer'
    }
})

export const CargoTypeConfigManagement = (props) => {
    const { companies, company, onSubmit } = props;
    const { data: cargoTypes, isLoading } = useCargoTypes();
    const [selectedCargoTypes, setSelectedCargoTypes] = React.useState(company.cargoTypes);
    const { mutate: editCargoConfigs, isLoading: isUpdating } = useEditOrganizationCargoConfig(company.id);

    const cargoTypeConfigValid = React.useMemo(() => {
        if (company.isCarrier) {
            const owningOrgs = companies.filter(x => {
                return x.carriers.find(y => y.id === company.id);
            });

            const carrierHasAllOwningOrgCargoTypes = owningOrgs.map(x => {
                const selectedOrgCargoTypeIds = selectedCargoTypes.map(x => x.id);
                const owningOrgCargoTypesIds = x.cargoTypes.map(x => x.id);
                const carrierHasAllCargoTypes = owningOrgCargoTypesIds.every(r => selectedOrgCargoTypeIds.includes(r));
                return carrierHasAllCargoTypes;
            });
            return carrierHasAllOwningOrgCargoTypes.every(x => Boolean(x));
        } else {
            const carrierIds = company.carriers.map(x => x.id);
            if (carrierIds.length) {
                const owningOrgCarriers = companies.filter(x => carrierIds.includes(x.id));
                const owningOrgHasAllCarrierOrgCargoTypes = owningOrgCarriers.map(x => {
                    const selectedOrgCargoTypeIds = selectedCargoTypes.map(x => x.id);
                    const carrierCargoTypesIds = x.cargoTypes.map(x => x.id);
                    const owningOrgHasAllCargoTypes = selectedOrgCargoTypeIds.every(r => carrierCargoTypesIds.includes(r));
                    return owningOrgHasAllCargoTypes;
                });
                return owningOrgHasAllCarrierOrgCargoTypes.every(x => Boolean(x));
            } else {
                return true;
            }
        }
    }, [selectedCargoTypes]);

    const toSkeletonListItem = (x) => <Skeleton key={x} width='100%' height={32}/>;

    const cargoTypeCategories = React.useMemo(() => {
        return cargoTypesToCategoryGroups(cargoTypes?.sortBy(x => x.type));
    }, [cargoTypes]);

    return (
        <ResourceEditCreateContainer width='50vw' data-testid={'cargo-type-management'}>
            <CargoTypesContainer dense disablePadding>
                {isLoading ? Array.from(Array(5), (x, i) => i).map(toSkeletonListItem) : Object.entries(cargoTypeCategories).map(([category, cargoTypeList]) => {

                    return (
                        <div key={category}>
                            <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{category}</Typography>
                            {cargoTypeList.map(cargoType => {
                                const isChecked = selectedCargoTypes.some(c => c.id === cargoType.id);

                                return (
                                    <StyledListItem key={cargoType.id} dense disableGutters>
                                        <Checkbox checked={isChecked}
                                            id={`${cargoType.type}-${cargoType.id}`}
                                            onChange={() => {
                                                if (isChecked) {
                                                    setSelectedCargoTypes(selectedCargoTypes.filter(c => c.id !== cargoType.id));
                                                } else {
                                                    setSelectedCargoTypes(selectedCargoTypes.concat(cargoType));
                                                }
                                            }}>
                                        </Checkbox>
                                        <label htmlFor={`${cargoType.type}-${cargoType.id}`}>{L[`cargoValue${cargoType.id}`]()}</label>
                                    </StyledListItem>
                                );
                            })}
                        </div>
                    );
                })}
            </CargoTypesContainer>
            <div style={{ float: 'right', margin: '0 0.75rem 0.75rem 0' }}>
                {!cargoTypeConfigValid && <AgisticsWarningIcon message={L.carrierCargoTypeWarning2()} />}
                <Button type='button'
                    disabled={isLoading || isUpdating || !cargoTypeConfigValid}
                    title='Save'
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        editCargoConfigs({ cargoTypes: selectedCargoTypes });
                        onSubmit();
                    }}
                >
                    {L.save()}
                </Button>
            </div>
        </ResourceEditCreateContainer>
    );
};


CargoTypeConfigManagement.propTypes = {
    company: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    companies: PropTypes.array,
};
