import { locationTypeToCargoTypeIdList, toStopDisplayName } from '../../../utils/data-mapping-utils';

// there use to be some LoadStops components in here
// no longer used, this was the only thing still used
// removed it all, as there was a TON of makeStyles stuff here as well
export const loadStopsMapper = (organizationLocations, locationTypes, loadingTeams) => (stop) => {
    const orgLocation = organizationLocations?.find(x => x.id === stop.organizationLocationId);
    const locationType = locationTypes?.find(x => x.id === orgLocation?.organizationLocationTypeId);

    return {
        ...stop,
        loadingTeam: loadingTeams?.find(x => x.id === stop.loadingTeamId),
        displayName: toStopDisplayName(organizationLocations)(stop),
        cargoTypes: locationTypeToCargoTypeIdList(stop.type)(locationType),
        locationTypeId: locationType?.id
    };
};
