import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

export const SearchBar = (props) => {
    const { placeholder, text, setText } = props;

    return (
        <Grid item style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <SearchIcon/>
            <TextField
                variant='standard'
                type='search'
                value={text || ''}
                onChange={e => {
                    setText(e.target.value || undefined);
                }}
                placeholder={placeholder}
                inputProps={{ 'aria-label': 'search' }} />
        </Grid>
    );
};

SearchBar.propTypes = {
    placeholder: PropTypes.string,
    text: PropTypes.string,
    setText: PropTypes.func.isRequired,
};
