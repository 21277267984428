import React from 'react';
import { add, subtract, utcWithTz } from '../../utils/date-time-utils';
import { DynamicQueryKeys } from '../config';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { Load } from '../../types';
import { useTier } from '../../components/user/selectors/use-permissions';
import { CustomerTier } from 'harmony-constants';

export const useLoadsInTransit = (options?: UseQueryOptions<Load[]>) => {
    const isFreemium = useTier(CustomerTier.Freemium);
    const timeWindow = React.useMemo(() => {
        return {
            // This should be more than adequate range for in transit loads...
            from: subtract(1, 'week').format('YYYY-MM-DD'),
            to: add(1, 'week').format('YYYY-MM-DD')
        };
    }, []);
    options = options || {};
    options.enabled = !isFreemium;

    // adding staleTime as this is called every re-render and the 'load' we are tracking to
    // calculate arrivale time is watching for 'load' to change which was triggering
    // another call to HERE. Which was causing a lot of HERE transactions.
    options.staleTime = 1 * 60 * 1000; // 1 min

    return useQuery<Load[]>([DynamicQueryKeys.loadsInTransit(utcWithTz(timeWindow.from), utcWithTz(timeWindow.to))], options);
};
