import * as HereMapWrapper from './here-map-wrapper';

export const calculateRoute = (waypoints, avoidanceZones, callback) => {
    const origin = originString(waypoints);
    const destination = destinationString(waypoints);

    const routeRequestParams = {
        transportMode: 'car',
        routingMode: 'fast',
        origin: origin,
        destination: destination,
        return: 'polyline',
    };

    if (waypoints.length > 2) {
        const minusFirst = waypoints.slice(1);
        const minusLast = minusFirst.slice(null, -1);
        const viaArgs = minusLast.reduce((acc, cur) => {
            const via = viaString(cur);
            return acc.concat(via);
        }, []);

        routeRequestParams.via = HereMapWrapper.createViaArgs(viaArgs);
    }

    if (avoidanceZones && avoidanceZones.length > 0) {
        const avoidanceZonesFlat = avoidanceZones.reduce((acc, curr) => {
            const { topLeft, bottomRight } = curr.boundingBox;

            return acc + `bbox:${topLeft.longitude},${bottomRight.latitude},${bottomRight.longitude},${topLeft.latitude}|`;
        }, '');

        routeRequestParams['avoid[areas]'] = avoidanceZonesFlat.slice(0, -1);
    }

    //https://developer.here.com/documentation/maps/3.1.30.16/api_reference/H.service.Platform.html#getRoutingService
    const router = HereMapWrapper.getPlatform().getRoutingService(null, 8);

    router.calculateRoute(routeRequestParams, callback, (error) => {
        console.error(error); // eslint-disable-line no-console
    });
};

const originString = (waypoints) => {
    const originLat = waypoints[0].customGeofence?.latitude || waypoints[0].latitude;
    const originLng = waypoints[0].customGeofence?.longitude || waypoints[0].longitude;

    return `${originLat},${originLng}`;
}

const destinationString = (waypoints) => {
    const destinationLat = waypoints[waypoints.length - 1].customGeofence?.latitude || waypoints[waypoints.length - 1].latitude;
    const destinationLng = waypoints[waypoints.length - 1].customGeofence?.longitude || waypoints[waypoints.length - 1].longitude;

    return `${destinationLat},${destinationLng}`;
}

const viaString = (viaStop) => {
    const viaLat = viaStop.customGeofence?.latitude || viaStop.latitude;
    const viaLng = viaStop.customGeofence?.longitude || viaStop.longitude

    return `${viaLat},${viaLng}`;
}
