import { QueryKeys } from '../config';
import { useStaticQuery } from './use-static-query';
import React from 'react';
import { getTransCargoCategoryLabel, getTransCargoTypeLabel } from '../../constants/constants';
import { CargoType } from '../../types';

const OTHER_CARGO_TYPE_ID = 21;

export const useCargoTypes = (removeOther?: boolean) => {
    const cargoTypeQuery = useStaticQuery(QueryKeys.cargoTypes);

    const cargoTypes = cargoTypeQuery.data as CargoType[];
    const cargoTypeList = React.useMemo(() => {
        const unsortedCargoTypes = cargoTypes?.map((c) => {
            return ({
                id: c.id,
                label: getTransCargoTypeLabel(c.id),
                category: getTransCargoCategoryLabel(c.category),
            });
        }).filter(element => removeOther ? element.id !== OTHER_CARGO_TYPE_ID : true) || [];
        return unsortedCargoTypes.sort((a, b) => a.label.localeCompare(b.label));
    }, [cargoTypes]);

    return {
        ...cargoTypeQuery,
        cargoTypeList,
    };
};
