import { NotificationConfig } from '../../../../types';
import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { L } from 'harmony-language';
import { notificationEventTypeTranslationHelper } from '../name-helpers';
import { NotificationEventType } from 'harmony-constants';

// returns undefined at end of list, but items here should have defined sortOrder
const sortFunc = (a: number | undefined, b: number | undefined) => {
    // return (a && b) ? (a > b ? 1 : b > a ? -1 : 0) : (!a ? 1 : !b ? -1 : 0);
    if (a && b) {
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1
        }
        return 0;
    }
    if (!a) {
        return 1;
    }
    if (!b) {
        return -1;
    }
    return 0;
}

type NotificationTypeDropdownProps = {
    notificationConfig: NotificationConfig;
    eventList: NotificationEventType[];
    onNotificationChange: (notificationConfig: NotificationConfig) => void;
}

export const NotificationTypeDropdown: React.FC<NotificationTypeDropdownProps> = (props) => {
    const { notificationConfig, eventList, onNotificationChange } = props;

    const sortedEventList = React.useMemo(() => {
        return eventList.sort((a, b) => sortFunc(a.sortOrder, b.sortOrder));
    }, [eventList]);

    return (
        <TextField
            variant='standard'
            className='notification-type-dropdown'
            label={L.notificationEventType()}
            id='notification-type'
            style={{ width: '100%' }}
            select={true}
            value={notificationConfig.notificationEventTypeId}
            onChange={(e) => {
                const value = parseInt(e.target.value);
                onNotificationChange({
                    ...notificationConfig,
                    notificationEventTypeId: value,
                    stopCargoTypeIds: null,
                    trailerCargoTypeIds: null,
                    destinationSiteIds: null,
                    originSiteIds: null,
                    customerIds: null,
                });
            }}>
            {sortedEventList.map((event) => (
                <MenuItem key={event.id} value={event.id}>
                    {notificationEventTypeTranslationHelper(event.id)}
                </MenuItem>
            ))}
        </TextField>
    );
};
