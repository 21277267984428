import { CargoType, Customer, OrganizationLocation } from '../../../types';
import { NotificationEventTypes } from 'harmony-constants';
import { DictionaryKey, L } from 'harmony-language';
import { toSiteDisplayName } from '../../../utils/data-mapping';

export const notificationEventTypeIdToName = (notificationEventTypeId: number): string => {
    const notificationEventName = notificationEventTypeTranslationHelper(Object.values(NotificationEventTypes).find(x => x.id === notificationEventTypeId)?.id);

    return notificationEventName;
}

export const siteIdsToNames = (siteIds: number[] | null, organizationLocations: OrganizationLocation[] | undefined): string[] => {
    if (siteIds && organizationLocations) {
        const siteNames = siteIds.map(x => {
            const orgLocation = organizationLocations.find(ol => ol.id === x);
            return orgLocation ? toSiteDisplayName(orgLocation.name, orgLocation.description) : '';
        });

        return siteNames;
    } else {
        return [''];
    }
}

export const customerIdsToNames = (customerIds: number[] | null, customers: Customer[] | undefined): string[] => {
    if (customerIds && customers) {
        const customerNames = customerIds.map(x => customers?.find(c => c.id === x)?.customerName || '');

        return customerNames;
    } else {
        return [''];
    }
}

export const cargoTypesToNames = (stopCargoTypeIds: number[] | null, cargoTypeList: CargoType[]): string[] => {
    if (stopCargoTypeIds && cargoTypeList) {
        const stopCargoTypeNames = stopCargoTypeIds.map(x => cargoTypeList.find(ct => ct.id === x)?.label || '');

        return stopCargoTypeNames;
    } else {
        return [''];
    }
}

export const notificationEventTypeTranslationHelper = (notificationEventTypeId: number | undefined) => {
    return notificationEventTypeId ? L[`notificationEventType${notificationEventTypeId}` as DictionaryKey]() : '';
}
