import { useCallback, useMemo } from 'react';
import { UnitsWeight } from 'harmony-constants';
import { L } from 'harmony-language';
import { useUser } from '../../../api/queries/use-user';

const WeightAbbreviation = {
    'USTon': L.unitsImperialWeightLargeAbbr(),
    'Pound': L.unitsImperialWeightAbbr(),
    'MetricTon': L.unitsMetricWeightLargeAbbr(),
    'Kilogram': L.unitsMetricWeightAbbr(),
}

const WeightAbbreviationPerMin = {
    'USTon': L.weightTonPerMin(),
    'Pound': L.weightPoundPerMin(),
    'MetricTon': L.weightMetricTonPerMin(),
    'Kilogram': L.weightKilogramMin(),
}

// exporting just for tests, is there a better way?
export const convert = (from: keyof typeof UnitsWeight | 'gram', to: keyof typeof UnitsWeight | 'gram', precision: number) => (amount: number) => {
    const table = {
        'gram->uston': 0.00000110231131092439,
        'gram->pound': 0.00220462262184878,
        'gram->metricton': 0.000001,
        'gram->kilogram': 0.001,

        'gram->gram': 1,

        'kilogram->gram': 1000,
        'metricton->gram': 1000000,
        'pound->gram': 453.59237,
        'uston->gram': 907184.74,
    };
    // @ts-ignore
    const base = amount * table[`${from.toLowerCase()}->${to.toLowerCase()}`];
    return Number(base.toFixed(precision));
}

export const useWeights = () => {
    const { user } = useUser();
    const weightSystem = user.contact?.unitsWeight || user.organization?.unitsWeight || UnitsWeight.Pound;

    const weightAbbreviation = WeightAbbreviation[weightSystem];

    const isDecimalWeight = weightSystem === UnitsWeight.MetricTon || weightSystem === UnitsWeight.USTon;
    const weightPrecision = isDecimalWeight ? 2 : 0;

    const convertFromGrams = useCallback((grams: number) => {
        return convert('gram', weightSystem, weightPrecision)(grams);
    }, [weightSystem]);

    const convertToGrams = useCallback((value: number) => {
        return convert(weightSystem, 'gram', 0)(value);
    }, [weightSystem]);

    const convertFromGramsDisplay = useCallback((grams: number) => {
        const userDefinedNumber = convert('gram', weightSystem, weightPrecision)(grams);
        if (isDecimalWeight) {
            return userDefinedNumber.toFixed(2).concat(' ', weightAbbreviation);
        } else {
            return userDefinedNumber.toString().concat(' ', weightAbbreviation);
        }
    }, [weightSystem]);

    const stringAbbreviationDisplay = useMemo(() => {
        return WeightAbbreviationPerMin[weightSystem];
    }, [weightSystem]);

    return {
        weightSystem,
        weightAbbreviation,
        weightPrecision,
        convertFromGrams,
        convertToGrams,
        convertFromGramsDisplay,
        stringAbbreviationDisplay,
    }
};
