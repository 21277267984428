import { Button, Typography } from '@mui/material';
import { L } from 'harmony-language';
import React from 'react';

interface SwapEventsButtonProps {
    swap: () => void;
    mobileEventsShown: boolean;
}

export const SwapEventsButton: React.FC<SwapEventsButtonProps> = (props) => {
    const { swap, mobileEventsShown } = props;

    return (
        <div style={{ display: 'flex' }}>
            <Button
                variant='contained'
                color='primary'
                style={{ marginBottom: '10px' }}
                onClick={() => swap()}>
                    {L.swapEvents()}
            </Button>
            <Typography
                style={{ fontSize: '1.4rem', marginLeft: '10px' }}>
                    {mobileEventsShown ? L.mobile() : L.trailer()}
            </Typography>
        </div>
    );
};
