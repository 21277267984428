import axios from 'axios';
import { L } from 'harmony-language';
import { enqueueSnackbar } from 'notistack';
import { NavigateFunction } from 'react-router-dom';

export const activateUser = async (uuid: string, password: string, navigate: NavigateFunction) => {
    await axios.post(`${API_BASE}/api/users/${uuid}/activate`, { password });

    navigate('/login');
    enqueueSnackbar(L.userActivated(), { variant: 'success' });
};
