import { stopToOrgLocationIdLabelTuple } from '../../../utils/data-mapping-utils';
import { styled } from '@mui/material/styles';
import { ResourceConflictContainer } from '../../shared/resource-conflicts/resource-conflict-container';
import { isValidDate, localDateTimeDisplay } from '../../../utils/date-time-utils';
import PropTypes from 'prop-types';
import React from 'react';

const Item = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isBasis',
})(({ isBasis }) => ({
    minWidth: 0,
    display: 'flex',
    ...(isBasis && {
        fontWeight: 'bold',
        margin: '0.1rem 0',
    })
}));

const LocationTime = styled('span')({
    textAlign: 'right',
    minWidth: '8em',
    marginRight: '0.2rem',
    display: 'flex',
    justifyContent: 'space-between'
});

const LocationName = styled('div')({
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginLeft: '0.2rem',
});

export const StopItem = (props) => {
    const { stop, organizationLocations, isBasis, icon, textAdornment } = props;
    const { label: displayName, id } = stopToOrgLocationIdLabelTuple(organizationLocations)(stop);

    return (
        <ResourceConflictContainer resource={{ id: id, type: 'location', stop: { ...stop, displayName } }}>
            <Item isBasis={isBasis}>
                <LocationTime>
                    {isValidDate(stop?.arrivalTime) ? <>
                        <span>{localDateTimeDisplay(stop?.arrivalTime)}</span></> : ''}
                </LocationTime>
                {icon}
                <LocationName>
                    {displayName}{textAdornment}
                </LocationName>
            </Item>
        </ResourceConflictContainer>
    );
};

StopItem.propTypes = {
    stop: PropTypes.object,
    organizationLocations: PropTypes.array,
    icon: PropTypes.node,
    isBasis: PropTypes.bool,
    textAdornment: PropTypes.node
};
