import React from 'react';
import { Load } from '../../../../types';
import { useTractorTrailer } from '../../../../api/queries/use-tractor-trailer';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../../api/config';
import {
    searchByDriver,
    searchByLoadId,
    searchByOrderNumber,
    searchByStopCargo,
    searchByStopCustomerOrderNumber,
    searchByStopDates,
    searchByStopLoadingTeam,
    searchByStopLocation,
    searchByTractor,
    searchByTrailer
} from '../../table/utils/search-functions';

export type LoadSearching = {
    filteredData: Load[];
    handleChange: (s: string) => void;
}

export const useLoadSearching = (filteredData: Load[]): LoadSearching => {
    const [searchText, setSearchText] = React.useState('');
    const {tractors, trailers, isLoadingTractors, isLoadingTrailers} = useTractorTrailer();
    const {data: loadingTeams, isLoading: isLoadingLoadingTeams} = useOrganizationQuery(OrgQueryKeys.loadingTeams);
    const {data: organizationLocations, isLoading: isLoadingLocations} = useOrganizationQuery(OrgQueryKeys.locations);
    const {data: drivers, isLoading: isLoadingDrivers} = useOrganizationQuery(OrgQueryKeys.drivers);
    const isLoading = isLoadingTractors || isLoadingTrailers || isLoadingLoadingTeams || isLoadingLocations || isLoadingDrivers;

    const resultData = React.useMemo(() => {
        if (isLoading || !searchText) {
            return filteredData;
        }
        return filteredData.filter((load) => {
            return searchByDriver(load, searchText, drivers) ||
                searchByTractor(load, searchText, tractors) ||
                searchByTrailer(load, searchText, trailers) ||
                searchByStopCargo(load, searchText) ||
                searchByOrderNumber(load, searchText) ||
                searchByStopLocation(load, searchText, organizationLocations) ||
                searchByStopLoadingTeam(load, searchText, loadingTeams) ||
                searchByStopCustomerOrderNumber(load, searchText) ||
                searchByStopDates(load, searchText) ||
                searchByLoadId(load, searchText)
        })
    }, [searchText, filteredData, tractors, trailers, drivers, organizationLocations])


    return {handleChange: setSearchText, filteredData: resultData}
};
