import React, { useEffect } from 'react';
import { L } from 'harmony-language';
import Button from '@mui/material/Button';
import Info from '@mui/icons-material/Info';
import { ValidatedTextField } from '../shared/inputs/validated-text-field';
import '../../scss/login.scss';
// @ts-ignore
import SamsImage from '../../resources/agistics-logo-name.png';
import Paper from '@mui/material/Paper';
import { useNavigate, useParams } from 'react-router-dom';
import { activateUserCheck } from './actions/activate-user-check';
import { activateUser } from './actions/activate-user';

const PASSWORD_MISMATCH = () => L.passwordMismatch();
const PASSWORD_INVALID = () => L.passwordInvalid();

export const isPasswordValid = (password: string) => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d-_!@#$%^&*?~]{8,}/.test(password);

export const UserActivation: React.FC = () => {
    const [password, setPassword] = React.useState('');
    const [repeatPassword, setRepeatPassword] = React.useState('');
    const { uuid } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (uuid) {
            activateUserCheck(uuid, navigate);
        }
    }, []);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (uuid) {
            activateUser(uuid, password, navigate);
        }
    }

    return (
        <div className='login-container'>
            <img className='agistics-logo' src={SamsImage} alt=''/>
            <Paper className='login-paper'>
                <form className='user-activation-form' onSubmit={onSubmit}>
                    <ValidatedTextField
                        label={L.password()}
                        type='password'
                        required
                        InputLabelProps={{ shrink: true }}
                        validations={[{ message: PASSWORD_INVALID(), isValid: () => isPasswordValid(password) }]}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <ValidatedTextField
                        label={L.passwordRepeat()}
                        type='password'
                        required
                        InputLabelProps={{ shrink: true }}
                        validations={[{ message: PASSWORD_MISMATCH(), isValid: () => password === repeatPassword }]}
                        value={repeatPassword}
                        onChange={(e) => setRepeatPassword(e.target.value)}
                    />
                    <Button variant='contained' color='primary' type='submit'>
                        {L.activate()}
                    </Button>
                </form>
                <div className='user-activation-password-information'>
                    <Info/>
                    <div className='user-activation-password-information-text'>
                        <p>{L.passwordRequirements()}</p>
                    </div>
                </div>
            </Paper>
        </div>
    );
};
