import React from 'react';
import PropTypes from 'prop-types';
import UpdateIcon from '@mui/icons-material/Update'
import Button from '@mui/material/Button';
import { L } from 'harmony-language';
import { ModalDialog } from '../../../shared/containers/modal-dialog';
import { useBoolean } from '../../../shared/hooks/use-boolean';
import { useLocationTypes } from '../../../../api/queries/use-location-types';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { STOP_TYPES, getTransCargoTypeLabel } from '../../../../constants/constants';
import { Grid, MenuItem, Select } from '@mui/material';
import ThroughputEntry from '../../entries/throughput-entry';

export const ThroughputInput = ({throughputs: configurableThroughputs, setThroughputs: setConfigurableThroughputs, organizationLocation }) => {
    const locationTypes = useLocationTypes({ name: organizationLocation.orgLocationType?.name })
    const { data: organization, isLoading: isLoadingOrg } = useOrganizationQuery('');
    const [localThroughputs, setLocalThroughputs] = React.useState([]);
    const [cargoTypes, setCargoTypes] = React.useState([]);
    const [throughputCargoTypeId, setThroughputCargoTypeId] = React.useState(-1);

    React.useEffect(() => {
        if (!locationTypes || !organization) {
            return;
        }
        const defaults = {
            [STOP_TYPES().Origin.key]: configurableThroughputs?.find(x => x.stopType === STOP_TYPES().Origin.key && x.cargoTypeId === null),
            [STOP_TYPES().Destination.key]: configurableThroughputs?.find(x => x.stopType === STOP_TYPES().Destination.key && x.cargoTypeId === null)
        }

        const noCargoTypeDefault = [STOP_TYPES().Origin.key, STOP_TYPES().Destination.key].map(key => ({
            id: null,
            domKey: `DEFAULT_THROUGHPUT_${key}`,
            label: L.default(),
            stopType: key,
            cargoTypeId: null,
            ...(defaults[key] ? {cargoUnitsPerMinute: defaults[key].cargoUnitsPerMinute} : {}),
            ...(defaults[key] ? {baselineLoadingMinutes: defaults[key].baselineLoadingMinutes} : {}),
            ...(defaults[key] ? {weightPerMinute: defaults[key].weightPerMinute} : {}),
        }));
        const locationType = locationTypes.data.find(x => x.name === organizationLocation?.orgLocationType?.name)
        const originCargoTypes = locationType?.shipsCargoTypeIds
                                             ?.filter(ct => organization.cargoTypes.find(x => x.id === ct)) || [];
        const destinationCargoTypes = locationType?.receivesCargoTypeIds
                                                  ?.filter(ct => organization.cargoTypes.find(x => x.id === ct)) || [];
        setCargoTypes([...(new Set([...originCargoTypes, ...destinationCargoTypes]))].sort((a,b) => {
            return `${getTransCargoTypeLabel(a)}`.localeCompare(getTransCargoTypeLabel(b));
        }));

        const originThroughputs = originCargoTypes.map(ct => {
            const existing = configurableThroughputs?.find(x => x.stopType === 'Origin' && x.cargoTypeId === ct)
            return {
                id: null,
                domKey: `Origin_${ct}`,
                label: L[`cargoValue${ct}`](),
                stopType: STOP_TYPES().Origin.key,
                cargoTypeId: ct,
                ...(existing ? {cargoUnitsPerMinute: existing.cargoUnitsPerMinute} : {}),
                ...(existing ? {baselineLoadingMinutes: existing.baselineLoadingMinutes} : {}),
                ...(existing ? {weightPerMinute: existing.weightPerMinute} : {}),
            }
        });
        const destinationThroughputs = destinationCargoTypes.map(ct => {
            const existing = configurableThroughputs?.find(x => x.stopType === 'Destination' && x.cargoTypeId === ct)
            return {
                id: null,
                domKey: `Destination_${ct}`,
                label: L[`cargoValue${ct}`](),
                stopType: STOP_TYPES().Destination.key,
                cargoTypeId: ct,
                ...(existing ? {cargoUnitsPerMinute: existing.cargoUnitsPerMinute} : {}),
                ...(existing ? {baselineLoadingMinutes: existing.baselineLoadingMinutes} : {}),
                ...(existing ? {weightPerMinute: existing.weightPerMinute} : {}),
            }
        })
        const cargoThroughputs = [...originThroughputs, ...destinationThroughputs].sort((a,b) => {
            return a.label?.localeCompare(b.label);
        })
        const throughputTemplates = [...noCargoTypeDefault, ...cargoThroughputs];
        setLocalThroughputs(throughputTemplates);

    }, [organizationLocation, locationTypes.data, organization, configurableThroughputs]);

    const handleChangeFunc = (index) => (key, value) => {
        const newThroughputs = [
            ...localThroughputs.slice(0, index),
            {
                ...localThroughputs[index],
                [key]: value
            },
            ...localThroughputs.slice(index+1, localThroughputs.length)
        ]
        setConfigurableThroughputs(newThroughputs)
        setLocalThroughputs(newThroughputs)
    };

    const [isOpen, open, close] = useBoolean(false);
    return (
        <>
            <Button color='secondary' onClick={open} style={{ width: '100%' }}>
                <UpdateIcon sx={{ padding: '0 5px' }} />{L.throughput()}
            </Button>
            <ModalDialog title={L.throughput()} open={isOpen} onClose={close}>
                <Grid container spacing={2} data-testid='location-throughput-form' sx={{ width: '35vw', flexWrap: 'wrap', flexDirection: 'unset' }}>
                    <Grid item xs={12}>
                        <Select
                            variant='standard'
                            label={L.cargoType()}
                            value={throughputCargoTypeId}
                            onChange={(e) => setThroughputCargoTypeId(e.target.value)}>
                            <MenuItem value={-1}>{L.default()}</MenuItem>
                            {cargoTypes.map((ct) => <MenuItem key={ct} value={ct}>{getTransCargoTypeLabel(ct)}</MenuItem>)}
                        </Select>
                    </Grid>
                    {localThroughputs.map((t, i) => {
                        const show = throughputCargoTypeId === -1 && t.cargoTypeId === null || //special for "default"
                                     t.cargoTypeId === throughputCargoTypeId

                        return show ? <ThroughputEntry key={t.domKey} throughput={t} isLoading={isLoadingOrg} onChange={handleChangeFunc(i)} />
                        : null
                    })}
                </Grid>
            </ModalDialog>
        </>
    );
};

ThroughputInput.propTypes = {
    organizationLocation: PropTypes.object,
    setThroughputs: PropTypes.func,
    throughputs: PropTypes.array
};
