import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { mutationOptions, QueryKeys } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { ProductionCardType } from '../../../components/production-planning/generation/production-card';
import { L } from 'harmony-language'

export const useDeleteProductionCardMutation = () => {
    const snackbar = useSnackbar();
    const queryClient = useQueryClient();

    return useMutation(async function (productionCard: ProductionCardType) {
        return (await axios.delete(`${API_BASE}/api/production-cards/${productionCard.guid}`)).data;
    }, {
        ...mutationOptions([QueryKeys.productionCards], queryClient),
        onSuccess: () => {
            snackbar(L.productionPlanningCardDeleteSuccess());
        },
    });
};
