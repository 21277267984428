import { OrgQueryKeys } from '../config';
import { useOrganizationQuery } from './use-organization-query';
import { Tractor, Trailer } from '../../types';

export const useTractorTrailer = () => {
    const {
        data: tractors,
        isLoading: isLoadingTractors,
        organizationId: organizationId
    } = useOrganizationQuery<Tractor[]>(OrgQueryKeys.tractors);
    const { data: trailers, isLoading: isLoadingTrailers } =
        useOrganizationQuery<Trailer[]>(OrgQueryKeys.trailers);

    return {
        tractors,
        trailers,
        isLoadingTractors,
        isLoadingTrailers,
        isLoading: isLoadingTractors || isLoadingTrailers,
        organizationId: organizationId
    };
};
