import React from 'react';
import { getCountryCodeFromLanguage, getCurrentLanguage, L } from 'harmony-language';
import { useValidations } from './validated-text-field';
import { MuiTelInput } from 'mui-tel-input'
import { validatePhone } from '../../../utils/string-utils';

interface PhoneInputProps {
    phone: string | undefined;
    onChange: (s: string) => void;
}

export const PhoneInput: React.FC<PhoneInputProps> = props => {
    const { phone, onChange } = props;
    const countryCode = getCountryCodeFromLanguage(getCurrentLanguage());

    const validations = React.useMemo(() => [{
        message: L.invalidPhone(),
        isValid: () => validatePhone(phone)
    }], [phone]);
    const ref = useValidations(validations);
    const error = !validatePhone(phone);

    return (
        <MuiTelInput
            variant='standard'
            inputProps={{
                ref: ref
            }}
            defaultCountry={countryCode}
            preferredCountries={['US']}
            label={L.phone()}
            value={phone}
            error={!!error}
            helperText={error && L.invalidPhone()}
            onChange={(phone) => {
                const newPhone = phone === '+' ? '' : phone;

                onChange(newPhone);
            }}
            style={{ width: '100%' }}
        />
    );
};
