import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { L } from 'harmony-language';

interface CheckboxContractProps {
    checked: boolean;
    disabled?: boolean;
    onChange: () => void;
}

export const CheckboxContract: React.FC<CheckboxContractProps> = (props) => {
    const { checked, disabled, onChange } = props;

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    disabled={disabled ? true : false}
                    onChange={onChange} />
            }
            label={L.contract()} />
    );
}
