// Found this online: https://stackoverflow.com/questions/36231498/auto-select-span-tag-with-reactjs
export const copyNodeTextToClipboard = (node) => {
    if (document.body.createTextRange) {
        const range = document.body.createTextRange();

        range.moveToElementText(node);
        range.select();
        document.execCommand('copy');
        range.remove();
        return true;
    } else if (window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();

        range.selectNodeContents(node);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        selection.removeAllRanges();
        return true;
    } else {
        alert('Could not select text, Unsupported browser');
        return false;
    }
};
