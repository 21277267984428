import { day, getDaysOfWorkingWeek, replaceTimeZone, utc } from '../../../utils/date-time-utils';
import { getTransCargoType } from '../../../constants/constants';

export const currentProjectionsBySite = (currentProjections, siteId, cargoTypeId) => {
    if (!currentProjections) {
        return 0;
    }

    return currentProjections
        .filter((projection) => projection.destination.id === siteId && projection.cargoTypeId === cargoTypeId)
        .sum((projection) => projection.numberOfLoads);
};

export const lastWeekProjectionsBySite = (lastWeeksProjections, siteId, cargoTypeId) => {
    if (!lastWeeksProjections) {
        return null;
    }

    return [...Array(7)].map((x, i) => {
        const dayProjection = lastWeeksProjections.filter(projection => projection.destination.id === siteId).filter(projection => projection.cargoTypeId === cargoTypeId).find(projection => day(projection.date) === i);

        return dayProjection ? dayProjection.numberOfLoads : 0;
    });
};

export const initializeLoads = (numberOfSites, defaultValue) => {
    const tempLoads = [];

    for (let site = 0; site < numberOfSites; site++) {
        const tempSites = [];

        [...Array(7)].forEach(() => tempSites.push(defaultValue));
        tempLoads.push(tempSites);
    }
    return tempLoads;
};

export const createProjection = (loads, siteList, bulkConfiguration) => {
    const weekDays = getDaysOfWorkingWeek(bulkConfiguration.week);
    const projection = { projectionConfigs: [], projections: [] };

    siteList.forEach((site, siteIndex) => {
        weekDays.forEach((day, dayIndex) => {
            for (let loadCnt = 0; loadCnt < loads[siteIndex][dayIndex]; loadCnt++) {
                projection.projections.push({
                    date: day.label,
                    location: site.name,
                    cargoType: getTransCargoType(bulkConfiguration.cargoType).label,
                    loadWeight: bulkConfiguration.loadWeightDisplay,
                    loadSize: bulkConfiguration.loadSize
                });
            }

            if (loads[siteIndex][dayIndex] > 0) {
                projection.projectionConfigs.push({
                    organizationLocationId: site.id,
                    weightPerLoad: bulkConfiguration.loadWeightGrams,
                    cargoTypeId: bulkConfiguration.cargoType.id,
                    numberOfLoads: loads[siteIndex][dayIndex],
                    quantityPerLoad: bulkConfiguration.loadSize,
                    date: utc(replaceTimeZone(day.value.toDate())),
                    isBasedOnOrigin: bulkConfiguration.checked,
                });
            }
        });
    });

    projection.projections.sort((a, b) => {
        if (a.date.label === b.date.label) {
            return a.location.localeCompare(b.location);
        }
        return a.date.value.isAfter(b.date.value) ? 1 : -1;
    });

    return projection;
};
