import React from 'react';
import { DeviceLocation, Load, OrganizationLocation } from '../../../types';
import { getNextStop } from './get-next-stop';
import * as HereMapRouteService from '../../../services/here-maps/here-map-route-service';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';

export const useRouteData = (deviceLocation: DeviceLocation, load?: Load | undefined): any => {
    const [route, setRoute] = React.useState(null);
    const { data: organizationLocations } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations, { disableOnFreemium: true });
    const stops = load ? load.stops : null

    // we probably need to generate the destination coords off the 'center' of geofence if exists.
    // need to include avoidance zones, as it can really thorw off estimated time of arrival.
    React.useEffect(() => {
        let stopLatLongs: Record<number, { lat: number, long: number }> | null = null;
        if (organizationLocations && stops) {
            stopLatLongs = stops.reduce((acc, stop) => {
                const orgLoc = organizationLocations.find(x => x.id === stop.organizationLocationId);
                if (!orgLoc) {
                    return acc;
                }
                const geoFence = orgLoc.customGeofence || orgLoc?.location?.geoLocation
                if (!geoFence) {
                    return acc
                }
                const { latitude, longitude } = geoFence

                return { ...acc, [stop.id]: { lat: latitude, long: longitude } };
            }, {});
        }

        if (stopLatLongs && deviceLocation?.latitude) {
            const nextStop = getNextStop(stops);
            if (nextStop) {
                const nextStopWaypoint = {
                    latitude: stopLatLongs[nextStop.id]?.lat,
                    longitude: stopLatLongs[nextStop.id]?.long
                };
                const driverWaypoint = {
                    latitude: deviceLocation.latitude,
                    longitude: deviceLocation.longitude
                };

                HereMapRouteService.calculateRoute([driverWaypoint, nextStopWaypoint], [], (result: any) => {
                    if (result.routes) setRoute(result.routes[0]);
                });
            }
        }
    }, [deviceLocation]);

    return route;
};
