import React from 'react';
import { styled } from '@mui/material/styles';
import { SearchBar } from '../../shared/search-bar';
import { L } from 'harmony-language';

const StyledDiv = styled('div')(({ theme }) => ({
    padding: '0.5rem 1rem',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
}))

interface BucketSearchProps {
    searchText: string;
    setSearchText: (searchText: string) => void;
    numOfRecords: number;
}

const BucketSearch: React.FC<BucketSearchProps> = (props) => {
    const { searchText, setSearchText, numOfRecords} = props;

    const handleSearchTextChange = (text: string) => {
        setSearchText(text);
    };

    return (
        <StyledDiv>
            <SearchBar
                placeholder={`${L.numRecords(`${numOfRecords}`)}`}
                text={searchText}
                setText={handleSearchTextChange}
            />
        </StyledDiv>
    );
};

export default BucketSearch;
