import { L, UNITS, Units } from 'harmony-language';
import React from 'react';

interface InfoPanelProps {
    pointCount: number;
    userUnits: Units;
    draggableRouteMax: number;
    onInfoClick: () => void;
}

export const InfoPanel: React.FC<InfoPanelProps> = (props) => {
    const { pointCount, userUnits, draggableRouteMax, onInfoClick } = props;

    const isMetric = userUnits === UNITS.Metric;
    const isWarning = isMetric ? draggableRouteMax > 30 : draggableRouteMax > 18.6;

    return (
        <div onClick={onInfoClick}>
            <span>Number of points in calculated route: {pointCount}</span>
            <br />
            <span style={{ color: isWarning ? 'red' : 'inherit' }}>
                Max distance: {draggableRouteMax.toFixed(2)} {isMetric ? L.unitsMetricDistance() : L.unitsImperialDistance()}
            </span>
            {/* https://www.here.com/docs/bundle/routing-api-v8-api-reference/page/index.html#tag/Routing/operation/importRoute */}
            {isWarning && <span> For traces with less frequent points, HERE maps will attempt to create an approximate reconstruction. In some situations, when consecutive points are too far apart (more than about 30 kilometers (18.6 miles) of on-road distance), they could be considered unreachable and one of them could fail to be matched.</span>}
        </div>
    );
};
