import { UNITS, Units } from 'harmony-language';

interface HaversinePoint {
    latitude: number;
    longitude: number;
}

export const getHaversineDistance = (pointA: HaversinePoint, pointB: HaversinePoint, userUnits: Units) => {
    const deg2rad = Math.PI / 180;
    const lat1 = pointA.latitude * deg2rad;
    const lon1 = pointA.longitude * deg2rad;
    const lat2 = pointB.latitude * deg2rad;
    const lon2 = pointB.longitude * deg2rad;
    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;
    const a = (
        (1 - Math.cos(dLat)) +
        (1 - Math.cos(dLon)) * Math.cos(lat1) * Math.cos(lat2)
    ) / 2;

    // Diameter of the earth in km (2 * 6371) = 12742 (miles * 0.621371)
    const diameter = userUnits === UNITS.Imperial ? 7917.5 : 12742;

    return diameter * Math.asin(Math.sqrt(a));
}

// https://math.stackexchange.com/questions/474602/reverse-use-of-haversine-formula
const haversineHelper = (theta: number) => {
    return Math.sin(theta / 2) ** 2;
}

const deg2rad = (deg: number) => {
    return deg * (Math.PI / 180);
}

const rad2deg = (rad: number) => {
    return rad * (180 / Math.PI);
}

export const calculateOuterBounds = (location: HaversinePoint, radius: number, units: Units) => {
    // Radius of the earth in mi or km
    const R = units === UNITS.Imperial ? 3958.8 : 6371;
    const deltaLatitude = radius / R
    const deltaLongitude = 2 * Math.asin(Math.sqrt(haversineHelper(radius / R) / (Math.cos(deg2rad(location.latitude)) ** 2)))

    return {
        deltaLatitude: rad2deg(deltaLatitude), 
        deltaLongitude: rad2deg(deltaLongitude)
    };
}

export const adjust = (point: HaversinePoint, by: number[]) => {
    const adjusted = { ...point }
    adjusted.latitude += by[0];
    adjusted.longitude += by[1];
    return {
        latitude: adjusted.latitude,
        longitude: adjusted.longitude,
    };
}
