import { isPolyline } from './here-map-wrapper';

export function onPointerEnter(circleOutline, timeout) {
    const currentStyle = circleOutline.getStyle();
    const newStyle = currentStyle.getCopy({
        strokeColor: 'rgb(10, 10, 10)'
    });

    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }

    circleOutline.setStyle(newStyle);

    return timeout;
}

export function onPointerLeave(event, circleOutline, timeout) {
    const currentStyle = circleOutline.getStyle();
    const newStyle = currentStyle.getCopy({
        strokeColor: 'rgba(0, 0, 0, 0)'
    });

    timeout = setNewStyle(event.currentPointer.type, circleOutline, newStyle);
    document.body.style.cursor = 'default';

    return timeout;
}

export function onPointerMove(event) {
    if (isPolyline(event.target)) {
        document.body.style.cursor = 'pointer';
    } else {
        document.body.style.cursor = 'default';
    }
}

export function onDrag(event, map, circle, circleOutline) {
    const pointer = event.currentPointer;
    const distanceFromCenterInMeters = circle.getCenter().distance(map.screenToGeo(pointer.viewportX, pointer.viewportY));

    if (isPolyline(event.target)) {
        circle.setRadius(distanceFromCenterInMeters);

        const outlineLinestring = circle.getGeometry().getExterior();

        outlineLinestring.pushPoint(outlineLinestring.extractPoint(0));
        circleOutline.setGeometry(outlineLinestring);

        event.stopPropagation();
    }
}

function setNewStyle(type, outline, style) {
    const newTimeout = (type === 'touch') ? 1000 : 0;

    return setTimeout(() => {
        outline.setStyle(style);
    }, newTimeout);
}