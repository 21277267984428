import { Units } from 'harmony-language';
import { HerePoint } from '../../types';
import { getHaversineDistance } from '../company-management/avoidance-zones/haversine';
import { HereMapContextValue } from './here-map-context';

const DRAGGABLE_POINTS = 10;

export const distributedCopy = (items: HerePoint[]) => {
    const elements = [items[0]];
    const totalItems = items.length - 2;
    const interval = Math.floor(totalItems / (DRAGGABLE_POINTS - 2));
    for (let i = 1; i < DRAGGABLE_POINTS - 1; i++) {
        elements.push(items[i * interval]);
    }
    elements.push(items[items.length - 1]);
    return elements;
}

export const removeMapObjectById = (context: HereMapContextValue, objectId: number) => {
    const existingObject = context.map.getObjects().find(obj => obj.getId() === objectId);

    if (existingObject) {
        context.map.removeObject(existingObject);
    }
}

export const lineStringToHerePointArray = (lineString: H.geo.LineString) => {
    const constructedArrayOfPoints: HerePoint[] = [];
    lineString.eachLatLngAlt((lat, lng) => {
        constructedArrayOfPoints.push({
            lat: lat,
            lng: lng,
        })
    });

    return constructedArrayOfPoints;
}

export const maxDistance = (points: HerePoint[], userUnits: Units) => {
    let max = 0;
    for (let i = 0; i < points.length - 1; i++) {
        const point1 = {
            latitude: points[i].lat,
            longitude: points[i].lng,
        }

        const point2 = {
            latitude: points[i + 1].lat,
            longitude: points[i + 1].lng,
        }

        const distance = getHaversineDistance(point1, point2, userUnits);
        max = Math.max(max, distance);
    }

    return max;
}

export const indexOnLine = (points: HerePoint[], clickedLat: number, clickedLng: number) => {
    const newPoint = { lat: clickedLat, lng: clickedLng };

    const isCollinear = (p1: HerePoint, p2: HerePoint) => {
        const { lat: y1, lng: x1 } = p1;
        const { lat: y2, lng: x2 } = p2;
        const { lat: y, lng: x } = newPoint;

        // calculate the cross-product (should be 0 for collinear points)
        const crossProduct = (x2 - x1) * (y - y1) - (y2 - y1) * (x - x1);

        return Math.abs(crossProduct) < 1e-5; // allow small numerical errors
    }

    const isWithinBounds = (p1: HerePoint, p2: HerePoint) => {
        const yDiff = Math.abs(p1.lat - p2.lat);
        const xDiff = Math.abs(p1.lng - p2.lng);
        const isHorizontal = yDiff < xDiff;

        // check if the point is within the bounding box of the line segment
        const MINOR_DIFF = 0.001;

        if (isHorizontal) {
            const isWithinBounds = (
                Math.min(p1.lat, p2.lat - MINOR_DIFF) <= clickedLat &&
                clickedLat <= Math.max(p1.lat + MINOR_DIFF, p2.lat) &&
                Math.min(p1.lng, p2.lng) <= clickedLng &&
                clickedLng <= Math.max(p1.lng, p2.lng)
            )

            return isWithinBounds;
        } else {
            const isWithinBounds = (
                Math.min(p1.lat, p2.lat) <= clickedLat &&
                clickedLat <= Math.max(p1.lat, p2.lat) &&
                Math.min(p1.lng, p2.lng - MINOR_DIFF) <= clickedLng &&
                clickedLng <= Math.max(p1.lng + MINOR_DIFF, p2.lng)
            );

            return isWithinBounds;
        }
    }

    for (let i = 0; i < points.length - 1; i++) {
        const p1 = points[i];
        const p2 = points[i + 1];

        if (isCollinear(p1, p2) && isWithinBounds(p1, p2)) {
            return i;
        }
    }

    return null;
}
