import React from 'react';
import ReactDOMServer from 'react-dom/server';

export const getDriverIcon = (heading: number | null | undefined, color = '#74b7ff', driverName = '') => {
    if (!heading) {
        heading = 0;
    }
    const svg = (
        <div>
            <svg className='live-map-marker driver-marker' viewBox='-20 -30 40 60' width='48' height='48'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M -14 18 L 0 6 L 14 18 L 0 -18 Z'
                    stroke='black'
                    fill={color}
                    transform={`rotate(${heading})`}
                />
                <text className='driver-text' fill={color} x='20' y='8'>{driverName}</text>
            </svg>
        </div>
    );

    return ReactDOMServer.renderToString(svg);
};
