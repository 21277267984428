import React, { CSSProperties, ReactNode } from 'react';
import { Button, Drawer, Grid, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

interface DrawerTextItemProps {
    additionalStyle?: CSSProperties;
    applySpacing?: boolean;
    children: ReactNode;
}

export const DrawerTextItem: React.FC<DrawerTextItemProps> = (props) => {
    const { additionalStyle, applySpacing = false, children } = props;

    return (
        <Typography
            variant='body2'
            style={{
                ...additionalStyle,
                margin: applySpacing ? '5px' : 0,
                padding: applySpacing ? '5px' : 0,
                ...(!applySpacing ? { margin: '0px 5px 0px 5px' } : {}),
            }}
        >
            {children}
        </Typography>
    );
};


interface NotificationConfigDrawerProps {
    drawerOpen: boolean;
    setDrawerOpen: (b: boolean) => void;
}

export const NotificationConfigDrawer: React.FC<NotificationConfigDrawerProps> = (props) => {
    const { drawerOpen, setDrawerOpen } = props;

    return (
        <Drawer
            open={drawerOpen}
            anchor='bottom'
            variant='persistent'
        >
            <Button
                className={'modal-dialog-header-button'}
                type='button'
                onClick={() => setDrawerOpen(!drawerOpen)}
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                }}
            >
                <Close />
            </Button>
            <Grid
                container={true}
                direction='row'
                spacing={0}
                sx={{ padding: '10px' }}
            >
                <Grid item md={12}>
                    <Typography variant='h6'>General - Notification type and and least 1 contact required</Typography>
                </Grid>

                <Grid item md={6}>
                    <DrawerTextItem additionalStyle={{ border: '1px solid black' }} applySpacing={true}>When selecting multiple criteria columns on this screen it means <strong>AND</strong>.  For
                        example if creating a notification for Enter Avoidance Zone and you select an origin location `Site1` AND trailer cargo `Pelleted Feed`.
                        The notification will only send when a driver is hauling a stop with both things being true. Ex: stop being worked is an origin stop, 
                        stop is `Site1` and trailer is setup to haul `Pelleted Feed`.  If no criteria is defined the contacts will receive all notifications of that type.
                    </DrawerTextItem>
                </Grid>
                <Grid item md={6}>
                    <DrawerTextItem additionalStyle={{ border: '1px solid black' }} applySpacing={true}>Some notifications have criteria that is not applicable and will not be displayed and show as a grey box.  Also,
                        some notifications do not have a distinction between origin / destination locations.  In that case only locations will be shown.
                    </DrawerTextItem>
                </Grid>

                <Grid item md={6}>
                    <DrawerTextItem additionalStyle={{ border: '1px solid black' }} applySpacing={true}>If a contact is setup, but does not have a preference for how they would like to receive a notification, they will
                        be turned red.  You can hover over a contact name to see the preference for that notification.
                    </DrawerTextItem>
                </Grid>
                <Grid item md={6}>
                    <DrawerTextItem additionalStyle={{ border: '1px solid black' }} applySpacing={true}>Even if they are not red, but do not have a valid phone number / email address / logged into app. They will not receive a notification
                        for the corresponding missing destination.
                    </DrawerTextItem>
                </Grid>

                <Grid item md={12} sx={{ marginTop: '20px' }}>
                    <Typography variant='h6'>Notification specifics</Typography>
                </Grid>

                <Grid item md={6}>
                    <DrawerTextItem><strong>Stop ETA: </strong>This notification has a corresponding organization setting for the amount of time to send the ETA out.
                        Uses HERE maps for ETA, so driver must be navigating (also must have a valid ETA from HERE).  If a driver starts a stop and the setting is 30 minutes, but the driver is
                        only 5 minutes away, a notification will go out saying the driver is 5 minutes away.
                    </DrawerTextItem>
                </Grid>
                <Grid item md={6}>
                    <DrawerTextItem><strong>Stop ETA Delayed: </strong>This notification has a corresponding organization setting.
                        Driver must be navigating.  Uses the ETA and scheduled time for stop to determine if the driver will arrive within the threshold set in the organization settings.
                        Repeats sending notification based on the same threshold setting.
                    </DrawerTextItem>
                </Grid>

                <Grid item md={12} sx={{ marginTop: '20px' }}>
                    <DrawerTextItem>The following 4 notifications are all different ways of notifying based on <strong>stop complete</strong>.</DrawerTextItem>
                </Grid>
                <Grid item md={6}>
                    <DrawerTextItem><strong>Stop Enroute To Destinations: </strong><u>Corresponding Stop</u> An origin stop completed.  With the intention to notify destinations.</DrawerTextItem>
                </Grid>
                <Grid item md={6}>
                    <DrawerTextItem><strong>Stop Delivered To Destinations: </strong>A destination stop has been completed.  With the intention to notify destinations.</DrawerTextItem>
                </Grid>

                <Grid item md={6}>
                    <DrawerTextItem><strong>Stop Enroute From Origins: </strong>An origin stop completed.  With the intention to notify origins.</DrawerTextItem>
                </Grid>
                <Grid item md={6}>
                    <DrawerTextItem><strong>Stop Delivered From Origins: </strong><u>Corresponding Stop</u> A destination stop has been completed.  With the intention to notify origins.</DrawerTextItem>
                </Grid>

                <Grid item md={6} sx={{ marginTop: '20px' }}>
                    <DrawerTextItem><strong>Stop Arrived Wrong Location: </strong>Driver manually arrived at a stop.  Device GPS determined it was not within the bounds of the site.</DrawerTextItem>
                </Grid>
                <Grid item md={6} sx={{ marginTop: '20px' }}>
                    <DrawerTextItem><strong>Stop Completed Wrong Location: </strong>Driver completed a stop.  Device GPS determined it was not within the bounds of the site.</DrawerTextItem>
                </Grid>

                <Grid item md={6}>
                    <DrawerTextItem><strong>Site Issue Recorded: </strong>Driver recorded a site isse.</DrawerTextItem>
                </Grid>
                <Grid item md={6}>
                    <DrawerTextItem><strong>Enter Avoidance Zone: </strong>Driver entered an avoidance zone.</DrawerTextItem>
                </Grid>

                <Grid item md={6}>
                    <DrawerTextItem><strong>Checklist Defects: </strong>Driver submitted a checklist with defects.</DrawerTextItem>
                </Grid>
                <Grid item md={6}>
                    <DrawerTextItem><strong>API Request Issue: </strong>Errors in integration endpoints.</DrawerTextItem>
                </Grid>

                <Grid item md={6}>
                    <DrawerTextItem><strong>API Request Missing Resources: </strong>Errors in integration endpoints related to resources (tractors/trailers/locations/etc...).</DrawerTextItem>
                </Grid>
                <Grid item md={6}>
                    <DrawerTextItem><strong>Stop Comments Added: </strong>Driver added comments to a stop.</DrawerTextItem>
                </Grid>

                <Grid item md={6}>
                    <DrawerTextItem><strong>Stop Images Added: </strong>Driver added images to a stop.</DrawerTextItem>
                </Grid>
                <Grid item md={6}>
                    <DrawerTextItem><strong>Stop Split: </strong><u>Corresponding Stop</u> Stop was split in either UI/mobile.</DrawerTextItem>
                </Grid>

                <Grid item md={6}>
                    <DrawerTextItem><strong>Auger On Invalid Sublocation: </strong>Smart trailer required.  Driver engaged auger while not at a valid sublocation for the load.</DrawerTextItem>
                </Grid>
                <Grid item md={6}>
                    <DrawerTextItem><strong>Unscheduled Stop: </strong>Driver stopped for a length of time (organization settings).  Critera for origin/destination/customer/stop cargo types are only taken into account for incomplete stops.</DrawerTextItem>
                </Grid>

                <Grid item md={12} sx={{ marginTop: '20px' }}>
                    <Typography variant='h6'>Definitions</Typography>
                </Grid>
                <Grid item md={12}>
                    <DrawerTextItem><strong>Corresponding Stop: </strong>
                        All <strong>cargo</strong> stops that are opposite type of the initiating stop.  Also, if the initiating stop has an `order number`,
                        gets the `matching` opposite stop.
                        If only 1 opposing stop or initiating stop `order number` is nothing, returns the opposite stops.
                    </DrawerTextItem>
                </Grid>
            </Grid>
        </Drawer>
    );
};
