import { Image } from '../../../../types';

const toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

export const imageFileToBase64 = async (
    file: File
): Promise<Pick<Image, 'blob' | 'type'> | undefined> => {
    try {
        const mimeType = file.type;
        const imageBase64 = await toBase64(file);
        return {
            blob: imageBase64,
            type: mimeType
        } as Pick<Image, 'blob' | 'type'>;
    } catch (err) {
        console.log(err);
    }
};
