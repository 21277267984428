import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { L } from 'harmony-language';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from '@tanstack/react-query';
import { utc } from '../../utils/date-time-utils';
import { DynamicQueryKeys } from '../../api/config';
import { AgisticsCard } from '../shared/containers/agistics-card';

export const DashboardSummary = props => {
    const { workingWeek: { weekNumber, sundayValue, saturdayValue } } = props;
    const { data, isLoading } = useQuery([DynamicQueryKeys.loadsSummary(utc(sundayValue), utc(saturdayValue))],
        {
            enabled: !props.isDisabled,
            staleTime: 5 * 60 * 1000, //5mins
        }
    );

    const renderContent = () => {
        if (isLoading) {
            return <CircularProgress />;
        } else {
            return (
                <>
                    <Typography variant={'h6'}><b>{data?.numberOfLoadsCompleted}</b> {L.completed()}</Typography>
                    <Typography variant={'h6'}><b>{data?.numberOfLoadsInTransit}</b> {L.inTransit()}</Typography>
                    <Typography variant={'h6'}><b>{data?.numberOfLoadsUpcoming}</b> {L.upcoming()}</Typography>
                </>
            );
        }
    };

    return (
        <AgisticsCard title={L.weekLoadSummary(weekNumber)}>
            {!props.isDisabled
                ? renderContent()
                : <Typography variant={'h6'}>{L.freemiumPitchWeeklyLoadSummary()}</Typography>}
        </AgisticsCard>
    );
};

DashboardSummary.propTypes = {
    workingWeek: PropTypes.object,
    isDisabled: PropTypes.bool
};

