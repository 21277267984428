import { getTransCargoType } from '../../../../constants/constants';
import { L } from 'harmony-language';

export function validateTrailer(trailer) {

    if (!trailer.userDisplayName || trailer.userDisplayName === '') {
        throw new Error(L.trailerNameRequired());
    }

    if (trailer.trailerCargoTypes) {
        const valuesSoFar = {};

        trailer.trailerCargoTypes.forEach(trailerCargoType => {

            if (valuesSoFar[trailerCargoType.cargoTypeId]) {
                throw new Error(L.duplicateCargoType(getTransCargoType({ id: trailerCargoType.cargoTypeId }).label));
            }
            valuesSoFar[trailerCargoType.cargoTypeId] = true;
        });
    }
}
