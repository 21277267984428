import { getCurrentLanguage, getCurrentUnitSystem } from 'harmony-language';
import { getTimezone } from './date-time-utils';

export const downloadBillsOfLading = (orderIds) => {
    const language = getCurrentLanguage();
    const units = getCurrentUnitSystem();
    const timezone = getTimezone();

    window.location.href = `${API_BASE}/api/orders/bill-of-lading?orderIds=[${orderIds}]&language=${language}&units=${units}&timezone=${timezone}`;
};

export const downloadDeliveryTickets = (orderIds) => {
    window.location.href = `${API_BASE}/api/orders/delivery-tickets?orderIds=[${orderIds}]`
}

export const downloadDailyReport = (reportDate, organizationId, resourceType, resourceId) => {
    const language = getCurrentLanguage();
    const units = getCurrentUnitSystem();
    const timezone = getTimezone();
    const resourceQuery = (resourceType && resourceId) ? `&resourceType=${resourceType}&resourceIds=[${resourceId}]` : '';

    window.location.href = `${API_BASE}/api/organizations/${organizationId}/daily-reports?language=${language}&units=${units}&timezone=${timezone}&date=${reportDate}${resourceQuery}`;
};

export const downloadResourceReport = (organizationId, dateBegin, dateEnd, resourceType, resourceId, weightSystem) => {
    const url = new URL(`${API_BASE}/api/organizations/${organizationId}/resource-reports`);
    let params = new URLSearchParams(url.search);
    params.set('language', getCurrentLanguage());
    params.set('units', getCurrentUnitSystem());
    params.set('timezone', getTimezone());
    params.set('unitsWeight', weightSystem);
    params.set('dateBegin', dateBegin);
    params.set('dateEnd', dateEnd);
    params.set('resourceType', resourceType);
    params.set('resourceId', resourceId);

    window.location.href = `${url.toString()}?${params.toString()}`;
};

export const downloadLoadsCSV = (organizationId, weightSystem, loadIds) => {
    const url = new URL(`${API_BASE}/api/organizations/${organizationId}/loads-csv-export`);
    let params = new URLSearchParams(url.search);
    params.set('language', getCurrentLanguage());
    params.set('units', getCurrentUnitSystem());
    params.set('timezone', getTimezone());
    params.set('unitsWeight', weightSystem);
    params.set('loadIds', loadIds);

    window.location.href = `${url.toString()}?${params.toString()}`;
}
