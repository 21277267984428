import axios from 'axios';
import { setLanguageDictionary, SUPPORTED_LANGUAGES } from 'harmony-language';
import moment from 'moment-timezone';
import { getCurrentLocale } from '../../../services/localization/date-picker-localization';
import { LanguageCode } from 'harmony-constants';

export const fetchLanguageDictionary = async (langCode?: LanguageCode) => {
    if (!langCode) {
        // @ts-ignore
        langCode = navigator.languages ? navigator.languages[0] : navigator.language;
    }

    if (!SUPPORTED_LANGUAGES.some(language => language.code === langCode)) {
        langCode = LanguageCode.EnglishUS;
    }

    const languageDictionary = (await axios.get(`${API_BASE}/api/language-dictionary/${langCode}`)).data;

    setLanguageDictionary(languageDictionary);
    const locale = getCurrentLocale();

    moment.locale(locale);
};
