import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions } from '../config';
import { useSnackbar } from '../../components/notifications/use-snackbar';
import { Load, LoadCache, OrganizationCarrier } from '../../types';
import { loadCellIsEditable } from '../../components/shared/load-table/utils/load-utils';
import { OrderStatus } from 'harmony-constants';
import { useUser } from '../queries/use-user';
import { useLoadsKey } from '../config-hooks';

type CarrierMutationOptions = {
    loads: Load[];
    carrier?: OrganizationCarrier;
}

async function assignCarrier(options: CarrierMutationOptions) {
    const updatedLoads = options.loads.map(load => {
        // before carrier had load settings (who edits driver/trailer/tractor) we just removed the value for those fields in the api.
        // example: now if an owning org can (as either CarrierEditLoadOption.Carrier or CarrierEditLoadOption.both) assign driver and currently have one of their drivers assigned to the load
        //          the driver should not be removed as the currently assigned driver is valid.
        if (options.carrier) {
            const incommingCarrierId = options.carrier.carrierOrganizationId;
            const isBecomingCarrierLoadOrAlreadyThisCarrier = load.organizationId === load.transportingOrganizationId || load.transportingOrganizationId === incommingCarrierId;
            const isAlreadyCarrierLoadAndBecomingDifferentCarrierLoad = load.organizationId !== load.transportingOrganizationId && load.transportingOrganizationId !== incommingCarrierId;

            let transportedByUserId = null, tractorId = null, trailerId = null;
            if (isBecomingCarrierLoadOrAlreadyThisCarrier || isAlreadyCarrierLoadAndBecomingDifferentCarrierLoad) {
                //check status on loadDriver loadTractor loadTrailer
                if (loadCellIsEditable('loadDriver', options.carrier, false)) {
                    transportedByUserId = load.transportedByUserId
                }
                if (loadCellIsEditable('loadTrailer', options.carrier, false)) {
                    trailerId = load.trailerId;
                }
                if (loadCellIsEditable('loadTractor', options.carrier, false)) {
                    tractorId = load.tractorId;
                }
            }

            return {
                id: load.id,
                status: transportedByUserId ? OrderStatus.Assigned : OrderStatus.Open,
                transportedByUserId: transportedByUserId,
                tractorId: tractorId,
                trailerId: trailerId,
                transportingOrganizationId: options.carrier.carrierOrganizationId
            };
        } else {
            //carrier unassign
            return {
                id: load.id,
                status: OrderStatus.Open,
                transportedByUserId: null,
                tractorId: null,
                trailerId: null,
                transportingOrganizationId: load.organizationId,
            }
        }
    });

    return (await axios.post(`${API_BASE}/api/carriers/assign-loads`, updatedLoads)).data;
}

export const useAssignCarrierMutation = () => {
    const snackbar = useSnackbar();
    const { user } = useUser();
    const queryClient = useQueryClient();
    const loadsKey = useLoadsKey();

    return useMutation(assignCarrier, {
        ...mutationOptions(loadsKey, queryClient),
        onMutate: (options: CarrierMutationOptions) => {
            const { loads, carrier } = options;

            queryClient.cancelQueries(loadsKey);
            const previousCache = queryClient.getQueryData<LoadCache>(loadsKey);

            const newLoads = previousCache?.data?.map((load: Load) => {
                if (loads.find(l => l.id === load.id)) {
                    return {
                        ...load,
                        acceptedByUserId: user.id,
                        transportingOrganizationId: carrier ? carrier.carrierOrganizationId : load.organizationId,
                    };
                }
                return load;
            });

            // Optimistically update to the new value
            queryClient.setQueryData(loadsKey, {
                ...previousCache,
                data: newLoads
            });
            const snackbarString = carrier ? `${carrier.carrier?.name}` : (user.organization?.name || '')
            snackbar(L.assignedSnackbarSuccess(snackbarString));

            // Return a rollback function
            return () => queryClient.setQueryData(loadsKey, previousCache);
        }
    });
};
