import {
    getTransCargoTypeLabel,
    getTransLocationTypeLabel,
    isCargoStop,
    STOP_TYPES
} from '../../../../constants/constants';
import { L } from 'harmony-language';
import Typography from '@mui/material/Typography';
import React from 'react';
import { LocationResource, Stop } from '../../../../types';
import { useLocationInfo } from '../../hooks/use-location-info';

const getConflictMessage = (label: string, locationTypeId: number, stop?: Stop) => {
    const cargoTypeId = stop?.cargoTypeId as number;
    if (stop?.type === STOP_TYPES().Origin.key) {
        return L.resourceCargoTypeOriginMismatchWarning(label, getTransLocationTypeLabel(locationTypeId), getTransCargoTypeLabel(cargoTypeId));
    } else if (stop?.type === STOP_TYPES().Destination.key) {
        return L.resourceCargoTypeDestinationMismatchWarning(label, getTransLocationTypeLabel(locationTypeId), getTransCargoTypeLabel(cargoTypeId));
    }
    else if(stop?.type === STOP_TYPES().Weigh.key){
        return L.resourceLocationTypeMismatchWarning(label, getTransLocationTypeLabel(locationTypeId), L.weighBridge());
    }
};

export const LocationCargoTypeConflictTooltip: React.FC<{ resource: LocationResource }> = (props) => {
    const {resource} = props;
    const stop = resource.stop;
    const { toSiteDisplayName, locationTypeId } = useLocationInfo(Number(resource.id), resource.stop?.type);

    if (!locationTypeId) {
        return null;
    }

    const message = getConflictMessage(toSiteDisplayName(stop.organizationSubLocationIds), locationTypeId, stop);
    return (
        <div>
            <Typography>{isCargoStop(stop) ?  L.cargoTypeInvalid() : L.locationTypeInvalid()}</Typography>
            <div>
                {message}
            </div>
        </div>
    );
};
