import {
    getCarrier,
    getFeatures,
    getOrganizationTier,
    getPermissions
} from '../utils/user-mappings';
import { CarrierEditLoadOption, CustomerTier, CustomerTierValue, Features, PermissionType } from 'harmony-constants';
import { useUser } from '../../../api/queries/use-user';

export const usePermissions = (permissions?: PermissionType | PermissionType[]) => {
    const { user } = useUser();
    const userPermissions = getPermissions(user);

    if (!permissions) {
        return true;
    }

    if (Array.isArray(permissions)) {
        return permissions.every(p => {
            return userPermissions.map((up) => up.name).includes(p);
        });
    }

    return userPermissions.some((p) => p.name === permissions);
};

export const useTier = (tier: CustomerTier) => {
    const { user } = useUser();
    return getOrganizationTier(user) === tier;
}

export const useHasTier = (tier: CustomerTier) => {
    const { user } = useUser();
    const organizationTier = user?.organization?.tier || CustomerTier.Freemium;
    const userTier = CustomerTierValue[organizationTier];

    return userTier >= CustomerTierValue[tier];
}

export const useFeatures = (features?: Features) => {
    const { user } = useUser();
    const companyFeatures = getFeatures(user);

    if (!features) {
        return true;
    }

    return companyFeatures.some((f) => f.name === features);
};

export const useCarrier = () => {
    const { user } = useUser();
    const isCarrier = getCarrier(user);
    return isCarrier;
}

export const useCarrierAccess = (accessBool: boolean) => {
    const isCarrier = useCarrier();
    if (isCarrier && !accessBool) {
        return false;
    }

    return true;
}

export const useAtLeastOneReport = () => {
    const reportPermissions = [
        PermissionType.ViewReportDriverEfficiency,
        PermissionType.ViewReportDecision,
        PermissionType.ViewReportSequencing,
        PermissionType.ViewReportSplitStop,
        PermissionType.ViewReportOnTime,
    ];

    const per = reportPermissions.map(x => usePermissions(x));
    const hasOne = per.some(x => x);
    return hasOne;
};

export const useEquipmentEditable = () => {
    const { user } = useUser();
    const clients = user?.organization?.clients;

    if (clients && clients.length) {
        const canEditTractors = clients.map(client => {
            const tractorSetting = client.organizationCarrier.loadTractor;
            return tractorSetting === CarrierEditLoadOption.Carrier || tractorSetting === CarrierEditLoadOption.Both ? true : false;
        }).some(x => x);

        const canEditTrailers = clients.map(client => {
            const trailerSetting = client.organizationCarrier.loadTrailer;
            return trailerSetting === CarrierEditLoadOption.Carrier || trailerSetting === CarrierEditLoadOption.Both ? true : false;
        }).some(x => x);

        return { canEditTractors, canEditTrailers };
    } else {
        return { canEditTractors: true, canEditTrailers: true };
    }
};
