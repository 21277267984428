import { getCurrentLanguage } from 'harmony-language';
import 'moment/locale/es';
import 'moment/locale/nl';
import moment from 'moment-timezone';

moment.locale('en');

export const getCurrentLocale = () => {
    const languageCode = getCurrentLanguage();

    switch (languageCode) {
    case 'en-US': {
        return 'en';
    }
    case 'es-MX': {
        return 'es';
    }
    case 'nl': {
        return 'nl';
    }
    case 'fr-CA': {
        return 'fr';
    }
    default:
        return 'en';
    }
};
