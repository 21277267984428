import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions } from '../config';
import { useSnackbar } from '../../components/notifications/use-snackbar';
import { Load, LoadCache, User } from '../../types';
import { useUser } from '../queries/use-user';
import { useLoadsKey } from '../config-hooks';

interface AssignDriverOptions {
    loads: Load[];
    driver: User;
    currentUserIsCarrier: boolean;
}

async function assignDriver(options: AssignDriverOptions) {
    const updatedLoads = options.loads.map(load => {
        return {
            id: load.id,
            transportedByUserId: options.driver.id,
            tractorId: options.driver?.driverInfo?.defaultTractorId || null,
            trailerId: options.driver?.driverInfo?.defaultTrailerId || null,
        };
    });

    return (await axios.post(`${API_BASE}/api/drivers/assign-orders`, updatedLoads)).data;
}

export const useAssignDriverMutation = () => {
    const snackbar = useSnackbar();
    const { user } = useUser();
    const loadsKey = useLoadsKey();
    const queryClient = useQueryClient();

    return useMutation(assignDriver, {
        ...mutationOptions(loadsKey, queryClient),
        onMutate: (options: { loads: Load[], driver: User }) => {
            const { loads, driver } = options;

            queryClient.cancelQueries(loadsKey);

            const previousCache = queryClient.getQueryData<LoadCache>(loadsKey);
            const newLoads = previousCache?.data.map((load) => {
                if (loads.find(l => l.id === load.id)) {
                    return {
                        ...load,
                        acceptedByUserId: user.id,
                        transportedByUserId: driver.id,
                    };
                }
                return load;
            });

            // Optimistically update to the new value
            queryClient.setQueryData(loadsKey, {
                ...previousCache,
                data: newLoads
            });
            snackbar(L.assignedSnackbarSuccess(`${driver.firstName} ${driver.lastName}`));

            // Return a rollback function
            return () => queryClient.setQueryData(loadsKey, previousCache);
        }
    });
};
