import { getCurrentUnitSystem, L, UNITS } from 'harmony-language';
import { kgToPounds, metersPerSecondToKMPH, metersPerSecondToMPH } from '../../../utils/unit-conversion';

export const useConvertedUnitWeight = (weight = 0): { result: number, unitTranslation: string } => {
    const isMetric = getCurrentUnitSystem() === UNITS.Metric;
    const unitTranslation = isMetric ? L.unitsMetricWeightAbbr() : L.unitsImperialWeightAbbr();
    const result = Math.round((isMetric ? weight : kgToPounds(weight)) * 100) / 100;

    return {
        result,
        unitTranslation
    };
};

export const useConvertedUnitSpeed = (speed = 0): { result: number, unitTranslation: string } => {
    const isMetric = getCurrentUnitSystem() === UNITS.Metric;
    const unitTranslation = isMetric ? L.unitsMetricSpeedAbbr() : L.unitsImperialSpeedAbbr();
    const result = Math.round((isMetric ? metersPerSecondToKMPH(speed) : metersPerSecondToMPH(speed)) * 100) / 100;

    return {
        result,
        unitTranslation
    };
};
