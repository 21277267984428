import { useState } from 'react';
import { useAddOrganizationLocationImages } from '../mutations/add/use-add-organization-location-images';
import { useQuery } from '@tanstack/react-query';
import { OrgQueryKeys } from '../config';
import { imageFileToBase64 } from '../../components/company-management/locations/edit-create-location/image-util';
import { useDeleteOrganizationLocationImages } from '../mutations/delete/use-delete-organization-location-images';
import { useCompanyId } from '../../app/agistics-context';
import { Image, OrganizationLocation } from '../../types';

const useOrgLocationImages = ({
    organizationLocation
}: {
    organizationLocation: OrganizationLocation | null;
}) => {
    const [newImageFiles, setNewImageFiles] = useState<Pick<Image, 'blob' | 'type'>[]>(
        []
    );
    const [deleteImageIds, setDeleteImageIds] = useState<number[]>([]);
    const { mutateAsync: addImages, isLoading: isCreatingImages } =
        useAddOrganizationLocationImages();
    const { mutate: deleteImage, isLoading: isDeletingImage } =
        useDeleteOrganizationLocationImages();

    let existingImages: Image[] = [], isLoadingImages;
    if (organizationLocation) {
        const ret = useOrgLocationImagesQuery(organizationLocation.id);
        existingImages = ret.data || [];
        isLoadingImages = ret.isLoading;
    } else {
        existingImages = [];
        isLoadingImages = false;
    }

    const onDroppedFiles = async (droppedFiles: File[]) => {
        if (droppedFiles?.length) {
            const newImages = (
                await Promise.all(droppedFiles.map((f) => imageFileToBase64(f)))
            ).filter(Boolean) as Pick<Image, 'blob' | 'type'>[];
            if (newImages) {
                setNewImageFiles(newImageFiles.concat(newImages));
            }
        }
    };

    const onLocationSubmit = async (
        location: OrganizationLocation | undefined
    ) => {
        if (!location) {
            return;
        }
        await Promise.all(
            [...deleteImageIds.map((imageId) =>
                deleteImage({
                    imageId,
                    locationId: location?.id
                })
            ),
            (newImageFiles.length && (await addImages({ images: newImageFiles, locationId: location?.id })))]
        );
    };

    const removeImage = (image: Image) => {
        if (image.id) {
            setDeleteImageIds(deleteImageIds.concat(image.id));
        } else {
            setNewImageFiles(
                newImageFiles.filter((ni) => image.blob !== ni.blob)
            );
        }
    };

    const isLoading = isLoadingImages || isCreatingImages || isDeletingImage;

    const images = existingImages.filter((ei) => !deleteImageIds.includes(ei.id)).concat(newImageFiles as Image[]);

    return { images, isLoading, onDroppedFiles, onLocationSubmit, removeImage };
};

export const useOrgLocationImagesQuery = (locationId: number) => {
    const companyId = useCompanyId();

    const key = OrgQueryKeys.locationImages(companyId, locationId);

    return useQuery<Image[]>([key], {  enabled: !!locationId, staleTime: 5 * 60 * 1000, cacheTime: 0 });
};

export default useOrgLocationImages;
