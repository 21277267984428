import React from 'react';
import { useTractorTrailer } from '../../api/queries/use-tractor-trailer';
import { DriverInfo } from '../../types';
import { L } from 'harmony-language';

interface DriverDefaultsProps {
    driverInfo: DriverInfo;
}

export const DriverDefaults: React.FC<DriverDefaultsProps> = (props) => {
    const { driverInfo } = props;
    const { tractors = [], trailers = [] } = useTractorTrailer();

    const tractorName = tractors.find(x => x.id === driverInfo.defaultTractorId)?.userDisplayName;
    const trailerName = trailers.find(x => x.id === driverInfo.defaultTrailerId)?.userDisplayName;

    return (
        <>
            <div>{tractorName && `${L.defaultTractor()}: ${tractorName}`}</div>
            <div>{trailerName && `${L.defaultTrailer()}: ${trailerName}`}</div>
        </>
    );
};
