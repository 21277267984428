import React from 'react';
import { connectSocket } from './connect-socket';
import { disconnectSocket } from './disconnect-socket';
import { useUser } from '../../api/queries/use-user';

export const SocketListener = () => {
    const { user: self } = useUser();

    React.useEffect(() => {
        if (self) {
            connectSocket();
        }
    }, [self]);

    React.useEffect(() => {
        return () => {
            disconnectSocket();
        };
    }, []);

    return null;
};
