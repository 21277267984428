import React from 'react';
import PropTypes from 'prop-types';
import { HereMapContext } from './here-map-context';
import { createInfoBubble, createRectangle } from '../../services/here-maps/here-map-wrapper';
import { makeShapeDynamic } from '../../services/here-maps/here-map-resizable-shapes';

export const HereMapRectangle = (props) => {
    const context = React.useContext(HereMapContext);
    const instance = React.useRef({});

    React.useEffect(() => {
        return () => {
            instance.current.map?.removeObject(instance.current.rectangle);
            instance.current.rectangle = null;
        };
    }, []);

    React.useEffect(() => {
        instance.current = {
            ...instance.current,
            map: context.map,
            ui: context.ui,
            behavior: context.behavior
        };

        if (instance.current.map) {
            if (instance.current.rectangle) {
                instance.current.map.removeObject(instance.current.rectangle);
            }

            let rectangle = createRectangle(props.pointA, props.pointB, props.strokeColor, props.fillColor, props.type);

            if (props.changeable) {
                rectangle = makeShapeDynamic(instance.current.map, instance.current.behavior, rectangle, props.onChanging, props.onChanged);
            }

            instance.current.rectangle = rectangle;
            instance.current.map.addObject(rectangle);

            if (props.children) {
                const infoBubble = createInfoBubble(props.pointA.lat, (props.pointA.lng + props.pointB.lng) / 2, props.children);
                infoBubble.setData({ isRectangleTooltip: true });

                rectangle.addEventListener('pointerenter', () => {
                    instance.current.ui.addBubble(infoBubble);
                });

                rectangle.addEventListener('pointerleave', () => {
                    const allBubbles = instance.current.ui.getBubbles();

                    if (allBubbles) {
                        allBubbles.map((bubble) => {
                            // don't want to remove all info bubbles, ie: when clicked on device and showing driver tooltip
                            if (bubble.getData()?.isRectangleTooltip) {
                                instance.current.ui.removeBubble(bubble);
                            }
                        })
                    }
                });
            }
        }
    }, [props.pointA, props.pointB, context.map]);

    return null;
};

HereMapRectangle.propTypes = {
    children: PropTypes.node,
    pointA: PropTypes.object,
    pointB: PropTypes.object,
    strokeColor: PropTypes.string,
    fillColor: PropTypes.string,
    onClick: PropTypes.func,
    changeable: PropTypes.bool,
    onChanging: PropTypes.func,
    onChanged: PropTypes.func,
    type: PropTypes.string,
};
