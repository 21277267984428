import React from 'react';
import { Stop } from '../../types';
import { useLoadContext } from './load-context';
import { calculateStopArrivalTimes } from '../../utils/data-mapping-utils';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../api/config';
import { withThroughputs } from '../../utils/throughput-utils';
import { useTravelTimesLoad } from '../travel-times/use-travel-times';
import { useCarrier } from '../user/selectors/use-permissions';

export type ConditionalEditStopProperties = {
    orderNumber: boolean,
    productionPlan: boolean,
    lotId: boolean
}
export const StopContext = React.createContext<{
    stop: Stop;
    onChange: ((stop: Partial<Stop>) => void) | null;
    calculateStopTimes: (sequence: number) => void;
    isSaving?: boolean,
    canEditProperty: ConditionalEditStopProperties,
    stopEditDisabled: boolean,
}>({
    stop: {
        id: 0,
        orderId: 0,
        type: 'Origin',
        sequence: 1,
        arrivalTime: '',
        orderNumber: null,
        productionPlan: null,
        lotId: null,
        quantity: 0,
        cargoTypeId: null,
        organizationLocationId: 0,
        status: 'Not Started',
        splitId: null,
        notes: null,
        customerOrderNumber: null,
        requiredBegin: null,
        requiredEnd: null,
        readyTime: null,
        weight: null,
        actualWeight: null,
    },
    onChange: () => {
    },
    calculateStopTimes: () => {
    },
    canEditProperty: {
        orderNumber: true,
        productionPlan: true,
        lotId: true
    },
    stopEditDisabled: false,
});
export const useStopContext = () => React.useContext(StopContext);

export const StopProvider: React.FC<{ stop: Stop, children: React.ReactNode }> = (props) => {
    const { load, editFunctions } = useLoadContext();
    const { stop } = props;
    const { travelTimesCache } = useTravelTimesLoad(load);
    const { data: throughputs } = useOrganizationQuery(OrgQueryKeys.throughput);
    const [canEditProperty, setCanEditProperty] = React.useState({
        orderNumber: !stop.orderNumber,
        productionPlan: !stop.productionPlan,
        lotId: !stop.lotId
    });
    const currentUserIsCarrierOrg = useCarrier();

    React.useEffect(() => {
        setCanEditProperty({
            orderNumber: !stop.orderNumber,
            productionPlan: !stop.productionPlan,
            lotId: !stop.lotId
        })

    }, [stop])

    const stopOnChange = (stop: Partial<Stop>) => {
        editFunctions?.onChange?.({
            ...load,
            stops: load.stops.map(x => {
                if (x.sequence === props.stop.sequence) {
                    return { ...x, ...stop }
                } else {
                    return x;
                }
            }).map(withThroughputs(throughputs))
        });
    };

    const calculateStopTimes = (sequence: number) => {
        editFunctions?.onChange?.({
            ...load,
            stops: calculateStopArrivalTimes(load.stops.map(withThroughputs(throughputs)), sequence, travelTimesCache)
        })
    };

    return (
        <StopContext.Provider
            value={{
                    stop: props.stop,
                    onChange: editFunctions?.onChange ? stopOnChange : null,
                    calculateStopTimes,
                    isSaving: editFunctions?.isSaving,
                    canEditProperty,
                    stopEditDisabled: currentUserIsCarrierOrg }}>
            {props.children}
        </StopContext.Provider>
    )
}
