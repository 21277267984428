import React from 'react';
import { Box } from '@mui/material';

interface AgisticsTabPanelProps {
    children: React.ReactNode;
    index: number;
    value: number;
    p: number;
    permission?: boolean;
}

export const AgisticsTabPanel: React.FC<AgisticsTabPanelProps> = (props) => {
    const { children, value, index, permission = true, p } = props;

    return (
        <div
            role='tabpanel'
            hidden={!permission || value !== index}
            id={`tabpanel-${index}`}
        >
            {value === index && (
                <Box p={p}>
                    {children}
                </Box>
            )}
        </div>
    );
};
