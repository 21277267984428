import React from 'react';
import TextField from '@mui/material/TextField';
import { L } from 'harmony-language';
import { EitherOrganizationLocation } from './edit-create-location';

interface PremiseIdInputProps {
    organizationLocation: EitherOrganizationLocation;
    setOrganizationLocation: (e: EitherOrganizationLocation) => void;
}

export const PremiseIdInput: React.FC<PremiseIdInputProps> = (props) => {
    const { organizationLocation, setOrganizationLocation } = props;

    return (
        <TextField
            variant='standard'
            label={L.premiseId()}
            id='premise-id'
            style={{ width: '100%' }}
            value={organizationLocation.premiseId || ''}
            onChange={(e) => {
                setOrganizationLocation({
                    ...organizationLocation,
                    premiseId: e.target.value,
                });
            }}
            inputProps={{ maxLength: 7 }}
        />
    );
};
