export const isValidStopLocation = (orgLocationId: number | null) => {
    return orgLocationId && orgLocationId > 0 ? true : false;
};

export const isValidVinNumber = (vinNumber: string | null) => {
    return vinNumber && vinNumber.length !== 17 ? false : true;
};

export const isIntegerAndMoreThanZeroAndLessThan600 = (number: number | undefined) => {
    if (number && Number.isInteger(number) && number > 0 && number <= 600) {
        return true;
    } else {
        false
    }
};

export const isValidLatitude = (latitude: number | null) => {
    return latitude ? (latitude >= -90 && latitude <= 90) : false;
};

export const isValidLongitude = (longitude: number | null) => {
    return longitude ? (longitude >= -180 && longitude <= 180) : false;
};
