import React from 'react';
import { orderToDateTimeRange, stopToDateTimeRange } from '../../../utils/data-mapping-utils';
import { filterForConflicts } from '../../downtime/util/downtime-utils';
import { DowntimeConflictTooltip } from './tooltips/downtime-conflict-tooltip';
import { DownTime, Resource, Load } from '../../../types';
import { Conflict } from './resource-conflict-utils';

export const useGetDowntimeConflict = (load: Load, downtimes: any, resource: Resource): Conflict => {
    const dateRange = orderToDateTimeRange(load);
    const id = resource.type === 'location' ? Number(resource.id) : resource.id;
    const filteredDowntimes = (downtimes as DownTime[]).filter(
        (d) => {
            return (d.resourceType.toUpperCase() === resource.type.toUpperCase() && d.resourceId === id);
        }
    );
    let range = dateRange;
    if ("stop" in resource && Boolean(resource.stop)) {
        range = stopToDateTimeRange(resource.stop)
    }

    const conflicts = filterForConflicts(filteredDowntimes, range);
    const conflict = conflicts?.[0] ? <DowntimeConflictTooltip conflict={conflicts[0]}/> : null;

    return { conflict: conflict }
}
