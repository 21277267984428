import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { NavigateFunction } from 'react-router-dom';
import { L } from 'harmony-language';

export const activateUserCheck = async (uuid: string, navigate: NavigateFunction) => {
    const response = await axios.get(`${API_BASE}/api/users/${uuid}/activate`);

    if (response.data.valid === false) {
        navigate('/login');
        enqueueSnackbar(L.passwordLinkExpired(), { variant: 'warning' });
    }
};
