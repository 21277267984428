import React from 'react';
import { LoadFilter, LoadFiltering } from './utils/use-load-filtering';
import { Select } from '../inputs/select';
import { Button, TextField } from '@mui/material';
import { L } from 'harmony-language';
import { useWeights } from '../hooks/use-weights';

export const Comparison = {
    LessThan: 'less-than',
    LessThanEqual: 'less-than-equal',
    MoreThan: 'more-than',
    MoreThanEqual: 'more-than-equal',
    Equal: 'equal',
};

const COMPARISON_OPERATORS = [
    { id: Comparison.LessThan, label: '<' },
    { id: Comparison.LessThanEqual, label: '<=' },
    { id: Comparison.MoreThan, label: '>' },
    { id: Comparison.MoreThanEqual, label: '>=' },
    { id: Comparison.Equal, label: '=' },
];

interface MoreFiltersProps {
    filtering: LoadFiltering;
}

export const ComparisonFilters: React.FC<MoreFiltersProps> = (props) => {
    const { filtering } = props;
    const [item, setItem] = React.useState(COMPARISON_OPERATORS[0]);
    const [weight, setWeight] = React.useState<string | number>('');
    const { weightAbbreviation } = useWeights();

    const internalClear = () => {
        filtering.removeByField('operatorWeight');
    }

    const handleApply = () => {
        internalClear();

        const newFilter: LoadFilter = {
            entity: 'calculatedField',
            field: 'operatorWeight',
            category: item.id,
            label: 'Unsure!',
            value: weight,
        }

        filtering.add(newFilter);
    }

    return (
        <div style={{ width: '100%', display: 'flex', columnGap: '15px', alignItems: 'flex-end' }}>
            <Select
                label='Operator'
                list={COMPARISON_OPERATORS}
                item={item}
                style={{ width: '5%' }}
                onChange={(item) => {
                    setItem(item);
                }}
            />
            <TextField
                variant='standard'
                value={weight}
                type='number'
                label={`${L.weight()} (${weightAbbreviation})`}
                onChange={(e) => {
                    const inputNum = Number(e.target.value);
                    setWeight(inputNum ? inputNum : '');
                }}
            />
            <Button
                variant='contained'
                onClick={handleApply}
                disabled={typeof weight !== 'number'}
            >
                {L.apply()}
            </Button>
            <Button
                variant='outlined'
                onClick={() => {
                    setWeight('');
                    internalClear();
                }}
            >
                {L.clear()}
            </Button>
        </div>
    );
};
