import { L } from 'harmony-language';
import { OrganizationLocation, TripEvent } from '../../../types';
import { toSiteDisplayName } from '../../../utils/data-mapping';
import { pointInZone } from '../../../utils/map-utils';

export const findKnownLocationOrLatLng = (event: TripEvent, locations: OrganizationLocation[]) => {
    const foundLocation = locations.find(x => x.customGeofence ? pointInZone({ latitude: event.latitude, longitude: event.longitude }, x.customGeofence) : false);

    if (foundLocation) {
        return ' [' + L.knownLocation() + ': ' + toSiteDisplayName(foundLocation.name, foundLocation.description) + ']';
    } else {
        return ' - ' + event.latitude + ', ' + event.longitude;
    }
}
