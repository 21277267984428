import React from 'react';
import { styled } from '@mui/material/styles';
import { L } from 'harmony-language';
import { isValidDate, localNow } from '../../utils/date-time-utils';
import { AgisticsCard } from '../shared/containers/agistics-card';
import Button from '@mui/material/Button';
import { DropDownValue, OmniResourceSelect } from '../shared/inputs/omni-resource-select';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import { downloadDailyReport } from '../../utils/download-utils';
import { useUser } from '../../api/queries/use-user';
import { AgisticsDatePicker } from '../shared/inputs/agistics-date-picker';

const DailyReportContainer = styled(`div`)({
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    }
})

interface DashboardReportsProps {
    isDisabled?: boolean,
}

export const DashboardReports: React.FC<DashboardReportsProps> = ({ isDisabled }) => {
    const { user } = useUser();
    const [reportsResource, setReportsResource] = React.useState<DropDownValue | null>(null);
    const [dailyReportSelectedDate, setDailyReportSelectedDate] = React.useState<string>(localNow().toISOString());

    return (
        <AgisticsCard title={L.dailyReports()}
            left={
                <Tooltip
                    placement={'right-end'}
                    title={L.dailyReportsInfo(user.organization?.name || '')} >
                    <Icon fontSize='medium'>info_outlined</Icon>
                </Tooltip >
            }>
            <DailyReportContainer>
                <div>
                    <AgisticsDatePicker
                        disabled={isDisabled}
                        label={L.reportDate()}
                        value={dailyReportSelectedDate}
                        onChange={(date) => setDailyReportSelectedDate(date)}
                    />
                </div>
                <div>
                    <OmniResourceSelect
                        isDisabled={isDisabled}
                        resourceTypes={['driver', 'location', 'loadingTeam', 'siteManager']}
                        value={reportsResource}
                        onChange={(value: DropDownValue | null) => {
                            setReportsResource(value);
                        }} 
                    />
                    <Button
                        style={{ marginLeft: '1rem' }}
                        type='button'
                        title={L.dailyReportGenerate()}
                        variant='contained'
                        disabled={isDisabled || !isValidDate(dailyReportSelectedDate)}
                        onClick={() => {
                            downloadDailyReport(dailyReportSelectedDate, user.organization?.id, reportsResource?.type, reportsResource?.id);
                        }}
                        color='primary'>{L.generate()}</Button>
                </div>
            </DailyReportContainer>
        </AgisticsCard >
    );
};
