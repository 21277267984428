import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { L } from 'harmony-language';
import { ResourceEditCreateContainer } from '../../../shared/containers/resource-edit-create-container';

export const EditCreateServiceAccount = (props) => {
    const { existingServiceAccount, onSubmit } = props;
    const [serviceAccount, setServiceAccount] = React.useState({});

    React.useEffect(() => {
        if (existingServiceAccount) {
            setServiceAccount(existingServiceAccount);
        }
    }, []);

    const submitForm = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        onSubmit(serviceAccount);
    };

    return (
        <ResourceEditCreateContainer width='20vw'>
            <form id='edit-create-service-account-form' name='edit-create-service-account-form' onSubmit={submitForm}>
                <TextField
                    variant='standard'
                    label={L.name()}
                    required={true}
                    value={serviceAccount.name || ''}
                    onChange={(e) => {
                        setServiceAccount({ ...serviceAccount, name: e.target.value })
                    }}
                    style={{
                        marginBottom: '1rem',
                        width: '100%'
                    }} />
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <Button type='submit'
                        title='Save'
                        variant='contained'
                        color='primary'>{existingServiceAccount ? L.updateServiceAccount() : L.createServiceAccount()}</Button>
                </div>
            </form>
        </ResourceEditCreateContainer>
    );
};

EditCreateServiceAccount.propTypes = {
    existingServiceAccount: PropTypes.object,
    onSubmit: PropTypes.func
};
