import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions, OrgQueryKeys } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { invalidateDowntimeCache } from '../../../components/downtime/use-resource-downtimes';
import { Resource } from '../../../types';

export const useEditOrganizationResourceDowntimeMutation = (companyId: number, queryKey: string, resourceName: string, resourceId: number) => {
    const snackbar = useSnackbar();
    const key = OrgQueryKeys.resolveDowntime(companyId, queryKey, resourceId);
    const queryClient = useQueryClient();

    return useMutation(async (resource) => {
        resource.organizationId = companyId;
        return (await axios.put(`${API_BASE}/api${key}/${resource.id}`, resource)).data;
    }, {
        ...mutationOptions([key], queryClient),
        onSuccess: (data) => {
            const resources = queryClient.getQueryData<Resource[]>([key]);
            const updatedResource = resources?.map((existingResource) => {
                if (existingResource.id === data.id) {
                    return {
                        ...data,
                    };
                }
                return existingResource;
            });

            queryClient.setQueryData([key], updatedResource);

            invalidateDowntimeCache(companyId, queryClient);
            snackbar(L.updatedMessage(resourceName));
        }
    });
};
