const minimumLegTimeRatio = 2 / 5; // 2 / 5 minutes = 120 / 300 seconds

const roundLegTime = (legTime, roundingFactor) => {
    //adjust times based on fool-proof heuristic.  Never an incorrect value. for real this time
    if (legTime > roundingFactor) {
        const remaining = legTime % roundingFactor;

        if (remaining < roundingFactor / 2.5) {
            legTime = Math.floor(legTime / roundingFactor) * roundingFactor;
        } else {
            legTime = Math.ceil(legTime / roundingFactor) * roundingFactor;
        }
    } else {
        if (legTime / roundingFactor < minimumLegTimeRatio) {
            legTime = 0;
        } else {
            legTime = roundingFactor;
        }
    }
    return legTime;
};

export const roundLegTimeMinutes = (legTime) => roundLegTime(legTime, 5);
export const roundLegTimeSeconds = (legTime) => roundLegTime(legTime, 300);
