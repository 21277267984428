import React from 'react';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../api/config';
import { ResourceTable } from '../shared/resource-table/resource-table';
import { Content } from '../shared/containers/content';
import { useSiteIssueColumns } from './use-site-issue-columns';
import { L } from 'harmony-language';
import { SiteIssue } from '../../types';
import { ModalDialog } from '../shared/containers/modal-dialog';
import { useBoolean } from '../shared/hooks/use-boolean';
import { EditSiteIssue } from './edit-site-issue';

const SiteIssues: React.FC = () => {
    const {data: siteIssues, isLoading} = useOrganizationQuery<SiteIssue[]>(OrgQueryKeys.siteIssues, { staleTime: 0 });
    const [modalOpen, openModal, closeModal] = useBoolean(false);
    const [issue, setIssue] = React.useState<SiteIssue | null>(null);

    const handleModalOpen = (siteIssue: SiteIssue) => {
        setIssue(siteIssue);
        openModal();
    }

    const {columns, isLoading: isLoadingColumns } = useSiteIssueColumns(handleModalOpen);

    return (
        <Content>
            <ModalDialog open={modalOpen} onClose={closeModal} title={`${L.edit()} ${L.siteIssues()}`}>
                {issue && <EditSiteIssue siteIssue={issue} closeModal={closeModal} />}
            </ModalDialog>
            <ResourceTable
                id={'site-issues'}
                title={L.siteIssues()}
                isLoading={isLoading || isLoadingColumns}
                columns={columns}
                data={siteIssues || []}
            />
        </Content>
    );
};

export default SiteIssues;
