import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const Card = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'center'
}));

const LinkTo = styled(Link)({
    textDecoration: 'none'
});

const CardIcon = styled(Icon)(({ theme }) => ({
    color: theme.palette.secondary.main,
}));

interface DashboardCardProps {
    linkTo: string;
    icon: string;
    text: string;
    isDisabled?: boolean;
}

export const DashboardCard: React.FC<DashboardCardProps> = (props) => {
    const { linkTo, icon, text, isDisabled } = props;

    return (
        <Grid item xs={12} sm={6} md={3} sx={{ padding: '12px' }}>
            <LinkTo to={linkTo}>
                <Card style={{ opacity: isDisabled ? '0.4' : '1' }}>
                    <CardIcon style={{ fontSize: '4em' }}>{icon}</CardIcon>
                    <Typography variant={'h6'}>{text}</Typography>
                </Card>
            </LinkTo>
        </Grid>
    );
};
