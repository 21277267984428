import React from 'react';
import { L } from 'harmony-language';
import { OrgQueryKeys } from '../../../api/config';
import { useAddOrganizationResourceMutation } from '../../../api/mutations/add/use-add-organization-resource-mutation';
import { useEditOrganizationResourceMutation } from '../../../api/mutations/edit/use-edit-organization-resource-mutation';
import { ResourcePage } from '../resource-page';
import { EditCreateContact } from './edit-create-contact';
import { Contact, NOTIFICATION_TYPES } from '../../../types';
import { ResourceEditCreateContainer } from '../../shared/containers/resource-edit-create-container';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { formatPhone } from '../../../utils/string-utils';
import { UserNotificationPreferences } from '../../user/user-notification-preferences';
import { useBoolean } from '../../shared/hooks/use-boolean';
import { NotificationType } from 'harmony-constants';
import { useCompanyId } from '../../../app/agistics-context';
import { useContactColumns } from './use-contact-columns';

export const ContactManagement = () => {
    const { data: contacts, isLoading } = useOrganizationQuery<Contact[]>(OrgQueryKeys.contacts);
    const { mutateAsync: addContact, isLoading: isCreating } = useAddOrganizationResourceMutation(OrgQueryKeys.contacts, L.contact());
    const { mutate: editContact, isLoading: isUpdating } = useEditOrganizationResourceMutation(OrgQueryKeys.contacts, L.contact());
    const [preferencesOpen, openPreferences, closePreferences] = useBoolean(false);
    const [newContactId, setNewContactId] = React.useState<number>(-1);
    const companyId = useCompanyId();
    const columns = useContactColumns();

    const filteredContacts = React.useMemo(() => {
        //get rid of interactive users, and contacts that only have Webhook notifications,
        //but keep contacts that don't have any contactNotificationMethods yet.
        return contacts?.filter(x => !x.interactiveUser)
                        .filter(x => !x.contactNotificationMethods.length || !x.contactNotificationMethods.every(x =>
                                x.notificationType === NotificationType.Webhook)
                        );
    }, [contacts]);

    return (
        <ResourcePage
            resourceType='contact'
            isLoading={isLoading || isCreating || isUpdating}
            titleFunc={contact => contact.name}
            data={filteredContacts}
            columns={columns}
            EditCreateComponent={(editCreateProps: any) => {
                return (
                    <ResourceEditCreateContainer width='20vw'>
                        {preferencesOpen && <UserNotificationPreferences contactId={newContactId} organizationId={companyId} closePreferences={() => {closePreferences(); editCreateProps.closeModal();}} />}
                        <EditCreateContact
                            contact={editCreateProps.resource}
                            onSubmit={async (newData) => {
                                const existingPhone = newData.contactNotificationMethods.find(x => x.notificationType === NOTIFICATION_TYPES.Text);
                                if (existingPhone) {
                                    const index = newData.contactNotificationMethods.indexOf(existingPhone);
                                    existingPhone.destination = formatPhone(existingPhone.destination) || '';
                                    newData.contactNotificationMethods[index] = existingPhone;
                                }

                                if (newData.id) {
                                    editContact(newData);
                                    editCreateProps.closeModal();
                                } else {
                                    const contact = await addContact(newData);
                                    setNewContactId(contact.id);
                                    openPreferences();
                                }
                            }}
                        />
                    </ResourceEditCreateContainer>
                );
            }} />
    );
}
