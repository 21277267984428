import React from 'react';
import { NicknameInput } from './nickname-input';
import { SelectLocationType } from '../../../shared/inputs/select-location-type';
import { PremiseIdInput } from './premise-id-input';
import { SiteInstructionsInput } from './site-instructions-input';
import { LoadingTeamDropdown } from './loading-team-dropdown';
import Grid from '@mui/material/Grid';
import Skeleton from "@mui/material/Skeleton";
import { SITE_CATEGORIES } from '../../../../constants/constants';
import { EditLocationSubSites } from './edit-location-sub-sites';
import { Button, Checkbox } from '@mui/material';
import { L } from 'harmony-language';
import { AddressItemInput } from './address-item-input';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { CustomerDropdown } from './customer-dropdown';
import { DescriptionInput } from './description-input';
import EditLocationImageModal from './edit-location-image-modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ThroughputInput } from './throughput-input';
import { AgisticsMultiSelect } from '../../../shared/multi-select/agistics-multi-select';
import { CheckboxEnabled } from '../../../shared/inputs/checkbox-enabled';
import { hereMapGeocode } from '../../../../api/here-utils';
import { Customer, Image, LoadingTeam, OrganizationLocation, Point, Throughput, User } from '../../../../types';
import { HereMapRefObject } from '../../../here-maps/here-map';
import { EitherOrganizationLocation } from './edit-create-location';
import { pointToBbox } from 'harmony-constants';

interface EditCreateLocationFormProps {
    mapRef: React.RefObject<HereMapRefObject>;
    isLoading: boolean;
    setHelperMarker: (p: Point | null) => void;
    organizationLocation: EitherOrganizationLocation;
    setOrganizationLocation: (e: EitherOrganizationLocation) => void;
    siteManagers: User[];
    loadingTeams: LoadingTeam[];
    customers: Customer[];
    images: Image[];
    throughputs: Throughput[];
    setThroughputs: (throughputs: Throughput[]) => void;
    onDroppedFiles: (droppedFiles: File[]) => Promise<void>;
    onDelete?: (image: Image) => void;
}

export const EditCreateLocationForm: React.FC<EditCreateLocationFormProps> = props => {
    const {
        mapRef, isLoading, setHelperMarker, organizationLocation, setOrganizationLocation,
        images, throughputs, setThroughputs, onDroppedFiles, onDelete, siteManagers, loadingTeams, customers
    } = props;

    const { onClickGeoLoc, q } = useHereGeoLocation(organizationLocation, setOrganizationLocation, setHelperMarker, mapRef.current?.centerMap);

    if (isLoading) {
        return (
            <Grid container spacing={3} className='location-container' data-testid='loading-form'>
                {[1, 2, 3, 4, 5, 6].map((x) =>
                    <Grid key={x} item xs={6} sm={12} lg={6}>
                        <Skeleton height='100%' />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Skeleton height='100%' />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton height='100%' />
                </Grid>
            </Grid>
        );
    }

    const showSiteInputs = organizationLocation.orgLocationType?.category === SITE_CATEGORIES.SITE;

    return (
        <Grid container spacing={1} className='location-container'>
            <Grid item xs={7} sm={7} lg={7}>
                <SelectLocationType
                    locationType={organizationLocation.orgLocationType}
                    onChange={(newLocationType) => {
                        setOrganizationLocation({
                            ...organizationLocation,
                            orgLocationType: newLocationType,
                            organizationLocationTypeId: newLocationType.id
                        });
                    }}
                    fullWidth={true}
                    required={true}
                />
            </Grid>
            <Grid item xs={6} sm={12} lg={6}>
                <NicknameInput organizationLocation={organizationLocation} setOrganizationLocation={setOrganizationLocation} />
            </Grid>
            <Grid item xs={6} sm={12} lg={6}>
                <DescriptionInput organizationLocation={organizationLocation} setOrganizationLocation={setOrganizationLocation} />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <AddressItemInput
                    addressItem={'address1'}
                    label={L.addressLine1()}
                    organizationLocation={organizationLocation}
                    setOrganizationLocation={setOrganizationLocation}
                />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
                <AddressItemInput
                    addressItem={'address2'}
                    label={L.addressLine2()}
                    optional={true}
                    organizationLocation={organizationLocation}
                    setOrganizationLocation={setOrganizationLocation}
                />
            </Grid>
            <Grid item xs={12} sm={12} lg={4}>
                <AddressItemInput
                    addressItem={'city'}
                    label={L.city()}
                    organizationLocation={organizationLocation}
                    setOrganizationLocation={setOrganizationLocation}
                />
            </Grid>
            <Grid item xs={12} sm={12} lg={3}>
                <AddressItemInput
                    addressItem={'state'}
                    label={L.state()}
                    organizationLocation={organizationLocation}
                    setOrganizationLocation={setOrganizationLocation}
                />
            </Grid>
            <Grid item xs={10} sm={10} lg={3}>
                <AddressItemInput
                    addressItem={'postalCode'}
                    label={L.postalCode()}
                    organizationLocation={organizationLocation}
                    setOrganizationLocation={setOrganizationLocation}
                />
            </Grid>
            <Grid item xs={2} sm={2} lg={2} style={{display: 'flex', alignItems: 'center'}}>
                <Button
                    disabled={!organizationLocation || !q}
                    size='small'
                    onClick={onClickGeoLoc}
                >
                    <MyLocationIcon />
                </Button>
            </Grid>
            {showSiteInputs &&
                <>
                    <Grid item xs={6} sm={12} lg={6}>
                        <PremiseIdInput
                            organizationLocation={organizationLocation}
                            setOrganizationLocation={setOrganizationLocation}
                        />
                    </Grid>
                    <Grid item xs={6} sm={12} lg={6}>
                        <LoadingTeamDropdown
                            organizationLocation={organizationLocation}
                            setOrganizationLocation={setOrganizationLocation}
                            loadingTeams={loadingTeams}
                        />
                    </Grid>
                    <Grid item xs={6} sm={12} lg={6}>
                        <CustomerDropdown
                            organizationLocation={organizationLocation}
                            setOrganizationLocation={setOrganizationLocation}
                            customers={customers}
                        />
                    </Grid>
                    <Grid item xs={6} sm={12} lg={6}>
                        <AgisticsMultiSelect
                            selectedIds={organizationLocation.users?.map(locUser => locUser.id) || []}
                            setSelectedIds={(value: number[]) => {
                                setOrganizationLocation({
                                    ...organizationLocation,
                                    // @ts-ignore
                                    users: value.map(id => siteManagers.find(siteManager => id === siteManager.id)),
                                });
                            }}
                            items={siteManagers.map(x => ({ id: x.id, value: x.name }))}
                            label={L.siteManagerEntry()}
                        />
                    </Grid>
                    <Grid item xs={6} sm={12} lg={6} style={{ verticalAlign: 'baseline' }}>
                        <FormControlLabel
                            style={{ height: '100%' }}
                            control={<Checkbox
                                checked={Boolean(organizationLocation.hasScale)}
                                id='hasScale'
                                onChange={() => {
                                    setOrganizationLocation({
                                        ...organizationLocation,
                                        hasScale: !organizationLocation.hasScale,
                                    });
                                }}
                            />}
                            label={L.hasWeighScale()}
                        />
                    </Grid>
                </>
            }
            <Grid item xs={6} sm={12} lg={6}>
                <EditLocationImageModal images={images} onDelete={onDelete} onDroppedFiles={onDroppedFiles} />
            </Grid>
            <Grid item xs={6} sm={12} lg={6}>
                <SiteInstructionsInput organizationLocation={organizationLocation} setOrganizationLocation={setOrganizationLocation} />
            </Grid>
            <Grid item xs={6} sm={12} lg={6}>
                <ThroughputInput throughputs={throughputs} organizationLocation={organizationLocation} setThroughputs={setThroughputs} />
            </Grid>
            {
                showSiteInputs &&
                <Grid item xs={12}>
                    <EditLocationSubSites
                        onChange={(subSites) => setOrganizationLocation({ ...organizationLocation, orgSubLocations: subSites } as OrganizationLocation)}
                        subSites={organizationLocation.orgSubLocations} />
                </Grid>
            }
            <Grid>
                <CheckboxEnabled
                    checked={organizationLocation.enabled}
                    onChange={() => setOrganizationLocation({ ...organizationLocation, enabled: !organizationLocation.enabled })}
                />
            </Grid>
        </Grid>
    );
};

const useHereGeoLocation = (
    organizationLocation: EitherOrganizationLocation,
    setOrganizationLocation: (e: EitherOrganizationLocation) => void,
    setHelperMarker: (p: Point | null) => void,
    centerMap?: (latitude: number, longitude: number, zoomLevel: number) => void,
) => {
    const q = React.useMemo(() => {
        return [
            organizationLocation.address1,
            organizationLocation.address2,
            organizationLocation.city,
            organizationLocation.state,
            organizationLocation.postalCode,
        ].filter(Boolean).join(' ');
    }, [organizationLocation.address1,
        organizationLocation.address2,
        organizationLocation.city,
        organizationLocation.state,
        organizationLocation.postalCode,
    ]);

    const onClickGeoLoc = React.useCallback(async () => {
        try {
            if (!q) {
                return
            }
            const geocode = await hereMapGeocode({q});
            if (geocode?.position) {
                const point = {
                    latitude: geocode.position.lat,
                    longitude: geocode.position.lng,
                };
                setOrganizationLocation({
                    ...organizationLocation,
                    customGeofence: {
                        ...organizationLocation.customGeofence,
                        ...point,
                        bbox: pointToBbox(point),
                    }
                });
                setHelperMarker(point);
                centerMap && centerMap(point.latitude, point.longitude, 14);
            }
        } catch(e) {
            //ignore errors if we don't have a valid geocode response
            console.log(e);
        }
    }, [q, organizationLocation]);

    return { onClickGeoLoc, q }
}
