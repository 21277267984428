import { createGeoRectangle, createGeoRectangleFromPoints, isPolyline, isRectangle } from './here-map-wrapper';

export function onPointerMove(event, map, rectangleOutline, rectangleGroup) {
    const objectTopLeftScreen = map.geoToScreen(event.target.getGeometry().getBoundingBox().getTopLeft());
    const objectBottomRightScreen = map.geoToScreen(event.target.getGeometry().getBoundingBox().getBottomRight());
    let draggingType = '';

    if (event.target !== rectangleOutline) {
        return;
    }

    if (event.currentPointer.viewportX < (objectTopLeftScreen.x + 4)) {
        document.body.style.cursor = 'ew-resize';
        draggingType = 'left';
    } else if (event.currentPointer.viewportX > (objectBottomRightScreen.x - 4)) {
        document.body.style.cursor = 'ew-resize';
        draggingType = 'right';
    } else if (event.currentPointer.viewportY < (objectTopLeftScreen.y + 4)) {
        document.body.style.cursor = 'ns-resize';
        draggingType = 'top';
    } else if (event.currentPointer.viewportY > (objectBottomRightScreen.y - 4)) {
        document.body.style.cursor = 'ns-resize';
        draggingType = 'bottom';
    } else {
        document.body.style.cursor = 'default';
    }

    if (draggingType === 'left') {
        if (event.currentPointer.viewportY < (objectTopLeftScreen.y + 4)) {
            document.body.style.cursor = 'nwse-resize';
            draggingType = 'left-top';
        } else if (event.currentPointer.viewportY > (objectBottomRightScreen.y - 4)) {
            document.body.style.cursor = 'nesw-resize';
            draggingType = 'left-bottom';
        }
    } else if (draggingType === 'right') {
        if (event.currentPointer.viewportY < (objectTopLeftScreen.y + 4)) {
            document.body.style.cursor = 'nesw-resize';
            draggingType = 'right-top';
        } else if (event.currentPointer.viewportY > (objectBottomRightScreen.y - 4)) {
            document.body.style.cursor = 'nwse-resize';
            draggingType = 'right-bottom';
        }
    }

    rectangleGroup.setData({ 'draggingType': draggingType });
}

export function onPointerEnter(rectangleOutline, timeout) {
    const currentStyle = rectangleOutline.getStyle();
    const newStyle = currentStyle.getCopy({
        strokeColor: 'rgb(10, 10, 10)'
    });

    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }

    rectangleOutline.setStyle(newStyle);

    return timeout;
}

export function onPointerLeave(event, rectangleOutline, timeout) {
    const currentStyle = rectangleOutline.getStyle();
    const newStyle = currentStyle.getCopy({
        strokeColor: 'rgba(0, 0, 0, 0)'
    });
    const newTimeout = (event.currentPointer.type === 'touch') ? 1000 : 0;

    timeout = setTimeout(() => {
        rectangleOutline.setStyle(newStyle);
    }, newTimeout);

    document.body.style.cursor = 'default';

    return timeout;
}


export function onDrag(event, map, rectangleGroup, rectangle, rectangleOutline) {

    event.stopPropagation();

    const currentGeoPoint = map.screenToGeo(event.currentPointer.viewportX, event.currentPointer.viewportY);
    const currentGeoRect = rectangle.getGeometry().getBoundingBox();
    let newGeoRect = null;

    if (isPolyline(event.target)) {
        // outline being dragged; resize
        const draggingType = rectangleGroup.getData()['draggingType'];

        newGeoRect = calculateResizedBoundingBox(currentGeoPoint, currentGeoRect, draggingType);
    } else if (isRectangle(event.target)) {
        // shape being dragged; move
        const startCoordinates = event.target.getData()['startCoord'];

        if (!currentGeoPoint.equals(startCoordinates)) {
            newGeoRect = calculateMovedBoundingBox(currentGeoPoint, currentGeoRect, startCoordinates);
            event.target.setData({
                startCoord: currentGeoPoint
            });
        }
    }

    if (!newGeoRect) {
        return;
    }

    rectangle.setBoundingBox(newGeoRect);
    const outlineLinestring = rectangle.getGeometry().getExterior();

    outlineLinestring.pushPoint(outlineLinestring.extractPoint(0));
    rectangleOutline.setGeometry(outlineLinestring);

}


function calculateResizedBoundingBox(currentGeoPoint, currentGeoRect, draggingType) {
    const currentTopLeft = currentGeoRect.getTopLeft();
    const currentBottomRight = currentGeoRect.getBottomRight();
    let newGeoRect;

    switch (draggingType) {
    case 'left-top':
        if (currentGeoPoint.lng >= currentBottomRight.lng || currentGeoPoint.lat <= currentBottomRight.lat) {
            return;
        }
        newGeoRect = createGeoRectangleFromPoints(currentGeoPoint, currentGeoRect.getBottomRight());
        break;
    case 'left-bottom':
        if (currentGeoPoint.lng >= currentBottomRight.lng || currentGeoPoint.lat >= currentTopLeft.lat) {
            return;
        }
        currentTopLeft.lng = currentGeoPoint.lng;
        currentBottomRight.lat = currentGeoPoint.lat;
        newGeoRect = createGeoRectangleFromPoints(currentTopLeft, currentBottomRight);
        break;
    case 'right-top':
        if (currentGeoPoint.lng <= currentTopLeft.lng || currentGeoPoint.lat <= currentBottomRight.lat) {
            return;
        }
        currentTopLeft.lat = currentGeoPoint.lat;
        currentBottomRight.lng = currentGeoPoint.lng;
        newGeoRect = createGeoRectangleFromPoints(currentTopLeft, currentBottomRight);
        break;
    case 'right-bottom':
        if (currentGeoPoint.lng <= currentTopLeft.lng || currentGeoPoint.lat >= currentTopLeft.lat) {
            return;
        }
        newGeoRect = createGeoRectangleFromPoints(currentGeoRect.getTopLeft(), currentGeoPoint);
        break;
    case 'left':
        if (currentGeoPoint.lng >= currentBottomRight.lng) {
            return;
        }
        currentTopLeft.lng = currentGeoPoint.lng;
        newGeoRect = createGeoRectangleFromPoints(currentTopLeft, currentGeoRect.getBottomRight());
        break;
    case 'right':
        if (currentGeoPoint.lng <= currentTopLeft.lng) {
            return;
        }
        currentBottomRight.lng = currentGeoPoint.lng;
        newGeoRect = createGeoRectangleFromPoints(currentGeoRect.getTopLeft(), currentBottomRight);
        break;
    case 'top':
        if (currentGeoPoint.lat <= currentBottomRight.lat) {
            return;
        }
        currentTopLeft.lat = currentGeoPoint.lat;
        newGeoRect = createGeoRectangleFromPoints(currentTopLeft, currentGeoRect.getBottomRight());
        break;
    case 'bottom':
        if (currentGeoPoint.lat >= currentTopLeft.lat) {
            return;
        }
        currentBottomRight.lat = currentGeoPoint.lat;
        newGeoRect = createGeoRectangleFromPoints(currentGeoRect.getTopLeft(), currentBottomRight);
        break;
    default:
        break;
    }

    return newGeoRect;
}

function calculateMovedBoundingBox(currentGeoPoint, currentGeoRect, startCoordinates) {
    const newTop = currentGeoRect.getTop() + currentGeoPoint.lat - startCoordinates.lat;
    const newBottom = currentGeoRect.getBottom() + currentGeoPoint.lat - startCoordinates.lat;

    if (newTop >= 90 || newBottom <= -90) {
        return;
    }

    const newLeft = currentGeoRect.getLeft() + currentGeoPoint.lng - startCoordinates.lng;
    const newRight = currentGeoRect.getRight() + currentGeoPoint.lng - startCoordinates.lng;

    const newGeoRect = createGeoRectangle(newTop, newLeft, newBottom, newRight);

    return newGeoRect;
}
