import React from 'react';
import { L } from 'harmony-language';
import { OrganizationLocation, OrganizationLocationWithCustomGeofence } from '../../../types';
import { Autocomplete } from "@mui/material";
import { TextField } from '@mui/material';
import { toSiteDisplayName } from '../../../utils/data-mapping';

interface SiteLocatorProps {
    sites: OrganizationLocation[];
    onChange: (orgLoc: OrganizationLocationWithCustomGeofence | null) => void;
}

export const SiteLocator: React.FC<SiteLocatorProps> = (props) => {
    const { sites, onChange } = props;

    const filteredSites = React.useMemo(() => {
        return sites.filter((x): x is OrganizationLocationWithCustomGeofence => !!x.customGeofence && !!x.customGeofence.bbox && x.enabled);
    }, [sites]);

    return (
        <Autocomplete
            style={{ width: '400px', display: 'inline-flex' }}
            options={filteredSites}
            noOptionsText={L.noOptions()}
            getOptionLabel={(option) => toSiteDisplayName(option.name, option.description)}
            onChange={(event: any, newValue) => {
                onChange(newValue ? newValue : null);
            }}
            renderInput={(params) => <TextField variant='standard' {...params} label={L.siteLocator()} />}
        />
    );
}
