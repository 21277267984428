import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { L } from 'harmony-language';
import Tooltip from '@mui/material/Tooltip';
import { Select } from '../../../shared/inputs/select';
import { OrgQueryKeys } from '../../../../api/config';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { LoadingTeamMember } from '../../../../constants/constants';
import Skeleton from "@mui/material/Skeleton";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { filterByRole } from '../../../user/utils/user-mappings';
import Divider from '@mui/material/Divider';

const renderLoadingTeamUserRow = (user, index, availableLoadingTeamUsers, handleChange, handleDelete) => {
    const mapToSelectItem = (user) => ({ ...user, label: user.firstName + ' ' + user.lastName });

    if (user.id) {
        availableLoadingTeamUsers.push(user);
    }
    const availableLoadingTeamUserItems = availableLoadingTeamUsers.map(mapToSelectItem);
    const currentLoadingTeamUser = availableLoadingTeamUserItems.find(x => x.id === user.id);

    return (
        <React.Fragment key={user.domKey || user.id}>
            <ListItem dense style={{ alignItems: 'flex-end' }}>
                <Select
                    label={L.user()}
                    list={availableLoadingTeamUserItems}
                    item={currentLoadingTeamUser}
                    inputProps={{ disableUnderline: true }}
                    onChange={(value) => {
                        handleChange(value, index);
                    }}/>
                <Tooltip title={L.delete()}>
                    <IconButton onClick={() => handleDelete(index)} size='small'>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </ListItem>
            <Divider/>
        </React.Fragment>
    );
};

const renderAddLoadingTeamUser = (handleAdd, disabled) => {
    if (disabled) {
        return null;
    }

    return (
        <ListItem dense>
            <ListItemText primary={L.loadingTeamUserAddPrompt()}/>
            <Tooltip title={L.add()}>
                <IconButton onClick={handleAdd} size='small'>
                    <AddIcon/>
                </IconButton>
            </Tooltip>
        </ListItem>
    );
};

export const EditLoadingTeamUsers = props => {
    const { users = [], onChange } = props;
    const { data: allAvailableUsers, isLoading } = useOrganizationQuery(OrgQueryKeys.users);
    const availableLoadingTeamUsers = filterByRole(allAvailableUsers, LoadingTeamMember);

    const handleChange = (value, index) => {
        const changed = [...users];
        changed[index] = value;
        onChange(changed);
    };

    const handleDelete = (index) => {
        onChange(users.filter((_, i) => i !== index));
    };

    const handleAdd = () => {
        onChange(users.concat({ domKey: Math.random() }));
    };

    const filteredAvailableLoadingTeamUsers = availableLoadingTeamUsers?.filter(
        availableLoadingTeamUser => !users?.some(user => user.id === availableLoadingTeamUser.id)
    );

    const toSkeletonItem = (x) => (
        <ListItem dense key={x}>
            <Skeleton width={'100%'}/>
        </ListItem>
    );
    const toRowItem = (loadingTeamUser, i) => {
        return renderLoadingTeamUserRow(loadingTeamUser, i, [...filteredAvailableLoadingTeamUsers], handleChange, handleDelete);
    };

    return (
        <>
            <Typography variant='h6'>{L.loadingTeamMembers()}</Typography>
            <List>
                {isLoading ? Array.from(Array(3), (x, i) => i).map(toSkeletonItem) : users.map(toRowItem)}
                {!isLoading && renderAddLoadingTeamUser(handleAdd, Boolean((users.length >= availableLoadingTeamUsers.length)))}
            </List>
        </>
    );
};

EditLoadingTeamUsers.propTypes = {
    users: PropTypes.array,
    onChange: PropTypes.func
};

