import { Load, Stop, SalmonellaResult } from  '../../../types';
import { isCargoStop, ORDER_STATUSES, SalmonellaRanks } from '../../../constants/constants';
import { withThroughputs } from '../../../utils/throughput-utils';
import { filterStopByDestination, filterStopByOrigin } from '../../../utils/data-mapping-utils';

type MergedStop = Omit<Stop, "id" | "orderId"> & {"originalStopId"?: number}

const selectCommonIdentifier = (key: string) => (acc: any, cur: { [x: string]: any; }) => {
    // returns common value when only one is present or all are the same
    // returns null if there are different values
    if (acc) {
        if (!cur[key] || cur[key] === acc) {
            return acc;
        } else {
            return null;
        }
    } else {
        return cur[key];
    }
};

const getStopKey = (stop: Stop): string => {
    const sortedSublocations = stop.organizationSubLocationIds ? stop.organizationSubLocationIds.toSorted() : null;
    let key = `${stop.type}|${stop.organizationLocationId}|${sortedSublocations}`;

    if (isCargoStop(stop)) {
        key += `${stop.cargoTypeId}|${stop.lotId}|${stop.productionPlan}|${stop.orderNumber}`;
    }
    return key;
};

//all stops between a start and end index
const getStopsBetween = (stops: Stop[], start: number, end: number) => {
    return stops.slice(start, end);
};

const mergeStops = (stops: Stop[]) => {
    const keepingTrackOfMergedStops: number[] = [];

    const mergedStops = stops.map(stop => {
        if (!keepingTrackOfMergedStops.find(stopId => stopId === stop.id)) {
            const newStop: MergedStop = {
                arrivalTime: null, // arrival time calculations will occur later
                cargoTypeId: stop.cargoTypeId,
                orderNumber: stop.orderNumber,
                compartments: stop.compartments,
                type: stop.type,
                sequence: stop.sequence, //we fix this later
                organizationLocationId: stop.organizationLocationId,
                organizationSubLocationIds: stop.organizationSubLocationIds,
                lotId: stop.lotId,
                productionPlan: stop.productionPlan,
                quantity: stop.quantity,
                actualQuantity: stop.actualQuantity,
                notes: stop.notes,
                originalStopId: stop.id,
                status: 'Not Started',
                customerOrderNumber: stop.customerOrderNumber,
                requiredBegin: stop.requiredBegin,
                requiredEnd: stop.requiredEnd,
                readyTime: stop.readyTime,
                weight: null,
                actualWeight: null,
                metadata: stop.metadata,
                timeSeconds: null,
                distanceMeters: null,
            };

            const stopKey = getStopKey(stop);
            const matchingStops = stops.filter(x => getStopKey(x) === stopKey);

            if (matchingStops.length > 0 && !isCargoStop(stop)) {
                //add matching stops of this one to array so we ignore it later, just keeping the 1st one.
                keepingTrackOfMergedStops.push(...stops.filter(x => getStopKey(x) === stopKey).map(x => x.id));
            } else {
                newStop.quantity = matchingStops.reduce((prev, curr) => prev + curr.quantity, 0);
                newStop.actualQuantity = matchingStops.reduce((prev, curr) => prev + (curr.actualQuantity || 0), 0);

                newStop.weight = matchingStops.reduce((prev, curr) => prev + (curr.weight ?? 0), 0);
                newStop.actualWeight = matchingStops.reduce((prev, curr) => prev + (curr.actualWeight ?? 0), 0);

                newStop.loadingTeamId = [stop, ...matchingStops].reduce(selectCommonIdentifier('loadingTeamId'), null);

                //add stops that are merged into this new stop so we don't merge them again
                keepingTrackOfMergedStops.push(...stops.filter(x => getStopKey(x) === stopKey).map(x => x.id));
            }

            if (matchingStops.some(s => s.notes)) {
                newStop.notes = matchingStops.filter(s => s.notes).reduce((prev, curr) => prev + curr.notes + '\n', '');
            }

            if (matchingStops.every(s => s.cargoPerCompartment === stop.cargoPerCompartment)) {
                newStop.cargoPerCompartment = stop.cargoPerCompartment;
            }

            //temporary solution per Jeff, in the future we'll want to look at how
            //to combine stop-level metadata values correctly
            //instead of blowing them away.
            //e.g.: const MasonVergerTheJsonMerger = (maSON, maSON) => { console.log('Cordell!'); }
            if (matchingStops.length > 1) {
                newStop.metadata = null;
                //originalStopId is used on the API side to populate properties
                //from unmerged stops that aren't brought down to the web UI
                //(e.g. deliverTicket), so they can be preserved on the server side
                newStop.originalStopId = undefined;
            }

            return newStop;
        }
    });

    return mergedStops.filter(item => item) as MergedStop[];
};

//rules - think of a merge having 5 parts:
//  1. Stops before first CARGO stop (could be either 1st origin or destination)
//  2. 1st CARGO stop
//  3. everything between the FIRST and LAST cargo events
//  4. LAST CARGO stop
//  5. stops after LAST cargo stop

//Notes about ordering
//Now that we are allowing merging of more then 2 drafts there is really no good way to decide what should come '1st' outside the rules of the 5 part merge.
//example: exact same pick up at start and same drop off at end, draft1 has a waypoint between the 2 cargo stops and draft 2 has a weigh between the two cargo stops.
//         the order will be decided based on when they are in the array, which is decided when they select the checkboxes before merge.
const fivePartMerge = (loads: Load[]) => {
    const partOneStops: Stop[] = [];
    const partTwoStops: Stop[] = [];
    const partThreeStops: Stop[] = [];
    const partFourStops: Stop[] = [];
    const partFiveStops: Stop[] = [];
    //get the first cargoStops arrival time from the base (first selected) load
    const earliestCargoArrivalTime: string | null = loads[0].stops.find(isCargoStop)?.arrivalTime || null;

    loads.map(load => {
        const stops = load.stops;
        let indexOfFirstCargoStop, indexOfLastCargoStop;

        //part 1 - get everything before 1st cargo stop
        const firstCargoStop = stops.find(x => isCargoStop(x));
        if (firstCargoStop) {
            indexOfFirstCargoStop = stops.indexOf(firstCargoStop);
            partOneStops.push(...getStopsBetween(stops, 0, indexOfFirstCargoStop));

            //part 2 - should be all matching stops on this one load, so we can merge them and keep their place in the new merged load.
            const stopKey = getStopKey(firstCargoStop);
            const firstCargoMatchingStops = stops.filter(x => getStopKey(x) === stopKey);
            partTwoStops.push(...firstCargoMatchingStops);
        } else {
            //I think if no cargo stops, just treat everything as part1?
            partOneStops.push(...stops);
        }

        const numberOfCargoStops = stops.filter(x => isCargoStop(x)).length;
        if (numberOfCargoStops > 1 && firstCargoStop) {
            //not supported in es6 or something?
            //const lastCargoStop = stops.findLast(x => isCargoStop(x));
            //part 5 - everything after last cargo stop
            const lastCargoStop = [...stops].reverse().find(x => isCargoStop(x));
            if (lastCargoStop) {
                indexOfLastCargoStop = stops.indexOf(lastCargoStop);
                partFiveStops.push(...getStopsBetween(stops, indexOfLastCargoStop + 1, stops.length));

                //part 3 - middle stops
                if (indexOfFirstCargoStop || indexOfFirstCargoStop === 0) {
                    const middleStops = getStopsBetween(stops, indexOfFirstCargoStop + 1, indexOfLastCargoStop);
                    partThreeStops.push(...middleStops);
                }
                //part 4 - should be all matching stops on this one load, so we can merge them and keep there place in the new merged load
                const stopKey = getStopKey(lastCargoStop);
                const firstCargoStopKey = getStopKey(firstCargoStop);
                //do not want to merge last cargo stop if it is exactly the same as the 1st cargo stop.
                if (firstCargoStopKey !== stopKey) {
                    const lastCargoMatchingStops = stops.filter(x => getStopKey(x) === stopKey);
                    partFourStops.push(...lastCargoMatchingStops);
                }
            }
        } else if (numberOfCargoStops === 1) {
            //only 1 cargo stop.  Going to treat this as 3 parts merge, parts 1,2,3
            //parts 1 and 2 are handeled already, just need to handel everything AFTER the cargo stop, considerering anything is even after the last stop.
            //part 3 - rest of stops after 1 cargo stop
            if (firstCargoStop) {
                indexOfFirstCargoStop = stops.indexOf(firstCargoStop);
                const restOfStops = getStopsBetween(stops, indexOfFirstCargoStop + 1, stops.length);
                partThreeStops.push(...restOfStops);
            }
        } else {
            //0 cargo stops, handeled above
        }
    });

    //at this point, partOne and partFive will only be non-cargo stops. partThree can be assortment of all stop types.
    //we want to merge partThree non-cargo stops together, we will worry about cargo stops later.
    const partThreeNonCargoStops = partThreeStops.filter(x => !isCargoStop(x));
    const mergedGroupOneStops = mergeStops(partOneStops);
    const mergedGroupThreeNonCargoStops = mergeStops(partThreeNonCargoStops);
    const mergedGroupFiveStops = mergeStops(partFiveStops);

    const mergedGroupTwoStops = mergeStops(partTwoStops);
    //set the first loads cargo arrival time to the base load, so the rest can be calculated
    if (mergedGroupTwoStops[0]) {
        mergedGroupTwoStops[0].arrivalTime = earliestCargoArrivalTime;
    }
    const mergedGroupFourStops = mergeStops(partFourStops);

    //group 3 cargo stops.  Need to get the stops that don't have key of group 2 or group 4.
    const keysInGroupTwo: string[] = [];
    const keysInGroupFour: string[] = [];
    keysInGroupTwo.push(...partTwoStops.map(x => getStopKey(x)));
    keysInGroupFour.push(...partFourStops.map(x => getStopKey(x)));

    const partThreeCargoStops = partThreeStops.filter(x => isCargoStop(x));
    const readyToMergePartThreeCargoStops = partThreeCargoStops.filter(cargoStop => {
        const cargoStopKey = getStopKey(cargoStop);
        const existInGroupTwo = keysInGroupTwo.find(key => key === cargoStopKey);
        const existInGroupFour = keysInGroupFour.find(key => key === cargoStopKey);
        if (!existInGroupTwo && !existInGroupFour) {
            return cargoStop;
        }
    });
    const mergedGroupThreeCargoStops = mergeStops(readyToMergePartThreeCargoStops);

    const finalGroupedStops = mergedGroupOneStops.concat(mergedGroupTwoStops)
        .concat(mergedGroupThreeNonCargoStops)
        .concat(mergedGroupThreeCargoStops)
        .concat(mergedGroupFourStops)
        .concat(mergedGroupFiveStops);

    return finalGroupedStops;
};

export const mergeDrafts = (drafts: Load[], throughputs: any): Load => {
    const mergedStops = fivePartMerge(drafts);
    const stopsWithKey = mergedStops.map((x, i) => ({ ...x, domKey: Math.random(), sequence: i + 1 })); // domKey for react-dnd
    const assignedDriver = drafts.reduce(selectCommonIdentifier('transportedByUserId'), null);

    // getting this type to work properly ended up causing problems in load-context.tsx - orderId: load.id
    // we set orderId to undefined there, even tho the type does not support it, so it must be getting fixed somewhere else?
    // @ts-ignore
    return {
        // id: null,
        organizationId: drafts[0].organizationId,
        date: drafts[0]?.date,
        status: assignedDriver ? ORDER_STATUSES().Assigned.key : ORDER_STATUSES().Open.key,
        isDraft: true,
        washOption: drafts.reduce(selectCommonIdentifier('washOption'), null),
        transportedByUserId: assignedDriver,
        trailerId: drafts.reduce(selectCommonIdentifier('trailerId'), null),
        tractorId: drafts.reduce(selectCommonIdentifier('tractorId'), null),
        stops: stopsWithKey.map(withThroughputs(throughputs)),
        metadata: mergeMetadata(drafts),
        mergedFromOrderIds: drafts.map(x => x.id),
    };
};

const stopQuantitySums = (load: Load, filterFn: (s: Stop) => boolean) => {
    return load?.stops?.filter(filterFn).reduce((pre, cur) => pre + cur.quantity, 0) || 0;
};

const isSalmonellaResult = (item: string | undefined): item is SalmonellaResult => {
    return !!item;
}

//properties that are not averageWeight, salmonellaResult, or showTrailerBoxes are dropped when merging
export const mergeMetadata = (drafts: Load[]) => {
    const allMetadata = drafts.map(d => d.metadata);

    // Salmonella Merging
    const salmonellaResults = allMetadata.map(x => x?.cargo?.salmonellaResult)
        .filter(isSalmonellaResult).map(r => SalmonellaRanks.indexOf(r));
    const mergedSalmonella = salmonellaResults.length ? SalmonellaRanks[Math.max(...salmonellaResults)] : null;

    // Average weight merging
    let countableQuantity = 0;
    let totalWeight = 0;
    drafts.map(draft => {
        const draftAverageWeight = draft.metadata?.cargo?.averageWeight;
        if (draftAverageWeight) {
            const draftQuantity = Math.max(stopQuantitySums(draft, filterStopByOrigin), stopQuantitySums(draft, filterStopByDestination));

            totalWeight = totalWeight + (draftAverageWeight * draftQuantity);
            countableQuantity = countableQuantity + draftQuantity;
        }
    });

    let mergedAveragedWeight = null;
    if (countableQuantity) {
        mergedAveragedWeight = Math.round((totalWeight / countableQuantity) * 100) / 100;
    }

    //showTrailerBoxes merging any draft is true, merge is true
    const showTrailerBoxes = allMetadata.some(metadata => metadata?.showTrailerBoxes);

    const newMeta = {} as any;
    if (mergedSalmonella) {
        newMeta.cargo = {
            salmonellaResult: mergedSalmonella,
        }
    }
    if (mergedAveragedWeight) {
        newMeta.cargo = {
            ...newMeta.cargo,
            averageWeight: mergedAveragedWeight,
        }
    }

    if (showTrailerBoxes) {
        newMeta.showTrailerBoxes = true;
    }

    return newMeta;
};
