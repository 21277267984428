import React from 'react';
import { L } from 'harmony-language';
import { PhoneNumber } from '../../shared/phone-number';
import { createColumnHelper } from '@tanstack/react-table';
import { Contact, NOTIFICATION_TYPES } from '../../../types';

export const useContactColumns = () => {
    const columnHelper = createColumnHelper<Contact>();

    const columns = React.useMemo(() => {
        const c = [
            columnHelper.accessor('name', {
                header: L.name(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                size: 300,
            }),
            columnHelper.accessor('contactNotificationMethods', {
                id: 'contactNotificationMethodsEmail',
                header: L.email(),
                cell: (info) => {
                    const email = info.getValue().find(x => x.notificationType === NOTIFICATION_TYPES.Email)?.destination;
                    return email;
                },
                enableColumnFilter: false,
                size: 150,
            }),
            columnHelper.accessor('contactNotificationMethods', {
                id: 'contactNotificationMethodsPhone',
                header: L.phone(),
                cell: (info) => {
                    const phone = info.getValue().find(x => x.notificationType === NOTIFICATION_TYPES.Text)?.destination;
                    return <PhoneNumber phone={phone} />
                },
                enableColumnFilter: false,
                size: 150,
            }),
        ];

        return c;
    }, []);

    return columns;
};
