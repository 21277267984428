export const cancellablePromise = (promise) => {
    let hasCancelled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then((val) =>
            hasCancelled_ ? reject({ isCancelled: true }) : resolve(val)
        );
        promise.catch((error) =>
            hasCancelled_ ? reject({ isCancelled: true }) : reject(error)
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCancelled_ = true;
        },
    };
};
