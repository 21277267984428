import { useUser } from '../../../api/queries/use-user';
import { useEditUserPreferences } from '../../../api/mutations/edit/use-edit-user-preferences-mutation';

export const useUpdateUserPreferences = () => {
    const { user } = useUser();
    const { mutate: updatePreferences } = useEditUserPreferences(user.id);

    return {
        preferences: user.preferences,
        updatePreferences: updatePreferences,
    }
};
