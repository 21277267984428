import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions, QueryKeys } from '../config';
import { useSnackbar } from '../../components/notifications/use-snackbar';
import { Load, LoadCache } from '../../types';
import { useDraftsKey } from '../config-hooks';

async function publishDrafts(options: { drafts: Load[] }) {
    const drafts = options.drafts;

    return (await axios.put(`${API_BASE}/api/orders`, drafts.map(d => ({ ...d, isDraft: false })))).data;
}

export const usePublishDraftsMutation = () => {
    const snackbar = useSnackbar();
    const draftsKey = useDraftsKey();
    const queryClient = useQueryClient();

    return useMutation(publishDrafts, {
        ...mutationOptions(draftsKey, queryClient),
        onMutate: () => {
            queryClient.cancelQueries(draftsKey);
            const previousCache = queryClient.getQueryData(draftsKey);

            return () => queryClient.setQueryData(draftsKey, previousCache);
        },
        onSuccess: (data, options: { drafts: Load[] }) => {
            const drafts = options.drafts;

            const previousCache = queryClient.getQueryData<LoadCache>(draftsKey);
            const filteredDrafts = previousCache?.data?.filter(draft => !drafts.some(d => d.id === draft.id));

            queryClient.setQueryData(draftsKey, {
                ...previousCache,
                data: filteredDrafts
            });
            snackbar(L.bulkScheduleSnackbarSuccess(data.length));
        },
        onSettled: () => {
            queryClient.invalidateQueries([QueryKeys.productionCardGroups]);
        },
    });
};
