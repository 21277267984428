import { useSnackbar as notistackUseSnackbar } from 'notistack';

export const SnackbarTypes = {
    success: 'success' as const,
    warning: 'warning' as const,
    error: 'error' as const,
};

export const useSnackbar = () => {
    const { enqueueSnackbar } = notistackUseSnackbar();

    return (message: string, type: keyof typeof SnackbarTypes = SnackbarTypes.success) => {
        enqueueSnackbar(message, { variant: type });
    };
};
