import React from 'react';
import { L } from 'harmony-language';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { useAddOrganizationResourceMutation } from '../../../api/mutations/add/use-add-organization-resource-mutation';
import {
    useEditOrganizationResourceMutation
} from '../../../api/mutations/edit/use-edit-organization-resource-mutation';
import { ResourcePage } from '../resource-page';
import { useTractorColumns } from './use-tractor-columns';
import { EditCreateTractor } from './edit-create-tractor/edit-create-tractor';

export const TractorManagement = () => {
    const { data: tractors, isLoading, organizationId } = useOrganizationQuery(OrgQueryKeys.tractors);
    const { mutate: addTractor, isLoading: isCreating } = useAddOrganizationResourceMutation(OrgQueryKeys.tractors, L.tractor());
    const { mutate: editTractor, isLoading: isUpdating } = useEditOrganizationResourceMutation(OrgQueryKeys.tractors, L.tractor());

    const columns = useTractorColumns();

    return (
        <ResourcePage
            resourceType='tractor'
            isLoading={isLoading || isCreating || isUpdating}
            titleFunc={tractor => tractor.userDisplayName}
            data={tractors ? tractors.filter(x => x.organizationId === organizationId) : []}
            columns={columns}
            EditCreateComponent={(editCreateProps) => {
                return <EditCreateTractor
                    existingTractor={editCreateProps.resource}
                    organizationId={organizationId}
                    onSubmit={async (newData) => {
                        if (newData.id) {
                            editTractor(newData);
                        } else {
                            addTractor(newData);
                        }
                        editCreateProps.closeModal();
                    }}/>;
            }}/>
    );
};
