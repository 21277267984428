import { localTime } from '../../../utils/date-time-utils';

export const getTimeToNextStop = (route) => {
    if (!route?.sections[0]?.arrival) {
        return null;
    }

    const arrivalTime = route.sections[0].arrival.time;
    return localTime(arrivalTime);
};
