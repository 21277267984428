import axios from 'axios';
import { Load, ProductionCard } from '../../../types';
import { useBool } from '../../shared/hooks/use-bool';

type OrderGroupDetails = {
    isLoading: boolean,
    fetch: (guid: string | null) => Promise<OrderGroupDetailsResult | null>;
}

export type OrderGroupDetailsResult = {
    id: number,
    guid: string,
    orders: Load[],
    productionCards: ProductionCard[]
}

export const useOrderGroupDetails = (): OrderGroupDetails => {
    const [isLoading, { off, on }] = useBool(false);

    const fetch = async (guid: string | null): Promise<OrderGroupDetailsResult | null> => {
        if (!guid)
            return null;
        on();
        const response = await axios.get(`${API_BASE}/api/production-cards/groups/${guid}/detail`);
        off();
        return response.data;
    };

    return {
        isLoading,
        fetch
    };
};
