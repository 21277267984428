
import React from 'react';
import { L, SUPPORTED_LANGUAGES } from 'harmony-language';
import { ActionIcon } from '../../shared/table/components/action-icon';
import { createColumnHelper } from '@tanstack/react-table';

interface Action {
    id?: 'carrier';
    icon: string;
    tooltip: string;
    action: (c: any) => void;
}

export const useCompanyColumns = (actions: Action[]) => {
    const columnHelper = createColumnHelper<any>();

    const columns = React.useMemo(() => {
        const c = [
            columnHelper.accessor('name', {
                header: L.name(),
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('language', {
                header: L.defaultLanguage(),
                // @ts-ignore
                cell: (info) => L[SUPPORTED_LANGUAGES.find(x => x.code === info.row.original.language).description](),
            }),
            columnHelper.accessor('timezone', {
                header: L.defaultTimezone(),
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('units', {
                header: L.defaultUnits(),
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('actions', {
                header: L.actions(),
                cell: (info) => {
                    const row = info.row.original;

                    return (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {actions?.map(a => <ActionIcon testId={row.id} key={a.icon} action={() => a.action(row)} icon={a.icon}
                                tooltip={a.tooltip} isDisabled={a.id === 'carrier' ? row.isCarrier : false} />)}
                        </div>
                    );
                }
            }),
        ];

        return c;
    }, []);

    return columns;
};
