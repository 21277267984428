import { CHECKLIST_RESPONSE_BITMASK, ChecklistResponseValue } from 'harmony-constants';
import { ChecklistChecklist, ChecklistKey } from '../../types';

export const hasNotesResolver = (checklist: ChecklistChecklist) => {
    return (
        Boolean(checklist.notes) ||
        checklist.groups.some((group) =>
            group.items.some((item) => Boolean(item.notes))
        )
    );
};

export const usedResponseResolver = (checklist: ChecklistChecklist | undefined) => {
    return checklist?.groups?.reduce((finalMask, group) => {
        return (
            finalMask |
            group.items.reduce((groupMask, item) => {
                return groupMask | CHECKLIST_RESPONSE_BITMASK[item.response];
            }, 0)
        );
    }, 0);
};

export const areAllItemsResolved = (
    checklist: ChecklistChecklist | undefined,
    toBeResolvedList: ChecklistKey[]
) => {
    return checklist?.groups?.[0]?.items?.every((i) => {
        if (!isChecklistItemResolved(i.response)) {
            return toBeResolvedList.includes(i.key);
        }
        return true;
    });
};

export const isChecklistItemResolved = (
    checklistItemResponse: ChecklistResponseValue
) =>
    checklistItemResponse === ChecklistResponseValue.OK ||
    checklistItemResponse === ChecklistResponseValue.Unspecified;
