import axios from 'axios';

export const  hereMapGeocode = async (params: Record<string, string>) => {
    const response = await axios.get(`${API_BASE}/api/locations/geocode?`, {
        params
    });
    if (!response?.data?.position) {
        return null;
    }

    return response.data;
}
