import { GeoLocation, Point } from '../types';

// putting this here for now, as geo-utils is removed in another branch that I have
export const pointInZone = (point: Point, geoLocation: GeoLocation) => {
    return (
        point.latitude < geoLocation.bbox.topLeft.latitude &&
        point.latitude > geoLocation.bbox.bottomRight.latitude &&
        point.longitude > geoLocation.bbox.topLeft.longitude &&
        point.longitude < geoLocation.bbox.bottomRight.longitude
    );
};
