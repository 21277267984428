import React from 'react';
import { styled } from '@mui/material/styles';
import { DateFiltering } from '../../shared/load-table/load-date-filtering';
import { QueryKeys } from '../../../api/config';
import { Table } from '@tanstack/react-table';
import { SearchBar } from '../../shared/search-bar';
import { L } from 'harmony-language';

const StyledDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: '0.5rem 1rem',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.grey[300]}`
}));

interface OrderGroupTableToolbarProps {
    refetch: () => void;
    table: Table<any>;
}

const OrderGroupTableToolbar: React.FC<OrderGroupTableToolbarProps> = (props) => {
    const { refetch, table } = props;
    const tableKey = QueryKeys.productionCardGroups;

    const handleSearchTextChange = (text: string) => {
        table.setGlobalFilter(text);
    };

    return (
        <StyledDiv role='toolbar'>
            <DateFiltering tableKey={tableKey} refetch={refetch} />
            <SearchBar
                placeholder={`${L.numRecords(table.getRowCount().toString())}`}
                text={table.getState().globalFilter}
                setText={handleSearchTextChange}
            />
        </StyledDiv>
    );
};

export default OrderGroupTableToolbar;
