import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { mutationOptions, OrgQueryKeys } from '../../config';
import { createNewBookingsFromLoad } from '../../../components/bookings/utils/booking-utils';
import { Booking, Load, LoadCache } from '../../../types';
import { useUser } from '../../queries/use-user';
import { useDraftsKey } from '../../config-hooks';

async function editDraft(options: { draft: Load }) {
    const draft = options.draft;

    return (await axios.put(`${API_BASE}/api/orders/${draft.id}`, draft)).data;
}

function updateBookingsCache(companyId: number, draft: Load, queryClient: QueryClient) {
    const cacheKey = OrgQueryKeys.resolve(companyId, OrgQueryKeys.bookings);
    const previousCache = queryClient.getQueryData<Booking[]>([cacheKey]);

    queryClient.setQueryData([cacheKey], {
        ...previousCache,
        ...createNewBookingsFromLoad(previousCache, draft)
    });
    return () => queryClient.setQueryData([cacheKey], previousCache);
}

function updateDraftCache(draft: Load, queryClient: QueryClient, draftsKey: (string | number | number[])[]) {
    queryClient.cancelQueries(draftsKey);

    const previousCache = queryClient.getQueryData<LoadCache>(draftsKey);
    const newDrafts = previousCache?.data?.map((existingDraft) => {
        if (existingDraft.id === draft.id) {
            return {
                ...existingDraft,
                ...draft,
            };
        }
        return existingDraft;
    });

    // Optimistically update to the new value
    queryClient.setQueryData(draftsKey, {
        ...previousCache,
        data: newDrafts
    });

    // Return a rollback function
    return () => queryClient.setQueryData(draftsKey, previousCache);
}

export const useEditDraftMutation = () => {
    const { organizationId } = useUser();
    const queryClient = useQueryClient();
    const draftsKey = useDraftsKey();

    return useMutation(editDraft, {
        ...mutationOptions(draftsKey, queryClient),
        onMutate: (options) => {
            const bookingsRollback = updateBookingsCache(organizationId, options.draft, queryClient);

            return () => {
                bookingsRollback();
            };
        },
        onSuccess: (data) => {
            updateDraftCache(data, queryClient, draftsKey);
        },
        onSettled: () => {
        }
    });
};
