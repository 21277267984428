import { ColumnDef, PaginationState, Row, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';

export type OrderGroup = {
    id: number;
    guid: string;
    dateRange: [string, string];
    pickups: number[];
    dropoffs: number[];
    cargo: {
        cargoTypeId: number,
        quantity: number | null,
        weight: number | null,
        productionPlan?: string
    }[],
    orderNumbers: string[],
    lotIds: string[];
    numberOfDraftLoads: number;
    numberOfScheduledLoads: number;
    numberOfHistoricLoads: number;
    isUnlinkable: boolean;
};

// react-table refuses to search array columns, even if you define a filterFn on the column definition
// I tried both filterFn and a custom accessor function, but the filter never ran
const orderGroupGlobalFilterFunc = (row: Row<OrderGroup>, columnId: string, filterValue: string) => {
    const dateString = row.getValue<string>('dateRange');
    const origins = row.getValue<string[]>('pickups');
    const destinations = row.getValue<string[]>('dropoffs');
    const cargos = row.getValue<string[]>('cargo');
    const orderNumbers = row.getValue<string[]>('orderNumbers');
    const lotIds = row.getValue<string[]>('lotIds');

    const hasDate = dateString.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase());

    const hasOrigin = origins.some(displayName => {
        return displayName.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase());
    });

    const hasDestination = destinations.some(displayName => {
        return displayName.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase());
    });

    const hasCargo = cargos.some(displayName => {
        return displayName.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase());
    });

    const hasOrderNumber = orderNumbers.some(orderNumber => {
        return orderNumber.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase());
    });

    const hasLotId = lotIds.some(lotId => {
        return lotId.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase());
    })

    return hasDate || hasOrigin || hasDestination || hasCargo || hasOrderNumber || hasLotId;
}

export const useOrderGroupTable = <T>(
    columns: ColumnDef<T, any>[], // can't get TypeScript to figure out the 6+ column types that will be retured from createColumnHelper in react-table
    data: OrderGroup[],
    pagination: PaginationState,
) => {
    // const { data: locations } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);

    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(), // this enables client side pagination
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: orderGroupGlobalFilterFunc,
        enableGlobalFilter: true,
        initialState: {
            pagination
        },
    });

    return table;
};
