import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mutationOptions, OrgQueryKeys } from '../../config';
import axios from 'axios';
import { useState } from 'react';
import { useCompanyId } from '../../../app/agistics-context';
import { Image } from '../../../types';

export const useAddOrganizationLocationImages = () => {
    const [mutateKey, setMutateKey] = useState<string | undefined>();
    const companyId = useCompanyId();
    const queryClient = useQueryClient();

    return useMutation(async ({ images, locationId }: { images: Pick<Image, 'blob' | 'type'>[], locationId: number }) => {
        const key = OrgQueryKeys.locationImages(companyId, locationId);
        setMutateKey(key);
        return (await axios.post<Image[]>(`${API_BASE}/api${key}`, images))?.data
    }, {
        ...mutationOptions([mutateKey], queryClient),
    })
}
