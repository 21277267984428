import React from 'react';
import { L } from 'harmony-language';
import { AgisticsCard } from '../shared/containers/agistics-card';
import { Button, FormControl, InputLabel, MenuItem, TextField, Typography } from '@mui/material';
import { getWorkingWeek, localNow } from '../../utils/date-time-utils';
import { ReportRunOptions } from './report-types';
import { AgisticsDateRange } from '../shared/inputs/agistics-date-range';
import { Select } from '@mui/material';
import { OnTimeReportMode } from './on-time/on-time-report';

interface ReportFiltersProps {
    isAdmin: boolean;
    organizationId: number;
    setOrganizationId: React.Dispatch<React.SetStateAction<number>>;
    startDate: string;
    endDate: string;
    setStartDate: React.Dispatch<React.SetStateAction<string>>;
    setEndDate: React.Dispatch<React.SetStateAction<string>>;
    runReport: (r: ReportRunOptions) => void;
    mode?: OnTimeReportMode;
    setMode?: (o: OnTimeReportMode) => void;
    children?: React.ReactNode;
}

// abstract filters, might need to rework if to many reports don't follow this logic
export const ReportFilters: React.FC<ReportFiltersProps> = (props) => {
    const { isAdmin, organizationId, setOrganizationId, startDate, endDate, setStartDate, setEndDate, runReport, mode, setMode, children } = props;

    const lastWeek = () => {
        const lastWeek = getWorkingWeek(-1);
        const sunUtc = lastWeek.sundayValue.startOf('day').toISOString();
        const satUtc = lastWeek.saturdayValue.endOf('day').toISOString();
        setStartDate(sunUtc);
        setEndDate(satUtc);
    }

    const thisWeek = () => {
        const thisWeek = getWorkingWeek(0);
        const sunUtc = thisWeek.sundayValue.startOf('day').toISOString();
        const satUtc = thisWeek.saturdayValue.endOf('day').toISOString();
        setStartDate(sunUtc);
        setEndDate(satUtc);
    }

    const lastMonth = () => {
        const lastMonth = localNow().subtract(1, 'month');
        const daysInMonth = lastMonth.daysInMonth();
        setStartDate(lastMonth.date(1).toISOString());
        setEndDate(lastMonth.date(daysInMonth).toISOString());
    }

    const thisMonth = () => {
        const now = localNow();
        const daysInMonth = now.daysInMonth();
        setStartDate(now.date(1).startOf('day').toISOString());
        setEndDate(now.date(daysInMonth).endOf('day').toISOString());
    }

    return (
        <AgisticsCard title={L.filters()}>
            <div style={{ display: 'flex', gap: '40px' }}>
                <div>
                    {isAdmin &&
                        <>
                            <TextField
                                variant='standard'
                                value={organizationId}
                                type='number'
                                onChange={(e) => setOrganizationId(Number(e.target.value))}
                                label={L.organizationId()} />
                            <Typography>Reminder: This is running against prod replica.  Set organizationId to prod id, or 0 for all.</Typography>
                            <br />
                        </>
                    }
                    {mode && <div>
                    <FormControl variant='standard' style={{ width: '10vw' }}>
                        <InputLabel id='mode-select-label'>Mode</InputLabel>
                        <Select
                            labelId='mode-select-label'
                            value={mode}
                            onChange={(e) => {
                                // fix mui typescirpt bug
                                const value = e.target.value as OnTimeReportMode;
                                setMode && setMode(value);
                                if (value === OnTimeReportMode.day) {
                                    setStartDate(localNow().subtract(1, 'day').startOf('day').toISOString());
                                    setEndDate(localNow().subtract(1, 'day').endOf('day').toISOString());
                                } else if (value === OnTimeReportMode.week) {
                                    setStartDate(localNow().weekday(0).startOf('day').toISOString());
                                    setEndDate(localNow().weekday(6).endOf('day').toISOString());
                                } else {
                                    thisMonth();
                                }
                            }}
                        >
                            {Object.values(OnTimeReportMode).map(mode => (
                                <MenuItem
                                    key={mode}
                                    value={mode}
                                >
                                    {L[mode as keyof typeof L]()}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br />
                    <br />
                    </div>}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AgisticsDateRange
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            mode={mode}
                        />
                        {(!mode || mode === OnTimeReportMode.week) && <>
                            <Button variant='contained' color='primary' size='small' onClick={lastWeek}>{L.lastWeek()}</Button>
                            <Button variant='contained' color='primary' size='small' onClick={thisWeek} style={{ marginLeft: '10px' }}>{L.thisWeek()}</Button>
                        </>}
                        {mode === OnTimeReportMode.month && <>
                            <Button variant='contained' color='primary' size='small' onClick={lastMonth}>{L.lastMonth()}</Button>
                            <Button variant='contained' color='primary' size='small' onClick={thisMonth} style={{ marginLeft: '10px' }}>{L.thisMonth()}</Button>
                        </>}
                    </div>
                    <br />
                    <br />
                    <Button variant='contained' color='primary' onClick={() => runReport({})}>{L.runReport()}</Button>
                </div>
                <div style={{ borderLeft: 'thin solid #42424246' }} />
                <div>
                    {children}
                </div>
            </div>
        </AgisticsCard>
    );
};
