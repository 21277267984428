import { QueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { NavigateFunction } from 'react-router-dom';

export const userSignOut = async (queryClient: QueryClient, navigate?: NavigateFunction) => {
    axios.delete(`${API_BASE}/api/session`);

    queryClient.clear();

    navigate && navigate('/login');
}
