import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions, OrgQueryKeys } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { StandardRoute } from '../../../types';
import { useCompanyId } from '../../../app/agistics-context';

export const useEditStandardRouteMutation = () => {
    const snackbar = useSnackbar();
    const companyId = useCompanyId();
    const key = OrgQueryKeys.resolve(companyId, OrgQueryKeys.standardRoutes);
    const queryClient = useQueryClient();

    // react-query v5 only supports object format going forward
    const mutation = useMutation({
        ...mutationOptions([key], queryClient),
        mutationFn: async (standardRoute: StandardRoute) => {
            return (await axios.put(`${API_BASE}/api${key}/${standardRoute.id}`, standardRoute)).data;
        },
        onSuccess: (data) => {
            const standardRoutes = queryClient.getQueryData<StandardRoute[]>([key]);
            if (standardRoutes) {
                const newStandardRoutes = standardRoutes.map((existingRoute) => {
                    if (existingRoute.id === data.id) {
                        return {
                            ...data,
                        };
                    }
                    return existingRoute;
                });
                queryClient.setQueryData([key], newStandardRoutes);
                snackbar(L.standardRouteModified());
            }
        },
    });

    return mutation;
};
