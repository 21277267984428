import { useCallback, useState } from 'react';
import useIsMounted from './use-is-mounted';

type VoidFn = () => void;

export function useBool(
    initialState = false
): Readonly<[boolean, { on: VoidFn; off: VoidFn; toggle: VoidFn; set: (b: boolean) => void }]> {
    const [value, setValue] = useState(initialState);
    const isMounted = useIsMounted();

    const on = useCallback(() => {
        if (isMounted()) setValue(true);
    }, [isMounted]);

    const off = useCallback(() => {
        if (isMounted()) setValue(false);
    }, [isMounted]);

    const toggle = useCallback(() => {
        if (isMounted()) setValue((prev) => !prev);
    }, [isMounted]);

    const set = useCallback(
        (value) => {
            if (isMounted()) setValue(value);
        },
        [isMounted]
    );

    return [value, { on, off, toggle, set }] as const;
}
