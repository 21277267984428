import FilterList from '@mui/icons-material/FilterList';
import { Button, ListItemButton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import React from 'react';
import { useBoolean } from '../../hooks/use-boolean';

export const DistinctColumnFilter = ({column}) => {

    const [isPopperOpen, open, close] = useBoolean(false);
    const buttonEl = React.useRef(null);
    const sortedUniqueValues = React.useMemo(
        () => Array.from(column.getFacetedUniqueValues().keys()).sort(),
        [column.getFacetedUniqueValues()]
    );
    const existingFilters = column.getFilterValue();

    const handleFilterClick = (val) => {
        if (!existingFilters) {
            column.setFilterValue([val]);
            return;
        }

        const existingIndex = existingFilters.indexOf(val);

        if (existingIndex !== -1) {
            column.setFilterValue(prevValues => prevValues.filter(value => value != val));
        } else {
            column.setFilterValue(prevValues => [...prevValues, val]);
        }
    };

    return (
        <div>
            <IconButton size='small' ref={buttonEl} onClick={open}>
                <FilterList fontSize={'inherit'} color={existingFilters?.length ? 'primary' : 'action'} />
            </IconButton>
            <Popper open={isPopperOpen} anchorEl={buttonEl.current} disablePortal={true} placement='bottom-start' style={{ zIndex: 1 }}>
                <ClickAwayListener onClickAway={close}>
                    <Paper>
                        <List dense={true}>
                            {sortedUniqueValues.map((val, i) =>
                                <ListItemButton key={i}
                                    onClick={() => {
                                        handleFilterClick(val)}}
                                >
                                    <Checkbox color='primary' checked={existingFilters ? existingFilters.includes(val) : false} />
                                    {val}
                                </ListItemButton>
                            )}
                                <Button fullWidth onClick={() => column.setFilterValue([])}>
                                    Reset Filters
                                </Button>
                        </List>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </div>
    );
};

DistinctColumnFilter.propTypes = {
    column: PropTypes.object.isRequired,
};
