import { useQuery } from '@tanstack/react-query';
import { PermissionType } from 'harmony-constants';
import React, { ChangeEvent, FormEvent, FormEventHandler } from 'react';
import { OrgQueryKeys } from '../../api/config';
import { useAddOrganizationChecklistMutation } from '../../api/mutations/add/use-add-organization-checklist-mutation';
import { useUser } from '../../api/queries/use-user';
import { type Checklist, type ChecklistKey, type EquipmentResourcePath } from '../../types';
import { deepEqual } from '../../utils/deep-equal';
import { usePermissions } from '../user/selectors/use-permissions';
import { isChecklistItemResolved, usedResponseResolver } from './checklist-utils';
import { TractorTrailerResourceWithType } from './use-maintenance-columns';

export const useChecklistEntry = ({
    onSubmit,
    resource,
    resourceType,
    existingChecklist
}: {
    onSubmit: () => void;
    resource: TractorTrailerResourceWithType;
    resourceType: TractorTrailerResourceWithType['type'];
    existingChecklist?: Checklist;
}) => {
    const [resolvedList, setResolvedList] = React.useState<ChecklistKey[]>([]);
    const { user: currentUser, organizationId } = useUser();
    const resolveChecklistPermission = usePermissions(PermissionType.ResolveChecklistItem);

    const { data: originalChecklist, isLoading: isLoadingChecklist } =
        existingChecklist
            ? { data: existingChecklist, isLoading: false }
            : useQuery<Checklist>(
                  [OrgQueryKeys.getLatestChecklist(
                      organizationId,
                      resourceType.toLowerCase() as EquipmentResourcePath<typeof resourceType>,
                      resource.id
                  )],
                  { staleTime: Infinity }
              );

    const { mutate: resolveChecklist, isLoading: isResolving } =
        useAddOrganizationChecklistMutation(
            organizationId,
            resourceType?.toLowerCase() as EquipmentResourcePath<typeof resourceType>,
            resource?.id
        );

    const isLoading = isLoadingChecklist || isResolving;

    const checklist = originalChecklist?.checklist;

    const usedResponses = usedResponseResolver(checklist);
    const viewOnlyMode =
        !resolveChecklistPermission || (usedResponses && usedResponses <= 3);
    
    const checklistItemChanged = (itemKey: ChecklistKey) => {
        if (resolvedList.indexOf(itemKey) !== -1) {
            setResolvedList(resolvedList.filter((i) => i !== itemKey));
        } else {
            setResolvedList(resolvedList.concat(itemKey));
        }
    };

    const [notes, setNotes] = React.useState<Record<string, string>>({})
    const [originalNotes, setOriginalNotes] = React.useState<Record<string, string>>({})

    const checklistToNotesMap = (checklist: Checklist | undefined) => {
        return checklist?.checklist.groups?.[0]?.items
                    ?.filter(x => !isChecklistItemResolved(x.response))
                    .reduce((acc, cur) => {
                        acc[cur.key] = cur.notes;
                        return acc;
                    }, ({} as Record<ChecklistKey, any>)) || {}
    }

    const checklistHasChanged = React.useMemo(() => {

        return resolvedList.length > 0 || !deepEqual(notes, originalNotes)

    }, [notes, resolvedList, originalChecklist?.checklist?.groups?.[0]?.items])

    React.useEffect(() => {
        const originalNotes = checklistToNotesMap(originalChecklist)
        setNotes(originalNotes);
        setOriginalNotes(originalNotes);
    }, [originalChecklist?.checklist?.groups?.[0]?.items])

    const notesChanged = (key: ChecklistKey) => (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement
        setNotes({...notes, [key]: target.value})
    }

    const handleSubmit: FormEventHandler<HTMLInputElement> = async (
        e: FormEvent
    ) => {
        e?.preventDefault?.();
        try {

            const newItems = originalChecklist?.checklist.groups[0].items.map(x => {
                const isResolvedNow = resolvedList.indexOf(x.key) !== -1;
                const newItem = {
                    ...x,
                    ...(isResolvedNow ? {notes:null, response: 'OK'} : {notes: notes?.[x.key] || x.notes})
                }
                return newItem;
            })
            const updatedChecklist = {
                ...originalChecklist,
                completedByUserId: currentUser.id,
                checklist: {
                    odometerReading: originalChecklist?.checklist.odometerReading,
                    groups: [
                        {
                            key: originalChecklist?.checklist.groups[0].key,
                            shownText: originalChecklist?.checklist.groups[0].shownText,
                            items: newItems
                        }
                    ]
                }
            }
            await resolveChecklist({ checklist: updatedChecklist as Checklist });
            onSubmit();
        } catch (e) {
            // only care to submit if resolve works
        }
    };

    return {
        checklistItemChanged,
        notesChanged,
        notes,
        handleSubmit,
        isLoading,
        viewOnlyMode,
        checklistHasChanged,
        originalChecklist,
        resolvedList
    };
};
