import { createTheme } from '@mui/material/styles';

// https://mui.com/material-ui/customization/palette/#custom-colors
const defaultGreyBase = '#E0E0E0';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#D32F2F',
        },
        secondary: {
            main: '#424242',
            light: '#676767',
            dark: '#2E2E2E',
            contrastText: '#FFFFFF',
        },
        error: {
            main: '#F44336',
            light: '#E57373',
            dark: '#D32F2F',
            contrastText: '#FFFFFF',
        },
        // this is only needed for Chips in tooltip. Has alpha, and red in tooltip bleads through
        // @ts-ignore
        defaultGrey: {
            main: defaultGreyBase,
            light: defaultGreyBase,
            dark: defaultGreyBase,
            contrastText: '#000000',
        },
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: { fontSize: '0.9rem' },
            },
        },
        // MuiCheckbox: {
        //     styleOverrides: {
        //         root: {
        //             [`&.${checkboxClasses.checked}`]: {
        //                 color: '#424242',
        //             },
        //         }
        //     }
        // },
        // 4 buttons on pagination refuse to stay horizontal without this
        // MuiTablePagination: {
        //     styleOverrides: {
        //         spacer: { flex: 'none' },
        //         // root: { styles: { }}

        //     }
        // },
        // @ts-ignore - Base MUI does not know about @mui/x-date-pickers types
        MuiDatePicker: {
            defaultProps: {
                slotProps: {
                    textField: { size: 'small', variant: 'standard' },
                    openPickerIcon: { fontSize: 'small' },
                }
            }
        }
        // MuiAutocomplete: {
        //     styleOverrides: {
        //         inputRoot: {
        //             fontSize: '.875rem',
        //         },
        //     },
        // },
    },
    // components: {
    //     MuiButton: {
    //         styleOverrides: {
    //             root: {
    //                 fontWeight: 400,
    //             },
    //         },
    //     },
    // },
});
