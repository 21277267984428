import { Button, Checkbox, ListItemText, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { L } from 'harmony-language';
import { Driver } from '../../../types';

interface DriverFilterProps {
    drivers: Driver[];
    removedDrivers: number[];
    setRemovedDrivers: (value: number[]) => void
}

export const DriverFilter: React.FC<DriverFilterProps> = (props) => {
    const { drivers, removedDrivers, setRemovedDrivers } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (driverId: number) => {
        const update = (driverIds: number[]) => {
            setRemovedDrivers(driverIds);
        }

        const isCurrentlyRemoved = removedDrivers.some(x => x === driverId);
        if (isCurrentlyRemoved) {
            const newRemovedDrivers = removedDrivers.filter(x => x !== driverId);
            update(newRemovedDrivers);
        } else {
            const newRemovedDrivers = removedDrivers.concat([driverId]);
            update(newRemovedDrivers);
        }

    };

    return (
        <div>
            <Button
                type='submit'
                title={L.save()}
                variant='contained'
                color='primary'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {L.filterDrivers()}
            </Button>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {drivers.map(driver => driver.enabled && (
                    <MenuItem
                        key={driver.id}
                        onClick={() => handleMenuItemClick(driver.id)}
                        value={driver.id}
                        style={{ width: '100%' }}
                    >
                        <Checkbox checked={!(removedDrivers.indexOf(driver.id) > -1)} />
                        <ListItemText primary={driver.name} />
                    </MenuItem>
                ))}
            </Menu>
      </div>
    );
};
