import { useQuery } from '@tanstack/react-query';
import { OrgQueryKeys } from '../config';
import { ResourceType } from '../../types';
import { Downtime } from 'harmony-types';

export const useDowntimeQuery = (resourceType: ResourceType, resourceId: number, companyId: number) => {
    const queryKey = OrgQueryKeys.resolveDowntime(companyId, OrgQueryKeys[resourceType], resourceId);

    return useQuery<Downtime[]>([queryKey]);
};
