import moment from 'moment-timezone';

function resortValue<T>(this: Array<T>, oldIndex: number, newIndex: number, newValue: T): Array<T> {
    const value = this.splice(oldIndex, 1)[0];

    this.splice(newIndex, 0, newValue ? newValue : value);
    return [...this];
}

function countWhere<T>(this: Array<T>, predicate: (x: T) => boolean): number {
    return this.reduce((count, el) => {
        if (predicate(el)) {
            count++;
        }

        return count;
    }, 0);
}

function distinctBy<T, V>(this: Array<T>, selector: (x: T) => V): Array<T> {
    return this.filter((el, i) => this.findIndex(a => selector(a) === selector(el)) === i);
}

function first<T>(this: Array<T>): T {
    return this[0];
}

function last<T>(this: Array<T>): T {
    return this[this.length - 1];
}

function sum<T>(this: Array<T>, selector: (x: T) => number): number {
    return this.reduce((acc, cur) => {
        if (selector) {
            return acc + selector(cur);
        }

        return acc + (cur as unknown as number);
    }, 0);
}

function sortBy<T, V extends string | number | Date>(this: Array<T>, selector: (x: T) => V): Array<T> {
    return [...this].sort((a: T, b: T) => {
        const resultOfSelectorA = selector(a);
        if (typeof resultOfSelectorA === 'string') {
            return resultOfSelectorA.localeCompare(selector(b) as string);
        }
        if (resultOfSelectorA instanceof Date) {
            return moment(resultOfSelectorA).valueOf() - moment(selector(b)).valueOf();
        }

        return resultOfSelectorA as number - (selector(b) as number);
    });
}

function flatMap<T>(this: Array<Array<T>>, f: (x: Array<T>) => Array<T>): Array<T> {
    return this.reduce((x, y) => [...x, ...f(y)], []);
}

function flat<T>(this: Array<Array<T>>): Array<T> {
    return this.reduce((x, y) => x.concat(y), []);
}

export const setupArrayUtilityFunctions = () => {
    Object.defineProperty(Array.prototype, 'resortValue', { value: resortValue });
    Object.defineProperty(Array.prototype, 'countWhere', { value: countWhere });
    Object.defineProperty(Array.prototype, 'distinctBy', { value: distinctBy });
    Object.defineProperty(Array.prototype, 'last', { value: last });
    Object.defineProperty(Array.prototype, 'first', { value: first });
    Object.defineProperty(Array.prototype, 'sortBy', { value: sortBy });
    Object.defineProperty(Array.prototype, 'sum', { value: sum });
    Object.defineProperty(Array.prototype, 'flatMap', { value: flatMap });
    Object.defineProperty(Array.prototype, 'flat', { value: flat });
};
