import React from 'react';
import { QueryKeys } from '../config';
import { useStaticQuery } from './use-static-query';

export const useLocationTypes = (filters) => {
    const locationTypes = useStaticQuery(QueryKeys.locationTypes);

    const siteTypeCategory = filters?.category;
    const siteTypeName = filters?.name;
    const cargoTypeId = filters?.cargoTypeId;
    const isBasedOnOrigin = filters?.isBasedOnOrigin;

    const data = React.useMemo(() => {
        return locationTypes.data
            ?.filter(l => siteTypeCategory ? l.category === siteTypeCategory : true)
            ?.filter(l => siteTypeName? l.name === siteTypeName : true)
            ?.filter(l => cargoTypeId ? (isBasedOnOrigin ? l.shipsCargoTypeIds?.includes(cargoTypeId) : l.receivesCargoTypeIds?.includes(cargoTypeId)) : true);
    }, [locationTypes.data, siteTypeCategory, cargoTypeId, isBasedOnOrigin]);

    return {
        ...locationTypes,
        data
    };
};

