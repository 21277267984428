import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions, OrgQueryKeys } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { CreateStandardRoute, StandardRoute } from '../../../types';
import { useCompanyId } from '../../../app/agistics-context';

export const useAddStandardRouteMutation = () => {
    const snackbar = useSnackbar();
    const companyId = useCompanyId();
    const key = OrgQueryKeys.resolve(companyId, OrgQueryKeys.standardRoutes);
    const queryClient = useQueryClient();

    // react-query v5 only supports object format going forward
    const mutation = useMutation({
        ...mutationOptions([key], queryClient),
        mutationFn: async (standardRoute: CreateStandardRoute) => {
            return (await axios.post(`${API_BASE}/api${key}`, standardRoute)).data;
        },
        onSuccess: (data) => {
            const standardRoutes = queryClient.getQueryData<StandardRoute[]>([key]);
            if (standardRoutes) {
                const newStandardRoutes = [...standardRoutes, data];
                queryClient.setQueryData([key], newStandardRoutes);
                snackbar(L.standardRouteCreated());
            }
        }
    });

    return mutation;
};
