import React from 'react';

export interface HereMapContextValue {
    map: H.Map;
    ui: H.ui.UI;
    behavior: H.mapevents.Behavior;
}

const HereMapContext = React.createContext<HereMapContextValue>({} as HereMapContextValue);

export { HereMapContext };