import { ColumnDef, PaginationState, getCoreRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';

export const useResourceTable = <T>(
    columns: ColumnDef<T, any>[], // can't get TypeScript to figure out the 6+ column types that will be retured from createColumnHelper in react-table
    data: any,
    pagination: PaginationState,
) => {
    // this table uses client side pagination, an important difference compared to how
    // reports table pagination works
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(), // this enables client side pagination
        getSortedRowModel: getSortedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFilteredRowModel: getFilteredRowModel(),
        initialState: {
            pagination
        },
    });

    return table;
};
