import React from 'react';
import { OrgQueryKeys } from '../../../../api/config';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { Load, OrganizationLocation, Stop } from '../../../../types';
import { filterStopByDestination } from '../../../../utils/data-mapping-utils';
import { useWeights } from '../../hooks/use-weights';
import { Comparison } from '../comparison-filters';

type LoadFilterField = keyof Load | keyof Stop | keyof OrganizationLocation | 'operatorWeight';

export type LoadFilter = {
    entity: 'order' | 'stop' | 'organizationLocation' | 'calculatedField';
    field: LoadFilterField;
    category: string,
    label: string,
    value: string | number;
    children?: {field: keyof Stop, id: number, label: string}[]
    selectedChild?: {field: keyof Stop, id: number, label: string}
}

export type LoadFiltering = {
    filters: LoadFilter[],
    filteredData: Load[],
    add: (f: LoadFilter) => void,
    // I don't think this remove is used anywhere?
    // remove: (f: LoadFilter) => void,
    removeByField: (f: LoadFilterField) => void;
    clear: () => void,
    set: (f: LoadFilter[]) => void;
}

export const useLoadFiltering = (data: Load[]): LoadFiltering => {
    const [filters, setFilters] = React.useState<LoadFilter[]>([]);
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { convertFromGrams } = useWeights();

    const add = React.useCallback((f: LoadFilter): void => {
        setFilters((x) => x.concat(f));
    }, []);
    // const remove = React.useCallback((f: LoadFilter): void => {
    //     console.log('removing!');
    //     setFilters((x) => x.filter(x => x.entity !== f.entity && x.field !== f.field && x.value !== f.value));
    // }, []);
    const removeByField = React.useCallback((field: LoadFilterField) => {
        setFilters((x) => x.filter(x => x.field !== field));
    }, []);
    const clear = React.useCallback((): void => {
        setFilters([]);
    }, [])

    const filteredData: Load[] = React.useMemo(() => {

        const groupedFilters = filters.reduce<Record<string, LoadFilter[]>>((acc, curr) => {
            const key = `${curr.entity}.${curr.field}`;
            if (acc[key]) {
                acc[key].push(curr);
            } else {
                acc[key] = [curr];
            }
            return acc;
        }, {});

        let filtered = data;
        Object.entries(groupedFilters).forEach(([, filters]) => {
            filtered = filtered.filter(load => {
                return filters.some(filter => {
                    if (filter.entity === 'order') {
                        const field = filter.field as keyof Load;
                        return load[field] === filter.value;
                    } else if (filter.entity === 'stop') {
                        const field = filter.field as keyof Stop;
                        const filterValue = filter.value;
                        if (filter.selectedChild) {
                            const subField = filter.selectedChild.field as keyof Stop;
                            return load.stops.some(stop => stop[field] === filterValue && stop[subField] === filter.selectedChild?.id)
                        }
                        return load.stops.some(stop => stop[field] === filterValue);
                    // careful, the following is actually the filter for customer, which is determined by the organizationLocation customerId
                    } else if (filter.entity === 'organizationLocation') {
                        return load.stops.some(stop => organizationLocations.find(orgLoc => orgLoc.id === stop.organizationLocationId)?.customerId === filter.value);
                    } else if (filter.entity === 'calculatedField') {
                        if (filter.field === 'quantity') {
                            const destinationsWithQuantity = load.stops.filter(stop => filterStopByDestination(stop) && stop.quantity !== null);
                            
                            if (destinationsWithQuantity.length === 0) return false;
                            const loadDestinationQuantityTotal = destinationsWithQuantity
                                    .map(stop => stop.quantity || 0)
                                    .reduce((acc, stopQuantity) => acc + stopQuantity);
                            return loadDestinationQuantityTotal === filter.value;
                        }
                        if (filter.field === 'weight') {
                            const destinationsWithWeight = load.stops.filter(stop => filterStopByDestination(stop) && stop.weight !== null);

                            if (destinationsWithWeight.length === 0) return false;
                            const loadDestinationWeightTotal = destinationsWithWeight
                                    .map(stop => stop.weight || 0)
                                    .reduce((acc, stopWeight) => acc + stopWeight);
                            const totalWeightForCompare = convertFromGrams(loadDestinationWeightTotal);
                            return totalWeightForCompare === filter.value;
                        }
                        if (filter.field === 'operatorWeight') {
                            const operator = filter.category;
                            const destinationsWithWeight = load.stops.filter(stop => filterStopByDestination(stop) && stop.weight !== null);

                            if (destinationsWithWeight.length === 0) return false;
                            const loadDestinationWeightTotal = destinationsWithWeight
                                    .map(stop => stop.weight || 0)
                                    .reduce((acc, stopWeight) => acc + stopWeight);

                            const totalWeightForCompare = convertFromGrams(loadDestinationWeightTotal);

                            if (typeof filter.value === 'number') {
                                if (operator === Comparison.LessThan) {
                                    return filter.value > totalWeightForCompare;
                                } else if (operator === Comparison.LessThanEqual) {
                                    return filter.value >= totalWeightForCompare;
                                } else if (operator === Comparison.MoreThan) {
                                    return filter.value < totalWeightForCompare;
                                } else if (operator === Comparison.MoreThanEqual) {
                                    return filter.value <= totalWeightForCompare;
                                } else if (operator === Comparison.Equal) {
                                    return filter.value === totalWeightForCompare;
                                }
                            }
                        }
                    }
                })
            });
        });

        return filtered;

    }, [data, filters]);

    return {
        filteredData,
        filters,
        add,
        // remove,
        removeByField,
        clear,
        set: setFilters
    };
};
