import React, { useEffect } from 'react';
import { OrgQueryKeys } from '../../api/config';
import { L } from 'harmony-language';
import { Button, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { AgisticsDateTimePicker } from '../shared/inputs/agistics-date-time-picker';
import { useAddOrganizationResourceDowntimeMutation } from '../../api/mutations/add/use-add-organization-resource-downtime-mutation';
import { useEditOrganizationResourceDowntimeMutation } from '../../api/mutations/edit/use-edit-organization-resource-downtime-mutation';
import { DaysOfWeek } from './downtime-days-of-week';
import { CircularLoading } from '../shared/circular-loading';
import { ResourceType } from 'harmony-constants';
import { Downtime } from 'harmony-types';
import { getDowntimeEndDateString } from '../../utils/date-time-utils';
import { SelectTimezone } from '../shared/inputs/select-timezone';
import moment from 'moment-timezone';
import { useUser } from '../../api/queries/use-user';

interface DowntimeEntryProps {
    companyId: number;
    resourceType: ResourceType;
    resourceId: number;
    existingDowntime: Downtime;
    closeModal: () => void;
}

type ExistingDowntime = Omit<Downtime & {id?: number}, 'getResource'>

type DowntimePropKeys = keyof ExistingDowntime;
type DowntimePropValueTypes = ExistingDowntime[DowntimePropKeys];

export const DowntimeEntry = (props: DowntimeEntryProps) => {
    const { companyId, resourceType, resourceId, existingDowntime, closeModal } = props;
    const [toggle, setToggle] = React.useState<string | null>((existingDowntime?.recurrenceDaysOfWeek || '0000000') === '0000000' ? 'once' : 'recurring');
    const [duration, setDuration] = React.useState<number>(existingDowntime?.durationMinutes || 60)
    const [durationMode, setDurationMode] = React.useState<'minutes' | 'hours'>('minutes')
    //const [endDateTime, setEndDateTime] = React.useState<string | null>(getDowntimeEndDateString(existingDowntime))

    const { user } = useUser();
    const [downtime, setDowntime] = React.useState<ExistingDowntime>(existingDowntime || {
        durationMinutes: 60,
        timezone: user.contact.timezone || user.organization?.timezone,
        recurrenceDaysOfWeek: '0000000'
    });
    

    useEffect(() => {
        if (existingDowntime) {
            setDowntime(existingDowntime);
            //setEndDateTime(getDowntimeEndDateString(existingDowntime))
        }
    }, []);

    const handleToggle = (e: React.MouseEvent<HTMLElement>, toggleVal: string | null) => {
        if (toggleVal === null)
            return;
        setToggle(toggleVal);
        if (toggleVal = 'once') {
            downtimeChanged('recurrenceDaysOfWeek', '0000000')
        }
    };

    const { mutate: addDowntime, isLoading: isCreating } = useAddOrganizationResourceDowntimeMutation(companyId, OrgQueryKeys[resourceType], L[resourceType](), resourceId);
    const { mutate: editDowntime, isLoading: isUpdating } = useEditOrganizationResourceDowntimeMutation(companyId, OrgQueryKeys[resourceType], L[resourceType](), resourceId);
    const isLoading = isCreating || isUpdating;

    const downtimeChanged = (prop: DowntimePropKeys, value: DowntimePropValueTypes) => {
        const downtimeChanged = { ...downtime };
        if (prop === 'startDate') {
            downtimeChanged['startDate'] = moment(value).format('YYYY-MM-DD');
            downtimeChanged['startTime'] = moment(value).format('HH:mm:00');
        } else {
            downtimeChanged[prop] = value;
        }
        setDowntime(downtimeChanged);
    };

    const handleSubmit: React.FormEventHandler = async (e) => {
        e.preventDefault();
        downtime.description = downtime.description || '';
        if (downtime.id) {
            editDowntime(downtime);
        } else {
            addDowntime(downtime);
        }
        closeModal();
    };

    return (
        <div data-testid='resource-downtime-entry'>
            <form id='create-downtime-entry' name='create-downtime-entry'
                onSubmit={(event) => handleSubmit(event)}>
                <div style={{display:'flex', justifyContent: 'center', gap: '10px'}}>
                    <ToggleButtonGroup
                        value={toggle}
                        size={'small'}
                        exclusive
                        onChange={handleToggle}
                        aria-label="downtime mode (one-time or recurring)"
                        >
                        <ToggleButton value="once" aria-label="one time">
                            <Typography>{L.oneTime()}</Typography>
                        </ToggleButton>
                        <ToggleButton value="recurring" aria-label="recurring">
                            <Typography>{L.recurring()}</Typography>
                        </ToggleButton>

                    </ToggleButtonGroup>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                }
                }>
                    {isLoading && <CircularLoading/>}
                    <AgisticsDateTimePicker
                        disabled={isLoading}
                        required
                        label={L.begin()}
                        onChange={(date) => {
                            downtimeChanged('startDate', date);
                        }}
                        value={`${downtime.startDate?.toString()} ${downtime.startTime?.toString()}` || null}
                    />
                    

                    <div style={{display:'flex', justifyContent: 'flex-end'}}>
                    <TextField
                        variant='standard'
                        value={duration}
                        label={L.duration()}
                        style={{width: '75px', paddingRight:'10px'}}
                        onChange={(e) => {
                            setDuration(e.target.value);
                            const val = parseFloat(e.target.value);
                            if (isNaN(val))
                                return;
                            downtimeChanged('durationMinutes',(durationMode === 'hours') ? val * 60 : val)

                        }}
                        inputProps={{
                            'aria-label': L.duration()
                        }}
                        required />
                    <Select
                        id="duration-select"
                        value={durationMode}
                        label={L.duration()}
                        size="small"
                        onChange={(e) => {
                            setDurationMode(e.target.value);
                            setDuration(e.target.value === 'hours'
                                ? downtime.durationMinutes / 60
                                : downtime.durationMinutes)
                        }}
                    >
                        <MenuItem value={'minutes'}>{L.minutes()}</MenuItem>
                        <MenuItem value={'hours'}>{L.hours()}</MenuItem>
                    </Select>
                    </div>
                    {toggle === 'recurring' &&
                    <div style={{
                        margin: '1rem 0 0.5rem 0',
                    }}>
                        <DaysOfWeek clickable={!isLoading}
                            daysOfWeekBitmask={downtime?.recurrenceDaysOfWeek || '0'}
                            onChange={(newBitmask) => downtimeChanged('recurrenceDaysOfWeek', newBitmask)}/>
                    </div>
                    }
                    <SelectTimezone timezone={downtime?.timezone || user.contact?.timezone || user.organization?.timezone}
                        onChange={(timezone) => {
                            downtimeChanged('timezone', timezone);
                        }}
                    />
                    <div style={{
                        margin: '0.5rem 0 0.8rem 0',
                        width: '300px'
                    }}>
                        <TextField
                            variant='standard'
                            disabled={isLoading}
                            label={L.description()}
                            autoFocus={true}
                            maxRows='5'
                            minRows='1'
                            fullWidth={true}
                            onChange={(e) => {
                                downtimeChanged('description', e.target.value);
                            }}
                            value={downtime.description || ''}
                            required />
                    </div>
                </div>
                <Button className='submit-button' type='submit' variant='contained' disabled={isLoading}
                    color='primary'>{L.save()}</Button>
            </form>
        </div>
    );
};


