import { Popper, PopperProps } from '@mui/material';
import React from 'react';

// forces popup menu to fit width of longest item in the dropdown
export const CustomPopper: React.FC<PopperProps> = (props) => {
    return (
        <Popper
            {...props}
            style={{ width: 'fit-content' }}
        />
    );
};
