import React from 'react';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { AgisticsDateTimePicker } from '../../shared/inputs/agistics-date-time-picker';
import { L } from 'harmony-language';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import Button from '@mui/material/Button';
import { Load, OrganizationLocation, Stop } from '../../../types';
import { ORDER_STATUSES, STOP_STATUSES, STOP_TYPES } from '../../../constants/constants';
import { localDateTimeDisplay, localNow } from '../../../utils/date-time-utils';
import { toSiteDisplayName } from '../../../utils/data-mapping';
import { Checkbox, ListItemButton } from '@mui/material';
import { useManuallyCompleteLoads } from '../../../api/mutations/use-manually-complete-loads';
import { StopStatus } from 'harmony-constants';

const OrderRow = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ open }) => ({
    ...(!open && {
        display: 'grid',
        alignItems: 'center',
        borderTop: '1px solid #e0e0e0',
        gridAutoColumns: '5vw 10vw 10vw 2vw',
        gridAutoFlow: 'column',
    }),
}));

const StopRow = styled(ListItem)({
    display: 'grid',
    alignItems: 'center',
    borderTop: '1px solid #e0e0e0',
    gridAutoColumns: '3vw 7vw 7vw 10vw 5vw 10vw 13vw 3vw',
    gridAutoFlow: 'column'
});

interface ManuallyCompleteOrderItemProps {
    order: Load;
    handleChange: (id: number, s: Stop) => void;
}

const ManuallyCompleteOrderItem: React.FC<ManuallyCompleteOrderItemProps> = (props) => {
    const { order, handleChange } = props;
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <OrderRow open={open} onClick={handleClick}>
                <ListItemText primary={order.id}/>
                <ListItemText primary={ORDER_STATUSES()[order.status].label}/>
                <ListItemText primary={localDateTimeDisplay(order.date)}/>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </OrderRow>
            <Collapse in={open} timeout='auto' unmountOnExit>
                <List sx={{ paddingLeft: '2rem' }}>
                    <StopRow>
                        <ListItemText primary='' />
                        <ListItemText primary={L.stopType()} />
                        <ListItemText primary={L.status()} />
                        <ListItemText primary={L.location()} />
                        <ListItemText primary={L.quantity()} />
                        <ListItemText primary={L.orderNumber()} />
                        <ListItemText primary={L.time()} />
                        <ListItemText primary={L.notify()} />
                    </StopRow>
                    {order.stops.map((stop, i) => {
                        return (
                            <ManuallyCompleteStopItem key={i} stop={stop} handleChange={handleChange} />
                        )
                    })}
                </List>
            </Collapse>
        </>
    );
}

interface ManuallyCompleteStopItemProps {
    stop: Stop;
    handleChange: (id: number, s: Stop) => void;
}

const ManuallyCompleteStopItem: React.FC<ManuallyCompleteStopItemProps> = (props) => {
    const { stop, handleChange } = props;
    const { data: locations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const stopIsComplete = React.useMemo(() => stop.status === StopStatus.Complete, [stop]);
    const site = locations.find(e => e.id === stop.organizationLocationId);

    const handleCompletedChange = (dateTime: string) => {
        const updatedCompleted = {
            ...stop,
            completedAt: dateTime
        }
        handleChange(stop.id, updatedCompleted);
    }

    const handleNotifyChange = () => {
        const updatedNotify = {
            ...stop,
            metadata: {
                ...stop.metadata,
                suppressDeliveryNotification: !stop.metadata?.suppressDeliveryNotification,
            },
        }
        handleChange(stop.id, updatedNotify);
    }

    return (
        <>
            <StopRow>
                <ListItemText primary={stop.id}/>
                <ListItemText primary={STOP_TYPES()[stop.type].label}/>
                <ListItemText primary={STOP_STATUSES()[stop.status].label}/>
                <ListItemText primary={site ? toSiteDisplayName(site.name, site.description) : ''} />
                <ListItemText primary={stop.quantity}/>
                <ListItemText primary={stop.orderNumber}/>
                <AgisticsDateTimePicker
                    onChange={(date: string | null) => {
                        if (date) {
                            handleCompletedChange(date)
                        }
                    }}
                    disabled={stopIsComplete}
                    value={stop.completedAt} />
                {!stopIsComplete && <Checkbox checked={!stop.metadata?.suppressDeliveryNotification} onChange={handleNotifyChange} />}
            </StopRow>
        </>
    );
}

const setManuallyCompleteLoadsDefaults = (completableLoads: Load[]) => {
    const withDefaults = completableLoads.map(completableLoad => {
        return {
            ...completableLoad,
            stops: completableLoad.stops.map(stop => {
                const stopIsComplete = stop.status === StopStatus.Complete;

                if (stopIsComplete) {
                    return stop;
                } else {
                    return {
                        ...stop,
                        completedAt: localNow().seconds(0).milliseconds(0).toISOString(),
                        metadata: {
                            ...stop.metadata,
                            suppressDeliveryNotification: true,
                        },
                    }
                }
            }),
        }
    });

    return withDefaults;
}

interface ManuallyCompleteModalProps {
    completableLoads: Load[];
    onSubmit: () => void;
}

export const ManuallyCompleteModal: React.FC<ManuallyCompleteModalProps> = (props) => {
    const { completableLoads, onSubmit } = props;
    const [manuallyCompleteLoads, setManuallyCompleteLoads] = React.useState(setManuallyCompleteLoadsDefaults(completableLoads));
    const { mutate: manuallyComplete } = useManuallyCompleteLoads();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const loadsToComplete = manuallyCompleteLoads.map(x => ({
            id: x.id,
            stops: x.stops.map(s => ({
                id: s.id,
                metadata: s.metadata,
                completedAt: s.completedAt,
            }))
        }));
        manuallyComplete({ loads: loadsToComplete });
        onSubmit();
    };

    const handleChange = (stopId: number, updatedStop: Stop) => {
        const updatedLoads = manuallyCompleteLoads.map(load => {
            return {
                ...load,
                stops: load.stops.map(stop => {
                    if (stop.id === stopId) {
                        return updatedStop;
                    } else {
                        return stop
                    }
                })
            }
        });
        setManuallyCompleteLoads(updatedLoads);
    }

    if (manuallyCompleteLoads.length) {
        return (
            <>
                <form id={'manually-complete-loads'} onSubmit={(e) => handleSubmit(e)}>
                    <List>
                        {manuallyCompleteLoads.map((load, i) => {
                            return (
                                <ManuallyCompleteOrderItem key={i} order={load} handleChange={handleChange} />
                            )
                        })}
                    </List>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <Button type='submit'
                                title='Save'
                                variant='contained'
                                color='primary'>{manuallyCompleteLoads.length > 1 ? L.manuallyCompleteLoads() : L.manuallyCompleteLoad()}</Button>
                    </div>
                </form>
            </>
        );
    } else {
        return null;
    }
};
