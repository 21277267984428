import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

interface LoadingButtonProps extends ButtonProps {
    isLoading?: boolean;
    icon?: React.ReactNode
}

const LoadingButton: React.FC<LoadingButtonProps> = (props) => {
    const {children, isLoading, icon, ...rest} = props;

    return (
        <Button {...rest} disabled={isLoading || rest.disabled} startIcon={isLoading ? <CircularProgress size={'1em'}/> : icon}>
            {children}
        </Button>
    )
};

export default LoadingButton;
