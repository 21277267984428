import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions, QueryKeys } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { CargoType } from '../../../types';

function editCompanyCargoConfig(companyId: number) {
    return async (options: { cargoTypes: CargoType[] }) => {
        const cargoTypes = options.cargoTypes;

        return (await axios.put(`${API_BASE}/api/organizations/${companyId}/cargo-config`, cargoTypes.map(x => x.id))).data;
    };
}

export const useEditOrganizationCargoConfig = (companyId: number) => {
    const snackbar = useSnackbar();
    const key = QueryKeys.companies;
    const queryClient = useQueryClient();

    return useMutation(editCompanyCargoConfig(companyId), {
        ...mutationOptions([key], queryClient),
        onSuccess: (data) => {
            snackbar(L.updatedMessage(data.name));
        }
    });
};
