import React from 'react';
import { DeviceLocation } from '../../../types';
import axios from 'axios';

export const usePastLoadLocations = (deviceLocation: DeviceLocation | undefined) => {
    const [pastLoadLocations, setPastLoadLocations] = React.useState<DeviceLocation[]>([]);

    React.useEffect(() => {
        if (deviceLocation && deviceLocation.orderId) {
            const fetchFunc = async () => {
                const loadId = deviceLocation.orderId;
                const response = await axios.get<DeviceLocation[]>(`${API_BASE}/api/order/${loadId}/devicelocations`);
                setPastLoadLocations(response.data);
            }

            fetchFunc();
        } else {
            // set back to empty array so the unload useEffect in HereMapRoute removes line from map for us
            setPastLoadLocations([]);
        }
    }, [deviceLocation]);

    return pastLoadLocations;
};
