import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions, OrgQueryKeys, QueryKeys } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { Organization } from '../../../types';

async function editCompany(options: { company: Organization }) {
    const company = options.company;

    return (await axios.put(`${API_BASE}/api/organizations/${company.id}`, company)).data;
}

export const useEditCompanyMutation = (companyId: number | null = null) => {
    const snackbar = useSnackbar();
    const key = [QueryKeys.companies];
    const queryClient = useQueryClient();

    companyId && key.push(`/${companyId}`);
    return useMutation(editCompany, {
        ...mutationOptions([key.join('')], queryClient),
        onSuccess: (data) => {
            if (companyId) {
                const throughputKey = OrgQueryKeys.resolve(companyId, OrgQueryKeys.throughput)
                queryClient.invalidateQueries([throughputKey], { refetchType: 'inactive'});
            }

            snackbar(L.updatedMessage(data.name));
        }
    });
};
