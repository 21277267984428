import { CarrierEditLoadOption, StopStatus, StopType } from 'harmony-constants';
import { L } from 'harmony-language';
import { type CarrierConfigurableLoadSetting, type Driver, type GrossWeightObject, type OrganizationCarrier, type Stop, type Tractor, type Trailer } from '../../../../types';

export const loadCellIsEditable = (propertyName: CarrierConfigurableLoadSetting, currentCarrier: OrganizationCarrier, currentUserIsCarrier: boolean): boolean => {
    const OwningOrgSettings = [CarrierEditLoadOption.Owning, CarrierEditLoadOption.Both];
    const CarrierSettings = [CarrierEditLoadOption.Carrier, CarrierEditLoadOption.Both];
    
    const currentSetting = currentCarrier[propertyName];
    return currentUserIsCarrier ? CarrierSettings.includes(currentSetting) : OwningOrgSettings.includes(currentSetting);
};

type ResourceOption = Tractor | Trailer | Driver

export const carrierResourceSettings = <T extends ResourceOption>(list: T[], currentUserIsCarrier: boolean, userOrgId: number, currentCarrier: OrganizationCarrier, propertyName: CarrierConfigurableLoadSetting) => {
    const currentSetting = currentCarrier[propertyName];
    const disabled = currentUserIsCarrier ? currentSetting === CarrierEditLoadOption.Owning : currentSetting === CarrierEditLoadOption.Carrier;

    let filteredList: T[] = [];
    if (disabled) {
        filteredList = list;
    } else {
        list.map(item => {
            // user can select resource from own org reguardless of any settings
            if (item.organizationId === userOrgId) {
                filteredList.push(item);
            }
            // if both - owning org can see just ASSIGNED carriers resources.  Carrier can see owning org resources
            if (currentSetting === CarrierEditLoadOption.Both) {
                if (currentUserIsCarrier) {
                    // show owning org resource to carrier
                    if (item.organizationId === currentCarrier.organizationId) {
                        filteredList.push(item);
                    }
                } else {
                    // show carrier resource to owning org
                    if (item.organizationId === currentCarrier.carrierOrganizationId) {
                        filteredList.push(item);
                    }
                }
            }
        });
    }

    return {
        resources: filteredList,
        isDisabled: disabled,
    }
}

// convertFromGramsDisplay is passed in to prevent inconsistent hook render issues
export function getGrossWeight(tractor: Tractor | undefined, trailer: Trailer | undefined, stops: Stop[] | undefined, convertFromGramsDisplay: (n: number) => string): GrossWeightObject {
    if (!tractor?.weight || !trailer?.weight || !stops) return null;

    const tareWeight = tractor.weight + trailer.weight;

    function createTooltip(netWeight?: number) {
        const netWeightString = netWeight !== undefined ? convertFromGramsDisplay(netWeight) : L.none();
        return `\n\n${L.tareWeight()}: ${convertFromGramsDisplay(tareWeight)}\n${L.netWeight()}: ${netWeightString}`;
    }

    const completedStops = stops.filter(stop => stop.status === StopStatus.Complete && stop.type !== StopType.Waypoint);
    if (!completedStops.length) return {
        grossWeight: convertFromGramsDisplay(tareWeight),
        tooltipText: createTooltip()
    };

    const hasCompleteActuals = completedStops.every(stop => stop.actualWeight);
    const hasCompleteScheduled = completedStops.every(stop => stop.weight);
    let netWeight: number;

    if (hasCompleteActuals) {
        const actualWeights = completedStops.map(stop => {
            return stop.type === StopType.Origin ? stop.actualWeight! : stop.actualWeight! * -1;
        });
        netWeight = actualWeights.reduce((prev, curr) => prev + curr);
    } else if (hasCompleteScheduled) {
        const scheduledWeights = completedStops.map(stop => {
            return stop.type === StopType.Origin ? stop.weight! : stop.weight! * -1
        });
        netWeight = scheduledWeights.reduce((prev, curr) => prev + curr);
    } else {
        return {
            grossWeight: `${L.grossWeightError()}*`,
            tooltipText: L.grossWeightErrorMessage() + createTooltip()
        }
    }

    return {
        grossWeight: convertFromGramsDisplay(tareWeight + netWeight),
        tooltipText: L.grossWeightWithScheduled() + createTooltip(netWeight)
    }
}