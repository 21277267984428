import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchWithTimeWindow, QueryKeys } from '../../api/config';
import { endOfDay, getWorkingWeek } from '../../utils/date-time-utils';
import { OrderGroup } from './order-groups/use-order-group-table';
import { deepEqual } from '../../utils/deep-equal';
import { AgisticsContext } from '../../app/agistics-context';
import { useProductionCardGroupsKey } from '../../api/config-hooks';

export interface OrderGroupCache {
    data: OrderGroup[];
    timeWindow: { from: string, to: string };
}

export const useProductionCardGroups = () => {
    const baseKey = QueryKeys.productionCardGroups;
    const queryKey = useProductionCardGroupsKey()
    const { dateFilters, updateDateFilters } = React.useContext(AgisticsContext);
    const dateFilter = dateFilters[baseKey];

    const query = useQuery<OrderGroupCache, string>(queryKey, async () => {
        let result: OrderGroupCache;
        if (dateFilter.from && dateFilter.to) {
            result = await fetchWithTimeWindow({ from: dateFilter.from, to: endOfDay(dateFilter.to).toISOString()})(baseKey);
        } else {
            const week0 = getWorkingWeek(0);
            result = await fetchWithTimeWindow({
                preset: week0.weekNumber,
                from: week0.sundayIso,
                to: week0.saturdayIso
            })(baseKey);
        }

        if (!deepEqual(result.timeWindow, dateFilter)) {
            updateDateFilters(baseKey, result.timeWindow);
        }

        return { data: result.data, timeWindow: result.timeWindow };
    }, {
        staleTime: 30 * 1000,
        cacheTime: 0,
        refetchOnWindowFocus: false,
    });

    return query;
};
