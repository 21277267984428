import axios from 'axios'
import { HERE_API_KEY, TRANSPORT_MODE } from './here-map-constants';
import { HerePoint } from '../../types';

const HERE_ROUTER_IMPORT = 'https://router.hereapi.com/v8/import';
const URL = HERE_ROUTER_IMPORT + `?apiKey=${HERE_API_KEY}&transportMode=${TRANSPORT_MODE}&return=polyline`;

// Problem - Here SDKs (JS / Native android/iOS) use GET requests under the hood
// while using the via / waypoint properties in the requests, they still cap out request length very fast.
// there is a POST, but it does not support passing via in the BODY of the request, so that does not help us.
// 414 Request-URI Too Large (error in native android when trying to use 400+ waypoints in requests)

// back to square 1, using import api endpoint
// trace can have 2-50000 items, or max 10MiB body

interface RouteImportRes {
    notices: string | null;
    polyline: string | null;
}

export const manualHereMapRouteImport = async (route: HerePoint[]): Promise<RouteImportRes> => {
    const data = {
        trace: route,
    }

    const res = await axios.post(URL, data, {
        withCredentials: false,
    });

    return {
        notices: res.data?.notices ? res.data.notices[0].title : null,
        polyline: res.data.routes.length ? res.data.routes[0].sections[0].polyline : null
    }
}
