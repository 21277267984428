import { ConfirmationDialog } from '../shared/confirmation-dialog';
import { L } from 'harmony-language';
import React from 'react';
import { usePublishDraftsMutation } from '../../api/mutations/use-publish-drafts-mutation';
import PropTypes from 'prop-types';

export const PublishDraftsModal = (props) => {
    const { records, unselectFn, onResultCallback } = props;
    const { mutate: publishDrafts } = usePublishDraftsMutation();

    return (
        <ConfirmationDialog
            open={records.length > 0}
            title={L.publishConfirmationTitle()}
            message={L.publishConfirmationMessage(`${records.length} ${L.drafts().toLocaleLowerCase()}`)}
            cancelText={L.cancel()}
            confirmText={L.publish()}
            resultCallback={async (confirmed) => {
                onResultCallback();
                if (confirmed) {
                    unselectFn?.();
                    publishDrafts({ drafts: records });
                }
            }}>
        </ConfirmationDialog>
    );
};

PublishDraftsModal.propTypes = {
    records: PropTypes.array,
    unselectFn: PropTypes.func,
    onResultCallback: PropTypes.func
};
