import React from 'react';
import { L } from 'harmony-language';
import PropTypes from 'prop-types';
import { Select } from './select';

const TIER_VALUES = {
    'Freemium': 0,
    'Standard': 1,
}

export const SelectTier = props => {
    const { tier, onChange } = props;
    const menuItems = Object.keys(TIER_VALUES).map(x => (
        {
            id: x, value: x, label: L[`tier${x}`]()
        }));
    const item = menuItems.find(x => x.value === tier);

    return (
        <Select
            {...props}
            label={L.selectTier()}
            list={menuItems}
            item={item}
            onChange={(v) => onChange(v.value)} />
    );
};

SelectTier.propTypes = {
    tier: PropTypes.string,
    onChange: PropTypes.func,
};
