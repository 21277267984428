import React from 'react';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { AgisticsCard } from '../shared/containers/agistics-card';
import Navigate from '@mui/icons-material/NavigateNext';

interface StyledProps {
    isDisabled: boolean;
}

const StyledLink = styled(Link, {
    shouldForwardProp: (prop) => prop !== 'isDisabled',
})<StyledProps>(({ isDisabled }) => ({
    textDecoration: 'none',
    ...(isDisabled && {
        pointerEvents: 'none',
    })
}));

const Wrapper = styled('div')<StyledProps>(({ isDisabled }) => ({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    justifyContent: 'space-between',
    ...(isDisabled && {
        opacity: '0.5'
    })
}));

const Info = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    '& b': {
        color: theme.palette.primary.main
    },
    '& span': {
        color: theme.palette.grey['600'],
        padding: '0.5rem 0 0.5rem 1rem'
    }
}));

interface LinkType {
    title: string
    description: string;
    to: string;
    isDisabled: boolean;
}

interface AdministrationCardProps {
    icon: string;
    links: LinkType[];
    title: string;
}

export const AdministrationCard: React.FC<AdministrationCardProps> = (props) => {
    const { icon, links = [], title } = props;

    if(links.length === 0){
        return null;
    }

    return (
        <AgisticsCard icon={icon} title={title}>
            {links.map(l => {
                return (
                    <StyledLink isDisabled={l.isDisabled} key={l.to} to={l.to}>
                        <Wrapper isDisabled={l.isDisabled }>
                            <Info>
                                <b>{l.title}</b>
                                <span>{l.description}</span>
                            </Info>
                            <Navigate color='primary' fontSize='large'/>
                        </Wrapper>
                    </StyledLink>
                );
            })}
        </AgisticsCard>
    );
};
