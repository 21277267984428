import React from 'react';
import TextField from '@mui/material/TextField';
import { L } from 'harmony-language';
import { EitherOrganizationLocation } from './edit-create-location';

type DescriptionInputProps = {
    organizationLocation: EitherOrganizationLocation,
    setOrganizationLocation: (e: EitherOrganizationLocation) => void;
}

export const DescriptionInput: React.FC<DescriptionInputProps> = (props) => {
    const { organizationLocation, setOrganizationLocation } = props;

    return (
        <TextField
            variant='standard'
            label={L.description()}
            id='site-description'
            value={organizationLocation.description || ''}
            style={{ width: '100%' }}
            onChange={(e) => {
                setOrganizationLocation({ ...organizationLocation, description: e.target.value });
            }}
            inputProps={{ maxLength: 200 }}
        />
    );
};
