import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { L } from 'harmony-language';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { CreateOrganizationSubLocation, OrganizationSubLocation } from '../../../../types';

const renderSubSite = (
    subSite: Sublocation,
    index: number,
    handleChange: (value: Sublocation, index: number) => void,
    handleDelete: (index: number) => void,
) => {
    return (
        <ListItem dense key={index} style={{ alignItems: 'flex-end' }}>
            <TextField
                variant='standard'
                label={L.name()}
                inputProps={{
                    'aria-label': L.name()
                }}
                id={`subsite-name-${1}`}
                style={{ width: '100%' }}
                value={subSite.name || ''}
                onChange={(e) => {
                    handleChange({ ...subSite, name: e.target.value }, index);
                }} />
            <Tooltip title={L.delete()}>
                <IconButton onClick={() => handleDelete(index)} size='small'>
                    <DeleteIcon/>
                </IconButton>
            </Tooltip>
        </ListItem>
    );
};

const renderAddSubSite = (handleAdd: () => void) => {
    return (
        <ListItem dense>
            <ListItemText primary={L.subLocationAddPrompt()}/>
            <Tooltip title={L.add()}>
                <IconButton onClick={handleAdd} size='small'>
                    <AddIcon/>
                </IconButton>
            </Tooltip>
        </ListItem>
    );
};

type Sublocation = (OrganizationSubLocation | CreateOrganizationSubLocation);
type Sublocations = (OrganizationSubLocation | CreateOrganizationSubLocation)[];

interface EditLocationSubSitesProps {
    subSites: Sublocations;
    onChange: (s: Sublocations) => void;
}

export const EditLocationSubSites: React.FC<EditLocationSubSitesProps> = (props) => {
    const { subSites = [], onChange } = props;

    const handleChange = (value: Sublocation, index: number) => {
        const changed = [...subSites];
        changed[index] = value;
        onChange(changed);
    };

    const handleDelete = (index: number) => {
        onChange(subSites.filter((_, i) => i !== index));
    };

    const handleAdd = () => {
        const newSublocation: CreateOrganizationSubLocation = {
            name: '',
            customGeofence: null,
        }

        onChange([...subSites, newSublocation]);
    };

    const toRowItem = (subSite: Sublocation, i: number) => {
        return renderSubSite(subSite, i, handleChange, handleDelete);
    };

    return (
        <div data-testid='location-subsites'>
            <Typography variant='subtitle1'>{L.subLocations()}</Typography>
            <List>
                {subSites.map(toRowItem)}
                {renderAddSubSite(handleAdd)}
            </List>
        </div>
    );
};
