import React from 'react';
import { Load } from '../../../../types';

export type LoadSelection = {
    selectedLoads: Load[];
    toggle: (Load: Load) => void;
    toggleAll: () => void;
    clearAll: () => void;
    allSelected: boolean;
    indeterminate: boolean;
    isSelected: (load: Load) => boolean;
}

export const useLoadSelection = (filteredData: Load[]): LoadSelection => {
    const [selectedLoads, setSelectedLoads] = React.useState<Load[]>([]);

    const someSelected = Boolean(selectedLoads.length)
    const allSelected = someSelected && filteredData.every(x => selectedLoads.some(f => f.id === x.id));
    const indeterminate = someSelected && !allSelected;

    React.useEffect(() => {
        setSelectedLoads((current) => {
            return filteredData.filter(x => current.some(f => x.id === f.id));
        })
    }, [filteredData]);

    const toggle = React.useCallback((load: Load) => {
        setSelectedLoads((current) => {
            const index = current.findIndex(x => x.id === load.id);

            if (index > -1) {
                current.splice(index, 1);
            } else {
                current.push(load);
            }
            return [...current];
        })
    }, []);

    const toggleAll = React.useCallback(() => {
        if (allSelected) {
            setSelectedLoads([]);
        } else {
            setSelectedLoads([...filteredData])
        }
    }, [filteredData, allSelected]);

    const clearAll = React.useCallback(() => {
            setSelectedLoads([]);
    }, []);


    const isSelected = React.useCallback((load: Load) => {
        return selectedLoads.some(x => load.id === x.id);
    }, [selectedLoads]);


    return { selectedLoads, toggle, toggleAll, clearAll, allSelected, indeterminate, isSelected }
};
