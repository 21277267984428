import { styled, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ResourceType } from "harmony-constants";
import { L } from "harmony-language";
import React from "react";
import { type ChecklistTemplate, type ChecklistTemplateTemplate } from "../../../types";

export interface EditCreateChecklistTemplateProps {
    existingChecklistTemplate: ChecklistTemplate;
    onSubmit: (submittedChecklistTemplate: ChecklistTemplate) => void;
}

export type AllowRequireOff = 'allow' | 'require' | 'off';

export enum ReadingsKey {
    TRUCK_ENGINE = 'checklistItemEngineHours',
    TRAILER_ENGINE_1 = 'checklistItemEngine1Hours',
    TRAILER_ENGINE_2 = 'checklistItemEngine2Hours',
    ODOMETER = 'odometerReading'
}

export const GridContainer = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gridColumnGap: '1rem'
});

export const ToggleButtonGroupContainer = styled('div')({
    paddingTop: '8px',
    paddingBottom: '8px'
});

export const Footer = styled('div')({
    gridColumn: '2',
    display: 'flex',
    justifyContent: 'flex-end'
});

export const TextInput = (props: {label: string, value: unknown, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>}) => (
    <TextField
        label={props.label}
        variant='standard'
        required
        value={props.value}
        onChange={props.onChange}
        style={{
            padding: '.25rem 0',
            width: '100%'
        }}
    />
);

export const TripletToggleGroup = (props: {
    value: any,
    onChange: (event: React.MouseEvent<HTMLElement>, value: any) => void,
    label: string
}) => (
    <ToggleButtonGroupContainer>
        <Typography>{props.label}</Typography>
        <ToggleButtonGroup
            color="primary"
            value={props.value}
            exclusive
            onChange={props.onChange}
        >
            <ToggleButton value='allow'>{L.allow()}</ToggleButton>
            <ToggleButton value='require'>{L.require()}</ToggleButton>
            <ToggleButton value='off'>{L.off()}</ToggleButton>
        </ToggleButtonGroup>
    </ToggleButtonGroupContainer>
);

// Put an empty string in the default array so an empty checklist item is prompted intially
const defaultChecklistTemplate: ChecklistTemplateTemplate[] = [{default: ['']}];

export function newChecklistTemplate(resourceType: ResourceType) {
    return {
        name: '',
        resourceType: resourceType,
        timeToExpirationMinutes: '',
        template: defaultChecklistTemplate,
        default: false,
        requireOnUserSwitch: false,
        validResponses: 15,
    }
}