import React from 'react';
import { OrganizationLocation, Resource } from '../../../types';
import { stopToLocationInfo } from '../hooks/use-location-info';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { useLocationTypes } from '../../../api/queries/use-location-types';
import { useLoadContext } from '../load-context';
import { useGetBookingConflict } from './use-booking-conflict';
import { useGetDowntimeConflict } from './use-downtime-conflict';
import { useGetLocationTypeConflict } from './use-location-type-conflict';
import { useGetCargoTypeConflict } from './use-cargo-type-conflict';
import { conflictMapper } from './resource-conflict-utils';
import { validateDraft } from '../../drafts/utils/validate-drafts';
import { L } from 'harmony-language';
import { useFeatures } from '../../user/selectors/use-permissions';
import { Features } from 'harmony-constants';
import { useResourceBookings } from '../../bookings/use-resource-bookings';
import { useResourceDowntimes } from '../../downtime/use-resource-downtimes';
import { useWeights } from '../hooks/use-weights';

export const useLoadConflict = (isDraft: boolean): React.ReactNode => {
    const { load, disableConflicts } = useLoadContext();
    const hasDowntimeAccess = useFeatures(Features.Downtime);
    const { data: allBookings, isLoading: isBookingLoading } = useResourceBookings();
    const { data: allDowntimes = [], isLoading: isDowntimeLoading } = useResourceDowntimes({enabled: hasDowntimeAccess && !disableConflicts});
    const { data: locations = [], isLoading: isLoadingLocations } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { data: locationTypes = [], isLoading: isLoadingLocationTypes } = useLocationTypes();
    const { convertFromGrams } = useWeights();

    const isLoading = isBookingLoading || isDowntimeLoading || isLoadingLocations || isLoadingLocationTypes;

    return React.useMemo(() => {
        if (disableConflicts || isLoading) {
            return null;
        }

        const loadConflicts = []
        if (isDraft) {
            loadConflicts.push(...validateDraft(load, convertFromGrams).map(e => <div key={e}>{e}</div>))
            //we are not going show LOAD level resouce conflicts on drafts page, only show validation errors
            if (loadConflicts.length) {
                return loadConflicts;
            }
            return null;
        }

        const hasResourceConflict = load.stops.some(stop => {
            const {cargoTypeIds} = stopToLocationInfo(locations || [], locationTypes)(stop);
            let hasLocationConflict = false;
            if (stop.organizationLocationId) {
                const location: Resource = {
                    id: stop.organizationLocationId,
                    type: 'location',
                    cargoTypeIds,
                    stop
                }
                const bookingConflict = useGetBookingConflict(load, allBookings, location);
                const downtimeConflict = useGetDowntimeConflict(load, allDowntimes, location)
                const locationTypeConflict = useGetLocationTypeConflict(locations, locationTypes, location);
                const cargoTypeConflict = useGetCargoTypeConflict(location);

                const conflicts = conflictMapper(bookingConflict, downtimeConflict, locationTypeConflict, cargoTypeConflict);
                hasLocationConflict = conflicts.some(x => Boolean(x));
            }
            let hasLoadingTeamConflict = false;
            if (stop.loadingTeamId) {
                const loadingTeam: Resource = {
                    id: stop.loadingTeamId,
                    type: 'loadingTeam',
                    stop
                }
                const bookingConflict = useGetBookingConflict(load, allBookings, loadingTeam);
                const downtimeConflict = useGetDowntimeConflict(load, allDowntimes, loadingTeam)

                const conflicts = conflictMapper(bookingConflict, downtimeConflict);
                hasLoadingTeamConflict = conflicts.some(x => Boolean(x));
            }

            return hasLocationConflict
                || hasLoadingTeamConflict
        })

        if (hasResourceConflict) {
            loadConflicts.push(<div key={L.conflictResource()}>{L.conflictResource()}</div>)
        }

        if (loadConflicts.length) {
            return loadConflicts
        }
        return null;
    }, [isDraft, load, locations, locationTypes, isLoading]);
}
