import { Button, Checkbox, List, ListItem, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DictionaryKey, L } from 'harmony-language';
import React from 'react';
import { OrgQueryKeys } from '../../api/config';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { type Checklist, type ChecklistGroupItem, type ChecklistReadingItem, type User } from '../../types';
import { localDateTimeDisplay } from '../../utils/date-time-utils';
import { numberWithCommas } from '../../utils/number-utils';
import { CircularLoading } from '../shared/circular-loading';
import { Content } from '../shared/containers/content';
import { isChecklistItemResolved } from './checklist-utils';
import { useChecklistEntry } from './use-checklist-entry';
import { TractorTrailerResourceWithType } from './use-maintenance-columns';

const SummaryContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '1rem'
});

const SummaryColumn = styled('div')({
    flex: '0 38%',
    '&>div': {
        display: 'flex',
        justifyContent: 'space-between'
    },
    '&>div>div:first-child': {
        fontWeight: 'bold'
    }
});

const ListItemInfo = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 14
});

const ListItemCheckbox = styled('div')({
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    '& span:first-child': {
        padding: 0
    }
});

const ItemResponse = styled('div')({
    flex: 3
});

interface ItemNotesProps {
    checked: boolean;
}

const ItemNotes = styled('div', {
    shouldForwardProp: (prop) => prop !== 'checked',
})<ItemNotesProps>(({ checked }) => ({
    fontStyle: 'italic',
    flex: 6,
    ...(checked && {
        textDecoration: 'line-through',
        textColor: '#999',
    })
}));

const SubmitButton = styled(Button)({
    float: 'right',
    margin: '1rem 0',
    minWidth: '6vw',
});

const ButtonContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
});

interface ChecklistEntryProps {
    resource: TractorTrailerResourceWithType;
    resourceType: TractorTrailerResourceWithType['type'];
    onSubmit: () => void;
    isHistoricChecklist: boolean;
    existingChecklist?: Checklist;
}

export const ChecklistEntry: React.FC<ChecklistEntryProps> = (props) => {
    const { resource, resourceType, onSubmit, isHistoricChecklist, existingChecklist } = props;

    const {
        checklistItemChanged,
        notesChanged,
        notes,
        handleSubmit,
        isLoading: isChecklistLoading,
        viewOnlyMode,
        checklistHasChanged,
        originalChecklist,
        resolvedList
    } = useChecklistEntry({
        resource,
        resourceType,
        onSubmit,
        existingChecklist
    });

    const { data: users, isLoading: isLoadingUsers } = useOrganizationQuery<
        User[]
    >(OrgQueryKeys.users);

    const user = originalChecklist?.completedByUserId
        ? users?.find((x) => x.id === originalChecklist?.completedByUserId)
        : null;
    const isLoading = isChecklistLoading || isLoadingUsers;

    return (
        <div data-testid='checklist-entry'>
            {isLoading && <CircularLoading />}
            <form
                id='create-checklist-entry'
                name='create-checklist-entry'
                // @ts-ignore
                onSubmit={handleSubmit}
            >
                <div
                    style={{
                        paddingTop: '1vh',
                        width: '40vw'
                    }}
                >
                    <SummaryContainer>
                        <SummaryColumn>
                            <div>
                                <div>{L.type()}:</div>
                                <div>{originalChecklist?.type}</div>
                            </div>
                            <div>
                                <div>
                                    {L[
                                        resourceType?.toLowerCase() as DictionaryKey
                                    ]()}
                                    :
                                </div>
                                <div>{resource.userDisplayName}</div>
                            </div>
                            {originalChecklist?.checklist?.odometerReading && (
                                <div>
                                    <div>{L.odometerReading()}:</div>
                                    <div>
                                        {numberWithCommas(
                                            originalChecklist?.checklist
                                                .odometerReading
                                        )}
                                    </div>
                                </div>
                            )}
                            {!!originalChecklist?.checklist?.readings?.length &&
                                originalChecklist.checklist.readings.map((reading: ChecklistReadingItem) => (
                                    <div key={reading.key}>
                                        <div>{`${L[reading.key as DictionaryKey]()}:`}</div>
                                        <div>
                                            {numberWithCommas(
                                                reading.value
                                            )}
                                        </div>
                                    </div>
                                ))
                            }
                        </SummaryColumn>
                        <SummaryColumn>
                            {user && (
                                <div>
                                    <div>{L.completedBy()}:</div>
                                    <div>{`${user.firstName} ${user.lastName}`}</div>
                                </div>
                            )}
                            <div>
                                <div>{L.date()}:</div>
                                <div>
                                    {localDateTimeDisplay(
                                        originalChecklist?.updatedAt
                                    )}
                                </div>
                            </div>
                        </SummaryColumn>
                    </SummaryContainer>
                    <Content>
                        {originalChecklist?.checklist?.notes && (
                            <div>
                                <div style={{ fontWeight: 'bold' }}>{L.notes()}:</div>
                                <div style={{ fontStyle: 'italic' }}>
                                    {originalChecklist?.checklist?.notes}
                                </div>
                            </div>
                        )}
                    </Content>
                    <List>
                        {originalChecklist?.checklist?.groups?.[0]?.items?.map(
                            (item: ChecklistGroupItem, i: number) => {
                                const isItemResolved = isChecklistItemResolved(
                                    item.response
                                );
                                const isChecked =
                                    resolvedList.includes(item.key) ||
                                    isItemResolved;
                                const itemLabel =
                                    L[item.key as DictionaryKey]();
                                const itemResponse =
                                    L[`checklistResponse${item.response}`]();

                                return (
                                    <ListItem
                                        key={i}
                                        sx={{ padding: '1rem' }}
                                    >
                                        <ListItemInfo>
                                            <div style={{ flex: 6 }}>
                                                {itemLabel}
                                            </div>
                                            <ItemResponse>
                                                {isChecked ?  L.checklistResponseOK() : itemResponse}
                                            </ItemResponse>
                                            {isChecked || isHistoricChecklist ?
                                            <ItemNotes checked={isChecked}>
                                                {item.notes}
                                            </ItemNotes>
                                            :
                                            <TextField
                                                variant='standard'
                                                sx={{ fontStyle: 'italic', flex: 6}}
                                                onChange={notesChanged(item.key)}
                                                value={notes?.[item.key]} />
                                            }
                                        </ListItemInfo>
                                        <ListItemCheckbox>
                                            <Checkbox
                                                checked={isChecked}
                                                disabled={
                                                    viewOnlyMode ||
                                                    isItemResolved ||
                                                    isHistoricChecklist
                                                }
                                                title={itemLabel}
                                                onChange={() => {
                                                    checklistItemChanged(
                                                        item.key
                                                    );
                                                }}
                                            ></Checkbox>
                                        </ListItemCheckbox>
                                    </ListItem>
                                );
                            }
                        )}
                    </List>
                    {!viewOnlyMode && !isHistoricChecklist && (
                        <ButtonContainer>
                            <SubmitButton
                                type='submit'
                                disabled={isLoading || !checklistHasChanged}
                                variant='contained'
                                color='primary'
                            >
                                {L.checklistResolve()}
                            </SubmitButton>
                        </ButtonContainer>
                    )}
                </div>
            </form>
        </div>
    );
};
