import React, { FormEvent } from 'react';
import '../../../../scss/location.scss';
import { getCurrentUnitSystem, L, UNITS } from 'harmony-language';
import { EditCreateLocationForm } from './edit-create-location-form';
import { LocationMap } from './location-map';
import Button from '@mui/material/Button';
import { OrgQueryKeys } from '../../../../api/config';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { SITE_CATEGORIES, SiteManager } from '../../../../constants/constants';
import { SnackbarTypes, useSnackbar } from '../../../notifications/use-snackbar';
import Grid from '@mui/material/Grid';
import { filterByRole } from '../../../user/utils/user-mappings';
import { ResourceEditCreateContainer } from '../../../shared/containers/resource-edit-create-container';
import { useLocationTypes } from '../../../../api/queries/use-location-types';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import useOrgLocationImages from '../../../../api/queries/use-org-location-images';
import { useOrgLocationThroughputs } from '../../../../api/queries/use-org-location-throughputs';
import { CreateOrganizationLocation, Customer, LoadingTeam, OrganizationLocation, Point, User } from '../../../../types';
import { HereMapRefObject } from '../../../here-maps/here-map';

export type EitherOrganizationLocation = OrganizationLocation | CreateOrganizationLocation;

const MAX_MILES_BETWEEN_SITE_AND_GEOZONE = 100;
const MAX_KILOM_BETWEEN_SITE_AND_GEOZONE = 160.9;

export const getMaxDistanceByUnit = () => {
    return getCurrentUnitSystem() === UNITS.Metric ? MAX_KILOM_BETWEEN_SITE_AND_GEOZONE : MAX_MILES_BETWEEN_SITE_AND_GEOZONE;
};

interface EditCreateLocationProps {
    existingOrganizationLocation: OrganizationLocation | null;
    companyId: number;
    addLocation: UseMutateAsyncFunction<OrganizationLocation, any, CreateOrganizationLocation, unknown>;
    editLocation: UseMutateAsyncFunction<OrganizationLocation, any, OrganizationLocation, unknown>;
    closeModal: () => void;
}

export const EditCreateLocation: React.FC<EditCreateLocationProps> = (props) => {
    const { existingOrganizationLocation, companyId, addLocation, editLocation, closeModal } = props;
    const [organizationLocation, setOrganizationLocation] = React.useState<EitherOrganizationLocation>(existingOrganizationLocation || {
        organizationId: companyId,
        name: '',
        // this should probably be rewoked, but will involve reworking select input, maybe later
        organizationLocationTypeId: 1,
        // orgLocationType: {
        //     id: 1,
        //     category: SITE_CATEGORIES.SITE,
        // },
        loadingTeamId: null,
        hasScale: false,
        orgSubLocations: [],
        customGeofence: {
            latitude: 1,
            longitude: 1,
            radius: null,
            bbox: {
                topLeft: {
                    latitude: 1,
                    longitude: 1,
                },
                bottomRight: {
                    latitude: 1,
                    longitude: 1,
                }
            }
        },
        users: [],
        customerId: null,
        description: null,
        enabled: true,
        premiseId: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        postalCode: null,
        country: null,
    });

    const { images = [], onLocationSubmit, onDroppedFiles, removeImage, isLoading: isImagesLoading } = useOrgLocationImages({ organizationLocation: existingOrganizationLocation });
    const { throughputs = [], onThroughputSubmit, setThroughputs} = useOrgLocationThroughputs(existingOrganizationLocation);

    const snackbar = useSnackbar();

    const { isLoading: isLoadingLocationTypes } = useLocationTypes();
    const { data: loadingTeams = [], isLoading: isLoadingLoadingTeams } = useOrganizationQuery<LoadingTeam[]>(OrgQueryKeys.loadingTeams);
    const { data: customers = [], isLoading: isLoadingCustomers } = useOrganizationQuery<Customer[]>(OrgQueryKeys.customers);
    const { data: users = [], isLoading: isLoadingUsers } = useOrganizationQuery<User[]>(OrgQueryKeys.users);
    const siteManagers = React.useMemo(() => {
        const filtered = filterByRole(users, SiteManager) || [];
        const sorted = filtered.sort((a, b) => a.name.localeCompare(b.name));
        return sorted;
    }, [users]);
    const mapRef = React.useRef<HereMapRefObject>(null);
    const [helperMarker, setHelperMarker] = React.useState<Point | null>(null);
    const isLoading = isLoadingLocationTypes || isLoadingLoadingTeams || isLoadingUsers || isLoadingCustomers || isImagesLoading;

    const submitForm = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (organizationLocation.customGeofence.latitude === 1) {
            snackbar(L.warningCreateGeozone(), SnackbarTypes.warning);
            return;
        }

        if (organizationLocation.orgLocationType && organizationLocation.orgLocationType.category !== SITE_CATEGORIES.SITE) {
            organizationLocation.loadingTeamId = null;
            organizationLocation.users = [];
            organizationLocation.premiseId = null;
        }

        let result;
        if ('id' in organizationLocation) {
            result = await editLocation(organizationLocation);
        } else {
            result = await addLocation(organizationLocation);
        }
        await onLocationSubmit(result);
        await onThroughputSubmit('id' in organizationLocation ? organizationLocation.id : result.id);
        closeModal();
    };

    return (
        <ResourceEditCreateContainer>
            <form id='edit-create-location-form' name='edit-create-location-form' onSubmit={submitForm}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={3} lg={4}>
                        <EditCreateLocationForm
                            mapRef={mapRef}
                            isLoading={isLoading}
                            organizationLocation={organizationLocation}
                            loadingTeams={loadingTeams}
                            customers={customers}
                            setHelperMarker={setHelperMarker}
                            setOrganizationLocation={setOrganizationLocation}
                            siteManagers={siteManagers}
                            images={images}
                            throughputs={throughputs}
                            setThroughputs={setThroughputs}
                            onDelete={removeImage}
                            onDroppedFiles={onDroppedFiles}
                        />
                    </Grid>
                    <Grid item xs={12} md={9} lg={8}>
                        <LocationMap
                            mapRef={mapRef}
                            organizationLocation={organizationLocation}
                            setOrganizationLocation={setOrganizationLocation}
                            helperMarker={helperMarker}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type='submit'
                            title={L.save()}
                            disabled={organizationLocation.customGeofence.latitude === 1}
                            variant='contained'
                            color='primary'
                        >
                            {existingOrganizationLocation ? L.updateLocation() : L.createLocation()}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </ResourceEditCreateContainer>
    );
};
