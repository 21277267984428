import React from 'react';
import { L } from 'harmony-language';
import { localDateTimeDisplay } from '../../../utils/date-time-utils';
import { createColumnHelper } from '@tanstack/react-table';
import { ServiceAccount } from '../../../types';

export const useServiceAccountsColumns = () => {
    const columnHelper = createColumnHelper<ServiceAccount>();

    const columns = React.useMemo(() => {
        const c = [
            columnHelper.accessor('name', {
                header: L.name(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
            }),
            columnHelper.accessor('createdAt', {
                header: L.createdAt(),
                cell: (info) => localDateTimeDisplay(info.getValue()),
                enableColumnFilter: false,
            }),
        ];

        return c;
    }, []);

    return columns;
};
