import { Load } from '../../../types';
import axios from 'axios';
import { useSnackbar } from '../../notifications/use-snackbar';
import { useBool } from '../../shared/hooks/use-bool';
import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../../../api/config';
import { L } from 'harmony-language';

type PreviewState = {
    isLoading: boolean,
    drafts: Load[],
    setDrafts: (newDrafts: Load[]) => void
    trailerProfile: { maxCargoWeight: number, compartmentCount: number} | null,
    generate: (drafts: Load[]) => Promise<any>;
}

export type DistributionMode = 'greedy' | 'equitable';

export const useProductionPreview = (mode: DistributionMode, productionCardIds: number[]): PreviewState => {
    const snackbar = useSnackbar();

    const [drafts, setDrafts] = React.useState<Load[]>([]);
    const [trailerProfile, setTrailerProfile] = React.useState<{ maxCargoWeight: number, compartmentCount: number} | null>(null);
    const [isLoading, { off, on }] = useBool(false);
    const queryClient = useQueryClient();

    React.useEffect(() => {
        async function fetch() {
            on();
            const { drafts, trailerProfile: profile} = await preview(mode);
            setDrafts(drafts);
            setTrailerProfile(profile);
            off();
        }

        fetch();
    }, [mode]);

    const preview = async (mode: DistributionMode) => {
        const response = await axios.get(`${API_BASE}/api/production-cards/group-preview?distributionMode=${mode}&ids=[${productionCardIds}]`);
        return response.data;
    };

    const generate = async (drafts: Load[]) => {
        on();
        const response = await axios.post(`${API_BASE}/api/production-cards/groups`, { drafts, productionCardIds });
        queryClient.invalidateQueries([QueryKeys.productionCards]);
        queryClient.invalidateQueries([QueryKeys.productionCardGroups]);
        off();
        snackbar(L.productionPlanningGroupCreateSuccess(`${drafts.length}`));
        return response.data;
    };

    return {
        isLoading,
        drafts,
        setDrafts,
        trailerProfile,
        generate,
    };
};
