import React from 'react';
import { DynamicQueryKeys, QueryKeys } from '../../../api/config';
import { L } from 'harmony-language';
import { useStaticQuery } from '../../../api/queries/use-static-query';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import Skeleton from "@mui/material/Skeleton";
import List from '@mui/material/List';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { AdminBreadCrumbHeader } from '../admin-bread-crumb-header';
import { Content } from '../../shared/containers/content';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import { PermissionsManagementItem } from './permissions-management-item';

const SkeletonList = () => {
    return (
        <>
            {Array.from(Array(7), (x, i) => i).map((x) => (
                <ListItem dense key={x} data-testid={`loading-${x}`}>
                    <Skeleton width={'100%'}/>
                </ListItem>
            ))}
        </>
    );
};

const PermissionAccordion = (props) => {
    const { role, currentPermissions, isLoading, allPermissions } = props;

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls={`role-${role.id}-content`}
                id={`role-${role.id}-header`}
            >
                <Typography>{role.role}</Typography>
            </AccordionSummary>
            <Divider/>
            <AccordionDetails sx={{ display: 'flex' }} data-testid={`${role.id}-permissions`}>
                <List component='div' disablePadding>
                    {
                        isLoading ?
                            <List data-testid={`loading-role-${role.id}`}>
                                <SkeletonList />
                            </List>
                            :
                            allPermissions?.map(permission => {
                                return (
                                    <PermissionsManagementItem
                                        key={permission.id}
                                        permission={permission}
                                        currentPermissions={currentPermissions}
                                        roleId={role.id} />
                                );
                            })
                    }
                </List>
            </AccordionDetails>
        </Accordion>
    );
};

PermissionAccordion.propTypes = {
    role: PropTypes.object,
    currentPermissions: PropTypes.array,
    isLoading: PropTypes.bool,
    allPermissions: PropTypes.array,
};

const RolePermissionAccordion = (props) => {
    const { role, allPermissions } = props;
    const { data: currentPermissions, isLoading } = useStaticQuery(DynamicQueryKeys.rolePermissions(role.id));

    return <PermissionAccordion allPermissions={allPermissions} role={role} currentPermissions={currentPermissions}
        isLoading={isLoading}/>;
};

RolePermissionAccordion.propTypes = {
    role: PropTypes.object,
    allPermissions: PropTypes.array,
};

export const PermissionsManagement = () => {
    const { data: allPermissions, isLoading: loadingAllPermissions } = useStaticQuery(QueryKeys.permissions);
    const { data: allRoles, isLoading: loadingAllRoles } = useStaticQuery(QueryKeys.roles);
    const isLoading = loadingAllPermissions || loadingAllRoles;

    return (
        <>
            <AdminBreadCrumbHeader hideResourceDropDown hideCompanyDropDown/>
            <Content>
                <Typography variant='subtitle1'>
                    {L.viewPermissionsForRoles()}
                </Typography>
                {isLoading ?
                    <List role={'loading'}>
                        <SkeletonList/>
                    </List>
                    :
                    <>
                        {allRoles?.map(role => {
                            return (
                                <RolePermissionAccordion
                                    key={role.id}
                                    allPermissions={allPermissions}
                                    role={role}/>
                            );
                        })}
                    </>
                }
            </Content>
        </>
    );
};
