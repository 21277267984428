import React from 'react';
import { L } from 'harmony-language';
import { useBoolean } from '../shared/hooks/use-boolean';
import { ModalDialog } from '../shared/containers/modal-dialog';
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../api/config';
import { useUpdateUserPreferences } from '../shared/hooks/use-update-user-preferences';
import { Search } from '@mui/icons-material';
import { Driver, Trailer } from '../../types';

interface UserResourcePreferencesProps {
    closePreferences: () => void;
}

interface FilterInputHTMLAttributes extends React.InputHTMLAttributes<HTMLInputElement> {
    "data-entity": string
}

export const UserResourcePreferences: React.FC<UserResourcePreferencesProps> = props => {
    const { closePreferences } = props;
    const [isLoading, loading, done] = useBoolean(false);

    const { preferences, updatePreferences } = useUpdateUserPreferences();
    const { data: serverTrailers, isLoading: isLoadingTrailers } = useOrganizationQuery<Trailer[]>(OrgQueryKeys.trailers);
    const { data: serverDrivers, isLoading: isLoadingDrivers } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers);
    const [drivers, setDrivers] = React.useState<Driver[]>();
    const [trailers, setTrailers] = React.useState<Trailer[]>();
    const [removedDrivers, setRemovedDrivers] = React.useState<number[]>([])
    const [removedTrailers, setRemovedTrailers] = React.useState<number[]>([])

    const filterDrivers = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDrivers(serverDrivers?.filter(x => (new RegExp(e.target.value, 'gi')).test(x.name))
                                 .sortBy(x => x.name))
    }
    
    const filterTrailers = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTrailers(serverTrailers?.filter(x => (new RegExp(e.target.value, 'gi')).test(x.userDisplayName))
                                   .sortBy(x => x.userDisplayName))
    }

    React.useEffect(() => {
        loading();
        if (!isLoadingDrivers && !isLoadingTrailers && preferences) {
            setDrivers(serverDrivers?.sortBy(x => x.name))
            setTrailers(serverTrailers?.sortBy(x => x.userDisplayName))
            setRemovedDrivers([...(preferences.removedDrivers || [])]);
            setRemovedTrailers([...(preferences.removedTrailers || [])]);
            done();
        }
    }, [isLoadingTrailers, isLoadingDrivers, preferences]);

    const resourcePrefsChanged = async () => {
        loading();
        updatePreferences({...preferences, removedDrivers, removedTrailers})
        closePreferences();
        done();
    }


    const toggleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const entity = e.target.dataset.entity;
        const removingEntity = e.target.checked === false;
        const state = entity === 'Driver' ? removedDrivers : removedTrailers;
        const setter = entity === 'Driver' ? setRemovedDrivers : setRemovedTrailers;
        if (removingEntity) {
            setter([...state, parseInt(e.target.value)]);
        } else {
            setter(state.filter(x => x !== parseInt(e.target.value)))
        }
    }

    const checkboxMapper = (entity: 'Driver' | 'Trailer') => (x: any, i: number) => {
        return <FormControlLabel key={i} control={<Checkbox value={x.id}
                                                    checked={entity === 'Driver' ?
                                                             !removedDrivers.some(y => y === x.id) :
                                                             !removedTrailers.some(y => y === x.id)}
                                                    inputProps={{'data-entity': entity} as FilterInputHTMLAttributes}
                                                    onChange={toggleCheckbox}  />}
                                                    label={x.name || x.userDisplayName} />
    }

    return (
        !isLoading ? <ModalDialog title={L.resourcePreferences()} open={true} onClose={closePreferences}>
            <form id='edit-notification-prefs-form' name='edit-notification-prefs-form'>
                <div style={{ display: 'grid', gridTemplateColumns: '10fr 10fr', width: '700px' }}>
                    <div>{L.drivers()}</div>
                    <div>{L.trailers()}</div>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel size='small' htmlFor="filter-drivers">{L.searchDrivers()}</InputLabel>
                        <OutlinedInput label={L.searchDrivers()} id='filter-drivers' type="text" size="small"
                                       endAdornment={<InputAdornment position="start"><Search /></InputAdornment>}
                                       onChange={filterDrivers}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel size='small' htmlFor="filter-trailers">{L.searchTrailers()}</InputLabel>
                        <OutlinedInput label={L.searchTrailers()} id='filter-trailers' type="text" size="small"
                                       endAdornment={<InputAdornment position="start"><Search /></InputAdornment>}
                                       onChange={filterTrailers}
                        />
                    </FormControl>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '10fr 10fr', width: '700px', height:'50vh' }}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        {drivers && drivers.map(checkboxMapper('Driver'))}
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        {trailers && trailers.map(checkboxMapper('Trailer'))}
                    </div>
                    <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'end', gridColumnStart: 'span 2', paddingBottom: '20px' }}>
                        <Button variant='contained' color='primary' onClick={resourcePrefsChanged}>{L.save()}</Button>
                    </div>
                </div>
            </form>
        </ModalDialog> : <></>
    );
};
