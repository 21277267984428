import React, { FormEvent } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { L, UNITS, setCurrentUnitSystem } from 'harmony-language';
import { RolesList } from './roles-list';
import { ResourceEditCreateContainer } from '../../../shared/containers/resource-edit-create-container';
import { Driver, SiteManager } from '../../../../constants/constants';
import { Select } from '../../../shared/inputs/select';
import { toIdLabelTuple } from '../../../../utils/data-mapping-utils';
import { useTractorTrailer } from '../../../../api/queries/use-tractor-trailer';
import { EditCreateContact } from '../../contacts/edit-create-contact';
import { Contact, ContactNotificationMethod, NOTIFICATION_TYPES, Organization, Role } from '../../../../types';
import { CheckboxEnabled } from '../../../shared/inputs/checkbox-enabled';
import { useLookupUsername } from './use-lookup-username';
import { useDebounce } from '../../../shared/hooks/use-debounce';
import { setTimezone } from '../../../../utils/date-time-utils';
import { isNothing } from '../../../../utils/string-utils';
import { ValidatedTextField } from '../../../shared/inputs/validated-text-field';
import { isPasswordValid } from '../../../user/user-activation';
import { Checkbox, FormControlLabel, Icon, InputAdornment, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useUser } from '../../../../api/queries/use-user';
import { fetchLanguageDictionary } from '../../../user/actions/fetch-language-dictionary';

export const userHasEmail = (notificationMethods: ContactNotificationMethod[]): boolean => {
    if (isNothing(notificationMethods?.find(x => x.notificationType === NOTIFICATION_TYPES.Email)?.destination)) {
        return false;
    }
    return true;
}

type ShimInteractiveUser = {
    id?: number;
    contact: Contact;
    contactId?: number;
    email?: string | null;
    phone?: string | null;
    enabled: boolean;
    firstName?: string;
    lastName?: string;
    name?: string;
    organization?: Organization;
    organizationId?: number;
    roles?: Role[];
    username: string;
    driverInfo?: {
        defaultTractorId?: number | null;
        defaultTrailerId?: number | null;
        contract?: boolean;
        loadCompletionOutOfSequence?: boolean;
    }
    password?: string;
    sublocationTagging?: boolean;
};

type EditCreateUserProps = {
    existingUser: ShimInteractiveUser;
    onSubmit: (user: ShimInteractiveUser) => void;
};

export const EditCreateUser = (props: EditCreateUserProps) => {
    const { existingUser, onSubmit } = props;
    const [user, setUser] = React.useState<ShimInteractiveUser>(existingUser || {
        contact: null,
        enabled: true,
        username: ''
    });
    const [isDriverInfoAvailable, setIsDriverInfoAvailable] = React.useState(false);
    const [isSublocationTaggingAvailable, setIsSublocationTaggingAvailable] = React.useState(false);
    const { tractors, trailers, organizationId } = useTractorTrailer();
    const tractorList = (tractors?.filter(x => x.organizationId === organizationId)?.map(toIdLabelTuple('userDisplayName')) || []).concat({ id: null, label: L.none() });
    const trailerList = (trailers?.filter(x => x.organizationId === organizationId)?.map(toIdLabelTuple('userDisplayName')) || []).concat({ id: null, label: L.none() });
    const [search, setSearch] = React.useState('');
    const { debouncedValue, isDebouncing } = useDebounce(search, 2000);
    const [usernameValid, setIsUsernameValid] = React.useState(true);
    const { isLoading, fetch } = useLookupUsername();
    const { user: { id: selfId } } = useUser();
    const isEditingSelf = React.useMemo(() =>  user?.id === selfId ? true : false, []);
    const [emailHasValue, setEmailHasValue] = React.useState(userHasEmail(user.contact?.contactNotificationMethods));
    const [showPasswordField, setShowPasswordField] = React.useState(false);

    React.useEffect(() => {
        if (search && existingUser?.username !== search) {
            const fetchData = async () => {
                const validUsername = await fetch(debouncedValue);
                setIsUsernameValid(validUsername);
            }

            fetchData();
        } else {
            setIsUsernameValid(true);
        }
    }, [debouncedValue]);

    React.useEffect(() => {
        setIsDriverInfoAvailable(Boolean(user.roles?.some(r => r.id === Driver.id)));
        const canTagSublocations = Boolean(user.roles?.some(r => r.id === Driver.id || r.id === SiteManager.id));
        setIsSublocationTaggingAvailable(canTagSublocations);
        if (!canTagSublocations && user.sublocationTagging) {
            setUser({
                ...user,
                sublocationTagging: false,
            });
        }
    }, [user]);

    const submitForm = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (isEditingSelf) {
            setTimezone(user.contact.timezone);
            fetchLanguageDictionary(user.contact.language || undefined);
            setCurrentUnitSystem(user.contact.units || user.organization?.units || UNITS.Imperial);
        }
        if (userHasEmail(user.contact.contactNotificationMethods)) {
            delete user.password;
        }

        onSubmit(user);
    };

    const onContactChanged = (contact: Contact) => {
        const hasEmail = userHasEmail(contact.contactNotificationMethods);
        if (hasEmail) user.password = '';
        setEmailHasValue(hasEmail);
        setUser({ ...user, contact: contact });
    };

    return (
        <ResourceEditCreateContainer width='30vw'>
            <form onSubmit={submitForm}>
                <Grid container spacing={2}>
                    <Grid item md={6} lg={6}>
                        <TextField
                            variant='standard'
                            label={L.username()}
                            fullWidth
                            required={true}
                            error={!usernameValid}
                            helperText={!usernameValid && L.usernameTaken()}
                            value={user.username}
                            onChange={(e) => {
                                setUser({ ...user, username: e.target.value });
                                setSearch(e.target.value);
                            }} />
                        <EditCreateContact
                            contact={user.contact}
                            onContactChanged={onContactChanged}
                        />
                        {
                            isDriverInfoAvailable &&
                            <>
                                <Select
                                    item={tractorList.find(tractor => tractor.id === user.driverInfo?.defaultTractorId)}
                                    label={L.defaultTractor()}
                                    list={tractorList}
                                    onChange={(value) => setUser({
                                        ...user, driverInfo: {
                                            ...user.driverInfo,
                                            defaultTractorId: value.id
                                        }
                                    })}/>
                                <Select
                                    item={trailerList.find(trailer => trailer.id === user.driverInfo?.defaultTrailerId)}
                                    label={L.defaultTrailer()}
                                    list={trailerList}
                                    onChange={(value) => setUser({
                                        ...user, driverInfo: {
                                            ...user.driverInfo,
                                            defaultTrailerId: value.id
                                        }
                                    })}/>
                            </>
                        }
                        {!emailHasValue && showPasswordField &&
                            <ValidatedTextField
                                label={L.password()}
                                type='password'
                                autoComplete='new-password'
                                fullWidth
                                required={true}
                                validations={[{ message: L.passwordInvalid(), isValid: () => user?.password ? isPasswordValid(user.password) : false }]}
                                value={user?.password}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position='end'>
                                            <Tooltip
                                                placement={'top'}
                                                title={L.passwordRequirements()}>
                                                    <Icon><InfoOutlined></InfoOutlined></Icon>
                                            </Tooltip>
                                        </InputAdornment>,
                                }}
                                onChange={(e) => {
                                    setUser({ ...user, password: e.target.value });
                                }} />
                        }
                        {isSublocationTaggingAvailable &&
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={user?.sublocationTagging || false}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setUser({
                                                ...user,
                                                sublocationTagging: event.target.checked,
                                        })}} />
                                }
                                label={L.sublocationTagging()} />
                        }
                        {isDriverInfoAvailable &&
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={user.driverInfo?.contract || false}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setUser({
                                                ...user,
                                                driverInfo: {
                                                    ...user.driverInfo,
                                                    contract: event.target.checked,
                                                }
                                        })}} />
                                }
                                label={L.contract()} />
                        }
                        {isDriverInfoAvailable &&
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={user.driverInfo?.loadCompletionOutOfSequence || false}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setUser({
                                                ...user,
                                                driverInfo: {
                                                    ...user.driverInfo,
                                                    loadCompletionOutOfSequence: event.target.checked,
                                                }
                                        })}} />
                                }
                                label={L.loadsOutOfSequence()} />
                        }
                    </Grid>
                    <Grid item md={6} lg={6}>
                        <RolesList roles={user.roles} onChange={(newRoles) => {
                            setUser({ ...user, roles: newRoles });
                        }}/>
                    </Grid>
                    <Grid item md={12} lg={12}>
                        <CheckboxEnabled
                            checked={user.enabled}
                            disabled={isEditingSelf ? true : false}
                            onChange={() => setUser({ ...user, enabled: !user.enabled })} />
                        {!emailHasValue && !showPasswordField &&
                            <Button type='button' variant='contained' color='primary' onClick={() => setShowPasswordField(true)}>{L.showPasswordField()}</Button>}
                    </Grid>
                </Grid>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <Button type='submit'
                        data-testid='user-submit-button'
                        title={L.save()}
                        disabled={isDebouncing || !usernameValid || isLoading}
                        variant='contained'
                        color='primary'>{existingUser ? L.updateUser() : L.createUser()}</Button>
                </div>
            </form>
        </ResourceEditCreateContainer>
    );
};
