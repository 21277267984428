import React from 'react';
import { styled } from '@mui/material/styles';
import { AgisticsCard } from '../shared/containers/agistics-card';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import { L } from 'harmony-language';
import { isValidDate, localNow } from '../../utils/date-time-utils';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../api/config';
import Button from '@mui/material/Button';
import { AgisticsDateTimePicker } from '../shared/inputs/agistics-date-time-picker';
import { Driver, Tractor, Trailer } from '../../types';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { ModalDialog } from '../shared/containers/modal-dialog';
import { useBoolean } from '../shared/hooks/use-boolean';
import { DeliveryLog, DeliveryLogResourceType } from '../loads/delivery-log/delivery-log';

const ReportContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
});

const ReportContainerDates = styled('div')({
    flexDirection: 'row',
    marginBottom: '.5rem',
});

export const DashboardResourceLog: React.FC = () => {
    const [selectedDateBegin, setSelectedDateBegin] = React.useState<string | null>(localNow().subtract(1, 'day').seconds(0).milliseconds(0).toISOString());
    const [selectedDateEnd, setSelectedDateEnd] = React.useState<string | null>(localNow().seconds(0).milliseconds(0).toISOString());
    const [selectedResourceType, setSelectedResourceType] = React.useState<DeliveryLogResourceType | null>();
    const [selectedResourceId, setSelectedResourceId] = React.useState<number | null>();
    const { data: drivers = [], isLoading: isLoadingDrivers, organizationId } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers);
    const { data: tractors = [], isLoading: isLoadingTractors } = useOrganizationQuery<Tractor[]>(OrgQueryKeys.tractors);
    const { data: trailers = [], isLoading: isLoadingTrailers } = useOrganizationQuery<Trailer[]>(OrgQueryKeys.trailers);
    const [deliveryDialogOpen, deliveryOpenDialog, deliveryCloseDialog] = useBoolean(false);

    const isLoading = isLoadingDrivers || isLoadingTractors || isLoadingTrailers;
    const options = React.useMemo(() => {
        if (isLoading) {
            return [];
        } else {
            const driverList = drivers.filter(x => x.organizationId === organizationId).map((x) => ({
                category: L.drivers(),
                type: 'user' as DeliveryLogResourceType,
                label: `${x.lastName}, ${x.firstName}`,
                id: x.id }));
            const tractorList = tractors.filter(x => x.organizationId === organizationId).map((x) => ({
                category: L.tractor(),
                type: 'tractor' as DeliveryLogResourceType,
                label: x.userDisplayName,
                id: x.id })) || [];
            const trailerList = trailers.filter(x => x.organizationId === organizationId).map((x) => ({
                category: L.trailer(),
                type: 'trailer' as DeliveryLogResourceType,
                label: x.userDisplayName,
                id: x.id })) || [];
            return [...driverList, ...tractorList, ...trailerList];
        }
    }, [drivers, tractors, trailers, isLoading]);

    return (
        <>
            <ModalDialog title={L.resourceLog()}
                open={deliveryDialogOpen}
                onClose={() => {deliveryCloseDialog()}}>
                    <DeliveryLog
                        isResourceLog={true}
                        resourceType={selectedResourceType ? selectedResourceType : undefined}
                        resourceId={selectedResourceId ? selectedResourceId : undefined}
                        startDate={selectedDateBegin}
                        endDate={selectedDateEnd}
                    />
            </ModalDialog>
            <AgisticsCard title={L.resourceLog()}
                left={
                    <Tooltip
                        placement={'right-end'}
                        title={L.resourceLogInfo()} >
                        <Icon fontSize='medium'>info_outlined</Icon>
                    </Tooltip >
                }>
                <ReportContainer>
                    <ReportContainerDates>
                        <div>
                            <AgisticsDateTimePicker
                                label={L.dateBegin()}
                                value={selectedDateBegin}
                                onChange={(date) => setSelectedDateBegin(date)}
                            />
                        </div>
                        <div>
                            <AgisticsDateTimePicker
                                label={L.dateEnd()}
                                value={selectedDateEnd}
                                onChange={(date) => setSelectedDateEnd(date)}
                            />
                        </div>
                    </ReportContainerDates>
                    <div>
                        <Autocomplete
                            fullWidth
                            loading={isLoading}
                            loadingText={L.loading()}
                            options={options}
                            groupBy={(option) => option.category}
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            onChange={(_, newValue) => {
                                setSelectedResourceType(newValue ? newValue.type : null);
                                setSelectedResourceId(newValue ? newValue.id : null);
                            }}
                            renderInput={(params) => <TextField variant='standard' {...params} label={L.selectResource()} />}
                        />
                        <Button
                            style={{ marginLeft: '1rem', width: '15rem' }}
                            type='button'
                            title={L.resourceReportGenerate()}
                            variant='contained'
                            disabled={!isValidDate(selectedDateBegin) || !isValidDate(selectedDateEnd) || !selectedResourceType}
                            onClick={() => {
                                deliveryOpenDialog();
                            }}
                            color='primary'>{L.resourceLog()}</Button>
                    </div>
                </ReportContainer>
            </AgisticsCard >
        </>
    );
}
