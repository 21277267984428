import React from 'react';
import { styled } from '@mui/material/styles';
import { ModalDialog } from './containers/modal-dialog';
import { L } from 'harmony-language';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useBoolean } from './hooks/use-boolean';
import { DialogContent, DialogContentText } from '@mui/material';
import { Image } from '../../types';

const RowImage = styled('img')({
    margin: 4,
    'object-fit': 'contain',
    width: '200px',
    height: '200px'
});

const ImageFull = styled('img')({
    flex: 1,
    'object-fit': 'contain',
    flexDirection: 'column',
    width: '600px',
    height: '600px'
});

interface ImageThumbnailExpandProps {
    image: Image | undefined;
    label: string;
    onDelete?: (image: Image) => void;
    imageStyle?: any;
}

export const ImageThumbnailExpand: React.FC<ImageThumbnailExpandProps> = (props) => {
    const { image, label, onDelete } = props;
    const [isImageExpanded, expandImage, unExpandImage] = useBoolean(false);
    const [isDeleteIconVisible, showDeleteIcon, hideDeleteIcon] = useBoolean(false);
    const [isConfirmationDialogOpen, openConfirmationDialog, closeConfirmationDialog] = useBoolean(false);

    const onConfirmDelete = () => {
        closeConfirmationDialog();
        if (onDelete && image) {
            onDelete(image);
        }
    };

    const showingDeleteIcon = image && onDelete && isDeleteIconVisible;

    return (
        <>
            <ModalDialog title={label} open={isImageExpanded} onClose={unExpandImage}>
                {image ? (
                    <ImageFull alt={image.blob} src={image.blob} />
                ) : null}
            </ModalDialog>
            <Dialog open={isConfirmationDialogOpen} onClose={closeConfirmationDialog}
                    aria-labelledby="alert-dialog-title" aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>
                    {L.deleteConfirmationTitle()}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {L.deleteImageConfirmationMessage()}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onConfirmDelete} color='primary'>
                        {`${L.yes()}`}
                    </Button>
                    <Button onClick={closeConfirmationDialog}
                        color='primary' autoFocus>
                        {`${L.no()}`}
                    </Button>
                </DialogActions>
            </Dialog>
            <Button onClick={expandImage}
                onMouseEnter={showDeleteIcon} onMouseLeave={hideDeleteIcon}>
                {showingDeleteIcon ? (
                    <IconButton
                        onClick={(event: React.MouseEvent) => {
                            event.stopPropagation();
                            openConfirmationDialog();
                        }}
                        aria-label='delete'
                        sx={{ zIndex: 1000, position: 'absolute', right: 4, top: 4 }}
                        size="large">
                        <DeleteOutlineOutlinedIcon fontSize='small' color='primary' />
                    </IconButton>
                ) : null}
                {image ? (
                    <RowImage alt={image.blob} src={image.blob} />
                ) : null}
            </Button>
        </>
    );
};
