import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useOrderGroupTable } from './use-order-group-table';
import { useOrderGroupColumns } from './use-order-group-columns';
import { AgisticsTableBody } from '../../shared/table/components/agistics-table-body';
import { AgisticsTableFooter } from '../../shared/table/components/agistics-table-footer';
import { useProductionCardGroups } from '../use-production-card-groups';
import { useUpdateUserPreferences } from '../../shared/hooks/use-update-user-preferences';
import { AgisticsTableHeader } from '../../shared/table/components/agistics-table-header';
import OrderGroupTableToolbar from './order-group-table-toolbar';

const StyledPaper = styled(Paper)({
    margin: '0 1rem'
});

const StyledTable = styled('table')({
    flex: 1,
    height: '100%',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    width: '100%',
    minWidth: '900px',
    fontSize: '.875rem'
});

const TABLE_ID = 'orderGroups';

interface OrderGroupTableProps {
    cargoTypeId: number;
}

export const OrderGroupTable: React.FC<OrderGroupTableProps> = (props) => {
    const { cargoTypeId } = props;
    const { preferences } = useUpdateUserPreferences();
    const pageSizePreference = (preferences?.tables?.[TABLE_ID]?.pageSize) || 10;
    const columns = useOrderGroupColumns();
    const { data, isLoading, isFetching, refetch } = useProductionCardGroups();

    const orderGroups = React.useMemo(() => {
        const grouped = data?.data.filter(group => {
            return group.cargo.some(c => c.cargoTypeId === cargoTypeId)
        });
        return grouped || [];
    }, [data?.data, cargoTypeId]);

    const table = useOrderGroupTable(columns, orderGroups, { pageIndex: 0, pageSize: pageSizePreference });

    return (
        <StyledPaper>
            <OrderGroupTableToolbar refetch={refetch} table={table} />
            <StyledTable>
                <AgisticsTableHeader table={table} />
                <AgisticsTableBody
                    isLoading={isLoading || isFetching}
                    table={table}
                />
                <AgisticsTableFooter
                    id={TABLE_ID}
                    table={table}
                />
            </StyledTable>
        </StyledPaper>
    );
};
