import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { L, UNITS, getCurrentUnitSystem } from 'harmony-language';
import { useWeights } from '../../shared/hooks/use-weights';
import { DecisionReportColumns } from '../report-types';

export const useDecisionColumns = (isAdmin: boolean) => {
    const columnHelper = createColumnHelper<DecisionReportColumns>();
    const isMetric = getCurrentUnitSystem() === UNITS.Metric;
    const { weightAbbreviation } = useWeights();

    const columns = React.useMemo(() => {
        const distanceAbbreviation = isMetric ? L.unitsMetricDistanceAbbr() : L.unitsImperialDistanceAbbr();

        const adminSettings = () => {
            return [
                columnHelper.accessor('organization_id', {
                    header: () => L.organizationId(),
                    cell: info => info.getValue(),
                }),
            ]
        }

        const adminColumns = isAdmin ? adminSettings() : [];
        const restColumns = [
            columnHelper.accessor('site_id', {
                header: () => L.siteId(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('site', {
                header: () => L.site(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('site_type', {
                header: () => L.siteType(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('site_mode', {
                header: () => L.siteMode(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('loads', {
                header: () => L.loads(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('stops', {
                header: () => L.stops(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('stops_fulfilled', {
                header: () => L.stopsFulfilled(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('stops_fulfilled_percent', {
                header: () => L.stopsFulfilledPercent(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('stops_fulfilled_on_time_percent', {
                header: () => L.stopsFulfilledOnTimePercent(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('distance', {
                header: () => `${L.distance()} (${distanceAbbreviation})`,
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('distance_per_load', {
                header: () => `${L.distancePerLoad()} (${distanceAbbreviation})`,
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('delivery_hours', {
                header: () => L.deliveryHours(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('delivery_hours_per_load', {
                header: () => L.deliveryHoursPerLoad(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('driving_hours', {
                header: () => L.drivingHours(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('driving_hours_per_load', {
                header: () => L.drivingHoursPerLoad(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('site_hours', {
                header: () => L.siteHours(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('site_minutes_per_load', {
                header: () => L.siteMinutesPerLoad(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('splits_same_site', {
                header: () => L.splitsSameSite(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('splits_different_site', {
                header: () => L.splitsDifferentSite(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('weight', {
                header: () => `${L.weight()} (${weightAbbreviation})`,
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('weight_per_load', {
                header: () => `${L.weightPerLoad()} (${weightAbbreviation})`,
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('quantity', {
                header: () => L.quantity(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('quantity_per_load', {
                header: () => L.quantityPerLoad(),
                cell: info => info.getValue(),
            }),
        ];

        return [...adminColumns, ...restColumns];
    }, [weightAbbreviation, isMetric]);

    return columns;
}
