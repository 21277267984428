import React from 'react';
import { L, SUPPORTED_LANGUAGES } from 'harmony-language';
import PropTypes from 'prop-types';
import { Select } from './select';

export const SelectLanguage = props => {
    const { language, onChange } = props;

    const menuItems = SUPPORTED_LANGUAGES.map(language => ({
        id: language.code,
        value: language.code,
        label: L[language.description]()
    }));

    return (
        <Select
            {...props}
            label={L.selectLanguage()}
            list={menuItems}
            item={menuItems.find(x => x.value === language)}
            onChange={(v) => onChange(v.value)}/>
    );
};

SelectLanguage.propTypes = {
    language: PropTypes.string,
    onChange: PropTypes.func,
};
