import { ProductionCardType } from './production-card';
import { getTransCargoTypeLabel } from '../../../constants/constants';
import React from 'react';
import { useGetStopDisplayName } from '../../shared/hooks/use-location-info';
import { doesValueMatch } from '../../shared/table/utils/search-functions';

export const useBucketFiltering = (cards: ProductionCardType[], search: string): ProductionCardType[] => {
    const getStopDisplayName = useGetStopDisplayName();
    return React.useMemo(() => {
        if (!search) {
            return cards;
        }

        return cards.filter(c => {
            const origin = c.stops.find(s => s.type === 'Origin') as { organizationLocationId: number, organizationSubLocationId?: number };
            const destination = c.stops.find(s => s.type === 'Destination') as { organizationLocationId: number, organizationSubLocationId?: number };
            const values = [
                getStopDisplayName(origin),
                getStopDisplayName(destination),
                getTransCargoTypeLabel(c.cargoTypeId),
                c.quantity,
                c.productionPlan,
                c.lotId,
                c.orderNumber];
            return values.some(x => doesValueMatch(x, search));
        });
    }, [cards, search]);
};
