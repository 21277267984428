import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { L } from 'harmony-language';
import { SearchBar } from '../search-bar';
import { Table } from '@tanstack/react-table';
import { Button } from '@mui/material';
import { NotificationConfigDrawer } from '../../company-management/notification-configs/notification-config-drawer';
import { Info } from '@mui/icons-material';

interface ResourceTableToolbarProps {
    title: string;
    table: Table<any>;
    showDrawer: boolean;
}

export const ResourceTableToolbar: React.FC<ResourceTableToolbarProps> = (props) => {
    const { title, table, showDrawer } = props;
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const handleSearchTextChange = (text: string) => {
        table.setGlobalFilter(text);
    };

    return (
        <Toolbar role='toolbar'>
            <Grid container alignItems='center' direction='row' spacing={0}>
                <Grid item xs={12} sm={10} md={10} lg={10}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='h6' style={{ display: 'inline' }}>{title}</Typography>
                        {showDrawer && <Button
                            onClick={() => setDrawerOpen(!drawerOpen)}
                        >
                            <Info />
                        </Button>}
                    </div>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2}>
                    <Grid container justifyContent='flex-end' alignItems='center'>
                        <Grid item>
                            <SearchBar
                                placeholder={`${L.numRecords(table.getRowCount().toString())}`}
                                text={table.getState().globalFilter}
                                setText={handleSearchTextChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <NotificationConfigDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        </Toolbar>
    );
};
